import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Button,
  Divider,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_SUCCESS_ERROR_POPUP } from "../../../../store/actions";

const RecurringSelection = ({
  open,
  handleClose,
  handleSave,
  selectedRecurringOpt,
  handleChangeRecurring,
  recurringOpts
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const onSave = () => {
    if (selectedRecurringOpt?.length) {
      handleSave(selectedRecurringOpt)
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: "Select recurring options, before save" }
      })
    }
  }

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="xs"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          py={1.5}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={22}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Recurring
          </Typography>
        </Box>
      </DialogTitle>
      {/* <Box width={1} mb={1}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box> */}
      <Divider sx={{ mb: 2 }}></Divider>
      <DialogContent sx={{ px: 3 }}>
        <Box width={1}>
          <Typography
            variant="h6"
            sx={{
              color: theme?.palette?.text?.containerTextLabel,
              fontSize: 15,
              fontFamily: "Poppins",
              fontWeight: 500
            }}
          >
            Choose Recurring Cadence
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme?.palette?.text?.containerTextLabel,
              fontSize: 13,
              fontFamily: "Poppins",
              fontWeight: 500,
            }}
          >
            Setting an open time here will automatically create a Private 1:1 session event whereby a single user can register.
          </Typography>
        </Box>
        <Box>
          {recurringOpts?.map(opt => (
            <Box key={opt?.id} mt={0.5}>
              <FormControlLabel
                label={opt?.name}
                control={
                  <Checkbox
                    sx={{
                      color: "#00B60D",
                      "&.Mui-checked": {
                        color: "#00B60D",
                      },
                    }}
                    checked={selectedRecurringOpt.indexOf(opt?.id) > -1}
                    onChange={() => handleChangeRecurring(opt?.id)}
                  />
                }
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          mt: 3,
          pb: 2
        }}>
        <Box display="flex" justifyContent="space-between" width={1}>
          <Typography
            variant="h5"
            sx={{
              width: 120,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer"
            }}
            onClick={handleClose}
          >
            Cancel
          </Typography>
          <Button
            size="small"
            variant="contained"
            sx={{
              width: 120,
              height: 40,
              background: theme?.palette?.secondary?.dark,
              color: "white",
              ml: 2
            }}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringSelection;
