import {
  Box,
  Typography,
  Grid,
  Divider,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import ComingSoon from "../../components/ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useRef } from "react";
import TiktokIcon from "../../assets/images/social-links/tiktok.png";
import TwitterIcon from "../../assets/images/social-links/twitter.png";
import InstaIcon from "../../assets/images/social-links/instagram.png";
import SnapIcon from "../../assets/images/social-links/snapchat.png";
import FacebookIcon from "../../assets/images/social-links/facebook.png";
import LinkedInIcon from "../../assets/images/social-links/linkedin.png"
import YouTubeIcon from "@mui/icons-material/YouTube";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTheme } from "@mui/material/styles";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Tile from "../../assets/images/Tile.png";
import Employee from "../../assets/images/employee.png";
import { getMe, SET_USER_DRAWER, SET_SUCCESS_ERROR_POPUP, SET_USER, SET_LOADER } from "../../store/actions";
import * as pages from '@routes/constants'
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { charValidate } from '@utils/functions'
import { Admin_Tournament_PAGE } from "../../routes/constants";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dailyTraining_icon from "../../assets/images/dashboard-menu/icon-daily-training.svg";
import products_icon from "../../assets/images/dashboard-menu/icon-products.svg";
import filmRoom_icon from "../../assets/images/dashboard-menu/icon-filmroom.svg";
import performanceEvaluations_icon from "../../assets/images/dashboard-menu/icon-performance-evaluations.svg";
import campsClinics_icon from "../../assets/images/dashboard-menu/icons-camps-clinics.svg";
import communication_icon from "../../assets/images/dashboard-menu/icon-communication.svg";
import manageCoaches_icon from "../../assets/images/dashboard-menu/icon-manage-coaches.svg";
import manageAthletes_icon from "../../assets/images/dashboard-menu/icon-manage-athletes.svg";
import myteamsFootball_icon from "../../assets/images/dashboard-menu/icon-myteams_football.svg";
import mytournaments_icon from "../../assets/images/dashboard-menu/icon-mytournaments.svg";
import myleaguesFootball_icon from "../../assets/images/dashboard-menu/icon-myleagues_football.svg";
import httpClient from '@utils/httpClient'


const DashboardForTeamCoachManager = () => {
  const { user, token } = useSelector((state) => state.user);
  const drawerOpened = useSelector((state) => state.theme.drawer);
  const org = useSelector((state) =>
    state.org.org ? state.org.org.data : {}
  )
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const isSocialLink = org ? (org?.web_menu_settings?.teammanager[0]?.dashboard.filter(x => x.id === 'social')[0].externalLink) : "";
  const mainMenuOptions = org?.web_menu_settings?.teammanager?.map((menu) => menu?.dashboardMenuOptions?.mainMenuOptions ?? []);
  const secondaryOptions = org?.web_menu_settings?.teammanager?.map((menu) => menu?.dashboardMenuOptions?.secondaryOptions ?? []);

  const userProfilePlusIconStyle = {
    color: "green",
    fontSize: "1.4rem",
    position: "absolute",
    bottom: "8px",
    right: 0,
    background: "white",
    borderRadius: "100%"
  }

  const renderMainMenuIcons = (iconName) => {
    if ("dailyTraining_icon" === iconName) {
      return dailyTraining_icon;
    } else if ("products_icon" === iconName) {
      return products_icon;
    } else if ("filmRoom_icon" === iconName) {
      return filmRoom_icon;
    } else if ("performanceEvaluations_icon" === iconName) {
      return performanceEvaluations_icon;
    } else if ("campsClinics_icon" === iconName) {
      return campsClinics_icon;
    } else if ("myteamsFootball_icon" === iconName) {
      return myteamsFootball_icon;
    } else if ("mytournaments_icon" === iconName) {
      return mytournaments_icon;
    } else if ("myleaguesFootball_icon" === iconName) {
      return myleaguesFootball_icon;
    }
  }

  const renderSideMenuIcons = (iconName) => {
    if ("communication_icon" === iconName) {
      return communication_icon;
    } else if ("manageCoaches_icon" === iconName) {
      return manageCoaches_icon;
    } else if ("manageAthletes_icon" === iconName) {
      return manageAthletes_icon;
    }
  }

  const handleClick = (event) => {
    if (!user?.tiktok_url) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(getMe())
  }, [user?.user_type])

  const handleFileInputChange = async (event) => {
    const selectedFile = event.target.files[0];
    const API_URL = "/auth/change-profilePic"
    const formData = new FormData();
    formData.append('files', selectedFile);
    dispatch({ type: SET_LOADER, data: true })
    const apiRes = await httpClient.post(API_URL, formData)
    if (apiRes && apiRes.ok) {
      dispatch({ type: SET_LOADER, data: false })
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: apiRes.message }
      })
      dispatch({ type: SET_USER, data: apiRes.user })
    } else {
      dispatch({ type: SET_LOADER, data: false })
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes.message }
      })
    }
  };

  const handleIconClick = () => {
    // Trigger the file input when the icon is clicked
    fileInputRef.current.click();
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Grid
        container
        spacing={2}
        sx={{ alignSelf: "center", justifySelf: "center", height: "100%" }}
      >
        <Grid
          item
          lg={4} md={5} xs={12}
          sx={{
            background: "#FAFAFA",
            borderRight: "1.7px solid #DDDDDD",
            // height: "100vh"
            [theme.breakpoints.down('md')]: {
              background: "transparent",
              borderRight: "0",
            }
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            sx={{
              p: 3,
              pl: 1,
              [theme.breakpoints.down('md')]: {
                background: "#FAFAFA",
                borderRight: "1px solid #DDDDDD",
                borderTop: "1px solid #DDDDDD",
                borderLeft: "1px solid #DDDDDD",
                pl: 3,
              },
              "& .MuiOutlinedInput-root": {
                border: "1px solid #dfe0df",
                boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
                background: "#fff",
                "& .MuiOutlinedInput-input": {
                  background: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: "none",
                },
              },
              "& .MuiFormControl-root .MuiFormControl-root": {
                boxShadow: "none",
              },
              span: {
                display: "block",
                "font-size": "25px",
                "font-weight": 700,
                "line-height": "57px",
                /* text-align: center; */
                height: "60px",
                width: "60px",
                pl: "12px",
              },
              figure: {
                margin: 0,
                height: "60px",
                width: "60px",
                "min-width": "30px",
                "border-radius": "50rem",
                position: "relative",
                overflow: "hidden",
                border: "3px solid #fff",
                "box-shadow": "0 0 15px rgba(0, 0, 0, 0.25)",
                left: "50%",
                "-webkit-transform": "translate(-50%)",
                "-moz-transform": "translate(-50%)",
                "-ms-transform": "translate(-50%)",
                transform: "translate(-50%)",
              },
              "#userImg": {
                width: "100%",
                height: "100%",
              },
              "#save": {
                py: 1.5,
                px: 3.5,
                fontSize: 15,
                fontWeight: 700,
                mt: 2,
              },
            }}
          >
            <Box display="flex" alignItems="center" sx={{
              width: '100%',
            }}>
              {user?.image ?
                <Box
                  sx={{
                    // border: `3px solid ${theme?.palette?.background?.paper}`,
                    // boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                    // textTransform: "uppercase",
                    height: 100,
                    width: 100,
                    borderRadius: "100%",
                    position: "relative",
                    cursor: "pointer",
                    [theme.breakpoints.down('lg')]: {
                      height: 80,
                      width: 80,
                    }
                  }}
                >
                  <img
                    src={user?.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "100%",
                      objectFit: "cover",
                      background: "white",
                      padding: "5px",
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                      [theme.breakpoints.down('lg')]: {
                        height: "100%",
                        width: "100%",
                      }
                    }}
                  />
                  <Box>
                    <input
                      type="file"
                      accept="image/*" // Specify the file types allowed (e.g., images)
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                    />
                    <AddCircleIcon sx={userProfilePlusIconStyle} onClick={handleIconClick} />
                  </Box>
                </Box> :
                <Box
                  sx={{
                    position: "relative", height: 100, width: 100, cursor: "pointer",
                    [theme.breakpoints.down('lg')]: {
                      height: 80,
                      width: 80,
                    }
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: theme?.palette?.secondary?.dark,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: theme?.palette?.background?.paper,
                      border: `3px solid ${theme?.palette?.background?.paper}`,
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                      textTransform: "uppercase",
                      height: 100,
                      width: 100,
                      borderRadius: "100%",
                      fontSize: "24px",
                      [theme.breakpoints.down('lg')]: {
                        height: 80,
                        width: 80,
                      }
                    }}
                  >
                    {`${user?.first_name?.charAt(
                      0,
                    )} ${user?.last_name?.charAt(0)}`}
                  </Box>
                  <Box>
                    <input
                      type="file"
                      accept="image/*" // Specify the file types allowed (e.g., images)
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                    />
                    <AddCircleIcon sx={userProfilePlusIconStyle} onClick={handleIconClick} />
                  </Box>
                </Box>
              }
              <Box display="flex" flexDirection="column" ml={2}
                sx={{
                  width: "calc(100% - 116px)",
                  [theme.breakpoints.down('lg')]: {
                    width: "calc(100% - 96px)",
                  }
                }}>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 1,
                    fontSize: "20px",
                    color: theme?.palette?.dark?.dark3,
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  {`${user?.first_name} ${user?.last_name}`}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                    color: theme?.palette?.text?.containerTextLabel,
                    my: 1,
                    fontFamily: "Arial, Regular",
                    fontWeight: 500,
                  }}
                >
                  {user?.email}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: 'wrap',
                    alignItems: "center",
                  }}
                >
                  {user?.tiktok_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.tiktok.com/${user?.tiktok_url}` */}
                      <a href={user?.tiktok_url} target="_blank"
                        style={{
                          pointerEvents: user?.tiktok_url ? 'auto' : 'none',
                          opacity: user?.tiktok_url ? 1 : 0.5,
                          cursor: user?.tiktok_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={TiktokIcon}
                          alt="Tiktok Icon"
                          height="auto"
                          width="15px"
                        />
                      </a>
                    </Box>
                  }
                  {user?.twitter_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://twitter.com//${user?.twitter_url}` */}
                      <a href={user?.twitter_url} target="_blank"
                        style={{
                          pointerEvents: user?.twitter_url ? 'auto' : 'none',
                          opacity: user?.twitter_url ? 1 : 0.5,
                          cursor: user?.twitter_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={TwitterIcon}
                          alt="Twitter Icon"
                          width="15px"
                          height="auto"
                        />
                      </a>
                    </Box>
                  }
                  {user?.linkedin_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.linkedin.com/${user?.linkedin_url}` */}
                      <a href={user?.linkedin_url} target="_blank"
                        style={{
                          pointerEvents: user?.linkedin_url ? 'auto' : 'none',
                          opacity: user?.linkedin_url ? 1 : 0.5,
                          cursor: user?.linkedin_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={LinkedInIcon}
                          alt="Linked Icon"
                          width="15px"
                          height="auto"
                        />
                      </a>
                    </Box>
                  }
                  {user?.insta_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.instagram.com/${user?.insta_url}` */}
                      <a href={user?.insta_url} target="_blank"
                        style={{
                          pointerEvents: user?.insta_url ? 'auto' : 'none',
                          opacity: user?.insta_url ? 1 : 0.5,
                          cursor: user?.insta_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={InstaIcon}
                          alt="Instagram Icon"
                          width="15px"
                          height="auto"
                        />
                      </a>
                    </Box>
                  }
                  {user?.yt_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.youtube.com/${user?.yt_url}` */}
                      <a href={user?.yt_url} target="_blank"
                        style={{
                          pointerEvents: user?.yt_url ? 'auto' : 'none',
                          opacity: user?.yt_url ? 1 : 0.5,
                          cursor: user?.yt_url ? 'pointer' : 'default',
                        }}
                      >
                        <YouTubeIcon sx={{ color: "#1E2549" }} />{" "}
                      </a>
                    </Box>
                  }
                  {user?.snap_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.snapchat.com/add/${user?.snap_url}` */}
                      <a href={user?.snap_url} target="_blank"
                        style={{
                          pointerEvents: user?.snap_url ? 'auto' : 'none',
                          opacity: user?.snap_url ? 1 : 0.5,
                          cursor: user?.snap_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={SnapIcon}
                          alt="Snap Icon"
                          width="15px"
                          height="auto"
                        />
                      </a>
                    </Box>
                  }
                  {user?.fb_url &&
                    <Box px={0.7} py={1} sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      {/* append url - `https://www.facebook.com/${user?.fb_url}` */}
                      <a href={user?.fb_url} target="_blank"
                        style={{
                          pointerEvents: user?.fb_url ? 'auto' : 'none',
                          opacity: user?.fb_url ? 1 : 0.5,
                          cursor: user?.fb_url ? 'pointer' : 'default',
                        }}
                      >
                        <img
                          src={FacebookIcon}
                          alt="Facebook Icon"
                          width="15px"
                          height="auto"
                        />
                      </a>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                // border: "1px solid",
                // borderRadius: "4px",
                height: "115px",
                width: "100%",
                mt: 4,
                overflowY: "auto",
                padding: "1rem",
                [theme.breakpoints.down('lg')]: {
                  mt: 3,
                },
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                }
              }}
            >
              {
                user.bio ?
                  <Typography
                    sx={{
                      // textAlign: "center",
                      fontSize: "14px",
                      color: theme?.palette?.dark?.dark3,
                      fontWeight: 400,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {user.bio}
                  </Typography> :
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: theme?.palette?.dark?.dark3,
                      textDecoration: "underline",
                      fontWeight: 400,
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      dispatch({ type: SET_USER_DRAWER, data: true });
                    }}
                  >
                    Add Bio
                  </Typography>
              }
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} mt={0} pl={1} pr={3} sx={{
            [theme.breakpoints.down('md')]: {
              background: "#FAFAFA",
              borderRight: "1px solid #DDDDDD",
              borderBottom: "1px solid #DDDDDD",
              borderLeft: "1px solid #DDDDDD",
              pl: 3,
              pb: 2,
            },
          }}>
            {secondaryOptions && secondaryOptions?.length ?
              secondaryOptions[0]?.map((option) => (
                <Box
                  key={option?.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "4rem",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    my: 1.5,
                    background: "white",
                    padding: "1.5rem",
                    borderRadius: "5px",
                    cursor: "pointer",
                    [theme.breakpoints.down('md')]: {
                      mt: 0,
                    }
                  }}
                  onClick={() => {
                    if (option?.title === "Community & Communication") {
                      // window.open(option?.path);
                      window.open(`${isSocialLink}?token=${token}`, "_blank")
                    } else {
                      navigate(option?.path);
                    }
                  }}
                >
                  <img
                    src={renderSideMenuIcons(option?.icon)}
                    style={{
                      width: "1.8rem",
                      height: "1.8rem"
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: theme?.palette?.secondary?.dark,
                      fontWeight: 500,
                      fontFamily: "Poppins, sans-serif",
                      ml: 2.5
                    }}
                  >
                    {option?.title}
                  </Typography>
                </Box>
              ))
              : null}
          </Box>
        </Grid>
        <Grid item lg={8} md={7} xs={12}>
          <Box mx={1} mb={2}>
            {mainMenuOptions && mainMenuOptions?.length ?
              mainMenuOptions[0]?.map((menu) => (
                <Box key={menu?.id}>
                  <Box
                    sx={{
                      mt: 3,
                      pb: 2.5,
                      borderBottom: "2.5px solid rgba(0,0,0,0.1)",
                      [theme.breakpoints.down('md')]: {
                        pb: 1.5,
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                      }}
                    >
                      {menu?.title}
                    </Typography>
                  </Box>
                  <Grid container spacing={2} mt={0}>
                    {menu?.options?.map((option) => (
                      <Grid item xl={3} lg={4} md={6} sm={4} xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            background: "white",
                            padding: "1.5rem",
                            borderRadius: "5px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            if (option?.path) {
                              navigate(option?.path);
                            }
                          }}
                        >
                          <img
                            src={renderMainMenuIcons(option?.icon)}
                            style={{
                              width: "1.8rem",
                              height: "1.8rem",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: theme?.palette?.secondary?.dark,
                              fontWeight: 700,
                              fontFamily: "Poppins, sans-serif",
                              textAlign: "center",
                              mt: 2
                            }}
                          >
                            {option?.title.split(' ').map((word, index) => (
                              <div key={index}>
                                {word}
                                <br />
                              </div>
                            ))}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))
              : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardForTeamCoachManager;
