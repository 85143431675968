import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme, Tooltip, Popover
} from '@mui/material';
import SettingsIcon from "@mui/icons-material/Settings";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  SET_TOAST,
  SET_LOADER, SET_SUCCESS_ERROR_POPUP, getTournamentTeams
} from '../../store/actions';
import MuiDataGrid from '../../components/MuiDataGrid/MuiDataGrid';
import { useRef } from 'react';
import { charValidate, formatPhoneNumber } from '../../utils/functions';
import { useParams } from 'react-router-dom';
import { Admin_Tournament_PAGE } from '../../routes/constants';
import ActionCard from '../Common/ActionCard';

const ViewTournamentTeams = () => {
  const navigate = useNavigate()
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [isTournamentTeamListLoading, setTournamentTeamListLoading] = useState(false);
  const [tournamentTeamsData, setTournamentTeamsData] = useState([])
  const [tournamentInfo, setTournamentInfo] = useState({});

  const theme = useTheme();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const tableRef = useRef();
  const location = useLocation();
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popid = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getTournamentTeamsList()
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const columns = [
    {
      field: "title",
      headerName: "Team Name",
      flex: 1,
      minWidth: 200,
      renderCell: (data) => (
        <Tooltip title={data?.row?.title ?? "N/A"}>
          {data?.row?.title ? charValidate(data?.row?.title, 20) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "Coach/Team Manager First Name",
      flex: 1,
      minWidth: 150,
      renderCell: (data) => {
        const teamManagerTeamCoach = data?.row?.team_manager_and_team_coach;
        return (
          <ul>
            {teamManagerTeamCoach.map((teamCoachManager) => (
              <Tooltip title={teamCoachManager?.first_name ?? "N/A"}>
                <li key={teamCoachManager?.id}>
                  {teamCoachManager?.first_name ? charValidate(teamCoachManager?.first_name, 15) : "N/A"}
                </li>
              </Tooltip>
            ))}
          </ul>
        );
      },
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "Coach/Team Manager Last Name",
      flex: 1,
      minWidth: 150,
      renderCell: (data) => {
        const teamManagerTeamCoach = data?.row?.team_manager_and_team_coach;
        return (
          <ul>
            {teamManagerTeamCoach.map((teamCoachManager) => (
              <Tooltip title={teamCoachManager?.last_name ?? "N/A"}>
                <li key={teamCoachManager?.id}>
                  {teamCoachManager?.last_name ? charValidate(teamCoachManager?.last_name, 15) : "N/A"}
                </li>
              </Tooltip>
            ))}
          </ul>
        );
      },
      sortable: false,
    },
    {
      field: "email",
      headerName: "Coach/Team Manager Email",
      flex: 1,
      minWidth: 200,
      renderCell: (data) => {
        const teamManagerTeamCoach = data?.row?.team_manager_and_team_coach;
        return (
          <ul>
            {teamManagerTeamCoach.map((teamCoachManager) => (
              <Tooltip title={teamCoachManager?.email ?? "N/A"}>
                <li key={teamCoachManager?.id} sx={{ wordBreak: 'break-word' }}>
                  {teamCoachManager?.email ? teamCoachManager?.email : "N/A"}
                </li>
              </Tooltip>
            ))}
          </ul>
        );
      },
      sortable: false,
    },
    {
      field: "mobile",
      headerName: "Coach/Team Manager Phone",
      flex: 1,
      minWidth: 200,
      renderCell: (data) => {
        const teamManagerTeamCoach = data?.row?.team_manager_and_team_coach;
        return (
          <ul>
            {teamManagerTeamCoach.map((teamCoachManager) => (
              <Tooltip title={teamCoachManager?.mobile ? formatPhoneNumber(teamCoachManager?.mobile) : "N/A"}>
                <li key={teamCoachManager?.id}>
                  {teamCoachManager?.mobile ? formatPhoneNumber(teamCoachManager?.mobile) : "N/A"}
                </li>
              </Tooltip>
            ))}
          </ul>
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            <Box>
              <SettingsIcon
                onClick={handleClick}
                sx={{ fontSize: 22, cursor: "pointer" }}
                color="primary"
              />
              <Popover
                id={popid}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  p: 1,
                  "& .MuiPaper-root": { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)" }
                }}
              >
                <ActionCard title={"Actions"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{ paddingX: 5.5, paddingY: 1, mt: 1 }}
                      onClick={() => {
                        navigate(`/admin-tournament/admin-tournament-team/team-roster/${data?.row?.id}`);
                      }}
                    >
                      View Roster
                    </Button>
                  </Box>
                </ActionCard>
              </Popover>
            </Box>
          </>
        )
      },
    },
  ];


  const getTournamentTeamsList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
      tournamentId: id
    };
    let response = await getTournamentTeams(params, dispatch);
    if (response?.ok) {
      setTournamentTeamsData(response?.data?.data?.TournamentList ?? []);
      setTournamentInfo(response?.data?.data?.TournamentInfo ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong for tournament data" }
      })
    }
  }

  return (
    <MuiDataGrid
      headerLabel={tournamentInfo?.name || "N/A"}
      gridData={isTournamentTeamListLoading ? [] : tournamentTeamsData}
      columns={columns}
      paginationOptions={paginationOptions}
      tableRef={tableRef}
      isLoading={isTournamentTeamListLoading}
      isAddNewBtnShow={false}
      gridToolbarButtons={() => {
        return (
          <>
            {
              tournamentTeamsData?.length ?
                <Button
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1 }}
                  onClick={() => {
                    navigate(`/view-and-add-score/${id}`);
                  }}
                >
                  Tournament Scores & Details
                </Button> :
                null
            }

          </>
        )
      }}
      gridButtons={() => {
        return (
          <Box sx={{ my: 2, px: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
              size="small"
              variant="outlined"
              sx={{ paddingX: 3.5, paddingY: 1 }}
              onClick={() => {
                navigate(Admin_Tournament_PAGE);
              }}
            >
              Back
            </Button>
          </Box>
        )
      }}
    />
  )

}
export default ViewTournamentTeams;
