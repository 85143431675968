import { Box, Button, Divider, FormControl, FormHelperText, TextField, Typography, Grid, InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useTheme } from '@mui/material/styles'
import httpClient from '@utils/httpClient'
import { useState } from 'react';
import { SET_USER, getMe, SET_SUCCESS_ERROR_POPUP } from '../../store/actions';
import { APP_MESSAGES } from '../../utils/constants';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import UserInfoForm from '../AppForms/UserInfoForm';
import SocialMediaProfile from '../AppForms/SocialMediaProfile';

const validationSchema = Yup.object({
    first_name: Yup.string().trim().required(APP_MESSAGES.emptyEditFirstNameMsg),
    last_name: Yup.string().trim().required(APP_MESSAGES.emptyEditLastNameMsg),
    bio: Yup.string().trim(),
    image: Yup.string(),
    twitter_url: Yup.string().trim(),
    tiktok_url: Yup.string().trim(),
    insta_url: Yup.string().trim(),
    snap_url: Yup.string().trim(),
    linkedin_url: Yup.string().trim(),
    fb_url: Yup.string().trim(),
    yt_url: Yup.string().trim(),
});


const ParentProfile = ({ UserDrawerClose, setStep }) => {
    const { user } = useSelector((state) => state.user);
    const theme = useTheme()
    const dispatch = useDispatch()
    const [imageChange, setImageChange] = useState(null);
    const [showLoader, setShowLoader] = useState(false)

    const handleSave = async (values, actions) => {
        setShowLoader(true)
        if (imageChange) {
            const API_URL = "/auth/change-profilePic"
            const formData = new FormData();
            formData.append('files', imageChange);
            const apiRes = await httpClient.post(API_URL, formData)
            if (apiRes && apiRes.ok) {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'success', message: "Your bio has successfully been updated." }
                })
            } else {
                setShowLoader(false)
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: apiRes.message }
                })
            }
        }
        const postData = { ...values }
        const apiRes = await httpClient.post("/auth/edit-profile", postData)
        if (apiRes && apiRes.ok) {
            setShowLoader(false)
            dispatch({ type: SET_USER, data: apiRes.user })
            dispatch(getMe())
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: apiRes.message }
            })
            UserDrawerClose()
            setStep(0);
        } else {
            setShowLoader(false)
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: apiRes.message }
            })
        }
    }
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            bio: "",
            twitter_url: "",
            tiktok_url: "",
            insta_url: "",
            snap_url: "",
            linkedin_url: "",
            fb_url: "",
            yt_url: "",
            user_type: ""
        },
        validationSchema: validationSchema,
        onSubmit: handleSave
    });

    useEffect(() => {
        if (user) {
            formik.setFieldValue('first_name', user.first_name);
            formik.setFieldValue('last_name', user.last_name);
            formik.setFieldValue('email', user.email);
            formik.setFieldValue('mobile', user.mobile);
            formik.setFieldValue('bio', user.bio);
            formik.setFieldValue('image', user.image);
            formik.setFieldValue('user_type', user.user_type);
            formik.setFieldValue('twitter_url', user.twitter_url || "");
            formik.setFieldValue('tiktok_url', user.tiktok_url || "");
            formik.setFieldValue('insta_url', user.insta_url || "");
            formik.setFieldValue('snap_url', user.snap_url || "");
            formik.setFieldValue('linkedin_url', user.linkedin_url || "");
            formik.setFieldValue('fb_url', user.fb_url || "");
            formik.setFieldValue('yt_url', user.yt_url || "");
        }
    }, [user, formik.setFieldValue]);

    const handleImageChange = (e) => {
        setImageChange(e.target.files[0])
        const file = e.target.files[0];
        if (file) {
            formik.setFieldValue('image', URL.createObjectURL(file));
        }
    };
    const EditIconWrapper = styled(EditIcon)`
    position: absolute;
    top: 58px;
    right: 5px;
    color: #ffffff;
    background-color: #000000;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2 !important;;
`;
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        background: '#fff',
                        borderRadius: '5px',
                        // p: 3,
                        '& .MuiOutlinedInput-root': {
                            border: '1px solid #dfe0df',
                            boxShadow: '4px 5px 11px rgba(162, 188, 244, 0.4)',
                            background: '#fff',
                            '& .MuiOutlinedInput-input': {
                                background: 'transparent',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '0',
                                boxShadow: 'none',
                            },
                        },
                        '& .MuiFormControl-root .MuiFormControl-root': {
                            boxShadow: 'none',
                        },
                        span: {
                            "display": "block",
                            "fontSize": "37px",
                            "fontWeight": "bold",
                            "lineHeight": "99px",
                            "textAlign": "center",
                            "height": "100px",
                            "width": "100px",

                        },
                        figure: {
                            "margin": "0",
                            "height": "100px",
                            "width": "100px",
                            "minWidth": "50px",
                            "borderRadius": "50rem",
                            "position": "relative",
                            "overflow": "hidden",
                            "border": "3px solid #fff",
                            "boxShadow": " 0 0 15px rgba(0, 0, 0, 0.25)",
                            left: '50%',
                            transform: 'translate(-50%)',
                        },
                        "#userImg": {
                            width: "100%",
                            height: "100%"
                        },
                        "#save": {
                            py: 1.5,
                            px: 3.5,
                            fontSize: 15,
                            fontWeight: 700,
                            mt: 2
                        }
                    }}>
                    {
                        user?.image ?
                            <Box sx={{ position: "relative" }}>
                                <figure>
                                    <label htmlFor="imageUpload">
                                        <img id="userImg" src={formik.values.image} alt="profile-img" />
                                        <EditIconWrapper />
                                    </label>
                                    <input
                                        id="imageUpload"
                                        name="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                </figure>
                            </Box> :
                            (
                                <figure>
                                    <label htmlFor="imageUpload">
                                        {formik.values.image ? (
                                            <img id="userImg" src={formik.values.image} alt="profile-img" />
                                        ) : (
                                            <>
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        "backgroundColor": "#000000",
                                                        "justifyContent": "center",
                                                        "margin": "0",
                                                        "minWidth": "100px",
                                                        "color": "#ffffff",
                                                        "borderRadius": "50rem",
                                                        "position": "relative",
                                                        "overflow": "hidden",
                                                        "border": "3px solid #fff",
                                                        "boxShadow": "0 0 15px rgba(0, 0, 0, 0.25)",
                                                        "textTransform": "uppercase",
                                                        "height": "100px",
                                                        "width": "100px",
                                                        'lineHeight': '97px',
                                                        'marginLeft': '-3px',
                                                        'marginRight': 'auto',
                                                        'marginTop': "-3px",
                                                        'fontSize': '2rem',
                                                        'cursor': 'pointer',
                                                    }}
                                                >
                                                    {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
                                                </Box>
                                                <EditIconWrapper />
                                            </>
                                        )}
                                        <input
                                            id="imageUpload"
                                            name="image"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                </figure>
                            )
                    }
                    <Box>
                        <Typography variant='h3' sx={{ mt: 2 }}>My Information</Typography>
                        <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                    </Box>
                    <Box sx={{ backgroundColor: "transparent" }}>
                        <Box className="guardian-form-box" sx={{
                            '&.guardian-form-box div': {
                                maxWidth: '100%',
                                border: '0',
                                borderRadius: '0',
                            }
                        }}>
                            <UserInfoForm formik={formik} isEmailPhoneDisabled={true} />
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    formik.touched.bio && formik.errors.bio
                                )}
                                sx={{
                                    margin: '8px 0'
                                }}
                            >
                                <TextField
                                    id="bio"
                                    name="bio"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.bio}
                                    // label="Enter Bio"
                                    placeholder='Enter Bio'
                                    error={Boolean(
                                        formik.touched.bio && formik.errors.bio
                                    )}
                                    sx={{ boxShadow: theme.shadows[1] }}
                                    multiline
                                    rows={6}
                                />
                                {Boolean(
                                    formik.touched.bio && formik.errors.bio
                                ) && (
                                        <FormHelperText error>
                                            {formik.errors.bio}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                            <Box>
                                <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                                <Typography variant='h3' sx={{ mt: 2 }}>Your Social Accounts</Typography>
                            </Box>
                            <SocialMediaProfile formik={formik} />
                        </Box>

                        <Button id="save" fullWidth variant="contained" type="submit">{showLoader ? "Loading..." : "Save"}</Button>
                    </Box>
                </Box>
            </form >
        </>
    );
}

export default ParentProfile;
