import { Box, Typography, useTheme } from "@mui/material"
import { useSelector } from "react-redux";
import SuccessCheck from "../../../assets/images/success_check.png";

const EventRegisterSuccess = ({ title, closeSuccessDrawer }) => {

  const theme = useTheme();
  const state = useSelector((state) => state);
  const orgDetails = state?.org?.org?.data;

  const closeLabelStyle = {
    fontWeight: 600,
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    color: orgDetails?.primary_color,
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={1}
      width={1}
      p={3}
    >
      <Typography
        variant="h3"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <Box sx={{ my: 6 }}>
        <img src={SuccessCheck} style={{ maxWidth: 80 }} />
      </Box>
      <Typography
        variant="p"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontWeight: 500,
          textAlign: "center",
          fontFamily: "Poppins",
        }}
        onClick={closeSuccessDrawer}
      >
        Tap outside of this drawer or <span style={closeLabelStyle}>Close</span> to continue.
      </Typography>
    </Box>
  )
}

export default EventRegisterSuccess;
