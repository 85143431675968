import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import CustomTextField from "../../components/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TOAST,
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import styled from "@emotion/styled";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import httpClient from "@utils/httpClient";
import { useNavigate } from "react-router";
import { Tournament_Paid_Roster_Athlete_List } from "../../routes/constants";
import { useState } from "react";
import SuccessPopup from "../../components/Common/SuccessPopup";
import { ReactComponent as PoweredByStripe } from "../../assets/images/svg/Powered-by-Stripe.svg";
import { formatCurrency } from "../../utils/functions";

const CheckoutForm = ({
  handleClose,
  formData,
  type,
  afterPayemntMethod,
  payAmount,
}) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const cardElementOptions = {
    hidePostalCode: true,
    required: true,
  };
  const StyledCardElement = styled(CardElement)`
    border: 1px solid #000000;
    padding: 15px;
    font-size: 18px;
    color: #000000;
    background-color: #fafafa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid #bdbdbd;
    height: 48px;
    width: 100%,

    ::placeholder {
      color: #999999;
    }

    :focus,
    :hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    :invalid {
      color: #ff0000;
    }
  `;

  // const validationSchema = Yup.object({
  //   cardName: Yup.string().trim(),
  //   // firstName: Yup.string().trim().required("First Name is required"),
  //   // lastName: Yup.string().trim().required("Last Name is required"),
  //   // email: Yup.string()
  //   //   .trim()
  //   //   .required("Email is required")
  //   //   .email("Must be a valid email"),
  //   // address: Yup.string().required("Address is required"),
  //   // city: Yup.string().required("City is required"),
  //   // state: Yup.string().required("State is required"),
  //   // zip: Yup.string().max(10).required("Zip Code is required"),
  // });

  const handleSubmit = async (values) => {
    if (!stripe || !elements) {
      return;
    }

    let cardElement = elements.getElement(CardElement);
    const result = await stripe.confirmCardPayment(formData?.clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    dispatch({ type: SET_LOADER, data: true });
    if (result.error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: result?.error?.message || "Invalid Card",
        },
      });
      dispatch({ type: SET_LOADER, data: false });
    } else {
      setTimeout(() => {
        afterPayemntMethod();
      }, 3000);
    }
    dispatch({ type: SET_LOADER, data: false });
  };

  const handleSuccessModalClose = () => {
    handleClose(true);
    if (type === "package") {
      return;
    }
    if (type === "team") {
      navigate("/tournament/add-player", {
        state: { teamId: formData?.team_id ?? "" },
      });
    }
    if (type === "athlete") {
      navigate(Tournament_Paid_Roster_Athlete_List, {
        replace: true,
        state: {
          teamName: formData?.title,
          teamId: formData?.team_id ?? "",
        },
      });
    }
  };

  return (
    <>
      <Box sx={{ width: isMobile ? "315px" : "350px", margin: "0 auto" }}>
        <Box sx={{ width: isMobile ? "120px" : "160px" }}>
          <PoweredByStripe />
        </Box>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Box
          width={1}
          sx={{ mb: 3, mt: 3 }}
          display="flex"
          flexDirection="column"
        >
          <Box width={1}>
            <CustomInputLabel htmlFor="card-name">
              Name on the card
            </CustomInputLabel>
            <CustomTextField
              id="card-name"
              name="cardName"
              placeholder="Enter card name..."
              variant="outlined"
              size="small"
              required={true}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.cardName}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "12px 14px !important",
                },
                width: "100%",
              }}
            />
            {/* {Boolean(formik.touched.cardName && formik.errors.cardName) && (
                <FormHelperText error>{formik.errors.cardName}</FormHelperText>
              )} */}
          </Box>
          <Box width={1} mt={3}>
            <CustomInputLabel>Card Number</CustomInputLabel>
            <StyledCardElement options={cardElementOptions} />
          </Box>
        </Box>

        <Box sx={{ py: 3, display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2, mr: 2 }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              // type="submit"
              onClick={handleSubmit}
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
            >
              Pay {payAmount ? formatCurrency(payAmount) : ""}
            </Button>
          </Box>
        </Box>
        {/* </form> */}
      </Box>
    </>
  );
};

const ProductStripePayment = ({
  open,
  handleClose,
  formData,
  type,
  handleChange,
  afterPayemntMethod,
  payAmount,
}) => {
  const stripePromise = loadStripe(formData.publishableKey);
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        open={open}
        handleClose={handleClose}
        formData={formData}
        type={type}
        handleChange={handleChange}
        afterPayemntMethod={afterPayemntMethod}
        payAmount={payAmount}
      />
    </Elements>
  );
};

export default ProductStripePayment;
