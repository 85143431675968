
import { Box, Typography, Grid } from '@mui/material'

const Commerce = () => {
    return (
        <>
            <Grid
                container
                sx={{ pt: 2 }}
            >
                <Box>
                    <Typography variant='h2'>Commerce Page</Typography>
                </Box>
            </Grid>
        </>
    )

}

export default Commerce;
