import * as actionTypes from '@store/actions'

export const initialState = {
    productCategories: [],
    prodcutList: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRODUCTS: {
            return {
                ...state,
                prodcutList: action.data,
            }
        }
        case actionTypes.SET_PRODUCT_CATEGORY: {
            return {
                ...state,
                productCategories: action.data,
            }
        }
        case actionTypes.ADD_PRODUCT_CATEGORY: {
            return {
                ...state,
                productCategories: [action.data, ...state.productCategories],
            }
        }
        // case actionTypes.EDIT_PLAYER_EVENT: {
        //     const events = [...state.events]
        //     const findIndex = events.findIndex((x) => x.id === action.data.id)
        //     events[findIndex] = action.data
        //     return {
        //         ...state,
        //         events,
        //     }
        // }

        default: {
            return state
        }
    }
}

export default reducer
