import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import ListIcon from '@mui/icons-material/List';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from "react-router";
import AddRoaster from "../../components/Tournament/AddRoaster";
import { Admin_Tournament_PAGE, Tournament_Paid_Roster_Athlete_List, Tournament_Roster_Athlete_List, Tournament_Teams_Page } from "../../routes/constants";
import { SET_LOADER, SET_TOAST, getAthleteTemplateAction, saveRosterAction, uploadAthleteInfoAction, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { saveAs } from "file-saver";
import SuccessErrorPopup from "../../components/Common/SuccessErrorPopup";

const TournamentAddPlayer = () => {
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [rosterData, setRosterData] = useState([]);
  const [csvFileName, setCSVFileName] = useState("");
  const [isRosterAdded, setRosterAdded] = useState(false);

  const addPlayerOptions = [
    {
      num: 1,
      title: "Bulk Add Roster",
      message: "Add players in bulk by downloading, filling out parent and athlete information, then re-uploading the template below."
    },
    {
      num: 2,
      title: "Manually Add Roster",
      message: "Add parent and athlete information individually by clicking the button bellow."
    },
    // {
    //   num: 3,
    //   title: "Add Registered Users",
    //   message: "Search for users that have already registered with the Gridiron platform."
    // },
  ]
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const teamId = location?.state?.teamId ?? "";
  const rosterCount = location?.state?.rosterCount ?? 0;

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const uploadCSV = async (e) => {
    let value = e?.target?.files[0];
    let fileName = value?.name;

    if (!value) {
      // dispatch({
      //   type: SET_TOAST,
      //   data: {
      //     type: "error",
      //     message: "Not able to upload file, please try again with correct file",
      //   },
      // });
      return;
    }
    setCSVFileName(fileName);
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let formData = new FormData();
    formData.append("files", value ?? "");
    formData.append("type", "FilmRoom");
    formData.append("fileType", "Thumbnail");
    formData.append("userId", state?.user?.user?.id ?? "");
    formData.append("team_id", teamId ?? "");
    try {
      dispatch({ type: SET_LOADER, data: true });
      let response = await uploadAthleteInfoAction(dispatch, params, formData);
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response?.message ?? "CSV file uploaded successfully", }
        })
        setRosterData(response?.data ?? []);
        setRosterAdded(true);
        // navigate(Tournament_Roster_Athlete_List, { state: { dataList: response?.data ?? [], teamId } });
      } else {
        setRosterData([]);
        setRosterAdded(false);
        showError(response);
      }
    } catch (error) {
      setRosterData([]);
      setRosterAdded(false);
      showError(error);
    }
  }

  const showError = (response) => {
    dispatch({
      type: SET_SUCCESS_ERROR_POPUP,
      data: { open: true, type: 'error', message: response?.message ?? "Something went wrong! Try again" }
    })
  }

  const downloadTemplate = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    dispatch({ type: SET_LOADER, data: true });
    let response = await getAthleteTemplateAction(params);
    dispatch({ type: SET_LOADER, data: false });
    if (response?.ok) {
      saveAs(response?.data, "roster_template");
    }
  }

  const handleModalClose = () => {
    setShowAddEditModal(false);
  }

  const handleViewRoster = () => {
    navigate(Tournament_Paid_Roster_Athlete_List, { state: { teamId, rosterCount } });
  }

  const handleSaveRoster = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let paymentData = {
      team_id: teamId ?? "",
      athlete_count: rosterData?.length ? rosterData?.length : 0,
      athleteData: rosterData ?? []
    }
    let response = await saveRosterAction(dispatch, params, paymentData);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response?.message ?? "Success!" }
      })
      navigate(Tournament_Roster_Athlete_List, { state: { dataList: [], teamId } });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong! Try again" }
      })
    }
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
      <Box
        sx={{
          width: "95%",
          position: "relative",
          backgroundColor: theme?.palette?.background?.paper,
          boxShadow: theme?.palette?.boxShadow?.tableContainer,
          borderRadius: 1,
          border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
        }}
        mt={4}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" px={3.5} py={2.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                fontWeight={700}
                fontSize={20}
                color={theme?.palette?.text?.containerTextLabel}
                mt={1}
              >
                Team Registration: Add Players to Roster
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column"
                sx={{
                  alignItems: "end",
                  [theme.breakpoints.down('md')]: {
                    alignItems: "self-start",
                  }
                }}
              >
                <Box
                  sx={{
                    maxWidth: "400px",
                    height: "55px",
                    border: `1.6px solid ${theme?.palette?.text?.containerTextLabel}`,
                    borderRadius: "35px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                    background: "white",
                    [theme.breakpoints.down('md')]: {
                      maxWidth: "330px",
                      height: "auto",
                    },
                  }}
                  onClick={handleViewRoster}
                >
                  <Box
                    sx={{
                      minWidth: "50px",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      background: theme?.palette?.text?.containerTextLabel,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "1px 0 1px 2px",
                      padding: "0 15px",
                      [theme.breakpoints.down('md')]: {
                        minWidth: "39px",
                        width: "39px",
                        height: "39px",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      fontSize={16}
                      color={"white"}
                    >
                      {rosterCount}
                    </Typography>
                  </Box>
                  <Box sx={{ ml: 2, mr: 3 }}>
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      color={theme?.palette?.dark?.darkTextSubHeader}
                      sx={{
                        fontSize: "22px",
                        [theme.breakpoints.down('md')]: {
                          fontSize: "18px",
                        },
                        [theme.breakpoints.down('sm')]: {
                          fontSize: "16px",
                        },
                      }}>
                      View Current Roster

                    </Typography>
                  </Box>
                  <Box sx={{ mt: 0.5, mr: 1 }}>
                    <ListIcon color="gray" fontSize="medium" sx={{ opacity: 0.4 }} />
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    textDecoration: "underline",
                    cursor: "pointer",
                    ml: 1,
                    mt: 1,
                    maxWidth: "350px",
                    width: "100%",
                    [theme.breakpoints.down('md')]: {
                      maxWidth: "100%",
                    },
                  }}
                  onClick={() => {
                    navigate(Tournament_Roster_Athlete_List, { state: { dataList: [], teamId, rosterCount } });
                  }}
                >
                  View Unpaid Roster List
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mt={1}
        ></Box>
        <Grid container spacing={2} sx={{ mt: 3, px: 5.5 }}>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={13}
                color={theme?.palette?.text?.containerTextLabel}
                sx={{ mt: 2 }}
              >
                There are 3 ways to add users to your roster.
              </Typography>
              <ul style={{ margin: "5px 0" }}>
                <li>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize={13}
                    color={theme?.palette?.text?.containerTextLabel}
                  >
                    Add players in bulk
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize={13}
                    color={theme?.palette?.text?.containerTextLabel}
                  >
                    Manually add parents and athletes
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize={13}
                    color={theme?.palette?.text?.containerTextLabel}
                  >
                    Search for, and add athletes who’ve already registered.
                  </Typography>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 4, px: 5.5 }}>
          {addPlayerOptions?.map((opt) => (
            <>
              <Grid item xs={12} md={6} key={opt?.num}>
                <Box display="flex">
                  <Box display="flex" flexDirection="column" sx={{
                    [theme.breakpoints.down('md')]: {
                      marginBottom: '2rem'
                    },
                  }}>
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "100%",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `2px solid ${theme?.palette?.dark?.dark3}`,
                        alignSelf: "center",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                        // mr: 5,
                        [theme.breakpoints.down('md')]: {
                          marginRight: 'auto'
                        },
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.dark?.dark3}
                      >
                        {opt?.num}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      fontSize={20}
                      color={theme?.palette?.dark?.darkTextSubHeader}
                      sx={{ mt: 2, mb: 2.5 }}
                    >
                      {opt?.title}
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight={500}
                      fontSize={14}
                      color={theme?.palette?.text?.containerTextLabel}
                    >
                      {opt?.message}
                    </Typography>
                    {opt?.num === 1 &&
                      <Typography
                        variant="h6"
                        sx={{
                          paddingX: 3,
                          paddingY: 1.2,
                          color: theme?.palette?.text?.containerTextLabel,
                          border: `2px solid ${theme?.palette?.text?.containerTextLabel}`,
                          fontWeight: 700,
                          alignSelf: "flex-start",
                          mt: 2.5,
                          mb: 3.5,
                          borderRadius: "5px",
                          cursor: "pointer",
                          background: "white"
                        }}
                        onClick={downloadTemplate}
                      >
                        Download Roaster Template
                      </Typography>
                    }
                    {opt?.num === 1 &&
                      <Box>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Bulk Add Roaster
                        </CustomInputLabel>
                        <FormControl
                          fullWidth
                          sx={{
                            maxWidth: 350,
                            width: "100%",
                            "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                              height: "41px",
                              padding: "12.5px 10px",
                            },
                            "& .MuiIconButton-root": {
                              background: "transparent",
                              borderRadius: "0 5px 5px 0",
                              left: 16,
                            },
                          }}
                        >
                          <InputLabel
                            htmlFor="upload-file"
                            shrink={false}
                            sx={{
                              fontFamily: "'Poppins', sans-serif, Arial",
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            Upload roaster...
                          </InputLabel>
                          <OutlinedInput
                            id="upload-file"
                            type={"file"}
                            onChange={(e) => uploadCSV(e)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  size="large"
                                  htmlFor="upload-file"
                                  component="label"
                                >
                                  <Box
                                    sx={{
                                      width: 85,
                                      height: 47,
                                      backgroundColor:
                                        theme?.palette?.text?.containerTextLabel,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    htmlFor="upload-file"
                                  >
                                    <UploadFileIcon sx={{ fontSize: 20, color: "white" }} />
                                  </Box>
                                </IconButton>
                              </InputAdornment>
                            }
                            sx={{
                              "& ::file-selector-button": {
                                opacity: 0,
                                display: "none",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: 350,
                                boxShadow: "none",
                                opacity: 0,
                              },
                              "& .MuiInputBase-input": {
                                padding: "13.5px 10px !important",
                              },
                            }}
                            inputProps={{
                              style: {
                                opacity: 0,
                              },
                              accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            }}
                          />
                        </FormControl>
                        {csvFileName ?
                          <Typography
                            variant="h4"
                            fontWeight={500}
                            fontSize={13}
                            color={theme?.palette?.text?.containerTextLabel}
                            sx={{ mt: 1, fontStyle: "italic" }}
                          >
                            {csvFileName}
                          </Typography>
                          : null}
                      </Box>
                    }
                    {opt?.num === 2 &&
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        sx={{ paddingX: 3.5, paddingY: 1.2, mt: 2.5, alignSelf: "flex-start" }}
                        onClick={() => setShowAddEditModal(true)}
                      >
                        Add Manually
                      </Button>
                    }
                    {/* {opt?.num === 3 &&
                      <Box alignSelf="flex-start" mt={2.5}>
                        <FormControl
                          fullWidth
                          sx={{
                            maxWidth: 300,
                            width: "100%",
                            "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                              height: "41px",
                              padding: "10.5px 10px",
                            },
                            "& .MuiIconButton-root": {
                              background: "transparent",
                              borderRadius: "0 5px 5px 0",
                              left: 16,
                            },
                          }}
                        >
                          <InputLabel
                            shrink={false}
                            sx={{
                              fontFamily: "'Poppins', sans-serif, Arial",
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            Search registered users...
                          </InputLabel>
                          <OutlinedInput
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  size="large"
                                  component="label"
                                >
                                  <Box
                                    sx={{
                                      width: 70,
                                      height: 47,
                                      backgroundColor:
                                        theme?.palette?.text?.containerTextLabel,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    htmlFor="upload-file"
                                  >
                                    <SearchIcon sx={{ fontSize: 20, color: "white" }} />
                                  </Box>
                                </IconButton>
                              </InputAdornment>
                            }
                            sx={{
                              "& ::file-selector-button": {
                                opacity: 0,
                                display: "none",
                              },
                              "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: 350,
                                boxShadow: "none",
                                opacity: 0,
                              },
                              "& .MuiInputBase-input": {
                                padding: "13.5px 10px !important",
                              },
                            }}
                            inputProps={{
                              style: {
                                opacity: 0,
                              },
                            }}
                          />
                        </FormControl>
                      </Box>
                    } */}
                  </Box>
                  {opt?.num !== 2 &&
                    <Box
                      sx={{
                        width: "1px",
                        background: "gray",
                        opacity: 0.3,
                        mx: 3,
                        [theme.breakpoints.down('md')]: {
                          display: 'none',
                        },
                      }}></Box>
                  }
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
        <Box display="flex" mt={8} mb={2} px={4.5} py={2} justifyContent="space-between" alignItems="center">
          {/* <Box display="flex" alignItems="center" mt={5} mb={3}> */}
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.3, paddingY: 1 }}
            color="inherit"
            onClick={() => navigate(Admin_Tournament_PAGE)}
          >
            Cancel
          </Button>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                paddingY: 1.2,
                paddingX: 3.7,
                color: theme?.palette?.text?.containerTextLabel,
                border: `2px solid ${theme?.palette?.text?.containerTextLabel}`,
                fontWeight: 700,
                alignSelf: "flex-start",
                borderRadius: "5px",
                cursor: "pointer",
                background: "white",
              }}
              onClick={() => {
                navigate(-1);
                // navigate(Tournament_Teams_Page, { state: { isShowTeamManagerRegistrationForm: true } });
              }}
            >
              Back
            </Typography>
            {/* <Box>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2, ml: 2.5 }}
              color="inherit"
              onClick={() => navigate(Tournament_Teams_Page)}
            >
              Cancel
            </Button>
          </Box> */}
            {isRosterAdded &&
              <Button
                size="small"
                variant="contained"
                sx={{
                  paddingY: 1.2,
                  paddingX: 2,
                  background: theme?.palette?.text?.containerTextLabel,
                  ml: 3,
                  width: "200px"
                }}
                onClick={handleSaveRoster}
              >
                Save Roster
              </Button>
            }
          </Box>
          {/* </Box> */}
        </Box>
        {showAddEditModal && (
          <AddRoaster
            open={showAddEditModal}
            handleClose={handleModalClose}
            teamId={teamId ?? ""}
            setRosterData={(data) => {
              setRosterAdded(true);
              setRosterData(data ?? []);
            }}
          />
        )}
      </Box>
    </Box >
  );
};

export default TournamentAddPlayer;
