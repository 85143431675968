import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import httpClient from "@utils/httpClient";
import { useDispatch } from "react-redux";

const AddSketchplayBucksConfirmModal = ({
  open,
  handleClose,
  handleUpdateVolunteerHour,
  isShowReason = false,
  selectedReason = "",
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const validationSchema = Yup.object({
    reason: Yup.string().trim().required("Reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: selectedReason ?? "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleUpdateVolunteerHour(values.reason);
    },
  });

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ p: 0 }}>
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            py={3}
            ml={2}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {isShowReason ? "Reason" : " Household Sketchplay Bucks"}
          </Typography>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </DialogTitle>
        <DialogContent
          sx={{
            pt: 4,
            px: 4,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            pb: 6,
          }}
        >
          {isShowReason ? null : (
            <Typography
              sx={{ py: 3 }}
              fontFamily={"Poppins"}
              fontSize={16}
              fontWeight={600}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Are you sure you want to update the Volunteer Hours or Foundation
              Credits?
            </Typography>
          )}
          <CustomTextField
            name="reason"
            placeholder={"Enter reason..."}
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.reason}
            disabled={isShowReason}
            sx={{
              width: "100%",
              mt: isShowReason ? 3 : 0,
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                boxShadow: "none",
              },
            }}
            multiline
            rows={4}
          />
          {Boolean(formik.touched.reason && formik.errors.reason) && (
            <FormHelperText sx={{ alignSelf: "flex-start" }} error>
              {formik.errors.reason}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions sx={{ pr: 4, pb: 3 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            {isShowReason ? "Close" : "Cancel"}
          </Button>
          {isShowReason ? null : (
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
              type="submit"
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSketchplayBucksConfirmModal;
