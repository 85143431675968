import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { Grid, Box, useTheme } from '@mui/material'
import SegmentSharpIcon from '@mui/icons-material/SegmentSharp';
import Logo from '@components/Logo'
import { Apply_Scholarship_Program, Parent_Apply_Scholarship_Program, DASHBOARD_PAGE_ATHLETE, DASHBOARD_PAGE_TEAM_MANAGER_COACH, DASHBOARD_PAGE_PARENT } from '../../routes/constants';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { getAppMessageAction } from '../../store/actions';
import { useMutation } from 'react-query';
import hiddenMenuIcon from "../../assets/images/svg/feather-menu.svg";

const Header = ({ leftDrawer, openUserDrawer, leftDrawerToggle }) => {
  // const navigate = useNavigate()
  const theme = useTheme();
  const { user } = useSelector((state) => state.user || {})
  const state = useSelector((state) => state || {})
  const goBackPath = useSelector((state) => state.theme.goBackPath)
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [appMessage, setAppMessage] = useState();
  const { mutateAsync: loadAppMessages } = useMutation(getAppMessageAction);

  const isNonAdminUser = user?.user_type === "parent" || user?.user_type === "player" || user?.user_type === "coach" || user?.user_type === "teamcoach" || user?.user_type === "teammanager" || user?.user_type === "tournamentmanager";
  const isShowMessage = location?.pathname === DASHBOARD_PAGE_PARENT || location?.pathname === DASHBOARD_PAGE_ATHLETE || location?.pathname === DASHBOARD_PAGE_TEAM_MANAGER_COACH;
  const isAlreadyAppliedForScholarship = state?.user?.user?.scholarship && state?.user?.user?.scholarship?.length > 0 ? true : false;
  const warningMsgBgColor = "#FFF6F6";
  const warningMsgBorderColor = "#BA0000";
  const generalMsgBgColor = "#EFF7FF";
  const generalMsgBorderColor = "#7FBEFD";

  useEffect(() => {
    getAppMessages();
  }, []);

  const getAppMessages = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    try {
      let response = await loadAppMessages({ params, dispatch });
      if (response?.ok) {
        setAppMessage(response?.data);
      }
    } catch (error) {
      // dispatch({
      //   type: SET_TOAST,
      //   data: {
      //     type: "error",
      //     message: error?.message ?? "Something went wrong",
      //   },
      // });
    }
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={2}>
        {/* {goBackPath && (
                    <ArrowBackIosNewIcon
                        onClick={() => navigate(goBackPath)}
                        className="pointer"
                    />
                )} */}
        {!leftDrawer &&
          <Box sx={{ height: '25px', width: '25px' }} onClick={leftDrawerToggle} className="pointer">
            <img
              src={hiddenMenuIcon}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        }
      </Grid>
      {isNonAdminUser && appMessage?.is_active && isShowMessage ?
        <Grid item xs={6.5} container justifyContent="center" className="header-mesage"
          sx={{
            [theme.breakpoints.down('md')]: {
              display: "none"
            }
          }}>
          <Box
            hidden={
              appMessage?.format_type === "scholarship_apply" ?
                state?.user?.user?.scholarship &&
                  state?.user?.user?.scholarship?.length > 0 ?
                  state?.user?.user?.scholarship[0]?.status === "Denied" ? false : true
                  : false
                : false
            }
            sx={{
              background: appMessage?.color,
              border: `1px solid ${appMessage?.color === warningMsgBgColor ? warningMsgBorderColor : generalMsgBorderColor}`,
              color: "black",
              borderRadius: "5px",
              fontSize: "13px",
              fontWeight: 500,
              padding: "5px 8px",
              color: appMessage?.color === warningMsgBgColor ? warningMsgBorderColor : "black",
              cursor: appMessage?.format_type === "scholarship_apply" ? "pointer" : appMessage?.link ? "pointer" : "initial",
              minHeight: "40px",
              maxHeight: "55px",
              overflowY: "auto",
              fontSize: "13px",
              [theme.breakpoints.down('sm')]: {
                maxHeight: "inherit",
                borderRadius: "0",
              }
            }}
            onClick={() => {
              if (appMessage?.format_type === "scholarship_apply") {
                if (user?.user_type === "parent") {
                  navigate(Parent_Apply_Scholarship_Program, { state: { isNewEntry: true } })
                } else {
                  navigate(Apply_Scholarship_Program, { state: { isNewEntry: true } })
                }
              } else {
                if (appMessage?.link) {
                  window.open(appMessage?.link);
                }
              }
            }}
          >
            <span style={{ fontWeight: 700 }}>{appMessage?.title}:</span> {appMessage?.desc ?? "N/A"}
          </Box>
        </Grid> : null
      }
      <Grid item xs={2} container justifyContent="flex-end" sx={{ ml: 'auto', }}>
        <Box onClick={openUserDrawer} sx={{
          ml: 'auto',
          '& .user-login-info figure': {
            height: '45px',
            width: '45px',
            minWidth: '45px',
            background: '#2a2e3e',
            margin: 0,
            color: '#fff',
            fontSize: '22px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 600,
            borderRadius: '50px',
            marginRight: '0.6rem',
            position: 'relative',
            overflow: 'hidden',
          },
          '& .user-login-info figure img': {
            width: '100%',
            height: 'auto',
            borderRadius: '50px',
          },
          '& .user-login-info': {
            display: 'flex',
            alignItems: 'center'
          }
        }} className='pointer'>
          <Box className='user-login-info' sx={{
            '& .user-info h5': {
              fontSize: '0.85rem',
              color: '#212121',
              fontWeight: 600,
              lineHeight: 1.167,
              letterSpacing: '0em',
              margin: '0 0 0.2rem 0',
              whiteSpace: 'nowrap',
            },
            '& .user-info p': {
              margin: 0,
              fontSize: '0.8rem'
            },
            "#userImg": {
              width: "100%",
              height: "100%",
              objectFit: "cover"
            },
            span: {
              "display": "block",
              "fontSize": "20px",
              "fontWeight": "bold",
              "lineHeight": "45px",
              "textAlign": "center",
            }
          }}>
            <Box className='user-info' sx={{ paddingRight: '15px', '& p': { textAlign: 'end' } }}>
              <h5>{user?.first_name} {user?.last_name}</h5>
              <p>{user?.user_type?.charAt(0)?.toUpperCase() + user?.user_type?.slice(1)}</p>
            </Box>
            {user?.image ?
              <figure><img id="userImg" src={user.image} alt="profile-img" /></figure> :
              <Box component="span"
                sx={{
                  "backgroundColor": "#000000",
                  "justifyContent": "center",
                  "margin": "0",
                  "height": "50px",
                  "width": "50px",
                  "minWidth": "50px",
                  "color": "#ffffff",
                  "borderRadius": "50rem",
                  "position": "relative",
                  "overflow": "hidden",
                  "border": "3px solid #fff",
                  "boxShadow": " 0 0 15px rgba(0, 0, 0, 0.25)",
                  "textTransform": "uppercase"
                }}>{`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
              </Box>}
          </Box>
        </Box>
      </Grid>
    </Grid >
  )
}

Header.propTypes = {
  drawerToggle: PropTypes.func,
}

export default Header
