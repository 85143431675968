import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
  FormControlLabel,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubOrg } from "../../store/actions";
import CustomSwitch from "../Common/Inputs/Switch";

const SubOrgTeamsForm = ({ handleClose, data }) => {
  const [subOrgsList, setSubOrgs] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const subOrgTeams = state?.subOrgTeams;

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: 0,
      limit: 1000
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      setSubOrgs(response?.data?.data ?? []);
    }
  };

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const validationSchema = Yup.object({
    // Sub Org Details
    subOrgName: Yup.string().trim().required("Sub Org. Team Name is required"),
    state: Yup.string().trim().required("State is required"),
    city: Yup.string().trim().required("City is required"),
    zip: Yup.string().max(10).required("Zip Code is required"),
    address1: Yup.string().trim().required("Address Line 1 is required"),
    address2: Yup.string().trim().required("Address Line 2 is required"),
    orgMobile: Yup.string().min(10).required("Mobile is required"),
    // countryCode: Yup.string().required("Country Code is required"),
    subOrg: Yup.string().trim().required("Sub Org. is required"),
    orgCode: Yup.string().trim().required("Sub Org. Code is required"),
    websiteUrl: Yup.string().trim().required("Team website url is required"),
    order: Yup.string().trim().required("Order is required"),
    paymentDisable: Yup.bool().required(),
    price: Yup.string().trim().required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      subOrgName: data?.title ?? subOrgTeams?.subOrgDetails?.subOrgName ?? "",
      state: data?.address?.state ?? subOrgTeams?.subOrgDetails?.state ?? "",
      city: data?.address?.city ?? subOrgTeams?.subOrgDetails?.city ?? "",
      zip: data?.address?.zip_code ?? subOrgTeams?.subOrgDetails?.zip ?? "",
      address1:
        data?.address?.address ?? subOrgTeams?.subOrgDetails?.address1 ?? "",
      address2:
        data?.address?.Address2 ?? subOrgTeams?.subOrgDetails?.address2 ?? "",
      orgMobile: data?.contact_mobile ?? subOrgTeams?.subOrgDetails?.orgMobile ?? "",
      countryCode: data?.contact_countryCode ?? subOrgTeams?.subOrgDetails?.countryCode ? subOrgTeams?.subOrgDetails?.countryCode?.toUpperCase() : "",
      subOrg:
        data?.sub_organizations ?? subOrgTeams?.subOrgDetails?.subOrg ?? "",
      orgCode: data?.org_code ?? subOrgTeams?.subOrgDetails?.orgCode ?? "",
      websiteUrl: data?.org_website ?? subOrgTeams?.subOrgDetails?.websiteUrl ?? "",
      order: data?.order ?? subOrgTeams?.subOrgDetails?.order ?? 0,
      paymentDisable: data?.paymentDisable ?? subOrgTeams?.subOrgDetails?.paymentDisable ?? false,
      price: data?.price ?? subOrgTeams?.subOrgDetails?.price ?? 0
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // const formattedMobileNum = values?.orgMobile?.slice(values.countryCode.length);
      dispatch({
        type: "STEP_COUNT",
        data: 2,
      });
      dispatch({
        type: "SUB_ORG_DETAILS",
        data: { ...values },
      });
    },
  });

  useEffect(() => {
    if (subOrgTeams?.isAllStepsCompleted) {
      formik.resetForm();
    }
  }, [subOrgTeams?.isAllStepsCompleted]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} mt={0.001}>
        <Grid item md={6} xs={12}>
          <CustomInputLabel sx={inputLabelStyle} htmlFor="sub-org-name">
            SUB-ORGANIZATION's TEAM NAME
          </CustomInputLabel>
          <CustomTextField
            id="sub-org-name"
            name="subOrgName"
            placeholder="Enter sub-org. team name..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.subOrgName}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.subOrgName && formik.errors.subOrgName) && (
            <FormHelperText error>{formik.errors.subOrgName}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="city" sx={inputLabelStyle}>CITY</CustomInputLabel>
          <CustomTextField
            id="city"
            name="city"
            placeholder="Enter city..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.city}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.city && formik.errors.city) && (
            <FormHelperText error>{formik.errors.city}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="address-1" sx={inputLabelStyle}>ADDRESS</CustomInputLabel>
          <CustomTextField
            id="address-1"
            name="address1"
            placeholder="Enter address line 1..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address1}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.address1 && formik.errors.address1) && (
            <FormHelperText error>{formik.errors.address1}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="state" sx={inputLabelStyle}>STATE</CustomInputLabel>
          <CustomTextField
            id="state"
            name="state"
            placeholder="Enter state..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.state}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.state && formik.errors.state) && (
            <FormHelperText error>{formik.errors.state}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="address-2" sx={inputLabelStyle}>ADDRESS 2</CustomInputLabel>
          <CustomTextField
            id="address-2"
            name="address2"
            placeholder="Enter address line 2..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address2}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.address2 && formik.errors.address2) && (
            <FormHelperText error>{formik.errors.address2}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="state" sx={inputLabelStyle}>ZIP</CustomInputLabel>
          <CustomTextField
            id="zip"
            name="zip"
            //type="number"
            placeholder="Enter zip..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.zip}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.zip && formik.errors.zip) && (
            <FormHelperText error>{formik.errors.zip}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="org-mobile" sx={inputLabelStyle}>
            Mobile Number
          </CustomInputLabel>
          <Box
            sx={{
              "& .react-tel-input .form-control": {
                minWidth: "100%",
                fontWeight: 500,
                background: theme?.palette?.grey[50],
                height: "42px",
                width: "100%"
              },
            }}
          >
            <PhoneInput
              country={"us"}
              id="org-mobile"
              name="orgMobile"
              variant="outlined"
              placeholder="(702) 123-4567"
              onBlur={formik.handleBlur}
              value={formik.values.orgMobile}
              onChange={(value, country, e, formattedValue) => {
                formik.setFieldValue("countryCode", country?.countryCode || "");
                formik.setFieldValue("orgMobile", value || "");
              }}
              error={Boolean(
                formik.touched.orgMobile && formik.errors.orgMobile,
              )}
              size="sm"
              style={{
                width: "100%"
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "12px 14px",
                },
              }}
              disableDropdown={true}
              disableCountryCode={true}
              onlyCountries={["us"]}
            />
            {Boolean(formik.touched.orgMobile && formik.errors.orgMobile) && (
              <FormHelperText error>{formik.errors.orgMobile}</FormHelperText>
            )}
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="org-filter" sx={inputLabelStyle}>
            THIS SUB-ORGANIZATION's TEAM IS A CHILD OF:
          </CustomInputLabel>
          <Select
            name="subOrg"
            labelId="org-filter"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.subOrg}
            input={
              <OutlinedInput
                label=""
                placeholder="Select Sub-Org"
                sx={{ width: "100%", height: 45 }}
              />
            }
            MenuProps={MenuProps}
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                let selected = subOrgsList?.find((subOrg) => subOrg?.id === value);
                if (selected) {
                  return selected?.title;
                }
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Select Sub-Organization...
                  </Typography>
                );
              }
            }}
          >
            {subOrgsList?.map((orgType) => (
              <MenuItem key={orgType?.id} value={orgType?.id}>
                {orgType?.title}
              </MenuItem>
            ))}
          </Select>
          {Boolean(formik.touched.subOrg && formik.errors.subOrg) && (
            <FormHelperText error>{formik.errors.subOrg}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="org-code" sx={inputLabelStyle}>
            ORGANIZATIONAL CODE
          </CustomInputLabel>
          <CustomTextField
            id="org-code"
            name="orgCode"
            placeholder="Enter org. code..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.orgCode}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.orgCode && formik.errors.orgCode) && (
            <FormHelperText error>{formik.errors.orgCode}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="website-url" sx={inputLabelStyle}>
            TEAM WEBSITE URL
          </CustomInputLabel>
          <CustomTextField
            id="website-url"
            name="websiteUrl"
            placeholder="Enter website url..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.websiteUrl}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.websiteUrl && formik.errors.websiteUrl) && (
            <FormHelperText error>{formik.errors.websiteUrl}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="order" sx={inputLabelStyle}>
            ORDER
          </CustomInputLabel>
          <CustomTextField
            id="order"
            name="order"
            placeholder="Enter Order..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.order}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.order && formik.errors.order) && (
            <FormHelperText error>{formik.errors.order}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            control={
              <CustomSwitch
                name="paymentDisable"
                checked={formik.values.paymentDisable}
                onChange={formik.handleChange}
                size="sm"
                sx={{ ml: 1 }}
              />
            }
            label="PAYMENT DISABLE"
            labelPlacement="start"
            sx={{
              "&.MuiFormControlLabel-root": {
                ml: 0,
              },
              "& .MuiTypography-root": {
                color: theme?.palette?.text?.containerTextLabel,
                fontWeight: 400,
                fontFamily: "inherit",
              },
            }}
          />
          {Boolean(formik.touched.paymentDisable && formik.errors.paymentDisable) && (
            <FormHelperText error>{formik.errors.paymentDisable}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="price" sx={inputLabelStyle}>
            PRICE
          </CustomInputLabel>
          <CustomTextField
            id="price"
            name="price"
            type="number"
            placeholder="Enter Price..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.price}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.price && formik.errors.price) && (
            <FormHelperText error>{formik.errors.price}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          pt: 3,
          [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
          }
        }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{
            paddingX: 3.5, paddingY: 1.2, mx: 2,
            [theme.breakpoints.down('sm')]: {
              ml: 0,
            }
          }}
          color="inherit"
          onClick={() => handleClose(true)}
        >
          Close
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

export default SubOrgTeamsForm;
