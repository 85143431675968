import SocialIcon from "@assets/images/icons/social.png";
import DrawerMenu from "@components/DrawerMenu";
import Header from "@components/Header";
import Logo from "@components/Logo";
import NotificationPopup from "@components/NotificationPopup";
import PageLoader from "@components/PageLoader";
import ToastMessage from "@components/ToastMessage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ADMIN_USERS_PAGE,
  BUCKET_PAGE,
  COACHING_STAFF_PAGE,
  COACH_PLAYER_CONTENT_PAGE,
  DASHBOARD_PAGE,
  PLAYER_FILM_ROOM_PAGE,
  PLAYER_SOCIAL_WALL_PAGE,
  SUB_ORGANIZATIONS_PAGE,
  USERS_PAGE,
} from "@routes/constants";
import {
  LOGOUT_USER,
  SET_BUCKET,
  SET_DRAWER,
  getOrgData,
  SET_USER_DRAWER,
  getAppMessageAction,
  SET_TOAST,
} from "@store/actions";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ChangePassword from "../../components/ChangePassword";
import EditProfile from "../../components/EditProfile";
import { Home } from "@mui/icons-material";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  ROOT_PAGE,
  SUBSCRIPTION_PAGE,
  APPROVAL_PAGE,
  CALENDARS_PAGE,
  COMMUNITYS_PAGE,
  FILMROOM_PAGE,
  League_Page,
  MANAGEUSER_PAGE,
  Manage_Athlete_Page,
  Manage_Parent_Page,
  Manage_Parent_BY_ATHLETE_Page,
  Manage_COACH,
  PERFORMANCE_PAGE,
  PLAYER_PARENT_SUPPORT_PAGE,
  SUB_ORGANIZATIONS_TEAM_PAGE,
  SUPPORT_PAGE,
  TEAMS_PAGE,
  Tournament_Page,
  Team_Page,
  DASHBOARD_PAGE_PARENT,
  DASHBOARD_PAGE_COACH,
  DASHBOARD_PAGE_ATHLETE,
  League_Coach_Page,
  Camp_Page,
  Clinic_Page,
  Daily_Training_Page,
  LEAGUE_SUPERADMIN,
  PRODUCT_PAGE,
  Admin_Tournament_PAGE,
  Parent_Tounament_Page,
  Help_Page,
  Manage_Positions,
} from "@routes/constants";
import HelpIcon from "@mui/icons-material/Help";
import "./style.css";
import AthleteProfile from "../../components/EditProfile/AthleteProfile";
import ParentProfile from "../../components/EditProfile/ParentProfile";
import { ReactComponent as RugbyPlayer } from "../../assets/images/svg/rugby-player.svg";
import { ReactComponent as UserCheck } from "../../assets/images/svg/user-check.svg";
import { ReactComponent as SubOrgTeams } from "../../assets/images/svg/sub-org-teams.svg";
import { ReactComponent as SubOrgs } from "../../assets/images/svg/sub-org.svg";
import { ReactComponent as OrgAdmin } from "../../assets/images/svg/OrgAdmin.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/svg/check.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/svg/support.svg";
import { ReactComponent as FilmRoomIcon } from "../../assets/images/svg/filmroom.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/svg/calendar.svg";
import { ReactComponent as CommunityIcon } from "../../assets/images/dashboard-menu/icon-communication.svg";
import { ReactComponent as StarPerform } from "../../assets/images/svg/starperform.svg";
import { ReactComponent as LeagueIcon } from "../../assets/images/svg/league.svg";
import { ReactComponent as TeamsIcon } from "../../assets/images/svg/teams.svg";
import { ReactComponent as TournamentIcon } from "../../assets/images/svg/tournament-bracket.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/svg/settings.svg";
import SuccessErrorPopup from "../../components/Common/SuccessErrorPopup";
import SchoolIcon from "@mui/icons-material/School";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import {
  Parent_Apply_Scholarship_Program,
  Apply_Scholarship_Program,
  USER_WALLET_HISTORY_PAGE,
  CALENDAR_PAGE,
  DASHBOARD_PAGE_ADMIN,
  DASHBOARD_PAGE_TEAM_MANAGER_COACH,
  Org_Settings,
  Package_List,
  Reporting_Finance,
  Scholarship_Request,
  Team_Coach,
  Team_Manager,
  Tournament_Teams_Page,
  Manage_Grades,
  Manage_Divisions,
  Manage_Sprints,
} from "../../routes/constants";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ReactComponent as BarChartIcon } from "../../assets/images/dashboard-menu/icon-bar-chart.svg";
import { ReactComponent as ScholarshipProgramIcon } from "../../assets/images/dashboard-menu/icon-scholarship-program.svg";
import { ReactComponent as ReelPlayingIcon } from "../../assets/images/dashboard-menu/icon-reel-playing.svg";
import { ReactComponent as VideoCameraIcon } from "../../assets/images/dashboard-menu/icon-video-camera.svg";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useMutation } from "react-query";
import hiddenMenuIcon from "../../assets/images/svg/feather-menu-white.svg";
import { getMe } from "../../store/actions";
import BoughtPackages from "../../components/packages/BoughtPackages";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { formatCurrency } from "../../utils/functions";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  }),
);

const MainLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tournamentActiveLink = location?.state?.activeLink ?? "";
  const [openLeftDrawer, setOpenLeftDrawer] = useState(true);
  const [userDrawer, setUserDrawer] = useState(false);
  const [isMessageActive, setIsMessageActive] = useState(false);
  const [step, setStep] = useState(0);
  const [prevStep, setPrevStep] = useState(3);
  const [showBoughtPackagesPopup, setShowBoughtPackagesPopup] = useState(false);
  const showLoader = useSelector((state) => state.theme.showLoader);
  const drawerOpened = useSelector((state) => state.theme.drawer);
  const userDrawerOpened = useSelector((state) => state.theme.userDrawer);
  const showPopup = useSelector((state) => state.theme.successErrorPopup);
  const [activeItem, setActiveItem] = useState(
    tournamentActiveLink ? tournamentActiveLink : "dashboard",
  );
  const org = useSelector((state) => (state.org.org ? state.org.org.data : {}));
  const orgToken = useSelector((state) =>
    state.org.org ? state.org.org.token : "",
  );
  const userToken = useSelector((state) =>
    state?.user ? state?.user?.token : "",
  );
  const [menuItems, setMenuItems] = useState([]);
  const [dashboardLength, setDashboardLength] = useState(0);
  const [manageLength, setManageLength] = useState(0);
  const [competeLength, setCompeteLength] = useState(0);
  const [supportLength, setSupportLength] = useState(0);
  const [adminDashboardLength, setAdminDashboardLength] = useState(0);
  const { user, token } = useSelector((state) => state.user || {});
  const { mutateAsync: loadAppMessages } = useMutation(getAppMessageAction);

  const scholarshipData = user?.scholarship ?? [];

  const state = useSelector((state) => state || {});
  const [appMessage, setAppMessage] = useState();
  const isNonAdminUser =
    user?.user_type === "parent" ||
    user?.user_type === "player" ||
    user?.user_type === "coach" ||
    user?.user_type === "teamcoach" ||
    user?.user_type === "teammanager" ||
    user?.user_type === "tournamentmanager";
  const isShowMessage =
    location?.pathname === DASHBOARD_PAGE_PARENT ||
    location?.pathname === DASHBOARD_PAGE_ATHLETE ||
    location?.pathname === DASHBOARD_PAGE_TEAM_MANAGER_COACH;
  const isAlreadyAppliedForScholarship =
    state?.user?.user?.scholarship && state?.user?.user?.scholarship?.length > 0
      ? true
      : false;
  const warningMsgBgColor = "#FFF6F6";
  const warningMsgBorderColor = "#BA0000";
  const generalMsgBgColor = "#EFF7FF";
  const generalMsgBorderColor = "#7FBEFD";

  useEffect(() => {
    if (org.web_menu_settings) {
      const roleMenus =
        user?.user_type !== "none"
          ? org?.web_menu_settings[user?.user_type][0]
          : [];
      setDashboardLength(roleMenus.dashboard.length);
      setManageLength(roleMenus.manage.length);
      setCompeteLength(roleMenus.compete.length);
      setSupportLength(roleMenus.support.length);
      setAdminDashboardLength(roleMenus.adminDashboard.length);

      if (user?.user_type === "parent" || user?.user_type === "player") {
        roleMenus.dashboard.map((item) => {
          if (item.id === "scholarship-program" && !scholarshipData.length) {
            item.isWeb = false;
          } else {
            item.isWeb = true;
          }
        });
      } else if (
        user?.user_type === "teammanager" ||
        user?.user_type === "teamcoach"
      ) {
        roleMenus.dashboard.map((item) => {
          if (item.id === "scholarship-program" && !isMessageActive) {
            item.isWeb = false;
          } else {
            item.isWeb = true;
          }
        });
      }

      let _menuItems = [
        ...roleMenus.dashboard,
        ...roleMenus.manage,
        ...roleMenus.compete,
        ...roleMenus.support,
        ...roleMenus.adminDashboard,
      ];
      _menuItems = _menuItems
        .filter((menuItem) => menuItem.isWeb)
        .map((menuItem) => ({
          ...menuItem,
          ...(menuSettings[menuItem.id] || {}),
        }));

      setMenuItems(_menuItems);
    }
  }, [org, user, isMessageActive]);

  const handleDrawerToggle = () => {
    dispatch({ type: SET_DRAWER, data: !drawerOpened });
  };

  const handleOpenLeftDrawer = () => {
    setOpenLeftDrawer(true);
  };

  const handleCloseLeftDrawer = () => {
    setOpenLeftDrawer(false);
  };

  const UserDrawerOpen = () => {
    dispatch(getMe());
    dispatch({ type: SET_USER_DRAWER, data: true });
  };

  const UserDrawerClose = () => {
    dispatch({ type: SET_USER_DRAWER, data: false });
  };

  const Navigate = (item) => {
    dispatch({ type: SET_USER_DRAWER, data: false });
    // setOpenLeftDrawer(false);
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("orgToken");
    dispatch({ type: SET_BUCKET, data: [] });
    dispatch({ type: LOGOUT_USER });
    dispatch({ type: SET_USER_DRAWER, data: false });
    navigate(ROOT_PAGE);
  };
  // const changePassword = () => {
  //     navigate(CHANGE_PASSWORD_PAGE)
  // }

  const navigateToSubsciption = () => {
    navigate(SUBSCRIPTION_PAGE);
    setUserDrawer(false);
  };

  useEffect(() => {
    dispatch(getOrgData());
    getAppMessages();
  }, []);

  const getAppMessages = async () => {
    let params = {
      userToken,
      orgToken,
    };
    try {
      let response = await loadAppMessages({ params, dispatch });
      if (response?.ok) {
        setAppMessage(response?.data);
        if (response?.data?.is_active) {
          setIsMessageActive(true);
        } else {
          setIsMessageActive(false);
        }
      }
    } catch (error) {
      // dispatch({
      //   type: SET_TOAST,
      //   data: {
      //     type: "error",
      //     message: error?.message ?? "Something went wrong",
      //   },
      // });
    }
  };

  const icons = {
    "player-content": {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <path d="M21.7,6.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.9-0.8l-7-4c-0.9-0.5-2.1-0.5-3,0l-7,4C3.2,5.6,2.9,5.9,2.6,6.2C2.6,6.3,2.5,6.4,2.4,6.5c0,0.1,0,0.1-0.1,0.2C2.1,7.1,2,7.5,2,8v8c0,1.1,0.6,2.1,1.5,2.6l7,4c0.3,0.2,0.7,0.3,1,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.7-0.2,1-0.3l7-4c0.9-0.5,1.5-1.5,1.5-2.6v0V8C22,7.5,21.9,7.1,21.7,6.7z M11.5,3.1C11.7,3,11.8,3,12,3c0.2,0,0.3,0,0.5,0.1L19,6.8l-7,4l-7-4L11.5,3.1z M4.5,16.9C4.2,16.7,4,16.4,4,16V8.5l7,4v8L4.5,16.9z M19.5,16.9L13,20.6v-8l7-4V16C20,16.4,19.8,16.7,19.5,16.9z" />
        </svg>
      ),
    },
    calendar: {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <path d="M19,3h-2V2c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9V2c0-0.6-0.4-1-1-1S7,1.4,7,2v1H5C3.3,3,2,4.3,2,6v14c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V6C22,4.3,20.7,3,19,3z M5,5h2v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h2c0.6,0,1,0.4,1,1v3H4V6C4,5.4,4.4,5,5,5z M19,21H5c-0.6,0-1-0.4-1-1v-9h16v9C20,20.6,19.6,21,19,21z" />
        </svg>
      ),
    },
    promoLinks: {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <g>
            <path d="M12,8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C16,9.8,14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,13.1,13.1,14,12,14z" />
            <path d="M20.3,15.4c0.1-0.2,0.3-0.4,0.7-0.4c1.7,0,3-1.3,3-3s-1.3-3-3-3h-0.2c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.5,0.2-0.8c1.2-1.2,1.2-3.1,0-4.2v0c0,0,0,0,0,0c-0.6-0.6-1.3-0.9-2.1-0.9c0,0,0,0,0,0c-0.8,0-1.6,0.3-2.2,0.9c-0.2,0.2-0.5,0.2-0.7,0.1C15.2,3.6,15,3.3,15,3c0-1.7-1.3-3-3-3S9,1.3,9,3v0.2c0,0.2-0.1,0.4-0.3,0.5c-0.1,0-0.1,0-0.2,0.1C8.3,3.9,8,3.8,7.7,3.6c-1.2-1.2-3.1-1.2-4.2,0C2.3,4.8,2.3,6.7,3.6,7.9c0.2,0.2,0.2,0.5,0.1,0.8C3.6,8.9,3.3,9.1,3,9.1c-1.7,0-3,1.3-3,3s1.3,3,3,3h0.2c0.3,0,0.5,0.2,0.6,0.4c0.1,0.2,0.1,0.5-0.2,0.8c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1c0,0,0,0,0,0c1.2,1.2,3.1,1.2,4.3-0.1c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0.1,0.4,0.3,0.4,0.7c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.2c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.1,0.8,0.2c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1-0.1-4.3C20.3,15.9,20.2,15.6,20.3,15.4C20.3,15.4,20.3,15.4,20.3,15.4z M18.5,14.6c-0.4,1-0.2,2.1,0.6,3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c0,0,0,0,0,0c-0.3,0-0.5-0.1-0.8-0.4c-0.8-0.8-1.9-1-2.9-0.5c-1,0.4-1.6,1.4-1.6,2.4V21c0,0.6-0.4,1-1,1s-1-0.4-1-1c0,0,0-0.1,0-0.1c0-1.1-0.7-2-1.7-2.4c-0.3-0.2-0.7-0.2-1.1-0.2c-0.7,0-1.4,0.3-1.9,0.8c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0h0c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.4-0.8c0.8-0.8,1-1.9,0.5-2.9c-0.4-1-1.4-1.6-2.4-1.6H3c-0.6,0-1-0.4-1-1s0.4-1,1-1c0,0,0.1,0,0.1,0c1.1,0,2-0.7,2.4-1.7c0.4-1,0.2-2.1-0.6-3C4.5,6,4.5,5.4,4.9,5c0.4-0.4,1-0.4,1.5,0.1C7.1,5.8,8.2,6,9.1,5.7c0.1,0,0.2,0,0.3-0.1c1-0.4,1.6-1.4,1.6-2.4V3c0-0.6,0.4-1,1-1s1,0.4,1,1.1c0,1.1,0.6,2,1.6,2.4c1,0.4,2.1,0.2,3-0.6c0.2-0.2,0.4-0.3,0.7-0.3h0c0.3,0,0.5,0.1,0.7,0.3c0,0,0,0,0,0c0.4,0.4,0.4,1-0.1,1.5c-0.7,0.7-0.9,1.8-0.6,2.7c0,0.1,0,0.2,0.1,0.3c0.4,1,1.4,1.6,2.4,1.6H21c0.6,0,1,0.4,1,1s-0.4,1-1.1,1C19.9,13,18.9,13.6,18.5,14.6z" />
          </g>
        </svg>
      ),
    },
    "manage-users1": {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <g>
            <path d="M13,14H5c-2.8,0-5,2.2-5,5v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C18,16.2,15.8,14,13,14z" />
            <path d="M9,12c2.8,0,5-2.2,5-5s-2.2-5-5-5S4,4.2,4,7S6.2,12,9,12z M9,4c1.7,0,3,1.3,3,3s-1.3,3-3,3S6,8.7,6,7S7.3,4,9,4z" />
            <path d="M20.2,14.2c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c1.3,0.3,2.2,1.5,2.2,2.9v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C24,16.7,22.5,14.7,20.2,14.2z" />
            <path d="M16.2,2.2C15.7,2,15.2,2.3,15,2.9c-0.1,0.5,0.2,1.1,0.7,1.2c1.6,0.4,2.6,2,2.2,3.7c-0.3,1.1-1.1,1.9-2.2,2.2c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.2,0,0.2,0c1.8-0.5,3.2-1.8,3.6-3.6C20.5,5.6,18.9,2.8,16.2,2.2z" />
          </g>
        </svg>
      ),
    },
    subsciption: {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <g>
            <path d="M13,14H5c-2.8,0-5,2.2-5,5v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C18,16.2,15.8,14,13,14z" />
            <path d="M9,12c2.8,0,5-2.2,5-5s-2.2-5-5-5S4,4.2,4,7S6.2,12,9,12z M9,4c1.7,0,3,1.3,3,3s-1.3,3-3,3S6,8.7,6,7S7.3,4,9,4z" />
            <path d="M20.2,14.2c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c1.3,0.3,2.2,1.5,2.2,2.9v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C24,16.7,22.5,14.7,20.2,14.2z" />
            <path d="M16.2,2.2C15.7,2,15.2,2.3,15,2.9c-0.1,0.5,0.2,1.1,0.7,1.2c1.6,0.4,2.6,2,2.2,3.7c-0.3,1.1-1.1,1.9-2.2,2.2c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.2,0,0.2,0c1.8-0.5,3.2-1.8,3.6-3.6C20.5,5.6,18.9,2.8,16.2,2.2z" />
          </g>
        </svg>
      ),
    },
    social: {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <path d="M19,2H5C3.3,2,2,3.3,2,5v16c0,0.4,0.2,0.8,0.6,0.9C2.7,22,2.9,22,3,22c0.3,0,0.5-0.1,0.7-0.3L7.4,18H19c1.7,0,3-1.3,3-3V5C22,3.3,20.7,2,19,2z M20,15c0,0.6-0.4,1-1,1H7c-0.3,0-0.5,0.1-0.7,0.3L4,18.6V5c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V15z" />
        </svg>
      ),
    },
    "manage-users": {
      link: (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24">
          <path d="M21.7,6.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.9-0.8l-3.3-1.9c-0.1-0.1-0.3-0.2-0.5-0.3l-3.2-1.8c-0.9-0.5-2.1-0.5-3,0L7.3,3.2c-0.2,0-0.3,0.1-0.5,0.3L3.5,5.4C3.2,5.6,2.9,5.9,2.6,6.2C2.6,6.3,2.5,6.4,2.4,6.5c0,0.1,0,0.1-0.1,0.2C2.1,7.1,2,7.5,2,8v8c0,1.1,0.6,2.1,1.5,2.6l3.3,1.9c0.1,0.1,0.3,0.2,0.5,0.3l3.2,1.9c0.3,0.2,0.7,0.3,1,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.7-0.2,1-0.3l3.3-1.9c0.2,0,0.4-0.1,0.5-0.3l3.3-1.9c0.9-0.5,1.5-1.5,1.5-2.6v0V8C22,7.5,21.9,7.1,21.7,6.7z M12,10.9l-7-4l2.5-1.4l4,2.3c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1l3.9-2.3L19,6.8L12,10.9z M11.5,3.1C11.7,3,11.8,3,12,3c0.2,0,0.3,0,0.5,0.1l1.9,1.1L12,5.7L9.6,4.2L11.5,3.1z M4,16v-2.3l2.5,1.4V18l-2-1.1C4.2,16.7,4,16.4,4,16z M8.5,19.2v-4.6c0-0.4-0.2-0.7-0.5-0.9l-4-2.3V8.5l7,4v8L8.5,19.2z M13,20.6v-8l7-4v2.9l-4,2.3c-0.3,0.2-0.5,0.5-0.5,0.9v4.6L13,20.6z M19.5,16.9l-2,1.1v-2.8l2.5-1.4V16C20,16.4,19.8,16.7,19.5,16.9z" />
        </svg>
      ),
    },
  };

  const menuSettings = {
    dashboard: {
      icon: Home,
      link:
        user.user_type === "coach"
          ? DASHBOARD_PAGE_COACH
          : user.user_type === "parent"
          ? DASHBOARD_PAGE_PARENT
          : user.user_type === "player"
          ? DASHBOARD_PAGE_ATHLETE
          : user.user_type === "admin"
          ? DASHBOARD_PAGE_ADMIN
          : user.user_type === "teammanager" || user.user_type === "teamcoach"
          ? DASHBOARD_PAGE_TEAM_MANAGER_COACH
          : DASHBOARD_PAGE,
    },
    camps: {
      icon: CalendarIcon,
      link: Camp_Page,
    },
    clinics: {
      icon: CalendarIcon,
      link: Clinic_Page,
    },
    calendar: {
      icon: CalendarIcon,
      link:
        user.user_type === "admin"
          ? `${CALENDAR_PAGE}`
          : user.user_type === "parent" || user.user_type === "player"
          ? `${CALENDAR_PAGE}?show_registered_events=true`
          : "/calendars",
      // link: "/calendars",
      // comingsoon: {
      //     body: 'Once ready, you’ll be able to see all your users who have registered on desktop and mobile app. You’ll also be able to see which plan that user has subscribed to (if they’ve subscribed).',
      //     postedBy: 'The {{RG_SP_ORG_NAME}} Team',
      // },
    },
    "team-coach-list": {
      icon: UserCheck,
      link: Team_Coach,
    },
    "team-manager-list": {
      icon: UserCheck,
      link: Team_Manager,
    },
    "manage-parent": {
      icon: EscalatorWarningIcon,
      link:
        user.user_type === "parent"
          ? Manage_Parent_Page
          : Manage_Parent_BY_ATHLETE_Page,
    },
    "manage-athlete": {
      icon: EscalatorWarningIcon,
      link: Manage_Athlete_Page,
    },
    "manage-coaches": {
      icon: EscalatorWarningIcon,
      link: Manage_COACH,
    },
    social: {
      icon: CommunityIcon,
      link: COMMUNITYS_PAGE,
    },
    leagues: {
      icon: LeagueIcon,
      link:
        user.user_type === "coach"
          ? League_Coach_Page
          : user.user_type === "parent" || user.user_type === "player"
          ? League_Page
          : user.user_type === "admin"
          ? LEAGUE_SUPERADMIN
          : League_Coach_Page,
    },
    tournaments: {
      icon: TournamentIcon,
      //link: user.user_type === "parent" ? Parent_Tounament_Page : user.user_type === "admin" ? Admin_Tournament_PAGE : Tournament_Page
      link: Admin_Tournament_PAGE,
    },
    teams: {
      icon: TeamsIcon,
      link:
        // user.user_type === "admin" ||
        //   user.user_type === "teammanager" ?
        Tournament_Teams_Page,
      // : Team_Page,
    },
    "daily-training": {
      icon: CalendarIcon,
      link: Daily_Training_Page,
    },
    "purchase-packages": {
      icon: CalendarIcon,
      link: CALENDARS_PAGE,
    },
    "film-room": {
      icon: ReelPlayingIcon,
      link: FILMROOM_PAGE,
    },
    "performance-evaluations": {
      icon: StarPerform,
      link: PERFORMANCE_PAGE,
    },
    support: {
      icon: SupportIcon,
      link: PLAYER_PARENT_SUPPORT_PAGE,
    },
    "organization-admin": {
      icon: OrgAdmin,
      link: ADMIN_USERS_PAGE,
    },
    "sub-organization": {
      icon: SubOrgs,
      link: SUB_ORGANIZATIONS_PAGE,
    },
    "sub-org-teams": {
      icon: SubOrgTeams,
      link: SUB_ORGANIZATIONS_TEAM_PAGE,
    },
    "coaching-staff": {
      icon: UserCheck,
      link: COACHING_STAFF_PAGE,
    },
    users: {
      icon: RugbyPlayer,
      link: USERS_PAGE,
    },
    approvals: {
      icon: CheckIcon,
      link: APPROVAL_PAGE,
    },
    "support-tickets": {
      icon: SupportIcon,
      link: SUPPORT_PAGE,
    },
    products: {
      icon: SupportIcon,
      link: PRODUCT_PAGE,
    },
    "products-packages": {
      icon: ShoppingCartIcon,
      link: Package_List,
    },
    help: {
      icon: VideoCameraIcon,
      link: Help_Page,
    },
    "org-settings": {
      icon: SettingsIcon,
      link: Org_Settings,
    },
    "reporting-finance": {
      icon: BarChartIcon,
      link: Reporting_Finance,
    },
    "scholarship-program": {
      icon: ScholarshipProgramIcon,
      link:
        user.user_type === "admin"
          ? Scholarship_Request
          : Apply_Scholarship_Program,
    },
    "manage-grades": {
      icon: BarChartIcon,
      link: Manage_Grades,
    },
    "manage-divisions": {
      icon: BarChartIcon,
      link: Manage_Divisions,
    },
    "manage-sprints": {
      icon: BarChartIcon,
      link: Manage_Sprints,
    },
    "manage-postions": {
      icon: BarChartIcon,
      link: Manage_Positions,
    },
  };

  const contentBox = {
    // paddingLeft: "20px",
    width: "100%",
    // minWidth: 130,
    // minHeight: 100,
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    borderRadius: "4px",
    borderColor: "transparent",
    textAlign: "left",
    display: "flex",
    alignItems: "left",
    justifyContent: "center",
    flexDirection: "column",
    // cursor: "pointer"
  };

  const subTitleStyle = {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: theme?.palette?.secondary?.dark,
    fontWeight: 500,
    my: 1,
  };

  const handleGoBack = () => {
    if (step === 0) {
      UserDrawerClose();
    } else if (step === 1 || step === 2) {
      setStep(0);
    }
  };
  const handleCancel = () => {
    setStep(prevStep);
  };
  const handleClick = (menuId) => {
    if (isMobile) {
      setOpenLeftDrawer(false);
    }
    setActiveItem(menuId);
  };

  const handleCloseMyPacksModal = (isClosed) => {
    setShowBoughtPackagesPopup(false);
  };

  return (
    <>
      <Helmet defaultTitle="App" titleTemplate="%s App" title={org.title} />
      <Box
        className={openLeftDrawer ? "menu-open" : "root-main"}
        sx={{
          display: "flex",
          backgroundColor: theme.palette.primary.light,
          transition: "0.22s all",
          "& .pointer": {
            cursor: "pointer",
          },
          "& .textUnderline": {
            textDecoration: "underline",
            textDecorationColor: "secondary",
          },
          "& .d-flex": {
            display: "flex",
          },
          "&.menu-open": {
            transition: "0.22s all",
            paddingLeft: "300px",
            "& header": {
              paddingLeft: "300px",
            },
          },
          "& header": {
            transition: "0.22s all",
          },
        }}
      >
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.paper,
            transition: drawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}
        >
          <Toolbar>
            <Header
              leftDrawer={openLeftDrawer}
              openUserDrawer={UserDrawerOpen}
              leftDrawerToggle={handleOpenLeftDrawer}
            />
          </Toolbar>
        </AppBar>
        {/* drawer */}
        <DrawerMenu
          drawerOpen={drawerOpened}
          drawerToggle={handleDrawerToggle}
        />

        {/* notification popup */}
        <NotificationPopup />

        {/* main content */}
        <Main
          theme={theme}
          open={drawerOpened}
          sx={{
            height: "auto",
            [theme.breakpoints.down("md")]: {
              height: "100%",
            },
          }}
        >
          {isNonAdminUser && appMessage?.is_active && isShowMessage ? (
            <Box
              justifyContent="center"
              className="header-mesage"
              sx={{
                display: "none",
                [theme.breakpoints.down("md")]: {
                  position: "relative",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  minWidth: "100%",
                  height: "auto",
                  zIndex: 0,
                  mb: 2,
                  display: "block",
                },
              }}
            >
              <Box
                hidden={
                  appMessage?.format_type === "scholarship_apply"
                    ? state?.user?.user?.scholarship &&
                      state?.user?.user?.scholarship?.length > 0
                      ? state?.user?.user?.scholarship[0]?.status === "Denied"
                        ? false
                        : true
                      : false
                    : false
                }
                sx={{
                  background: appMessage?.color,
                  border: `1px solid ${
                    appMessage?.color === warningMsgBgColor
                      ? warningMsgBorderColor
                      : generalMsgBorderColor
                  }`,
                  color: "black",
                  borderRadius: "5px",
                  fontSize: "13px",
                  fontWeight: 500,
                  padding: "5px 8px",
                  color:
                    appMessage?.color === warningMsgBgColor
                      ? warningMsgBorderColor
                      : "black",
                  cursor:
                    appMessage?.format_type === "scholarship_apply"
                      ? "pointer"
                      : appMessage?.link
                      ? "pointer"
                      : "initial",
                  minHeight: "40px",
                  maxHeight: "55px",
                  overflowY: "auto",
                  fontSize: "13px",
                  [theme.breakpoints.down("sm")]: {
                    maxHeight: "inherit",
                    borderRadius: "0",
                  },
                }}
                onClick={() => {
                  if (appMessage?.format_type === "scholarship_apply") {
                    if (user?.user_type === "parent") {
                      navigate(Parent_Apply_Scholarship_Program, {
                        state: { isNewEntry: true },
                      });
                    } else {
                      navigate(Apply_Scholarship_Program, {
                        state: { isNewEntry: true },
                      });
                    }
                  } else {
                    if (appMessage?.link) {
                      window.open(appMessage?.link);
                    }
                  }
                }}
              >
                <span style={{ fontWeight: 700 }}>{appMessage?.title}:</span>{" "}
                {appMessage?.desc ?? "N/A"}
              </Box>
            </Box>
          ) : null}
          <Outlet />
        </Main>
      </Box>

      <Drawer
        anchor="left"
        open={openLeftDrawer}
        sx={{
          "& .MuiPaper-root": {
            overflow: "inherit",
          },
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
      >
        <Grid container sx={{ height: "100%", overflow: "inherit" }}>
          <Grid
            item
            xs={12}
            sx={{
              "& .box-with-menu": {
                position: "relative",
              },
            }}
          >
            <Box
              className="box-with-menu"
              sx={{
                "& .aside-menu-box": {
                  width: "300px",
                  background: `${org?.primary_color}`,
                  padding: "0.5rem 1rem",
                  position: "fixed",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 999,
                  minHeight: "100vh",
                  overflowY: "auto",
                },
              }}
            >
              <Box
                className="aside-menu-box"
                sx={{
                  "& .aside-logo-box": {
                    maxWidth: "48px",
                    //marginBottom: '1.5rem',
                    "& img": {
                      width: "100%",
                      height: "auto",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    marginBottom: "1.5rem",
                    width: "100%",
                  }}
                >
                  <Box
                    className="aside-logo-box"
                    mt={1}
                    sx={{
                      justifyContent: "center",
                      textAlign: "center",
                      marginBottom: "0",
                      marginRight: "auto",
                      width: "110px !important",
                      maxWidth: "110px !important",
                    }}
                  >
                    <Logo size="small" />
                  </Box>
                  <Box
                    sx={{ height: "25px", width: "25px", cursor: "pointer" }}
                    onClick={handleCloseLeftDrawer}
                    className="pointer"
                  >
                    <img
                      src={hiddenMenuIcon}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 6,
                    "& .aside-menu-list ul": {
                      margin: 0,
                      padding: 0,
                      listStyle: "none",
                      fontSize: "14px",
                      color: "#fff",
                      fontWeight: 500,
                      lineHeight: 1.167,
                      letterSpacing: "0.03em",
                      fontFamily: "Poppins,sans-serif,Arial",
                    },
                    "& .aside-menu-list ul li": {
                      // marginBottom: ' 1.4rem'
                      padding: "0.8rem 1rem",
                      margin: "0 -1rem",
                      transition: "0.5s all",
                      fontWeight: "400",
                    },
                    "& .aside-menu-list ul li.active": {
                      // backgroundColor: "rgba(255, 255, 255, 0.2)",
                      fontWeight: "700",
                    },
                    "& .aside-menu-list ul li.active .menu-icon": {
                      opacity: "1",
                    },
                    "& .aside-menu-list ul a": {
                      color: "#fff",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .aside-menu-list ul .menu-icon": {
                      width: "20px",
                      height: "20px",
                      marginRight: "0.5rem",
                      opacity: "0.4",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    "& .aside-menu-list ul .menu-icon svg": {
                      fill: "#fff",
                      "& path": {
                        fill: "#fff",
                      },
                    },
                    "& .aside-menu-list ul li:last-child": {
                      marginBottom: 0,
                    },
                  }}
                >
                  <Box className="aside-menu-list">
                    <ul>
                      {menuItems.map((item, i, array) => (
                        <>
                          <li
                            key={i}
                            onClick={() => handleClick(item.id)}
                            className={activeItem === item.id ? "active" : ""}
                          >
                            {item?.externalLink ? (
                              <>
                                {item.id === "social" ? (
                                  <>
                                    <Box onClick={Navigate}>
                                      <a
                                        href={`${item?.externalLink}?token=${token}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="menu-icon">
                                          {/* {icons[item.id]?.link} */}
                                          <CommunityIcon />
                                        </span>
                                        {item.label}
                                      </a>
                                    </Box>
                                    {/* <Box onClick={Navigate}>
                                                                                    <Link
                                                                                        to={item?.link}
                                                                                    >
                                                                                        <span className='menu-icon'>
                                                                                            {item.icon && <item.icon />}
                                                                                        </span>
                                                                                        {item.label}
                                                                                    </Link>
                                                                                </Box > */}
                                  </>
                                ) : (
                                  <Box onClick={Navigate}>
                                    <a
                                      href={item?.externalLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="menu-icon">
                                        {icons[item.id]?.link}
                                      </span>
                                      {item.label}
                                    </a>
                                  </Box>
                                )}
                              </>
                            ) : (
                              <Box onClick={Navigate}>
                                <Link to={item?.link}>
                                  <span className="menu-icon">
                                    {item.icon && <item.icon />}
                                  </span>
                                  {item.label}
                                </Link>
                              </Box>
                            )}
                          </li>
                          {item.isDevider && (
                            <>
                              <li>
                                <Box>
                                  <Divider
                                    sx={{
                                      mt: 1,
                                      mb: 1,
                                      backgroundColor: "#868686",
                                    }}
                                  ></Divider>
                                </Box>
                              </li>
                            </>
                          )}
                        </>
                      ))}
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Drawer>
      <Drawer
        anchor="right"
        open={userDrawerOpened}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            // backgroundColor: "#F4F6F9",
            maxWidth: "500px",
            "& .MuiPaper-root": {
              width: "100%",
              backgroundColor: "transparent",
              minWidth: "inherit",
            },
          },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "100%",
                "& .event-details-box": {
                  background: "#fff",
                  // borderRadius: '20px',
                  padding: "4rem",
                  boxShadow: "0 -15px 15px rgba(0, 0, 0, 0.1)",
                  fontSize: "14px",
                  marginTop: "0",
                  position: "relative",
                  zIndex: 2,
                  overflow: "hidden",
                  color: "#000",
                  textAlign: "center",
                  height: "100%",
                  [theme.breakpoints.down("md")]: {
                    padding: "3rem 2rem 2.3rem 2rem",
                  },
                },
              }}
            >
              <Box
                className="event-details-box"
                sx={{
                  "& .event-bottom-box": {
                    marginTop: "0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // padding: "1.5rem"
                  },
                  "& .event-bottom-box .btn": {
                    padding: "1rem",
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#fff",
                    border: 0,
                    borderRadius: "5px",
                    marginTop: 2,
                  },
                  "& .event-bottom-box .btn+.btn": {
                    marginTop: "1rem",
                  },
                  "& .btn-black": {
                    background: "black",
                    cursor: "pointer",
                  },
                }}
              >
                <Box className="event-bottom-box">
                  <Box
                    className="drawer-top"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      p: 1,
                      pb: 0,
                      position: "fixed",
                      zIndex: 99,
                      top: "0",
                      left: "0",
                      right: "0",
                      width: "100%",
                      maxWidth: "500px",
                      marginLeft: "auto",
                      background: "#fff",
                    }}
                  >
                    <IconButton
                      aria-label="back"
                      sx={{
                        position: "relative",
                        color: (theme) => theme.palette.grey[500],
                        right: 8,
                        bottom: 5,
                      }}
                      onClick={handleGoBack}
                    >
                      {step !== 3 && (
                        <NavigateBeforeIcon
                          sx={{ fontSize: 35, color: "black" }}
                        />
                      )}
                    </IconButton>
                    {step === 0 ? (
                      ""
                    ) : (
                      <IconButton
                        aria-label="close"
                        sx={{
                          position: "relative",
                          color: (theme) => theme.palette.grey[500],
                          bottom: 5,
                        }}
                        onClick={() => {
                          if (step !== 3) {
                            setStep(() => {
                              setPrevStep(step);
                              return 3;
                            });
                          }
                        }}
                      >
                        <CloseIcon sx={{ fontSize: 30, color: "black" }} />
                      </IconButton>
                    )}
                  </Box>
                  <Box
                    sx={{
                      "& .user-login-info figure": {
                        height: "100px",
                        width: "100px",
                        minWidth: "45px",
                        background: "#2a2e3e",
                        margin: 0,
                        color: "#fff",
                        fontSize: "22px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 600,
                        borderRadius: "50px",
                        marginRight: "0.6rem",
                        position: "relative",
                        overflow: "hidden",
                      },
                      "& .user-login-info figure img": {
                        width: "100%",
                        height: "auto",
                        borderRadius: "50px",
                      },
                      "& .user-login-info": {
                        display: "flex",
                        alignItems: "center",
                        mb: 8,
                        mt: 12,
                        justifyContent: "center",
                        "flex-direction": "column-reverse",
                      },
                    }}
                    className="pointer"
                  >
                    {step === 0 && (
                      <Box
                        className="user-login-info"
                        sx={{
                          "& .user-info h5": {
                            fontSize: "0.85rem",
                            color: "#212121",
                            fontWeight: 600,
                            lineHeight: 1.167,
                            letterSpacing: "0em",
                            margin: "0 0 0.2rem 0",
                            whiteSpace: "nowrap",
                            mt: 2,
                          },
                          "& .user-info p": {
                            margin: 0,
                            fontSize: "0.8rem",
                          },
                          "#userImg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          },
                          span: {
                            display: "block",
                            fontSize: "37px",
                            fontWeight: "bold",
                            lineHeight: "99px",
                            textAlign: "center",
                            height: "100px",
                            width: "100px",
                          },
                        }}
                      >
                        <Box
                          className="user-info"
                          sx={{ paddingRight: "15px" }}
                        >
                          <h5>
                            {user?.first_name} {user?.last_name}
                          </h5>
                          <p>
                            {user?.user_type?.charAt(0)?.toUpperCase() +
                              user?.user_type?.slice(1)}
                          </p>
                        </Box>
                        {user?.image ? (
                          <figure>
                            <img
                              id="userImg"
                              src={user.image}
                              alt="profile-img"
                            />
                          </figure>
                        ) : (
                          <Box
                            component="span"
                            sx={{
                              backgroundColor: "#000000",
                              justifyContent: "center",
                              margin: "0",
                              height: "50px",
                              width: "50px",
                              minWidth: "50px",
                              color: "#ffffff",
                              borderRadius: "50rem",
                              position: "relative",
                              overflow: "hidden",
                              border: "3px solid #fff",
                              boxShadow: " 0 0 15px rgba(0, 0, 0, 0.25)",
                              textTransform: "uppercase",
                            }}
                          >
                            {`${user?.first_name?.charAt(
                              0,
                            )}${user?.last_name?.charAt(0)}`}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                  {step === 0 && (
                    <>
                      {/* {user.user_type === 'player' && (
                                                <button className='btn btn-black' onClick={navigateToSubsciption}>My Subscription</button>
                                            )} */}
                      <Box
                        sx={{
                          ...contentBox,
                          my: 2,
                          cursor: "pointer",
                          [theme.breakpoints.down("md")]: {
                            my: 1,
                          },
                        }}
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            p: 3,
                            [theme.breakpoints.down("md")]: {
                              p: 2,
                            },
                          }}
                        >
                          <PersonIcon
                            sx={{
                              fontSize: 45,
                              [theme.breakpoints.down("md")]: {
                                fontSize: 30,
                              },
                            }}
                          />
                          <Typography
                            variant="span"
                            //fontWeight={700}
                            sx={{
                              fontSize: 16,
                              ml: 2,
                              [theme.breakpoints.down("md")]: {
                                ml: "12px",
                                fontSize: 15,
                              },
                            }}
                            color={theme.palette.dark.dark3}
                          >
                            Edit Profile
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...contentBox,
                          my: 2,
                          cursor: "pointer",
                          [theme.breakpoints.down("md")]: {
                            my: 1,
                          },
                        }}
                        onClick={() => {
                          setStep(2);
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            p: 3,
                            [theme.breakpoints.down("md")]: {
                              p: 2,
                            },
                          }}
                        >
                          <GroupsIcon
                            sx={{
                              fontSize: 45,
                              [theme.breakpoints.down("md")]: {
                                fontSize: 30,
                              },
                            }}
                          />
                          <Typography
                            variant="span"
                            //fontWeight={700}
                            sx={{
                              fontSize: 16,
                              ml: 2,
                              [theme.breakpoints.down("md")]: {
                                ml: "12px",
                                fontSize: 15,
                              },
                            }}
                            color={theme.palette.dark.dark3}
                          >
                            Change Password
                          </Typography>
                        </Box>
                      </Box>

                      {(user.user_type === "parent" ||
                        user.user_type === "player") && (
                        <>
                          <Box
                            sx={{
                              ...contentBox,
                              my: 2,
                              cursor: "pointer",
                              [theme.breakpoints.down("md")]: {
                                my: 1,
                              },
                            }}
                            onClick={() => {
                              setShowBoughtPackagesPopup(true);
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{
                                p: 3,
                                [theme.breakpoints.down("md")]: {
                                  p: 2,
                                },
                              }}
                            >
                              <Inventory2Icon
                                sx={{
                                  fontSize: 45,
                                  [theme.breakpoints.down("md")]: {
                                    fontSize: 30,
                                  },
                                }}
                              />
                              <Typography
                                variant="span"
                                //fontWeight={700}
                                sx={{
                                  fontSize: 16,
                                  ml: 2,
                                  [theme.breakpoints.down("md")]: {
                                    ml: "12px",
                                    fontSize: 15,
                                  },
                                }}
                                color={theme.palette.dark.dark3}
                              >
                                My Products
                              </Typography>
                            </Box>
                          </Box>
                          {scholarshipData?.length ? (
                            <Box
                              sx={{
                                ...contentBox,
                                my: 2,
                                cursor: "pointer",
                                [theme.breakpoints.down("md")]: {
                                  my: 1,
                                },
                              }}
                              onClick={() => {
                                dispatch({
                                  type: SET_USER_DRAWER,
                                  data: false,
                                });
                                navigate(Apply_Scholarship_Program);
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  p: 3,
                                  [theme.breakpoints.down("md")]: {
                                    p: 2,
                                  },
                                }}
                              >
                                <SchoolIcon
                                  sx={{
                                    fontSize: 45,
                                    [theme.breakpoints.down("md")]: {
                                      fontSize: 30,
                                    },
                                  }}
                                />
                                <Typography
                                  variant="span"
                                  //fontWeight={700}
                                  sx={{
                                    fontSize: 16,
                                    ml: 2,
                                    [theme.breakpoints.down("md")]: {
                                      ml: "12px",
                                      fontSize: 15,
                                    },
                                  }}
                                  color={theme.palette.dark.dark3}
                                >
                                  Scholarship Program
                                </Typography>
                              </Box>
                              <Box
                                width={1}
                                height={"1px"}
                                bgcolor={"gray"}
                                sx={{ opacity: 0.25 }}
                                mt={0}
                              ></Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  fontWeight={600}
                                  color={
                                    scholarshipData[0]?.status ===
                                    "Awaiting Approval"
                                      ? theme.palette.warning.dark
                                      : scholarshipData[0]?.status === "Denied"
                                      ? theme.palette.error.main
                                      : theme.palette.success.dark
                                  }
                                  sx={{
                                    pt:
                                      scholarshipData[0]?.status === "Approved"
                                        ? 3
                                        : 0,
                                    py:
                                      scholarshipData[0]?.status === "Approved"
                                        ? 0
                                        : 3,
                                    mt:
                                      scholarshipData[0]?.status === "Approved"
                                        ? 3
                                        : 0,
                                  }}
                                >
                                  {scholarshipData[0]?.status === "Approved" ? (
                                    <>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "30px",
                                        }}
                                      >
                                        {scholarshipData[0]?.discount + "%"}
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    scholarshipData[0]?.status
                                  )}
                                </Typography>
                                {scholarshipData?.length &&
                                scholarshipData[0]?.status === "Approved" ? (
                                  <Typography
                                    variant="h4"
                                    fontWeight={500}
                                    sx={{
                                      textAlign: "center",
                                      pb: 3,
                                      pt: 1,
                                      px: 2,
                                      fontSize: 14,
                                    }}
                                  >
                                    Only applicable for qualified events.
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          ) : null}
                          <Box
                            sx={{
                              ...contentBox,
                              my: 2,
                              [theme.breakpoints.down("md")]: {
                                my: 1,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch({ type: SET_USER_DRAWER, data: false });
                              navigate(USER_WALLET_HISTORY_PAGE, {
                                state: {
                                  userData: user,
                                },
                              });
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                p: 3,
                                [theme.breakpoints.down("md")]: {
                                  p: 2,
                                },
                                [theme.breakpoints.down("sm")]: {
                                  flexDirection: "column",
                                  alignItems: "start",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AttachMoneyIcon
                                  sx={{
                                    fontSize: 45,
                                    [theme.breakpoints.down("md")]: {
                                      fontSize: 30,
                                    },
                                  }}
                                />
                                <Typography
                                  variant="span"
                                  //fontWeight={700}
                                  sx={{
                                    fontSize: 16,
                                    ml: 1,
                                    minWidth: "130px",
                                    [theme.breakpoints.down("md")]: {
                                      ml: "12px",
                                      fontSize: 15,
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      ml: 1,
                                    },
                                  }}
                                  color={theme.palette.dark.dark3}
                                >
                                  Sketchplay Bucks Balance:
                                </Typography>
                              </Box>

                              <Typography
                                variant="h3"
                                fontWeight={700}
                                color={theme.palette.success.dark}
                                sx={{
                                  ml: 1,
                                  my: 1,
                                  paddingRight: "0",
                                  wordBreak: "break-all",
                                  minWidth: "80px",
                                  width: "100%",
                                  textAlign: "right",
                                  fontSize: "1.2rem",
                                  [theme.breakpoints.down("sm")]: {
                                    ml: 5,
                                    pr: 0,
                                    width: "auto",
                                    textAlign: "left",
                                  },
                                }}
                              >
                                {formatCurrency(user?.sketchplay_bucks)}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}

                      <button
                        className="btn btn-black"
                        onClick={handleClickOpen}
                      >
                        Logout
                      </button>
                    </>
                  )}
                </Box>
                {step === 1 && (
                  <>
                    {user.user_type === "player" && (
                      <AthleteProfile
                        UserDrawerClose={UserDrawerClose}
                        setStep={setStep}
                      />
                    )}
                    {(user.user_type === "parent" ||
                      user.user_type === "coach") && (
                      <ParentProfile
                        UserDrawerClose={UserDrawerClose}
                        setStep={setStep}
                      />
                    )}
                    {user.user_type !== "player" &&
                      user.user_type !== "parent" &&
                      user.user_type !== "coach" && (
                        <EditProfile
                          UserDrawerClose={UserDrawerClose}
                          setStep={setStep}
                        />
                      )}
                  </>
                )}
                {step === 2 && (
                  <ChangePassword
                    UserDrawerClose={UserDrawerClose}
                    setStep={setStep}
                  />
                )}
                {step === 3 && (
                  <>
                    <Box
                      sx={{
                        minHeight: "calc(100vh - 128px)",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        padding: "5rem",
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "0",
                        bottom: "0",
                        margin: "auto",
                        padding: "3rem",
                        height: "100%",
                        minHeight: "inherit",
                        background: "#fff",
                        [theme.breakpoints.down("md")]: {
                          padding: "2.3rem 2rem",
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: "auto auto 6rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "center",
                            fontSize: "17px",
                            fontWeight: "700",
                            mb: 1,
                          }}
                        >
                          Are you sure you want to cancel?
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "center" }}
                        >
                          You will lose any unsaved content
                        </Typography>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          //position: "absolute",
                          //bottom: 50,
                          // width: '100%',
                          marginTop: "auto",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Button
                              type="button"
                              variant="contained"
                              fullWidth={false}
                              onClick={handleCancel}
                              sx={{
                                py: 1,
                                px: 1,
                                fontSize: 13,
                                fontWeight: 700,
                                display: "inline-block",
                                width: "100%",
                                height: "100%",
                                boxShadow: "none",
                                color: "#0063A8",
                                border: "1px solid #0063A8",
                                background: "#fff",
                              }}
                            >
                              No, don't cancel
                            </Button>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                UserDrawerClose();
                                setStep(0);
                              }}
                              sx={{
                                py: 1,
                                px: 1,
                                fontSize: 13,
                                fontWeight: 700,
                                display: "inline-block",
                                width: "100%",
                                height: "100%",
                                boxShadow: "none",
                                marginLeft: "auto",
                              }}
                              fullWidth={true}
                            >
                              Yes, Cancel
                            </Button>
                          </Grid>
                        </Grid>
                        {/* {showError && <Alert severity="error">{showError}</Alert>} */}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ p: 0 }}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              py={3}
              ml={2}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Logout
            </Typography>
            <Box
              width={1}
              height={"1px"}
              bgcolor={"gray"}
              sx={{ opacity: 0.25 }}
            ></Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  fontSize={20}
                  py={3}
                  ml={2}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{ textAlign: "center" }}
                >
                  Are you sure you want to log out?
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              px: 3,
              pb: 2.4,
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              cancel
            </Button>
            <Button onClick={logout} variant="contained" color="error">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
      {showLoader && <PageLoader />}
      <ToastMessage />

      {showPopup?.open && <SuccessErrorPopup />}

      {showBoughtPackagesPopup && (
        <BoughtPackages
          open={showBoughtPackagesPopup}
          handleClose={handleCloseMyPacksModal}
        />
      )}
    </>
  );
};

export default MainLayout;
