import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP, getMe } from "@store/actions";
import httpClient from "@utils/httpClient";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SocialMediaProfile from "../../components/AppForms/SocialMediaProfile";
import { dateFormatForAPI } from "../../utils/functions";

const validationSchema = Yup.object({
  twitter_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  tiktok_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  insta_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  snap_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  linkedin_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  fb_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  yt_url: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  socialAccount: Yup.boolean().required(),
});

const EditSocialAccounts = ({
  step,
  setStep,
  formData,
  setFormData,
  setActiveAthleteId,
}) => {
  const dispatch = useDispatch();

  const handleSave = async (values, actions) => {
    const permission = {
      eventRegister: formData?.eventRegister,
      socialAccount: values?.socialAccount,
    };
    const postData = { ...formData, ...values, permission };
    postData.dob = postData?.dob ? dateFormatForAPI(postData?.dob) : "";
    dispatch({ type: SET_LOADER, data: true });

    const res = await httpClient.post(
      "/updateAthleteRelationDetails",
      postData,
    );

    if (res && res.ok) {
      await dispatch(getMe());
      dispatch({ type: SET_LOADER, data: false });
      setActiveAthleteId(0);
      setFormData({});
      actions.resetForm();
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: res.message },
      });
      setStep(step - 5);
    } else {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: res.message },
      });
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      twitter_url: formData?.twitter_url || "",
      tiktok_url: formData?.tiktok_url || "",
      insta_url: formData?.insta_url || "",
      snap_url: formData?.snap_url || "",
      linkedin_url: formData?.linkedin_url || "",
      fb_url: formData?.fb_url || "",
      yt_url: formData?.yt_url || "",
      socialAccount: formData?.permission?.socialAccount || false,
      submittedBy: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    formik.setFieldValue("twitter_url", formData?.twitter_url);
    formik.setFieldValue("tiktok_url", formData?.tiktok_url);
    formik.setFieldValue("insta_url", formData?.insta_url);
    formik.setFieldValue("snap_url", formData?.snap_url);
    formik.setFieldValue("linkedin_url", formData?.linkedin_url);
    formik.setFieldValue("fb_url", formData?.fb_url);
    formik.setFieldValue("yt_url", formData?.yt_url);
    formik.setFieldValue(
      "socialAccount",
      formData?.permission?.socialAccount || false,
    );
  }, [formData?.id]);

  return (
    <>
      <Box sx={{ mt: 3, mb: 3, display: "flex", alignItems: "center" }}>
        <Grid item xs={12} sm={12} sx={{ my: 1 }}>
          <Typography variant="h2" sx={{ color: "#262626" }}>
            Edit Athlete’s Social Accounts
          </Typography>
        </Grid>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box
          className="guardian-form-box"
          sx={{
            "&.guardian-form-box div": {
              maxWidth: "100%",
            },
          }}
        >
          <SocialMediaProfile formik={formik} />
        </Box>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              mb: 4,
              "& .MuiTypography-root": {
                color: "#3B3B3B",
                textAlign: "left",
              },
              "& .MuiSwitch-thumb": {
                background: "#fff",
                height: "13px",
                width: "13px",
                marginTop: "5px",
              },
              "& .MuiSwitch-track": {
                background: "#C9C9C9",
                opacity: "1",
                height: "18px",
                borderRadius: "50px",
              },
              "& .MuiSwitch-switchBase": {
                left: "6px",
                "&.Mui-checked": {
                  transform: "translateX(15px)",
                },
                "&.Mui-checked+.MuiSwitch-track": {
                  background: "#00B60D",
                  opacity: "1",
                },
              },
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="socialAccount"
                  checked={formik.values.socialAccount}
                  onChange={formik.handleChange}
                />
              }
              label="Allow this athlete to enter their own social account links the next time they login."
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="large"
              type="button"
              variant="contained"
              sx={{ fontWeight: "600", mt: 3, width: "45%" }}
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
            <Button
              // disableElevation
              // disabled={formik.isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              sx={{ fontWeight: "600", mt: 3, width: "45%" }}
            >
              Finished
            </Button>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="h6"
              sx={{
                mt: 4,
                color: "red",
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => {
                setFormData({});
                setActiveAthleteId(0);
                setStep(step - 5);
              }}
            >
              Cancel
            </Typography>
            {/* <Button
                            fullWidth
                            size="large"
                            variant="outlined"
                            sx={{ fontWeight: "600", mt: 2 }}
                            onClick={() => { setFormData({}); setActiveAthleteId(0); setStep(step - 5) }}
                        >
                            Cancel
                        </Button> */}
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default EditSocialAccounts;
