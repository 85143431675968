import {
    Box,
    Button,
    Grid,
    Typography,
    useTheme,
    Card, CardContent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton'
import {
    SET_TOAST,
    getAllHelpVideos, SET_LOADER, SET_SUCCESS_ERROR_POPUP
} from '../../store/actions';
import AddHelpVideo from '../../components/Help/AddHelpVideo';
import { convertToEmbedURL, getYoutubeThumbnail } from '../../utils/functions';
import httpClient from "@utils/httpClient";
import DeleteConfirmPopup from '../../components/Common/DeleteConfirm';
import { charValidate } from '../../utils/functions';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ViewHelpVideoDetails from '../../components/Help/ViewHelpVideoDetails';


const Help = () => {
    const [helpVideosData, setHelpVideosData] = useState([])
    const [showAddHelpVideoModal, setShowAddHelpVideoModal] = useState(false);
    const [showViewHelpVideoModal, setshowViewHelpVideoModal] = useState(false);
    const [helpVideoObj, setHelpVideoObj] = useState({})
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const userType = state?.user?.user?.user_type;
    const theme = useTheme();

    useEffect(() => {
        getHelpVideosList();
    }, []);

    const getHelpVideosList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        };
        let response = await getAllHelpVideos(params, dispatch);
        if (response?.ok) {
            setHelpVideosData(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for tournament data",
                },
            });
        }
    }

    const handleHelpVideoModalClose = (isClosed) => {
        setShowAddHelpVideoModal(false);
        setHelpVideoObj({})
        if (!isClosed) {
            getHelpVideosList()
        }
    };

    const handleViewHelpVideoModalClose = () => {
        setshowViewHelpVideoModal(false);
        setHelpVideoObj({})
    };

    const handleEditHelpVideo = (obj) => {
        setHelpVideoObj(obj)
        setShowAddHelpVideoModal(true);
    }

    const handleDeleteClick = (obj) => {
        setHelpVideoObj(obj);
        setShowDeletePopup(true);
    };

    const handleConfirmDelete = async () => {
        const postData = { id: helpVideoObj?.id }
        dispatch({ type: SET_LOADER, data: true });
        const res = await httpClient.post("/deleteHelpVideo", postData);
        dispatch({ type: SET_LOADER, data: false });
        if (res && res.ok) {
            setHelpVideoObj({})
            setShowDeletePopup(false);
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: res.message ?? "Video Deleted successfully" }
            })
            getHelpVideosList()
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
            })
        }
    };

    return (
        <>
            <Card sx={{
                mt: 1,
                [theme.breakpoints.down("sm")]: {
                    mt: 0,
                }
            }}>
                <CardContent>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        [theme.breakpoints.down("sm")]: {
                            flexDirection: "column",
                            alignItems: "start",
                        }
                    }}>
                        <Typography variant="h3" component="div">
                            How-To Videos
                        </Typography>
                        {
                            userType === 'admin' &&
                            <Box display="flex" alignItems="center">
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        paddingX: 3.5, paddingY: 1, ml: 1,
                                        [theme.breakpoints.down("sm")]: {
                                            mt: 1,
                                            ml: 0,
                                        }
                                    }}
                                    onClick={() => {
                                        setShowAddHelpVideoModal(true)
                                    }}
                                >
                                    Add Video
                                </Button>
                            </Box>
                        }
                    </Box>
                </CardContent>
            </Card>
            <Box sx={{
                my: 2,
            }}>
                <Grid container spacing={2}>
                    {
                        helpVideosData?.length > 0 ?
                            <>
                                {helpVideosData?.map((help) => (
                                    <Grid item md={4} sm={6} xs={12} key={help.id}>
                                        <Card sx={{ height: "100%", }}>
                                            <CardContent sx={{
                                                p: "0 !important",
                                            }}>
                                                <Box sx={{
                                                    p: 2,
                                                }}>
                                                    <Box
                                                        onClick={() => {
                                                            setHelpVideoObj(help);
                                                            setshowViewHelpVideoModal(true);
                                                        }}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: 0,
                                                            paddingBottom: '56.25%',
                                                            '& .ctmVideo': {
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%'
                                                            }
                                                        }}>

                                                        <img className='ctmVideo' src={help?.url ? getYoutubeThumbnail(help?.url) : ""} />
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    borderTop: `1px solid ${theme?.palette?.grey?.[200]}`,
                                                    p: 2,
                                                }}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}>
                                                        <Typography variant="h4" component="div">
                                                            {charValidate(help?.title, 35) || "N/A"}
                                                        </Typography>
                                                        {
                                                            userType === 'admin' &&
                                                            <Box display="flex" alignItems="center" sx={{
                                                                borderLeft: `1px solid ${theme?.palette?.grey?.[300]}`,
                                                                ml: 2,
                                                                py: 0.2,
                                                                pl: 2,
                                                            }}>
                                                                <IconButton
                                                                    size="small"
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        handleEditHelpVideo(help)
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon fontSize="small" sx={{ color: '#212121', cursor: 'pointer' }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    size="small"
                                                                    color="error"
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        handleDeleteClick(help)
                                                                    }}
                                                                    sx={{ ml: 1 }}
                                                                >
                                                                    <DeleteSweepIcon fontSize="small" sx={{ cursor: "pointer", color: "red" }} />
                                                                </IconButton>
                                                            </Box>
                                                        }

                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h3" component="div" sx={{ textAlign: 'center' }}>
                                                No Data Found
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                    }

                </Grid>
            </Box >

            {showAddHelpVideoModal && (
                <AddHelpVideo
                    open={showAddHelpVideoModal}
                    handleClose={handleHelpVideoModalClose}
                    helpVideoObj={helpVideoObj}
                />
            )
            }

            {
                showViewHelpVideoModal && (
                    <ViewHelpVideoDetails
                        open={showViewHelpVideoModal}
                        handleClose={handleViewHelpVideoModalClose}
                        helpVideoObj={helpVideoObj}
                    />
                )
            }

            {
                showDeletePopup && (
                    <DeleteConfirmPopup
                        title={"Help Video"}
                        message={
                            "Are you sure you want to delete this video?"
                        }
                        open={showDeletePopup}
                        handleClose={() => {
                            setShowDeletePopup(false);
                            setHelpVideoObj({});
                        }}
                        handleConfirm={handleConfirmDelete}
                    />
                )
            }
        </>
    )
}
export default Help;
