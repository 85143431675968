import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
  useTheme, Select, OutlinedInput, MenuItem
} from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_TOAST, createTeamManagerAction, createTeamCoachAction, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { convertToCapsOfFirstLetter } from "@utils/functions";

const AddTeamManager = ({
  open,
  handleClose,
  teamId = "",
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const selectionInputStyle = { width: "100%", height: 40, width: 300, };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  const state = useSelector((state) => state);
  const roleArr = [
    { id: 'team_manager', name: 'Team Manager' },
    { id: 'team_coach', name: 'Team Coach' }
  ]

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
    roleType: Yup.string().required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      countryCode: "",
      roleType: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParam = {
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        email: values?.email ?? "",
        countryCode: values?.countryCode?.toUpperCase() ?? "",
        mobile: values?.mobile ?? "",
        // team: formik.values.roleType === 'team_coach' ? [] : undefined,
        // is_team_coach: formik.values.roleType === 'team_coach' ? false : undefined
        team: teamId ? [teamId] : [],
        is_team_coach: teamId ? formik.values.roleType === 'team_coach' ? true : false : false,
        is_team_manager: teamId ? formik.values.roleType === 'team_manager' ? true : false : false,
      };
      createTeamManager(bodyParam);
    },
  });

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const createTeamManager = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = {}
    if (formik.values.roleType === 'team_manager') {
      response = await createTeamManagerAction(dispatch, params, bodyParams);
    } else if (formik.values.roleType === 'team_coach') {
      response = await createTeamCoachAction(dispatch, params, bodyParams);
    }

    if (response?.ok) {
      handleClose(false, response?.data?.id, response?.data?.first_name + " " + response?.data?.last_name);
      formik.resetForm()
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response.message ?? "Team Manager/Coach added successfully" }
      })
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
      })
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          py={1}
          sx={{
            [theme.breakpoints.down('md')]: {
              p: 0
            }
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Add Team Coach/Manager
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }} mb={0}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      {/* <Box
        width={1}
        height={"1px"}
        bgcolor={"gray"}
        sx={{ opacity: 0.25 }}
      ></Box> */}
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{
          p: 4,
          [theme.breakpoints.down('md')]: {
            p: 3,
          }
        }}>
          {/* <Typography
            variant="h5"
            fontWeight={700}
            fontSize={17}
            color={theme?.palette?.text?.containerTextLabel}
          >
            User Information
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="role-name" sx={inputLabelStyle}>
                Select Role
              </CustomInputLabel>
              <Select
                name="roleType"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.roleType}
                input={
                  <OutlinedInput
                    sx={selectionInputStyle}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{
                  "aria-label": "Without label",
                }}
                className="MuiSelect-select"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10.5px 14px"
                  },
                  width: "100%",
                }}
                displayEmpty
                renderValue={(value) => {
                  if (value) {
                    let selected = roleArr?.find((role) => role?.id === value);
                    if (selected) {
                      return selected?.name
                    }
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select Role...
                      </Typography>
                    );
                  }
                }}
              >
                {roleArr?.map((role) => (
                  <MenuItem key={role?.id} value={role?.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(formik.touched.roleType && formik.errors.roleType) && (
                <FormHelperText error>{formik.errors.roleType}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="first-name" sx={inputLabelStyle}>
                First Name
              </CustomInputLabel>
              <CustomTextField
                id="first-name"
                name="firstName"
                placeholder="Enter first name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
                value={formik.values.firstName}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.firstName && formik.errors.firstName) && (
                <FormHelperText error>{formik.errors.firstName}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="last-name" sx={inputLabelStyle}>Last Name</CustomInputLabel>
              <CustomTextField
                id="last-name"
                name="lastName"
                placeholder="Enter last name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'lastName')}
                value={formik.values.lastName}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.lastName && formik.errors.lastName) && (
                <FormHelperText error>{formik.errors.lastName}</FormHelperText>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="email" sx={inputLabelStyle}>Email Address</CustomInputLabel>
              <CustomTextField
                id="email"
                name="email"
                placeholder="Enter email..."
                variant="outlined"
                size="small"
                type="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="mobile-number" sx={inputLabelStyle}>
                Mobile Number
              </CustomInputLabel>
              <Box
                sx={{
                  "& .react-tel-input .form-control": {
                    minWidth: "100%",
                    fontWeight: 500,
                    background: theme?.palette?.grey[50],
                    height: "42px",
                  },
                }}
              >
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  placeholder="(702) 123-4567"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onChange={(value, country) => {
                    formik.setFieldValue("countryCode", country?.countryCode || "");
                    formik.setFieldValue("mobile", value || "");
                  }}
                  error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  size="sm"
                  style={{
                    width: "100%",
                  }}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                    },
                  }}
                  disableDropdown={true}
                  disableCountryCode={true}
                  onlyCountries={["us"]}
                />
                {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                  <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 4, pt: 0, m: 0, justifyContent: "space-between", }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTeamManager;
