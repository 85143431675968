import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TOAST,
  getMe,
  SET_SUCCESS_ERROR_POPUP,
  getLeagues,
  leagueTeamRegistration,
  getGrades,
} from "../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import { convertToCapsOfFirstLetter } from "@utils/functions";
import PhoneInput from "react-phone-input-2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { dateFormatForAPI } from "../../utils/functions";
import { Manage_Athlete_Page } from "../../routes/constants";
import { useNavigate } from "react-router-dom";

const LeagueRegistration = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const userinfo = state?.user?.user;

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const selectionInputStyle = { width: "100%", height: 40, width: 300 };

  const [leagueList, setLeagueList] = useState([]);
  const [leagueTeamList, setLeagueTeamList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [gradeId, setGradeId] = useState("");

  useEffect(() => {
    dispatch(getMe());
    getLeagueList();
    getGradeList();
  }, []);

  const validationSchema = Yup.object({
    athleteId: Yup.string().required("Please select Athlete"),
    leagueId: Yup.string().required("please select league"),
    teamId: Yup.string().required("please select team"),
    first_name: Yup.string().trim().required("first name is required"),
    last_name: Yup.string().trim().required("last name is required"),
    email: Yup.string()
      .trim()
      .required("email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().trim().required("mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
    athlete_firstName: Yup.string().trim().required("first name is required"),
    athlete_lastName: Yup.string().trim().required("last name is required"),
    dob: Yup.string().trim().required("dob is required"),
    grade: Yup.string().required("please select grade"),
  });

  const handleRegistration = async (values, actions) => {
    let bodyParam = {
      league: values?.leagueId,
      league_team: values?.teamId,
      athlete: values?.athleteId,
      parent: userinfo?.id,
      user_data: {
        parent_first_name: values?.first_name,
        parent_last_name: values?.last_name,
        parent_email: values?.email,
        parent_mobile: values?.mobile,
        parent_countryCode: values?.countryCode,
        first_name: values?.athlete_firstName,
        last_name: values?.athlete_lastName,
        dob: values?.dob ? dateFormatForAPI(values?.dob) : "",
        gradeId: gradeId,
        grade: values?.grade,
      },
    };
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await leagueTeamRegistration(dispatch, params, bodyParam);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message },
      });
      actions.resetForm();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong, try again latter",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      athleteId: "",
      leagueId: "",
      teamId: "",
      first_name: userinfo?.first_name ?? "",
      last_name: userinfo?.last_name ?? "",
      email: userinfo?.email ?? "",
      mobile: userinfo?.mobile ?? "",
      countryCode: userinfo?.countryCode ?? "",
      athlete_firstName: "",
      athlete_lastName: "",
      dob: "",
      grade: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleRegistration,
  });

  const getLeagueList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getLeagues(params, dispatch);
    if (response?.ok) {
      setLeagueList(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for league data",
        },
      });
    }
  };

  const getGradeList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getGrades(params, dispatch);
    if (response?.ok) {
      setGradeList(response?.data ?? []);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const handleLeagueChange = (event) => {
    const leagueId = event?.target.value;
    formik.setFieldValue("leagueId", leagueId);
    const league = leagueList.find((x) => x.leagueId === leagueId);
    setLeagueTeamList(league?.team);
  };

  const handleAthleteChange = (event) => {
    const athleteId = event?.target.value;
    formik.setFieldValue("athleteId", athleteId);
    const selectedAthlete = userinfo?.athlete?.find((x) => x.id === athleteId);
    formik.setFieldValue("athlete_firstName", selectedAthlete?.first_name);
    formik.setFieldValue("athlete_lastName", selectedAthlete?.last_name);
    formik.setFieldValue("dob", dayjs(selectedAthlete?.dob));
    formik.setFieldValue("grade", selectedAthlete?.grade);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
      <Box
        sx={{
          width: "95%",
          position: "relative",
          backgroundColor: theme?.palette?.background?.paper,
          boxShadow: theme?.palette?.boxShadow?.tableContainer,
          borderRadius: 1,
          border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
        }}
        mt={4}
      >
        <Box sx={{ pt: 3 }}>
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{ pr: 3, pl: 3, pb: 2.5, pt: 1.5 }}
          >
            {userinfo.user_type === "parent"
              ? "League Registration"
              : "League List"}
          </Typography>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
            mt={1}
          ></Box>
          {userinfo.user_type === "parent" ? (
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 4,
                  mb: 4,
                  px: 3,
                  [theme.breakpoints.down("sm")]: {
                    mt: 1,
                    mb: 3,
                  },
                }}
              >
                <Grid item xs={12} sm={12} lg={5}>
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      alignItems: "self-start",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          CHOOSE Athlete
                        </CustomInputLabel>
                        <Select
                          name="athleteId"
                          onChange={handleAthleteChange}
                          value={formik.values.athleteId}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 300,
                              },
                            },
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: "100%",

                            //maxWidth: "300px"
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = userinfo?.athlete?.find(
                                (athlete) => athlete?.id === value,
                              );
                              if (selected) {
                                return (
                                  selected?.first_name +
                                  " " +
                                  selected?.last_name
                                );
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select Athlete...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {userinfo?.athlete?.map((athlete) => (
                            <MenuItem key={athlete?.id} value={athlete?.id}>
                              {athlete.first_name} {athlete.last_name}
                            </MenuItem>
                          ))}
                        </Select>

                        {Boolean(
                          formik.touched.athleteId && formik.errors.athleteId,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.athleteId}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() =>
                            navigate(Manage_Athlete_Page, {
                              state: { isNewEntry: true },
                            })
                          }
                          sx={{
                            paddingX: 3.5,
                            paddingY: 1.2,
                            mt: 2.7,
                            width: "100%",
                          }}
                        >
                          Add Athlete
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          CHOOSE League
                        </CustomInputLabel>
                        <Select
                          name="leagueId"
                          onChange={handleLeagueChange}
                          value={formik.values.leagueId}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 300,
                              },
                            },
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: "100%",
                            //maxWidth: "300px"
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = leagueList?.find(
                                (league) => league?.leagueId === value,
                              );
                              if (selected) {
                                return selected?.leagueName;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select League...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {leagueList?.map((league) => (
                            <MenuItem
                              key={league?.leagueId}
                              value={league?.leagueId}
                            >
                              {league.leagueName}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          formik.touched.leagueId && formik.errors.leagueId,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.leagueId}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          CHOOSE Team
                        </CustomInputLabel>
                        <Select
                          name="teamId"
                          onChange={formik.handleChange}
                          value={formik.values.teamId}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 300,
                              },
                            },
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: "100%",
                            //maxWidth: "300px"
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = leagueTeamList.find(
                                (leagueTeam) => leagueTeam?.id === value,
                              );
                              if (selected) {
                                return selected?.name;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select Team...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {leagueTeamList?.map((leagueTeam) => (
                            <MenuItem
                              key={leagueTeam?.id}
                              value={leagueTeam?.id}
                            >
                              {leagueTeam.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          formik.touched.teamId && formik.errors.teamId,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.teamId}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Parent First Name
                        </CustomInputLabel>
                        <TextField
                          id="first_name"
                          name="first_name"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(e, formik, "first_name")
                          }
                          value={formik.values.first_name}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-input": {
                              padding: "10.5px 14px !important",
                              outline: "none",
                            },
                          }}
                          // className="input-style"
                          placeholder="Enter first name..."
                          error={Boolean(
                            formik.touched.first_name &&
                              formik.errors.first_name,
                          )}
                        />
                        {Boolean(
                          formik.touched.first_name && formik.errors.first_name,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.first_name}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Parent Last Name
                        </CustomInputLabel>
                        <TextField
                          id="last_name"
                          name="last_name"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(e, formik, "last_name")
                          }
                          value={formik.values.last_name}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-input": {
                              padding: "10.5px 14px !important",
                              outline: "none",
                            },
                          }}
                          // className="input-style"
                          placeholder="Enter last name..."
                          error={Boolean(
                            formik.touched.last_name && formik.errors.last_name,
                          )}
                        />
                        {Boolean(
                          formik.touched.last_name && formik.errors.last_name,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.last_name}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Parent Email
                        </CustomInputLabel>
                        <TextField
                          type="email"
                          id="email"
                          name="email"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email.toLowerCase()}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-input": {
                              padding: "10.5px 14px !important",
                              outline: "none",
                            },
                          }}
                          // className="input-style"
                          placeholder="Enter email..."
                          error={Boolean(
                            formik.touched.email && formik.errors.email,
                          )}
                        />
                        {Boolean(
                          formik.touched.email && formik.errors.email,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.email}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Parent Phone
                        </CustomInputLabel>
                        <Box
                          sx={{
                            "& .react-tel-input .form-control": {
                              "min-width": "100%",
                              "font-weight": "500",
                              padding: "15px 14px 15px 50px",
                              height: "45px",
                              width: "100%",
                            },
                            "& .react-tel-input .flag-dropdown": {
                              border: 0,
                              background: "transparent",
                            },
                          }}
                        >
                          <PhoneInput
                            country={"us"}
                            id="mobile"
                            name="mobile"
                            variant="outlined"
                            placeholder="(702) 123-4567"
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            onlyCountries={["us"]}
                            disableCountryCode={true}
                            disableDropdown={true}
                            onChange={(value, country) => {
                              formik.setFieldValue(
                                "countryCode",
                                country.countryCode || "",
                              );
                              formik.setFieldValue("mobile", value);
                            }}
                            error={Boolean(
                              formik.touched.mobile && formik.errors.mobile,
                            )}
                          />
                        </Box>
                        {Boolean(
                          formik.touched.mobile && formik.errors.mobile,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.mobile}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Athlete’s First Name
                        </CustomInputLabel>
                        <TextField
                          id="athlete_firstName"
                          name="athlete_firstName"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(
                              e,
                              formik,
                              "athlete_firstName",
                            )
                          }
                          value={formik.values.athlete_firstName}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-input": {
                              padding: "10.5px 14px !important",
                              outline: "none",
                            },
                          }}
                          // className="input-style"
                          placeholder="Enter athlete first name..."
                          error={Boolean(
                            formik.touched.athlete_firstName &&
                              formik.errors.athlete_firstName,
                          )}
                        />
                        {Boolean(
                          formik.touched.athlete_firstName &&
                            formik.errors.athlete_firstName,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.athlete_firstName}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Athlete’s Last Name
                        </CustomInputLabel>
                        <TextField
                          id="athlete_lastName"
                          name="athlete_lastName"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(
                              e,
                              formik,
                              "athlete_lastName",
                            )
                          }
                          value={formik.values.athlete_lastName}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-input": {
                              padding: "10.5px 14px !important",
                              outline: "none",
                            },
                          }}
                          // className="input-style"
                          placeholder="Enter athlete last name..."
                          error={Boolean(
                            formik.touched.athlete_lastName &&
                              formik.errors.athlete_lastName,
                          )}
                        />
                        {Boolean(
                          formik.touched.athlete_lastName &&
                            formik.errors.athlete_lastName,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.athlete_lastName}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Dob
                        </CustomInputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="dob"
                            slotProps={{
                              textField: {
                                size: "small",
                                error: false,
                              },
                            }}
                            onChange={(newVal) =>
                              formik.setFieldValue("dob", newVal)
                            }
                            value={dayjs(formik?.values?.dob)}
                            views={["year", "month", "day"]}
                            sx={{
                              "&.MuiFormControl-root": {
                                width: "100%",
                              },
                            }}
                            maxDate={dayjs()}
                          />
                        </LocalizationProvider>
                        {Boolean(formik.touched.dob && formik.errors.dob) && (
                          <FormHelperText error>
                            {formik.errors.dob}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          CHOOSE Grade
                        </CustomInputLabel>
                        <Select
                          name="grade"
                          onChange={formik.handleChange}
                          value={formik.values.grade}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 300,
                              },
                            },
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: "100%",
                            //maxWidth: "300px"
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = gradeList?.find(
                                (grade) => grade?.name === value,
                              );
                              if (selected) {
                                setGradeId(selected?.id);
                                return selected?.name;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select Grade...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {gradeList?.map((grade) => (
                            <MenuItem key={grade?.id} value={grade?.name}>
                              {grade.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          formik.touched.grade && formik.errors.grade,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.grade}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 10,
                  mb: 4,
                  px: 3,
                  [theme.breakpoints.down("md")]: {
                    mt: 5,
                    mb: 2,
                  },
                  [theme.breakpoints.down("sm")]: {
                    mt: 2,
                    mb: 3,
                  },
                }}
              >
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  >
                    Registration
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                mt: 4,
                mb: 4,
                px: 3,
                [theme.breakpoints.down("sm")]: {
                  mt: 1,
                  mb: 3,
                },
              }}
            >
              <Grid item xs={12} sm={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    alignItems: "self-start",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                        sx={{
                          pr: 3,
                          pl: 3,
                          pb: 2.5,
                          pt: 1.5,
                          textAlign: "center",
                        }}
                      >
                        No Data Available
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LeagueRegistration;
