import {
    Box,
    Button,
    FormHelperText,
    Grid,
    useTheme,
    Typography, InputAdornment, FormControlLabel, Switch
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { SET_TOAST, SET_LOADER, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import httpClient from "@utils/httpClient";
import { styled } from "@mui/system";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteConfirmPopup from "../Common/DeleteConfirm";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const PackagePackDiscount = ({ handleClose, setStep, formData, setFormData, isBackClick, isFromDuplicate }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [discountIds, setDiscountIds] = useState(formData?.discount?.length > 0 ? formData?.discount?.map(x => x.id) : [])
    const [isDiscountRequired, setIsDiscountRequired] = useState(false)
    const [isOneFieldFilled, setIsOneFieldFilled] = useState(false);
    const [isOneFieldRequiredError, setIsOneFieldRequiredError] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [discountId, setDiscountId] = useState('');
    const [isValueGTzero, setIsValueGTzero] = useState(false);
    // const [inputFields, setInputFields] = useState(formData?.discount?.length > 0 ? [...formData?.discount, {
    //     idObj: 'name-id-0', name: '', flatOff: '', flatPer: '', is_active: true
    // }] : [
    //     { idObj: 'name-id-0', name: '', flatOff: '', flatPer: '', is_active: true }
    // ]);

    const [inputFields, setInputFields] = useState(
        isFromDuplicate
            ? [{ idObj: 'name-id-0', name: '', flatOff: '', flatPer: '', is_active: true }]
            : (formData?.discount?.length > 0
                ? [...formData?.discount, {
                    idObj: 'name-id-0',
                    name: '',
                    flatOff: '',
                    flatPer: '',
                    is_active: true
                }]
                : [
                    {
                        idObj: 'name-id-0',
                        name: '',
                        flatOff: '',
                        flatPer: '',
                        is_active: true
                    }
                ])
    );

    const CustomSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: "#00B60D",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const handleFieldChange = (index, fieldName, value) => {
        if (fieldName === 'name' && value.trim()) {
            setIsDiscountRequired(false)
        }
        const updatedFields = [...inputFields];
        if (fieldName === 'is_active') {
            updatedFields[index][fieldName] = value;
        } else {
            updatedFields[index][fieldName] = value.trim();
        }

        if (fieldName === 'flatPer' || fieldName === 'flatOff') {
            if (value > 0) {
                if (fieldName === 'flatPer') {
                    // If flatPer is filled, set flatOff to empty string
                    updatedFields[index]['flatOff'] = '';
                    setIsOneFieldRequiredError(false)
                } else if (fieldName === 'flatOff') {
                    updatedFields[index]['flatPer'] = '';
                    setIsOneFieldRequiredError(false)
                }
                setIsValueGTzero(false)
            } else {
                setIsValueGTzero(true)
            }
        }

        setInputFields(updatedFields);
    };

    const handleAddInputField = async (indexVal) => {
        const fieldValues = inputFields.map((field) => ({
            name: field.name,
            flatOff: parseFloat(field.flatOff),
            flatPer: parseFloat(field.flatPer),
            is_active: field.is_active,
        }));
        if (fieldValues.length > 0) {
            const reqPayload = fieldValues[indexVal];
            const newArray = inputFields.filter((item, index) => index !== indexVal);
            if (reqPayload?.name && (reqPayload.flatOff || reqPayload.flatPer)) {
                dispatch({ type: SET_LOADER, data: true });
                const res = await httpClient.post("/createDiscount", reqPayload);
                dispatch({ type: SET_LOADER, data: false });
                if (res && res.ok) {
                    const updateArr = [...discountIds, res?.data?.id]
                    setDiscountIds(updateArr)
                    const discount = updateArr;
                    const fieldFilterArr = inputFields.filter(x => x.name !== '' && (x.flatOff !== '' || x.flatPer !== ''))
                    const updatedArray = fieldFilterArr.slice(0, -1);
                    const mergeArr = [...updatedArray, res.data]
                    isBackClick(mergeArr)
                    setFormData((prevStage) => {
                        return {
                            ...prevStage,
                            discount
                        };
                    });
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'success', message: "This discount created successfully" }
                    })
                    setIsDiscountRequired(false)
                    setIsOneFieldFilled(false)
                    setIsOneFieldRequiredError(false);
                    setIsValueGTzero(false)
                    // Update the state to include the new input field
                    setInputFields((prevInputFields) => [
                        res.data,
                        ...newArray,
                        {
                            idObj: `name-id-${inputFields.length}`,
                            name: '',
                            flatOff: '',
                            flatPer: '',
                            is_active: true,
                        },
                    ]);

                } else {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                    })
                    return false;
                }
            } else {
                if (reqPayload?.name) {
                    setIsDiscountRequired(false)
                } else {
                    setIsDiscountRequired(true)
                }

                if (!reqPayload.flatOff || !reqPayload.flatPer) {
                    setIsOneFieldRequiredError(true);
                } else {
                    setIsOneFieldRequiredError(false);
                }
            }
        }
    };

    const handleEditInputField = async (indexVal) => {
        const fieldValues = inputFields.map((field) => ({
            ...field,
            flatOff: parseFloat(field.flatOff),
            flatPer: parseFloat(field.flatPer)
        }));
        if (fieldValues.length > 0) {
            const reqPayload = fieldValues[indexVal];
            const newArray = inputFields.filter((item, index) => index !== indexVal);
            if (reqPayload?.name && (reqPayload.flatOff || reqPayload.flatPer)) {
                dispatch({ type: SET_LOADER, data: true });
                const res = await httpClient.post("/createDiscount", reqPayload);
                dispatch({ type: SET_LOADER, data: false });
                if (res && res.ok) {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'success', message: "This discount updated successfully" }
                    })
                    setIsDiscountRequired(false)
                    setIsOneFieldFilled(false)
                    setIsOneFieldRequiredError(false);
                    setIsValueGTzero(false)
                    // Update the state to include the new input field
                    setInputFields((prevInputFields) => [
                        res.data,
                        ...newArray
                    ]);

                } else {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                    })
                    return false;
                }
            } else {
                if (reqPayload?.name) {
                    setIsDiscountRequired(false)
                } else {
                    setIsDiscountRequired(true)
                }

                if (!reqPayload.flatOff || !reqPayload.flatPer) {
                    setIsOneFieldRequiredError(true);
                } else {
                    setIsOneFieldRequiredError(false);
                }
            }
        }
    };

    const handleDeleteDicount = async () => {
        dispatch({ type: SET_LOADER, data: true });
        const postData = { id: parseInt(discountId) }
        const res = await httpClient.post("/deleteDiscount", postData);
        dispatch({ type: SET_LOADER, data: false });
        if (res && res.ok) {
            const filterArr = inputFields.filter((item, index) => item?.id !== discountId);
            const updateArr = discountIds.filter((item, index) => item !== discountId);
            setDiscountIds(updateArr)
            setInputFields((prevInputFields) => [
                ...filterArr
            ]);
            setDiscountId('')
            setShowDeletePopup(false);
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: res.message }
            })
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
            })
            return false;
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Add Discount Codes
                    </Typography>
                </Grid>
                {inputFields.map((inputField, index) => (
                    <>
                        <Grid item xs={2} sx={{ mt: 4 }}>
                            <FormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={inputField.is_active}
                                        onChange={(e) =>
                                            handleFieldChange(index, 'is_active', e.target.checked)
                                        }
                                        size="sm"
                                        sx={{ m: 1 }}
                                    />
                                }
                                label="Active"
                                labelPlacement="start"
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme?.palette?.text?.containerTextLabel,
                                        fontWeight: 400,
                                        fontFamily: "inherit",
                                    },
                                }}
                            //disabled={inputField?.hasOwnProperty("id")}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Discount Code
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`name-${index}`}
                                placeholder="Enter a code"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'name', e.target.value)
                                }
                                value={inputField.name}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                disabled={!inputField?.is_active}
                            />
                            {(isDiscountRequired && !inputField.name) && (
                                <FormHelperText error>Discount Code is required</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={2}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Discount $
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`flatOff-${index}`}
                                type="number"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'flatOff', e.target.value)
                                }
                                value={inputField.flatOff}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ ml: 1 }}>
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={!inputField?.is_active}
                            />
                            {(isOneFieldRequiredError && !inputField?.hasOwnProperty("id")) && (
                                <FormHelperText error>At least one field is required of discount off or discount percentage</FormHelperText>
                            )}

                            {(isValueGTzero && !inputField?.hasOwnProperty("id")) && (
                                <FormHelperText error>Value must be greater than to 0</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={2}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Discount %
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`flatPer-${index}`}
                                type="number"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'flatPer', e.target.value)
                                }
                                value={inputField.flatPer}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ ml: 1 }}>
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={!inputField?.is_active}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {
                                inputField?.hasOwnProperty("id") ?
                                    <>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="button"
                                            sx={{ width: "70px", height: "45px", mt: 3.5, mr: 1 }}
                                            onClick={() => { handleEditInputField(index) }}
                                        // disabled={!inputField?.is_active}
                                        >
                                            <EditOutlinedIcon
                                                sx={{ fontSize: 22, cursor: "pointer", }}
                                            />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="button"
                                            sx={{ width: "70px", height: "45px", mt: 3.5 }}
                                            onClick={() => { setDiscountId(inputField?.id); setShowDeletePopup(true) }}
                                        //disabled={!inputField?.is_active}
                                        >
                                            <DeleteSweepIcon
                                                onClick={() => { setDiscountId(inputField?.id); setShowDeletePopup(true) }}
                                                sx={{ fontSize: 22, cursor: "pointer", }}
                                            />
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="button"
                                        sx={{ width: "70px", height: "45px", mt: 3.5, fontSize: "25px" }}
                                        onClick={() => handleAddInputField(index)}
                                    >
                                        +
                                    </Button>
                            }

                        </Grid>
                    </>
                ))}
            </Grid>
            {/* <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mt: 4 }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                    color="inherit"
                    onClick={() => { setIsDiscountRequired(false); setIsOneFieldFilled(false); setIsOneFieldRequiredError(false); setIsValueGTzero(false); handleClose(true); }}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                    color="inherit"
                    onClick={() => handleBackClick()}
                >
                    Back
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    type="button"
                    sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                    onClick={() => handleSave()}
                >
                    Finish
                </Button>
            </Box> */}
            {showDeletePopup &&
                <DeleteConfirmPopup
                    title={"Discount"}
                    message={"Are you sure you want to delete this Discount?"}
                    open={showDeletePopup}
                    handleClose={() => {
                        setShowDeletePopup(false);
                        setDiscountId('')
                    }}
                    handleConfirm={handleDeleteDicount}
                />
            }
        </>
    );
};

export default PackagePackDiscount;
