import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Typography,
    useTheme,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import httpClient from "@utils/httpClient";
import { useDispatch } from "react-redux";

const DeductCountModal = ({ open, handleClose, selectedRow }) => {

    const dispatch = useDispatch();
    const theme = useTheme();

    const validationSchema = Yup.object({
        deductReason: Yup.string().trim().required('Reason is required'),
    });

    const formik = useFormik({
        initialValues: {
            deductReason: "",
        },
        validationSchema,
        onSubmit: () => {
            handleSubmit();
        },
    });

    const handleSubmit = async () => {
        let params = {
            "pack": selectedRow?.packWalletId,
            "athlete_id": null,
            "value": parseInt(selectedRow?.currentCount),
            "description": formik.values.deductReason
        }
        dispatch({ type: SET_LOADER, data: true });
        const response = await httpClient.post("/updatePack", params);
        dispatch({ type: SET_LOADER, data: false });
        if (response?.ok) {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: "success", message: response.message ?? "Pack deducted successfully" }
            })
            handleClose(false)
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
            })
        }
    }

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="sm"
            fullWidth
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle sx={{ p: 0 }}>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        py={3}
                        ml={2}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Deduct Pack
                    </Typography>
                    <Box
                        width={1}
                        height={"1px"}
                        bgcolor={"gray"}
                        sx={{ opacity: 0.25 }}
                    ></Box>
                </DialogTitle>
                <DialogContent
                    sx={{
                        pt: 4,
                        px: 4,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        pb: 6
                    }}
                >
                    <Typography
                        sx={{ py: 3 }}
                        fontFamily={"Poppins"}
                        fontSize={16}
                        fontWeight={600}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Are you sure you want to deduct this pack?
                    </Typography>
                    <CustomTextField
                        name="deductReason"
                        placeholder={'Enter reason...'}
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.deductReason}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                boxShadow: "none",
                            },
                        }}
                        multiline
                        rows={4}
                    />
                    {Boolean(formik.touched.deductReason && formik.errors.deductReason) && (
                        <FormHelperText sx={{ alignSelf: "flex-start", ml: 3 }} error>{formik.errors.deductReason}</FormHelperText>
                    )}
                </DialogContent>
                <DialogActions sx={{ pr: 4, pb: 3 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        color="inherit"
                        onClick={() => handleClose(true)}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        type="submit"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DeductCountModal;
