import {
  Box,
  Typography,
  useTheme,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useState, useEffect } from "react";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  SET_LOADER,
  SET_TOAST,
  getAllPositionsAction,
  getAllPositionsList,
  getAllProductPackages,
  getCoach,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import { LIMIT_VALIDATION } from "../../../../utils/constants";
import { CALENDAR_PAGE } from "../../../../routes/constants";
import { useNavigate } from "react-router";
import AddAddress from "./AddAddress";
import CustomSwitch from "../../../../components/Common/Inputs/Switch";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "react-query";

export default function CreateCampStep4({
  onNext,
  onBack,
  step,
  selectedEvent,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const selectionInputStyle = {
    width: "100%",
    minWidth: "300px",
    height: "44px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const navigate = useNavigate();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const step4Details = state?.createCampEvent?.step4Details;
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setselectedProduct] = useState([]);
  const [permissions, setPermissions] = useState([
    {
      id: 1,
      label: "Allow drop in registrations.",
      name: "allow_drop_in",
      flag: true,
    },
    {
      id: 2,
      label: "Restrict Athlete’s from registering.",
      name: "restrict_atheletes_to_register",
      flag: false,
    },
    {
      id: 3,
      label: "Allow Scholarship Funds to be applied to this event.",
      name: "applied_scholarship_funds_to_event",
      flag: false,
    },
    {
      id: 4,
      label: "Allow Discount Codes to be applied to this event.",
      name: "allow_discount_code_to_event",
      flag: false,
    },
    {
      id: 5,
      label: "Require approval for registrations outside of selected grades",
      name: "approval_grade",
      flag: false,
    },
    {
      id: 6,
      label: "Make this event private, invite only (Coming Soon)",
      name: "is_event_private",
      flag: false,
    },
    {
      id: 7,
      label: "Allow a single pack to be applied during registration",
      name: "allow_single_pack",
      flag: false,
    },
  ]);
  const [positionsList, setPositions] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  const { mutateAsync: getAllPositions } = useMutation(getAllPositionsList);
  const { mutateAsync: getStaffs } = useMutation(getCoach);

  useEffect(() => {
    getProductList();
    getPositions();
    getStaff();
  }, []);

  useEffect(() => {
    if (step4Details) {
      dispatch({ type: SET_LOADER, data: true });
      // let selectedStaffs = selectedEvent?.staff_members ? selectedEvent?.staff_members?.map(staff => staff?.id) : [];
      // let products = selectedEvent?.products ? selectedEvent?.products?.map(product => product?.id) : [];
      // if(selectedEvent) {
      //   let permission = [...permissions];
      //   permission[0]["flag"] = step4Details ? step4Details?.permissions[0]["flag"] : selectedEvent?.allow_drop_in;
      //   permission[1]["flag"] = step4Details ? step4Details?.permissions[1]["flag"] : selectedEvent?.allow_athletes;
      //   permission[2]["flag"] = step4Details ? step4Details?.permissions[2]["flag"] : selectedEvent?.allow_scholarship;
      //   permission[3]["flag"] = step4Details ? step4Details?.permissions[3]["flag"] : selectedEvent?.allow_discount_code;
      //   permission[4]["flag"] = step4Details ? step4Details?.permissions[4]["flag"] : selectedEvent?.approval_grade;
      //   permission[5]["flag"] = step4Details ? step4Details?.permissions[5]["flag"] : selectedEvent?.visibility === "private" ? true : false;
      //   setPermissions(permission);
      // }
      setPermissions(
        step4Details?.permissions?.length
          ? step4Details?.permissions
          : permissions,
      );
      setselectedProduct(
        step4Details?.selectedProduct?.length
          ? step4Details?.selectedProduct
          : [],
      );
      setSelectedStaff(
        step4Details?.staff_members?.length ? step4Details?.staff_members : [],
      );
      if (step4Details) {
        setSelectedPosition(step4Details?.position ?? []);
      }
      dispatch({ type: SET_LOADER, data: false });
    }
  }, [step4Details]);

  const getProductList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      productType: "",
      grade: "",
    };
    let response = await getAllProductPackages(params, dispatch);
    if (response?.ok) {
      let dataObj = response?.data ?? [];
      let dataList = [];
      for (const key in dataObj) {
        if (dataObj.hasOwnProperty(key)) {
          const data = dataObj[key];
          dataList.push({
            categoryName: key,
            data: data,
          });
        }
      }
      setProductList(dataList ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const getPositions = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllPositions({ dispatch, params });
    if (response?.ok) {
      setPositions(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const getStaff = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: offset,
      limit: 20,
    };
    let response = await getStaffs({ dispatch, params });
    if (response?.ok) {
      const res = response?.data?.data ?? [];
      setStaffList((prevOptions) => [...prevOptions, ...res]);
      setOffset((prevOffset) => prevOffset + res.length);
      if (res.length === 0) {
        setHasMore(false);
      }
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target?.scrollHeight - target?.scrollTop === target?.clientHeight &&
      hasMore
    ) {
      getStaff();
    }
  };

  const handleChangeProductSelection = (event) => {
    const {
      target: { value },
    } = event;
    setselectedProduct(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePositionSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPosition(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeStaffMemberSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStaff(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePermissions = (e, index) => {
    let flag = e?.target?.checked;
    let copy = [...permissions];
    copy[index]["flag"] = flag;
    setPermissions(copy);
  };

  const handleNext = () => {
    // if(!selectedProduct?.length) {
    //   dispatch({
    //     type: SET_TOAST,
    //     data: {
    //       type: "error",
    //       message: "Product selection is required",
    //     },
    //   });
    //   return;
    // }
    let data = {
      selectedProduct,
      permissions,
      position: selectedPosition ?? [],
      staff_members: selectedStaff ?? [],
    };
    dispatch({
      type: "STEP_4_DETAILS",
      data,
    });
    onNext();
  };

  return (
    <Box p={3} mt={1}>
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
        }}
      >
        Enter your event details below. You will be guided through a few steps
        to create your event. At anytime, you can select the step you’d like to
        go back to without losing any information.
      </Typography>
      <Box mt={3}>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={5.5}>
            <Box>
              <CustomInputLabel sx={inputLabelStyle}>
                CHOOSE PRODUCTS/PACKAGES THAT CAN BE USED FOR THIS EVENT
              </CustomInputLabel>
              <Select
                multiple
                displayEmpty
                value={selectedProduct}
                onChange={handleChangeProductSelection}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select products..."
                    sx={selectionInputStyle}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={(selected) => {
                  if (selected?.length && productList?.length) {
                    let products = [];
                    productList?.forEach((product) => {
                      if (product?.data && product?.data?.length) {
                        product?.data?.filter((productVal) => {
                          if (selected.includes(productVal?.id)) {
                            products.push(productVal?.name);
                          }
                        });
                      }
                    });
                    if (products?.length) {
                      return products.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select one or more products...
                      </Typography>
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                {productList && productList?.length
                  ? productList?.map((product) =>
                      product?.data && product?.data?.length
                        ? product?.data?.map((productVal) =>
                            productVal?.is_activated ? (
                              <MenuItem
                                key={productVal?.id}
                                value={productVal?.id}
                              >
                                <Checkbox
                                  checked={
                                    selectedProduct.indexOf(productVal?.id) > -1
                                  }
                                />
                                <ListItemText primary={productVal?.name} />
                              </MenuItem>
                            ) : null,
                          )
                        : null,
                    )
                  : null}
              </Select>
            </Box>

            <Box mt={2.5}>
              <CustomInputLabel sx={inputLabelStyle}>
                TAG PLAYER POSITIONS FOR SEARCH
              </CustomInputLabel>
              <Select
                multiple
                displayEmpty
                value={selectedPosition}
                onChange={handleChangePositionSelection}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select position..."
                    sx={selectionInputStyle}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={(selected) => {
                  if (selected?.length && positionsList?.length) {
                    let positions = [];
                    positionsList?.forEach((position) => {
                      if (selected.includes(position?.id)) {
                        positions.push(position?.name);
                      }
                    });
                    if (positions?.length) {
                      return positions.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select one or more position...
                      </Typography>
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                {positionsList && positionsList?.length
                  ? positionsList?.map((position) => (
                      <MenuItem key={position?.id} value={position?.id}>
                        <Checkbox
                          checked={selectedPosition.indexOf(position?.id) > -1}
                        />
                        <ListItemText primary={position?.name} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Box>

            <Box mt={2.5}>
              <CustomInputLabel sx={inputLabelStyle}>
                STAFF MEMBER
              </CustomInputLabel>
              <Select
                multiple
                displayEmpty
                value={selectedStaff}
                onChange={handleChangeStaffMemberSelection}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select staff member..."
                    sx={selectionInputStyle}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={(selected) => {
                  if (selected?.length && staffList?.length) {
                    let staffMembers = [];
                    staffList?.forEach((staff) => {
                      if (selected.includes(staff?.id)) {
                        staffMembers.push(
                          `${staff?.first_name} ${staff?.last_name}`,
                        );
                      }
                    });
                    if (staffMembers?.length) {
                      return staffMembers.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select one or more staff member...
                      </Typography>
                    );
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 300,
                    },
                    onScroll: (event) => {
                      handleScroll(event);
                    },
                  },
                }}
              >
                {staffList && staffList?.length
                  ? staffList?.map((staff) => (
                      <MenuItem key={staff?.id} value={staff?.id}>
                        <Checkbox
                          checked={selectedStaff.indexOf(staff?.id) > -1}
                        />
                        <ListItemText
                          primary={`${staff?.first_name} ${staff?.last_name}`}
                        />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={1}
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                width: "1px",
                height: "100%",
                background: "grey",
                opacity: 0.4,
                m: "auto",
              }}
            ></Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5.5}>
            <Box>
              <Typography
                variant="h5"
                fontWeight={700}
                fontSize={17}
                color={theme?.palette?.text?.containerTextLabel}
              >
                ADDITIONAL PERMISSIONS
              </Typography>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize={13}
                color={theme?.palette?.text?.containerTextLabel}
                sx={{ mt: 1, letterSpacing: "0.1px" }}
              >
                If you’d like to restrict specific age groups to this package,
                remove the check the box next to the appropriate age group.
              </Typography>
              <Box mt={2} pl={1.5}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {permissions?.map((permission, index) => (
                    <FormControlLabel
                      key={permission?.id}
                      sx={{ mt: 1.5 }}
                      control={
                        <CustomSwitch
                          name={permission?.name}
                          checked={permission?.flag}
                          onChange={(e) => handleChangePermissions(e, index)}
                          sx={{ mr: 1 }}
                        />
                      }
                      label={permission?.label}
                      disabled={
                        permission?.name === "is_event_private" ? true : false
                      }
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          width={1}
          mt={6}
        >
          <Typography
            variant="h5"
            sx={{
              maxWidth: 120,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer",
              mr: 2,
              my: 1,
              px: 3,
            }}
            onClick={() => setShowCancelConfirmPopup(true)}
          >
            Cancel
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
              variant="h5"
              sx={{
                maxWidth: 120,
                height: 40,
                background: "transparent",
                border: `2px solid ${theme?.palette?.secondary?.dark}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme?.palette?.secondary?.dark,
                cursor: "pointer",
                px: 3,
                my: 1,
              }}
              onClick={onBack}
            >
              Back
            </Typography>
            <Button
              size="small"
              variant="contained"
              // type="submit"
              sx={{
                maxWidth: 160,
                height: 40,
                background: theme?.palette?.secondary?.dark,
                color: "white",
                ml: 2,
                px: 3,
                my: 1,
              }}
              onClick={handleNext}
            >
              Review & Publish
            </Button>
          </Box>
        </Box>
        {/* </form> */}
      </Box>
    </Box>
  );
}
