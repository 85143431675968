import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import { useRef } from "react";
import { useState } from "react";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  acceptDenyRequestAction,
  getAdminUsers,
  getAllRequestAction,
  getAllSubOrg,
} from "../../store/actions";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTheme } from "@emotion/react";
import ActionCard from "../../components/Common/ActionCard";
import CustomTextField from "../../components/CustomTextField";
import { charValidate } from '@utils/functions';
import { dateFormat, phoneFormat, timeFormatWithDate } from "../../utils/functions";

const Approval = () => {
  const tableRef = useRef();
  const [dataList, setDataList] = useState();
  const [showDenyRequestPopup, setShowDenyRequestPopup] = useState(false);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [denyReason, setDenyReason] = useState("");
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getDataList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getDataList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getAllRequestAction(params);
    if (response?.ok) {
      setDataList(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getDataList();
  };

  const columns = [
    {
      field: "type",
      headerName: "Type",
      flex: 1.3,
      minWidth: 120,
      renderCell: (data) => (data?.row?.type ? data?.row?.type : "N/A"),
      sortable: false,
    },
    // {
    //   headerName: "",
    //   flex: 0.3,
    //   sortable: false,
    // },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      minWidth: 125,
      renderCell: (data) =>
        data?.row?.user?.first_name ? data?.row?.user?.first_name : "N/A",
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 125,
      renderCell: (data) =>
        data?.row?.user?.last_name ? data?.row?.user?.last_name : "N/A",
      sortable: false,
    },
    {
      field: "event?.title",
      headerName: "Event Detail",
      flex: 1,
      minWidth: 210,
      renderCell: (data) => (
        <>
          {
            Object.keys(data?.row?.event).length !== 0 ?
              <>
                <Tooltip
                  title={
                    <>
                      <Box display="flex">
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 700,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                          }}
                        >
                          Name:
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                            ml: 1,
                          }}
                        >
                          {data?.row?.event?.title ?? "N/A"}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 700,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                          }}
                        >
                          Date:
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                            ml: 1,
                          }}
                        >
                          {data?.row?.event?.start_datetime_utc ? dateFormat(data?.row?.event?.start_datetime_utc) : "N/A"}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 700,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                          }}
                        >
                          Time:
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                            ml: 1,
                          }}
                        >
                          {data?.row?.event?.start_datetime_utc ? timeFormatWithDate(data?.row?.event?.start_datetime_utc) : "N/A"}
                        </Typography>
                      </Box>
                    </>
                  }
                >
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                      }}
                    >
                      Name:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                        ml: 1,
                      }}
                    >
                      {data?.row?.event?.title
                        ? charValidate(data?.row?.event?.title, 10)
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                      }}
                    >
                      Date:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                        ml: 1,
                      }}
                    >
                      {data?.row?.event?.start_datetime_utc ? dateFormat(data?.row?.event?.start_datetime_utc) : "N/A"}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                      }}
                    >
                      Time:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                        ml: 1,
                      }}
                    >
                      {data?.row?.event?.start_datetime_utc ? timeFormatWithDate(data?.row?.event?.start_datetime_utc) : "N/A"}
                    </Typography>
                  </Box>
                </Tooltip>
              </> :
              "N/A"
          }
        </>
      ),
      sortable: false,
    },
    {
      field: "code",
      headerName: "Access Code",
      flex: 1.4,
      minWidth: 130,
      sortable: false,
      renderCell: (data) => (
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: theme?.palette?.text?.darkTextSubHeader,
            fontFamily: "Poppins",
            ml: 1,
          }}
        >
          {data?.row?.code ? data?.row?.code : "N/A"}
        </Typography>
      ),
    },
    {
      field: "request_reason",
      headerName: "Request Reason",
      flex: 1.4,
      minWidth: 140,
      sortable: false,
      renderCell: (data) => (
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: theme?.palette?.text?.darkTextSubHeader,
            fontFamily: "Poppins",
            ml: 1,
          }}
        >
          {data?.row?.request_reason ? data?.row?.request_reason : "N/A"}
        </Typography>
      ),
    },
    {
      field: "reject_reason",
      headerName: "Reject Reason",
      flex: 1.4,
      minWidth: 200,
      sortable: false,
      renderCell: (data) => (
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: theme?.palette?.text?.darkTextSubHeader,
            fontFamily: "Poppins",
            ml: 1,
          }}
        >
          {data?.row?.reject_reason ? data?.row?.reject_reason : "N/A"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.2,
      minWidth: 130,
      renderCell: (data) =>
        data?.row?.status ? data?.row?.status : "N/A",
      sortable: false,
    },
    {
      field: "contact",
      headerName: "Contact",
      sortable: false,
      minWidth: 150,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.user?.email && data?.row?.user?.mobile ? (
              <>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                    }}
                  >
                    E:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                      ml: 1,
                    }}
                  >
                    {data?.row?.user?.email ?? "N/A"}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                    }}
                  >
                    M:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                      ml: 1,
                    }}
                  >
                    {data?.row?.user?.mobile ?? "N/A"}
                  </Typography>
                </Box>
              </>
            ) : "N/A"
          }
        >
          <Box display="flex">
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
              }}
            >
              E:
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
                ml: 1,
              }}
            >
              {data?.row?.user?.email
                ? charValidate(data?.row?.user?.email, 10)
                : "N/A"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
              }}
            >
              M:
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 400,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
                ml: 1,
              }}
            >
              {data?.row?.user?.mobile
                ? phoneFormat(data?.row?.user?.mobile)
                : "N/A"}
            </Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 130,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => (
        <Box hidden={data?.row?.status !== "pending" ?? false}>
          <SettingsIcon
            onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
            sx={{ fontSize: 22, cursor: "pointer" }}
            color="primary"
          />
          <Popover
            id={id}
            open={openPopover}
            anchorEl={popoverAnchorEl}
            onClick={() => setPopoverAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              p: 1,
              "& .MuiPaper-root": {
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
              },
            }}
          >
            <ActionCard title={"Actions"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{
                    minWidth: 175,
                    height: 43,
                    bgcolor: theme?.palette?.inputsLabels?.green,
                    fontSize: 12,
                  }}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    minWidth: 175,
                    height: 43,
                    background: theme?.palette?.error?.dark,
                    fontSize: 12,
                  }}
                  onClick={handleDeny}
                >
                  Deny with response
                </Button>
              </Box>
            </ActionCard>
          </Popover>
        </Box>
      ),
    },
  ];

  const handleApprove = () => {
    setPopoverAnchorEl(null);
    acceptDenyRequest({
      id: selectedDataGridRow?.id ?? "",
      status: "approved",
      reject_reason: "",
    });
  };

  const handleDeny = () => {
    setPopoverAnchorEl(null);
    setShowDenyRequestPopup(true);
  };

  const handleRequestDenySubmit = () => {
    acceptDenyRequest({
      id: selectedDataGridRow?.id ?? "",
      status: "rejected",
      reject_reason: denyReason ?? "",
    });
    setShowDenyRequestPopup(false);
  };

  const handleCloseDenyPopup = (e) => {
    e.preventDefault();
    setShowDenyRequestPopup(false);
    setDenyReason("");
  };

  const acceptDenyRequest = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await acceptDenyRequestAction(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response?.message ?? "Success!" }
      })
      setShowDenyRequestPopup(false);
      setDenyReason("");
      getDataList();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
      })
    }
  };

  const renderDenyRequestPopup = () => {
    return (
      <Dialog
        open={showDenyRequestPopup}
        keepMounted
        maxWidth="xs"
        fullWidth
        onClose={handleCloseDenyPopup}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={handleRequestDenySubmit}>
          <DialogTitle sx={{ p: 0 }}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              py={3}
              ml={2}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Deny Approval Request
            </Typography>
            <Box
              width={1}
              height={"1px"}
              bgcolor={"gray"}
              sx={{ opacity: 0.25 }}
            ></Box>
          </DialogTitle>
          <DialogContent
            sx={{
              pt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pb: 5,
            }}
          >
            <Typography
              sx={{ pt: 2, pb: 2 }}
              fontFamily={"Poppins"}
              fontSize={16}
              fontWeight={600}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Are you sure, want to deny this request?
            </Typography>
            <CustomTextField
              name="denyReason"
              placeholder={"Enter reason..."}
              variant="outlined"
              size="small"
              onChange={(e) => {
                let { value } = e?.target;
                setDenyReason(value?.replace(/^\s*\s*$/, ""))
              }}
              value={denyReason}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: 350,
                  boxShadow: "none",
                },
              }}
              required
            />
          </DialogContent>
          <DialogActions sx={{ pr: 4, pb: 3 }}>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
              color="inherit"
              onClick={handleCloseDenyPopup}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
              type="submit"
              disabled={!denyReason}
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Approvals"}
        gridData={dataList}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={dataList ? false : true}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
        isAddNewBtnShow={false}
      />
      {showDenyRequestPopup && renderDenyRequestPopup()}
    </>
  );
};

export default Approval;
