import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from '@store/actions'
import { Box, Typography, Grid, Button } from '@mui/material'
import productIcon from "../../assets/images/icons/new-product@2x.png"
import { useNavigate } from 'react-router'

const ComingSoon = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        dispatch(goBack(null))
    }, [])

    // const navigate = useNavigate();

    // // const handleNavigate = () => {
    // //     if (user.user_type === 'parent') {
    // //         navigate("/dashboard-parent")
    // //     } else if (user.user_type === 'player') {
    // //         navigate("/dashboard-athlete")
    // //     } else if (user.user_type === 'coach') {
    // //         navigate("/dashboard-coach")
    // //     } else if (user.user_type === 'admin' || user.user_type === 'superadmin') {
    // //         navigate("/dashboard")
    // //     }
    // // }

    return (
        <>
            <Box sx={{
                p: 3,
                maxWidth: '400px', width: '100%', margin: '0 auto'
            }}>
                <Typography variant='h2'>
                    Coming Soon!
                </Typography>
                <Typography variant='h5' sx={{ mt: 5, mb: 4 }}>
                    You tapped on a feature that is still in development. Soon, you'll be able to engage with several different features including the one you just clicked on!
                </Typography>
                {/* <Typography variant='h5' sx={{ mt: 2, mb: 4, cursor: "pointer", color: '#747474', }} onClick={handleNavigate}>
                    Tap here to go back to the scheduler dashboard.
                </Typography> */}
                <img src={productIcon} alt="Box Icon" height="140px" />
                <Typography variant='h2' sx={{ mt: 4, color: '#383C50', fontSize: '1.35rem', }}>
                    Want to See the Gridiron Product Roadmap?
                </Typography>
                <Typography variant='h5' sx={{ mt: 4, mb: 8 }}>
                    Our team has partnered with the Sketchplay team and together, we're constantly working hard to create the best experience for you no matter the device. If you have questions about our roadmap send us a message.
                </Typography>
                {user.user_type === "admin" ? null :
                    <Box sx={{ width: "100%" }}>
                        <Button size="large" sx={{ width: "100%" }} variant='contained'>
                            Contact Us
                        </Button>
                    </Box>

                }
            </Box>
        </>
    )

}

export default ComingSoon
