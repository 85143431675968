import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, createTeamCoachAction, createTeamManagerAction } from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { convertToCapsOfFirstLetter } from "@utils/functions";

const AddTeamCoach = ({
  open,
  handleClose,
  teamId = "",
  isTeamCoach = false
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      countryCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParam = {
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        email: values?.email ?? "",
        countryCode: values?.countryCode?.toUpperCase() ?? "",
        mobile: values?.mobile ?? "",
        team: teamId ? [teamId] : [],
        is_team_coach: isTeamCoach,
      };
      createTeamCoach(bodyParam);
    },
  });

  const createTeamCoach = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await createTeamCoachAction(dispatch, params, bodyParams);
    if (response?.ok) {
      handleClose(false, response?.data?.id, response?.data?.first_name + " " + response?.data?.last_name);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response.message ?? "Success!" }
      })
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
      })
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          pt={1}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Add Team Coach
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }} mb={3}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      {/* <Box
        width={1}
        height={"1px"}
        bgcolor={"gray"}
        sx={{ opacity: 0.25 }}
      ></Box> */}
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ paddingX: 6 }}>
          {/* <Typography
            variant="h5"
            fontWeight={700}
            fontSize={17}
            color={theme?.palette?.text?.containerTextLabel}
          >
            User Information
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomInputLabel htmlFor="first-name">
                First Name
              </CustomInputLabel>
              <CustomTextField
                id="first-name"
                name="firstName"
                placeholder="Enter first name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
                value={formik.values.firstName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: 350,
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.firstName && formik.errors.firstName) && (
                <FormHelperText error>{formik.errors.firstName}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel htmlFor="last-name">Last Name</CustomInputLabel>
              <CustomTextField
                id="last-name"
                name="lastName"
                placeholder="Enter last name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'lastName')}
                value={formik.values.lastName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: 350,
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.lastName && formik.errors.lastName) && (
                <FormHelperText error>{formik.errors.lastName}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6} mt={1}>
              <CustomInputLabel htmlFor="email">Email Address</CustomInputLabel>
              <CustomTextField
                id="email"
                name="email"
                placeholder="Enter email..."
                variant="outlined"
                size="small"
                type="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email.toLowerCase()}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: 350,
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} mt={1}>
              <CustomInputLabel htmlFor="mobile-number">
                Mobile Number
              </CustomInputLabel>
              <Box
                sx={{
                  "& .react-tel-input .form-control": {
                    minWidth: "100%",
                    fontWeight: 500,
                    background: theme?.palette?.grey[50],
                    height: "42px",
                  },
                }}
              >
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  placeholder="(702) 123-4567"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onChange={(value, country) => {
                    formik.setFieldValue("countryCode", country?.countryCode || "");
                    formik.setFieldValue("mobile", value || "");
                  }}
                  error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  size="sm"
                  style={{
                    width: 350,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                    },
                  }}
                  disableDropdown={true}
                  disableCountryCode={true}
                  onlyCountries={["us"]}
                />
                {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                  <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 2 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTeamCoach;
