import * as actionTypes from '@store/actions'

export const initialState = {
    athleteArr: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ATHLETEARR: {
            return {
                ...state,
                athleteArr: action.data,
            }
        }

        case actionTypes.ADD_ATHLETEARR: {
            return {
                ...state,
                athleteArr: [action.data, ...state.athleteArr],
            }
        }

        case actionTypes.UPDATE_ATHLETEARR: {
            const athleteArr = [...state.athleteArr]
            const findIndex = athleteArr.findIndex((x) => x.id === action.data.id)
            athleteArr[findIndex] = action.data
            return {
                ...state,
                athleteArr,
            }
        }

        default: {
            return state
        }
    }
}

export default reducer
