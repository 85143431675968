import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Grid,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

const ViewTeamsModal = ({ open, handleClose, selectedData }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="md"
            fullWidth
            onClose={() => handleClose()}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2.5}
                    pt={1}
                >
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Teams Name
                    </Typography>
                </Box>
            </DialogTitle>
            <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }}>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
            </Box>
            <DialogContent sx={{ paddingX: 6 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} mt={2}>
                        {selectedData?.team?.map(team => (
                            <Typography
                                variant="h6"
                                fontWeight={400}
                                fontSize={14}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{ mt: 1, letterSpacing: "0.1px" }}
                            >
                                {team?.title + ", "}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ margin: 2 }}>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                    color="inherit"
                    onClick={() => handleClose()}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewTeamsModal;
