import {
    Box,
    Button,
    FormHelperText,
    Grid,
    useTheme,
    Typography, InputAdornment, FormControlLabel, Switch, Divider
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import httpClient from "@utils/httpClient";
import { styled } from "@mui/system";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeleteConfirmPopup from "../Common/DeleteConfirm";
import PackagePackDiscount from "./PackagePackDiscount";

const PackagePack = ({ handleClose, setStep, formData, setFormData, activeProduct, isFromDuplicate }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [packIds, setPackIds] = useState(formData?.packs?.length > 0 ? formData?.packs?.map(x => x.id) : [])
    const [isPackRequired, setIsPackRequired] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [packId, setPackId] = useState('');
    const [discountInputFields, setDiscountInputFields] = useState(formData?.discount?.length > 0 ? formData?.discount : [])
    const [inputFields, setInputFields] = useState(
        isFromDuplicate
            ? [
                {
                    idObj: 'title-id-0',
                    title: '',
                    pack: '',
                    amount: '',
                    is_active: true
                }
            ]
            : (formData?.packs?.length > 0
                ? [...formData?.packs, {
                    idObj: 'title-id-0',
                    title: '',
                    pack: '',
                    amount: '',
                    is_active: true
                }]
                : [
                    {
                        idObj: 'title-id-0',
                        title: '',
                        pack: '',
                        amount: '',
                        is_active: true
                    }
                ])
    );

    const CustomSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: "#00B60D",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const handleSave = async () => {
        const packs = packIds;
        const postData = { ...formData, packs }
        if (packs.length > 0) {
            if (postData?.rowType) {
                delete postData.id; // for clone product
            }
            postData.cost = 0;
            postData.session_per_package = 0;
            const API_URL = postData?.id ? "/updateProduct" : "/addProduct";
            dispatch({ type: SET_LOADER, data: true });
            const res = await httpClient.post(API_URL, postData);
            dispatch({ type: SET_LOADER, data: false });
            if (res && res.ok) {
                setFormData({})
                setInputFields([])
                setDiscountInputFields([])
                handleClose(false)
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                })
                return false;
            }
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: "Please add at least one package pack" }
            })
            return false;
        }
    }

    const handleFieldChange = (index, fieldName, value) => {
        if (fieldName === 'title' && value.trim()) {
            setIsPackRequired(false)
        }
        const updatedFields = [...inputFields];
        updatedFields[index][fieldName] = value;
        setInputFields(updatedFields);
    };

    const handleAddInputField = async (indexVal) => {
        const fieldValues = inputFields.map((field) => ({
            title: field.title,
            pack: parseInt(field.pack),
            amount: parseFloat(field.amount),
            is_active: field.is_active,
        }));
        if (fieldValues.length > 0) {
            const reqPayload = fieldValues[indexVal];
            const newArray = inputFields.filter((item, index) => index !== indexVal);
            if (reqPayload?.title && reqPayload?.pack && (reqPayload?.amount || reqPayload?.amount === 0)) {
                dispatch({ type: SET_LOADER, data: true });
                const res = await httpClient.post("/createPack", reqPayload);
                dispatch({ type: SET_LOADER, data: false });
                if (res && res.ok) {
                    const updateArr = [...packIds, res?.data?.id]
                    setPackIds(updateArr)
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'success', message: "This pack created successfully" }
                    })
                    setIsPackRequired(false)
                    setInputFields((prevInputFields) => [
                        res.data,
                        ...newArray,
                        {
                            idObj: `title-id-${inputFields.length}`,
                            title: '',
                            pack: '',
                            amount: '',
                            is_active: true,
                        },
                    ]);

                } else {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                    })
                    return false;
                }
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: "All Package pack fields must be required" }
                })
                return false;
            }
        }
    };

    const handleEditInputField = async (indexVal) => {
        const fieldValues = inputFields.map((field) => ({
            ...field,
            pack: parseInt(field.pack),
            amount: parseFloat(field.amount)
        }));
        if (fieldValues.length > 0) {
            const reqPayload = fieldValues[indexVal];
            const newArray = inputFields.filter((item, index) => index !== indexVal);
            if (reqPayload?.title && reqPayload?.pack && (reqPayload?.amount || reqPayload?.amount === 0)) {
                dispatch({ type: SET_LOADER, data: true });
                const res = await httpClient.post("/createPack", reqPayload);
                dispatch({ type: SET_LOADER, data: false });
                if (res && res.ok) {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'success', message: "This pack updated successfully" }
                    })
                    setIsPackRequired(false)
                    setInputFields((prevInputFields) => [
                        res.data,
                        ...newArray
                    ]);

                } else {
                    dispatch({
                        type: SET_SUCCESS_ERROR_POPUP,
                        data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                    })
                    return false;
                }
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: "All Package pack fields must be required" }
                })
                return false;
            }
        }
    };

    const handleDeletePack = async () => {
        dispatch({ type: SET_LOADER, data: true });
        const postData = { id: parseInt(packId) }
        const res = await httpClient.post("/deletePack", postData);
        dispatch({ type: SET_LOADER, data: false });
        if (res && res.ok) {
            const filterArr = inputFields.filter((item, index) => item?.id !== packId);
            const updateArr = packIds.filter((item, index) => item !== packId);
            setPackIds(updateArr)
            setInputFields((prevInputFields) => [
                ...filterArr
            ]);
            setPackId('')
            setShowDeletePopup(false);
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: res.message ?? "Something went wrong" }
            })
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
            })
            return false;
        }
    }

    const handleBackClick = () => {
        setIsPackRequired(false);
        const fieldFilterArr = inputFields.filter(x => x.title !== '' && x.pack !== '' && x.amount !== '')
        const packs = fieldFilterArr;
        const discount = discountInputFields;
        setFormData((prevStage) => {
            return {
                ...prevStage,
                packs,
                discount
            };
        });
        setStep(4)
    }

    const handleDiscountInputFields = (discountData) => {
        setDiscountInputFields(discountData)
    }

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Add Packs
                    </Typography>
                </Grid>
                {inputFields.map((inputField, index) => (
                    <>
                        <Grid item xs={2} sx={{ mt: 4 }}>
                            <FormControlLabel
                                control={
                                    <CustomSwitch
                                        id={inputField.idObj}
                                        name={`is_active-${index}`}
                                        checked={inputField.is_active}
                                        onChange={(e) =>
                                            handleFieldChange(index, 'is_active', e.target.checked)
                                        }
                                        size="sm"
                                        sx={{ m: 1 }}
                                    />
                                }
                                label="Active"
                                labelPlacement="start"
                                sx={{
                                    "& .MuiTypography-root": {
                                        color: theme?.palette?.text?.containerTextLabel,
                                        fontWeight: 400,
                                        fontFamily: "inherit",
                                    },
                                }}
                            //disabled={inputField?.hasOwnProperty("id")}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Name
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`title-${index}`}
                                placeholder="Enter a name"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'title', e.target.value)
                                }
                                value={inputField.title}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                disabled={!inputField?.is_active}
                            />
                            {(isPackRequired && !inputField.name) && (
                                <FormHelperText error>Name is required</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={2}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Pack
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`pack-${index}`}
                                type="number"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'pack', e.target.value)
                                }
                                value={inputField.pack}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                disabled={!inputField?.is_active}
                            />
                            {(inputField?.pack < 0) && (
                                <FormHelperText error>Pack Value must be greater than to 0</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={2}>
                            <CustomInputLabel htmlFor={inputField.idObj}>
                                Amount
                            </CustomInputLabel>
                            <CustomTextField
                                id={inputField.idObj}
                                name={`amount-${index}`}
                                type="number"
                                variant="outlined"
                                size="small"
                                onChange={(e) =>
                                    handleFieldChange(index, 'amount', e.target.value)
                                }
                                value={inputField.amount}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ ml: 1 }}>
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={!inputField?.is_active}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {
                                inputField?.hasOwnProperty("id") ?
                                    <>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="button"
                                            sx={{ width: "70px", height: "45px", mt: 3.5, mr: 1 }}
                                            onClick={() => { handleEditInputField(index) }}
                                        //disabled={!inputField?.is_active}
                                        >
                                            <EditOutlinedIcon
                                                sx={{ fontSize: 22, cursor: "pointer", }}
                                            />
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="button"
                                            sx={{ width: "70px", height: "45px", mt: 3.5 }}
                                            onClick={() => { setPackId(inputField?.id); setShowDeletePopup(true) }}
                                        //disabled={!inputField?.is_active}
                                        >
                                            <DeleteSweepIcon
                                                sx={{ fontSize: 22, cursor: "pointer", }}
                                            />
                                        </Button>
                                    </>

                                    :
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="button"
                                        sx={{ width: "70px", height: "45px", mt: 3.5, fontSize: "25px" }}
                                        onClick={() => handleAddInputField(index)}
                                    >
                                        +
                                    </Button>
                            }

                        </Grid>
                    </>
                ))}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Divider sx={{ mt: 4, mb: 4 }}></Divider>
                </Grid>
            </Grid>
            <PackagePackDiscount
                handleClose={handleClose}
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
                isBackClick={handleDiscountInputFields}
                isFromDuplicate={isFromDuplicate}
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mt: 4 }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                    color="inherit"
                    onClick={() => { setIsPackRequired(false); setInputFields([]); setDiscountInputFields([]); handleClose(true); }}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                    color="inherit"
                    onClick={() => handleBackClick()}
                >
                    Back
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    type="button"
                    sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                    onClick={() => handleSave()}
                >
                    Finish
                </Button>
            </Box>
            {showDeletePopup &&
                <DeleteConfirmPopup
                    title={"Pack"}
                    message={"Are you sure you want to delete this pack?"}
                    open={showDeletePopup}
                    handleClose={() => {
                        setShowDeletePopup(false);
                        setPackId('')
                    }}
                    handleConfirm={handleDeletePack}
                />
            }
        </>
    );
};

export default PackagePack;
