import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

import config from '@src/config'

const app = initializeApp({
    apiKey: config.gcp.API_KEY,
    storageBucket: config.gcp.STORAGE_BUCKET,
})

export const firebaseAuth = getAuth(app)
export const firebaseStorage = getStorage(app)
