
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, Grid, InputLabel, TextField, Tooltip, Typography } from '@mui/material'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MuiDataGrid from '../../components/MuiDataGrid/MuiDataGrid';
import { useRef } from 'react';
import { useState } from 'react';
import { getAllContacts } from '../../store/actions';
// import SettingsIcon from "@mui/icons-material/Settings";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import CustomTextField from '../../components/CustomTextField';
import PhoneInput from 'react-phone-input-2';
import { styled } from "@mui/system";
import { useTheme } from '@emotion/react';
import { charValidate } from '@utils/functions'
import { convertToCapsOfFirstLetter } from "@utils/functions";


const validationSchema = Yup.object({
  firstName: Yup.string().trim(),
  lastName: Yup.string().trim(),
  email: Yup.string()
    .trim()
    .email("Must be a valid email"),
  mobile: Yup.string().min(10),
  message: Yup.string(),
});



const Support = () => {
  const tableRef = useRef();
  const [contactsData, setContactsData] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [supportData, setSupportData] = useState("");
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
  });
  const CustomInputLabel = styled("label")({
    color: theme?.palette?.text?.containerTextLabel,
    letterSpacing: "0.1px",
    fontSize: 14,
    marginBottom: "10px",
    textTransform: "uppercase",
    fontFamily: "Poppins, sans-serif,Arial",
    display: "block",
    fontWeight: 500,
  });

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getContactsList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getContactsList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getAllContacts(params);
    if (response) {
      setContactsData(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getContactsList();
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      renderCell: (data) => (
        <Tooltip title="view details">
          <AddCircleIcon
            onClick={() => { setOpen(true); setSupportData(data); }}
            sx={{ fontSize: 22, cursor: "pointer" }}
            color="primary"
          />
        </Tooltip>
      ),
    },
    {
      field: "mobile",
      headerName: "Number",
      flex: 1,
      sortable: false,
      minWidth: 80,
      renderCell: (data) => {
        return data?.id
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      sortable: false,
      minWidth: 110,
      renderCell: (data) => {
        const firstName = data?.row.first_name;
        return <strong>{firstName}</strong>;
      },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      sortable: false,
      minWidth: 110,
      renderCell: (data) => {
        const lastName = data?.row?.last_name;
        return <strong>{lastName}</strong>;
      },
    },
    {
      field: "user",
      headerName: "Created by",
      flex: 1,
      sortable: false,
      minWidth: 150,
      renderCell: (data) => {
        const createdBy = data?.row?.user?.email ?? "N/A";
        return <Tooltip title={createdBy}>
          <strong>{charValidate(createdBy, 10)}</strong>
        </Tooltip>
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      sortable: false,
      minWidth: 140,
      renderCell: (data) => {
        return <Tooltip title={data?.row?.message}>
          {charValidate(data?.row?.message, 15)}
        </Tooltip>
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      sortable: false,
      minWidth: 150,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.email || data?.row?.mobile ? (
              <>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                    }}
                  >
                    E:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                      ml: 1,
                    }}
                  >
                    {data?.row?.email ?? "N/A"}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                    }}
                  >
                    M:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: theme?.palette?.text?.darkTextSubHeader,
                      fontFamily: "Poppins",
                      ml: 1,
                    }}
                  >
                    {data?.row?.mobile ?? "N/A"}
                  </Typography>
                </Box>
              </>
            ) : "N/A"
          }
        >
          <Box display="flex">
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
              }}
            >
              E:
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
                ml: 1,
              }}
            >
              {data?.row?.email
                ? charValidate(data?.row?.email, 10)
                : "N/A"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
              }}
            >
              M:
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: theme?.palette?.text?.darkTextSubHeader,
                fontFamily: "Poppins",
                ml: 1,
              }}
            >
              {data?.row?.mobile
                ? charValidate(data?.row?.mobile, 10)
                : "N/A"}
            </Typography>
          </Box>
        </Tooltip>
      ),
    }
  ];

  return (
    <>
      <MuiDataGrid
        headerLabel={"Support Tickets"}
        gridData={contactsData}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={contactsData ? false : true}
        isSearchIconShow={false}
        isAddNewBtnShow={false}
      />
      <Dialog
        open={open}
        keepMounted
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box sx={{
            display: "flex", justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            }
          }}>
            <Typography variant='h3'>Support Ticket</Typography>
            <Typography variant="h3" sx={{
              pl: 2,
              [theme.breakpoints.down('sm')]: {
                pl: 0,
                mt: 2,
              }
            }}>#{supportData?.id}</Typography>
          </Box>
        </DialogTitle>
        <Divider></Divider>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ p: 3, overflow: "hidden" }}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <CustomInputLabel htmlFor="first-name">First Name</CustomInputLabel>
                <CustomTextField
                  id="first-name"
                  name="firstName"
                  disabled
                  placeholder="Enter first name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
                  value={formik.values.firstName || supportData?.row?.first_name}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: '100%',
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(formik.touched.firstName && formik.errors.firstName) && (
                  <FormHelperText error>{formik.errors.firstName}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <CustomInputLabel htmlFor="last-name">Last Name</CustomInputLabel>
                <CustomTextField
                  id="last-name"
                  name="lastName"
                  disabled
                  placeholder="Enter last name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'lastName')}
                  value={formik.values.lastName || supportData?.row?.last_name}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: '100%',
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(formik.touched.lastName && formik.errors.lastName) && (
                  <FormHelperText error>{formik.errors.lastName}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <CustomInputLabel htmlFor="email">Email Address</CustomInputLabel>
                <CustomTextField
                  id="email"
                  name="email"
                  placeholder="Enter email..."
                  variant="outlined"
                  size="small"
                  type="email"
                  disabled
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email.toLowerCase() || supportData?.row?.email.toLowerCase()}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: '100%',
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(formik.touched.email && formik.errors.email) && (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <CustomInputLabel htmlFor="mobile-number">
                  Mobile Number
                </CustomInputLabel>
                <Box
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    '& .react-tel-input .form-control': {
                      fontWeight: 500,
                      background: theme?.palette?.grey[50],
                      height: "42px",
                      width: '100%',
                    },
                  }
                  }>
                  <PhoneInput
                    country={"us"}
                    id="mobile"
                    name="mobile"
                    variant="outlined"
                    placeholder="(702) 123-4567"
                    disabled
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile || supportData?.row?.mobile}
                    onChange={(value, country) => {
                      formik.setFieldValue('countryCode', country.dialCode || "")
                      formik.setFieldValue('mobile', value || "");
                    }}
                    error={Boolean(
                      formik.touched.mobile && formik.errors.mobile
                    )}
                    size="sm"
                    style={{
                      width: '100%',
                    }}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "12px 14px",
                      },
                    }}
                  />
                  {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                    <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <CustomInputLabel htmlFor="email">Created By</CustomInputLabel>
                <CustomTextField
                  id="createdBy"
                  name="createdBy"
                  placeholder="Created By..."
                  variant="outlined"
                  size="small"
                  type="text"
                  disabled
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={supportData?.row?.user?.email}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: '100%',
                    },
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: '100%',
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(formik.touched.email && formik.errors.email) && (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                )}
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomInputLabel htmlFor="mobile-number">
                  Message
                </CustomInputLabel>
                <TextField
                  id="message"
                  name="message"
                  disabled
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile || supportData?.row?.message}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    formik.setFieldValue('message', supportData.message);
                  }}
                  multiline
                  rows={4}
                  sx={{ width: '100%', }}
                />

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{
            p: 3,
            pt: 0,
          }}>
            <Button
              size="small"
              variant="contained"
              sx={{ px: 3.5, paddingY: 1.2, }}
              color="inherit"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </form >
      </Dialog >
    </>
  );
}

export default Support;
