import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Button,
  FormHelperText,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../../components/CustomTextField";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import { getAllTemplates, SET_TOAST } from "../../../store/actions";

export default function SendEmailStep2({
  onNext,
  onBack,
  step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const step2Details = state?.sendEmailCommunicate?.step2Details;
  const theme = useTheme();
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const selectionInputStyle = {
    width: "100%",
    minWidth: "100%",
    padding: "12px 14px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  const [mailTemplates, setMailTemplates] = useState([]);

  const validationSchema = Yup.object({
    template: Yup.string().required("Template is required"),
    subject: Yup.string()
      .trim()
      .max(60, "Subject must be at most 60 characters")
      .required("Subject is required"),
    fromName: Yup.string().trim().required("From Name is required"),
    fromEmail: Yup.string()
      .trim()
      .required("From Email is required")
      .email("Must be a valid email"),
    replyTo: Yup.string()
      .trim()
      .required("Reply to email is required")
      .email("Must be a valid email"),
  });

  const formik = useFormik({
    initialValues: {
      template: step2Details?.template ?? "",
      subject: step2Details?.subject.trim() ?? "",
      fromName: step2Details?.fromName.trim() ?? "",
      fromEmail: step2Details?.fromEmail.trim() ?? "",
      replyTo: step2Details?.replyTo.trim() ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "EMAIL_STEP_2_DETAILS",
        data: {
          ...values,
        },
      });
      onNext();
    },
  });

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllTemplates(dispatch, params);
    if (response?.ok) {
      setMailTemplates(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <Box p={6}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ width: "50%" }}>
          <Grid item lg={12} md={12} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              Choose a Template
            </CustomInputLabel>
            <Select
              name="template"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.template}
              input={<OutlinedInput sx={selectionInputStyle} />}
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
                style: {
                  width: "100%",
                },
              }}
              sx={{
                width: "100%",
                //padding: "0 0 0 110px",
                "& .MuiOutlinedInput-root": {
                  minWidth: "100%",
                  width: "100%",
                  boxSizing: "border-box",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "12px 14px",
                  minWidth: "100%",
                  width: "100%",
                  boxSizing: "border-box",
                },
              }}
              displayEmpty
              renderValue={(value) => {
                if (value) {
                  let template = mailTemplates?.find(
                    (t) => t?.TemplateID === value,
                  );
                  if (template) {
                    return template?.Name;
                  }
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Select template...
                    </Typography>
                  );
                }
              }}
            >
              {mailTemplates?.map((template) => (
                <MenuItem
                  key={template?.TemplateID}
                  value={template?.TemplateID}
                >
                  {template?.Name}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.touched.template && formik.errors.template) && (
              <FormHelperText error>{formik.errors.template}</FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              Add a Subject
            </CustomInputLabel>
            <CustomTextField
              name="subject"
              placeholder="Enter a subject..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.subject}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                width: "100%",
              }}
            />
            <CustomInputLabel
              sx={{
                fontSize: 12,
                marginBottom: "5px",
                color: "gray",
              }}
            >
              MAX 60 Characters is allowed
            </CustomInputLabel>
            {Boolean(formik.touched.subject && formik.errors.subject) && (
              <FormHelperText error>{formik.errors.subject}</FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              Set the "From" Name
            </CustomInputLabel>
            <CustomTextField
              name="fromName"
              placeholder="Enter a from Name..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.fromName}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                width: "100%",
              }}
            />
            {Boolean(formik.touched.fromName && formik.errors.fromName) && (
              <FormHelperText error>{formik.errors.fromName}</FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              Set the "From" email
            </CustomInputLabel>
            <CustomTextField
              name="fromEmail"
              placeholder="Enter a from email..."
              type="email"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.fromEmail}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                width: "100%",
              }}
            />
            {Boolean(formik.touched.fromEmail && formik.errors.fromEmail) && (
              <FormHelperText error>{formik.errors.fromEmail}</FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              Reply to email
            </CustomInputLabel>
            <CustomTextField
              name="replyTo"
              placeholder="Enter reply to email..."
              type="email"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.replyTo}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                width: "100%",
              }}
            />
            {Boolean(formik.touched.replyTo && formik.errors.replyTo) && (
              <FormHelperText error>{formik.errors.replyTo}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} mt={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              width={1}
              mt={5}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: "transparent",
                  border: `2px solid ${theme?.palette?.secondary?.dark}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme?.palette?.secondary?.dark,
                  cursor: "pointer",
                  mr: 2,
                  my: 1,
                  px: 3,
                }}
                onClick={() => setShowCancelConfirmPopup(true)}
              >
                Cancel
              </Typography>

              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Typography
                  variant="h5"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: "transparent",
                    border: `2px solid ${theme?.palette?.secondary?.dark}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme?.palette?.secondary?.dark,
                    cursor: "pointer",
                    px: 3,
                    my: 1,
                  }}
                  onClick={onBack}
                >
                  Back
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: theme?.palette?.secondary?.dark,
                    color: "white",
                    ml: 2,
                    px: 3,
                    my: 1,
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

SendEmailStep2.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
SendEmailStep2.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];
