import { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "../../../../node_modules/react-quill/dist/quill.snow.css";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import SuccessPopup from "../../../components/Common/SuccessPopup";
import SendPreviewModal from "./SendPreviewModal";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
} from "../../../store/actions";
import httpClient from "@utils/httpClient";
import CustomTextField from "../../../components/CustomTextField";

export default function SendEmailStep4({
  onNext,
  onBack,
  step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const step1Details = state?.sendEmailCommunicate?.step1Details;
  const step2Details = state?.sendEmailCommunicate?.step2Details;
  const step3Details = state?.sendEmailCommunicate?.step3Details;
  const step4Details = state?.sendEmailCommunicate?.step4Details;
  const theme = useTheme();
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const inputLabelStyle1 = { fontSize: 12, marginBottom: "-31px", color: "gray" };

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [campaignId, setCampaignId] = useState(step4Details?.campaignId ?? "");

  const validationSchema = Yup.object({
    emailTitle: Yup.string().trim().required("Email title is required"),
    emailBody: Yup.string().trim().required("Email body is required"),
  });

  const formik = useFormik({
    initialValues: {
      emailBody: step4Details?.emailBody ?? "",
      emailTitle: step4Details?.emailTitle.trim() ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const emailbody = values?.emailBody.replace(/<\/?[^>]+(>|$)/g, "").trim();
      if (emailbody) {
        dispatch({
          type: "EMAIL_STEP_4_DETAILS",
          data: {
            campaignId: campaignId ?? "",
            ...values,
          },
        });
        setCampaignId("");
        onNext(5);
      } else {
        formik.setFieldError("emailBody", "Email body is required");
      }
    },
  });

  const handleQuillChange = (content) => {
    formik.setFieldValue("emailBody", content);
  };

  const handleModalClose = (isClosed, previewEmail) => {
    setShowModal(false);
    if (!isClosed) {
      if (campaignId) {
        sendPreview(previewEmail, campaignId);
      } else {
        sendDraftCampaign(previewEmail);
      }
    }
  };

  const handleModalOpen = () => {
    const emailbody = formik?.values?.emailBody
      .replace(/<\/?[^>]+(>|$)/g, "")
      .trim();
    const emailtitle = formik?.values?.emailTitle;
    if (!emailtitle) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Email title is required",
        },
      });
    } else if (!emailbody) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Email body is required",
        },
      });
    } else {
      setShowModal(true);
    }
  };

  const sendDraftCampaign = async (previewEmail) => {
    const bodyParam = {
      Name: "Sketchplay-" + step1Details?.campaign_name,
      Subject: step2Details?.subject,
      FromName: step2Details?.fromName,
      FromEmail: step2Details?.fromEmail,
      ReplyTo: step2Details?.replyTo,
      ListIDs: step3Details?.ListIDs ?? [],
      TemplateID: step2Details?.template,
      TemplateContent: {
        Singlelines: [
          {
            Content: formik?.values?.emailTitle ?? "",
            // "Href": "http://example.com/"
          },
        ],
        Multilines: [
          {
            Content: formik?.values?.emailBody ?? "<p></p>",
          },
        ],
      },
    };
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.post("/draftCampaign", bodyParam);
    dispatch({ type: SET_LOADER, data: false });
    if (res && res.ok) {
      setCampaignId(res?.data);
      sendPreview(previewEmail, res?.data);
    } else {
      if (res?.code === 303) {
        dispatch({
          type: SET_TOAST,
          data: {
            type: "error",
            message: res?.message ?? "Something went wrong",
          },
        });
        onNext(1);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: res.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  const sendPreview = async (previewEmail, campaignId) => {
    const bodyParam = {
      id: campaignId,
      PreviewRecipients: previewEmail,
    };
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.post("/sendPreviewCampaign", bodyParam);
    dispatch({ type: SET_LOADER, data: false });
    if (res && res.ok) {
      setShowSuccessPopup(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: res.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <>
      <Box p={6}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item lg={8} md={12} xs={12}>
              <Box
                sx={{
                  background: "#EFF7FF",
                  border: `1px solid #7FBEFD`,
                  color: "black",
                  maxWidth: "30rem",
                  width: "100%",
                  borderRadius: "7px",
                  fontSize: "14px",
                  fontWeight: 500,
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                  },
                }}
                p={1.4}
              >
                <b>Note : </b>Sending a preview will disable your ability to go
                edit this campaign. We suggest reviewing the email details prior
                to sending a preview.
              </Box>
            </Grid>
            <Grid item lg={8} md={12} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomInputLabel sx={inputLabelStyle1}>
                  Email Headline
                </CustomInputLabel>
                <Button
                  size="small"
                  variant="contained"
                  my={1}
                  sx={{
                    width: 150,
                    height: 40,
                    fontWeight: 700,
                    background: theme?.palette?.inputsLabels?.green,
                    color: "white",
                    my: 1,
                  }}
                  onClick={() => handleModalOpen()}
                >
                  Send Preview
                </Button>
              </Box>
              <CustomTextField
                name="emailTitle"
                placeholder="Enter headline..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emailTitle}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                  width: "100%",
                }}
                disabled={campaignId}
              />
              {Boolean(
                formik.touched.emailTitle && formik.errors.emailTitle,
              ) && (
                <FormHelperText error>
                  {formik.errors.emailTitle}
                </FormHelperText>
              )}
            </Grid>
            <Grid item lg={8} md={12} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomInputLabel sx={inputLabelStyle}>
                  Draft Email Body Content
                </CustomInputLabel>
              </Box>
              <ReactQuill
                theme="snow"
                name="emailBody"
                placeholder="Write something...!"
                modules={SendEmailStep4.modules}
                formats={SendEmailStep4.formats}
                value={formik.values.emailBody}
                onChange={handleQuillChange}
                error={Boolean(
                  formik.touched.emailBody && formik.errors.emailBody,
                )}
                style={{ height: "35vh" }}
                readOnly={campaignId}
              />
              {Boolean(formik.touched.emailBody && formik.errors.emailBody) && (
                <FormHelperText sx={{ mt: 8 }} error>
                  {formik.errors.emailBody}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} mt={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                width={1}
                mt={5}
              >
                <Typography
                  variant="h5"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: "transparent",
                    border: `2px solid ${theme?.palette?.secondary?.dark}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme?.palette?.secondary?.dark,
                    cursor: "pointer",
                    mr: 2,
                    my: 1,
                    px: 3,
                  }}
                  onClick={() => setShowCancelConfirmPopup(true)}
                >
                  Cancel
                </Typography>

                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {!campaignId && (
                    <Typography
                      variant="h5"
                      sx={{
                        maxWidth: 120,
                        height: 40,
                        background: "transparent",
                        border: `2px solid ${theme?.palette?.secondary?.dark}`,
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme?.palette?.secondary?.dark,
                        cursor: "pointer",
                        px: 3,
                        my: 1,
                      }}
                      onClick={onBack}
                    >
                      Back
                    </Typography>
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{
                      maxWidth: 120,
                      height: 40,
                      background: theme?.palette?.secondary?.dark,
                      color: "white",
                      ml: 2,
                      px: 3,
                      my: 1,
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      {showModal && (
        <SendPreviewModal open={showModal} handleClose={handleModalClose} />
      )}

      {showSuccessPopup && (
        <SuccessPopup
          open={true}
          handleClose={() => {
            setShowSuccessPopup(false);
          }}
          title="Send Preview"
          secondaryTitle="Success! You have successfully sent a preview email."
          isShowCloseBtn={true}
          isShowSubTitle={false}
          secondaryMessage="Tap anywhere out of this popup to hide this popup"
          alignCenterSecondaryTitle={true}
        />
      )}
    </>
  );
}

SendEmailStep4.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
SendEmailStep4.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];
