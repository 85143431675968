import {
  Box,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMe,
  getSubOrgTeams,
} from "../../store/actions";
import { charValidate } from "../../utils/functions";


const LeagueSuperAdmin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [subOrgTeam, setSubOrgTeam] = useState([]);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getSubOrgTeamsList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getSubOrgTeamsList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getSubOrgTeams(params, "");
    if (response?.ok) {
      setSubOrgTeam(response?.data ?? []);
    }
  };

  const handleBoxClick = (websiteUrl) => {
    window.open(websiteUrl, "_blank");
  };

  return (
    <>
      <Box sx={{ pt: 2, pl: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ mt: 4, mb: 4, alignItems: 'center' }}>
            <Typography component="div" variant="h2" sx={{ fontSize: '20px', color: '#3B3B3B' }}>
              League List
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sx={{
              height: '100%',
              '& .MuiPaper-elevation': {
                height: '100%',
                boxShadow: '0px 6px 15px -2px rgba(0, 0, 0, 0.15)',
              }
            }}>
              <Grid container spacing={3} sx={{ width: "100%", }}>
                {subOrgTeam?.length
                  ? subOrgTeam?.map((t) => (
                    <Grid item md={4} sm={6} xs={12}
                      onClick={() => {
                        handleBoxClick(t?.org_website);
                      }}
                      key={t?.id}
                    >
                      <Box
                        sx={{
                          p: "10px 20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: '0px 6px 15px -2px rgba(0, 0, 0, 0.15)',
                          background: "#fff",
                          borderRadius: "5px",
                          height: '100%',
                        }}
                      >
                        <Box>
                          <Tooltip title={t?.sub_organizationsData?.title ? t?.sub_organizationsData?.title : "N/A"}>
                            <Typography variant="h5" fontWeight={600}>
                              {t?.sub_organizationsData?.title ? charValidate(t?.sub_organizationsData?.title, 25) : "N/A"}
                            </Typography>
                          </Tooltip>
                          <Tooltip title={t?.title ? t?.title : "N/A"}>
                            <Typography variant="h5" fontWeight={600}>
                              {t?.title ? charValidate(t?.title, 25) : "N/A"}
                            </Typography>
                          </Tooltip>
                          <Tooltip title={t?.org_code ? t?.org_code : "N/A"}>
                            <Typography variant="h6">
                              {t?.org_code ? charValidate(t?.org_code, 25) : "N/A"}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <ChevronRightIcon />
                      </Box>
                    </Grid>
                  ))
                  : null}
              </Grid>
            </Grid>
            {!subOrgTeam?.length && (
              <Typography variant="h4" sx={{ textAlign: "center", mt: 3 }}>No Data Available</Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const mainBox = {
  width: "280px",
  minWidth: "280px",
  height: "80px",
  backgroundColor: "white",
  boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
  border: "none",
  padding: "1px",
  borderRadius: "10px",
  borderColor: "transparent",
  cursor: "pointer",
  m: 2,
};

export default LeagueSuperAdmin;
