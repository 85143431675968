import { Box, Button, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_SUCCESS_ERROR_POPUP, createTeamRequestAction } from "../../../store/actions";

const EventRegistrationRequest = ({
  eventId,
  selectedAthlete,
  setSuccessPopupTitle,
  handleNext,
  onBack,
  selectedSprintEventId,
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const orgDetails = state?.org?.org?.data;

  const createRequest = async () => {

    let bodyParam = {
      request_reason: "Allow in event",
      type: "event_allow",
      event: selectedSprintEventId ? selectedSprintEventId : eventId,
      AthleteId: selectedAthlete
    }

    let response = await createTeamRequestAction(dispatch, bodyParam);
    if (response?.ok) {
      setSuccessPopupTitle("Request Sent");
      handleNext("SUCCESS_POPUP");
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong, try again" }
      })
    }
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={1}
      width={1}
      p={3}
    >
      <form style={{ width: "80%" }}>
        <Box
          width={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center",
              mb: 3
            }}
          >
            To register for this event you will need approval.
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 600,
              textAlign: "center",
              fontFamily: "Poppins",
              mt: 1
            }}
          >
            Why is approval needed?
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 500,
              textAlign: "center",
              fontFamily: "Poppins",
              mt: 1
            }}
          >
            This event was made for athlete’s in a different age group than you. You can request approval from the organization to register.
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Button
            onClick={createRequest}
            sx={{
              alignSelf: "center",
              background: `${theme?.palette?.inputsLabels?.green} !important`,
              color: "white",
              width: "80% !important",
              padding: "0.8rem !important",
              borderRadius: "7px !important",
              mt: 9,
              fontSize: "16px"
            }}
          >
            Request Approval
          </Button>
        </Box>
      </form>
      <Typography
        variant="h6"
        sx={{
          mt: 4,
          fontSize: "14px",
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
          textAlign: "center",
          color: orgDetails?.primary_color,
        }}
        onClick={() => onBack("ATHLETE_INFO_SELECTION")}
      >
        Back
      </Typography>
    </Box>
  )
}

export default EventRegistrationRequest;
