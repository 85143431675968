import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ChildSchoolInfo from "../../components/AppForms/ChildSchoolInfo";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { SET_SUCCESS_ERROR_POPUP } from "../../store/actions";

const validationSchema = Yup.object({
  school: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("School Name is required"),
  grade: Yup.string().trim().required("Grade is required"),
  //position: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Position is required"),
  dob: Yup.date().required("Birthdate is required"),
  emergency_contact_name: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Contact Name is required"),
  emergency_contact_number: Yup.string().min(10, "Contact No. must be at least 10 characters").transform(value => (value === '' ? null : value)).nullable().required("Contact Number is required"),
  eventRegister: Yup.boolean().required(),
  //socialAccount: Yup.boolean().required(),
});

const AthleteInformation = ({ step, setStep, formData, setFormData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [showError, setShowError] = useState(null);
  const [isEmergency, setIsEmergency] = useState(false);
  const { user } = useSelector((state) => state.user);

  const handleSave = (values, actions) => {
    setShowError(null);
    const emergency_contact_countryCode = values.emergency_contact_countryCode.toUpperCase()
    const isValid = values.emergency_contact_number.length === 10 ? true : false;
    if (!selectedPosition?.length) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: "Choose position" }
      })
      return;
    }
    setFormData((prevStage) => {
      return {
        ...prevStage,
        ...values,
        position: selectedPosition ?? [],
        emergency_contact_countryCode
      };
    });
    setStep(step + 1);
  };

  const formik = useFormik({
    initialValues: {
      school: formData?.school || "",
      grade: formData?.grade || "",
      //position: formData?.position || "",
      dob: dayjs(formData?.dob) || "",
      emergency_contact_name: formData?.emergency_contact_name || "",
      emergency_contact_number: formData?.emergency_contact_number || "",
      eventRegister: formData?.eventRegister || true,
      emergency_contact_countryCode: formData?.emergency_contact_countryCode || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (formData?.emergency_contact_number) {
      const isMatchMobile = user.mobile === formData.emergency_contact_number ? true : false;
      setIsEmergency(isMatchMobile)
    }
  }, [])

  useEffect(() => {
    if (formData?.position?.length) {
      let positionIds = formData?.position?.map(p => p?.id);
      setSelectedPosition(positionIds ?? [])
    }
  }, [formData])


  const handleChangeEmergency = (event) => {
    let isChecked = event.target.checked
    setIsEmergency(isChecked)
    if (isChecked) {
      formik.setFieldValue("emergency_contact_name", user.first_name + ' ' + user.last_name)
      formik.setFieldValue("emergency_contact_number", user.mobile)
      formik.setFieldValue("emergency_contact_countryCode", user.countryCode)
    } else {
      formik.setFieldValue("emergency_contact_name", "")
      formik.setFieldValue("emergency_contact_number", "")
      formik.setFieldValue("emergency_contact_countryCode", "")
    }
  }

  return (
    <>
      <Box sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}>
        <Grid item xs={12} sm={12} sx={{ my: 1 }}>
          <Typography variant="h2" sx={{ color: '#262626' }}>
            Add Athlete's Information
          </Typography>
        </Grid>
      </Box>

      {showError && (
        <Grid item xs={12} alignItems="center" justifyContent="left">
          <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
            {showError}
          </Alert>
        </Grid>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box className="guardian-form-box" sx={{
          '&.guardian-form-box div': {
            maxWidth: '100%',
          },
          '&.guardian-form-box > div': {
            padding: '0',
          }
        }}>
          <ChildSchoolInfo
            formik={formik}
            setSelectedPosition={setSelectedPosition}
            selectedPosition={selectedPosition}
          />
        </Box>

        <Divider sx={{ mt: 4, mb: 4 }}></Divider>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEmergency}
                onChange={(e) => handleChangeEmergency(e)}
                color="primary"
              />
            }
            label="I am the emergency contact for this athlete."
          />
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.emergency_contact_name && formik.errors.emergency_contact_name,
            )}
            sx={{
              ...theme.typography.customInput,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0",
                boxShadow: theme?.palette?.boxShadow?.primary,
              },
            }}
            disabled={isEmergency}
          >
            <TextField
              id="emergency_contact_name"
              name="emergency_contact_name"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.emergency_contact_name}
              label="Emergency Contact Name *"
              error={Boolean(
                formik.touched.emergency_contact_name && formik.errors.emergency_contact_name,
              )}
              disabled={isEmergency}
            />
            {Boolean(
              formik.touched.emergency_contact_name && formik.errors.emergency_contact_name,
            ) && (
                <FormHelperText error>
                  {formik.errors.emergency_contact_name}
                </FormHelperText>
              )}
          </FormControl>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.emergency_contact_number && formik.errors.emergency_contact_number
            )}
            sx={{
              ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                border: '0',
                boxShadow: '0px 6px 20px #1F54C326',
              }
            }}
            disabled={isEmergency}
          >
            <Box
              sx={{
                '& .react-tel-input .form-control': {
                  border: 0,
                  "box-shadow": "0px 6px 20px #1F54C326",
                  "min-width": "100%",
                  "font-weight": 500,
                  background: "#ffffff",
                  padding: "15px 14px 15px 50px",
                  "border-radius": "0",
                  height: "51px",
                },
                "& .react-tel-input .flag-dropdown": {
                  border: 0,
                  background: "transparent"
                }
              }
              }>
              <PhoneInput
                country={"us"}
                id="emergency_contact_number"
                name="emergency_contact_number"
                variant="outlined"
                placeholder="(702) 123-4567"
                onBlur={formik.handleBlur}
                value={formik.values.emergency_contact_number}
                onlyCountries={['us']}
                disableCountryCode={true}
                disableDropdown={true}
                onChange={(value, country) => {
                  formik.setFieldValue('emergency_contact_countryCode', country.countryCode || '')
                  // formik.setFieldValue('dialCode', country.dialCode || '')
                  formik.setFieldValue('emergency_contact_number', value);
                }}
                error={Boolean(
                  formik.touched.emergency_contact_number && formik.errors.emergency_contact_number
                )}
                disabled={isEmergency}
              />
            </Box>
            {Boolean(
              formik.touched.emergency_contact_number && formik.errors.emergency_contact_number
            ) && (
                <FormHelperText error>
                  {formik.errors.emergency_contact_number}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Divider sx={{ mt: 4, mb: 4 }}></Divider>
        <Grid item xs={12} alignItems="center" textAlign="center">
          <Typography variant="h2" sx={{ color: "#3B3B3B" }}>
            Permissions
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              mb: 4,
              "& .MuiTypography-root": {
                color: "#3B3B3B",
                textAlign: 'left',
              },
              "& .MuiSwitch-thumb": {
                background: "#fff",
                height: "13px",
                width: "13px",
                marginTop: "5px",
              },
              "& .MuiSwitch-track": {
                background: "#C9C9C9",
                opacity: "1",
                height: "18px",
                borderRadius: "50px",
              },
              "& .MuiSwitch-switchBase": {
                left: "6px",
                "&.Mui-checked": {
                  transform: "translateX(15px)",
                },
                "&.Mui-checked+.MuiSwitch-track": {
                  background: "#00B60D",
                  opacity: "1",
                },
              },
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="eventRegister"
                  checked={formik.values.eventRegister}
                  onChange={formik.handleChange}
                />
              }
              label="Allow this user to register for camps, training and other events."
            />
            {/* <FormControlLabel
              control={
                <Switch
                // name="socialAccount"
                // checked={formik.values.socialAccount}
                // onChange={formik.handleChange}
                />
              }
              label="Allow this user to use my email to login to their account (note, password will be different)"
            /> */}
          </Box>
        </Grid>
        <Divider sx={{ mt: 4, mb: 4 }}></Divider>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              variant="contained"
              sx={{ fontWeight: "600", mt: 2, width: "45%" }}
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
            <Button
              disableElevation
              disabled={formik.isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              sx={{ fontWeight: "600", mt: 2, width: "45%" }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default AthleteInformation;
