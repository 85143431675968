import {
    Box,
    Button,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Typography,
    useTheme,
    FormControlLabel, Checkbox, InputAdornment
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomTextField from "../CustomTextField";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, getGrades } from "../../store/actions";

const SubscriptionSession = ({ handleClose, setStep, formData, setFormData, activeProduct }) => {
    const theme = useTheme();
    const [allowRenew, setAllowRenew] = useState("1")
    const [recurringTimeFrame, setRecurringTimeFrame] = useState(formData?.recurring ? formData?.recurring : "monthly")
    const [isOpenToAll, setIsOpenToAll] = useState(false);
    const [selectedGrades, setSelectedGrades] = useState(
        formData?.age_group_permissions?.length ?
            (formData?.age_group_permissions.some(item => item.hasOwnProperty("id")) ? formData?.age_group_permissions?.map(x => x.id) : formData.age_group_permissions) : []
    );
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const [gradeArr, setGradeArr] = useState([])

    const CustomSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: "#00B60D",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        getGradeList();
    }, [])

    const validationSchema = Yup.object({
        cost: Yup.number().required("Cost is required"),
    });


    const handleSave = async (values) => {

        if (selectedGrades.length > 0) {
            const age_group_permissions = selectedGrades;
            const is_activated = activeProduct;
            const recurring = recurringTimeFrame;
            const auto_renew = allowRenew === '1' ? true : false
            const postData = { ...formData, ...values, age_group_permissions, auto_renew, is_activated, recurring }
            setFormData((prevStage) => {
                return {
                    ...prevStage,
                    ...postData
                };
            });
            setStep(9)
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: "Please select at least one grade" }
            })
            return false;
        }
    }

    const formik = useFormik({
        initialValues: {
            cost: formData?.cost || 0,
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    const handleChangeOpenToAll = (event) => {
        const isChecked = event.target.checked;
        setIsOpenToAll(isChecked)
        if (isChecked) {
            let gradeIds = [];
            gradeArr?.forEach((grade) => gradeIds?.push(grade?.id));
            setSelectedGrades(gradeIds);
        } else {
            setSelectedGrades([])
        }
    };

    const handleChangeGrade = (gradeId) => {
        if (selectedGrades?.includes(gradeId)) {
            let gradeIds = selectedGrades?.filter((id) => id !== gradeId);
            setSelectedGrades(gradeIds);
        } else {
            setSelectedGrades([...selectedGrades, gradeId]);
        }
    };

    const handleBack = () => {
        const age_group_permissions = selectedGrades;
        const recurring = recurringTimeFrame;
        setFormData((prevStage) => {
            return {
                ...prevStage,
                ...formik.values,
                age_group_permissions,
                recurring
            };
        });
        setStep(6)
    }

    const getGradeList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        let response = await getGrades(params, dispatch);
        if (response?.ok) {
            if (response?.data?.length === selectedGrades.length) {
                setIsOpenToAll(true)
            }
            setGradeArr(response?.data ?? []);
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
            })
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6} mt={1}>
                    <CustomInputLabel htmlFor="recurringTime">
                        Recurring Time Frame
                    </CustomInputLabel>
                    <Select
                        name="recurringTime"
                        labelId="recurringTime"
                        value={recurringTimeFrame}
                        onChange={(event) => setRecurringTimeFrame(event.target.value)}
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Yes or No"
                                sx={{ maxWidth: 350, width: "100%", height: 45 }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                    >
                        <MenuItem value="monthly"> Monthly</MenuItem>
                        <MenuItem value="annually"> Annually</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6} mt={1}>
                    <CustomInputLabel htmlFor="org-filter">
                        Allow Auto-Renew
                    </CustomInputLabel>
                    <Select
                        name="subOrg"
                        labelId="org-filter"
                        value={allowRenew}
                        onChange={(event) => setAllowRenew(event.target.value)}
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Yes or No"
                                sx={{ maxWidth: 350, width: "100%", height: 45 }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                    >
                        <MenuItem value="1"> Yes</MenuItem>
                        <MenuItem value="2"> No</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={6}>
                    <CustomInputLabel htmlFor="cost-id">
                        Cost
                    </CustomInputLabel>
                    <CustomTextField
                        id="cost-id"
                        name="cost"
                        type="number"
                        placeholder="Enter Cost..."
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cost}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: 350,
                                boxShadow: "none",
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ ml: 1 }}>
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    {Boolean(formik.touched.cost && formik.errors.cost) && (
                        <FormHelperText error>{formik.errors.cost}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6} mt={1}>
                </Grid>
                <Grid item xs={6} mt={3}>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        fontSize={17}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Age Group Permissions
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight={400}
                        fontSize={11}
                        color={theme?.palette?.text?.containerTextLabel}
                        sx={{ mt: 1, letterSpacing: "0.1px" }}
                    >
                        If you'd like to restrict specific age group to this package, remove the check
                        the box next to the appropriate age group.
                    </Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                        <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize={14}
                            color={theme?.palette?.text?.containerTextLabel}
                            sx={{ mr: 2, letterSpacing: "0.1px" }}
                        >
                            Open to All
                        </Typography>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={isOpenToAll}
                                    onChange={(e) => {
                                        handleChangeOpenToAll(e)
                                    }}
                                    sx={{ m: 1 }}
                                />
                            }
                            label=""
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        fontWeight={400}
                        fontSize={11}
                        color={theme?.palette?.text?.containerTextLabel}
                        sx={{ mt: 1, letterSpacing: "0.1px" }}
                    >
                        Toggling this on will allow any user regardless of their age
                        purchase this package.
                    </Typography>
                </Grid>
                <Grid item xs={6} >
                    <Box
                        sx={{ height: 200, overflowY: "auto", overflowX: "none" }}
                        mt={2}
                        bgcolor="#F4F4F4"
                    >
                        {gradeArr?.length ? (
                            gradeArr?.map((grade) => (
                                <Box key={grade?.id}>
                                    <Box display="flex" alignItems="center">
                                        <Checkbox
                                            sx={{
                                                color: "#00B60D",
                                                "&.Mui-checked": {
                                                    color: "#00B60D",
                                                },
                                            }}
                                            checked={selectedGrades.indexOf(grade?.id) > -1}
                                            onChange={() => handleChangeGrade(grade?.id)}
                                        />
                                        <Typography
                                            variant="h6"
                                            fontWeight={400}
                                            fontSize={13}
                                            color={theme?.palette?.text?.containerTextLabel}
                                            sx={{ letterSpacing: "0.1px" }}
                                        >
                                            {grade?.name}
                                        </Typography>
                                    </Box>
                                    <Box
                                        width={1}
                                        height={"1px"}
                                        bgcolor={"gray"}
                                        sx={{ opacity: 0.25 }}
                                    ></Box>
                                </Box>
                            ))
                        ) : (
                            <Typography
                                variant="h6"
                                fontWeight={400}
                                fontSize={13}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{ letterSpacing: "0.1px", textAlign: "center", mt: 7 }}
                            >
                                No Team
                            </Typography>
                        )}
                    </Box>
                </Grid>

            </Grid>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mt: 4 }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                    color="inherit"
                    onClick={() => handleClose(true)}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                    color="inherit"
                    onClick={() => handleBack()}
                >
                    Back
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                >
                    Next
                </Button>
            </Box>
        </form>
    );
};

export default SubscriptionSession;