import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useTheme,
} from "@mui/material";
import SuccessCheck from "../../assets/images/success_check.png";
import { SET_SUCCESS_ERROR_POPUP } from "@store/actions";
import { useSelector, useDispatch } from 'react-redux';

const SuccessErrorPopup = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const showPopup = useSelector((state) => state.theme.successErrorPopup)
    const handleClose = () => {
        dispatch({ type: SET_SUCCESS_ERROR_POPUP, data: { open: false, type: '', message: '', customMessage: () => null } })
    }

    return (
        <Dialog
            open={showPopup?.open}
            keepMounted
            maxWidth="sm"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
          {
            showPopup?.type === 'warning' ?
              null :
              <DialogTitle sx={{ p: 0 }}>
                  <Typography
                      variant="h4"
                      fontWeight={700}
                      fontSize={20}
                      py={3}
                      ml={3}
                      color={showPopup?.type === 'success' ? theme?.palette?.text?.containerTextLabel : theme?.palette?.error?.dark}
                  >
                    {showPopup?.type === 'success' ? "Success" : "Error"}
                  </Typography>
                  <Box
                      width={1}
                      height={"1px"}
                      bgcolor={"gray"}
                      sx={{ opacity: 0.25 }}
                  ></Box>
              </DialogTitle>
            }
            <DialogContent
                sx={{
                    pt: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pb: showPopup?.isEditEventStyle ? 0 : 12,
                }}
            >
              {showPopup?.type === 'warning' ?
                showPopup?.customMessage ? showPopup?.customMessage() : null
                :
                <Typography
                    sx={{ py: 5, textAlign: "center" }}
                    fontFamily={"Poppins"}
                    fontSize={20}
                    fontWeight={500}
                    color={showPopup?.type === 'success' ? theme?.palette?.text?.darkTextSubHeader : theme?.palette?.error?.dark}
                >
                    {showPopup?.message}
                </Typography>
              }
                {
                    showPopup?.type === 'success' &&
                    <img src={SuccessCheck} style={successImgStyle} />
                }

            </DialogContent>
            <DialogActions sx={{ pr: 4, pb: 3 }}>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                    color="inherit"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const successImgStyle = {
    maxWidth: 100,
};

export default SuccessErrorPopup;
