import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

const AddressForm = ({ formik }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
        {/* Address Line 1 */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.address && formik.errors.address)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiInputBase-input": {
              border: "0",
              borderRadius: "5px",
              background: "transparent",
            },
            "& .MuiInputBase-root": {
              background: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 6px 20px #1F54C326",
            },
          }}
        >
          <TextField
            id="address"
            name="address"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
            // label="Address Line 1 *"
            placeholder="Address Line 1 *"
            error={Boolean(formik.touched.address && formik.errors.address)}
          />
          {Boolean(formik.touched.address && formik.errors.address) && (
            <FormHelperText error>{formik.errors.address}</FormHelperText>
          )}
        </FormControl>

        {/* Address Line 2 */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.suite && formik.errors.suite)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiInputBase-input": {
              border: "0",
              borderRadius: "5px",
              background: "transparent",
            },
            "& .MuiInputBase-root": {
              background: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 6px 20px #1F54C326",
            },
          }}
        >
          <TextField
            id="suite"
            name="suite"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.suite}
            // label="Address Line 2"
            placeholder="Address Line 2"
            error={Boolean(formik.touched.suite && formik.errors.suite)}
          />
          {Boolean(formik.touched.suite && formik.errors.suite) && (
            <FormHelperText error>{formik.errors.suite}</FormHelperText>
          )}
        </FormControl>

        {/* City */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.city && formik.errors.city)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiInputBase-input": {
              border: "0",
              borderRadius: "5px",
              background: "transparent",
            },
            "& .MuiInputBase-root": {
              background: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 6px 20px #1F54C326",
            },
          }}
        >
          <TextField
            id="city"
            name="city"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.city}
            // label="City *"
            placeholder="City *"
            error={Boolean(formik.touched.city && formik.errors.city)}
          />
          {Boolean(formik.touched.city && formik.errors.city) && (
            <FormHelperText error>{formik.errors.city}</FormHelperText>
          )}
        </FormControl>

        {/* State */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.state && formik.errors.state)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiInputBase-input": {
              border: "0",
              borderRadius: "5px",
              background: "transparent",
            },
            "& .MuiInputBase-root": {
              background: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 6px 20px #1F54C326",
            },
          }}
        >
          <TextField
            id="state"
            name="state"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.state}
            // label="State *"
            placeholder="State *"
            error={Boolean(formik.touched.state && formik.errors.state)}
          />
          {Boolean(formik.touched.state && formik.errors.state) && (
            <FormHelperText error>{formik.errors.state}</FormHelperText>
          )}
        </FormControl>

        {/* Zip Code */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.zip_code && formik.errors.zip_code)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiInputBase-input": {
              border: "0",
              borderRadius: "5px",
              background: "transparent",
            },
            "& .MuiInputBase-root": {
              background: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 6px 20px #1F54C326",
            },
          }}
        >
          <TextField
            id="zip_code"
            name="zip_code"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.zip_code}
            // label="Zip Code *"
            placeholder="Zip Code *"
            //pattern="[0-9]{5}"
            //type="number"
            //inputMode="numeric"
            error={Boolean(formik.touched.zip_code && formik.errors.zip_code)}
          />
          {Boolean(formik.touched.zip_code && formik.errors.zip_code) && (
            <FormHelperText error>{formik.errors.zip_code}</FormHelperText>
          )}
        </FormControl>
      </Box>
    </Grid>
  );
};

export default AddressForm;
