import {
  Box,
  Grid,
  Typography,
  useTheme,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDaysToCurrentDate } from "../../../../utils/functions";
import {
  SET_TOAST,
  getAllEventDiscountAction,
  getEventLocations,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef } from "react";
import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import { LIMIT_VALIDATION } from "../../../../utils/constants";
import { CALENDAR_PAGE } from "../../../../routes/constants";
import { useNavigate } from "react-router";
import AddAddress from "./AddAddress";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import AddUpdateEventDiscountCode from "./AddUpdateEventDiscountCode";

export default function CreateCampStep2({
  onNext,
  onBack,
  step,
  selectedEvent,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const selectionInputStyle = {
    width: "100%",
    minWidth: "300px",
    height: "44px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const step2Details = state?.createCampEvent?.step2Details;
  const locationsList = state?.event?.locations ?? [];
  const orgDetails = state?.org?.org?.data;

  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [showEventDiscountModal, setShowEventDiscountModal] = useState(false);
  const [selectedEventLocations, setSelectedEventLocations] = useState([]);
  const [selectedEventDiscounts, setSelectedEventDiscounts] = useState([]);

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const {
    mutateAsync: getAllEventDiscount,
    data: eventDiscounts,
    isLoading: isFetchingEventDiscounts,
  } = useMutation(getAllEventDiscountAction);

  useEffect(() => {
    if (state?.org?.org?.token && state?.user?.token) {
      const params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      getAllEventDiscount({ dispatch, params });
      dispatch(getEventLocations(params));
    }
  }, [state?.org?.org?.token, state?.user?.token]);

  useEffect(() => {
    if (step2Details) {
      setSelectedEventLocations(step2Details?.locations ?? []);
      setSelectedEventDiscounts(step2Details?.discount ?? []);
    }
  }, [step2Details]);

  const handleCloseLocationModal = (isSubmit) => {
    setShowAddAddressModal(false);
    if (isSubmit) {
      const params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      dispatch(getEventLocations(params));
    }
  };

  const handleCloseEventDiscountModal = (isSubmit) => {
    setShowEventDiscountModal(false);
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    getAllEventDiscount({ dispatch, params });
  };

  const handleChangeEventLocation = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEventLocations(
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleChangeEventDiscount = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEventDiscounts(
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const renderSelectedLocations = () => {
    if (selectedEventLocations?.length && locationsList?.length) {
      return locationsList?.map((location, index) => {
        return selectedEventLocations?.includes(location?.id) ? (
          <Box key={location?.id} display={"flex"} alignItems={"center"} my={1}>
            <Box
              sx={{
                background: orgDetails?.secondary_color
                  ? orgDetails?.secondary_color
                  : theme?.palette?.dark?.dark,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                width: 40,
                height: 40,
                minWidth: 40,
                minHeight: 40,
                borderRadius: "100%",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {index + 1}
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontSize: 15,
                fontFamily: "Poppins",
                fontWeight: 500,
                ml: 2,
              }}
            >
              {location?.name ? `${location?.name} -` : null} &nbsp;
              {`${location?.address ? location?.address + ", " : ""}
                  ${location?.suit ? location?.suit + ", " : ""}
                  ${location?.state ? location?.state + ", " : ""}
                  ${location?.city ? location?.city + ", " : ""}
                  ${location?.zip_code ? location?.zip_code : ""}
                  `}
            </Typography>
          </Box>
        ) : null;
      });
    }
  };

  const nextStep = () => {
    if (selectedEventLocations?.length) {
      dispatch({
        type: "STEP_2_DETAILS",
        data: {
          locations: selectedEventLocations ?? [],
          discount: selectedEventDiscounts ?? [],
        },
      });
      onNext();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: "Select event location" },
      });
    }
  };

  return (
    <Box p={3} mt={1}>
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
          mb: 3,
          [theme.breakpoints.down("md")]: {
            fontSize: 14,
          },
        }}
      >
        Enter your event details below. You will be guided through a few steps
        to create your event. At anytime, you can select the step you’d like to
        go back to without losing any information.
      </Typography>
      <Box>
        {/* <form onSubmit={formik.handleSubmit}> */}
        {/* <Box display="flex" justifyContent="space-between" flexWrap="wrap"> */}
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              CHOOSE FROM PREVIOUSLY ADDED LOCATIONS
            </CustomInputLabel>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <Select
                  multiple
                  displayEmpty
                  value={selectedEventLocations}
                  onChange={handleChangeEventLocation}
                  input={
                    <OutlinedInput
                      label=""
                      placeholder="Select locations..."
                      sx={selectionInputStyle}
                    />
                  }
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      minWidth: "100%",
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-input": {
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                      padding: "12px 14px",
                    },
                  }}
                  renderValue={(selected) => {
                    if (selected?.length && locationsList?.length) {
                      let locations = [];
                      locationsList?.filter((location) => {
                        if (selected.includes(location?.id)) {
                          locations.push(location?.address);
                        }
                      });
                      if (locations?.length) {
                        return locations.join(", ");
                      }
                      return selected?.join(", ");
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select one or more location...
                        </Typography>
                      );
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {/* <MenuItem disabled value="">
                          Select sub-organization...
                        </MenuItem> */}
                  {locationsList && locationsList?.length
                    ? locationsList?.map((location) => (
                        <MenuItem key={location?.id} value={location?.id}>
                          <Checkbox
                            checked={
                              selectedEventLocations.indexOf(location?.id) > -1
                            }
                          />
                          <ListItemText primary={location?.address} />
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="medium"
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: 44,
                    m: 0,
                    p: 0,
                    minWidth: "inherit",
                    fontSize: 20,
                    background: theme?.palette?.secondary?.dark,
                    color: "white",
                  }}
                  onClick={() => setShowAddAddressModal(true)}
                >
                  +
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel sx={inputLabelStyle}>
              CHOOSE DISCOUNT COUPON
            </CustomInputLabel>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <Select
                  multiple
                  displayEmpty
                  disabled={isFetchingEventDiscounts}
                  value={selectedEventDiscounts}
                  onChange={handleChangeEventDiscount}
                  input={
                    <OutlinedInput
                      label=""
                      placeholder="Select coupon code..."
                      sx={selectionInputStyle}
                    />
                  }
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      minWidth: "100%",
                      width: "100%",
                    },
                    "& .MuiOutlinedInput-input": {
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                      padding: "12px 14px",
                    },
                  }}
                  renderValue={(selected) => {
                    if (
                      selected?.length &&
                      eventDiscounts &&
                      eventDiscounts?.data?.length
                    ) {
                      let discounts = [];
                      eventDiscounts?.data?.filter((discount) => {
                        if (selected.includes(discount?.id)) {
                          discounts.push(discount?.name);
                        }
                      });
                      if (discounts?.length) {
                        return discounts.join(", ");
                      }
                      return selected?.join(", ");
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select one or more coupon...
                        </Typography>
                      );
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {eventDiscounts && eventDiscounts?.data?.length
                    ? eventDiscounts?.data?.map((discount) => (
                        <MenuItem key={discount?.id} value={discount?.id}>
                          <Checkbox
                            checked={
                              selectedEventDiscounts.indexOf(discount?.id) > -1
                            }
                          />
                          <ListItemText primary={discount?.name} />
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="medium"
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: 44,
                    m: 0,
                    p: 0,
                    minWidth: "inherit",
                    fontSize: 20,
                    background: theme?.palette?.secondary?.dark,
                    color: "white",
                  }}
                  onClick={() => setShowEventDiscountModal(true)}
                >
                  +
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box width={1} display={"flex"} flexDirection={"column"} my={3}>
          {renderSelectedLocations()}
        </Box>
        {/* </Box> */}

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          width={1}
          mt={5}
        >
          <Typography
            variant="h5"
            sx={{
              maxWidth: 120,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer",
              mr: 2,
              my: 1,
              px: 3,
            }}
            onClick={() => setShowCancelConfirmPopup(true)}
          >
            Cancel
          </Typography>

          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
              variant="h5"
              sx={{
                maxWidth: 120,
                height: 40,
                background: "transparent",
                border: `2px solid ${theme?.palette?.secondary?.dark}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme?.palette?.secondary?.dark,
                cursor: "pointer",
                px: 3,
                my: 1,
              }}
              onClick={onBack}
            >
              Back
            </Typography>
            <Button
              size="small"
              variant="contained"
              // type="submit"
              sx={{
                maxWidth: 120,
                height: 40,
                background: theme?.palette?.secondary?.dark,
                color: "white",
                ml: 2,
                px: 3,
                my: 1,
              }}
              onClick={nextStep}
              // disabled={!selectedEventLocations?.length}
            >
              Next
            </Button>
          </Box>
          {/* </form> */}
        </Box>
        {showAddAddressModal && (
          <AddAddress
            open={showAddAddressModal}
            handleClose={handleCloseLocationModal}
          />
        )}
        {showEventDiscountModal && (
          <AddUpdateEventDiscountCode
            open={showEventDiscountModal}
            handleClose={handleCloseEventDiscountModal}
            data={eventDiscounts?.data ?? []}
            setSelectedEventDiscounts={(data) => {
              //setSelectedEventDiscounts(data);
            }}
          />
        )}
      </Box>
    </Box>
  );
}
