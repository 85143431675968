const config = {
    basename: '',
    defaultPath: '/dashboard',
    gcp: {
        API_KEY: 'AIzaSyC_VSECbCC7thZhwXM_8SYfW6DAodMuSg8',
        STORAGE_BUCKET: 'gridiron-3d23b.appspot.com',
    },
}

export default config
