import { Box, Typography, useTheme } from "@mui/material";
import SuccessCheck from "../../assets/images/success_check.png";

const ThankYou = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 5,
      }}
    >
      <Typography
        sx={{ py: 5, textAlign: "center" }}
        fontFamily={"Poppins"}
        fontSize={20}
        fontWeight={500}
        color={theme?.palette?.text?.darkTextSubHeader}
      >
        Success, Thank you for registering!
      </Typography>
      <img src={SuccessCheck} style={successImgStyle} />
    </Box>
  );
};

const successImgStyle = {
  maxWidth: 100,
};

export default ThankYou;
