import { Box, Button, Divider, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material"
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Manage_Athlete_Page, PLAYER_PARENT_SUPPORT_PAGE } from "../../../routes/constants";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../../../components/CustomTextField";
import StripePayment from "../../../components/Tournament/StripePayment";
import { formatCurrency } from "../../../utils/functions";
import { useMutation } from "react-query";
import { SET_SUCCESS_ERROR_POPUP, checkEventDiscountAction, registerEventPaymentIntentAction } from "../../../store/actions";
import { NumericFormat } from "react-number-format";
import CancelIcon from '@mui/icons-material/Cancel';

const EventRegisterCardPaymentCheckout = ({
  onBack,
  handleNext,
  selectedPaymentMethod,
  eventRegistration,
  cost,
  checkAthleteEligibleResponse,
  title,
  eventId,
  selectedAthlete,
  allow_discount_code,
  allow_scholarship,
  selectedSprintEventId,
}) => {

  const theme = useTheme();
  const numericInputStyle = {
    padding: '1px',
    borderRadius: '4px',
    border: `1px solid ${theme?.palette?.grey?.[400]}`,
    width: "100%",
    boxShadow: "none",
    outline: "none",
    height: "34px",
    background: "#fafafa",
    padding: "5px"
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [discountCode, setDiscountCode] = useState("");
  const [SKBucks, setSKBucks] = useState("");
  const [SKBucksResVal, setSKBucksResVal] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [totalDiscountedValue, setTotalDiscountedValue] = useState(0);
  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(checkAthleteEligibleResponse?.total_amount ?? 0);
  const SKBucksFormatted = SKBucks && typeof SKBucks === "string" ? parseFloat(SKBucks.replace(/[^0-9.]/g, '')) : 0;

  const { mutateAsync: registerEventPaymentIntent } = useMutation(registerEventPaymentIntentAction);
  const { mutateAsync: checkEventDiscount } = useMutation(checkEventDiscountAction);

  const orgDetails = state?.org?.org?.data;
  const resetDiscountIconStyle = {
    color: "red",
    cursor: "pointer",
    position: "absolute",
    right: "8px",
  };

  const onNext = (e) => {
    e?.preventDefault();
    if (selectedPaymentMethod) {
      if (selectedPaymentMethod === "PRODUCT") {
        handleNext("PRODUCT_PAYMENT_SELECTION_PREVIEW");
      }
    }
  }

  useEffect(() => {
    setTotalAmountAfterDiscount(checkAthleteEligibleResponse?.total_amount ?? 0);
  }, [checkAthleteEligibleResponse?.total_amount]);

  const stripePaymentIntent = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    if (!cost) {
      return;
    }

    let bodyParam = {
      "event_id": selectedSprintEventId ? selectedSprintEventId : eventId,
      "original_amount": cost,
      "total_amount": totalAmountAfterDiscount,
      "discount": discountCode ?? "",
      "discount_value": totalDiscountedValue ?? 0,
      "is_apply_scholarship": checkAthleteEligibleResponse?.isScholarShipAllowed ?? false,
      "scholarship_amount": checkAthleteEligibleResponse?.scholarship_discount ?? 0,
      "discount_code_amount": discountedAmount ?? 0,
    }

    if (SKBucks) {
      if (typeof SKBucks === "string") {
        bodyParam["sketchplay_bucks"] = SKBucks ? parseFloat(SKBucks.replace(/[^0-9.]/g, '')) : "";
      } else {
        bodyParam["sketchplay_bucks"] = SKBucks;
      }
    } else {
      bodyParam["sketchplay_bucks"] = 0;
    }

    let response = await registerEventPaymentIntent({ dispatch, bodyParam, params })
    if (response && response.ok) {
      let values = {
        clientSecret: response.data?.clientSecret,
        transaction_id: response.data?.transaction_id,
        publishableKey: response.data?.publishableKey,
        amount: response?.data?.original_amount,
        sketchplay_bucks: bodyParam?.sketchplay_bucks ?? 0,
        discount_value: response?.data?.discount_value ?? 0,
        discount: response?.data?.discount ?? "",
        discount_code_amount: response?.data?.discount_code_amount ?? 0,
        total_amount: totalAmountAfterDiscount,
      }
      setPaymentFormData(values);
    } else {
      //manage error
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong, try again" }
      })
    }
  };

  useMemo(() => {
    if (totalAmountAfterDiscount != 0) {
      stripePaymentIntent();
    }
  }, [totalAmountAfterDiscount, SKBucksResVal, totalDiscountedValue]);

  const applyEventDiscount = async (SKBucksResetVal, discountCodeResetVal) => {
    let bodyParam = {
      "event_id": selectedSprintEventId ? selectedSprintEventId : eventId,
      "athlete_id": selectedAthlete,
      "total_amount": checkAthleteEligibleResponse?.total_amount,
      "scholarship_amount": checkAthleteEligibleResponse?.scholarship_discount ?? 0,
      "discount": discountCode ?? "",
    }

    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };

    if (SKBucksResetVal === 0) {
      bodyParam["sketchplay_bucks"] = 0;
    } else {
      if (SKBucks) {
        if (typeof SKBucks === "string") {
          bodyParam["sketchplay_bucks"] = SKBucks ? parseFloat(SKBucks.replace(/[^0-9.]/g, '')) : 0;
        } else {
          bodyParam["sketchplay_bucks"] = SKBucks;
        }
      } else {
        bodyParam["sketchplay_bucks"] = 0;
      }
    }

    if (typeof discountCodeResetVal !== "undefined") {
      bodyParam["discount"] = discountCodeResetVal;
    }

    let response = await checkEventDiscount({ dispatch, bodyParam, params });

    if (response && response.ok) {
      setDiscountedAmount(response?.data?.discount_code_amount ?? 0);
      setSKBucksResVal(response?.data?.sketchplay_bucks_discount ?? 0);
      setTotalAmountAfterDiscount(response?.data?.total_amount);
      setTotalDiscountedValue(response?.data?.discount_value);
      if (response?.message) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response?.message }
        })
      }
    } else {
      //manage error
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong, try again" }
      })
    }
  };

  const resetSKBucksDiscount = () => {
    setSKBucks("");
    applyEventDiscount(0, discountCode);
  }

  const resetDiscountCode = () => {
    setDiscountCode("");
    applyEventDiscount(SKBucks, "");
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      // justifyContent={"center"}
      alignItems={"center"}
      height={1}
      width={1}
      p={3}
    >
      <form onSubmit={onNext} style={{ width: "80%" }}>
        <Box
          width={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center",
              mb: 3
            }}
          >
            Checkout
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 500,
              fontFamily: "Poppins",
              my: 1.5
            }}
          >
            You’ve chosen to pay with a Credit/Debit Card
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "center",
              color: theme?.palette?.dark?.darkTextSubHeader,
            }}
            onClick={() => {
              setPaymentFormData({});
              onBack("PAYMENT_METHOD_SELECTION");
            }}
          >
            Pay a different way
          </Typography>
          <Box
            sx={{
              border: "1px solid #CCCCCC",
              borderRadius: "10px",
              my: 2
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
              <Typography
                variant="p"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  mr: 1
                }}
              >
                {title ?? "N/A"}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  color: "#707070",
                }}
              >
                {cost ? formatCurrency(cost) : "N/A"}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%", height: "2px" }}></Divider>
            {allow_scholarship &&
              <>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
                  <Typography
                    variant="p"
                    sx={{
                      color: theme?.palette?.dark?.darkTextSubHeader,
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      mr: 1
                    }}
                  >
                    Scholarship Discount
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      color: theme?.palette?.dark?.darkTextSubHeader,
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    }}
                  >
                    -({`${checkAthleteEligibleResponse?.scholarship_discount_per}%` ?? "N/A"})
                  </Typography>
                </Box>
                <Divider sx={{ width: "100%", height: "2px" }}></Divider>
              </>
            }
            {allow_discount_code &&
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
                <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                  <Typography
                    variant="p"
                    sx={{
                      color: theme?.palette?.dark?.darkTextSubHeader,
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      mr: 1,
                      mb: 0.5,
                    }}
                  >
                    Discount Code
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} position={"relative"} width={1}>
                    <CustomTextField
                      name="coupon"
                      placeholder="Enter code..."
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        setDiscountCode(e?.target?.value);
                      }}
                      value={discountCode}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "5px !important",
                        },
                        "& .MuiInputOutlined-input": {
                          padding: "5px !important",
                        },
                        width: "100%",
                      }}
                    />
                    {discountCode &&
                      <CancelIcon
                        sx={resetDiscountIconStyle}
                        fontSize="small"
                        onClick={resetDiscountCode}
                      />
                    }
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textDecoration: "underline",
                    cursor: discountCode ? "pointer" : "inherit",
                    textAlign: "center",
                    color: discountCode ? theme?.palette?.dark?.darkTextSubHeader : theme?.palette?.grey["300"],
                    ml: 1.5,
                    mt: 1.8
                  }}
                  onClick={() => {
                    if (discountCode) {
                      applyEventDiscount()
                    }
                  }}
                >
                  Apply
                </Typography>
              </Box>
            }
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} px={2} pb={2} pt={allow_discount_code ? 0 : 2}>
              <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                <Typography
                  variant="p"
                  sx={{
                    color: theme?.palette?.dark?.darkTextSubHeader,
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    mr: 1,
                    mb: 0.5,
                  }}
                >
                  Sketchplay Bucks - <strong>({formatCurrency(checkAthleteEligibleResponse ? checkAthleteEligibleResponse?.sketchplay_bucks : 0)})</strong>
                </Typography>
                <Box display={"flex"} alignItems={"center"} position={"relative"} width={1}>
                  <NumericFormat
                    name="cost"
                    placeholder="Enter amount..."
                    onChange={(e) => {
                      setSKBucks(e?.target?.value);
                    }}
                    value={SKBucks}
                    style={numericInputStyle}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    allowNegative={false}
                  />
                  {SKBucksResVal !== 0 &&
                    <CancelIcon
                      sx={resetDiscountIconStyle}
                      fontSize="small"
                      onClick={resetSKBucksDiscount}
                    />
                  }
                </Box>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: (SKBucksFormatted > 0) ? "pointer" : "inherit",
                  textAlign: "center",
                  color: (SKBucksFormatted > 0) ? theme?.palette?.dark?.darkTextSubHeader : theme?.palette?.grey["300"],
                  ml: 1.5,
                  mt: 1.8
                }}
                onClick={() => {
                  if (SKBucksFormatted > 0) {
                    applyEventDiscount();
                  }
                }}
              >
                Apply
              </Typography>
            </Box>
            <Divider sx={{ width: "100%", height: "2px" }}></Divider>
            <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"} p={2}>
              <Typography
                variant="p"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 700,
                  fontFamily: "Poppins",
                }}
              >
                Total Cost:
              </Typography>
              <Typography
                variant="p"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 700,
                  fontFamily: "Poppins",
                }}
              >
                {formatCurrency(totalAmountAfterDiscount ? totalAmountAfterDiscount : 0)}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%", height: "2px" }}></Divider>
            {totalAmountAfterDiscount == 0 ? null :
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
                <StripePayment
                  afterPayemntMethod={() => {
                    eventRegistration(paymentFormData);
                  }}
                  formData={paymentFormData}
                  type="team"
                  payAmount={cost}
                  isShowCancelBtn={false}
                  isFromEventRegister={true}
                />
              </Box>
            }
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={1}
            mt={4}
          >
            {totalAmountAfterDiscount == 0 &&
              <Button
                sx={{
                  background: `${theme?.palette?.inputsLabels?.green} !important`,
                  color: "white",
                  width: "70% !important",
                  padding: "0.5rem !important",
                  borderRadius: "10px !important",
                }}
                onClick={() => {
                  let sketchplay_bucks = 0;
                  if (SKBucks) {
                    if (typeof SKBucks === "string") {
                      sketchplay_bucks = SKBucks ? parseFloat(SKBucks.replace(/[^0-9.]/g, '')) : "";
                    } else {
                      sketchplay_bucks = SKBucks;
                    }
                  } else {
                    sketchplay_bucks = 0;
                  }
                  eventRegistration({
                    total_amount: totalAmountAfterDiscount,
                    sketchplay_bucks: sketchplay_bucks,
                    discount_value: totalDiscountedValue,
                    discount_code_amount: discountedAmount,
                    discount: discountCode,
                  });
                }}
              >
                Register
              </Button>
            }
          </Box>
        </Box>
      </form>
      <Typography
        variant="h6"
        sx={{
          mt: 5,
          fontSize: "14px",
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
          textAlign: "center",
          color: orgDetails?.primary_color,
        }}
        onClick={() => {
          setPaymentFormData({});
          onBack("PAYMENT_METHOD_SELECTION");
        }}
      >
        Back
      </Typography>
    </Box>
  )
}

export default EventRegisterCardPaymentCheckout;
