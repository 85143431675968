import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormHelperText,
    Grid,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Popover,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import SettingsIcon from "@mui/icons-material/Settings";
import { CustomInputLabel } from '../../components/Common/Inputs/InputLabel';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
    SET_TOAST,
    createTeamByTeamManagerAction,
    getTeamCoachAction,
    getTeamManagerAction,
    SET_LOADER,
    getTournamentTeams
} from '../../store/actions';
import AddTeamManager from '../../components/Tournament/AddTeamManager';
import AddTeamCoach from '../../components/Tournament/AddTeamCoach';
import UploadRosterDocuments from '../../components/Tournament/UploadRosterDocuments';
import ComingSoon from '../../components/ComingSoon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiDataGrid from '../../components/MuiDataGrid/MuiDataGrid';
import { useRef } from 'react';
import { charValidate } from '../../utils/functions';
import ActionCard from '../../components/Common/ActionCard';
import { Tournament_Paid_Roster_Athlete_List } from '../../routes/constants';
import TeamPaymentAmount from '../../components/Tournament/TeamPaymentAmount';
import httpClient from "@utils/httpClient";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const ParentTournament = () => {

    const [teamsList, setTeamsList] = useState([]);
    const [paginationOptions, setPaginationOptions] = useState({
        pageSize: 0,
        pageNum: 0,
        limit: 5,
    });
    const [isTournamentTeamListLoading, setTournamentTeamListLoading] = useState(false);
    const tableRef = useRef();
    const state = useSelector((state) => state);
    const dispatch = useDispatch();

    const columns = [
        {
            field: "",
            headerName: "TOURNAMENT",
            flex: 1,
            minWidth: 100,
            sortable: false,
        },
        {
            field: "title",
            headerName: "TEAM NAME",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.title ?? "N/A"}>
                    {data?.row?.title ? charValidate(data?.row?.title, 15) : "N/A"}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "first_name",
            headerName: "Athlete First Name",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.athlete?.first_name ?? "N/A"}>
                    {data?.row?.athlete?.first_name ? charValidate(data?.row?.athlete?.first_name, 15) : "N/A"}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "last_name",
            headerName: "Athlete Last Name",
            renderCell: (data) => (
                <Tooltip title={data?.row?.athlete?.last_name ?? "N/A"}>
                    {data?.row?.athlete?.last_name ? charValidate(data?.row?.athlete?.last_name, 15) : "N/A"}
                </Tooltip>
            ),
            flex: 1,
            minWidth: 100,
            sortable: false,
        }
    ];

    useEffect(() => {
        if (state?.user?.token && state?.org?.org?.token) {
            getAllTournamentTeam();
        }
    }, [state?.user?.token, state?.org?.org?.token]);

    const getAllTournamentTeam = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            skip: 0,
            limit: 1000,
            tournamentId: ""
        }
        setTournamentTeamListLoading(true);
        try {
            let response = await getTournamentTeams(params, dispatch);
            if (response?.ok) {
                setTeamsList(response?.data?.data?.TournamentList ?? []);
            }
        } catch (error) {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: error?.message ?? "Something went wrong",
                },
            });
        }
        setTournamentTeamListLoading(false);
    }


    return (
        <Box>
            <MuiDataGrid
                headerLabel={"My Tournaments"}
                gridData={isTournamentTeamListLoading ? [] : teamsList}
                columns={columns}
                paginationOptions={paginationOptions}
                tableRef={tableRef}
                isLoading={isTournamentTeamListLoading}
                isAddNewBtnShow={false}
            />
        </Box>
    )

}

export default ParentTournament;
