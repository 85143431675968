import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateOrgEmailAPISettings,
  getOrgData,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";

export default function ManageEmailAPIKeys() {
  const validationSchema = Yup.object({
    email_api_key: Yup.string().required("API Key is required").trim(),
    email_client_id: Yup.string().required("Client Id is required").trim(),
    registered_users_id: Yup.string().required("Registered User List Key is required").trim(),
    registered_scholarship_id: Yup.string().required("Scholarship User List Key is required").trim(),
    registered_package_id: Yup.string().required("Package User List Key is required").trim(),
  });
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const orgdata = useSelector((state) => state.org?.org?.data);
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] = useState(false);
  const [requestData, setRequestData] = useState({});

  const formik = useFormik({
    initialValues: {
      email_api_key: orgdata?.email_api_key ?? "",
      email_client_id: orgdata?.email_client_id ?? "",

      registered_users_id: orgdata?.registered_users_id ?? "",
      registered_package_id: orgdata?.registered_package_id ?? "",
      registered_scholarship_id: orgdata?.registered_scholarship_id ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowCancelConfirmPopup(true);
      setRequestData(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("email_api_key", orgdata?.email_api_key ?? "");
    formik.setFieldValue("email_client_id", orgdata?.email_client_id ?? "");
    formik.setFieldValue("registered_package_id", orgdata?.registered_package_id ?? "");
    formik.setFieldValue("registered_scholarship_id", orgdata?.registered_scholarship_id ?? "");
    formik.setFieldValue("registered_users_id", orgdata?.registered_users_id ?? "");
  }, [orgdata]);

  const saveEmailOrgSetings = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };

    let response = await updateOrgEmailAPISettings(
      dispatch,
      params,
      requestData,
    );
    setShowCancelConfirmPopup(false);
    if (response?.ok) {
      dispatch(getOrgData());
      setRequestData({});
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message:
            response?.message ??
            "Organization Email API settings updated successfully",
        },
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <Typography variant="h3">Email Provider Information</Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Grid
                    container
                    spacing={3}
                    mt={0}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        API Key
                      </CustomInputLabel>
                      <TextField
                        name="email_api_key"
                        // variant="outlined"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email_api_key}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        placeholder="Enter API Key..."
                        error={Boolean(
                          formik.touched.email_api_key &&
                          formik.errors.email_api_key,
                        )}
                        multiline
                        rows={1}
                      />
                      {Boolean(
                        formik.touched.email_api_key &&
                        formik.errors.email_api_key,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.email_api_key}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        Client Id
                      </CustomInputLabel>
                      <TextField
                        name="email_client_id"
                        // variant="outlined"
                        typr="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email_client_id}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        placeholder="Enter Client ID..."
                        error={Boolean(
                          formik.touched.email_client_id &&
                          formik.errors.email_client_id,
                        )}
                      />
                      {Boolean(
                        formik.touched.email_client_id &&
                        formik.errors.email_client_id,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.email_client_id}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        Sketchplay register users list key
                      </CustomInputLabel>
                      <TextField
                        name="registered_users_id"
                        typr="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.registered_users_id}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        placeholder="Enter Client ID..."
                        error={Boolean(
                          formik.touched.registered_users_id &&
                          formik.errors.registered_users_id,
                        )}
                      />
                      {Boolean(
                        formik.touched.registered_users_id &&
                        formik.errors.registered_users_id,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.registered_users_id}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        Sketchplay scholarship users list key
                      </CustomInputLabel>
                      <TextField
                        name="registered_scholarship_id"
                        typr="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.registered_scholarship_id}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        placeholder="Enter Client ID..."
                        error={Boolean(
                          formik.touched.registered_scholarship_id &&
                          formik.errors.registered_scholarship_id,
                        )}
                      />
                      {Boolean(
                        formik.touched.registered_scholarship_id &&
                        formik.errors.registered_scholarship_id,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.registered_scholarship_id}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        Sketchplay package users list key
                      </CustomInputLabel>
                      <TextField
                        name="registered_package_id"
                        typr="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.registered_package_id}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        placeholder="Enter Client ID..."
                        error={Boolean(
                          formik.touched.registered_package_id &&
                          formik.errors.registered_package_id,
                        )}
                      />
                      {Boolean(
                        formik.touched.registered_package_id &&
                        formik.errors.registered_package_id,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.registered_package_id}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    mt={0}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                      <Box sx={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ paddingX: 3.5, paddingY: 1 }}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {showCancelConfirmPopup && (
        <CancelConfirmDialog
          title={"Email"}
          message={
            "Are you sure you want to update this information? Doing so could cause your email communication to break. Be sure the edits you make are correct."
          }
          open={showCancelConfirmPopup}
          handleClose={() => setShowCancelConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelConfirmPopup(false);
          }}
          handleConfirmClose={saveEmailOrgSetings}
          confirmBtnLabel={"Yes, Save"}
          cancelBtnLabel={"No, Cancel"}
          modalSize={"sm"}
        />
      )}
    </>
  );
}
