import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import AddressForm from "../../components/AppForms/AddressForm";
import UserInfoForm from "../../components/AppForms/UserInfoForm";

const validationSchema = Yup.object({
  first_name: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("First Name is required"),
  last_name: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Last Name is required"),
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Must be a valid email"),
  mobile: Yup.string().transform(value => (value === '' ? null : value)).nullable().required("Mobile is required"),
  countryCode: Yup.string().required("Country Code is required"),
  address: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Address is required"),
  suite: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable(),
  city: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("City is required"),
  state: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("State is required"),
  zip_code: Yup.string().max(10).transform(value => (value === '' ? null : value)).nullable().required("Zip Code is required"),
});

const AddAthlete = ({ step, setStep, formData, setFormData }) => {

  const [showError, setShowError] = useState(null);

  const handleSave = (values, actions) => {
    setShowError(null);
    const countryCode = values.countryCode.toUpperCase()
    const mobile = values.mobile;
    const isValid = mobile.length === 10 ? true : false;
    if (!isValid) {
      formik.setFieldError('mobile', 'Invalid mobile number');
    } else {
      setFormData((prevStage) => {
        return {
          ...prevStage,
          ...values,
          countryCode
        };
      });
      setStep(step + 1);
    }

  };

  const formik = useFormik({
    initialValues: {
      first_name: formData?.first_name || "",
      last_name: formData?.last_name || "",
      email: formData?.email || "",
      mobile: formData?.mobile || "",
      countryCode: formData?.countryCode || "",
      address: formData?.address || "",
      suite: formData?.suite || "",
      city: formData?.city || "",
      state: formData?.state || "",
      zip_code: formData?.zip_code || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  return (
    <>
      <Box sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}>
        <Grid item xs={12} sm={12} sx={{ my: 1 }}>
          <Typography variant="h2" sx={{ color: '#262626' }}>
            Add Athlete's Name & Address
          </Typography>
        </Grid>
      </Box>
      {showError && (
        <Grid item xs={12} alignItems="center" justifyContent="left">
          <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
            {showError}
          </Alert>
        </Grid>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box className="guardian-form-box" sx={{
          '&.guardian-form-box div': {
            maxWidth: '100%',
          }
        }}>
          <UserInfoForm formik={formik} isAllowLogin={true} isParent={true} />
        </Box>
        <Divider sx={{ mt: 4, mb: 4 }}></Divider>
        <Box className="guardian-form-box" sx={{
          '&.guardian-form-box div': {
            maxWidth: '100%',
          }
        }}>
          <AddressForm formik={formik} />
        </Box>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ width: "10.5%", float: "right" }}>
            <Button
              disableElevation
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ fontWeight: "600", mt: 3 }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default AddAthlete;
