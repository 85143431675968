import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, Alert, Card, CardContent, Step, useMediaQuery } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAthlete from './AddAthlete';
import AthleteInformation from './AthleteInformation';
import SocialAccounts from './SocialAccounts';
import EditAthlete from './EditAthlete';
import EditAthleteInformation from './EditAthleteInformation';
import EditSocialAccounts from './EditSocialAccounts';
import AthleteDetail from './AthleteDetail';
import { getMe } from "@store/actions";
import UploadRosterDocuments from '../../components/Tournament/UploadRosterDocuments';
import { useTheme } from "@mui/material/styles";
import { phoneFormat } from '../../utils/functions';

const ManageAthlete = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const getArr = user.hasOwnProperty('athlete') ? user.athlete : []
  const athleteArr = [...getArr];
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(1);
  const [isAbleToManage, setIsAbleToManage] = useState(false);
  const [activeAthleteId, setActiveAthleteId] = useState(0);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formContainerRef = useRef(null);

  const handleEditAthlete = (obj) => {
    if(isMobile) {
      scrollToFormContainer()
    }
    setActiveAthleteId(obj.id)
    setFormData(obj)
    setStep(4)
  }

  const uploadImgStyle = {
    width: "70px",
    height: "70px",
    borderRadius: "100%",
    objectFit: "cover",
  };

  useEffect(() => {
    dispatch(getMe())
    if (user?.registeredBy?.id === undefined) {
      setIsAbleToManage(true);
    } else if (user?.id === user?.registeredBy?.id) {
      setIsAbleToManage(true);
    } else if (user?.isPrimaryParent) {
      setIsAbleToManage(true);
    } else if (user?.permission?.manageAthletes) {
      setIsAbleToManage(true);
    } else {
      setIsAbleToManage(false);
    }
  }, [])

  const handleDetailAthlete = (obj) => {
    setActiveAthleteId(obj.id)
    setFormData(obj)
    setStep(7)
  }


  const showDocModal = (athleteDetails) => {
    if (athleteDetails) {
      setSelectedAthlete(athleteDetails);
      setShowDocumentModal(true);
    }
  }

  const handleCloseDocModal = () => {
    setShowDocumentModal(false);
    dispatch(getMe());
  };

  const scrollToFormContainer = () => {
    if (formContainerRef.current) {
      formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ pt: 2 }}
      >
        <Grid item xs={12} lg={4} md={5} sx={{ mb: 3 }}>
          <Grid container sx={{
            mt: 4, mb: 4, alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              mt: 0,
              mb: 2,
            }
          }}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{
                color: '#262626', [theme.breakpoints.down('md')]: {
                  fontSize: "1.3rem",
                }
              }}>
                My Athletes
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems="center" textAlign="center">
            {athleteArr.map((athleteData, index) => (
              <Card
                key={index}
                sx={{
                  boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "white !important",
                  padding: "1rem",
                  overflow: 'inherit',
                  mt: 2,
                  border: athleteData.id === activeAthleteId ? '1px solid #000000' : 'none'
                }}
              >
                <CardContent sx={{ p: "0", pb: "0 !important", position: 'relative' }}>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                    <Box sx={{
                      pr: 2,
                      cursor: 'pointer'
                    }} onClick={() => handleDetailAthlete(athleteData)}>
                      <Box
                        sx={{
                          width: "70px",
                          height: "70px",
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: "#000000",
                            justifyContent: "center",
                            margin: "0",
                            minWidth: "70px",
                            color: "#ffffff",
                            borderRadius: "50rem",
                            position: "relative",
                            overflow: "hidden",
                            textTransform: "uppercase",
                            height: "70px",
                            width: "70px",
                            lineHeight: "70px",
                            marginLeft: "0",
                            marginRight: "auto",
                            marginTop: "0",
                            fontSize: "2rem",
                            cursor: "pointer",
                            fontWeight: 'bold',
                            display: 'block',
                            textAlign: 'center',
                          }}
                        >
                          {
                            athleteData?.image ?
                              <>
                                <img src={athleteData?.image} style={uploadImgStyle} />
                              </>
                              :
                              <>
                                {`${athleteData?.first_name?.charAt(
                                  0,
                                )}${athleteData?.last_name?.charAt(0)}`}
                              </>
                          }
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      width: "100%",
                    }}>
                      <Grid item xs={12} sm container>
                        <Grid item xs={8} container direction="column" spacing={2} sx={{ cursor: 'pointer' }} onClick={() => handleDetailAthlete(athleteData)}>
                          <Grid item xs sx={{ textAlign: 'left' }}>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="div"
                              sx={{ fontSize: "16px", fontWeight: "600", m: 0 }}
                            >
                              {`${athleteData?.first_name} ${athleteData?.last_name}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                fontFamily: '"Arial",sans-serif,Arial',
                                fontSize: "14px",
                              }}
                            >
                              {phoneFormat(athleteData?.mobile)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                fontFamily: '"Arial",sans-serif,Arial',
                                fontSize: "14px",
                              }}
                            >
                              {athleteData?.email}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{ alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              textAlign: "center",
                              padding: '0 1rem 0 0',
                            }}
                          >
                            {isAbleToManage &&
                              <Box onClick={() => handleEditAthlete(athleteData)} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                background: 'black',
                                width: '2rem', // Adjust the width and height as needed
                                height: '2rem',
                                cursor: 'pointer'
                              }}>
                                <EditOutlinedIcon sx={{ color: 'white', width: '0.8em', height: '0.8em' }} />
                              </Box>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 3 }} ref={formContainerRef}>
          {isAbleToManage &&
            <>
              {
                step === 1 &&
                <AddAthlete
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  setFormData={setFormData}
                />
              }
              {
                step === 2 &&
                <AthleteInformation
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  setFormData={setFormData}
                />
              }
              {
                step === 3 &&
                <SocialAccounts
                  step={step}
                  setStep={setStep}
                  setFormData={setFormData}
                  formData={formData}
                  showDocumentModal={showDocModal}
                />
              }
              {
                step === 4 &&
                <EditAthlete
                  step={step}
                  setStep={setStep}
                  setFormData={setFormData}
                  formData={formData}
                  setActiveAthleteId={setActiveAthleteId}
                />
              }
              {
                step === 5 &&
                <EditAthleteInformation
                  step={step}
                  setStep={setStep}
                  setFormData={setFormData}
                  formData={formData}
                  setActiveAthleteId={setActiveAthleteId}
                />
              }
              {
                step === 6 &&
                <EditSocialAccounts
                  step={step}
                  setStep={setStep}
                  setFormData={setFormData}
                  formData={formData}
                  setActiveAthleteId={setActiveAthleteId}
                />
              }

            </>}
          {
            step === 7 &&
            <AthleteDetail
              step={step}
              setStep={setStep}
              formData={formData}
              setFormData={setFormData}
              setActiveAthleteId={setActiveAthleteId}
            />
          }
        </Grid>

        {/* {
                    Object.keys(user?.permission).length !== 0 &&
                    <>
                        {
                            user?.permission.manageAthletes &&
                            <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                                {
                                    step === 1 &&
                                    <AddAthlete
                                        step={step}
                                        setStep={setStep}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                }
                                {
                                    step === 2 &&
                                    <AthleteInformation
                                        step={step}
                                        setStep={setStep}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                }
                                {
                                    step === 3 &&
                                    <SocialAccounts
                                        step={step}
                                        setStep={setStep}
                                        setFormData={setFormData}
                                        formData={formData}
                                    />
                                }
                                {
                                    step === 4 &&
                                    <EditAthlete
                                        step={step}
                                        setStep={setStep}
                                        setFormData={setFormData}
                                        formData={formData}
                                    />
                                }
                                {
                                    step === 5 &&
                                    <EditAthleteInformation
                                        step={step}
                                        setStep={setStep}
                                        setFormData={setFormData}
                                        formData={formData}
                                    />
                                }
                                {
                                    step === 6 &&
                                    <EditSocialAccounts
                                        step={step}
                                        setStep={setStep}
                                        setFormData={setFormData}
                                        formData={formData}
                                    />
                                }
                                {
                                    step === 7 &&
                                    <AthleteDetail
                                        step={step}
                                        setStep={setStep}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                }
                            </Grid>
                        }
                    </>
                } */}
      </Grid >
      {showDocumentModal &&
        <UploadRosterDocuments
          open={showDocumentModal}
          handleClose={handleCloseDocModal}
          rosterId={selectedAthlete?.id ?? ""}
          selectedAthlete={selectedAthlete}
          isShowRosterId={false}
        />
      }
    </>
  )

}

export default ManageAthlete;
