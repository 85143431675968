import { Box, Button, FormHelperText, Grid, useTheme } from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_LOADER,
  SET_TOAST,
  ADD_PRODUCT_CATEGORY,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import httpClient from "@utils/httpClient";

const ProductCategory = ({ setStep }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleSave = async (values, actions) => {
    const trimmedTitleValue = values?.title?.trim().replace(/\s+/g, ' ');
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.post("/addProductCategory", { title: trimmedTitleValue });
    dispatch({ type: SET_LOADER, data: false });
    if (res && res.ok) {
      actions.resetForm();
      dispatch({
        type: ADD_PRODUCT_CATEGORY,
        data: res.data,
      });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: res.message ?? "Something went wrong",
        },
      });
      setStep(2);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: res.message ?? "Something went wrong",
        },
      });
      return false;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required("Title is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <CustomInputLabel htmlFor="title">Category Title</CustomInputLabel>
          <CustomTextField
            id="title"
            name="title"
            placeholder="Enter Category Title"
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.title}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                maxWidth: 350,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.title && formik.errors.title) && (
            <FormHelperText error>{formik.errors.title}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ p: 2, mt: 4 }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
          color="inherit"
          onClick={() => setStep(2)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

export default ProductCategory;
