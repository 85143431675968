import {
  Box,
  Button,
  Divider,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Manage_Athlete_Page,
  PLAYER_PARENT_SUPPORT_PAGE,
} from "../../../routes/constants";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import {
  SET_SUCCESS_ERROR_POPUP,
  checkEligibleForEvent,
} from "../../../store/actions";

const EventRegisterAthleteSelection = ({
  onBack,
  handleNext,
  setSelectedAthlete,
  selectedAthlete,
  eventId,
  cost,
  eventRegistration,
  setCheckAthleteEligibleResponse,
  approvalGrade,
  selectedSprintEventId,
  positionsList,
  selectedPosition,
  handleChangePositionSelection,
  eventGrade,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [eventReuqestData, setEventReuqestData] = useState("");
  const [disableCheckout, setDisableCheckout] = useState(false);
  const orgDetails = state?.org?.org?.data;
  const athletes = state?.user?.user?.athlete ?? [];
  const userType = state?.user?.user?.user_type;

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "200px",
      },
    },
  };
  const selectionInputStyle = { height: 40, width: "200px" };

  const { mutateAsync: checkEventEligibility } = useMutation(
    checkEligibleForEvent,
  );

  const onNext = (e) => {
    e?.preventDefault();
    if (selectedAthlete) {
      checkEligibility();
    }
  };

  const checkEligibility = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: selectedSprintEventId ? selectedSprintEventId : eventId,
      athleteId: selectedAthlete,
    };

    let response = await checkEventEligibility({ dispatch, params });
    if (response?.ok) {
      setEventReuqestData(response?.data ? response?.data : "");
      if (
        response?.data?.isDenied ||
        response?.data?.isPending ||
        !response?.data?.eligible ||
        response?.data?.alreadyBooked
      ) {
        setDisableCheckout(true);
      }
      if (response?.data?.eligible && !response?.data?.alreadyBooked) {
        if (cost) {
          setCheckAthleteEligibleResponse(response?.data);
          handleNext("PAYMENT_METHOD_SELECTION");
        } else {
          eventRegistration();
        }
      } else {
        if (!response?.data?.alreadyBooked) {
          if (
            !response?.data?.isDenied &&
            !response?.data?.isPending &&
            approvalGrade
          ) {
            handleNext("EVENT_REGISTRATION_REQUEST");
          }
        }
        // dispatch({
        //   type: SET_SUCCESS_ERROR_POPUP,
        //   data: { open: true, type: 'error', message: response?.message ? response?.message : "The selected athlete is not eligible for this event" }
        // })
      }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const handleChangeAthleteSelection = (e) => {
    let value = e?.target?.value;
    setDisableCheckout(false);
    setSelectedAthlete(value);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={1}
      width={1}
      p={3}
    >
      <form onSubmit={onNext}>
        <Box
          width={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Choose the athlete that you would like to register:
          </Typography>
          <Box mt={3} mb={positionsList?.length ? 0 : 4} width={0.8}>
            <CustomInputLabel>Select Athlete/Player</CustomInputLabel>
            <Select
              required={true}
              onChange={(e) => handleChangeAthleteSelection(e)}
              value={selectedAthlete}
              input={<OutlinedInput sx={selectionInputStyle} />}
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              renderValue={(value) => {
                if (value) {
                  let selected = athletes?.find(
                    (athlete) => athlete?.id === value,
                  );
                  if (selected) {
                    return `${selected.first_name} ${selected.last_name}`;
                  }
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Choose athlete...
                    </Typography>
                  );
                }
              }}
              className="MuiSelect-select"
              sx={{
                "& .MuiSelect-select": {
                  padding: "10.5px 14px",
                  background: theme.palette.background.paper,
                },
                width: "100%",
                mb: 1,
              }}
            >
              {athletes?.map((athlete) => (
                <MenuItem key={athlete.id} value={athlete.id}>
                  {`${athlete.first_name} ${athlete.last_name}`}
                </MenuItem>
              ))}
            </Select>
            {eventReuqestData ? (
              eventReuqestData?.isDenied || eventReuqestData?.isPending ? (
                <Typography
                  variant="p"
                  sx={{
                    color: eventReuqestData?.isDenied
                      ? "red"
                      : theme?.palette?.dark?.darkTextSubHeader,
                    fontWeight: 600,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                  }}
                >
                  {eventReuqestData?.isDenied
                    ? "Registration request for the selected athlete has been denied by the administrator"
                    : "Registration reuqest is in review by the administrator for the selected athlete"}
                </Typography>
              ) : eventReuqestData?.alreadyBooked ? (
                <Typography
                  variant="p"
                  sx={{
                    color: theme?.palette?.dark?.darkTextSubHeader,
                    fontWeight: 600,
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                  }}
                >
                  This event is already been registered for the selected athlete
                </Typography>
              ) : eventReuqestData?.eligible ? null : (
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="p"
                    sx={{
                      color: "red",
                      fontWeight: 600,
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontStyle: "italic",
                    }}
                  >
                    This event is restricted to athletes in a specific grade. Be
                    sure you update your athlete profile information to confirm
                    elgibility.
                  </Typography>
                </Box>
              )
            ) : null}
          </Box>

          {userType === "parent" && positionsList?.length ? (
            <Box mt={3} mb={4} width={0.8}>
              <CustomInputLabel>POSITION</CustomInputLabel>
              <Select
                multiple
                displayEmpty
                value={selectedPosition}
                onChange={handleChangePositionSelection}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select position..."
                    sx={selectionInputStyle}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10.5px 14px",
                    background: theme.palette.background.paper,
                  },
                  width: "100%",
                }}
                renderValue={(selected) => {
                  if (selected?.length && positionsList?.length) {
                    let positions = [];
                    positionsList?.forEach((position) => {
                      if (selected.includes(position?.id)) {
                        positions.push(position?.name);
                      }
                    });
                    if (positions?.length) {
                      return positions.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select one or more position...
                      </Typography>
                    );
                  }
                }}
                // MenuProps={MenuProps}
              >
                {positionsList?.length
                  ? positionsList?.map((position) => (
                      <MenuItem key={position?.id} value={position?.id}>
                        <Checkbox
                          checked={selectedPosition.indexOf(position?.id) > -1}
                        />
                        <ListItemText primary={position?.name} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Box>
          ) : null}

          <Button
            // onClick={onNext}
            sx={{
              background: disableCheckout
                ? `${theme?.palette?.grey["300"]} !important`
                : `${theme?.palette?.inputsLabels?.green} !important`,
              color: "white",
              width: "70% !important",
              padding: "0.5rem !important",
              borderRadius: "10px !important",
            }}
            type="submit"
            disabled={disableCheckout}
          >
            {cost ? "Checkout" : "Register"}
          </Button>
          <Divider sx={{ my: 7, width: "100%", height: "2px" }}></Divider>
        </Box>
      </form>

      <Box
        width={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ p: 6, backgroundColor: "#f6f4ef" }}
      >
        <Box
          //width={0.8}
          display={"flex"}
          sx={{
            p: 4,
            backgroundColor: "#ffffff",
            boxShadow:
              "0 0.275rem 0.75rem -0.0625rem rgba(11,15,25,.06), 0 0.125rem 0.4rem -0.0625rem rgba(11,15,25,.03)",
          }}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            component="div"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Don’t see your child/athlete?
          </Typography>
          {/* <Box width={1} mt={5}>
            <Typography
              variant="h5"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontWeight: 700,
              }}
            >
              1. If your child/athlete is already registered with the platform:
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontWeight: 500,
                fontFamily: "Poppins"
              }}
            >
              <Link to={PLAYER_PARENT_SUPPORT_PAGE}>Contact us</Link> and we can help apply your child/athlete to your account.
            </Typography>
          </Box> */}
          <Box width={1} mt={4}>
            <Typography
              variant="h5"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontWeight: 700,
              }}
            >
              If your child/athlete is not already registered with the platform:
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontWeight: 500,
                fontFamily: "Poppins",
              }}
            >
              Click below to add them to your account.
            </Typography>
          </Box>
          <Typography
            variant="h5"
            sx={{
              width: 140,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer",
              fontWeight: 700,
              alignSelf: "baseline",
              mt: 3,
            }}
            onClick={() => {
              navigate(Manage_Athlete_Page);
            }}
          >
            Add Athlete
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="h6"
        sx={{
          mt: 5,
          fontSize: "14px",
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
          textAlign: "center",
          color: orgDetails?.primary_color,
        }}
        onClick={() => onBack("EVENT_INFO_PREVIEW")}
      >
        Back
      </Typography>
    </Box>
  );
};

export default EventRegisterAthleteSelection;
