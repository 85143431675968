import { Box, Button, Divider, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material"
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Manage_Athlete_Page, PLAYER_PARENT_SUPPORT_PAGE, Package_List } from "../../../routes/constants";
import { useDispatch, useSelector } from "react-redux";
import { SET_TOAST, checkEligibleForProduct } from "../../../store/actions";
import { useMutation } from "react-query";

const EventRegisterPaymentSelection = ({
  onBack,
  handleNext,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  selectedProduct,
  checkEligibleProduct,
  setSelectedProduct,
  productsList,
  eventRegistration,
  eventProducts,
}) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedProductInfo, setSelectedProductInfo] = useState("");
  const state = useSelector((state) => state);
  const orgDetails = state?.org?.org?.data;
  const userType = state?.user?.user?.user_type;

  let paymentMethodList = [
    { name: "CARD", label: "Pay with a Debit/Credit Card" },
  ]

  if (eventProducts?.length) {
    paymentMethodList.push({ name: "PRODUCT", label: "Pay with a Product" })
  }

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "200px",
      },
    },
  };
  const selectionInputStyle = { height: 40, width: "200px", };

  useEffect(() => {
    if (selectedProduct && productsList?.length) {
      let selected = productsList?.find((product) => product?.id === selectedProduct);
      setSelectedProductInfo(selected)
    }
  }, [selectedProduct]);

  const handleChangePaymentMethod = (e) => {
    let value = e?.target?.value;
    setSelectedPaymentMethod(value);
    if (value === "PRODUCT") {
      checkEligibleProduct();
    }
  }

  const onNext = (e) => {
    e?.preventDefault();
    if (selectedPaymentMethod) {
      if (selectedPaymentMethod === "PRODUCT") {
        eventRegistration();
      } else {
        handleNext("CC_PAYMENT_SELECTION_PREVIEW");
      }
    }
  }

  const navigatePurchaseProduct = (product) => {
    // const productIds = eventProducts?.map(x => x.id)
    navigate(Package_List, { state: { product: product } });
  }

  const renderProductEventsList = () => {
    return (
      <Box
        width={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ p: 3, backgroundColor: "#f6f4ef", my: 3 }}
      >
        <Box
          //width={0.8}
          display={"flex"}
          sx={{ p: 3, backgroundColor: "#ffffff", boxShadow: '0 0.275rem 0.75rem -0.0625rem rgba(11,15,25,.06), 0 0.125rem 0.4rem -0.0625rem rgba(11,15,25,.03)', }}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            component="div"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center"
            }}
          >
            Eligible Event Product List
          </Typography>
          <Box width={1} mt={2}>
            <Typography
              variant="h5"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontWeight: 700,
                mb: 2,
              }}
            >
              {/* These are the products that are selected by the administrator when creating this event. You have to purchase the listed product if you want to register for this event with the "Pay with a Product" option. If you have already purchased one of these products please ignore this message and proceed with the registration flow: */}
              The following packages are eligible to be used for this event. To purchase, click on the respective package and follow the steps to complete checkout.
            </Typography>
            {eventProducts?.map(product => (
              <Box width={1} mt={1}>
                <Typography
                  variant="p"
                  sx={{
                    color: theme?.palette?.dark?.darkTextSubHeader,
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigatePurchaseProduct(product)}
                >
                  * {product?.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={1}
      width={1}
      p={3}
    >
      {eventProducts?.length ?
        renderProductEventsList() :
        <Typography
          variant="p"
          sx={{
            color: theme?.palette?.dark?.darkTextSubHeader,
            fontWeight: 600,
            textAlign: "center",
            fontFamily: "Poppins",
            fontStyle: "italic",
            my: 3
          }}
        >
          The option to register in this event with the product is not available by the administrator, please use the "Pay with a Debit/Credit Card" option to proceed.
        </Typography>
      }
      <form onSubmit={onNext} style={{ width: "80%" }}>
        <Box
          width={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: 700,
              textAlign: "center"
            }}
          >
            How would you like to pay?
          </Typography>
          <Box
            my={3}
            width={1}
          >
            <CustomInputLabel>
              Select Payment Method
            </CustomInputLabel>
            <Select
              required={true}
              onChange={handleChangePaymentMethod}
              value={selectedPaymentMethod}
              input={
                <OutlinedInput
                  sx={selectionInputStyle}
                />
              }
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              renderValue={(value) => {
                if (value) {
                  let selected = paymentMethodList?.find((method) => method?.name === value);
                  if (selected) {
                    return selected?.label
                  }
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Choose option...
                    </Typography>
                  );
                }
              }}
              className="MuiSelect-select"
              sx={{
                "& .MuiSelect-select": {
                  padding: "10.5px 14px",
                  background: theme.palette.background.paper,
                },
                width: "100%"
              }}
            >
              {paymentMethodList?.map((method) => (
                <MenuItem key={method.name} value={method.name}>
                  {method?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* {selectedPaymentMethod === "PRODUCT" && !productsList?.length ?
            <Typography
              variant="h6"
              sx={{
                mt: 0.5,
                mb: 3,
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                color: orgDetails?.primary_color,
                alignSelf: "baseline",
                position: "relative",
                bottom: "20px"
              }}
              onClick={() => navigatePurchaseProduct()}
            >
              Purchase a Product Here
            </Typography>
            : null} */}
          {selectedPaymentMethod === "PRODUCT" && productsList?.length ?
            <Box width={1} mb={3}>
              <CustomInputLabel>
                Select Product
              </CustomInputLabel>
              <Select
                required={true}
                onChange={(e) => {
                  setSelectedProduct(e?.target?.value);
                }}
                value={selectedProduct}
                input={
                  <OutlinedInput
                    sx={selectionInputStyle}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{
                  "aria-label": "Without label",
                }}
                displayEmpty
                renderValue={(value) => {
                  if (value) {
                    let selected = productsList?.find((product) => product?.id === value);
                    if (selected) {
                      return selected?.title
                    }
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Choose product...
                      </Typography>
                    );
                  }
                }}
                className="MuiSelect-select"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10.5px 14px",
                    background: theme.palette.background.paper,
                  },
                  width: "100%"
                }}
              >
                {productsList?.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product?.title} - {product?.pack} remaining
                  </MenuItem>
                ))}
              </Select>
            </Box>
            : null}
          {selectedProduct && selectedPaymentMethod === "PRODUCT" ?
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                border: `2px solid ${orgDetails?.secondary_color}`,
                borderRadius: "4px",
                p: 4,
                width: 1,
                mb: 4,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 600,
                  textAlign: "center",
                  fontFamily: "Arial",
                }}
              >
                {selectedProductInfo?.title ?? "N/A"}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontWeight: 500,
                  textAlign: "center",
                  fontFamily: "Arial",
                }}
              >
                {selectedProductInfo?.desc ?? "N/A"}
              </Typography>
            </Box>
            : null}
          <Button
            // onClick={onNext}
            disabled={
              selectedPaymentMethod === "PRODUCT" ?
                productsList?.length ? false : true
                : false
            }
            sx={{
              background:
                selectedPaymentMethod === "PRODUCT" ?
                  productsList?.length ? `${theme?.palette?.inputsLabels?.green} !important` : `${theme?.palette?.grey["300"]} !important`
                  : `${theme?.palette?.inputsLabels?.green} !important`,
              color: "white",
              width: "70% !important",
              padding: "0.5rem !important",
              borderRadius: "10px !important",
            }}
            type="submit"
          >
            {selectedPaymentMethod ?
              selectedProduct && selectedPaymentMethod === "PRODUCT" ?
                "Complete Registration" : "Register" :
              "Checkout"
            }
          </Button>
        </Box>
      </form>
      <Typography
        variant="h6"
        sx={{
          mt: 5,
          fontSize: "14px",
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
          textAlign: "center",
          color: orgDetails?.primary_color,
        }}
        onClick={() =>
          userType === "player"
            ? onBack("EVENT_INFO_PREVIEW")
            : onBack("ATHLETE_INFO_SELECTION")
        }
      >
        Back
      </Typography>
    </Box>
  )
}

export default EventRegisterPaymentSelection;
