import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  ListItemText,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
  FormControl,
  InputAdornment,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  FormControlLabel,
} from "@mui/material";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useEffect, useState } from "react";
import { SET_TOAST, updateUserDetails, updateUserWallet } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import CustomTextField from "../CustomTextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Apply_Scholarship_Program,
  USERS_PAGE,
  USER_WALLET_HISTORY_PAGE,
  USER_PACK_PURCHASE_HISTORY_PAGE,
} from "../../routes/constants";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSwitch from "../Common/Inputs/Switch";
import { convertToCapsOfFirstLetter } from "../../utils/functions";
import SuccessPopup from "../Common/SuccessPopup";
import { SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { heIL } from "@mui/x-data-grid";
import AddSketchplayBucksConfirmModal from "./AddSketchplayBucksConfirmModal";

const ViewUserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const [relationshipList, setRelationshipList] = useState([]);
  const [inactiveUser, setInactiveUser] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSketchplayBucksConfirmModal, setShowSketchplayBucksConfirmModal] =
    useState(false);
  const selectedDataGridRow = location?.state?.selectedUserData;
  const [isEventPermission, setIsEventPermission] = useState(
    selectedDataGridRow?.event_permission,
  );
  const [walletType, setWalletType] = useState("");

  useEffect(() => {
    if (selectedDataGridRow) {
      const mainParent = {
        id: selectedDataGridRow?.id,
        first_name: selectedDataGridRow?.first_name ?? "",
        last_name: selectedDataGridRow?.last_name ?? "",
        email: selectedDataGridRow?.email ?? "",
        mobile: selectedDataGridRow?.mobile ?? "",
        countryCode: selectedDataGridRow?.countryCode ?? "",
        user_type: selectedDataGridRow?.user_type ?? "",
      };
      const relationArr = [
        mainParent,
        ...selectedDataGridRow?.parent,
        ...selectedDataGridRow?.athlete,
      ];
      setRelationshipList(relationArr);
    }
  }, [selectedDataGridRow]);

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedDataGridRow?.id,
      firstName: selectedDataGridRow?.first_name ?? "",
      lastName: selectedDataGridRow?.last_name ?? "",
      email: selectedDataGridRow?.email ?? "",
      mobile: selectedDataGridRow?.mobile ?? "",
      countryCode: selectedDataGridRow?.countryCode ?? "",
      userType: selectedDataGridRow?.user_type ?? "",
      voulenteerHours: 0,
      foundationCredits: 0,
      scholarshipDiscount: selectedDataGridRow?.scholarship?.length
        ? selectedDataGridRow?.scholarship[0].discount + "%"
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParam = {
        user_id: values?.id,
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        email: values?.email ?? "",
        mobile: values?.mobile ?? "",
        countryCode: values?.countryCode ?? "",
        event_permission: isEventPermission,
      };
      updateUserDetail(bodyParam);
    },
  });

  const updateUserDetail = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await updateUserDetails(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response.message ?? "User details updated scuccessfully.",
        },
      });
      navigate(USERS_PAGE);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const updateWallet = async (reason) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };

    let bodyParams = {
      user_id: selectedDataGridRow?.id,
      type: walletType,
      description: reason,
    };
    const volunteerHour = formik.values.voulenteerHours || 0;
    const foundationCredit = formik.values.foundationCredits || 0;
    if (walletType === "volunteer_hours") {
      if (volunteerHour > 0) {
        bodyParams.debit_type = "add";
        bodyParams.value = Number(volunteerHour.toFixed(2));
      } else {
        bodyParams.debit_type = "subtract";
        const replaceVal = Number(volunteerHour.toString().replace("-", ""));
        bodyParams.value = Number(parseFloat(replaceVal).toFixed(2));
      }
    } else if (walletType === "foundation") {
      if (foundationCredit > 0) {
        bodyParams.debit_type = "add";
        bodyParams.value = Number(foundationCredit.toFixed(2));
      } else {
        bodyParams.debit_type = "subtract";
        const replaceVal = foundationCredit.toString().replace("-", "");
        bodyParams.value = Number(parseFloat(replaceVal).toFixed(2));
      }
    }
    let response = await updateUserWallet(dispatch, params, bodyParams);
    handleSketchplayBucksConfirmModalClose();
    if (response?.ok) {
      selectedDataGridRow.volunteer_hours = response?.data.volunteer_hours;
      selectedDataGridRow.foundation_credits =
        response?.data.foundation_credits;
      selectedDataGridRow.sketchplay_bucks = response?.data.sketchplay_bucks;
      formik.setFieldValue("voulenteerHours", 0);
      formik.setFieldValue("foundationCredits", 0);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response.message ?? "Wallet updated scuccessfully.",
        },
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const bindUserDataClick = (userData) => {
    formik.setFieldValue("id", userData?.id);
    formik.setFieldValue("firstName", userData?.first_name);
    formik.setFieldValue("lastName", userData?.last_name);
    formik.setFieldValue("email", userData?.email);
    formik.setFieldValue("mobile", userData?.mobile);
    formik.setFieldValue("countryCode", userData?.countryCode);
    formik.setFieldValue("userType", userData?.user_type);
  };

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const handleSketchplayBucksConfirmModalClose = () => {
    setShowSketchplayBucksConfirmModal(false);
  };

  const handleUpdateVolunteerHour = (reason) => {
    updateWallet(reason);
  };

  const showZeroError = (type) => {
    const volunteerHour = formik.values.voulenteerHours || 0;
    const foundationCredit = formik.values.foundationCredits || 0;
    if (volunteerHour === 0 && type === "volunteer_hours") {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Volunteer Hours should be less than or greater than zero.",
        },
      });
      return false;
    }
    if (foundationCredit === 0 && type === "foundation") {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            "Foundation Credit should be less than or greater than zero.",
        },
      });
      return false;
    }
    return true;
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
        <Box
          sx={{
            width: "95%",
            position: "relative",
            backgroundColor: theme?.palette?.background?.paper,
            boxShadow: theme?.palette?.boxShadow?.tableContainer,
            borderRadius: 1,
            border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
          }}
          mt={4}
        >
          <Box sx={{}}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                    alignItems: "start",
                  },
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={700}
                  fontSize={20}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{
                    p: 1,
                    [theme.breakpoints.down("md")]: {
                      p: 0,
                      mb: 1,
                    },
                  }}
                >
                  {selectedDataGridRow?.first_name +
                    " " +
                    selectedDataGridRow?.last_name || "User Details"}
                </Typography>
                <Box position="relative" sx={{ cursor: "pointer" }}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={inactiveUser}
                        onChange={(e) => setInactiveUser(e?.target?.checked)}
                        size="sm"
                        sx={{ m: 1 }}
                      />
                    }
                    label="Remove authentication access"
                    labelPlacement="start"
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        ml: 0,
                      },
                      "& .MuiTypography-root": {
                        color: theme?.palette?.text?.containerTextLabel,
                        fontWeight: 400,
                        fontFamily: "inherit",
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box
                width={1}
                height={"1px"}
                bgcolor={"gray"}
                sx={{ opacity: 0.25 }}
              ></Box>
              <Grid
                container
                spacing={3}
                sx={{ p: 4, [theme.breakpoints.down("md")]: { p: 3 } }}
              >
                <Grid item md={7} xs={12}>
                  <Box display="flex">
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          fontSize={20}
                          color={theme?.palette?.text?.containerTextLabel}
                        >
                          User Information
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomInputLabel
                          htmlFor="first-name"
                          sx={inputLabelStyle}
                        >
                          First Name
                        </CustomInputLabel>
                        <CustomTextField
                          id="first-name"
                          name="firstName"
                          placeholder="Enter first name..."
                          variant="outlined"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(e, formik, "firstName")
                          }
                          value={formik.values.firstName}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              border: `1px solid ${theme?.palette?.grey?.[400]}`,
                              //width: 350,
                              boxShadow: "none",
                            },
                          }}
                        />
                        {Boolean(
                          formik.touched.firstName && formik.errors.firstName,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.firstName}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomInputLabel
                          htmlFor="last-name"
                          sx={inputLabelStyle}
                        >
                          Last Name
                        </CustomInputLabel>
                        <CustomTextField
                          id="last-name"
                          name="lastName"
                          placeholder="Enter last name..."
                          variant="outlined"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            convertToCapsOfFirstLetter(e, formik, "lastName")
                          }
                          value={formik.values.lastName}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              border: `1px solid ${theme?.palette?.grey?.[400]}`,
                              boxShadow: "none",
                            },
                          }}
                        />
                        {Boolean(
                          formik.touched.lastName && formik.errors.lastName,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.lastName}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <CustomInputLabel htmlFor="email" sx={inputLabelStyle}>
                          Email Address
                        </CustomInputLabel>
                        <CustomTextField
                          id="email"
                          name="email"
                          placeholder="Enter email..."
                          variant="outlined"
                          size="small"
                          type="email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email.toLowerCase()}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              border: `1px solid ${theme?.palette?.grey?.[400]}`,
                              boxShadow: "none",
                            },
                          }}
                          disabled={true}
                        />
                        {Boolean(
                          formik.touched.email && formik.errors.email,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.email}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomInputLabel
                          htmlFor="mobile-number"
                          sx={inputLabelStyle}
                        >
                          Mobile Number
                        </CustomInputLabel>
                        <Box
                          sx={{
                            "& .react-tel-input .form-control": {
                              width: "100%",
                              // minWidth: "100%",
                              fontWeight: 500,
                              background: theme?.palette?.grey[50],
                              height: "42px",
                            },
                          }}
                        >
                          <PhoneInput
                            country={"us"}
                            id="mobile"
                            name="mobile"
                            variant="outlined"
                            placeholder="(702) 123-4567"
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            onChange={(value, country) => {
                              formik.setFieldValue(
                                "countryCode",
                                country.dialCode || "",
                              );
                              formik.setFieldValue("mobile", value || "");
                            }}
                            error={Boolean(
                              formik.touched.mobile && formik.errors.mobile,
                            )}
                            size="sm"
                            style={{
                              width: "100%",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-input": {
                                padding: "12px 14px",
                              },
                            }}
                            disabled={true}
                            disableDropdown={true}
                            disableCountryCode={true}
                            onlyCountries={["us"]}
                          />
                          {Boolean(
                            formik.touched.mobile && formik.errors.mobile,
                          ) && (
                            <FormHelperText error>
                              {formik.errors.mobile}
                            </FormHelperText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CustomInputLabel
                          htmlFor="user-type"
                          sx={inputLabelStyle}
                        >
                          Primary User Type
                        </CustomInputLabel>
                        <CustomTextField
                          id="user-type"
                          name="userType"
                          placeholder="Enter user type..."
                          variant="outlined"
                          size="small"
                          value={formik.values.userType}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              border: `1px solid ${theme?.palette?.grey?.[400]}`,
                              boxShadow: "none",
                            },
                          }}
                          disabled={true}
                        />
                        {Boolean(
                          formik.touched.userType && formik.errors.userType,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.userType}
                          </FormHelperText>
                        )}
                      </Grid>
                      {formik?.values?.userType !== "player" && (
                        <Grid item md={6} xs={12} sx={{ mt: 3 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  color: "#00B60D",
                                  "&.Mui-checked": {
                                    color: "#00B60D",
                                  },
                                }}
                                checked={isEventPermission}
                                onChange={(e) => {
                                  setIsEventPermission(e?.target?.checked);
                                }}
                              />
                            }
                            label="Event Registration Permission."
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={0.5} xs={12}>
                  <Box
                    sx={{
                      width: "1px",
                      background: "gray",
                      opacity: 0.3,
                      height: "100%",
                      margin: "auto",
                      [theme.breakpoints.down("md")]: {
                        height: "1px",
                        width: "100%",
                      },
                    }}
                  ></Box>
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <Box display="flex">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          fontSize={20}
                          color={theme?.palette?.text?.containerTextLabel}
                          //sx={{ pl: 3, pb: 2.5, pt: 1.5 }}
                        >
                          Relationships
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {relationshipList.length > 0 && (
                          <>
                            <List
                              dense
                              sx={{
                                width: "100%",
                                border: "solid 0.1px #0000001f",
                                paddingBottom: "0px",
                              }}
                            >
                              {relationshipList.map((item) => {
                                //const labelId = `checkbox-list-secondary-label-${value}`;
                                return (
                                  <>
                                    <ListItem
                                      key={item.id}
                                      // secondaryAction={
                                      //     <AddCircleIcon
                                      //         edge="end"
                                      //     />
                                      // }
                                      disablePadding
                                      //  sx={{ px: 3 }}
                                    >
                                      <ListItemButton
                                        onClick={() => bindUserDataClick(item)}
                                      >
                                        <Avatar
                                          sx={{
                                            bgcolor:
                                              item.user_type === "player"
                                                ? "#319FA8"
                                                : "#1E2549",
                                            color: "primary.contrastText",
                                            marginRight: 1,
                                            width: 20,
                                            height: 20,
                                            fontSize: 10,
                                          }}
                                        >
                                          {item.user_type === "player" ||
                                          item.user_type === "admin"
                                            ? "A"
                                            : item.user_type === "parent"
                                            ? "P"
                                            : item.user_type === "coach"
                                            ? "C"
                                            : item.user_type === "teamcoach" ||
                                              item.user_type ===
                                                "teammanager" ||
                                              item.user_type ===
                                                "tournamentmanager"
                                            ? "T"
                                            : "P"}
                                        </Avatar>
                                        <ListItemText id={item.id}>
                                          <Typography
                                            variant="body1"
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {`${item.first_name} ${item.last_name}`}
                                          </Typography>
                                        </ListItemText>
                                      </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                  </>
                                );
                              })}
                            </List>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              {(selectedDataGridRow?.user_type === "parent" ||
                selectedDataGridRow?.user_type === "athlete") && (
                <>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      my: 2,
                      px: 4,
                      [theme.breakpoints.down("md")]: { px: 3 },
                    }}
                  >
                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      my: 2,
                      px: 4,
                      [theme.breakpoints.down("md")]: { px: 3 },
                    }}
                  >
                    {selectedDataGridRow?.scholarship?.length ? (
                      <>
                        {selectedDataGridRow?.scholarship[0].status ===
                        "Approved" ? (
                          <Grid item lg={6} md={12} xs={12}>
                            <Box display="flex">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h4"
                                    fontWeight={700}
                                    fontSize={20}
                                    color={
                                      theme?.palette?.text?.containerTextLabel
                                    }
                                    //sx={{ pl: 3, pb: 2.5, pt: 1.5 }}
                                  >
                                    Household Discounts & Credits
                                  </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12}>
                                  <CustomInputLabel htmlFor="scholarshipDiscount">
                                    Scholarship Discount
                                  </CustomInputLabel>
                                  <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                      <CustomTextField
                                        id="scholarshipDiscount"
                                        name="scholarshipDiscount"
                                        placeholder="Enter scholarship discount..."
                                        variant="outlined"
                                        size="small"
                                        value={
                                          formik.values.scholarshipDiscount
                                        }
                                        sx={{
                                          width: "100%",
                                          "& .MuiOutlinedInput-root": {
                                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                            //width: 150,
                                            boxShadow: "none",
                                          },
                                        }}
                                        disabled={true}
                                      />
                                      {Boolean(
                                        formik.touched.scholarshipDiscount &&
                                          formik.errors.scholarshipDiscount,
                                      ) && (
                                        <FormHelperText error>
                                          {formik.errors.scholarshipDiscount}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          paddingX: 3.5,
                                          paddingY: 1.2,
                                          width: "100%",
                                          ml: 1,
                                          fontWeight: 700,
                                        }}
                                        color="inherit"
                                        onClick={() => {
                                          navigate(Apply_Scholarship_Program, {
                                            state: {
                                              scholarshipData:
                                                selectedDataGridRow
                                                  ?.scholarship[0],
                                              isNewEntry: false,
                                              type: "userDetailPage",
                                              userData: selectedDataGridRow,
                                            },
                                          });
                                        }}
                                      >
                                        View Application
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ) : null}
                      </>
                    ) : null}
                    <Grid item lg={6} md={12} xs={12}>
                      <Box display="flex">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              fontWeight={700}
                              fontSize={20}
                              color={theme?.palette?.text?.containerTextLabel}
                              //sx={{ pl: 3, pb: 2.5, pt: 1.5 }}
                            >
                              Household Purchase History
                            </Typography>
                          </Grid>
                          <Grid lg={12} md={12} xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={8} sx={{ ml: 4, mt: 5 }}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  sx={{
                                    paddingX: 1,
                                    paddingY: 1.2,
                                    width: "100%",
                                    fontWeight: 700,
                                  }}
                                  //color='inherit'
                                  onClick={() => {
                                    navigate(USER_PACK_PURCHASE_HISTORY_PAGE, {
                                      state: {
                                        userData: selectedDataGridRow,
                                      },
                                    });
                                  }}
                                >
                                  View Purchase History
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h4"
                              fontWeight={700}
                              fontSize={20}
                              color={theme?.palette?.text?.containerTextLabel}
                              //sx={{ pl: 3, pb: 2.5, pt: 1.5 }}
                            >
                              Household Sketchplay Bucks
                            </Typography>
                          </Grid>
                          <Grid item lg={6} md={12} xs={12}>
                            <CustomInputLabel
                              htmlFor="voulenteerHours"
                              sx={inputLabelStyle}
                            >
                              Volunteer hours :{" "}
                              {selectedDataGridRow?.volunteer_hours || 0}
                            </CustomInputLabel>
                            <Grid container spacing={1}>
                              <Grid item xs={8}>
                                <CustomTextField
                                  id="voulenteerHours"
                                  name="voulenteerHours"
                                  type="number"
                                  placeholder="Enter volunteer hours..."
                                  variant="outlined"
                                  size="small"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.voulenteerHours}
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                      width: "100%",
                                      boxShadow: "none",
                                    },
                                  }}
                                />
                                {Boolean(
                                  formik.touched.voulenteerHours &&
                                    formik.errors.voulenteerHours,
                                ) && (
                                  <FormHelperText error>
                                    {formik.errors.voulenteerHours}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  sx={{
                                    paddingX: 1,
                                    paddingY: 1.25,
                                    width: "100%",
                                    fontWeight: 700,
                                  }}
                                  color="inherit"
                                  onClick={() => {
                                    if (showZeroError("volunteer_hours")) {
                                      setWalletType("volunteer_hours");
                                      setShowSketchplayBucksConfirmModal(true);
                                    }
                                  }}
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sx={{ paddingTop: "1px !important" }}
                          >
                            <Typography
                              variant="span"
                              //fontWeight={700}
                              fontSize={11}
                              color={theme?.palette?.text?.containerTextLabel}
                            >
                              1 HR=
                              {"$" +
                                (state?.org?.org?.data
                                  ?.volunteer_hourly_price ?? 0)}
                            </Typography>
                          </Grid>
                          <Grid item lg={6} md={12} xs={12}>
                            <CustomInputLabel
                              htmlFor="foundationCredits"
                              sx={inputLabelStyle}
                            >
                              Foundation Credits :{" "}
                              {selectedDataGridRow?.foundation_credits || 0}
                            </CustomInputLabel>
                            <Grid container spacing={1}>
                              <Grid item xs={8}>
                                <CustomTextField
                                  id="foundationCredits"
                                  name="foundationCredits"
                                  type="number"
                                  placeholder="Enter foundation credits..."
                                  variant="outlined"
                                  size="small"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.foundationCredits}
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                      width: "100%",
                                      boxShadow: "none",
                                    },
                                  }}
                                />
                                {Boolean(
                                  formik.touched.foundationCredits &&
                                    formik.errors.foundationCredits,
                                ) && (
                                  <FormHelperText error>
                                    {formik.errors.foundationCredits}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  sx={{
                                    paddingX: 1,
                                    paddingY: 1.25,
                                    width: "100%",
                                    fontWeight: 700,
                                  }}
                                  color="inherit"
                                  onClick={() => {
                                    if (showZeroError("foundation")) {
                                      setWalletType("foundation");
                                      setShowSketchplayBucksConfirmModal(true);
                                    }
                                  }}
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sx={{ paddingTop: "1px !important" }}
                          >
                            <Typography
                              variant="span"
                              //fontWeight={700}
                              fontSize={11}
                              color={theme?.palette?.text?.containerTextLabel}
                            >
                              1 CR=
                              {"$" +
                                (state?.org?.org?.data
                                  ?.foundation_hourly_price ?? 0)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body1"
                                fontSize={14}
                                color={theme?.palette?.text?.containerTextLabel}
                              >
                                SKETCHPLAY BUCKS BALANCE:
                              </Typography>
                              <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={14}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{ ml: 1 }}
                              >
                                {"$" +
                                  selectedDataGridRow?.sketchplay_bucks.toFixed(
                                    2,
                                  ) || "$0.00"}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{ mt: 1 }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                paddingX: 3.5,
                                paddingY: 1.2,
                                width: "100%",
                                fontWeight: 700,
                              }}
                              //color='inherit'
                              onClick={() => {
                                navigate(USER_WALLET_HISTORY_PAGE, {
                                  state: {
                                    userData: selectedDataGridRow,
                                  },
                                });
                              }}
                            >
                              View History
                            </Button>
                          </Grid>
                          {/* <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: 1 }}>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            sx={{ paddingX: 3.5, paddingY: 1.2, width: "100%", fontWeight: 700 }}
                                                            //color='inherit'
                                                            onClick={() => {
                                                                navigate(USER_PACK_PURCHASE_HISTORY_PAGE,
                                                                    {
                                                                        state: {
                                                                            userData: selectedDataGridRow
                                                                        }
                                                                    });
                                                            }}
                                                        >
                                                            View Purchase History
                                                        </Button>
                                                    </Grid> */}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                container
                spacing={2}
                sx={{
                  mt: 7,
                  mb: 4,
                  px: 4,
                  [theme.breakpoints.down("md")]: {
                    mt: 4,
                    mb: 3,
                    px: 3,
                  },
                }}
              >
                <Grid item xs={6}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                    color="inherit"
                    onClick={() => {
                      navigate(USERS_PAGE);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>

      {showSuccessPopup && (
        <SuccessPopup
          open={true}
          handleClose={() => {
            setShowSuccessPopup(false);
            navigate(-1);
          }}
          title="User Details"
          secondaryTitle="Success! User details updated successfully."
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to hide this popup"
          alignCenterSecondaryTitle={true}
        />
      )}
      {showSketchplayBucksConfirmModal && (
        <AddSketchplayBucksConfirmModal
          open={showSketchplayBucksConfirmModal}
          handleClose={handleSketchplayBucksConfirmModalClose}
          handleUpdateVolunteerHour={handleUpdateVolunteerHour}
        />
      )}
    </>
  );
};
export default ViewUserDetails;
