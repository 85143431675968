import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomTextField from "../../components/CustomTextField";
import PhoneInput from "react-phone-input-2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { UploadFileRounded } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { charValidate } from "../../utils/functions";
import {
  SET_TOAST,
  addScholarshipApplyAction,
  approveScholarshipRequestAction,
  checkAthleteUsersForScholarshipApplyAction,
  checkParentUserForScholarshipApplyAction,
  getMe,
  getScholarshipDiscountsAction,
  uploadDocumentAction,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { USER_DETAILS_PAGE } from "../../routes/constants";
import { useLocation, useNavigate } from "react-router";
import SuccessPopup from "../../components/Common/SuccessPopup";
import { convertToCapsOfFirstLetter } from "@utils/functions";
import SchoolIcon from "@mui/icons-material/School";
import { relativeTimeThreshold } from "moment/moment";
import EditScholarshipDiscount from "../../components/Scholarship/EditScholarshipDiscount";

const AdminScholarshipProgramApply = () => {
  let getRedirectFlag = localStorage.getItem("redirectScholarship");
  useEffect(() => {
    if (getRedirectFlag === "true") {
      localStorage.setItem("redirectScholarship", false);
    }
  }, []);

  const theme = useTheme();
  const state = useSelector((state) => state);
  const incomeChartURL = state?.org?.org?.data?.income_chart ?? "";
  const answerArray = [
    { id: "yes", value: "YES" },
    { id: "no", value: "NO" },
  ];
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewEnterParam = new URLSearchParams(location.search).get(
    "isnewentry",
  );
  //const isNewEntry = state?.user?.user?.user_type ==="teammanager" ? true :location?.state?.isNewEntry;
  const isNewEntry = location?.state?.isNewEntry ?? isNewEnterParam;
  const navigationData = location?.state?.scholarshipData;
  const userType = state?.user?.user?.user_type;
  const pageType = location?.state?.type;
  const backUserDetails = location?.state?.userData;

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const [athleteFields, setAthleteFields] = useState([
    {
      id: 1,
      athleteFirstName: "",
      athleteLastName: "",
      athleteEmail: "",
      athletePhone: "",
      athletePhoneCountryCode: "",
    },
  ]);
  const [documentsName, setDocumentsName] = useState({
    previousYearTaxStatement: "",
    lastTwoPayStubs: "",
    pay_stub_second: "",
    socialSecurityCheck: "",
    governmentIssuedID: "",
  });

  const [programApplyingForList, setProgramApplyingFor] = useState([
    {
      id: "income_based",
      value: "Income Based",
    },
    {
      id: "public_sector",
      value: "Public Sector/Active Military",
    },
    {
      id: "athlete_landing",
      value: "Athletes Lending a Helping Hand",
    },
  ]);
  const [selectedProgram, setSelectedProgram] = useState([]);

  const [showIncomeBasePopup, setShowIncomeBasePopup] = useState(false);
  const [discountsList, setDiscountsList] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);

  let condition =
    state?.user?.user?.scholarship &&
    state?.user?.user?.scholarship?.length > 0 &&
    !isNewEntry;
  const scholarshipData = condition
    ? state?.user?.user?.scholarship[0]
    : navigationData;
  const [isPersonalCorporateDonor, setIsPersonalCorporateDonor] = useState(
    scholarshipData?.donor || false,
  );
  const [inputFields, setInputFields] = useState(
    scholarshipData
      ? scholarshipData?.volunteered_hours_json?.length > 0
        ? [...scholarshipData?.volunteered_hours_json]
        : [
            {
              idObj: "name-id-0",
              event_name: scholarshipData?.event,
              volunteered_hours: scholarshipData?.volunteered_hours,
            },
          ]
      : [{ idObj: "name-id-0", event_name: "", volunteered_hours: "" }],
  );
  const scholarshipDetails = state?.user?.user?.scholarship ?? [];

  const numericInputStyle = {
    padding: "1px",
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.grey?.[400]}`,
    width: "100%",
    boxShadow: "none",
    outline: "none",
    height: "44px",
    background: "#fafafa",
    padding: "10px 14px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validationSchema = Yup.object({
    parentFirstName: Yup.string().trim().required("First Name is required"),
    parentLastName: Yup.string().trim().required("Last Name is required"),
    parentEmail: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    parentPhone: Yup.string().min(10).required("Mobile number is required"),
    programApplyingFor: Yup.string(),
    householdIncome: Yup.string()
      .trim()
      .required(
        "Household income is required. Enter 0 if not applying for income based support.",
      ),
    household_size: Yup.string().trim().required("Household size is required"),
    // discount: Yup.number().required("Discount selection is required in case of approve the request"),
  });

  useEffect(() => {
    if (scholarshipData) {
      let previousTaxDocName = scholarshipData?.previous_tax
        ? scholarshipData?.previous_tax.split("/").pop().split("?")[0]
        : "";
      let payStubDocName = scholarshipData?.pay_stub
        ? scholarshipData?.pay_stub.split("/").pop().split("?")[0]
        : "";
      let payStubSecondDocName = scholarshipData?.pay_stub_second
        ? scholarshipData?.pay_stub_second.split("/").pop().split("?")[0]
        : "";
      let socialSecurityDocName = scholarshipData?.social_security
        ? scholarshipData?.social_security.split("/").pop().split("?")[0]
        : "";
      let govtIdDocName = scholarshipData?.govt_id
        ? scholarshipData?.govt_id.split("/").pop().split("?")[0]
        : "";

      setDocumentsName({
        previousYearTaxStatement: previousTaxDocName ?? "",
        lastTwoPayStubs: payStubDocName ?? "",
        pay_stub_second: payStubSecondDocName ?? "",
        socialSecurityCheck: socialSecurityDocName ?? "",
        governmentIssuedID: govtIdDocName ?? "",
      });

      let athletes = [];

      if (scholarshipData?.athletes && scholarshipData?.athletes?.length) {
        scholarshipData?.athletes?.forEach((athlete) => {
          athletes.push({
            id: athlete?.id,
            athleteFirstName: athlete?.first_name,
            athleteLastName: athlete?.last_name,
            athleteEmail: athlete?.email,
            athletePhone: athlete?.mobile,
            athletePhoneCountryCode: athlete?.countryCode
              ? athlete?.countryCode?.toLowerCase()
              : "us",
          });
        });
        if (athletes && athletes?.length) {
          setAthleteFields(athletes);
        }
      }

      if (scholarshipData?.discount) {
        formik.setFieldValue("discount", scholarshipData?.discount);
      }

      if (scholarshipData?.program) {
        setSelectedProgram(scholarshipData?.program.split(","));
      }
    }
  }, [scholarshipData]);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token && userType === "admin") {
      getAllScholarshipDiscounts();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  // useEffect(() => {
  //   dispatch(getMe());
  // }, []);

  const getAllScholarshipDiscounts = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getScholarshipDiscountsAction(params, dispatch);
    if (response?.ok) {
      setDiscountsList(response?.data ?? []);
    }
  };

  const formik = useFormik({
    initialValues: {
      parentFirstName: scholarshipData?.parent?.first_name ?? "",
      parentLastName: scholarshipData?.parent?.last_name ?? "",
      parentEmail: scholarshipData?.parent?.email ?? "",
      parentPhone: scholarshipData?.parent?.mobile ?? "",
      parentCountryCode: scholarshipData?.parent?.countryCode
        ? scholarshipData?.parent?.countryCode?.toLowerCase()
        : "us",
      previousYearTaxStatement: scholarshipData?.previous_tax ?? "",
      lastTwoPayStubs: scholarshipData?.pay_stub ?? "",
      pay_stub_second: scholarshipData?.pay_stub_second ?? "",
      socialSecurityCheck: scholarshipData?.social_security ?? "",
      governmentIssuedID: scholarshipData?.govt_id ?? "",
      householdIncome: scholarshipData?.household_income ?? "",
      household_size: scholarshipData?.household_size ?? "",
      programApplyingFor: scholarshipData?.program ?? "",
      discount: scholarshipData?.discount ?? "",
      company_name: scholarshipData?.company_name ?? "",
      veteran: scholarshipData?.veteran ?? "",
      branch: scholarshipData?.branch ?? "",
      service_active: scholarshipData?.service_active ?? "",
      event: scholarshipData?.event ?? "",
      volunteered_hours: scholarshipData?.volunteered_hours ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (
        !athleteFields[0]?.athleteFirstName ||
        !athleteFields[0]?.athleteLastName ||
        !athleteFields[0]?.athleteEmail ||
        !athleteFields[0]?.athletePhone
      ) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: "Fill the athlete details before submit",
          },
        });
        return;
      }

      let isIncomeZero = true;
      if (values?.householdIncome === "$0") {
        isIncomeZero = false;
      }

      if (isIncomeZero) {
        if (
          !values?.previousYearTaxStatement &&
          !values?.lastTwoPayStubs &&
          !values?.socialSecurityCheck &&
          !values?.governmentIssuedID
        ) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: "Please upload required documents before submitting",
            },
          });
          return;
        }

        if (
          values?.previousYearTaxStatement &&
          (!values?.lastTwoPayStubs || !values?.pay_stub_second)
        ) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message:
                "Upload documents with the combination or upload all before submitting",
            },
          });
          return;
        }

        if (
          !values?.previousYearTaxStatement &&
          values?.lastTwoPayStubs &&
          values?.pay_stub_second
        ) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message:
                "Upload documents with the combination or upload all before submitting",
            },
          });
          return;
        }

        if (values?.socialSecurityCheck && !values?.governmentIssuedID) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message:
                "Upload documents with the combination or upload all before submitting",
            },
          });
          return;
        }

        if (!values?.socialSecurityCheck && values?.governmentIssuedID) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message:
                "Upload documents with the combination or upload all before submitting",
            },
          });
          return;
        }
      }

      // if (selectedProgram.includes("public_sector")) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: {
      //       type: "error",
      //       message: "Public sector all fields are mandatory.",
      //     },
      //   });
      //   return;
      // }

      // if (selectedProgram.includes("athlete_landing")) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: {
      //       type: "error",
      //       message: "Athlete lending all fields are mandatory.",
      //     },
      //   });
      //   return;
      // }

      values.donor = isPersonalCorporateDonor;
      let parentResponse = await checkParentUser(values);
      let athleteResponse;
      if (parentResponse) {
        if (parentResponse?.ok) {
          athleteResponse = await checkAthleteUser(parentResponse);
        }
      }
      if (parentResponse && athleteResponse) {
        if (parentResponse?.ok && athleteResponse?.ok) {
          submitForm(values, parentResponse, athleteResponse);
        }
      }
    },
  });

  const discountValue = formik.values.discount;

  const checkParentUser = async (values) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let paymentData = {
      parent_first_name: values?.parentFirstName ?? "",
      parent_last_name: values?.parentLastName ?? "",
      parent_mobile: values?.parentPhone ?? "",
      parent_countryCode: values?.parentCountryCode
        ? values?.parentCountryCode?.toUpperCase()
        : "US",
      parent_email: values?.parentEmail ?? "",
    };
    let response = await checkParentUserForScholarshipApplyAction(
      dispatch,
      params,
      paymentData,
    );
    try {
      if (response?.ok) {
        // dispatch({
        //   type: SET_TOAST,
        //   data: {
        //     type: "success",
        //     message: response?.message ?? "Success!",
        //   },
        // });
        return response;
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong! Try again",
          },
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong! Try again",
        },
      });
      return null;
    }
  };

  const checkAthleteUser = async (parentResponse) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let paymentData = {
      parent_id: parentResponse?.data ?? "",
      athlete_info: [],
    };
    athleteFields?.forEach((athlete) => {
      paymentData?.athlete_info?.push({
        first_name: athlete?.athleteFirstName ?? "",
        last_name: athlete?.athleteLastName ?? "",
        mobile: athlete?.athletePhone ?? "",
        countryCode: athlete?.athletePhoneCountryCode
          ? athlete?.athletePhoneCountryCode?.toUpperCase()
          : "US",
        email: athlete?.athleteEmail ?? "",
      });
    });
    let response = await checkAthleteUsersForScholarshipApplyAction(
      dispatch,
      params,
      paymentData,
    );
    try {
      if (response?.ok) {
        // dispatch({
        //   type: SET_TOAST,
        //   data: {
        //     type: "success",
        //     message: response?.message ?? "Success!",
        //   },
        // });
        return response;
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong! Try again",
          },
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong! Try again",
        },
      });
      return null;
    }
  };

  const submitForm = async (values, parentResponse, athleteResponse) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let paymentData = {
      id: scholarshipData ? scholarshipData?.id : undefined,
      parent_first_name: values?.parentFirstName ?? "",
      parent_last_name: values?.parentLastName ?? "",
      parent_mobile: values?.parentPhone ?? "",
      parent_countryCode: values?.parentCountryCode
        ? values?.parentCountryCode?.toUpperCase()
        : "US",
      parent_email: values?.parentEmail ?? "",
      program: values?.programApplyingFor ?? "",
      govt_id: values?.governmentIssuedID ?? "",
      parent: parentResponse?.data ?? "",
      social_security: values?.socialSecurityCheck ?? "",
      previous_tax: values?.previousYearTaxStatement ?? "",
      pay_stub: values?.lastTwoPayStubs,
      pay_stub_second: values?.pay_stub_second,
      household_income: values?.householdIncome
        ? typeof values?.householdIncome === "number"
          ? values?.householdIncome
          : parseFloat(values?.householdIncome.replace(/[$,]/g, ""))
        : "",
      athletes: athleteResponse?.data ?? [],
      athlete_info: [],
      household_size: values?.household_size,
      company_name: values?.company_name,
      veteran: values?.veteran,
      branch: values?.branch,
      service_active: values?.service_active,
      event: "",
      volunteered_hours: 0,
      donor: values?.donor,
      volunteered_hours_json: [],
    };
    athleteFields?.forEach((athlete) => {
      paymentData?.athlete_info?.push({
        first_name: athlete?.athleteFirstName ?? "",
        last_name: athlete?.athleteLastName ?? "",
        mobile: athlete?.athletePhone ?? "",
        countryCode: athlete?.athletePhoneCountryCode
          ? athlete?.athletePhoneCountryCode?.toUpperCase()
          : "US",
        email: athlete?.athleteEmail ?? "",
      });
    });
    const fieldFilterArr = inputFields.filter(
      (x) => x.event_name !== "" && x.volunteered_hours !== "",
    );

    fieldFilterArr?.forEach((event) => {
      paymentData?.volunteered_hours_json?.push({
        event_name: event?.event_name ?? "",
        volunteered_hours: event?.volunteered_hours ?? "",
      });
    });

    let response = await addScholarshipApplyAction(
      dispatch,
      params,
      paymentData,
    );
    try {
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response.message ?? "Success!",
          },
        });
        navigate(-1);
        // if (userType === "parent") {
        //   navigate(DASHBOARD_PAGE_PARENT);
        // }
        // if (userType === "player") {
        //   navigate(DASHBOARD_PAGE_ATHLETE);
        // }
        // if (userType === "admin") {
        //   navigate(-1);
        // }
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response.message ?? "Something went wrong",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleAthleteInputFieldsChanged = (value, fieldName, id, index) => {
    if (athleteFields && athleteFields?.length) {
      let list = [...athleteFields];
      if (fieldName === "athleteFirstName") {
        list[index]["athleteFirstName"] =
          value.charAt(0).toUpperCase() + value.slice(1);
      } else if (fieldName === "athleteLastName") {
        list[index]["athleteLastName"] =
          value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        list[index][fieldName] = value;
      }
      setAthleteFields(list);
    }
  };

  const addNewAthleteFields = () => {
    let athleteFieldFormat = {
      id: athleteFields?.length + 1,
      athleteFirstName: "",
      athleteLastName: "",
      athleteEmail: "",
      athletePhone: "",
      athletePhoneCountryCode: "",
    };
    setAthleteFields([...athleteFields, athleteFieldFormat]);
  };

  const deleteAthleteField = (selectedAthlete) => {
    let newArr = athleteFields?.filter(
      (athlete) => athlete?.id !== selectedAthlete?.id,
    );
    setAthleteFields(newArr);
  };

  const uploadDocuments = async (e, fieldName) => {
    let value = e?.target?.files[0];
    let fileName = value?.name;

    if (!value) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            "Not able to upload file, please try again with correct file",
        },
      });
      return;
    }
    setDocumentsName((prevState) => {
      return {
        ...prevState,
        [fieldName]: fileName,
      };
    });
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let formData = new FormData();
    formData.append("files", value ?? "");
    formData.append("type", "scholarship");
    formData.append("fileType", "incomeDocument");
    formData.append("userId", state?.user?.user?.id ?? "");
    try {
      let response = await uploadDocumentAction(dispatch, params, formData);
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response?.message ?? "Success!",
          },
        });
        formik.setFieldValue(fieldName, response?.url);
      } else {
        showError(response);
      }
    } catch (error) {
      showError(error);
    }
  };

  const showError = (response) => {
    dispatch({
      type: SET_SUCCESS_ERROR_POPUP,
      data: {
        open: true,
        type: "error",
        message: response?.message ?? "Something went wrong! Try again",
      },
    });
    setTimeout(() => {
      window.alert("Reload the page and try again!");
      window.location.reload();
    }, 2500);
  };

  const approveScholarshipRequest = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let bodyParam = {
      id: scholarshipData ? scholarshipData?.id : undefined,
      status: "approved",
      discount: discountValue,
    };
    if (!discountValue) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: "Please select discount" },
      });

      return;
    }

    let response = await approveScholarshipRequestAction(
      dispatch,
      params,
      bodyParam,
    );
    try {
      if (response?.ok) {
        // dispatch({
        //   type: SET_TOAST,
        //   data: {
        //     type: "success",
        //     message: response?.message ?? "Success!",
        //   },
        // });
        setShowSuccessPopup(true);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong! Try again",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong! Try again",
        },
      });
    }
  };

  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProgram(value);
    formik.setFieldValue("programApplyingFor", value?.join(","));
  };

  const incomeBaseChartDialog = () => {
    return (
      <Dialog
        open={showIncomeBasePopup}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => setShowIncomeBasePopup(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            pt: 4,
            px: 4,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            pb: 6,
          }}
        >
          <img src={incomeChartURL} style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions sx={{ pr: 4, pb: 3 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => setShowIncomeBasePopup(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const contentBox = {
    width: "100%",
    minWidth: 130,
    // minHeight: 100,
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    borderRadius: "4px",
    borderColor: "transparent",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // cursor: "pointer"
  };

  const statusUI = () => {
    return (
      <Box
        sx={{
          ...contentBox,
          my: 2,
        }}
      >
        <Box display="flex" alignItems="center" pt={2.5} pb={2}>
          <SchoolIcon sx={{ fontSize: 45 }} />
          <Typography
            variant="h3"
            fontWeight={700}
            sx={{ fontSize: 18, ml: 2 }}
            color={theme.palette.dark.dark3}
          >
            Scholarship Program
          </Typography>
        </Box>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mt={1}
        ></Box>
        <Typography
          variant="h3"
          fontWeight={600}
          color={
            scholarshipDetails[0]?.status === "Awaiting Approval"
              ? theme.palette.warning.dark
              : scholarshipDetails[0]?.status === "Denied"
              ? theme.palette.error.main
              : theme.palette.success.dark
          }
          sx={{
            pt: scholarshipDetails[0]?.status === "Approved" ? 6 : 0,
            py: scholarshipDetails[0]?.status === "Approved" ? 0 : 6,
            mt: scholarshipDetails[0]?.status === "Approved" ? 4 : 0,
          }}
        >
          {scholarshipDetails[0]?.status === "Approved" ? (
            <>
              <span style={{ fontWeight: "bold", fontSize: "30px" }}>
                {scholarshipDetails[0]?.discount + "%"}
              </span>
              <br />
            </>
          ) : (
            scholarshipDetails[0]?.status
          )}
        </Typography>
        {scholarshipDetails?.length &&
        scholarshipDetails[0]?.status === "Approved" ? (
          <Typography variant="h4" fontWeight={500} pb={6}>
            Only applicable for qualified events.
          </Typography>
        ) : null}
      </Box>
    );
  };

  const handleClosePage = () => {
    if (pageType === "userDetailPage") {
      navigate(USER_DETAILS_PAGE, {
        state: {
          selectedUserData: location?.state?.userData,
        },
      });
    } else {
      navigate(-1);
    }
  };

  const incomeBasedUI = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              mt={1}
            >
              Income Based
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="span"
              fontSize={15}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Below, you’ll need to fill out either your previous years tax
              statement and provide your last two pay stubs OR provide social
              security/disability checks and a government issued ID.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="household-income" sx={inputLabelStyle}>
              HOUSEHOLD INCOME
            </CustomInputLabel>
            <NumericFormat
              id="household-income"
              name="householdIncome"
              placeholder="Enter household income..."
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.householdIncome}
              // customInput={AmountInput}
              // displayType={'text'}
              style={numericInputStyle}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              disabled={isNewEntry ? false : scholarshipData ? true : false}
              allowNegative={false}
            />
            {Boolean(
              formik.touched.householdIncome && formik.errors.householdIncome,
            ) && (
              <FormHelperText error>
                {formik.errors.householdIncome}
              </FormHelperText>
            )}
            {incomeChartURL && userType === "admin" ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => setShowIncomeBasePopup(true)}
              >
                View Income Scale
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="household_size" sx={inputLabelStyle}>
              HOUSEHOLD SIZE
            </CustomInputLabel>
            <CustomTextField
              id="household_size"
              name="household_size"
              type="number"
              placeholder="Enter household size"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.household_size}
              sx={{
                width: 1,
                "&.MuiFormControl-root": {
                  width: "100%",
                },

                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  // width: 350,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
              disabled={isNewEntry ? false : scholarshipData ? true : false}
            />
            {Boolean(
              formik.touched.household_size && formik.errors.household_size,
            ) && (
              <FormHelperText error>
                {formik.errors.household_size}
              </FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="athlete-mobile" sx={inputLabelStyle}>
              Previous Year Tax Statement (Page 1 Only)
            </CustomInputLabel>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="docImg"
                sx={{ fontSize: "14px" }}
                shrink={false}
              >
                {documentsName?.previousYearTaxStatement
                  ? charValidate(documentsName?.previousYearTaxStatement, 30)
                  : "Upload tax statement..."}
              </InputLabel>
              <OutlinedInput
                name={`previousYearTaxStatement`}
                id="previousYearTaxStatement"
                type={"file"}
                onChange={(e) => {
                  uploadDocuments(e, "previousYearTaxStatement");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="docImg"
                      edge="end"
                      size="large"
                      htmlFor={`previousYearTaxStatement`}
                      component="label"
                      sx={{
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderRadius: "0 3px 3px 0",
                        width: "50px",
                        left: "3px",
                        // ml: 'auto',
                        // mr: '-14px',
                        p: "10px",
                      }}
                    >
                      <UploadFileRounded sx={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                  "& ::file-selector-button": {
                    opacity: 0,
                    display: "none",
                  },
                  "& .MuiInputAdornment-root": {
                    width: "auto",
                    marginLeft: "0",
                  },
                }}
                inputProps={{
                  style: {
                    opacity: 0,
                  },
                  // accept: "application/pdf, image/*"
                  accept: "application/pdf, .pdf, .doc, .docx, image/*",
                }}
                disabled={isNewEntry ? false : scholarshipData ? true : false}
              />
            </FormControl>
            {scholarshipData?.previous_tax &&
            documentsName?.previousYearTaxStatement ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => window.open(scholarshipData?.previous_tax)}
              >
                {charValidate(documentsName?.previousYearTaxStatement, 30)}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="lastTwoPayStubs" sx={inputLabelStyle}>
              LAST TWO PAY STUBS (1)
            </CustomInputLabel>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="docImg"
                sx={{ fontSize: "14px" }}
                shrink={false}
              >
                {documentsName?.lastTwoPayStubs
                  ? charValidate(documentsName?.lastTwoPayStubs, 30)
                  : "Upload pay stubs..."}
              </InputLabel>
              <OutlinedInput
                name={`lastTwoPayStubs`}
                id="lastTwoPayStubs"
                type={"file"}
                onChange={(e) => {
                  uploadDocuments(e, "lastTwoPayStubs");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="docImg"
                      edge="end"
                      size="large"
                      htmlFor={`lastTwoPayStubs`}
                      component="label"
                      sx={{
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderRadius: "0 3px 3px 0",
                        width: "50px",
                        left: "3px",
                        // ml: 'auto',
                        // mr: '-14px',
                        p: "10px",
                      }}
                    >
                      <UploadFileRounded sx={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                  "& ::file-selector-button": {
                    opacity: 0,
                    display: "none",
                  },
                  "& .MuiInputAdornment-root": {
                    width: "auto",
                    marginLeft: "0",
                  },
                }}
                inputProps={{
                  style: {
                    opacity: 0,
                  },
                  // accept: "application/pdf, image/*"
                  accept: "application/pdf, .pdf, .doc, .docx, image/*",
                }}
                disabled={isNewEntry ? false : scholarshipData ? true : false}
              />
            </FormControl>
            {scholarshipData?.pay_stub && documentsName?.lastTwoPayStubs ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => window.open(scholarshipData?.pay_stub)}
              >
                {charValidate(documentsName?.lastTwoPayStubs, 30)}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="pay_stub_second" sx={inputLabelStyle}>
              LAST TWO PAY STUBS (2)
            </CustomInputLabel>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="docImg1"
                sx={{ fontSize: "14px" }}
                shrink={false}
              >
                {documentsName?.pay_stub_second
                  ? charValidate(documentsName?.pay_stub_second, 30)
                  : "Upload second last pay stubs..."}
              </InputLabel>
              <OutlinedInput
                name={`pay_stub_second`}
                id="pay_stub_second"
                type={"file"}
                onChange={(e) => {
                  uploadDocuments(e, "pay_stub_second");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="docImg1"
                      edge="end"
                      size="large"
                      htmlFor={`pay_stub_second`}
                      component="label"
                      sx={{
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderRadius: "0 3px 3px 0",
                        width: "50px",
                        left: "3px",
                        // ml: 'auto',
                        // mr: '-14px',
                        p: "10px",
                      }}
                    >
                      <UploadFileRounded sx={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                  "& ::file-selector-button": {
                    opacity: 0,
                    display: "none",
                  },
                  "& .MuiInputAdornment-root": {
                    width: "auto",
                    marginLeft: "0",
                  },
                }}
                inputProps={{
                  style: {
                    opacity: 0,
                  },
                  // accept: "application/pdf, image/*"
                  accept: "application/pdf, .pdf, .doc, .docx, image/*",
                }}
                disabled={isNewEntry ? false : scholarshipData ? true : false}
              />
            </FormControl>
            {scholarshipData?.pay_stub_second &&
            documentsName?.pay_stub_second ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => window.open(scholarshipData?.pay_stub_second)}
              >
                {charValidate(documentsName?.pay_stub_second, 30)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>

        <Box width={1} display="flex" alignItems="center" mt={2} mb={1}>
          <Box
            width={0.5}
            height={"1.5px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
          <CustomInputLabel sx={{ fontWeight: 700, mx: 2, mt: 1, mx: 2 }}>
            OR
          </CustomInputLabel>
          <Box
            width={0.5}
            height={"1.5px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <CustomInputLabel
              htmlFor="socialSecurityCheck"
              sx={inputLabelStyle}
            >
              SOCIAL SECURITY/DISABILITY CHECKS
            </CustomInputLabel>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="docImg"
                sx={{ fontSize: "14px" }}
                shrink={false}
              >
                {documentsName?.socialSecurityCheck
                  ? charValidate(documentsName?.socialSecurityCheck, 30)
                  : "Upload document..."}
              </InputLabel>
              <OutlinedInput
                name={`socialSecurityCheck`}
                id="socialSecurityCheck"
                type={"file"}
                onChange={(e) => {
                  uploadDocuments(e, "socialSecurityCheck");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="docImg"
                      edge="end"
                      size="large"
                      htmlFor={`socialSecurityCheck`}
                      component="label"
                      sx={{
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderRadius: "0 3px 3px 0",
                        width: "50px",
                        left: "3px",
                        // ml: 'auto',
                        // mr: '-14px',
                        p: "10px",
                      }}
                    >
                      <UploadFileRounded sx={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                  "& ::file-selector-button": {
                    opacity: 0,
                    display: "none",
                  },
                  "& .MuiInputAdornment-root": {
                    width: "auto",
                    marginLeft: "0",
                  },
                }}
                inputProps={{
                  style: {
                    opacity: 0,
                  },
                  // accept: "application/pdf, image/*"
                  accept: "application/pdf, .pdf, .doc, .docx, image/*",
                }}
                disabled={isNewEntry ? false : scholarshipData ? true : false}
              />
            </FormControl>
            {scholarshipData?.social_security &&
            documentsName?.socialSecurityCheck ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => window.open(scholarshipData?.social_security)}
              >
                {charValidate(documentsName?.socialSecurityCheck, 30)}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="governmentIssuedID" sx={inputLabelStyle}>
              GOVERNMENT ISSUED ID
            </CustomInputLabel>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="docImg"
                sx={{ fontSize: "14px" }}
                shrink={false}
              >
                {documentsName?.governmentIssuedID
                  ? charValidate(documentsName?.governmentIssuedID, 30)
                  : "Upload govt. issued ID..."}
              </InputLabel>
              <OutlinedInput
                name={`governmentIssuedID`}
                id="governmentIssuedID"
                type={"file"}
                onChange={(e) => {
                  uploadDocuments(e, "governmentIssuedID");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="docImg"
                      edge="end"
                      size="large"
                      htmlFor={`governmentIssuedID`}
                      component="label"
                      sx={{
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderRadius: "0 3px 3px 0",
                        width: "50px",
                        left: "3px",
                        // ml: 'auto',
                        // mr: '-14px',
                        p: "10px",
                      }}
                    >
                      <UploadFileRounded sx={{ color: "white" }} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "12.5px 14px",
                  },
                  "& ::file-selector-button": {
                    opacity: 0,
                    display: "none",
                  },
                  "& .MuiInputAdornment-root": {
                    width: "auto",
                    marginLeft: "0",
                  },
                }}
                inputProps={{
                  style: {
                    opacity: 0,
                  },
                  // accept: "application/pdf, image/*"
                  accept: "application/pdf, .pdf, .doc, .docx, image/*",
                }}
                disabled={isNewEntry ? false : scholarshipData ? true : false}
              />
            </FormControl>
            {scholarshipData?.govt_id && documentsName?.governmentIssuedID ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  my: 0.8,
                  ml: 0.4,
                  color: "blue",
                }}
                onClick={() => window.open(scholarshipData?.govt_id)}
              >
                {charValidate(documentsName?.governmentIssuedID, 30)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  };

  const publicSectorUI = () => {
    return (
      <>
        <Grid container spacing={2} my={3}>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              mt={1}
            >
              Public Sector/Active Military Requirements
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="company_name" sx={inputLabelStyle}>
              EMPLOYER/COMPANY NAME
            </CustomInputLabel>
            <CustomTextField
              id="company_name"
              name="company_name"
              placeholder="Enter the your place of work..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.company_name}
              sx={{
                width: 1,
                "&.MuiFormControl-root": {
                  width: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  // width: 350,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
              disabled={isNewEntry ? false : scholarshipData ? true : false}
            />
            {Boolean(
              formik.touched.company_name && formik.errors.company_name,
            ) && (
              <FormHelperText error>
                {formik.errors.company_name}
              </FormHelperText>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="veteran" sx={inputLabelStyle}>
              VETERAN?
            </CustomInputLabel>
            <Select
              name="veteran"
              labelId="veteran"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.veteran}
              input={
                <OutlinedInput
                  label=""
                  placeholder="Choose answer..."
                  sx={{ width: "100%", height: 43 }}
                />
              }
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              renderValue={(selected) => {
                if (selected) {
                  let selectedAnswer = answerArray?.find(
                    (answer) => answer?.id === selected,
                  );
                  return selectedAnswer?.value;
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Choose Option
                    </Typography>
                  );
                }
              }}
              displayEmpty
              disabled={isNewEntry ? false : scholarshipData ? true : false}
            >
              {answerArray?.map((answer) => (
                <MenuItem key={answer?.id} value={answer?.id}>
                  {answer?.value}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.touched.veteran && formik.errors.veteran) && (
              <FormHelperText error>{formik.errors.veteran}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="branch" sx={inputLabelStyle}>
              BRANCH OF THE MILITARY
            </CustomInputLabel>
            <CustomTextField
              id="branch"
              name="branch"
              placeholder="Enter the branch of the military..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.branch}
              sx={{
                width: 1,
                "&.MuiFormControl-root": {
                  width: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  // width: 350,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
              disabled={isNewEntry ? false : scholarshipData ? true : false}
            />

            {Boolean(formik.touched.branch && formik.errors.branch) && (
              <FormHelperText error>{formik.errors.branch}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInputLabel htmlFor="service_active" sx={inputLabelStyle}>
              STILL ACTIVE?
            </CustomInputLabel>
            <Select
              name="service_active"
              labelId="service_active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.service_active}
              input={
                <OutlinedInput
                  label=""
                  placeholder="Choose answer..."
                  sx={{ width: "100%", height: 43 }}
                />
              }
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              renderValue={(selected) => {
                if (selected) {
                  let selectedAnswer = answerArray?.find(
                    (answer) => answer?.id === selected,
                  );
                  return selectedAnswer?.value;
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Choose Option
                    </Typography>
                  );
                }
              }}
              displayEmpty
              disabled={isNewEntry ? false : scholarshipData ? true : false}
            >
              {answerArray?.map((answer) => (
                <MenuItem key={answer?.id} value={answer?.id}>
                  {answer?.value}
                </MenuItem>
              ))}
            </Select>
            {Boolean(
              formik.touched.service_active && formik.errors.service_active,
            ) && (
              <FormHelperText error>
                {formik.errors.service_active}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...inputFields];
    updatedFields[index][fieldName] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = async () => {
    const data = inputFields[inputFields.length - 1];
    if (data.event_name === "" || data.volunteered_hours === "") {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Athlete lending all fields are mandatory.",
        },
      });
    } else {
      // setIsScoreOneValueGTzero(false)
      // setIsScoreTwoValueGTzero(false)
      setInputFields((prevInputFields) => [
        ...prevInputFields.map((obj, index) => ({ ...obj, index })),
        {
          idObj: `name-id-${inputFields.length}`,
          event_name: "",
          volunteered_hours: "",
        },
      ]);
    }
  };

  const deleteAthleteLandingField = (indexToRemove) => {
    const newArray = [
      ...inputFields.slice(0, indexToRemove),
      ...inputFields.slice(indexToRemove + 1),
    ];
    setInputFields(newArray);
  };

  const athleteLendingUI = () => {
    return (
      <>
        <Grid container spacing={2} my={3}>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              mt={1}
            >
              Athletes Lending a Helping Hand
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="span"
              fontSize={15}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Enter the volunteer information and hours below.
            </Typography>
          </Grid>

          {inputFields.map((inputField, index) => (
            <>
              <Grid item md={11} xs={11}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      GRIDIRON EVENT
                    </CustomInputLabel>
                    <CustomTextField
                      id={inputField.idObj}
                      name={`event_name-${index}`}
                      placeholder="Enter the gridiron event you volunteered at..."
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        handleFieldChange(index, "event_name", e.target.value)
                      }
                      value={inputField.event_name}
                      sx={{
                        width: 1,
                        "&.MuiFormControl-root": {
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          // width: 350,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                      disabled={
                        isNewEntry ? false : scholarshipData ? true : false
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      TOTAL FAMILY HOURS VOLUNTEERED
                    </CustomInputLabel>
                    <CustomTextField
                      id={inputField.idObj}
                      name={`volunteered_hours-${index}`}
                      type="number"
                      placeholder="Enter hours..."
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        handleFieldChange(
                          index,
                          "volunteered_hours",
                          e.target.value,
                        )
                      }
                      value={inputField.volunteered_hours}
                      sx={{
                        width: 1,
                        "&.MuiFormControl-root": {
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          // width: 350,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                      disabled={
                        isNewEntry ? false : scholarshipData ? true : false
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isNewEntry && !scholarshipData && (
                <>
                  {inputField?.hasOwnProperty("index") ? (
                    <Grid item md={1} xs={1}>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        sx={{
                          maxWidth: "70px",
                          p: 0,
                          background: "transparent",
                          boxShadow: "none",
                          width: "100%",
                          minWidth: "inherit",
                          height: "40px",
                          mt: 3,
                        }}
                        onClick={() => deleteAthleteLandingField(index)}
                        //disabled={!inputField?.is_active}
                      >
                        <DeleteSweepIcon
                          onClick={() => deleteAthleteLandingField(index)}
                          sx={{ fontSize: 28, cursor: "pointer", color: "red" }}
                        />
                      </Button>
                    </Grid>
                  ) : null}
                </>
              )}
            </>
          ))}
          {isNewEntry && !scholarshipData && (
            <Grid item md={1} xs={1}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  maxWidth: "70px",
                  p: 0,
                  background: "transparent",
                  boxShadow: "none",
                  width: "100%",
                  minWidth: "inherit",
                  height: "40px",
                  mt: 3,
                }}
                onClick={() => handleAddInputField()}
              >
                <AddCircleIcon
                  sx={{ fontSize: 28, cursor: "pointer", color: "green" }}
                ></AddCircleIcon>
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#00B60D",
                    "&.Mui-checked": {
                      color: "#00B60D",
                    },
                  }}
                  checked={isPersonalCorporateDonor}
                  onChange={(e) => {
                    setIsPersonalCorporateDonor(e?.target?.checked);
                  }}
                  disabled={isNewEntry ? false : scholarshipData ? true : false}
                />
              }
              label="I am a personal or corporate donor."
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const handleCloseApprovePopup = (isClosed) => {
    setShowApprovePopup(false);
  };

  const scholarshipProgramUI = () => {
    return (
      <>
        {selectedProgram.includes("income_based") && <>{incomeBasedUI()}</>}
        {selectedProgram.includes("public_sector") && <>{publicSectorUI()}</>}
        {selectedProgram.includes("athlete_landing") && (
          <>{athleteLendingUI()}</>
        )}
      </>
    );
  };

  const scholarshipForm = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
        <Box
          sx={{
            width: "95%",
            position: "relative",
            backgroundColor: theme?.palette?.background?.paper,
            boxShadow: theme?.palette?.boxShadow?.tableContainer,
            borderRadius: 1,
            border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
            mt: 4,
            [theme.breakpoints.down("sm")]: {
              mt: 0,
              width: "100%",
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              pl={3.5}
              py={2.5}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  borderBottom: "1px solid #e0e0e0",
                  width: "100%",
                  px: 3,
                  py: 2,
                },
              }}
            >
              Scholarship Program
            </Typography>
            {userType === "admin" && !isNewEntry ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                px={3.5}
                py={2.5}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    justifyContent: "start",
                    px: 3,
                    py: 2,
                  },
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                  },
                }}
              >
                {/* <Typography
                  variant="h6"
                  fontWeight={700}
                  fontSize={18}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{
                    whiteSpace: "nowrap",
                    [theme.breakpoints.down('lg')]: {
                      fontSize: 15,
                    },
                    [theme.breakpoints.down('sm')]: {
                      mb: 1,
                      fontSize: 16,
                    }
                  }}>
                  Apply Discount:
                </Typography> */}
                {/* <Box display="flex" flexDirection="row" ml={2}
                  sx={{
                    width: "100%",
                    [theme.breakpoints.down('sm')]: {
                      ml: 0,
                      flexDirection: "column",
                    }
                  }}>
                  <Select
                    name="discount"
                    displayEmpty
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.discount}
                    input={
                      <OutlinedInput
                        sx={{
                          maxWidth: "250px", width: "100%",
                          [theme.breakpoints.down('sm')]: {
                            maxWidth: "100%",
                          }
                        }}
                      />
                    }
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    renderValue={(selected) => {
                      if (selected && discountsList?.length) {
                        // let selectedDiscountValue = discountsList?.find((discount) => discount?.value === selected);
                        return `Scholarship Program - ${selected}%`;
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select Discount to Apply...
                          </Typography>
                        );
                      }
                    }}
                    MenuProps={MenuProps}
                    className="MuiSelect-select"
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10.5px 14px",
                      },
                      maxWidth: "250px",
                      width: "100%",
                      [theme.breakpoints.down('sm')]: {
                        maxWidth: "100%",
                      }
                    }}
                    disabled={isNewEntry ? false : scholarshipData?.status === "Awaiting Approval" ? false : true}
                  >
                    <MenuItem disabled value="">
                      Select Discount to Apply...
                    </MenuItem>
                    {discountsList?.map((discount) => (
                      <MenuItem
                        key={discount}
                        value={discount}
                      >
                        {`Scholarship Program - ${discount}%`}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.touched.discount && formik.errors.discount) && (
                    <FormHelperText error>{formik.errors.discount}</FormHelperText>
                  )}
                </Box> */}
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    maxWidth: "150px",
                    width: "100%",
                    height: "40px",
                    px: 2,
                    whiteSpace: "nowrap",
                    ml: 2,
                    [theme.breakpoints.down("sm")]: {
                      ml: 0,
                      mt: 1,
                      maxWidth: "100%",
                    },
                  }}
                  onClick={() => setShowApprovePopup(true)}
                  //disabled={isNewEntry ? false : scholarshipData?.status === "Awaiting Approval" ? false : true}
                >
                  {scholarshipData?.status === "Approved"
                    ? "Edit Discount"
                    : "Approve Request"}
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
            mt={0}
          ></Box>
          {userType !== "admin" ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="h4"
                fontWeight={500}
                fontSize={14}
                sx={{ my: 3, px: 2, textAlign: "center", maxWidth: "600px" }}
                color={theme?.palette?.text?.containerTextLabel}
              >
                Please fill out the form below and provide the necessary
                documents so our team can review your application and provide an
                accurate response.
              </Typography>
            </Box>
          ) : null}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              p: 3,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                spacing={0}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} lg={8} md={10}>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <CustomInputLabel
                            htmlFor="programApplyingFor"
                            sx={inputLabelStyle}
                          >
                            PROGRAM APPLYING FOR
                          </CustomInputLabel>
                          <Select
                            name="programApplyingFor"
                            labelId="programApplyingFor"
                            onBlur={formik.handleBlur}
                            onChange={handleChangeProgram}
                            value={selectedProgram}
                            input={
                              <OutlinedInput
                                label=""
                                placeholder="Select program to apply for"
                                sx={{ width: "100%", height: 43 }}
                              />
                            }
                            MenuProps={MenuProps}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            renderValue={(selected) => {
                              if (selected?.length) {
                                let titles = [];
                                programApplyingForList?.filter((program) => {
                                  if (selected.includes(program?.id)) {
                                    titles.push(program?.value);
                                  }
                                });
                                if (titles?.length) {
                                  return titles.join(", ");
                                }
                                return selected?.join(", ");
                              } else {
                                return (
                                  <Typography
                                    variant="p"
                                    fontWeight={400}
                                    fontSize={14}
                                    sx={{ opacity: 0.5 }}
                                  >
                                    Select program to apply for...
                                  </Typography>
                                );
                              }
                            }}
                            displayEmpty
                            multiple
                            disabled={
                              isNewEntry
                                ? false
                                : scholarshipData
                                ? true
                                : false
                            }
                          >
                            {programApplyingForList?.map((program) => (
                              <MenuItem key={program?.id} value={program?.id}>
                                <Checkbox
                                  checked={
                                    selectedProgram.indexOf(program?.id) > -1
                                  }
                                />
                                <ListItemText primary={program?.value} />
                              </MenuItem>
                            ))}
                          </Select>
                          {Boolean(
                            formik.touched.programApplyingFor &&
                              formik.errors.programApplyingFor,
                          ) && (
                            <FormHelperText error>
                              {formik.errors.programApplyingFor}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CustomInputLabel
                        htmlFor="parent-first-name"
                        sx={inputLabelStyle}
                      >
                        Parent First Name
                      </CustomInputLabel>
                      <CustomTextField
                        id="parent-first-name"
                        name="parentFirstName"
                        placeholder="Enter first name..."
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={(e) =>
                          convertToCapsOfFirstLetter(
                            e,
                            formik,
                            "parentFirstName",
                          )
                        }
                        value={formik.values.parentFirstName}
                        sx={{
                          width: 1,
                          "&.MuiFormControl-root": {
                            width: "100%",
                          },
                          "& .MuiOutlinedInput-root": {
                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                            // width: 350,
                            width: "100%",
                            boxShadow: "none",
                          },
                        }}
                        disabled={
                          isNewEntry ? false : scholarshipData ? true : false
                        }
                      />
                      {Boolean(
                        formik.touched.parentFirstName &&
                          formik.errors.parentFirstName,
                      ) && (
                        <FormHelperText error>
                          {formik.errors.parentFirstName}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CustomInputLabel
                        htmlFor="parent-last-name"
                        sx={inputLabelStyle}
                      >
                        Parent Last Name
                      </CustomInputLabel>
                      <CustomTextField
                        id="parent-last-name"
                        name="parentLastName"
                        placeholder="Enter last name..."
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={(e) =>
                          convertToCapsOfFirstLetter(
                            e,
                            formik,
                            "parentLastName",
                          )
                        }
                        value={formik.values.parentLastName}
                        sx={{
                          width: 1,
                          "&.MuiFormControl-root": {
                            width: "100%",
                          },
                          "& .MuiOutlinedInput-root": {
                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                            // width: 350,
                            width: "100%",
                            boxShadow: "none",
                          },
                        }}
                        disabled={
                          isNewEntry ? false : scholarshipData ? true : false
                        }
                      />
                      {Boolean(
                        formik.touched.parentLastName &&
                          formik.errors.parentLastName,
                      ) && (
                        <FormHelperText error>
                          {formik.errors.parentLastName}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <CustomInputLabel
                        htmlFor="parent-email"
                        sx={inputLabelStyle}
                      >
                        Parent Email
                      </CustomInputLabel>
                      <CustomTextField
                        id="parent-email"
                        name="parentEmail"
                        placeholder="Enter email..."
                        variant="outlined"
                        size="small"
                        type="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.parentEmail.toLowerCase()}
                        sx={{
                          width: 1,
                          "&.MuiFormControl-root": {
                            width: "100%",
                          },
                          "& .MuiOutlinedInput-root": {
                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                            // width: 350,
                            width: "100%",
                            boxShadow: "none",
                          },
                        }}
                        disabled={
                          isNewEntry ? false : scholarshipData ? true : false
                        }
                      />
                      {Boolean(
                        formik.touched.parentEmail && formik.errors.parentEmail,
                      ) && (
                        <FormHelperText error>
                          {formik.errors.parentEmail}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CustomInputLabel
                        htmlFor="parent-mobile-number"
                        sx={inputLabelStyle}
                      >
                        Parent Phone
                      </CustomInputLabel>
                      <Box
                        sx={{
                          "& .react-tel-input .form-control": {
                            minWidth: "100%",
                            width: "100%",
                            fontWeight: 500,
                            background: theme?.palette?.grey[50],
                            height: "43px",
                          },
                        }}
                      >
                        <PhoneInput
                          country={"us"}
                          id="parent-mobile"
                          name="parent-mobile"
                          placeholder="(702) 123-4567"
                          variant="outlined"
                          onBlur={formik.handleBlur}
                          value={formik.values.parentPhone}
                          onChange={(value, country) => {
                            formik.setFieldValue(
                              "parentCountryCode",
                              country?.countryCode || "",
                            );
                            formik.setFieldValue("parentPhone", value || "");
                          }}
                          error={Boolean(
                            formik.touched.parentPhone &&
                              formik.errors.parentPhone,
                          )}
                          size="sm"
                          // style={{
                          //   width: 350,
                          // }}
                          sx={{
                            width: 1,
                            "&.MuiFormControl-root": {
                              width: "100%",
                            },
                            "& .MuiOutlinedInput-input": {
                              padding: "12px 14px",
                            },
                          }}
                          disableDropdown={true}
                          disableCountryCode={true}
                          onlyCountries={["us"]}
                          disabled={
                            isNewEntry ? false : scholarshipData ? true : false
                          }
                        />
                        {Boolean(
                          formik.touched.parentPhone &&
                            formik.errors.parentPhone,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.parentPhone}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ minWidth: 110, height: "40px", mt: 5 }}
                    onClick={addNewAthleteFields}
                    disabled={
                      isNewEntry ? false : scholarshipData ? true : false
                    }
                  >
                    Add Athlete
                  </Button>

                  {athleteFields && athleteFields?.length
                    ? athleteFields?.map((athlete, index) => (
                        <Grid
                          container
                          spacing={2}
                          mt={athleteFields?.length > 1 ? 0.5 : 0}
                          sx={{
                            position: "relative",
                          }}
                          key={athlete?.id}
                        >
                          {isNewEntry && !scholarshipData && (
                            <>
                              {index > 0 ? (
                                <DeleteSweepIcon
                                  onClick={() => deleteAthleteField(athlete)}
                                  sx={{
                                    fontSize: 28,
                                    cursor: "pointer",
                                    color: "red",
                                    position: "absolute",
                                    left: "-30px",
                                    top: "50px",
                                    [theme.breakpoints.down("md")]: {
                                      position: "relative",
                                      mt: 3,
                                      top: "0",
                                      left: "16px",
                                    },
                                  }}
                                />
                              ) : null}
                            </>
                          )}

                          <Grid item md={6} xs={12}>
                            <CustomInputLabel
                              htmlFor={"athlete-first-name" + athlete?.id}
                              sx={inputLabelStyle}
                            >
                              Athlete First Name
                            </CustomInputLabel>
                            <CustomTextField
                              id={"athlete-first-name" + athlete?.id}
                              name={"athleteFirstName" + athlete?.id}
                              placeholder="Enter first name..."
                              variant="outlined"
                              size="small"
                              onChange={(e) =>
                                handleAthleteInputFieldsChanged(
                                  e?.target?.value,
                                  "athleteFirstName",
                                  athlete?.id,
                                  index,
                                )
                              }
                              value={athlete?.athleteFirstName}
                              sx={{
                                width: 1,
                                "&.MuiFormControl-root": {
                                  width: "100%",
                                },
                                "& .MuiOutlinedInput-root": {
                                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                  // width: 350,
                                  width: "100%",
                                  boxShadow: "none",
                                },
                              }}
                              disabled={
                                isNewEntry
                                  ? false
                                  : scholarshipData
                                  ? true
                                  : false
                              }
                            />
                            {/* {Boolean(formik.touched.athleteFirstName && formik.errors.athleteFirstName) && (
                            <FormHelperText error>{formik.errors.athleteFirstName}</FormHelperText>
                          )} */}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CustomInputLabel
                              htmlFor={"athlete-last-name" + athlete?.id}
                              sx={inputLabelStyle}
                            >
                              Athlete Last Name
                            </CustomInputLabel>
                            <CustomTextField
                              id={"athlete-last-name" + athlete?.id}
                              name={"athleteLastName" + athlete?.id}
                              placeholder="Enter last name..."
                              variant="outlined"
                              size="small"
                              onChange={(e) =>
                                handleAthleteInputFieldsChanged(
                                  e?.target?.value,
                                  "athleteLastName",
                                  athlete?.id,
                                  index,
                                )
                              }
                              value={athlete?.athleteLastName}
                              sx={{
                                width: 1,
                                "&.MuiFormControl-root": {
                                  width: "100%",
                                },
                                "& .MuiOutlinedInput-root": {
                                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                  // width: 350,
                                  width: "100%",
                                  boxShadow: "none",
                                },
                              }}
                              disabled={
                                isNewEntry
                                  ? false
                                  : scholarshipData
                                  ? true
                                  : false
                              }
                            />
                            {/* {Boolean(formik.touched.athleteLastName && formik.errors.athleteLastName) && (
                            <FormHelperText error>{formik.errors.athleteLastName}</FormHelperText>
                          )} */}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CustomInputLabel
                              htmlFor={"athlete-email" + athlete?.id}
                              sx={inputLabelStyle}
                            >
                              Athlete Email
                            </CustomInputLabel>
                            <CustomTextField
                              id={"athlete-email" + athlete?.id}
                              name={"athleteEmail" + athlete?.id}
                              placeholder="Enter email..."
                              variant="outlined"
                              size="small"
                              type="email"
                              onChange={(e) =>
                                handleAthleteInputFieldsChanged(
                                  e?.target?.value,
                                  "athleteEmail",
                                  athlete?.id,
                                  index,
                                )
                              }
                              value={athlete?.athleteEmail.toLowerCase()}
                              sx={{
                                width: 1,
                                "&.MuiFormControl-root": {
                                  width: "100%",
                                },
                                "& .MuiOutlinedInput-root": {
                                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                  // width: 350,
                                  width: "100%",
                                  minWidth: "100%",
                                  boxShadow: "none",
                                },
                              }}
                              disabled={
                                isNewEntry
                                  ? false
                                  : scholarshipData
                                  ? true
                                  : false
                              }
                            />
                            {/* {Boolean(formik.touched.athleteEmail && formik.errors.athleteEmail) && (
                            <FormHelperText error>{formik.errors.athleteEmail}</FormHelperText>
                          )} */}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CustomInputLabel
                              htmlFor={"athlete-phone" + athlete?.id}
                              sx={inputLabelStyle}
                            >
                              Athlete Phone
                            </CustomInputLabel>
                            <Box
                              sx={{
                                "& .react-tel-input .form-control": {
                                  minWidth: "100%",
                                  width: "100%",
                                  fontWeight: 500,
                                  background: theme?.palette?.grey[50],
                                  height: "43px",
                                },
                              }}
                            >
                              <PhoneInput
                                country={"us"}
                                id={"athlete-phone" + athlete?.id}
                                name={"athletePhone" + athlete?.id}
                                variant="outlined"
                                // onBlur={formik.handleBlur}
                                // value={formik.values.athleteMobile}
                                // onChange={(value, country) => {
                                //   formik.setFieldValue("athleteCountryCode", country?.countryCode || "");
                                //   formik.setFieldValue("athleteMobile", value || "");
                                // }}
                                placeholder="(702) 123-4567"
                                onChange={(value, country) => {
                                  handleAthleteInputFieldsChanged(
                                    value,
                                    "athletePhone",
                                    athlete?.id,
                                    index,
                                  );
                                  handleAthleteInputFieldsChanged(
                                    country?.countryCode,
                                    "athletePhoneCountryCode",
                                    athlete?.id,
                                    index,
                                  );
                                }}
                                value={athlete?.athletePhone}
                                // error={Boolean(formik.touched.athleteMobile && formik.errors.athleteMobile)}
                                size="sm"
                                // style={{
                                //   width: 350,
                                // }}
                                sx={{
                                  "& .MuiOutlinedInput-input": {
                                    padding: "12px 14px",
                                  },
                                }}
                                disableDropdown={true}
                                disableCountryCode={true}
                                onlyCountries={["us"]}
                                disabled={
                                  isNewEntry
                                    ? false
                                    : scholarshipData
                                    ? true
                                    : false
                                }
                              />
                              {/* {Boolean(formik.touched.athleteMobile && formik.errors.athleteMobile) && (
                              <FormHelperText error>{formik.errors.athleteMobile}</FormHelperText>
                            )} */}
                            </Box>
                          </Grid>
                        </Grid>
                      ))
                    : null}

                  <Box
                    width={1}
                    height={"1.5px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                    my={3}
                  ></Box>

                  {incomeBasedUI()}
                  {publicSectorUI()}
                  {athleteLendingUI()}

                  {(isNewEntry ||
                    userType === "teammanager" ||
                    userType === "teamcoach") && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={12}
                      mb={7}
                      sx={{
                        [theme.breakpoints.down("lg")]: {
                          mt: 7,
                          mb: 5,
                        },
                        [theme.breakpoints.down("md")]: {
                          mt: 5,
                          mb: 3,
                        },
                        [theme.breakpoints.down("sm")]: {
                          mt: 3,
                          mb: 0,
                        },
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        sx={{ maxWidth: "250px", p: 1, width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
          {userType === "admin" && (
            <Box
              sx={{
                p: 3,
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => {
                  handleClosePage();
                }}
                sx={{ maxWidth: "150px", width: "100%", height: "40px" }}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
        {showSuccessPopup && (
          <SuccessPopup
            open={true}
            handleClose={() => {
              setShowSuccessPopup(true);
              navigate(-1);
            }}
            title="Scholarship Funds"
            secondaryTitle="Success! Scholarship discount has been awarded to this user."
            isShowCloseBtn={false}
            isShowSubTitle={true}
            secondaryMessage="Tap anywhere out of this popup to hide this popup"
            alignCenterSecondaryTitle={true}
          />
        )}
        {showIncomeBasePopup && incomeBaseChartDialog()}
      </Box>
    );
  };

  return (
    <>
      {userType !== "admin" ? (
        <>
          {scholarshipDetails?.length
            ? scholarshipDetails[0]?.status === "Denied" && isNewEntry
              ? scholarshipForm()
              : statusUI()
            : scholarshipForm()}
        </>
      ) : (
        scholarshipForm()
      )}
      {showApprovePopup && (
        <EditScholarshipDiscount
          open={showApprovePopup}
          handleClose={handleCloseApprovePopup}
          requestData={scholarshipData}
        />
      )}
    </>
  );
};

export default AdminScholarshipProgramApply;
