import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";

const CancelSendCampaign = ({ open, handleClose, handleConfirmClose }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ p: 0 }}>
        <Typography
          variant="h2"
          fontWeight={700}
          //fontSize={21}
          py={4}
          ml={4}
          color={theme?.palette?.text?.containerTextLabel}
        >
          Send Preview
        </Typography>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: 5,
        }}
      >
        <Typography
          sx={{ pt: 5, pb: 0.5 }}
          fontFamily={"Poppins"}
          fontSize={21}
          fontWeight={500}
          color={theme?.palette?.text?.containerTextLabel}
        >
          Are you sure you want to cancel this?
        </Typography>
        <Typography
          fontFamily={"Poppins"}
          fontSize={21}
          fontWeight={500}
          color={theme?.palette?.text?.containerTextLabel}
        >
          You will lose all your progress.
        </Typography>
        <Box
          sx={{
            mt: 7,
            width: "45%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              paddingX: 3.5,
              paddingY: 1.2,
              background: theme?.palette?.inputsLabels?.green,
              color: "white",
            }}
            onClick={handleClose}
          >
            No, Keep Going
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            onClick={handleConfirmClose}
          >
            Yes, Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CancelSendCampaign;
