import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_TOAST, SET_LOADER, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import styled from "@emotion/styled";
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import httpClient from "@utils/httpClient";
import { useNavigate } from "react-router";
import { Tournament_Paid_Roster_Athlete_List } from "../../routes/constants";
import { useState } from "react";
import SuccessPopup from "../Common/SuccessPopup";
import { ReactComponent as PoweredByStripe } from '../../assets/images/svg/Powered-by-Stripe.svg';

const CheckoutForm = ({ open, handleClose, formData, type }) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const cardElementOptions = {
    hidePostalCode: true,

  };
  const StyledCardElement = styled(CardElement)`
    border: 1px solid #000000;
    padding: 15px;
    font-size: 18px;
    color: #000000;
    background-color: #fafafa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid #bdbdbd;
    height: 48px;
    width: 100%,

    ::placeholder {
      color: #999999;
    }

    :focus,
    :hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    :invalid {
      color: #ff0000;
    }
  `;

  // const validationSchema = Yup.object({
  //   cardName: Yup.string().trim(),
  //   // firstName: Yup.string().trim().required("First Name is required"),
  //   // lastName: Yup.string().trim().required("Last Name is required"),
  //   // email: Yup.string()
  //   //   .trim()
  //   //   .required("Email is required")
  //   //   .email("Must be a valid email"),
  //   // address: Yup.string().required("Address is required"),
  //   // city: Yup.string().required("City is required"),
  //   // state: Yup.string().required("State is required"),
  //   // zip: Yup.string().max(10).required("Zip Code is required"),
  // });

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    let cardElement = elements.getElement(CardElement);
    const result = await stripe.confirmCardPayment(formData?.clientSecret, {
      payment_method: {
        card: cardElement
      },
    });
    dispatch({ type: SET_LOADER, data: true });
    if (result.error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: result?.error?.message || "Invalid Card" }
      })
    } else {
      dispatch({ type: SET_LOADER, data: true });
      let paymentData = {
        team_id: formData.team_id,
        transaction_id: formData.transaction_id,
        amount: formData.amount,
        discount: "",
        discount_value: 0,
      }
      if (type === "athlete") {
        paymentData.athlete_count = formData?.athlete_count;
        paymentData.athleteData = formData?.athleteData ?? [];
      }
      const API_URL = type === 'team' ? "/team-payments/teamPayment" : "/athlete-payments/athletePayment"
      const apiRes = await httpClient.post(API_URL, paymentData)
      dispatch({ type: SET_LOADER, data: false });
      if (apiRes && apiRes.ok) {
        // dispatch({
        //   type: SET_TOAST,
        //   data: {
        //     type: 'success',
        //     message: apiRes.message,
        //   },
        // });
        setShowSuccessModal(true);
      }
      else {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
        })
      }
    }
    dispatch({ type: SET_LOADER, data: false });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     cardName: ""
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: handleSubmit,
  // });

  const handleSuccessModalClose = () => {
    handleClose(true);
    if (type === "team") {
      navigate("/tournament/add-player", { state: { teamId: formData?.team_id ?? "" } });
    }
    if (type === "athlete") {
      navigate(
        Tournament_Paid_Roster_Athlete_List,
        {
          replace: true,
          state: {
            teamName: formData?.title,
            teamId: formData?.team_id ?? ""
          }
        }
      );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        //onClose={() => handleClose(true)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            pt={1}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Team Registration: {type === "team" ? "Entry" : "Player"} Fee
            </Typography>
          </Box>
        </DialogTitle>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
        <Box
          sx={{
            background: "#EFF7FF",
            border: `1px solid #7FBEFD`,
            color: "black",
            maxWidth: "30rem",
            borderRadius: "7px",
            fontSize: "14px",
            fontWeight: 500,
            ml: 3, mt: 2
          }}
          p={2}
        >
          *Please be patient once submitted. This process could take a several moments to complete.
        </Box>
        {/* <Typography
          variant="h6"
          fontWeight={500}
          fontSize={14}
          px={2.5}
          sx={{ color: 'orange !important' }}
          my={2.5}
        >
          
        </Typography> */}
        {/* <form onSubmit={formik.handleSubmit}> */}
        <DialogContent sx={{ mt: 1, mb: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CustomInputLabel htmlFor="card-name">
                Name on the card
              </CustomInputLabel>
              <CustomTextField
                id="card-name"
                name="cardName"
                placeholder="Enter card name..."
                variant="outlined"
                size="small"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                // value={formik.values.cardName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 14px !important",
                  },
                  width: "100%",
                }}
              />
              {/* {Boolean(formik.touched.cardName && formik.errors.cardName) && (
                <FormHelperText error>{formik.errors.cardName}</FormHelperText>
              )} */}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>
                Card Number
              </CustomInputLabel>
              <StyledCardElement options={cardElementOptions} />
            </Grid>
          </Grid>
        </DialogContent>

        <Box sx={{ padding: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '200px' }}>
            <PoweredByStripe />
          </Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2, mr: 2 }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              // type="submit"
              onClick={handleSubmit}
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
            >
              Pay ${formData?.amount}
            </Button>
          </Box>
        </Box>
        {/* </form> */}
      </Dialog>
      {showSuccessModal &&
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to see next view"
        />
      }
    </>
  );
};


const TeamPaymentAmount = ({ open, handleClose, formData, type }) => {
  const stripePromise = loadStripe(formData.publishableKey);
  return (
    <Elements stripe={stripePromise} >
      <CheckoutForm
        open={open}
        handleClose={handleClose}
        formData={formData}
        type={type}
      />
    </Elements>
  );
};


export default TeamPaymentAmount;
