import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { APP_MESSAGES, LIMIT_VALIDATION } from '../../utils/constants'

const validationSchema = Yup.object({
    password: Yup.string()
        .max(LIMIT_VALIDATION.newPassValueLimit, LIMIT_VALIDATION.newPassLimitMsg)
        .matches(APP_MESSAGES.newPasswordFormat, 'Password must meet the required rules.')
        .min(6)
        .required(LIMIT_VALIDATION.emptyNewPassMsg),
    passwordConfirmation: Yup.string()
        .max(LIMIT_VALIDATION.common255Length)
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
})

const PasswordScreen = ({ step, setStep, formData, setFormData }) => {
    const theme = useTheme()

    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirmation: false,
    })

    const handleClickShowPassword = (type) => {
        setShowPassword((prevState) => {
            return {
                ...prevState,
                [type]: !showPassword[type],
            }
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const goBack = () => {
        setStep(step - 1)
    }

    const handleSave = async (values) => {
        setFormData((prevStage) => {
            return {
                ...prevStage,
                ...values,
            }
        })
        setStep(2)
    }

    const formik = useFormik({
        initialValues: {
            password: formData?.password || '',
            passwordConfirmation: formData?.passwordConfirmation || '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    })

    return (
        <Grid container direction="column" justifyContent="left" sx={{ p: 2 }}>
            <Box sx={{ maxWidth: '400px', margin: '0 auto', width: '100%' }}>
                <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="h2" sx={{ fontSize: '14px' }}>
                        Safety First! Let's lock in a password for you.
                    </Typography>
                </Grid>
                <Box
                    sx={{
                        border: '1px solid #228b22',
                        padding: '4px',
                        borderRadius: '4px',
                        backgroundColor: '#E8F5E9',
                        textAlign: "left",
                        mt: 1,
                        mb: 1,
                        li: {
                            mt: 0.5,
                        }
                    }}
                >
                    <Typography variant="subtitle1" gutterBottom sx={{ color: '#006400', ml: 1 }}>
                        Password Rules:
                    </Typography>
                    <ul>
                        <li>At least 6 characters.</li>
                        <li>A mixture of both uppercase and lowercase letters.</li>
                        <li>A mixture of letters and numbers.</li>
                        <li>Include at least one special character (e.g., ! @ # ?)</li>
                    </ul>
                </Box>
            </Box>
            <Grid item xs={12}>
                <Box sx={{ maxWidth: '350px', margin: '0 auto', width: '100%' }}>
                    <form onSubmit={formik.handleSubmit}>
                        {/* Password */}
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.password && formik.errors.password
                            )}
                            sx={{
                                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                    border: '0',
                                    boxShadow: '0px 6px 20px #1F54C326',
                                }
                            }}
                        >
                            <InputLabel htmlFor="password">Password *</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword.password ? 'text' : 'password'}
                                value={formik.values.password}
                                name="password"
                                label="Password *"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleClickShowPassword('password')
                                            }
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword.password ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {Boolean(
                                formik.touched.password && formik.errors.password
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.password}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        {/* Confirmation Password */}
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.passwordConfirmation &&
                                formik.errors.passwordConfirmation
                            )}
                            sx={{
                                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                    border: '0',
                                    boxShadow: '0px 6px 20px #1F54C326',
                                }
                            }}
                        >
                            <InputLabel htmlFor="passwordConfirmation">
                                Confirm Password *
                            </InputLabel>
                            <OutlinedInput
                                id="passwordConfirmation"
                                type={
                                    showPassword.passwordConfirmation
                                        ? 'text'
                                        : 'password'
                                }
                                value={formik.values.passwordConfirmation}
                                name="passwordConfirmation"
                                label="Confirm Password *"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                handleClickShowPassword(
                                                    'passwordConfirmation'
                                                )
                                            }
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword.passwordConfirmation ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {Boolean(
                                formik.touched.passwordConfirmation &&
                                formik.errors.passwordConfirmation
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.passwordConfirmation}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        {/* Button */}
                        <Box sx={{ mt: 2 }}>
                            <Button
                                disableElevation
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{ fontWeight: '600' }}
                            // onClick={() => setStep(2)}
                            >
                                Confirm Account
                            </Button>
                        </Box>
                    </form>

                    {/* Links */}
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{ mt: 4 }}
                    >
                        <Typography
                            variant="subtitle1"
                            color="secondary"
                            className="pointer textUnderline"
                            align="center"
                            onClick={goBack}
                        >
                            Go Back
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PasswordScreen
