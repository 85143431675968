import { Avatar, Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_LOADER,
  SET_TOAST,
  deleteTeamRosterAction,
  getUnPaidTeamMemberListAction, SET_SUCCESS_ERROR_POPUP
} from "../../store/actions";
import { charValidate } from "../../utils/functions";
import { useLocation, useNavigate } from "react-router";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import TeamPaymentAmount from "../../components/Tournament/TeamPaymentAmount";
import httpClient from "@utils/httpClient";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import { Admin_Tournament_PAGE, Tournament_Add_Player_Page, Tournament_Teams_Page } from "../../routes/constants";

const RosterAthleteList = () => {
  const tableRef = useRef();
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState({})
  const [rosterObj, setRoasterObj] = useState({})
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationState = location?.state;
  let rostersList = navigationState?.dataList ?? [];
  const [dataList, setDataList] = useState(rostersList ?? []);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isRosterAthleteListLoading, setRosterAthleteListLoading] = useState(false);
  const [tournamentId, setTournamentId] = useState("");
  const [feePerPlayer, setFeePerPlayer] = useState(0);

  // useEffect(() => {
  //   if (location?.state) {
  //     setDataList(rostersList);
  //   }
  // }, [location?.state]);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getDataList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getDataList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    setRosterAthleteListLoading(true);
    let response = await getUnPaidTeamMemberListAction(params, navigationState?.teamId ?? "");
    setRosterAthleteListLoading(false);
    if (response?.ok) {
      setTournamentId(response?.data?.tournament_id ?? "");
      setFeePerPlayer(response?.data?.athlete_fee ?? 0);
      setDataList(response?.data?.athleteList ?? []);
    }
  }

  const deleteRosterFromList = () => {
    let roster = dataList?.find((roster) => roster?.id === rosterObj?.id);
    if (roster) {
      deleteRoster(roster?.id ?? "");
    }
  }

  const deleteRoster = async (rosterId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let bodyParams = {
      team_id: navigationState?.teamId ?? "",
      athletes: [rosterId]
    }
    try {
      let response = await deleteTeamRosterAction(dispatch, params, bodyParams);
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response.message ?? "Roster deleted successfully" }
        })
        setRoasterObj({});
        setShowDeletePopup(false);
        getDataList();
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response.message ?? "Something went wrong, try again!" }
        })
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error.message ?? "Something went wrong, try again!" }
      })
    }
  };

  const handleTeamPaymentAmountModalClose = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentConfirm = async () => {
    navigate(
      Tournament_Teams_Page,
      {
        state:
        {
          tournamentId: tournamentId ?? "",
        }
      }
    );
    // try {
    //   dispatch({ type: SET_LOADER, data: true });
    //   const apiRes = await httpClient.post(
    //     "/athlete-payments/paymentIntent",
    //     { team_id: navigationState?.teamId ?? "", athlete_count: dataList?.length }
    //   );
    //   dispatch({ type: SET_LOADER, data: false });
    //   if (apiRes && apiRes.ok) {
    //     let {
    //       clientSecret,
    //       transaction_id,
    //       publishableKey,
    //       amount
    //     } = apiRes.data;
    //     setPaymentFormData({
    //       team_id: navigationState?.teamId ?? "",
    //       clientSecret,
    //       transaction_id,
    //       publishableKey,
    //       amount,
    //       athlete_count: dataList?.length,
    //       athleteData: dataList,
    //     });
    //     setShowPaymentModal(true);
    //   } else {
    //     dispatch({
    //       type: SET_TOAST,
    //       data: {
    //         type: "error",
    //         message: apiRes?.message ?? "Something went wrong",
    //       },
    //     });
    //   }
    // } catch (error) {
    //   dispatch({
    //     type: SET_TOAST,
    //     data: {
    //       type: "error",
    //       message: error?.message ?? "Something went wrong",
    //     },
    //   });
    // }
  };

  let columns = [
    {
      field: "id",
      headerName: "ROSTER ID#",
      minWidth: 80,
      flex: 1,
      renderCell: (data) => data.api.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "ATHLETE FIRST NAME",
      flex: 1,
      minWidth: 130,
      renderCell: (data) => (
        <Tooltip title={data?.row?.first_name ?? "N/A"}>
          <Box sx={{ display: 'flex' }}>
            <Avatar
              sx={{
                bgcolor: theme?.palette?.success?.main,
                color: "primary.contrastText",
                marginRight: 1,
                mt: 0.4,
                width: 16,
                height: 16,
                fontSize: 10,
              }}
            >
              A
            </Avatar>
            {data?.row?.first_name
              ? charValidate(data?.row?.first_name, 15)
              : "N/A"}
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "ATHLETE LAST NAME",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title={data?.row?.last_name ?? "N/A"}>
          {data?.row?.last_name
            ? charValidate(data?.row?.last_name, 15)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "parent_first_name",
      headerName: "PARENT FIRST NAME",
      flex: 1,
      minWidth: 130,
      renderCell: (data) => {
        if (data?.row?.parent && data?.row?.parent?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.parent?.map(parent => (
                <Tooltip title={parent?.first_name ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4 }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {parent?.first_name ?? "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          )
        } else {
          return "N/A"
        }
      },
      sortable: false,
    },
    {
      field: "parent_last_name",
      headerName: "PARENT LAST NAME",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => {
        if (data?.row?.parent && data?.row?.parent?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.parent?.map(parent => (
                <Tooltip title={parent?.last_name ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4 }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {parent?.last_name ?? "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          )
        } else {
          return "N/A"
        }
      },
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <Box onClick={() => { setRoasterObj(data?.row); setShowDeletePopup(true); }}>
            <DeleteSweepIcon sx={{ fontSize: 22, cursor: "pointer", color: "red" }} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <MuiDataGrid
        headerLabel={"Team Roster"}
        gridData={isRosterAthleteListLoading ? [] : dataList}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={isRosterAthleteListLoading}
        onClickAddNew={() => {
          navigate(Tournament_Add_Player_Page, { state: { teamId: navigationState?.teamId ?? "", rosterCount: navigationState?.rosterCount } })
        }}
        isAddNewBtnShow={true}
        //isShowFileDownloadOpt={dataList?.length ? true : false}
        rightBtnLabel="Add Athlete"
        gridMessage={(
          <Box py={1.5} px={2}
            sx={{
              background: "#EFF7FF",
              border: `1px solid #7FBEFD`,
              color: "black",
              width: "15rem",
              borderRadius: "7px",
              fontSize: "14px",
              fontWeight: 500,
              marginRight: "10px",
            }}

          >
            {/* {`Roster fees are $${feePerPlayer} per player. You must pay for your roster before completing registration.`} */}
            {`Roster fee is $${feePerPlayer} per player.`}
          </Box>
        )}
        gridButtons={() => {
          return (
            <Box sx={{ my: 2, px: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Button
                size="small"
                variant="contained"
                sx={{ paddingX: 3.5, paddingY: 1 }}
                color="inherit"
                onClick={() => {
                  navigate(-1);
                  // navigationState = undefined;
                }}
              >
                Back
              </Button>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <Button
                  size="small"
                  variant="outlined"
                  sx={{ paddingX: 3.5, paddingY: 0.8, fontWeight: 600, border: "2px solid" }}
                  onClick={() => {
                    navigate(-1);
                    // navigationState = undefined;
                  }}
                >
                  Back
                </Button> */}
                {dataList?.length &&
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1, ml: 2 }}
                    onClick={() => {
                      if (dataList?.length) {
                        handlePaymentConfirm()
                      }
                    }}
                    disabled={dataList?.length ? false : true}
                  >
                    Save Roster & Go to Payment
                  </Button>
                }
              </Box>
            </Box>
          )
        }}
        options={{
          disableRowSelectionOnClick: true
        }}
      />

      {showDeletePopup &&
        <DeleteConfirmPopup
          title={"Athlete"}
          message={"Are you sure you want to delete this Athlete?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setRoasterObj({});
          }}
          handleConfirm={deleteRosterFromList}
        />
      }
    </>
  );
};

export default RosterAthleteList;
