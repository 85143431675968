import {
  Avatar,
  Box,
  Button,
  Tooltip,
  Typography,
  useTheme,
  TextField,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import MuiDataGrid from "../MuiDataGrid/MuiDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  getPackPurchaseHistory,
} from "../../store/actions";
import { charValidate } from "../../utils/functions";
import { USER_DETAILS_PAGE } from "../../routes/constants";
import { useLocation, useNavigate } from "react-router";
import { dateFormat } from "../../utils/functions";
import DeductCountModal from "./DeductCountModal";
import ScholarshipPayment from "../../assets/images/svg/scholarship-payment.svg";
import products_icon from "../../assets/images/dashboard-menu/icon-products.svg";
import handshake_icon from "../../assets/images/svg/handshake.svg";

const ViewPackPurchaseHistory = () => {
  const tableRef = useRef();
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedUserData = location?.state?.userData;
  const [dataList, setDataList] = useState([]);
  const [selectedPack, setSelectedPack] = useState("");
  const [showDeductModal, setShowDeductModal] = useState(false);
  const [purchaseType, setPurchaseType] = useState([
    { id: "package", name: "Package" },
    { id: "event", name: "Single Event" },
  ]);
  const [selectedType, setSelectedType] = useState("");
  const userType = state?.user?.user?.user_type;
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;

  useEffect(() => {
    getDataList();
  }, [selectedType]);

  useEffect(() => {
    if (selectedPack) {
      setShowDeductModal(true);
    }
  }, [selectedPack]);

  const getDataList = async () => {
    let params = {
      id: selectedUserData?.id,
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      type: selectedType === "All" ? "" : selectedType,
    };
    let response = await getPackPurchaseHistory(dispatch, params);
    if (response?.ok) {
      let dataList = response?.data;
      setDataList(dataList ?? []);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  let columns = [
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 70,
      renderCell: (data) => (
        <>{data?.row?.type ? getTypeValue(data?.row?.type) : "N/A"}</>
      ),
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Cost",
      flex: 1,
      minWidth: 70,
      renderCell: (data) => (
        <>{data?.row?.amount ? "$" + data?.row?.amount.toFixed(2) : "N/A"}</>
      ),
      sortable: false,
    },
    {
      field: "discount",
      headerName: "Discounts",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => {
        return (
          <>
            {data?.row?.scholarship ||
            data?.row?.sketchplay_bucks ||
            data?.row?.discount_code ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {data?.row?.scholarship ? (
                  <>
                    <img
                      src={ScholarshipPayment}
                      style={{ width: 20, height: 20 }}
                    />
                  </>
                ) : null}
                {data?.row?.sketchplay_bucks ? (
                  <>
                    <img
                      src={products_icon}
                      style={{ width: 20, height: 20, marginLeft: 3 }}
                    />
                  </>
                ) : null}
                {data?.row?.discount_code ? (
                  <>
                    <img
                      src={handshake_icon}
                      style={{ width: 20, height: 20, marginLeft: 3 }}
                    />
                  </>
                ) : null}
              </Box>
            ) : (
              "N/A"
            )}
          </>
        );
      },
      sortable: false,
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <>
          {data?.row?.productName
            ? charValidate(data?.row?.productName, 60)
            : "N/A"}
        </>
      ),
      sortable: false,
    },
    {
      field: "purchase_date",
      headerName: "Purchase Date",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <>
          {data?.row?.purchase_date
            ? dateFormat(data?.row?.purchase_date, 60)
            : "N/A"}
        </>
      ),
      sortable: false,
    },
    {
      field: "pack_count",
      headerName: "Package Count",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <>{data?.row?.pack_count ? data?.row?.pack_count + " - Pack" : "N/A"}</>
      ),
      sortable: false,
    },
    {
      field: "currentCount",
      headerName: "Package Remaining",
      flex: 1,
      minWidth: 270,
      renderCell: (data) => {
        return (
          <>
            {data?.row?.type === "package" ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <TextField
                  name="pack"
                  variant="outlined"
                  type="number"
                  onChange={(e) =>
                    handleCountChange(data?.row?.packWalletId, e.target.value)
                  }
                  value={data?.row?.currentCount}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-input": {
                      padding: "10.5px 14px !important",
                      outline: "none",
                    },
                  }}
                />
                <Box sx={{ px: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingX: 5.5, paddingY: 1 }}
                    onClick={() => handleModalOpen(data?.row)}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            ) : (
              "N/A"
            )}
          </>
        );
      },
      sortable: false,
    },
  ];

  const getTypeValue = (typeId) => {
    const data = purchaseType.find((x) => x.id === typeId);
    return data?.name;
  };

  const handleModalClose = (isClosed) => {
    setShowDeductModal(false);
    setSelectedPack("");
    if (!isClosed) {
      getDataList();
    }
  };

  const handleCountChange = (id, newCount) => {
    // Update the rowData with the new count
    const updatedRowData = dataList.map((row) =>
      row.packWalletId === id ? { ...row, currentCount: newCount } : row,
    );
    setDataList(updatedRowData);
  };

  const handleModalOpen = (pack) => {
    const packCurrentCount = parseInt(pack?.currentCount);
    if (packCurrentCount < 0) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Pack count must be greater than to zero",
        },
      });
      return;
    }
    setSelectedPack(pack);
  };

  const filterUI = () => {
    return (
      <>
        <Select
          name="purchaseType"
          onChange={(e) => setSelectedType(e.target.value)}
          value={selectedType}
          input={
            <OutlinedInput sx={{ width: "100%", height: 40, width: 200 }} />
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
              },
            },
          }}
          inputProps={{
            "aria-label": "Without label",
          }}
          className="MuiSelect-select"
          sx={{
            m: 0.5,
            "& .MuiSelect-select": {
              padding: "10.5px 14px",
            },
            width: "100%",
            minWidth: "200px",
            [theme.breakpoints.down("md")]: {
              mx: 0,
              minWidth: "inherit",
            },
          }}
          displayEmpty
          renderValue={(value) => {
            if (value) {
              let selected = purchaseType?.find((type) => type?.id === value);
              if (selected) {
                return selected?.name;
              }
              return "All";
            } else {
              return (
                <Typography
                  variant="p"
                  fontWeight={400}
                  fontSize={14}
                  sx={{ opacity: 0.5 }}
                >
                  Filter by Type...
                </Typography>
              );
            }
          }}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {purchaseType?.map((type) => (
            <MenuItem key={type?.id} value={type?.id}>
              {type?.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Purchase History"}
        gridData={dataList}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        uniqueId={"packWalletId"}
        isLoading={false}
        isAddNewBtnShow={false}
        gridButtons={() => {
          return (
            <>
              {userType === "admin" ? (
                <Box
                  sx={{
                    my: 2,
                    px: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1 }}
                    color="inherit"
                    onClick={() => {
                      navigate(USER_DETAILS_PAGE, {
                        state: {
                          selectedUserData: selectedUserData,
                        },
                      });
                    }}
                  >
                    Back
                  </Button>
                </Box>
              ) : null}
            </>
          );
        }}
        gridToolbarButtons={() => filterUI()}
      />

      {showDeductModal && (
        <DeductCountModal
          open={showDeductModal}
          handleClose={handleModalClose}
          selectedRow={selectedPack}
        />
      )}
    </>
  );
};

export default ViewPackPurchaseHistory;
