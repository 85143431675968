export const APP_MESSAGES = {
    emptySocialPostTitle: "Title is required",
    emptySocialPostContent: "Content is required",
    emptySocialPostPic: "Picture is required",
    emptySocialCommentTitle: "Title is required",
    commentPicMaxSizeTxt: "Maximum file size is 10MB",


    //Work out Module
    emptyWorkoutCatTitle: "Category Name is required",
    emptyWorkoutSubCatTitle: "Sub-Category Name is required",
    emptyWorkoutContentTitle: "This field is required",
    emptyWorkoutContentType: "This field is required",
    emptyWorkoutContentPlanType: "This field is required",
    emptyWorkoutContentDesc: "This field is required",
    emptyWorkoutContentURL: "This field is required",
    emptyWorkoutContentChooseCategoryTitle: "You can't leave this blank.",
    invalidUrlFormatMsg: "Invalid URL format.",
    emptyinviteUserFirstName: "First Name is required",
    emptyinviteUserLastName: "Last Name is required",
    emptyinviteUserEmail: "Email is required",
    emptyinviteUserNo: "Mobile is required",
    emptyemailFormatMsg: "Invalid Email Format",

    //Station Module

    emptyStationTilte: 'Title is required',
    emptyStationDesc: 'Description is required',
    emptyStationReps: "Reps is required",
    emptyStationSets: "Sets is required",

    //Calendar Module

    todaySchedule: "Today Schedule",
    noEventAvailable: "No Events available for Today",
    viewCalendarText: "View Calendar",
    viewMapText: "View Map",
    addEventToCalendarText: "Add an event to the calendar",
    whatAreYouSchedulText: "What are you scheduling ?",
    ScheduleDescText: `Here, you can create many types of events. Multiday camps, single day classes and training, or meetings. just tap this button and you set!`,
    campNameDescText: "Event Name and Description",
    startNEndTimeText: "Enter Start & End Time",
    emptyCampAndDescTitleMsg: 'Name is required',
    enterLocationBtnText: "Enter Location Details",
    enterLocationText: "Enter Locaiton",
    enterCustomDetailBtnText: "Enter Custom Details",
    addLegalWaverBtnText: "Add Legal Waiver",
    generatePrivateSignUpLinkBtnText: "Generate / Create Private Signup link",
    addExcelUploadWithTempBtnText: "Add excel upload\n W/ template to download so its the correct format",
    reviewAndConfirmBtnText: "Review & Confirm",
    chooseStartBtnText: "Choose Start Date",
    chooseEventTimeBtnText: "Choose Start & End Time",
    addAdditionalLegalContentText: 'Additional Legal Content',
    emptyCampAndDescMsg: 'Description is required',
    emptyStartDateMsg: "Start Date is required",
    emptyStartTimeMsg: "Start Time is required",
    emptyEndTimeMsg: "End Time is required",
    emptyTimeZoneMsg: "Time zone is required",
    emptyCostMsg: "Cost is required",
    emptySeatMsg: "Seat is required",
    emptyLegalContentMsg: "Legal Content is required",

    // Change Password Module

    changePassDesc: "You can update your password by entering a new on below and tapping the save button.",
    saveNewPassBtnText: "Save New Password",
    newPasswordFormat: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#?]).{6,}$/,

    // Edit Profile Module

    emptyEditFirstNameMsg: "First Name is required",
    emptyEditLastNameMsg: "Last Name is required",
    emptyEditBioMsg: "Bio is required",
    emptyEditMobMsg: "Mobile is required",
    emptyEditEmailMsg: "Email is required",
    invalidEmailFormatMsg: "Invalid Email Format",

    //Reset Password

    emptyTokenMsg: 'Token is required',
    emptyTempCodeMsg: 'Code is required',
    emptyNewPassMsg: 'Password is required',
    emptyConfirmPassMsg: 'Password is required',
    tempCodeLimitValue: 255,
    newPassLimitValue: 255,
    confirmLimitValue: 255,
    passDontMatchMsg: 'Passwords do not match',
};

export const LIMIT_VALIDATION = {
    socialPostTitleLimit: 50,
    socialPostTitle: "Max 50 Characters is allowed",
    socialPostContentLimit: 2110,
    socialPostContent: "Max 2000 Characters is allowed",
    socialCommentContentLimit: 250,
    socialCommentTitle: "Max 250 Characters is allowed",

    //Work out Module
    workoutCategoryTitleLimit: 50,
    workoutCategoryTitle: "Max 50 Characters is allowed",
    workoutSubCategoryTitleLimit: 50,
    workoutSubCategoryTitle: "Max 50 Characters is allowed",
    WorkoutContentTitleLimit: 50,
    WorkoutContentTitle: "Max 50 Characters is allowed",
    WorkoutContentChooseCategoryLimit: 1,
    WorkoutContentChooseCategoryTitle: "You can't leave this blank.",
    WorkoutContentDescLimit: 250,
    WorkoutContentDescLimitMsg: "Max 250 Characters is allowed",
    UrlFormat: /^(?:(?:https?|http?|ftp?):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:\/\S*)?$/,
    emailFormat: /^\S+@\S+\.\S+$/,

    // invite user Module

    inviteFirstNameLimit: 1,
    inviteFirstNameLimitMsg: "You can't leave this blank.",
    inviteMobileLimit: 10,
    inviteMObileLimitMsg: "Mobile must be at least 10 characters",

    // Station Module

    stationTitleLimit: 50,
    statinTitleLimitMsg: "Max 50 Characters is allowed",
    stationDescLimit: 250,
    statinDescLimitMsg: "Max 250 Characters is allowed",

    //Change Password Module

    emptyCurrentPassMsg: "Current Password is required",
    emptyNewPassMsg: "New Password is required",
    emptyConfirmPassMsg: "Confirm New Password is required",

    oldPassValueLimit: 255,
    oldPassLimitMsg: "Old Password must be at most 255 characters",

    newPassValueLimit: 255,
    newPassLimitMsg: "New Password must be at most 255 characters",

    common255Length: 255,

    // signup password module

    validPasswordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    validPasswordMsg: "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character",
}