import { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useRoutes,
  Navigate,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import config from "@src/config";
import * as pages from "@routes/constants";

import MainLayout from "@layouts/MainLayout";
import MinimalLayout from "@layouts/MinimalLayout";
import Loadable from "@components/Loadable";
import { Subscription } from "../views/subscription";
import AdminUsers from "../views/superAdmin/AdminUsers";
import CoachingStaff from "../views/superAdmin/CoachingStaff";
import SubOrganizations from "../views/superAdmin/SubOrganizations";
import Users from "../views/superAdmin/Users";
import League from "../views/parent/League";
import ManageParent from "../views/parent/ManageParent";
import ManageAthlete from "../views/parent/ManageAthlete";
import Approval from "../views/superAdmin/Approval";
import Support from "../views/superAdmin/Support";
import Teams from "../views/superAdmin/Teams";
import Commerce from "../views/superAdmin/Commerce";
import Performance from "../views/superAdmin/Performance";
import FilmRooms from "../views/superAdmin/FilmRoom";
import Calendars from "../views/superAdmin/Calendars";
import Communitys from "../views/superAdmin/Community";
import SubOrgTeams from "../views/superAdmin/SubOrgTeams";
import Registration from "../views/IYS/Registration";
import RegistrationForGDL from "../views/GDL/Registration";
import IYSPayment from "../views/IYSPayment";
import GDLPayment from "../views/GDLPayment/Index";
import UserSupport from "../views/user-support/UserSupport";
import ManageParentByAthlete from "../views/athlete/ManageParentByAthlete";
import CommingSoon from "../views/comingSoon";
import ManageCoach from "../views/athlete/ManageCoach";
import DashboardForCoach from "../views/dashboard/DashboardForCoach";
import ParentTeams from "../views/parent/ParentTeams";
import AuthChangePassword from "../views/authentication/AuthChangePassword";
import LeagueCoach from "../views/coach/LeagueCoach";
import Camp from "../views/parent/Camp";
import Clinic from "../views/parent/Clinic";
import DailyTraining from "../views/coach/DailyTraining";
import LeagueSuperAdmin from "../views/superAdmin/LeagueSuperAdmin";
import Products from "../views/superAdmin/Products";
import Tournament from "../views/team-manager/Tournament";
import TournamentAddPlayer from "../views/team-manager/TournamentAddPlayer";
import RosterAthleteList from "../views/team-manager/RosterAthleteList";
import PaidRosterAthleteList from "../views/team-manager/PaidRosterAthleteList";
import ParentTournament from "../views/parent/ParentTournament";
import AdminTournament from "../views/superAdmin/AdminTournament";
import TeamManager from "../views/superAdmin/TeamManager";
import TeamCoach from "../views/superAdmin/TeamCoach";
import ComingSoon from "../components/ComingSoon";
import ViewTournamentTeams from "../components/Tournament/ViewTournamentTeams";
import ViewTeamRoster from "../components/Tournament/ViewTeamRoster";
import { Box } from "@mui/material";
import DashboardForAdmin from "../views/dashboard/DashboardForAdmin";
import AdminMessage from "../views/superAdmin/AdminMessage";
import AdminScholarshipProgramApply from "../views/scholarship-program/AdminScholarshipProgramApply";
import ViewAndAddScore from "../components/Tournament/ViewAndAddScore";
import AdminScholarshipList from "../views/scholarship-program/AdminScholarshipList";
import Packages from "../views/packages/Packages";
import Help from "../views/superAdmin/Help";
import DashboardForTeamCoachManager from "../views/dashboard/DashboardForTeamCoachManager";
import OrgSettings from "../views/org-settings/OrgSettings";
import CreateEditCamp from "../views/calendar/camp/CreateEditCamp";
import ProductBuy from "../views/packages/ProductBuy";
import ReportingFinance from "../views/superAdmin/ReportingFinance";
import ViewUserDetails from "../components/Users/ViewUserDetails";
import ViewWalletHistory from "../components/Users/ViewWalletHistory";
import ParentScholarshipProgramApply from "../views/scholarship-program/ParentScholarshipProgramApply";
import ManageGrades from "../views/superAdmin/ManageGrades";
import ManageDivisions from "../views/superAdmin/ManageDivisions";
import ManageSprint from "../views/superAdmin/ManageSprint";
import ViewPackPurchaseHistory from "../components/Users/ViewPackPurchseHistory";
import ManagePositions from "../views/superAdmin/ManagePositions";
import EventRegistrationQR from "../views/calendar/EventRegistrationQR";
import { useState } from "react";
import SendEmailCommunicate from "../views/superAdmin/sendEmail/Index";
import LeagueRegistration from "../views/parent/LeagueRegistration";

const AuthLogin = Loadable(lazy(() => import("@views/authentication")));
const ForgotPassword = Loadable(lazy(() => import("@views/forgotPassword")));
const Signup = Loadable(lazy(() => import("@views/signup")));
const AccessCode = Loadable(lazy(() => import("@views/accessCode")));
const RequestAccessCode = Loadable(
  lazy(() => import("@views/requestAccessCode")),
);
const Dashboard = Loadable(lazy(() => import("@views/dashboard")));
const DashboardForParent = Loadable(
  lazy(() => import("@views/dashboard/DashboardForParent")),
);
const DashboardForAthlete = Loadable(
  lazy(() => import("@views/dashboard/DashboardForAthlete")),
);
const Buckets = Loadable(lazy(() => import("@views/buckets")));
const BucketDetail = Loadable(lazy(() => import("@views/bucketDetail")));
const PlayerContent = Loadable(lazy(() => import("@views/playerContent")));
const FilmRoom = Loadable(lazy(() => import("@views/filmRoom")));
const PaymentStatus = Loadable(lazy(() => import("@views/paymentStatus")));
const SocialWall = Loadable(lazy(() => import("@views/socialWall")));
const SocialWallComment = Loadable(
  lazy(() => import("@views/socialWallComment")),
);
const SocialWallPost = Loadable(lazy(() => import("@views/socialWallPost")));
const SocialWallCommentList = Loadable(
  lazy(() => import("@views/socialWallCommentList")),
);
const CalendarView = Loadable(lazy(() => import("@views/calendar")));
const ContentDetailView = Loadable(lazy(() => import("@views/ContentDetail")));
const ChangePassword = Loadable(
  lazy(() => import("@components/ChangePassword")),
);
const EditProfile = Loadable(lazy(() => import("@components/EditProfile")));
const ManageUser = Loadable(lazy(() => import("@components/ManageUser")));
let socialWallUrl = false;

const routes = (
  isLoggedIn,
  hasAccessCode,
  userType,
  routeToScholarship,
  eventIdParam,
  loginIdEvent,
  isPurchasePage,
) => [
  // Public Routes
  {
    path: pages.ROOT_PAGE,
    element: isLoggedIn ? (
      hasAccessCode ? (
        routeToScholarship === "true" ? (
          <Navigate to={pages.Apply_Scholarship_Program} />
        ) : userType === "player" ? (
          loginIdEvent ? (
            <Navigate
              to={`${pages.CALENDAR_PAGE}?calendar_view=SPRINT&event_id=${loginIdEvent}`}
            />
          ) : (
            <Navigate to={pages.DASHBOARD_PAGE_ATHLETE} />
          )
        ) : userType === "parent" ? (
          loginIdEvent ? (
            <Navigate
              to={`${pages.CALENDAR_PAGE}?calendar_view=SPRINT&event_id=${loginIdEvent}`}
            />
          ) : (
            <Navigate to={pages.DASHBOARD_PAGE_PARENT} />
          )
        ) : userType === "coach" ? (
          <Navigate to={pages.DASHBOARD_PAGE_COACH} />
        ) : userType === "teammanager" || userType === "teamcoach" ? (
          <Navigate to={pages.DASHBOARD_PAGE_TEAM_MANAGER_COACH} />
        ) : userType === "admin" ? (
          <Navigate to={pages.DASHBOARD_PAGE_ADMIN} />
        ) : (
          <Navigate to={pages.DASHBOARD_PAGE} />
        )
      ) : (
        <Navigate to={pages.ACCESS_CODE_PAGE} />
      )
    ) : (
      <MinimalLayout />
    ),
    children: [
      {
        path: pages.ROOT_PAGE,
        element:
          eventIdParam && !isPurchasePage ? (
            <Navigate
              to={`${pages.Event_Registration}?event_id=${eventIdParam}`}
            />
          ) : (
            <AuthLogin />
          ),
      },
      {
        path: pages.SIGNUP_PAGE,
        element: <Signup />,
      },
      {
        path: pages.AUTH_CHANGE_PASSWORD_PAGE,
        element: <AuthChangePassword />,
      },
      {
        path: pages.FORGOT_PAGE,
        element: <ForgotPassword />,
      },
      {
        path: pages.REGISTER_PAGE,
        element: <Registration />,
      },
      {
        path: pages.GDL_REGISTER_PAGE,
        element: <RegistrationForGDL />,
      },
      {
        path: pages.Product_Buy,
        element: <ProductBuy />,
      },
      {
        path: pages.Event_Registration,
        element: <EventRegistrationQR />,
      },
    ],
  },

  // Private Routes
  {
    path: pages.ROOT_PAGE,
    element: isLoggedIn ? (
      hasAccessCode ? (
        <MainLayout />
      ) : (
        <Navigate to={pages.ACCESS_CODE_PAGE} />
      )
    ) : (
      <Navigate to={pages.ROOT_PAGE} />
    ),
    children: [
      {
        path: pages.DASHBOARD_PAGE,
        element: <Dashboard />,
      },
      {
        path: pages.DASHBOARD_PAGE_ADMIN,
        element: <DashboardForAdmin />,
      },
      {
        path: pages.DASHBOARD_PAGE_PARENT,
        element: <DashboardForParent />,
      },
      {
        path: pages.DASHBOARD_PAGE_ATHLETE,
        element: <DashboardForAthlete />,
      },
      {
        path: pages.DASHBOARD_PAGE_COACH,
        element: <DashboardForCoach />,
      },
      {
        path: pages.BUCKET_PAGE,
        element: <Buckets />,
      },
      {
        path: pages.BUCKET_DETAIL_PAGE,
        element: <BucketDetail />,
      },
      {
        path: pages.COACH_PLAYER_CONTENT_PAGE,
        element: <PlayerContent />,
      },
      {
        path: pages.PLAYER_CONTENT_DETAIL_PAGE,
        element: <ContentDetailView />,
      },
      {
        path: pages.PLAYER_FILM_ROOM_PAGE,
        element: <FilmRoom />,
      },
      {
        path: pages.FILM_ROOM_PAYMENT_STATUS,
        element: <PaymentStatus />,
      },
      {
        path: pages.SUBSCRIPTION_PAGE,
        element: <Subscription />,
      },
      {
        path: pages.CALENDAR_PAGE,
        element: <CalendarView />,
      },
      {
        path: pages.MANAGEUSER_PAGE,
        element: <ManageUser />,
      },
      {
        path: pages.CHANGE_PASSWORD_PAGE,
        element: <ChangePassword />,
      },
      {
        path: pages.EDIT_PROFILE_PAGE,
        element: <EditProfile />,
      },
      {
        path: pages.ADMIN_USERS_PAGE,
        element: <AdminUsers />,
      },
      {
        path: pages.COACHING_STAFF_PAGE,
        element: <CoachingStaff />,
      },
      {
        path: pages.SUB_ORGANIZATIONS_PAGE,
        element: <SubOrganizations />,
      },
      {
        path: pages.SUB_ORGANIZATIONS_TEAM_PAGE,
        element: <SubOrgTeams />,
      },
      {
        path: pages.USERS_PAGE,
        element: <Users />,
      },
      {
        path: pages.USER_DETAILS_PAGE,
        element: <ViewUserDetails />,
      },
      {
        path: pages.USER_WALLET_HISTORY_PAGE,
        element: <ViewWalletHistory />,
      },
      {
        path: pages.League_Page,
        element: <LeagueRegistration />,
      },
      {
        path: pages.Tournament_Page,
        element: (
          <Box sx={{ pt: 5, textAlign: "center" }}>
            <ComingSoon />
          </Box>
        ),
      },
      {
        path: pages.Tournament_Teams_Page,
        element: <Tournament />,
      },
      {
        path: pages.Parent_Tounament_Page,
        element: <ParentTournament />,
      },
      {
        path: pages.Tournament_Add_Player_Page,
        element: <TournamentAddPlayer />,
      },
      {
        path: pages.Tournament_Roster_Athlete_List,
        element: <RosterAthleteList />,
      },
      {
        path: pages.Tournament_Paid_Roster_Athlete_List,
        element: <PaidRosterAthleteList />,
      },
      {
        path: pages.Manage_Parent_Page,
        element: <ManageParent />,
      },
      {
        path: pages.Manage_Athlete_Page,
        element: <ManageAthlete />,
      },
      {
        path: pages.Manage_Parent_BY_ATHLETE_Page,
        element: <ManageParentByAthlete />,
      },
      {
        path: pages.Team_Page,
        element: <ParentTeams />,
      },
      {
        path: pages.APPROVAL_PAGE,
        element: <Approval />,
      },
      {
        path: pages.SUPPORT_PAGE,
        element: <Support />,
      },
      {
        path: pages.TEAMS_PAGE,
        element: <Teams />,
      },
      {
        path: pages.COMMERCE_PAGE,
        element: <Commerce />,
      },
      {
        path: pages.PERFORMANCE_PAGE,
        element: <Performance />,
      },
      {
        path: pages.FILMROOM_PAGE,
        element: <FilmRooms />,
      },
      {
        path: pages.CALENDARS_PAGE,
        element: <Calendars />,
      },
      {
        path: pages.COMMUNITYS_PAGE,
        element: <Communitys />,
      },
      {
        path: pages.PLAYER_PARENT_SUPPORT_PAGE,
        element: <UserSupport />,
      },
      {
        path: pages.Manage_COACH,
        element: <ManageCoach />,
      },
      {
        path: pages.League_Coach_Page,
        element: <LeagueCoach />,
      },
      {
        path: pages.Camp_Page,
        element: <Camp />,
      },
      {
        path: pages.Clinic_Page,
        element: <Clinic />,
      },
      {
        path: pages.Daily_Training_Page,
        element: <DailyTraining />,
      },
      {
        path: pages.LEAGUE_SUPERADMIN,
        element: <LeagueSuperAdmin />,
      },
      {
        path: pages.PRODUCT_PAGE,
        element: <Products />,
      },
      {
        path: pages.Admin_Tournament_PAGE,
        element: <AdminTournament />,
      },
      {
        path: pages.Admin_Tournament_Team_PAGE,
        element: <ViewTournamentTeams />,
      },
      {
        path: pages.Team_Coach,
        element: <TeamCoach />,
      },
      {
        path: pages.Team_Manager,
        element: <TeamManager />,
      },
      {
        path: pages.Tournament_Team_Roster,
        element: <ViewTeamRoster />,
      },
      {
        path: pages.Admin_Communicate,
        element: <AdminMessage />,
      },
      {
        path: pages.Apply_Scholarship_Program,
        element: <AdminScholarshipProgramApply />,
      },
      {
        path: pages.Parent_Apply_Scholarship_Program,
        element: <ParentScholarshipProgramApply />,
      },
      {
        path: pages.View_And_Add_Score,
        element: <ViewAndAddScore />,
      },
      {
        path: pages.Scholarship_Request,
        element: <AdminScholarshipList />,
      },
      {
        path: pages.Package_List,
        element: <Packages />,
      },
      {
        path: pages.Help_Page,
        element: <Help />,
      },
      {
        path: pages.DASHBOARD_PAGE_TEAM_MANAGER_COACH,
        element: <DashboardForTeamCoachManager />,
      },
      {
        path: pages.Org_Settings,
        element: <OrgSettings />,
      },
      {
        path: pages.CREATE_CAMP,
        element: <CreateEditCamp />,
      },
      {
        path: pages.Reporting_Finance,
        element: <ReportingFinance />,
      },
      {
        path: pages.Manage_Grades,
        element: <ManageGrades />,
      },
      {
        path: pages.Manage_Divisions,
        element: <ManageDivisions />,
      },
      {
        path: pages.Manage_Sprints,
        element: <ManageSprint />,
      },
      {
        path: pages.USER_PACK_PURCHASE_HISTORY_PAGE,
        element: <ViewPackPurchaseHistory />,
      },
      {
        path: pages.Manage_Positions,
        element: <ManagePositions />,
      },
      {
        path: pages.Send_Email_Communicate,
        element: <SendEmailCommunicate />,
      },
    ],
  },
  {
    path: pages.IYS_PAYMENT_PAGE,
    element: <IYSPayment />,
  },
  {
    path: pages.GDL_PAYMENT_PAGE,
    element: <GDLPayment />,
  },
  {
    path: pages.PLAYER_SOCIAL_WALL_PAGE,
    element:
      socialWallUrl || isLoggedIn ? (
        <SocialWall />
      ) : (
        <Navigate to={pages.ROOT_PAGE} />
      ),
  },
  {
    path: pages.PLAYER_SOCIAL_COMMENT_PAGE,
    element: isLoggedIn ? (
      <SocialWallComment />
    ) : (
      <Navigate to={pages.ROOT_PAGE} />
    ),
  },
  {
    path: pages.PLAYER_SOCIAL_COMMENT_REPLY_PAGE,
    element: isLoggedIn ? (
      <SocialWallComment />
    ) : (
      <Navigate to={pages.ROOT_PAGE} />
    ),
  },
  {
    path: pages.PLAYER_SOCIAL_POST_PAGE,
    element: isLoggedIn ? (
      <SocialWallPost />
    ) : (
      <Navigate to={pages.ROOT_PAGE} />
    ),
  },
  {
    path: pages.PLAYER_SOCIAL_COMMENT_LIST_PAGE,
    element: isLoggedIn ? (
      <SocialWallCommentList />
    ) : (
      <Navigate to={pages.ROOT_PAGE} />
    ),
  },

  // Access Code Routes
  {
    path: pages.ROOT_PAGE,
    element:
      isLoggedIn && !hasAccessCode ? (
        <MinimalLayout />
      ) : (
        <Navigate to={pages.ROOT_PAGE} />
      ),
    children: [
      {
        path: pages.ACCESS_CODE_PAGE,
        element: <AccessCode />,
      },
      {
        path: pages.REQUEST_ACCESS_CODE_PAGE,
        element: <RequestAccessCode />,
      },
    ],
  },
  {
    path: pages.Product_Buy,
    element: <MinimalLayout />,
    children: [
      {
        path: pages.Product_Buy,
        element: <ProductBuy />,
      },
    ],
  },
  {
    path: pages.Event_Registration,
    element: <MinimalLayout />,
    children: [
      {
        path: pages.Event_Registration,
        element: <EventRegistrationQR />,
      },
    ],
  },
  // 404 Routes
  {
    path: "*",
    element: <Navigate to={pages.ROOT_PAGE} />,
  },
];

const Routes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let routeToScholarship = localStorage.getItem("redirectScholarship");
  useEffect(() => {
    if (routeToScholarship === "true") {
      navigate(pages.Apply_Scholarship_Program + "?isnewentry=true");
    }
  }, [routeToScholarship]);

  const isLoggedIn = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const userType = useSelector((state) => state.user?.user?.user_type);
  const hasAccessCode = user?.has_org_approval || false;
  const currentUrl = window.location.pathname;
  const isPurchasePage = location?.state?.isPurchasePage;
  const [urlEventSearchParams] = useSearchParams();
  const eventIdParam = urlEventSearchParams.get("event_id") ?? null;
  if (eventIdParam && isLoggedIn) {
    localStorage.setItem("loginEventId", eventIdParam);
  }
  // const [eventIdVal, setEventIdVal] = useState(
  //   eventIdParam && !isLoggedIn ? eventIdParam : null,
  // );
  const loginEvent_Id = localStorage.getItem("loginEventId") ?? "";
  if (currentUrl == "/social-wall") {
    const token = urlEventSearchParams.get("token");
    const orgToken = urlEventSearchParams.get("orgToken");
    if (token && orgToken) {
      socialWallUrl = true;
    }
  } else {
    const moveParam = urlEventSearchParams.get("redirectScholarship");
    if (moveParam) {
      localStorage.setItem("redirectScholarship", true);
      navigate(pages.Apply_Scholarship_Program + "?isnewentry=true");
    }
  }

  return useRoutes(
    routes(
      isLoggedIn,
      hasAccessCode,
      userType,
      routeToScholarship,
      eventIdParam,
      loginEvent_Id,
      isPurchasePage,
    ),
    config.basename,
  );
};

export default Routes;
