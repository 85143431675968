import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import SuccessCheck from "../../assets/images/success_check.png";

const SuccessPopup = ({
  open,
  handleClose,
  title = "",
  secondaryTitle = "",
  message,
  secondaryMessage = "",
  isTitleShow = true,
  isShowCloseBtn = true,
  isShowSubTitle = false,
  alignCenterSecondaryTitle = false,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {title && (
        <DialogTitle sx={{ p: 0 }}>
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            py={3}
            ml={3}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {title}
          </Typography>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          pt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: secondaryTitle ? 5 : 12,
        }}
      >
        {secondaryTitle && (
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize={20}
            py={5}
            //ml={3}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{ textAlign: alignCenterSecondaryTitle ? "center" : "inherit" }}
          >
            {secondaryTitle}
          </Typography>
        )}
        {message && (
          <Typography
            sx={{ py: 5, textAlign: "center" }}
            fontFamily={"Poppins"}
            fontSize={20}
            fontWeight={500}
            color={theme?.palette?.text?.darkTextSubHeader}
          >
            {message}
          </Typography>
        )}
        <img src={SuccessCheck} style={successImgStyle} />
      </DialogContent>
      {isShowSubTitle && (
        <Typography
          sx={{ py: 8, textAlign: "center" }}
          fontFamily={"Poppins"}
          fontSize={12}
          fontWeight={500}
          color={theme?.palette?.text?.darkTextSubHeader}
        >
          {secondaryMessage
            ? secondaryMessage
            : "Tap anywhere out of this popup to go to your dashboard"}
        </Typography>
      )}
      {isShowCloseBtn && (
        <DialogActions sx={{ pr: 4, pb: 3 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const successImgStyle = {
  maxWidth: 100,
};

export default SuccessPopup;
