// route constant
export const ROOT_PAGE = "/";
export const FORGOT_PAGE = "/forgot-password";
export const SIGNUP_PAGE = "/signup";
export const REGISTER_PAGE = "/iysregistration";
export const GDL_REGISTER_PAGE = "/gdlregistration";
export const IYS_PAYMENT_PAGE = "/iyspayments";
export const GDL_PAYMENT_PAGE = "/gdlpayments";
export const ACCESS_CODE_PAGE = "/access-code";
export const REQUEST_ACCESS_CODE_PAGE = "/request-access-code";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const AUTH_CHANGE_PASSWORD_PAGE = "/auth-change-password";
export const EDIT_PROFILE_PAGE = "/edit-profile";
export const MANAGEUSER_PAGE = "/manage-user";
export const DASHBOARD_PAGE = "/dashboard";
export const DASHBOARD_PAGE_PARENT = "/dashboard-parent";
export const DASHBOARD_PAGE_ATHLETE = "/dashboard-athlete";
export const DASHBOARD_PAGE_COACH = "/dashboard-coach";
export const DASHBOARD_PAGE_ADMIN = "/dashboard-admin";
export const BUCKET_PAGE = "/buckets";
export const BUCKET_DETAIL_PAGE = "/bucket/:id";
export const COACH_PLAYER_CONTENT_PAGE = "/player-content";
export const PLAYER_FILM_ROOM_PAGE = "/film-room";
export const FILM_ROOM_PAYMENT_STATUS = "/payment-status";
export const PLAYER_SOCIAL_WALL_PAGE = "/social-wall";
export const PLAYER_SOCIAL_COMMENT_PAGE = "/social-wall-comment/:type/:id";
export const PLAYER_SOCIAL_COMMENT_REPLY_PAGE =
  "/social-wall-comment/:type/:id/:postId";
export const PLAYER_SOCIAL_POST_PAGE = "/social-wall-post";
export const PLAYER_SOCIAL_COMMENT_LIST_PAGE =
  "/social-wall-comment-list/:postId";
export const PLAYER_CONTENT_DETAIL_PAGE = "/content-detail";
export const PLAYER_PARENT_SUPPORT_PAGE = "/user-support";
export const DASHBOARD_PAGE_TEAM_MANAGER_COACH =
  "/dashboard-team-manager-or-team-coach";

//Calendar
export const CALENDAR_PAGE = "/calendar";
export const SUBSCRIPTION_PAGE = "/subscription";
export const CREATE_CAMP = `${CALENDAR_PAGE}/create-camp`;
export const Event_Registration = "/event-registration";

//Super Admin
export const ADMIN_USERS_PAGE = "/admin-users";
export const COACHING_STAFF_PAGE = "/coaching-staff";
export const SUB_ORGANIZATIONS_PAGE = "/sub-organizations";
export const USERS_PAGE = "/users";
export const USER_DETAILS_PAGE = "/user-details";
export const USER_WALLET_HISTORY_PAGE = "/user-details/wallet-hitory";
export const USER_PACK_PURCHASE_HISTORY_PAGE =
  "/user-details/pack-purchase-hitory";
export const APPROVAL_PAGE = "/approval";
export const SUPPORT_PAGE = "/support";
export const TEAMS_PAGE = "/teams";
export const COMMERCE_PAGE = "/commerce";
export const PERFORMANCE_PAGE = "/performance";
export const FILMROOM_PAGE = "/film_room";
export const CALENDARS_PAGE = "/calendars";
export const COMMUNITYS_PAGE = "/communitys";
export const SUB_ORGANIZATIONS_TEAM_PAGE = "/sub-organizations-team";
export const LEAGUE_SUPERADMIN = "/league-admin";
export const PRODUCT_PAGE = "/products";
export const Admin_Tournament_PAGE = "/admin-tournament";
export const Admin_Tournament_Team_PAGE = "/admin-tournament-team/:id";
export const Admin_Communicate = "/admin-messages";
export const View_And_Add_Score = "/view-and-add-score/:id";
export const Help_Page = "/help";
export const Reporting_Finance = "/reporting-finance";
export const Manage_Grades = "/manage-grades";
export const Manage_Divisions = "/manage-divisions";
export const Manage_Sprints = "/manage-sprints";
export const Manage_Positions = "/manage-positions";
export const Send_Email_Communicate = "/send-emails";

//Parent
export const League_Page = "/league";
export const Manage_Parent_Page = "/manage_parent";
export const Manage_Athlete_Page = "/manage_athlete";
export const Manage_Parent_BY_ATHLETE_Page = "/manage-parent-by-athlete";
export const Manage_COACH = "/manage-coach";
export const Team_Page = "/parent-team";
export const Camp_Page = "/camp";
export const Clinic_Page = "/clinic";
export const Parent_Tounament_Page = "/parent-tournament";
export const Tournament_Team_Roster =
  "/admin-tournament/admin-tournament-team/team-roster/:teamId";

//Coach
export const League_Coach_Page = "/league-coach";
export const Daily_Training_Page = "/daily-training";

//team manager
export const Tournament_Page = "/tournaments";
export const Tournament_Teams_Page = "/all-teams";
export const Tournament_Add_Player_Page = "/tournament/add-player";
export const Tournament_Roster_Athlete_List = "/tournament/team-rosters";
export const Tournament_Paid_Roster_Athlete_List =
  "/tournament/team-rosters/paid-athletes";
export const Team_Coach = "/team-coaches";
export const Team_Manager = "/team-managers";

// Scholarship Program
export const Apply_Scholarship_Program = "/apply-for-scholarship-program";
export const Scholarship_Request = "/all-scholarship-request";
export const Parent_Apply_Scholarship_Program =
  "/apply-for-parent-scholarship-program";

// Package
export const Package_List = "/packages";
export const Product_Buy = "/buy-product";

// Organization Settings
export const Org_Settings = "/org-settings";
