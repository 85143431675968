import {
    Box,
    Button,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Typography,
    useTheme, InputAdornment, IconButton
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomTextField";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const PackageSession = ({ handleClose, setStep, formData, setFormData }) => {
    const theme = useTheme();
    const [allowRenew, setAllowRenew] = useState("1")

    const CustomSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: "#00B60D",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const validationSchema = Yup.object({
        cost: Yup.number().required("Cost is required"),
        session_per_package: Yup.number().required("Session Per Package is required"),
        category: Yup.string(),
    });

    const handleSave = (values) => {
        const session_permission = {}
        const auto_renew = allowRenew === '1' ? true : false
        setFormData((prevStage) => {
            return {
                ...prevStage,
                ...values,
                auto_renew,
                session_permission
            };
        });
        setStep(4)
    }

    const formik = useFormik({
        initialValues: {
            cost: formData?.cost || 0,
            session_per_package: formData?.session_per_package || 0,
            category: "",
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });
    const state = useSelector((state) => state);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                    <CustomInputLabel htmlFor="cost-id">
                        Cost
                    </CustomInputLabel>
                    <CustomTextField
                        id="cost-id"
                        name="cost"
                        type="number"
                        placeholder="Enter Cost..."
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cost}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: 350,
                                boxShadow: "none",
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ ml: 1 }}>
                                    $
                                </InputAdornment>
                            ),
                        }}
                    />
                    {Boolean(formik.touched.cost && formik.errors.cost) && (
                        <FormHelperText error>{formik.errors.cost}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6} mt={1}>
                    <CustomInputLabel htmlFor="org-filter">
                        Allow Auto-Renew
                    </CustomInputLabel>
                    <Select
                        name="subOrg"
                        labelId="org-filter"
                        value={allowRenew}
                        onChange={(event) => setAllowRenew(event.target.value)}
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Yes or No"
                                sx={{ maxWidth: 350, width: "100%", height: 45 }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                    >
                        <MenuItem value="1"> Yes</MenuItem>
                        <MenuItem value="2"> No</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={6} mt={1}>
                    <CustomInputLabel htmlFor="session_per_package">Session Per Package</CustomInputLabel>
                    <CustomTextField
                        id="session_per_package"
                        name="session_per_package"
                        placeholder="Enter session per package..."
                        variant="outlined"
                        size="small"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.session_per_package}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: 350,
                                boxShadow: "none",
                            },
                        }}
                    />
                    {Boolean(formik.touched.session_per_package && formik.errors.session_per_package) && (
                        <FormHelperText error>{formik.errors.session_per_package}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6} mt={1}>
                </Grid>
                {/* <Grid item xs={6} mt={2}>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        fontSize={17}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Session Permissions
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight={400}
                        fontSize={11}
                        color={theme?.palette?.text?.containerTextLabel}
                        sx={{ mt: 1, letterSpacing: "0.1px" }}
                    >
                        You can break off the package sessions based on category's you've created for products.
                        When a user looks at the schedule and sees an event they want to book, in order to register,
                        they will need a package session to use for particular event.
                    </Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                        <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize={14}
                            color={theme?.palette?.text?.containerTextLabel}
                            sx={{ mr: 2, letterSpacing: "0.1px" }}
                        >
                            Open to All
                        </Typography>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={true}
                                    sx={{ m: 1 }}
                                />
                            }
                            label=""
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        fontWeight={400}
                        fontSize={11}
                        color={theme?.palette?.text?.containerTextLabel}
                        sx={{ mt: 1, letterSpacing: "0.1px" }}
                    >
                        Toggling this on will allow this package to be used
                        for all categories.
                    </Typography>
                </Grid>
                <Grid item xs={4} mt={2}>
                    <CustomInputLabel htmlFor="categories">
                        CATEGORY
                    </CustomInputLabel>
                    <Select
                        name="categories"
                        labelId="categories"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.categories}
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Category"
                                sx={{ maxWidth: 350, width: "100%", height: 45 }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                        renderValue={(value) => {
                            if (value) {
                                let selected = productCategoriesList?.find((cat) => cat?.id === value);
                                if (selected) {
                                    return selected?.title;
                                }
                            } else {
                                return (
                                    <Typography
                                        variant="p"
                                        fontWeight={400}
                                        fontSize={14}
                                        sx={{ opacity: 0.5 }}
                                    >
                                        Select Category...
                                    </Typography>
                                );
                            }
                        }}
                    >
                        {productCategoriesList?.map((cat) => (
                            <MenuItem key={cat?.id} value={cat?.id}>
                                {cat?.title}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(formik.touched.categories && formik.errors.categories) && (
                        <FormHelperText error>{formik.errors.categories}</FormHelperText>
                    )}
                    <Box
                        sx={{ height: 200, overflowY: "auto", overflowX: "none" }}
                        mt={2}
                        bgcolor="#F4F4F4"
                    >
                        {productCategoriesList?.length ? (
                            productCategoriesList?.map((cat) => (
                                <Box key={cat?.id}>
                                    <Box display="flex" alignItems="center">
                                        <Checkbox
                                            sx={{
                                                color: "#00B60D",
                                                "&.Mui-checked": {
                                                    color: "#00B60D",
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            fontWeight={400}
                                            fontSize={13}
                                            color={theme?.palette?.text?.containerTextLabel}
                                            sx={{ letterSpacing: "0.1px" }}
                                        >
                                            {cat?.title}
                                        </Typography>
                                    </Box>
                                    <Box
                                        width={1}
                                        height={"1px"}
                                        bgcolor={"gray"}
                                        sx={{ opacity: 0.25 }}
                                    ></Box>
                                </Box>
                            ))
                        ) : (
                            <Typography
                                variant="h6"
                                fontWeight={400}
                                fontSize={13}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{ letterSpacing: "0.1px", textAlign: "center", mt: 7 }}
                            >
                                No Team
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={2} sx={{ mt: 5 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                        onClick={() => setStep(5)}
                    >
                        +
                    </Button>
                </Grid> */}
            </Grid>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mt: 4 }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                    color="inherit"
                    onClick={() => handleClose(true)}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                    color="inherit"
                    onClick={() => setStep(2)}
                >
                    Back
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                >
                    Next
                </Button>
            </Box>
        </form>
    );
};

export default PackageSession;