import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
    styled, Checkbox
} from "@mui/material";
import UserInfoForm from "../../components/AppForms/UserInfoForm";


const AddEditParentGuardian = ({ isEdit, formik }) => {

    return (
        <>
            <Box className="guardian-form-box" sx={{
                '&.guardian-form-box div': {
                    maxWidth: '100%',
                }
            }}>
                <UserInfoForm formik={formik} isEmailPhoneDisabled={isEdit} />
            </Box>

            <Divider sx={{ mt: 4, mb: 4 }}></Divider>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="h2" sx={{ color: "#3B3B3B" }}>
                    Permissions
                </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Box
                    sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        mb: 4,
                        "& .MuiTypography-root": {
                            color: "#3B3B3B",
                            textAlign: 'left',
                        },
                        "& .MuiSwitch-thumb": {
                            background: "#fff",
                            height: "13px",
                            width: "13px",
                            marginTop: "5px",
                        },
                        "& .MuiSwitch-track": {
                            background: "#C9C9C9",
                            opacity: "1",
                            height: "18px",
                            borderRadius: "50px",
                        },
                        "& .MuiSwitch-switchBase": {
                            left: "6px",
                            "&.Mui-checked": {
                                transform: "translateX(15px)",
                            },
                            "&.Mui-checked+.MuiSwitch-track": {
                                background: "#00B60D",
                                opacity: "1",
                            },
                        },
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                name="filmRoom"
                                checked={formik.values.filmRoom}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Allow this user to manage Film Room"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="calendar"
                                checked={formik.values.calendar}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Allow this user to manage Calendar"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="community"
                                checked={formik.values.community}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Allow this user to manage Community"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="manageAthletes"
                                checked={formik.values.manageAthletes}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Allow this user to manage athletes"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="manageSubscription"
                                checked={formik.values.manageSubscription}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Allow this user to manage subscriptions and packages"
                    />
                </Box>
            </Grid>
            <Divider sx={{ mt: 4, mb: 4 }}></Divider>
        </>
    );
};

export default AddEditParentGuardian;
