import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SUCCESS_ERROR_POPUP,
  addCoachAction,
  getAllSubOrg,
  getSubOrgTeams,
} from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import CustomSwitch from "../Common/Inputs/Switch";
import { useEffect } from "react";
import { convertToCapsOfFirstLetter } from "@utils/functions";

const AddEditCoachingStaff = ({ open, handleClose, editData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [subOrgsList, setSubOrgs] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState([]);
  const [subOrgTeamsList, setSubOrgTeamsList] = useState([]);
  const [selectedSubOrgTeams, setSelectedSubOrgTeams] = useState([]);
  const [inactiveCoach, setInactiveCoach] = useState(false);
  const [isInitialMount, setInitialMount] = useState(true);
  const [selectAllAccessPermission, setSelectAllAccessPermission] = useState(false);

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
    primarySubOrg: Yup.string().required("Primary Sub-Organization is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: editData?.first_name ?? "",
      lastName: editData?.last_name ?? "",
      email: editData?.email ?? "",
      mobile: editData?.mobile ?? "",
      countryCode: editData?.countryCode ?? "",
      primarySubOrg: editData?.primary_org ?? ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParam = {
        id: editData?.id ?? undefined,
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        email: values?.email ?? "",
        countryCode: values?.countryCode ? values?.countryCode?.toUpperCase() : "US",
        mobile: values?.mobile ?? "",
        sub_organization: selectedOrgType ?? [],
        team: selectedSubOrgTeams ?? [],
        primary_org: values?.primarySubOrg,
        is_active: inactiveCoach
      };
      addCoach(bodyParam);
    },
  });
  const state = useSelector((state) => state);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  useEffect(() => {
    setInactiveCoach(editData?.is_active || false)
  }, []);

  const addCoach = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await addCoachAction(dispatch, params, bodyParams);
    if (response?.ok) {
      handleClose(false);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong, try again latter" }
      })
    }
  };

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: 0,
      limit: 1000
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      setSubOrgs(response?.data?.data ?? []);
    }
  };

  const handleChangeOrgType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrgType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleChangeSubOrgTeam = (teamId) => {
    // removing teamId from list when unchecked on if condition true otherwise adding
    if (selectedSubOrgTeams?.includes(teamId)) {
      let teamIds = selectedSubOrgTeams?.filter((id) => id !== teamId);
      setSelectedSubOrgTeams(teamIds);
    } else {
      setSelectedSubOrgTeams([...selectedSubOrgTeams, teamId]);
    }
  };

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  useEffect(() => {
    if (selectedOrgType?.length) {
      getSubOrgTeamsList([...selectedOrgType]);
    }
  }, [selectedOrgType]);

  useEffect(() => {
    if (subOrgsList?.length) {
      if (selectAllAccessPermission) {
        let subOrgIds = [];
        subOrgsList?.forEach((subOrg) => subOrgIds?.push(subOrg?.id));
        setSelectedOrgType(subOrgIds);
      } else {
        setSelectedOrgType([]);
      }
    }
  }, [selectAllAccessPermission, subOrgsList]);

  useEffect(() => {
    if (subOrgTeamsList?.length) {
      if (selectAllAccessPermission) {
        let subOrgTeamIds = [];
        subOrgTeamsList?.forEach((team) => subOrgTeamIds?.push(team?.id));
        setSelectedSubOrgTeams(subOrgTeamIds);
      } else {
        if (!selectedOrgType?.length) {
          setSubOrgTeamsList([]);
          setSelectedSubOrgTeams([]);
        }
      }
    }
  }, [selectAllAccessPermission, subOrgTeamsList, selectedOrgType]);

  useEffect(() => {
    if (!selectedOrgType?.length) {
      setSelectAllAccessPermission(false);
    }
  }, [selectedOrgType]);

  useEffect(() => {
    // when all sub-org and sub-org team checked manually then enable select all switch
    if (
      subOrgsList?.length === selectedOrgType?.length &&
      subOrgTeamsList?.length === selectedSubOrgTeams?.length &&
      !isInitialMount
    ) {
      setSelectAllAccessPermission(true);
    }
  }, [subOrgsList, subOrgTeamsList, selectedOrgType, selectedSubOrgTeams]);

  const getSubOrgTeamsList = async (subOrgId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getSubOrgTeams(params, subOrgId);
    if (response?.ok) {
      setSubOrgTeamsList(response?.data ?? []);
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={0}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: "column",
              alignItems: "start"
            }
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{
              p: 1,
              [theme.breakpoints.down('md')]: {
                p: 0,
                mb: 1
              }
            }}
          >
            Coaching Staff
          </Typography>
          <Box position="relative" sx={{ cursor: "pointer" }}>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={inactiveCoach}
                  onChange={(e) => setInactiveCoach(e?.target?.checked)}
                  size="sm"
                  sx={{ m: 1 }}
                />
              }
              label="Inactivate Coach"
              labelPlacement="start"
              sx={{
                "&.MuiFormControlLabel-root": {
                  ml: 0
                },
                "& .MuiTypography-root": {
                  color: theme?.palette?.text?.containerTextLabel,
                  fontWeight: 400,
                  fontFamily: "inherit",
                },
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{
          p: 4,
          [theme.breakpoints.down('md')]: {
            p: 3,
          }
        }}>
          <Typography
            variant="h5"
            fontWeight={700}
            fontSize={17}
            color={theme?.palette?.text?.containerTextLabel}
            mb={1.5}
          >
            Coach Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="first-name" sx={inputLabelStyle}>
                First Name
              </CustomInputLabel>
              <CustomTextField
                id="first-name"
                name="firstName"
                placeholder="Enter first name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
                value={formik.values.firstName}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.firstName && formik.errors.firstName) && (
                <FormHelperText error>{formik.errors.firstName}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="last-name" sx={inputLabelStyle}>Last Name</CustomInputLabel>
              <CustomTextField
                id="last-name"
                name="lastName"
                placeholder="Enter last name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'lastName')}
                value={formik.values.lastName}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.lastName && formik.errors.lastName) && (
                <FormHelperText error>{formik.errors.lastName}</FormHelperText>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="email" sx={inputLabelStyle}>Email Address</CustomInputLabel>
              <CustomTextField
                id="email"
                name="email"
                placeholder="Enter email..."
                variant="outlined"
                size="small"
                type="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email.toLowerCase()}
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="mobile-number" sx={inputLabelStyle}>
                Mobile Number
              </CustomInputLabel>
              <Box
                sx={{
                  "&.MuiFormControl-root": {
                    width: '100%',
                  },
                  "& .react-tel-input .form-control": {
                    minWidth: "100%",
                    fontWeight: 500,
                    background: theme?.palette?.grey[50],
                    height: "42px",
                  },
                }}
              >
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  placeholder="(702) 123-4567"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onChange={(value, country) => {
                    formik.setFieldValue("countryCode", country?.countryCode || "");
                    formik.setFieldValue("mobile", value || "");
                  }}
                  error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  size="sm"
                  style={{
                    width: "100%",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                      width: "100%",
                    },
                  }}
                  disableDropdown={true}
                  disableCountryCode={true}
                  onlyCountries={["us"]}
                />
                {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                  <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="primary-sub-org" sx={inputLabelStyle}>
                Primary Sub-Organization
              </CustomInputLabel>
              <Select
                name="primarySubOrg"
                labelId="primary-sub-org"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.primarySubOrg}
                displayEmpty
                input={
                  <OutlinedInput
                    id="primary-sub-org"
                    label=""
                    placeholder="Select Sub-Organization"
                    sx={{ width: "100%", height: 45, outline: "none" }}
                  />
                }
                inputProps={{
                  style: {
                    outline: "none",
                  },
                }}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected && subOrgsList?.length) {
                    let title = subOrgsList?.find(
                      (subOrg) => subOrg?.id === selected,
                    )?.title;
                    if (title) {
                      return title;
                    }
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select primary sub-organization...
                      </Typography>
                    );
                  }
                }}
              >
                <MenuItem disabled value="">
                  Select primary sub-organization...
                </MenuItem>
                {subOrgsList?.map((orgType) => (
                  <MenuItem key={orgType?.id} value={orgType?.id}>
                    {orgType?.title}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(
                formik.touched.primarySubOrg && formik.errors.primarySubOrg,
              ) && (
                  <FormHelperText error>
                    {formik.errors.primarySubOrg}
                  </FormHelperText>
                )}
            </Grid>
            <Grid item md={6} xs={12}>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="h5"
                fontWeight={700}
                fontSize={17}
                color={theme?.palette?.text?.containerTextLabel}
              >
                Access Permissions
              </Typography>
              <Typography
                variant="h6"
                fontWeight={400}
                fontSize={11}
                color={theme?.palette?.text?.containerTextLabel}
                sx={{ mt: 1, letterSpacing: "0.1px" }}
              >
                If you’d like this user to have access to other
                sub-organizations or sub-organization teams. Check the box next
                to that item.
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize={14}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{ mr: 2, letterSpacing: "0.1px" }}
                >
                  Select All
                </Typography>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={selectAllAccessPermission}
                      onChange={(e) => {
                        setSelectAllAccessPermission(e?.target?.checked);
                        setInitialMount(false);
                      }}
                      sx={{ m: 1 }}
                    />
                  }
                  label=""
                />
              </Box>
              <Typography
                variant="h6"
                fontWeight={400}
                fontSize={11}
                color={theme?.palette?.text?.containerTextLabel}
                sx={{ mt: 1, letterSpacing: "0.1px" }}
              >
                Toggling this on will select all sub-organizations as well as
                all sub-organization teams.
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel htmlFor="org-filter" sx={inputLabelStyle}>
                Filter By Sub Organization
              </CustomInputLabel>
              <Select
                labelId="org-filter"
                multiple
                displayEmpty
                value={selectedOrgType}
                onChange={handleChangeOrgType}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select Sub-Org"
                    sx={{ width: "100%", height: 45 }}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={(selected) => {
                  if (selected?.length && subOrgsList?.length) {
                    let titles = [];
                    subOrgsList?.filter((subOrg) => {
                      if (selected.includes(subOrg?.id)) {
                        titles.push(subOrg?.title);
                      }
                    });
                    if (titles?.length) {
                      return titles.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select primary sub-organization...
                      </Typography>
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  Select sub-organization...
                </MenuItem>
                {subOrgsList?.map((orgType) => (
                  <MenuItem key={orgType?.id} value={orgType?.id}>
                    <Checkbox
                      checked={selectedOrgType.indexOf(orgType?.id) > -1}
                    />
                    <ListItemText primary={orgType?.title} />
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{ height: 200, overflowY: "auto", overflowX: "none" }}
                mt={2}
                bgcolor="#F4F4F4"
              >
                {subOrgTeamsList?.length ? (
                  subOrgTeamsList?.map((team) => (
                    <Box key={team?.id}>
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          sx={{
                            color: "#00B60D",
                            "&.Mui-checked": {
                              color: "#00B60D",
                            },
                          }}
                          checked={selectedSubOrgTeams.indexOf(team?.id) > -1}
                          onChange={() => handleChangeSubOrgTeam(team?.id)}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          fontSize={13}
                          color={theme?.palette?.text?.containerTextLabel}
                          sx={{ letterSpacing: "0.1px" }}
                        >
                          {team?.title}
                        </Typography>
                      </Box>
                      <Box
                        width={1}
                        height={"1px"}
                        bgcolor={"gray"}
                        sx={{ opacity: 0.25 }}
                      ></Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    fontSize={13}
                    color={theme?.palette?.text?.containerTextLabel}
                    sx={{ letterSpacing: "0.1px", textAlign: "center", mt: 7 }}
                  >
                    No Team
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 2 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditCoachingStaff;
