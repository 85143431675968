import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import {
  SET_TOAST,
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import httpClient from "@utils/httpClient";

const AddEditPosition = ({ open, handleClose, editData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Position Name is required"),
    order: Yup.number()
      .min(1, "Invalid order number, should be greater than 0")
      .positive("Invalid order number, should be greater than 0")
      .required("Sprint order is required"),
    display_value: Yup.string().trim(),
  });

  const handleSave = async (values, actions) => {
    dispatch({ type: SET_LOADER, data: true });
    const trimmedText = values?.name
    ?.replace(/<\/?[^>]+(>|$)/g, "")
    .trim();
    if (editData?.id) {
      values.id = editData?.id;
      values.name = trimmedText;
    }
    if (trimmedText) {
      values.name = trimmedText;
      const res = await httpClient.post("/createPosition", values);
      dispatch({ type: SET_LOADER, data: false });
      if (res && res.ok) {
        actions.resetForm();
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: res.message ?? "Position added/updated successfully",
          },
        });
        handleClose(false);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: res.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: editData?.name ?? "",
      order: editData?.order,
      display_value: editData?.display_value ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // px={2.5}
          pt={1}
          sx={{
            [theme.breakpoints.down("sm")]: {
              p: 0,
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {editData?.id ? "Edit Position" : "Add Position"}
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            // p: 4.5,
            [theme.breakpoints.down("sm")]: {
              p: 3,
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInputLabel htmlFor="name-id">Name</CustomInputLabel>
              <CustomTextField
                id="name-id"
                name="name"
                placeholder="Enter name..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.name && formik.errors.name) && (
                <FormHelperText error>{formik.errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel htmlFor="name-id">
                Display Value
              </CustomInputLabel>
              <CustomTextField
                id="value-id"
                name="display_value"
                placeholder="Enter display value..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.display_value}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(
                formik.touched.display_value && formik.errors.display_value,
              ) && (
                <FormHelperText error>
                  {formik.errors.display_value}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel htmlFor="name">Order</CustomInputLabel>
              <CustomTextField
                id="order"
                name="order"
                placeholder="Enter order..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.order}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
                type="number"
              />
              {Boolean(formik.touched.order && formik.errors.order) && (
                <FormHelperText error>{formik.errors.order}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2.5,
            pt: 0,
            [theme.breakpoints.down("sm")]: {
              p: 3,
              pt: 0,
            },
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditPosition;
