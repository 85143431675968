import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TOAST,
  createTeamRosterIndividualAction,
  getAllSubOrg,
  getSubOrgTeams,
  saveRosterAction,
  uploadFileAction,
  SET_SUCCESS_ERROR_POPUP,
  getAllPositionsList,
  getGrades,
} from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { Tournament_Roster_Athlete_List } from "../../routes/constants";
import { useNavigate } from "react-router";
import SuccessPopup from "../Common/SuccessPopup";
import { convertToCapsOfFirstLetter } from "@utils/functions";
import moment from "moment";
import { useMutation } from "react-query";

const AddRoaster = ({
  open,
  handleClose,
  adminData,
  teamId,
  setRosterData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [subOrgsList, setSubOrgs] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState([]);
  const [subOrgTeamsList, setSubOrgTeamsList] = useState([]);
  const [selectedSubOrgTeams, setSelectedSubOrgTeams] = useState([]);
  const [profileImgUrl, setProfileImgUrl] = useState(adminData?.image ?? "");
  const [isInitialMount, setInitialMount] = useState(true);
  const [selectAllAccessPermission, setSelectAllAccessPermission] =
    useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [returnRostersList, setReturnRostersList] = useState([]);
  const [positionsList, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [gradeArr, setGradeArr] = useState([]);
  const { mutateAsync: getAllPositions } = useMutation(getAllPositionsList);

  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const selectionInputStyle = { width: "100%", padding: "12px 14px" };
  const grades = ["3rd grade", "4th grade", "5th grade", "6th grade"];

  const validationSchema = Yup.object({
    parentFirstName: Yup.string().trim().required("First Name is required"),
    parentLastName: Yup.string().trim().required("Last Name is required"),
    parentEmail: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    parentMobile: Yup.string().min(10).required("Mobile number is required"),
    // parentCountryCode: Yup.string().required("Country Code is required"),

    athleteFirstName: Yup.string().trim().required("First Name is required"),
    athleteLastName: Yup.string().trim().required("Last Name is required"),
    athleteEmail: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    athleteMobile: Yup.string().min(10).required("Mobile number is required"),
    athleteSchool: Yup.string().trim().required("Athlete school is required"),
    athleteDOB: Yup.string().trim().required("Athlete DOB is required"),
    // athletePosition: Yup.string()
    //   .trim()
    //   .required("Athlete position is required"),
    athleteGrade: Yup.string().trim().required("Athlete grade is required"),
    // athleteCountryCode: Yup.string().required("Country Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      parentFirstName: "",
      parentLastName: "",
      parentEmail: "",
      parentMobile: "",
      parentCountryCode: "",
      athleteFirstName: "",
      athleteLastName: "",
      athleteEmail: "",
      athleteMobile: "",
      athleteCountryCode: "",
      athleteSchool: "",
      athleteDOB: "",
      athletePosition: "",
      athleteGrade: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedPosition?.length === 0) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: "Please select at least one position",
          },
        });
        return false;
      }
      let bodyParam = {
        team_id: teamId ?? "",
        first_name: values?.athleteFirstName ?? "",
        last_name: values?.athleteLastName ?? "",
        email: values?.athleteEmail ?? "",
        mobile: values?.athleteMobile ?? "",
        countryCode: values?.athleteCountryCode
          ? values?.athleteCountryCode?.toUpperCase()
          : "",
        school: values?.athleteSchool ?? "",
        dob: moment(values?.athleteDOB).format("MM-DD-YYYY") ?? "",
        position: selectedPosition,
        grade: values?.athleteGrade ?? "",
        parent_first_name: values?.parentFirstName ?? "",
        parent_last_name: values?.parentLastName ?? "",
        parent_email: values?.parentEmail ?? "",
        parent_mobile: values?.parentMobile ?? "",
        parent_countryCode: values?.parentCountryCode
          ? values?.parentCountryCode?.toUpperCase()
          : "",
      };
      createTeamRosterIndividual(bodyParam);
    },
  });

  const createTeamRosterIndividual = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await createTeamRosterIndividualAction(
      dispatch,
      params,
      bodyParams,
    );
    if (response?.ok) {
      setRosterData(response?.data);
      setSelectedPosition([]);
      setShowSuccessModal(true);
      // let paymentData = {
      //   team_id: teamId ?? "",
      //   athlete_count: response?.data ? response?.data?.length : 0,
      //   athleteData: response?.data ?? []
      // }
      // let response = await saveRosterAction(dispatch, params, paymentData);
      // if(response?.ok) {
      //   setShowSuccessModal(true);
      //   setReturnRostersList(response?.data ?? []);
      // }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllSubOrgList();
      getPositions();
      getGradeList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  useEffect(() => {
    if (!selectedOrgType?.length) {
      setSelectAllAccessPermission(false);
    }
  }, [selectedOrgType]);

  useEffect(() => {
    // when all sub-org and sub-org team checked manually then enable select all switch
    if (
      subOrgsList?.length === selectedOrgType?.length &&
      subOrgTeamsList?.length === selectedSubOrgTeams?.length &&
      !isInitialMount
    ) {
      setSelectAllAccessPermission(true);
    }
  }, [subOrgsList, subOrgTeamsList, selectedOrgType, selectedSubOrgTeams]);

  useEffect(() => {
    if (subOrgTeamsList?.length) {
      if (selectAllAccessPermission) {
        let subOrgTeamIds = [];
        subOrgTeamsList?.forEach((team) => subOrgTeamIds?.push(team?.id));
        setSelectedSubOrgTeams(subOrgTeamIds);
      } else {
        if (!selectedOrgType?.length) {
          setSubOrgTeamsList([]);
          setSelectedSubOrgTeams([]);
        }
      }
    }
  }, [selectAllAccessPermission, subOrgTeamsList, selectedOrgType]);

  useEffect(() => {
    if (selectedOrgType?.length) {
      getSubOrgTeamsList([...selectedOrgType]);
    }
  }, [selectedOrgType]);

  useEffect(() => {
    if (subOrgsList?.length) {
      if (selectAllAccessPermission) {
        let subOrgIds = [];
        subOrgsList?.forEach((subOrg) => subOrgIds?.push(subOrg?.id));
        setSelectedOrgType(subOrgIds);
      } else {
        setSelectedOrgType([]);
      }
    }
  }, [selectAllAccessPermission, subOrgsList]);

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: 0,
      limit: 1000,
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      setSubOrgs(response?.data?.data ?? []);
    }
  };

  const getSubOrgTeamsList = async (subOrgId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getSubOrgTeams(params, subOrgId);
    if (response?.ok) {
      setSubOrgTeamsList(response?.data ?? []);
    }
  };

  const getPositions = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllPositions({ dispatch, params });
    if (response?.ok) {
      setPositions(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const getGradeList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getGrades(params, dispatch);
    if (response?.ok) {
      setGradeArr(response?.data ?? []);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const handleSuccessModalClose = () => {
    handleClose(false);
    // navigate(Tournament_Roster_Athlete_List, { state: { dataList: [], teamId } });
  };

  const handleChangePositionSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPosition(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => handleClose(true)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            py={1.5}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Manually Add Roster
            </Typography>
          </Box>
        </DialogTitle>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mb={1}
        >
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ paddingX: 6 }}>
            <Typography
              variant="h5"
              fontWeight={700}
              fontSize={17}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Add Parent Information
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                mt: 2,
                "& .MuiFormControl-root": {
                  width: "100%",
                },
              }}
            >
              <Grid item xs={12} md={6}>
                <CustomInputLabel htmlFor="parent-first-name">
                  First Name
                </CustomInputLabel>
                <CustomTextField
                  id="parent-first-name"
                  name="parentFirstName"
                  placeholder="Enter first name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    convertToCapsOfFirstLetter(e, formik, "parentFirstName")
                  }
                  value={formik.values.parentFirstName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.parentFirstName &&
                    formik.errors.parentFirstName,
                ) && (
                  <FormHelperText error>
                    {formik.errors.parentFirstName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInputLabel htmlFor="parent-last-name">
                  Last Name
                </CustomInputLabel>
                <CustomTextField
                  id="parent-last-name"
                  name="parentLastName"
                  placeholder="Enter last name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    convertToCapsOfFirstLetter(e, formik, "parentLastName")
                  }
                  value={formik.values.parentLastName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.parentLastName && formik.errors.parentLastName,
                ) && (
                  <FormHelperText error>
                    {formik.errors.parentLastName}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="parent-email">
                  Email Address
                </CustomInputLabel>
                <CustomTextField
                  id="parent-email"
                  name="parentEmail"
                  placeholder="Enter email..."
                  variant="outlined"
                  size="small"
                  type="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.parentEmail.toLowerCase()}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.parentEmail && formik.errors.parentEmail,
                ) && (
                  <FormHelperText error>
                    {formik.errors.parentEmail}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="parent-mobile-number">
                  Mobile Number
                </CustomInputLabel>
                <Box
                  sx={{
                    "& .react-tel-input .form-control": {
                      minWidth: "100%",
                      width: "100%",
                      fontWeight: 500,
                      background: theme?.palette?.grey[50],
                      height: "42px",
                    },
                  }}
                >
                  <PhoneInput
                    country={"us"}
                    id="parent-mobile"
                    name="parent-mobile"
                    placeholder="(702) 123-4567"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.parentMobile}
                    onChange={(value, country) => {
                      formik.setFieldValue(
                        "parentCountryCode",
                        country?.countryCode || "",
                      );
                      formik.setFieldValue("parentMobile", value || "");
                    }}
                    error={Boolean(
                      formik.touched.parentMobile && formik.errors.parentMobile,
                    )}
                    size="sm"
                    style={{
                      width: "100%",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "12px 14px",
                      },
                      "& .react-tel-input": {
                        width: "100%",
                      },
                    }}
                    disableDropdown={true}
                    disableCountryCode={true}
                    onlyCountries={["us"]}
                  />
                  {Boolean(
                    formik.touched.parentMobile && formik.errors.parentMobile,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.parentMobile}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              fontWeight={700}
              fontSize={17}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{ my: 3 }}
            >
              Add Athlete Information
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                "& .MuiFormControl-root": {
                  width: "100%",
                },
              }}
            >
              <Grid item xs={12} md={6}>
                <CustomInputLabel htmlFor="athlete-first-name">
                  First Name
                </CustomInputLabel>
                <CustomTextField
                  id="athlete-first-name"
                  name="athleteFirstName"
                  placeholder="Enter first name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    convertToCapsOfFirstLetter(e, formik, "athleteFirstName")
                  }
                  value={formik.values.athleteFirstName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.athleteFirstName &&
                    formik.errors.athleteFirstName,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteFirstName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInputLabel htmlFor="athlete-last-name">
                  Last Name
                </CustomInputLabel>
                <CustomTextField
                  id="athlete-last-name"
                  name="athleteLastName"
                  placeholder="Enter last name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    convertToCapsOfFirstLetter(e, formik, "athleteLastName")
                  }
                  value={formik.values.athleteLastName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.athleteLastName &&
                    formik.errors.athleteLastName,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteLastName}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-email">
                  Email Address
                </CustomInputLabel>
                <CustomTextField
                  id="athlete-email"
                  name="athleteEmail"
                  placeholder="Enter email..."
                  variant="outlined"
                  size="small"
                  type="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.athleteEmail.toLowerCase()}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.athleteEmail && formik.errors.athleteEmail,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteEmail}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-mobile">
                  Mobile Number
                </CustomInputLabel>
                <Box
                  sx={{
                    "& .react-tel-input .form-control": {
                      minWidth: "100%",
                      width: "100%",
                      fontWeight: 500,
                      background: theme?.palette?.grey[50],
                      height: "42px",
                    },
                  }}
                >
                  <PhoneInput
                    country={"us"}
                    id="athlete-mobile"
                    name="athleteMobile"
                    variant="outlined"
                    placeholder="(702) 123-4567"
                    onBlur={formik.handleBlur}
                    value={formik.values.athleteMobile}
                    onChange={(value, country) => {
                      formik.setFieldValue(
                        "athleteCountryCode",
                        country?.countryCode || "",
                      );
                      formik.setFieldValue("athleteMobile", value || "");
                    }}
                    error={Boolean(
                      formik.touched.athleteMobile &&
                        formik.errors.athleteMobile,
                    )}
                    size="sm"
                    style={{
                      width: "100%",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "12px 14px",
                      },
                    }}
                    disableDropdown={true}
                    disableCountryCode={true}
                    onlyCountries={["us"]}
                  />
                  {Boolean(
                    formik.touched.athleteMobile && formik.errors.athleteMobile,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.athleteMobile}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-school">
                  Athlete School
                </CustomInputLabel>
                <CustomTextField
                  id="athlete-school"
                  name="athleteSchool"
                  placeholder="Enter athlete school..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.athleteSchool}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                />
                {Boolean(
                  formik.touched.athleteSchool && formik.errors.athleteSchool,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteSchool}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-DOB">
                  Date Of Birth
                </CustomInputLabel>
                <TextField
                  id="athlete-DOB"
                  name="athleteDOB"
                  variant="outlined"
                  // placeholder="Enter DOB..."
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.athleteDOB}
                  type={"date"}
                  // label="Enter DOB..."
                  onKeyDown={(e) => e.preventDefault()}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                    style: {
                      width: "100%",
                      padding: "12px 14px",
                    },
                  }}
                  sx={{
                    width: "100%",
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      background: "transparent",
                      bottom: 0,
                      color: "transparent",
                      cursor: "pointer",
                      height: "auto",
                      left: 0,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "100%",
                    },
                  }}
                  error={Boolean(
                    formik.touched.athleteDOB && formik.errors.athleteDOB,
                  )}
                />
                {Boolean(
                  formik.touched.athleteDOB && formik.errors.athleteDOB,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteDOB}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-school">
                  Athlete Position
                </CustomInputLabel>
                <Select
                  multiple
                  displayEmpty
                  name="position"
                  value={selectedPosition}
                  onChange={handleChangePositionSelection}
                  input={
                    <OutlinedInput
                      label=""
                      placeholder="Select position..."
                      sx={selectionInputStyle}
                    />
                  }
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                    },
                  }}
                  renderValue={(selected) => {
                    if (selected?.length && positionsList?.length) {
                      let positions = [];
                      positionsList?.forEach((position) => {
                        if (selected.includes(position?.id)) {
                          positions.push(position?.name);
                        }
                      });
                      if (positions?.length) {
                        return positions.join(", ");
                      }
                      return selected?.join(", ");
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select one or more position...
                        </Typography>
                      );
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {positionsList && positionsList?.length
                    ? positionsList?.map((position) => (
                        <MenuItem key={position?.id} value={position?.id}>
                          <Checkbox
                            checked={
                              selectedPosition.indexOf(position?.id) > -1
                            }
                          />
                          <ListItemText primary={position?.name} />
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <CustomInputLabel htmlFor="athlete-grade">
                  CHOOSE GRADE LEVEL
                </CustomInputLabel>
                <Select
                  id="athlete-grade"
                  name="athleteGrade"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.athleteGrade}
                  input={<OutlinedInput sx={selectionInputStyle} />}
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      let selected = gradeArr?.find(
                        (grade) => grade?.name === value,
                      );
                      if (selected) {
                        return selected?.name;
                      }
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select grade...
                        </Typography>
                      );
                    }
                  }}
                >
                  {gradeArr.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(
                  formik.touched.athleteGrade && formik.errors.athleteGrade,
                ) && (
                  <FormHelperText error>
                    {formik.errors.athleteGrade}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              marginX: 6,
              paddingX: 0,
              pt: 2,
              pb: 3.5,
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 4.5, paddingY: 1.2, mt: 2 }}
              color="inherit"
              onClick={() => {
                handleClose(true);
              }}
            >
              Close
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{
                paddingX: { xs: 2.5, sm: 3.5, md: 4.5, lg: 4.5, xl: 4.5 },
                paddingY: 1.4,
                mr: 4.6,
                mt: 2,
              }}
              style={{ marginLeft: 0 }}
            >
              Add to Roaster
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {showSuccessModal && (
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Roster Created Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup and click on Save Roster button to complete this process"
        />
      )}
    </>
  );
};

export default AddRoaster;
