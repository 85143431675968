import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Alert,
  Card,
  CardContent,
  Step,
  Divider,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { getMe, getViewEventRegisterUsers, SET_TOAST } from "@store/actions";
import { DOBFormat, dateFormat } from "../../../utils/functions";
import {
  SET_SUCCESS_ERROR_POPUP,
  updateUserEventStatusAction,
} from "../../../store/actions";
import {
  ContainedBtnStyled,
  StatusStyled,
  UnderlineBtnStyled,
} from "../../../components/Common/styled";
import SuccessCheck from "../../../assets/images/success_check.png";
import CancelConfirmDialog from "../../../components/Common/CancelConfirmDialog";
import ScholarshipPayment from "../../../assets/images/svg/scholarship-payment.svg";
import { useGetCurrentDateTime } from "../../../hooks/useCurrentDates";
import PlusIcon from "../../../assets/images/PluseIcon.png";
import CrossIcon from "../../../assets/images/CrossIcon.png";

const ViewEventRegisterUsers = ({ eventId, handleNext, onBack }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const userType = state?.user?.user?.user_type;
  const orgDetails = state?.org?.org?.data;
  const [eventRegisterUsers, setEventRegisterUsers] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [athleteId, setAthleteId] = useState("");
  const [athleteEmail, setAthleteEmail] = useState("");
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [showNoAthleteMessage, setShowNoAthleteMessage] = useState(false);
  const [currentDateString, currentTimeString] = useGetCurrentDateTime();

  const uploadImgStyle = {
    width: "70px",
    height: "70px",
    borderRadius: "100%",
    objectFit: "cover",
  };

  useEffect(() => {
    dispatch(getMe());
    getViewEventRegisterUsersList();
  }, []);

  const getViewEventRegisterUsersList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: eventId,
    };
    let response = await getViewEventRegisterUsers(params, dispatch);
    if (response?.ok) {
      setEventRegisterUsers(response?.data ?? []);
      if (!response?.data?.length) {
        setShowNoAthleteMessage(true);
      } else {
        setShowNoAthleteMessage(false);
      }
    } else {
      setShowNoAthleteMessage(false);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            response.message ?? "Something went wrong for tournament data",
        },
      });
    }
  };

  const updateUserEventStatus = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let bodyParams = {
      event_id: eventId,
      athlete_id: athleteId,
      user_status: attendanceStatus, //CHECKIN/UNREGISTER,
      date: currentDateString,
      time: currentTimeString,
      email: athleteEmail,
    };
    let response = await updateUserEventStatusAction(
      dispatch,
      params,
      bodyParams,
    );
    try {
      setShowCancelSaveConfirmPopup(false);
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response?.message ?? "Success",
          },
        });
        dispatch(getMe());
        getViewEventRegisterUsersList();
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong",
          },
        });
      }
    } catch (error) {
      setShowCancelSaveConfirmPopup(false);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleClickCheckin = (athleteId, status, athleteEmail) => {
    setAthleteId(athleteId);
    setAthleteEmail(athleteEmail);
    setAttendanceStatus(status);
    setShowCancelSaveConfirmPopup(true);
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ p: 4 }}
      >
        <Grid item xs={12} md={12} sx={{ mb: 3 }}>
          <Grid container sx={{ mt: 4, mb: 4, alignItems: "center" }}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                sx={{ color: "#262626", textAlign: "center" }}
              >
                Users List
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ p: 2 }}></Divider>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems="center" textAlign="center">
            {eventRegisterUsers.map((userData, index) => (
              <Card
                key={index}
                sx={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.11)",
                  backgroundColor: "white !important",
                  padding: "1rem",
                  overflow: "inherit",
                  mt: 2,
                  // border: userData.id === activeAthleteId ? '1px solid #000000' : 'none'
                }}
              >
                <CardContent
                  sx={{ p: "0", pb: "0 !important", position: "relative" }}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box
                        sx={{
                          width: "70px",
                          height: "70px",
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: "#000000",
                            justifyContent: "center",
                            margin: "0",
                            minWidth: "70px",
                            color: "#ffffff",
                            borderRadius: "50rem",
                            position: "relative",
                            overflow: "hidden",
                            textTransform: "uppercase",
                            height: "70px",
                            width: "70px",
                            lineHeight: "70px",
                            marginLeft: "0",
                            marginRight: "auto",
                            marginTop: "0",
                            fontSize: "2rem",
                            fontWeight: "bold",
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          {userData?.image ? (
                            <>
                              <img
                                src={userData?.image}
                                style={uploadImgStyle}
                              />
                            </>
                          ) : (
                            <>
                              {`${userData?.first_name?.charAt(
                                0,
                              )}${userData?.last_name?.charAt(0)}`}
                            </>
                          )}
                        </Box>
                      </Box>
                      {userType === "admin" &&
                      userData?.scholarshipFlag === "Y" ? (
                        <img
                          src={ScholarshipPayment}
                          style={{ width: 20, height: 20, marginTop: 10 }}
                        />
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm
                      container
                      sx={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                      // item
                      // xs={12}
                      // sm={userType === "admin" ? 8 : 12}
                      // md={userType === "admin" ? 8 : 12}
                      // lg={8}
                      // container
                      // direction="column"
                      // spacing={2}
                      >
                        <Grid item xs sx={{ textAlign: "left" }}>
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            component="div"
                            sx={{ fontSize: "16px", fontWeight: "600", m: 0 }}
                          >
                            {`${userData?.first_name} ${userData?.last_name}`}{" "}
                            <span
                              style={{
                                color: theme?.palette?.warning?.blue,
                                fontSize: "20px",
                                marginLeft: "3px",
                              }}
                            >
                              {userData?.guestUser ? "G" : ""}
                            </span>
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontFamily: '"Arial",sans-serif,Arial',
                              fontSize: "14px",
                            }}
                          >
                            GRADE: {userData?.grade ?? "N/A"}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontFamily: '"Arial",sans-serif,Arial',
                              fontSize: "14px",
                            }}
                          >
                            POSITION:{" "}
                            {userData?.position ? userData.position : "N/A"}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontFamily: '"Arial",sans-serif,Arial',
                              fontSize: "14px",
                            }}
                          >
                            DOB:{" "}
                            {userData?.dob ? DOBFormat(userData.dob) : "N/A"}
                          </Typography>
                          {userData?.userStatus !== "UNREGISTER" ? (
                            <UnderlineBtnStyled
                              sx={{ mt: 1 }}
                              onClick={() => {
                                handleClickCheckin(
                                  userData?.id,
                                  "UNREGISTER",
                                  userData?.email,
                                );
                              }}
                            >
                              Unregister
                            </UnderlineBtnStyled>
                          ) : null}
                        </Grid>
                      </Box>
                      <Box
                        // item
                        // xs={12}
                        // sm={userType === "admin" ? 4 : 12}
                        // md={userType === "admin" ? 4 : 12}
                        // lg={4}
                        sx={{
                          alignSelf: "center",
                          // ml:"auto",
                          my: 1,
                          [theme.breakpoints.down("lg")]: {
                            textAlign: userType === "admin" ? "center" : "left",
                          },
                        }}
                      >
                        {userType === "admin" ? (
                          <>
                            {userData?.userStatus === "CHECKIN" ? (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <StatusStyled
                                    sx={{
                                      backgroundColor:
                                        theme?.palette?.success?.dark,
                                      px: 2,
                                      py: 1,
                                      fontSize: "13px",
                                      whiteSpace: "nowrap",
                                      minWidth: "inherit",
                                      maxWidth: "inherit",
                                      height: "auto",
                                    }}
                                  >
                                    Checked-In
                                  </StatusStyled>
                                  <Box
                                    sx={{
                                      border: "1px solid #afafaf",
                                      height: "35px",
                                      width: "0.5px",
                                      marginRight: "8px",
                                      marginLeft: "8px",
                                    }}
                                  ></Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <img
                                      src={SuccessCheck}
                                      style={{
                                        minWidth: 30,
                                        maxWidth: 30,
                                        height: "auto",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <>
                                {userData?.userStatus === "UNREGISTER" ? (
                                  <>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <StatusStyled
                                        sx={{
                                          backgroundColor:
                                            theme?.palette?.error?.dark,
                                          px: 2,
                                          py: 1,
                                          fontSize: "13px",
                                          whiteSpace: "nowrap",
                                          minWidth: "inherit",
                                          maxWidth: "inherit",
                                          height: "auto",
                                        }}
                                      >
                                        Unregistered
                                      </StatusStyled>
                                      <Box
                                        sx={{
                                          border: "1px solid #afafaf",
                                          height: "35px",
                                          width: "0.5px",
                                          marginRight: "8px",
                                          marginLeft: "8px",
                                        }}
                                      ></Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        // sx={{ cursor: 'pointer' }}
                                        // onClick={() => {
                                        //   if (!userData?.userStatus || userData?.userStatus === "UNREGISTER") {
                                        //     handleClickCheckin(userData?.id, "CHECKIN");
                                        //   }
                                        // }}
                                      >
                                        <img
                                          src={CrossIcon}
                                          style={{
                                            minWidth: 30,
                                            maxWidth: 30,
                                            height: "auto",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <StatusStyled
                                        sx={{
                                          px: 2,
                                          py: 1,
                                          fontSize: "13px",
                                          whiteSpace: "nowrap",
                                          minWidth: "inherit",
                                          maxWidth: "inherit",
                                          height: "auto",
                                        }}
                                      >
                                        Unconfirmed
                                      </StatusStyled>
                                      <Box
                                        sx={{
                                          border: "1px solid #afafaf",
                                          height: "35px",
                                          width: "0.5px",
                                          marginRight: "8px",
                                          marginLeft: "8px",
                                        }}
                                      ></Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (
                                            !userData?.userStatus ||
                                            userData?.userStatus ===
                                              "UNREGISTER"
                                          ) {
                                            handleClickCheckin(
                                              userData?.id,
                                              "CHECKIN",
                                              userData?.email,
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          src={PlusIcon}
                                          style={{
                                            minWidth: 30,
                                            maxWidth: 30,
                                            height: "auto",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {userData?.userStatus === "CHECKIN" ? (
                              <StatusStyled
                                sx={{
                                  backgroundColor:
                                    theme?.palette?.success?.dark,
                                  px: 2,
                                  py: 1,
                                  fontSize: "13px",
                                  whiteSpace: "nowrap",
                                  minWidth: "inherit",
                                  maxWidth: "inherit",
                                  height: "auto",
                                }}
                              >
                                Confirmed
                              </StatusStyled>
                            ) : (
                              <ContainedBtnStyled
                                variant="contained"
                                sx={{
                                  px: 2,
                                  py: 1,
                                  fontSize: "13px",
                                  whiteSpace: "nowrap",
                                  minWidth: "inherit",
                                  maxWidth: "inherit",
                                  height: "auto",
                                  backgroundColor: orgDetails?.primary_color,
                                  [theme.breakpoints.down("sm")]: {
                                    maxWidth: "100%",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    !userData?.userStatus ||
                                    userData?.userStatus === "UNREGISTER"
                                  ) {
                                    handleClickCheckin(
                                      userData?.id,
                                      "CHECKIN",
                                      userData?.email,
                                    );
                                  }
                                }}
                                disabled={
                                  userData?.userStatus === "UNREGISTER"
                                    ? true
                                    : false
                                }
                              >
                                Check-In
                              </ContainedBtnStyled>
                            )}
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
            {eventRegisterUsers?.length === 0 &&
            userType === "parent" &&
            showNoAthleteMessage ? (
              <Typography
                variant="h3"
                sx={{ color: "#262626", textAlign: "center" }}
              >
                Your athlete not registered yet.
              </Typography>
            ) : null}
            {eventRegisterUsers?.length === 0 && userType === "player" ? (
              <Typography
                variant="h3"
                sx={{ color: "#262626", textAlign: "center" }}
              >
                You are not registered yet.
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} alignItems="center" textAlign="center">
            <Typography
              variant="h6"
              sx={{
                mt: 5,
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                textAlign: "center",
                color: orgDetails?.primary_color,
              }}
              onClick={() => onBack("EVENT_INFO_PREVIEW")}
            >
              Back
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmDialog
        title={"Registered Users"}
        message={`Are you sure, want to confirm ${
          attendanceStatus === "UNREGISTER" ? "Unregistration?" : "check-in?"
        }`}
        open={showCancelSaveConfirmPopup}
        handleClose={() => setShowCancelSaveConfirmPopup(false)}
        handleCancel={() => {
          setShowCancelSaveConfirmPopup(false);
        }}
        handleConfirmClose={updateUserEventStatus}
        confirmBtnLabel={"Yes, Confirm"}
      />
    </>
  );
};

export default ViewEventRegisterUsers;
