
import { Box, Button, FormControl, FormHelperText, Grid, TextField, TextareaAutosize, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@emotion/react';
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, userSupportContact } from '../../store/actions';
import PhoneInput from 'react-phone-input-2';
import { convertToCapsOfFirstLetter } from "@utils/functions";


const validationSchema = Yup.object({
  firstName: Yup.string().trim(),
  lastName: Yup.string().trim(),
  email: Yup.string()
    .trim()
    .email("Must be a valid email"),
  mobile: Yup.string(),
  countryCode: "",
  message: Yup.string().required("Message is required"),
});



const UserSupport = () => {
  const state = useSelector((state) => state);
  const userInfo = state?.user?.user;
  const theme = useTheme();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: userInfo?.first_name ?? "",
      last_name: userInfo?.last_name ?? "",
      email: userInfo?.email ?? "",
      mobile: userInfo?.mobile ?? "",
      countryCode: userInfo?.countryCode ?? "",
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      userSupport(values)
    },
  });

  const userSupport = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    bodyParams.countryCode = bodyParams.countryCode.toUpperCase()
    let response = await userSupportContact(dispatch, params, bodyParams);
    if (response?.ok) {
      formik.resetForm();
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message }
      })
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
      })
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography
        variant="h4"
        fontWeight={700}
        fontSize={20}
        color={theme?.palette?.text?.containerTextLabel}
        sx={{ mt: 5, mb: 3, textAlign: "center" }}
      >
        Have a question or want to send us feedback?
      </Typography>
      <Typography
        variant="h6"
        fontWeight={400}
        fontSize={15}
        color={theme?.palette?.text?.containerTextLabel}
        sx={{ mb: 3, textAlign: "center", maxWidth: "1000px", width: "100%" }}
      >
        Having a problem? Need help or just have a general question? Let us know. We’d also love to hear feedback on your experience. The good, the bad, the ugly. Just like you, we want to improve every day and you will see that over the coming months. Drop us a note below. Thank you!
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            {/* First Name */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.first_name && formik.errors.first_name)}
              sx={{
                ...theme.typography.customInput,
                m: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: theme?.palette?.boxShadow?.primary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                  width: "100%",
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                  width: "100%",
                }
              }}
            >
              <TextField
                id="first_name"
                name="first_name"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'first_name')}
                value={formik.values.first_name}
                // label="First Name *"
                placeholder='First Name *'
                error={Boolean(
                  formik.touched.first_name && formik.errors.first_name,
                )}
              />
              {Boolean(formik.touched.first_name && formik.errors.first_name) && (
                <FormHelperText error>{formik.errors.first_name}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>

            {/* Last Name */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.last_name && formik.errors.last_name)}
              sx={{
                ...theme.typography.customInput,
                m: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: theme?.palette?.boxShadow?.primary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                  width: "100%",
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                  width: "100%",
                }
              }}
            >
              <TextField
                id="last_name"
                name="last_name"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'last_name')}
                value={formik.values.last_name}
                // label="Last Name *"
                placeholder='Last Name *'
                error={Boolean(formik.touched.last_name && formik.errors.last_name)}
              />
              {Boolean(formik.touched.last_name && formik.errors.last_name) && (
                <FormHelperText error>{formik.errors.last_name}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            {/* Email */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              sx={{
                ...theme.typography.customInput,
                m: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: theme?.palette?.boxShadow?.primary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                  width: "100%",
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                  width: "100%",
                }
              }}
            >
              <TextField
                id="email"
                name="email"
                type="email"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email.toLowerCase()}
                // label="Email *"
                placeholder='Email *'
                error={Boolean(formik.touched.email && formik.errors.email)}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            {/* Mobile */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.mobile && formik.errors.mobile)}
              sx={{
                ...theme.typography.customInput,
                m: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: theme?.palette?.boxShadow?.primary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                  width: "100%",
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                  width: "100%",
                }
              }}
            >
              <Box
                sx={{
                  '& .react-tel-input .form-control': {
                    border: 0,
                    "box-shadow": "0px 6px 20px #1F54C326",
                    "min-width": "100%",
                    "font-weight": 500,
                    background: "#ffffff",
                    padding: "15px 14px 15px 50px",
                    height: "51px",
                    width: "100%",
                  },
                  "& .react-tel-input .flag-dropdown": {
                    border: 0,
                    background: "transparent",
                  }
                }
                }>
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  placeholder="(702) 123-4567"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onlyCountries={['us']}
                  disableCountryCode={true}
                  disableDropdown={true}
                  onChange={(value, country) => {
                    formik.setFieldValue("countryCode", country?.countryCode || "");
                    formik.setFieldValue("mobile", value || "");
                  }}
                  error={Boolean(
                    formik.touched.mobile && formik.errors.mobile
                  )}
                // disabled={isAthleteLogin}
                />
              </Box>
              {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                <FormHelperText error>{formik.errors.mobile}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {/* message */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.message && formik.errors.message)}
              sx={{
                ...theme.typography.customInput,
                border: "0",
                m: 0,
                boxShadow: theme?.palette?.boxShadow?.primary,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                  width: "100%",
                },
                '& .MuiFormControl-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                  width: "100%",
                }
              }}
            >
              <TextareaAutosize
                id="message"
                name="message"
                type="message"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.message}
                // label="message *"
                placeholder="Message *"
                error={Boolean(formik.touched.message && formik.errors.message)}
                minRows={12}
                maxRows={12}
                cols={3}
                style={{ outline: "none", border: 0, padding: "15px", fontSize: "14px", fontFamily: '"Arial",sans-serif,Arial', borderRadius: '5px' }}
              />
              {Boolean(formik.touched.message && formik.errors.message) && (
                <FormHelperText error>{formik.errors.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Button
            size="large"
            variant="contained"
            type="submit"
            sx={{
              paddingX: 20, paddingY: 1.8, my: 3,
              [theme.breakpoints.down('md')]: {
                px: 10,
              },
              [theme.breakpoints.down('sm')]: {
                px: 5,
                width: "80%",
              }
            }}
          >
            Send
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default UserSupport;
