const initialState = {
  currentStep: 1,
  step1Details: null,
  step2Details: null,
  step3Details: null,
  step4Details: null,
  step5Details: null,
};

const actionTypes = {
  EMAIL_CURRENT_STEP: "EMAIL_CURRENT_STEP",
  EMAIL_STEP_1_DETAILS: "EMAIL_STEP_1_DETAILS",
  EMAIL_STEP_2_DETAILS: "EMAIL_STEP_2_DETAILS",
  EMAIL_STEP_3_DETAILS: "EMAIL_STEP_3_DETAILS",
  EMAIL_STEP_4_DETAILS: "EMAIL_STEP_4_DETAILS",
  EMAIL_STEP_5_DETAILS: "EMAIL_STEP_5_DETAILS",
  RESET_CAMPAIGN_DATA: "RESET_CAMPAIGN_DATA",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMAIL_CURRENT_STEP: {
      return {
        ...state,
        step: action.data,
      };
    }
    case actionTypes.EMAIL_STEP_1_DETAILS: {
      return {
        ...state,
        step1Details: action.data,
      };
    }
    case actionTypes.EMAIL_STEP_2_DETAILS: {
      return {
        ...state,
        step2Details: action.data,
      };
    }
    case actionTypes.EMAIL_STEP_3_DETAILS: {
      return {
        ...state,
        step3Details: action.data,
      };
    }
    case actionTypes.EMAIL_STEP_4_DETAILS: {
      return {
        ...state,
        step4Details: action.data,
      };
    }
    case actionTypes.EMAIL_STEP_5_DETAILS: {
      return {
        ...state,
        step5Details: action.data,
      };
    }
    case actionTypes.RESET_CAMPAIGN_DATA: {
      return {
        currentStep: 1,
        step1Details: null,
        step2Details: null,
        step3Details: null,
        step4Details: null,
        step5Details: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
