import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Button,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import CreateCampStep1 from "../components/camp/CreateCampStep1";
import CreateCampStep2 from "../components/camp/CreateCampStep2";
import CreateCampStep3 from "../components/camp/CreateCampStep3";
import CreateCampStep4 from "../components/camp/CreateCampStep4";
import CreateCampStep5 from "../components/camp/CreateCampStep5";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  createUpdateEvent,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { CALENDAR_PAGE } from "../../../routes/constants";
import CustomSwitch from "../../../components/Common/Inputs/Switch";
import CancelConfirmDialog from "../../../components/Common/CancelConfirmDialog";

export default function CreateEditCamp() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const campEventDetails = state?.createCampEvent;
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [currentStep, setCurrentStep] = useState(1);
  const [doNotshowSprint, setDoNotShowSprint] = useState(
    campEventDetails?.step1Details?.doNotshowSprint ?? false,
  );
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] = useState(false);

  const steps = [1, 2, 3, 4, 5];
  const selectedEvent = location?.state?.selectedEvent ?? "";
  const eventType = location?.state?.eventType ?? "";
  const isEditEvent = location?.state?.isEditEvent ?? false;

  let permissions = [
    {
      id: 1,
      label: "Allow drop in registrations.",
      name: "allow_drop_in",
      flag: selectedEvent ? selectedEvent?.allow_drop_in : false,
    },
    {
      id: 2,
      label: "Restrict Athlete’s from registering.",
      name: "restrict_atheletes_to_register",
      flag: selectedEvent ? !selectedEvent?.allow_athletes : false,
    },
    {
      id: 3,
      label: "Allow Scholarship Funds to be applied to this event.",
      name: "applied_scholarship_funds_to_event",
      flag: selectedEvent ? selectedEvent?.allow_scholarship : false,
    },
    {
      id: 4,
      label: "Allow Discount Codes to be applied to this event.",
      name: "allow_discount_code_to_event",
      flag: selectedEvent ? selectedEvent?.allow_discount_code : false,
    },
    {
      id: 5,
      label: "Require approval for registrations outside of selected grades",
      name: "approval_grade",
      flag: selectedEvent ? selectedEvent?.approval_grade : false,
    },
    {
      id: 6,
      label: "Make this event private, invite only (Coming Soon)",
      name: "is_event_private",
      flag: selectedEvent
        ? selectedEvent?.visibility === "private"
          ? true
          : false
        : false,
    },
    {
      id: 7,
      label: "Allow a single pack to be applied during registration",
      name: "allow_single_pack",
      flag: selectedEvent ? selectedEvent?.allow_single_pack : false,
    },
  ];

  useEffect(() => {
    if (selectedEvent) {
      let selectedStaffs = selectedEvent?.staff_members
        ? selectedEvent?.staff_members?.map((staff) => staff?.id)
        : [];
      let products = selectedEvent?.products
        ? selectedEvent?.products?.map((product) => product?.id)
        : [];
      setDoNotShowSprint(!selectedEvent?.show_sprint ?? false);

      dispatch({
        type: "STEP_1_DETAILS",
        data: {
          // dates: selectedEvent ?
          //   selectedEvent?.start_date === selectedEvent?.end_date ?
          //     [selectedEvent?.start] :
          //     [selectedEvent?.start, selectedEvent?.end] :
          //   [],
          dates: selectedEvent
            ? selectedEvent?.start_date === selectedEvent?.end_date
              ? [covertToDateObj(selectedEvent?.start_date)]
              : [
                  covertToDateObj(selectedEvent?.start_date),
                  covertToDateObj(selectedEvent?.end_date),
                ]
            : [],
          dateSelectionOpt: selectedEvent
            ? selectedEvent?.is_range ?? false
            : false,
          startDate: selectedEvent?.start_date ?? "",
          endDate: selectedEvent?.end_date ?? "",
          campName: selectedEvent ? selectedEvent?.title : "",
          startTime: selectedEvent ? selectedEvent?.start_time : "",
          endTime: selectedEvent ? selectedEvent?.end_time : "",
          timezone: selectedEvent ? selectedEvent?.timezone : "US_Eastern",
          description: selectedEvent ? selectedEvent?.description : "",
          event_sprint: selectedEvent ? selectedEvent?.event_sprint?.id : "",
          recurrence_type: selectedEvent ? selectedEvent?.recurrence_type : "",
          recurrence_pattern: selectedEvent
            ? selectedEvent?.recurrence_pattern
              ? selectedEvent?.recurrence_pattern?.join(",")
              : ""
            : "",
          recurrence_pattern_date: selectedEvent
            ? selectedEvent?.recurrence_pattern_date?.length
              ? selectedEvent?.recurrence_pattern_date
              : []
            : [],
        },
      });
      dispatch({
        type: "STEP_2_DETAILS",
        data: {
          locations: selectedEvent
            ? selectedEvent?.locations?.map((loc) => loc?.id)
            : [],
          discount: selectedEvent
            ? selectedEvent?.discount?.map((dis) => dis?.id)
            : [],
        },
      });
      dispatch({
        type: "STEP_3_DETAILS",
        data: {
          cost: selectedEvent?.cost,
          attendees: selectedEvent?.max_seats
            ? selectedEvent?.max_seats === -1
              ? ""
              : selectedEvent?.max_seats
            : "",
          selectedGrades: selectedEvent
            ? selectedEvent?.grade?.map((grade) => grade?.id)
            : [],
          color: selectedEvent ? selectedEvent?.color : "#000000",
          eventFlyerImg: selectedEvent ? selectedEvent?.thumbnail : null,
          isOpenToAll: false,
          imageFileName: selectedEvent ? selectedEvent?.thumbnail : "",
          imageFileURL: "",
        },
      });
      dispatch({
        type: "STEP_4_DETAILS",
        data: {
          selectedProduct: products ?? [],
          permissions: permissions,
          position:
            selectedEvent?.position && selectedEvent?.position?.length
              ? selectedEvent?.position?.map((p) => p?.id)
              : [],
          staff_members: selectedStaffs ?? [],
        },
      });
    }
  }, [selectedEvent]);

  const covertToDateObj = (dateString) => {
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Subtract 1 to adjust for zero-based indexing
    const day = parseInt(parts[2], 10);
    const dateObject = new Date(year, month, day);
    return dateObject;
  };

  const onNext = (stepNum) => {
    setCurrentStep(stepNum);
    setCompletedSteps((prevState) => {
      return [...prevState, stepNum];
    });
  };

  const onBack = (stepNum) => {
    if (stepNum > 1) {
      setCurrentStep(stepNum - 1);
      let steps = completedSteps.filter((step) => step !== stepNum);
      setCompletedSteps(steps);
    }
  };

  const createUpdateEventCamp = async () => {
    let cost = campEventDetails?.step3Details?.cost ?? "";
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      isUpdate: selectedEvent ? true : false,
    };

    if (selectedEvent?.buttonType === "duplicate") {
      selectedEvent.id = undefined;
      params.isUpdate = false;
    }

    let bodyParams = {
      id: selectedEvent ? selectedEvent?.id : undefined,
      title: campEventDetails?.step1Details?.campName,
      description: campEventDetails?.step1Details?.description,
      recurrence_type: campEventDetails?.step1Details?.recurrence_type,
      recurrence_pattern: campEventDetails?.step1Details?.recurrence_pattern,
      start_date: campEventDetails?.step1Details?.startDate,
      end_date: campEventDetails?.step1Details?.endDate,
      start_time: campEventDetails?.step1Details?.startTime,
      end_time: campEventDetails?.step1Details?.endTime,
      timezone: campEventDetails?.step1Details?.timezone,
      event_sprint: campEventDetails?.step1Details?.event_sprint
        ? campEventDetails?.step1Details?.event_sprint
        : null,
      currency: "USD",
      is_public: campEventDetails?.step4Details?.permissions?.length
        ? campEventDetails?.step4Details?.permissions[
            campEventDetails?.step4Details?.permissions?.length - 1
          ]?.flag
          ? false
          : true
        : false,
      max_seats: campEventDetails?.step3Details?.attendees
        ? campEventDetails?.step3Details?.attendees
        : -1,
      visibility: campEventDetails?.step4Details?.permissions?.length
        ? campEventDetails?.step4Details?.permissions[
            campEventDetails?.step4Details?.permissions?.length - 1
          ]?.flag
          ? "private"
          : "public"
        : "",
      allow_drop_in:
        campEventDetails?.step4Details?.permissions[0]?.flag ?? false,
      allow_athletes:
        !campEventDetails?.step4Details?.permissions[1]?.flag ?? false,
      allow_scholarship:
        campEventDetails?.step4Details?.permissions[2]?.flag ?? false,
      allow_discount_code:
        campEventDetails?.step4Details?.permissions[3]?.flag ?? false,
      approval_grade:
        campEventDetails?.step4Details?.permissions[4]?.flag ?? false,
      products: campEventDetails?.step4Details?.selectedProduct ?? [],
      color: campEventDetails?.step3Details?.color,
      // "header_image": campEventDetails?.step3Details?.imageFileURL ?? "",
      thumbnail: campEventDetails?.step3Details?.imageFileURL ?? "",
      // "emails": "",
      locations: campEventDetails?.step2Details?.locations ?? [],
      // "nonRegisteredUsers": [],
      // "legal":1,
      // "legal_content":"hello world",
      position: campEventDetails?.step4Details?.position
        ? campEventDetails?.step4Details?.position
        : [],
      staff_members: campEventDetails?.step4Details?.staff_members ?? [],
      grade: campEventDetails?.step3Details?.selectedGrades ?? [],
      is_range: campEventDetails?.step1Details?.dateSelectionOpt ?? false,
      discount: campEventDetails?.step2Details?.discount ?? [],
      show_sprint: !doNotshowSprint,
    };

    if (eventType?.type === "MISC_EVENT") {
      bodyParams["class_type"] = "misc";
    } else if (eventType?.type === "DAILY_TRAINING_EVENT") {
      bodyParams["class_type"] = "dailytraining";
    } else if (eventType?.type === "CLINIC_EVENT") {
      bodyParams["class_type"] = "clinic";
    } else {
      bodyParams["class_type"] = "camp";
    }

    if (cost) {
      if (typeof cost === "string") {
        bodyParams["cost"] = cost
          ? parseFloat(cost.replace(/[^0-9.]/g, ""))
          : 0;
      } else {
        bodyParams["cost"] = cost;
      }
    } else {
      bodyParams["cost"] = 0;
    }

    let response = await createUpdateEvent(dispatch, params, bodyParams);

    try {
      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response?.message ?? "Success",
          },
        });
        // dispatch({ type: "RESET_EVENT_DATA" });
        navigate(CALENDAR_PAGE);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong",
        },
      });
    }
  };

  const closeStepsView = () => {
    dispatch({ type: "RESET_EVENT_DATA" });
    navigate(CALENDAR_PAGE);
  };

  return (
    <Card
      sx={{
        my: 1.5,
        border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
      }}
    >
      <CardContent sx={{ p: 0 }} style={{ paddingBottom: 0 }}>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="h3" component="div" my={1} mr={2}>
              Create Event
            </Typography>
            {eventType && (
              <Typography
                variant="h5"
                component="div"
                color={theme?.palette?.warning?.blue}
              >
                {eventType?.label}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            my={1}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              padding: currentStep === 5 ? 0 : "0 1rem",
              [theme.breakpoints.down("md")]: {
                alignItems: "start",
                mx: 0,
                p: 0,
              },
            }}
          >
            {steps.map((step) => (
              <Typography
                variant="h6"
                sx={{
                  background: completedSteps.includes(step)
                    ? theme?.palette?.secondary?.dark
                    : theme?.palette?.grey["300"],
                  width: 60,
                  height: 60,
                  borderRadius: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 15,
                  mx: 1,
                  color: completedSteps.includes(step)
                    ? "white"
                    : theme?.palette?.secondary?.darkNewVariant1,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  [theme.breakpoints.down("lg")]: {
                    width: 40,
                    height: 40,
                    mx: 0.5,
                  },
                  [theme.breakpoints.down("md")]: {
                    fontSize: 14,
                  },
                  // cursor: completedSteps.includes(step) ? "pointer" : "initial"
                }}
                key={step}
              >
                {step}
              </Typography>
            ))}
          </Box>
          {currentStep === 5 ? (
            <Button
              size="small"
              variant="contained"
              type="submit"
              my={1}
              sx={{
                width: 150,
                height: 40,
                background: theme?.palette?.inputsLabels?.green,
                color: "white",
                my: 1,
              }}
              onClick={createUpdateEventCamp}
            >
              Publish Event
            </Button>
          ) : null}
          {currentStep === 1 && (
            <FormControlLabel
              my={1}
              sx={{
                my: 1,
                mx: 0,
                "& .MuiFormControlLabel-label": {
                  ml: 1,
                },
              }}
              control={
                <CustomSwitch
                  name={"doNotshowSprint"}
                  checked={doNotshowSprint}
                  onChange={(e) => setDoNotShowSprint(e?.target?.checked)}
                  sx={{ m: 0 }}
                />
              }
              label={"Hide from Sprint View"}
            />
          )}
        </Box>
        {currentStep === 1 && (
          <CreateCampStep1
            step={currentStep}
            onNext={() => onNext(2)}
            selectedEvent={selectedEvent}
            handleCancel={closeStepsView}
            doNotshowSprint={doNotshowSprint}
            eventType={eventType?.type}
            isEditEvent={isEditEvent}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 2 && (
          <CreateCampStep2
            step={currentStep}
            onNext={() => onNext(3)}
            onBack={() => onBack(currentStep)}
            selectedEvent={selectedEvent}
            handleCancel={closeStepsView}
            eventType={eventType?.type}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 3 && (
          <CreateCampStep3
            step={currentStep}
            onNext={() => onNext(4)}
            onBack={() => onBack(currentStep)}
            selectedEvent={selectedEvent}
            handleCancel={closeStepsView}
            eventType={eventType?.type}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 4 && (
          <CreateCampStep4
            step={currentStep}
            onNext={() => onNext(5)}
            onBack={() => onBack(currentStep)}
            selectedEvent={selectedEvent}
            handleCancel={closeStepsView}
            eventType={eventType?.type}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 5 && (
          <CreateCampStep5
            step={currentStep}
            onNext={() => onNext(5)}
            onBack={() => onBack(currentStep)}
            selectedEvent={selectedEvent}
            handleCancel={closeStepsView}
            eventType={eventType?.type}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
      </CardContent>
      {showCancelConfirmPopup && (
        <CancelConfirmDialog
          title={"Create/Update Event"}
          message={"Are you sure want to cancel?"}
          open={showCancelConfirmPopup}
          handleClose={() => setShowCancelConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelConfirmPopup(false);
          }}
          handleConfirmClose={closeStepsView}
          confirmBtnLabel={"Yes"}
        />
      )}
    </Card>
  );
}
