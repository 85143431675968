import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const Image = styled('img')(({ theme, is_small }) => ({
    width: `auto`,
    maxHeight: is_small ? `100px` : `120px`,
    [theme.breakpoints.up('sm')]: {
        // maxWidth: `240px`,
        maxWidth: `100% !important`,
        Height: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
        // maxWidth: `120px`,
        maxWidth: `100% !important`,
        Height: 'auto'
    },
}))

const Logo = ({ size }) => {
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )

    return org.image_dark ? (
        <Image src={org.image_dark} alt="Logo" is_small={size || undefined} />
    ) : (
        <Typography variant="h1" sx={{ fontSize: '3rem' }}>
            {org.title}
        </Typography>
    )
}

export default Logo
