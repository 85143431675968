import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TOAST,
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import styled from "@emotion/styled";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import httpClient from "@utils/httpClient";
import { useNavigate } from "react-router";
import { Tournament_Paid_Roster_Athlete_List } from "../../routes/constants";
import { useState } from "react";
import SuccessPopup from "../Common/SuccessPopup";
import { ReactComponent as PoweredByStripe } from "../../assets/images/svg/Powered-by-Stripe.svg";
import { formatCurrency } from "../../utils/functions";

const CheckoutForm = ({
  handleClose,
  formData,
  type,
  afterPayemntMethod,
  payAmount,
  isShowCancelBtn,
  isFromEventRegister,
}) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const cardElementOptions = {
    hidePostalCode: true,
    required: true,
  };
  const StyledCardElement = styled(CardElement)`
    border: 1px solid #000000;
    padding: 15px;
    font-size: 18px;
    color: #000000;
    background-color: #fafafa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    border: 1px solid #bdbdbd;
    height: 48px;
    width: 100%,

    ::placeholder {
      color: #999999;
    }

    :focus,
    :hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    :invalid {
      color: #ff0000;
    }
  `;

  // const validationSchema = Yup.object({
  //   cardName: Yup.string().trim(),
  //   // firstName: Yup.string().trim().required("First Name is required"),
  //   // lastName: Yup.string().trim().required("Last Name is required"),
  //   // email: Yup.string()
  //   //   .trim()
  //   //   .required("Email is required")
  //   //   .email("Must be a valid email"),
  //   // address: Yup.string().required("Address is required"),
  //   // city: Yup.string().required("City is required"),
  //   // state: Yup.string().required("State is required"),
  //   // zip: Yup.string().max(10).required("Zip Code is required"),
  // });

  const handleSubmit = async (values) => {
    if (!stripe || !elements) {
      return;
    }

    let cardElement = elements.getElement(CardElement);
    const result = await stripe.confirmCardPayment(formData?.clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    dispatch({ type: SET_LOADER, data: true });
    if (result.error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            "Your card was declined by Stripe due to some reasons, please try again with different card details",
        },
      });
      dispatch({ type: SET_LOADER, data: false });
    } else {
      setTimeout(() => {
        afterPayemntMethod();
      }, 3000);
      //   dispatch({ type: SET_LOADER, data: true });
      //   let paymentData = {
      //     team_id: formData.team_id,
      //     transaction_id: formData.transaction_id,
      //     amount: formData.amount
      //   }
      //   if (type === "athlete") {
      //     paymentData.athlete_count = formData?.athlete_count;
      //     paymentData.athleteData = formData?.athleteData ?? [];
      //   }
      //   const API_URL = type === 'team' ? "/team-payments/teamPayment" : "/athlete-payments/athletePayment"
      //   const apiRes = await httpClient.post(API_URL, paymentData)
      //   dispatch({ type: SET_LOADER, data: false });
      //   if (apiRes && apiRes.ok) {
      //     setShowSuccessModal(true);
      //   }
      //   else {
      //     dispatch({ type: SET_LOADER, data: false });
      //     dispatch({
      //       type: SET_TOAST,
      //       data: {
      //         type: 'error',
      //         message: apiRes.message,
      //       },
      //     });
      //   }
    }
    dispatch({ type: SET_LOADER, data: false });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     cardName: ""
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: handleSubmit,
  // });

  const handleSuccessModalClose = () => {
    handleClose(true);
    if (type === "package") {
      return;
    }
    if (type === "team") {
      navigate("/tournament/add-player", {
        state: { teamId: formData?.team_id ?? "" },
      });
    }
    if (type === "athlete") {
      navigate(Tournament_Paid_Roster_Athlete_List, {
        replace: true,
        state: {
          teamName: formData?.title,
          teamId: formData?.team_id ?? "",
        },
      });
    }
  };

  return (
    <>
      <Box width={1} mt={3} ml={isFromEventRegister ? 2 : 0}>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={isFromEventRegister ? 12 : 6}>
            <CustomInputLabel sx={inputLabelStyle} htmlFor="card-name">
              Name on the card
            </CustomInputLabel>
            <CustomTextField
              id="card-name"
              name="cardName"
              placeholder="Enter card name..."
              variant="outlined"
              size="small"
              required={true}
              // onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              // value={formik.values.cardName}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "12px 14px !important",
                },
                width: "100%",
              }}
            />
            {/* {Boolean(formik.touched.cardName && formik.errors.cardName) && (
                <FormHelperText error>{formik.errors.cardName}</FormHelperText>
              )} */}
          </Grid>

          <Grid item xs={12} md={isFromEventRegister ? 12 : 6}>
            <CustomInputLabel sx={inputLabelStyle}>
              Card Number / EXPRIATION DATE / CVC
            </CustomInputLabel>
            <StyledCardElement options={cardElementOptions} />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                maxWidth: isFromEventRegister ? "135px" : "200px",
                minWidth: isFromEventRegister ? "135px" : "auto",
                width: "100%",
                height: isFromEventRegister ? "38px" : "auto",
              }}
            >
              <PoweredByStripe />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                [theme.breakpoints.down("md")]: {
                  alignItems: "start",
                },
                justifyContent: isFromEventRegister ? "start" : "end",
              }}
            >
              {isShowCancelBtn && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1.2, mr: 2 }}
                  color="inherit"
                  onClick={() => handleClose(true)}
                >
                  Cancel
                </Button>
              )}
              {isFromEventRegister ? (
                <Button
                  // onClick={onNext}
                  sx={{
                    background: `${theme?.palette?.inputsLabels?.green} !important`,
                    color: "white",
                    width: "100% !important",
                    padding: "0.3rem !important",
                    borderRadius: "6px !important",
                    height: "38px",
                  }}
                  // type="submit"
                  onClick={handleSubmit}
                >
                  Register
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  onClick={handleSubmit}
                  sx={{ paddingX: 3.5, paddingY: 1.2 }}
                >
                  Pay {payAmount ? formatCurrency(payAmount) : ""}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* </form> */}
      </Box>
      {showSuccessModal && (
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to see next view"
        />
      )}
    </>
  );
};

const StripePayment = ({
  open,
  handleClose,
  formData,
  type,
  handleChange,
  afterPayemntMethod,
  payAmount,
  isShowCancelBtn = true,
  isFromEventRegister = false,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const stripePromise = loadStripe(formData.publishableKey);
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  return (
    <Elements stripe={stripePromise}>
      <form onSubmit={handleSubmit}>
        <CheckoutForm
          open={open}
          handleClose={handleClose}
          formData={formData}
          type={type}
          handleChange={handleChange}
          afterPayemntMethod={afterPayemntMethod}
          payAmount={payAmount}
          isShowCancelBtn={isShowCancelBtn}
          isFromEventRegister={isFromEventRegister}
        />
      </form>
    </Elements>
  );
};

export default StripePayment;
