import { Button, Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, deleteAdminUser, getAdminUsers } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import AddEditAdminUser from "../../components/Admin/AddEditAdminUser";
import SuccessPopup from "../../components/Common/SuccessPopup";
import ActionCard from "../../components/Common/ActionCard";
import { Box, useTheme } from "@mui/system";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { useNavigate } from 'react-router-dom';
import { Org_Settings } from "../../routes/constants";
import { phoneFormat } from "../../utils/functions";

const AdminUsers = () => {
  const tableRef = useRef();
  const navigate = useNavigate();
  const [adminUsers, setAdminUsers] = useState();
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] = useState(false);
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAdminUsersList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAdminUsersList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAdminUsers(params);
    if (response?.ok) {
      setAdminUsers(response?.data ?? []);
    }
  };

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 330,
      flex: 1,
      sortable: false,
    },
    {
      headerName: "",
      flex: 0.55,
      sortable: false,
    },
    {
      field: "contact",
      headerName: "Mobile",
      renderCell: (data) => {
        let mobileNo = data?.row?.mobile
        return data?.row?.mobile ? phoneFormat(mobileNo) : "N/A";
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={handleClick}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)" }
              }}
            >
              <ActionCard title={"Org Admin"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingX: 5.5, paddingY: 1 }}
                    onClick={handleEditAdminClick}
                  >
                    Edit Admin Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, paddingX: 5.5, paddingY: 1, background: theme?.palette?.error?.dark }}
                    onClick={handleDeleteAdminClick}
                  >
                    Delete Admin
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        )
      }
    },
  ];

  const handleModalClose = (isClosed) => {
    setShowAddEditModal(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
    } else {
      setShowSuccessPopup(true);
      getAdminUsersList();
    }
  }

  const handleEditAdminClick = () => {
    handleClose();
    setShowAddEditModal(true);
  }

  const handleDeleteAdminClick = () => {
    handleClose();
    setShowDeletePopup(true);
  }

  const handleConfirm = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteAdminUser(dispatch, params, { id: selectedAdmin?.id });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message ?? "Something went wrong" }
      })
      setShowDeletePopup(false);
      setSelectedAdmin("");
      getAdminUsersList();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
      })
    }
  }


  return (
    <Box>
      <MuiDataGrid
        headerLabel={"Organization Admins"}
        gridData={adminUsers}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={adminUsers ? false : true}
        onClickAddNew={() => { setShowAddEditModal(true); setSelectedAdmin("") }}
        handleRowClick={(data) => setSelectedAdmin(data?.row)}
        gridToolbarButtons={() => {
          return (
            <Button
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1 }}
              onClick={() => navigate(Org_Settings)}
            >
              Back To Settings
            </Button>
          )
        }}
      />
      {showAddEditModal &&
        <AddEditAdminUser
          open={showAddEditModal}
          handleClose={handleModalClose}
          adminData={selectedAdmin}
        />
      }
      {showSuccessPopup &&
        <SuccessPopup
          title={"Organization Admin"}
          message={"Success! User details have been added/updated."}
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
        />
      }
      {showDeletePopup &&
        <DeleteConfirmPopup
          title={"Organization Admin"}
          message={"Are you sure you want to delete this admin?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedAdmin("");
          }}
          handleConfirm={handleConfirm}
        />
      }
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Organization Admin"}
          message={"Are you sure you want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setShowAddEditModal(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
          }}
        />
      )}
    </Box>
  );
};

export default AdminUsers;
