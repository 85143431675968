const initialState = {
  currentStep: 1,
  step1Details: null,
  step2Details: null,
  step3Details: null,
  step4Details: null,
};

const actionTypes = {
  CURRENT_STEP: "CURRENT_STEP",
  STEP_1_DETAILS: "STEP_1_DETAILS",
  STEP_2_DETAILS: "STEP_2_DETAILS",
  STEP_3_DETAILS: "STEP_3_DETAILS",
  STEP_4_DETAILS: "STEP_4_DETAILS",
  RESET_EVENT_DATA: "RESET_EVENT_DATA"
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CURRENT_STEP: {
      return {
        ...state,
        step: action.data,
      };
    }
    case actionTypes.STEP_1_DETAILS: {
      return {
        ...state,
        step1Details: action.data,
      };
    }
    case actionTypes.STEP_2_DETAILS: {
      return {
        ...state,
        step2Details: action.data,
      };
    }
    case actionTypes.STEP_3_DETAILS: {
      return {
        ...state,
        step3Details: action.data,
      };
    }
    case actionTypes.STEP_4_DETAILS: {
      return {
        ...state,
        step4Details: action.data,
      };
    }
    case actionTypes.RESET_EVENT_DATA: {
      return {
        currentStep: 1,
        step1Details: null,
        step2Details: null,
        step3Details: null,
        step4Details: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
