import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    Typography,
    useTheme, TextField, Select, OutlinedInput, MenuItem, Checkbox, ListItemText
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADER, getRoleTypes, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import httpClient from "@utils/httpClient";
import { useEffect } from "react";

const AddHelpVideo = ({ open, handleClose, helpVideoObj }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [roleList, setRoleList] = useState([])
    const [selectedRole, setSelectedRole] = useState([]);

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
            },
        },
    };
    const state = useSelector((state) => state);
    const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

    const validationSchema = Yup.object({
        title: Yup.string().trim().required("title is required"),
        desc: Yup.string().trim(),
        url: Yup.string().trim().required("URL is required"),
        user_type: Yup.string().required("please select At least one role"),
    });

    useEffect(() => {
        getRoleList();
        if (helpVideoObj?.id) {
            formik.setFieldValue("id", helpVideoObj?.id)
            formik.setFieldValue("title", helpVideoObj?.title)
            formik.setFieldValue("desc", helpVideoObj?.desc)
            formik.setFieldValue("url", helpVideoObj?.url)
            formik.setFieldValue("user_type", helpVideoObj?.user_type)
            setSelectedRole(helpVideoObj?.user_type ? helpVideoObj?.user_type.split(",") : [])
        }
    }, [])

    const handleSave = async (values, actions) => {
        if (!values?.id) {
            delete values?.id;
        }
        dispatch({ type: SET_LOADER, data: true });
        const res = await httpClient.post("/createHelpVideo", values);
        dispatch({ type: SET_LOADER, data: false });
        if (res && res.ok) {
            actions.resetForm();
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: res.message ?? "Video Add or Updated successfully" }
            })

            handleClose(false)
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
            })
        }
    };

    const formik = useFormik({
        initialValues: {
            id: "",
            title: "",
            desc: "",
            url: "",
            user_type: ""
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    const getRoleList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        };
        let response = await getRoleTypes(params, dispatch);
        if (response?.ok) {
            setRoleList(response?.data ?? []);
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
            })
        }
    }

    const handleChangeRole = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedRole(value);
        formik.setFieldValue("user_type", value?.join(','));
    };

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="sm"
            fullWidth
            onClose={() => handleClose(true)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    pt={0.5}
                    sx={{
                        [theme.breakpoints.down("sm")]: {
                            p: 0,
                        }
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Add Help Video
                    </Typography>
                </Box>
            </DialogTitle>
            <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }} mb={0}>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{
                    paddingX: 5,
                    [theme.breakpoints.down("sm")]: {
                        px: 3,
                    }
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomInputLabel sx={inputLabelStyle}>
                                Role
                            </CustomInputLabel>
                            <Select
                                name="user_type"
                                labelId="user_type-lblid"
                                multiple
                                displayEmpty
                                value={selectedRole}
                                onChange={handleChangeRole}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Role"
                                        sx={{ width: "100%", height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                renderValue={(selected) => {
                                    if (selected?.length && roleList?.length) {
                                        let titles = [];
                                        roleList?.filter((role) => {
                                            if (selected.includes(role?.value)) {
                                                titles.push(role?.name);
                                            }
                                        });
                                        if (titles?.length) {
                                            return titles.join(", ");
                                        }
                                        return selected?.join(", ");
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Roles...
                                            </Typography>
                                        );
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {roleList?.map((role) => (
                                    <MenuItem key={role?.value} value={role?.value}>
                                        <Checkbox
                                            checked={selectedRole.indexOf(role?.value) > -1}
                                        />
                                        <ListItemText primary={role?.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched.user_type && formik.errors.user_type) && (
                                <FormHelperText error>{formik.errors.user_type}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInputLabel htmlFor="title-id">
                                Title
                            </CustomInputLabel>
                            <CustomTextField
                                id="title-id"
                                name="title"
                                placeholder="Enter title..."
                                variant="outlined"
                                size="small"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                            />
                            {Boolean(formik.touched.title && formik.errors.title) && (
                                <FormHelperText error>{formik.errors.title}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInputLabel htmlFor="desc">
                                Description
                            </CustomInputLabel>
                            <TextField
                                name="desc"
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.desc}
                                sx={{
                                    width: "100%",
                                    ".MuiInputBase-input": {
                                        padding: "10.5px 14px !important",
                                        outline: "none",
                                    },
                                }}
                                // className="input-style"
                                placeholder="Enter description..."
                                error={Boolean(formik.touched.desc && formik.errors.desc)}
                                multiline
                                rows={3}
                            />
                            {Boolean(formik.touched.desc && formik.errors.desc) && (
                                <FormHelperText error>{formik.errors.desc}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInputLabel htmlFor="url-id">
                                URL
                            </CustomInputLabel>
                            <CustomTextField
                                id="url-id"
                                name="url"
                                placeholder="Enter url..."
                                variant="outlined"
                                size="small"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.url}
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                        width: "100%",
                                        boxShadow: "none",
                                    },
                                }}
                            />
                            {Boolean(formik.touched.url && formik.errors.url) && (
                                <FormHelperText error>{formik.errors.url}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{
                    px: 5,
                    pt: 2,
                    pb: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                        px: 3,
                    }
                }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        color="inherit"
                        onClick={() => handleClose(true)}
                    >
                        Close
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
};

export default AddHelpVideo;