import {
    Box,
    Button,
    Grid,
    Typography,
    useTheme, Tooltip, Popover
} from '@mui/material';
import SettingsIcon from "@mui/icons-material/Settings";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
    SET_TOAST,
    SET_LOADER, SET_SUCCESS_ERROR_POPUP, getTournaments
} from '../../store/actions';
import MuiDataGrid from '../../components/MuiDataGrid/MuiDataGrid';
import { useRef } from 'react';
import { charValidate } from '../../utils/functions';
import ActionCard from '../../components/Common/ActionCard';
import httpClient from "@utils/httpClient";
import AdminTournamentForm from '../../components/Tournament/AdminTournamentForm';
import { Tournament_Teams_Page } from '../../routes/constants';
import StatusButton from '../../components/Common/StatusButton';

const AdminTournament = () => {
    const navigate = useNavigate();

    const [showTournamentRegistration, setShowTournamentRegistration] = useState(false);
    const [selectedDataGridRow, setSelectedDataGridRow] = useState({});
    const [paginationOptions, setPaginationOptions] = useState({
        pageSize: 0,
        pageNum: 0,
        offset: 0,
        limit: 10,
    });
    const [isTournamentTeamListLoading, setTournamentTeamListLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tournamentData, setTournamentData] = useState([])

    const theme = useTheme();
    const state = useSelector((state) => state);
    const userType = state?.user?.user?.user_type;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const location = useLocation();
    const isShowTeamManagerRegistrationForm = location?.state?.isShowTeamManagerRegistrationForm;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const user_type = state?.user?.user?.user_type;

    useEffect(() => {
        if (isShowTeamManagerRegistrationForm) {
            setShowTournamentRegistration(true);
        }
    }, [isShowTeamManagerRegistrationForm]);

    useEffect(() => {
        getTournamentsList()
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            field: "name",
            headerName: "TOURNAMENT",
            flex: 1,
            minWidth: 130,
            sortable: false,
            renderCell: (data) => (
                <Tooltip title={data?.row?.name ?? "N/A"}>
                    {data?.row?.name ? data?.row?.name : "N/A"}
                </Tooltip>
            ),
        },
        {
            field: "primary_manager?.first_name",
            headerName: "OWNER FIRST NAME",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.primary_manager?.first_name ?? "N/A"}>
                    {data?.row?.primary_manager?.first_name ? charValidate(data?.row?.primary_manager?.first_name, 15) : "N/A"}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "primary_manager?.last_name",
            headerName: "OWNER LAST NAME",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.primary_manager?.last_name ?? "N/A"}>
                    {data?.row?.primary_manager?.last_name ? charValidate(data?.row?.primary_manager?.last_name, 15) : "N/A"}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "total_team",
            headerName: "TOTAL ALLOWED TEAMS",
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (data) => data?.row?.total_team == 0 ? "Unlimited" : data?.row?.total_team,
        },
        {
            field: "entry_fee?.cost",
            headerName: "TEAM FEE",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.entry_fee?.cost ?? "N/A"}>
                    ${data?.row?.entry_fee?.cost ? data?.row?.entry_fee?.cost : 0}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "entry_fee?.athlete_fee",
            headerName: "PER PLAYER FEE",
            flex: 1,
            minWidth: 100,
            renderCell: (data) => (
                <Tooltip title={data?.row?.entry_fee?.athlete_fee ?? 0}>
                    ${data?.row?.entry_fee?.athlete_fee ? data?.row?.entry_fee?.athlete_fee : 0}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (data) => {
                return (
                    <StatusButton status={data?.row?.status} style={{ fontWeight: 600, }} />
                )
            },
            sortable: false,
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (data) => {
                return (
                    <>
                        <Box>
                            <SettingsIcon
                                onClick={handleClick}
                                sx={{ fontSize: 22, cursor: "pointer" }}
                                color="primary"
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    p: 1,
                                    "& .MuiPaper-root": { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)" }
                                }}
                            >
                                <ActionCard title={"Actions"}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        {
                                            user_type === 'admin' &&
                                            <Button
                                                size="small"
                                                variant="contained"
                                                fullWidth
                                                sx={{ paddingX: 5.5, paddingY: 1 }}
                                                onClick={handleEditTournament}
                                            >
                                                Edit Details
                                            </Button>
                                        }

                                        <Button
                                            size="small"
                                            variant="contained"
                                            fullWidth
                                            sx={{ paddingX: 5.5, paddingY: 1, mt: 1 }}
                                            onClick={() => {
                                                if (user_type === 'admin' || user_type === 'teammanager' || user_type === 'teamcoach') {
                                                    navigate(
                                                        Tournament_Teams_Page,
                                                        {
                                                            state:
                                                            {
                                                                tournamentId: selectedDataGridRow?.id,
                                                                tournamentName: selectedDataGridRow?.name
                                                            }
                                                        }
                                                    );
                                                } else {
                                                    navigate(`/admin-tournament-team/${selectedDataGridRow?.id}`);
                                                }
                                            }}
                                        >
                                            View Teams
                                        </Button>
                                    </Box>
                                </ActionCard>
                            </Popover>
                        </Box>
                    </>
                )
            },
        },
    ];


    const validationSchema = Yup.object({
        name: Yup.string().trim().required("Tournament name is required"),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
        total_team: Yup.number().integer('This field should contain an integer').min(0, 'Total Team must be greater than or equal to 0').required("Total Team is required"),
        address: Yup.string().trim().required("Address is required"),
        city: Yup.string().trim().required("City is required"),
        state: Yup.string().trim().required("State is required"),
        zip_code: Yup.number().min(0).test(
            "noEOrSign", // type of the validator (should be unique)
            "Zip code is required and should be a number", // error message
            (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
        ).required("Zip Code is required"),
        entry_fee: Yup.string().required("Entry Fee is required"),
        sub_organization: Yup.string().required("Sub-Organization is required"),
        owner: Yup.array().min(1, "please select At least one owner"),
        division: Yup.array().min(1, "please select At least one division"),
        document: Yup.string().required("please select At least one document"),
        division_grade: Yup.object().test('grades-required', 'All divisions must have at least one grade', (divisionGrade) => {
            const divisionIds = Object.keys(divisionGrade);
            return divisionIds.every((divisionId) => divisionGrade[divisionId].length > 0);
        }),
    });

    const handleSave = async (values, actions) => {
        const street = {
            address: values?.address,
            city: values?.city,
            state: values?.state,
            zip_code: values?.zip_code,
        }
        const reqPayload = { ...values, street }
        if (isEdit) {
            reqPayload.id = selectedDataGridRow?.id
        }
        dispatch({ type: SET_LOADER, data: true });
        const res = await httpClient.post("/createTournament", reqPayload);
        dispatch({ type: SET_LOADER, data: false });

        if (res && res.ok) {
            actions.resetForm();
            const successMessage = isEdit ? 'Tournament updated successfully.' : 'Tournament created successfully.'
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: successMessage }
            })
            setIsEdit(false);
            handleClose()
            setSelectedDataGridRow({});
            setShowTournamentRegistration(false)
            getTournamentsList()
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
            })
            return false;
        }
    };

    const formik = useFormik({
        initialValues: {
            // id: "",
            name: "",
            start_date: "",
            end_date: "",
            total_team: "" || 0,
            description: "",
            address: "",
            city: "",
            state: "",
            zip_code: "",
            entry_fee: "",
            sub_organization: "",
            schedule: "",
            rules: "",
            legal: "",
            owner: [],
            division: [],
            grade: [],
            document: "",
            primary_manager: state?.user?.user?.id || "",
            division_grade: {}
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    const getTournamentsList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            skip: paginationOptions?.offset ?? 0,
            limit: paginationOptions?.limit ?? 10,
        };
        let response = await getTournaments(params, dispatch);
        if (response?.ok) {
            setTournamentData(response?.data?.data ?? []);
            setPaginationOptions({
                totalMainDocs: response?.data?.totalMainDocs ?? 0,
                totalDocs: response?.data?.totalDocs ?? 0,
                offset: response?.data?.offset ?? 0,
                limit: response?.data?.limit ?? 10,
                totalPages: response?.data?.totalPages ?? 0,
                currentPage: response?.data?.currentPage ?? 0,
                hasPrevPage: response?.data?.hasPrevPage ?? false,
                hasNextPage: response?.data?.hasNextPage ?? false,
            });
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
            })
        }
    }

    const handlePagination = (values) => {
        let page = values?.page;
        let pageSize = values?.pageSize;
        paginationOptions.offset = page * pageSize
        paginationOptions.limit = pageSize ?? 10
        getTournamentsList();
    };

    const handleEditTournament = () => {
        setIsEdit(true)
        setShowTournamentRegistration(true);
    }

    const renderAllTeams = () => {
        return (
            <Box>
                <MuiDataGrid
                    headerLabel={"Tournaments"}
                    gridData={isTournamentTeamListLoading ? [] : tournamentData}
                    columns={columns}
                    paginationOptions={paginationOptions}
                    handlePagination={handlePagination}
                    tableRef={tableRef}
                    isAddNewBtnShow={user_type === 'admin' ? true : false}
                    isLoading={isTournamentTeamListLoading}
                    onClickAddNew={() => setShowTournamentRegistration(true)}
                    handleRowClick={(data) => {
                        setSelectedDataGridRow(data?.row);
                    }}
                    rightBtnLabel="Create New"
                />
            </Box>
        )
    }

    if (showTournamentRegistration) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
                <Box
                    sx={{
                        width: "95%",
                        position: "relative",
                        backgroundColor: theme?.palette?.background?.paper,
                        boxShadow: theme?.palette?.boxShadow?.tableContainer,
                        borderRadius: 1,
                        border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
                    }}
                    mt={4}
                >
                    <Box sx={{ pt: 3, }}>
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={20}
                            color={theme?.palette?.text?.containerTextLabel}
                            sx={{ pr: 3, pl: 3, pb: 2.5, pt: 1.5 }}
                        >
                            Tournament Details: Gridiron Games 2023
                        </Typography>
                        <Box
                            width={1}
                            height={"1px"}
                            bgcolor={"gray"}
                            sx={{ opacity: 0.25 }}
                            mt={1}
                        ></Box>
                        <form onSubmit={formik.handleSubmit}>
                            <AdminTournamentForm formik={formik} isEdit={isEdit} selectedDataGridRow={selectedDataGridRow} />

                            <Grid container spacing={2} sx={{
                                mt: 10, mb: 4, px: 3,
                                [theme.breakpoints.down("md")]: {
                                    mt: 5,
                                    mb: 2,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    mt: 2,
                                    mb: 3,
                                }
                            }}>
                                <Grid item xs={6}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                                        color='inherit'
                                        onClick={() => {
                                            formik.resetForm();
                                            setIsEdit(false);
                                            handleClose()
                                            setSelectedDataGridRow({});
                                            setShowTournamentRegistration(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                                    >
                                        Save Tournament
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return renderAllTeams()
    }

}
export default AdminTournament;
