import OTPInput, { ResendOTP } from "otp-input-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { SET_LOADER, SET_USER } from "@store/actions";
import httpClient from "@utils/httpClient";
import { SET_TOKEN } from "../../store/actions";
import { ACCESS_CODE_PAGE, DASHBOARD_PAGE } from "../../routes/constants";

const OtpContainer = styled(Box)({
  marginTop: 24,
  "& .otp-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .otp-input": {
    borderRadius: "0.25rem",
    fontWeight: "bold",
    backgroundColor: "#fff !important",
    transition: "border-color 0.2s ease-in-out",
    width: "100% !important",
    height: "85px !important",
    margin: "0 0.25rem !important ",
    fontSize: "1.5rem",
    textAlign: "center",
    border: "0",
    background: "transparent",
    boxShadow: "0px 6px 20px #1F54C326",
  },
  "& .otp-input:focus": {
    outline: "none",
  },
  "& .resend-container": {
    marginTop: 16,
    justifyContent: "center !important",
  },
});

const OtpScreen = ({
  step,
  setStep,
  formData,
  setFormData,
  requestOtp,
  verifySmsOtp,
  isFromPackageBuy = false,
  setUserToken = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const [showError, setShowError] = useState(null);
  const [otp, setOtp] = useState("");

  const goBack = () => {
    setFormData((prevStage) => {
      return {
        ...prevStage,
        verify_code: null,
      };
    });
    setStep(step - 1);
  };

  const resendButton = (buttonProps) => {
    if (buttonProps.remainingTime === 0) {
      return (
        <Button
          disableElevation
          variant="text"
          className="textUnderline"
          onClick={buttonProps.onClick}
          sx={{
            fontSize: "12px",
            padding: "0",
            color: "#383C51",
            fontFamily: "Arial",
          }}
        >
          Resend
        </Button>
      );
    }
    return (
      <Typography
        variant="body1"
        textAlign="center"
        justifyContent="center"
        sx={{ my: "9px" }}
      >
        {`Please wait for ${buttonProps.remainingTime} sec`}
      </Typography>
    );
  };

  const handleResendClick = async () => {
    let otp_token = null;
    let goNext = false;
    try {
      setShowError(null);
      const requestOtpRes = await requestOtp(formData.verify_type);
      if (isMounted.current) {
        if (!requestOtpRes.ok && requestOtpRes.message) {
          setShowError(requestOtpRes.message);
        } else if (formData.verify_type === "email") {
          otp_token = requestOtpRes.data.otp_token || null;
          goNext = true;
        } else if (formData.verify_type === "sms") {
          goNext = true;
        }
      } else {
        return;
      }
    } catch (err) {
      setShowError("Something went wrong! Please try to refresh.");
    }
    if (goNext) {
      setFormData((prevStage) => {
        return {
          ...prevStage,
          otp_token,
        };
      });
    }
  };

  const editDetail = () => {
    setStep(step + 1);
  };

  const signupAction = async (otpCode) => {
    const { passwordConfirmation, ...postData } = formData;
    postData["verify_code"] = otpCode;
    let enableToProceed = false;
    if (formData?.verify_type === "sms") {
      const smsRes = await verifySmsOtp(otpCode);
      if (!smsRes.ok && smsRes.message) {
        setShowError(smsRes.message);
      } else {
        postData["otp_token"] = smsRes.data.user.uid;
        enableToProceed = true;
      }
    } else if (formData.verify_type === "email") {
      enableToProceed = true;
    }

    if (enableToProceed) {
      setShowError(null);
      dispatch({ type: SET_LOADER, data: true });
      // const formattedMobileChild = postData?.mobile?.slice(postData.countryCode.length);
      // const formattedMobileParent = postData?.parent_mobile?.slice(postData.parent_countryCode.length);
      let bodyParams = {
        ...postData,
        //   mobile: formattedMobileChild,
        //   parent_mobile: formattedMobileParent,
        formattedMobile: undefined,
      };
      const res = await httpClient.post(
        isFromPackageBuy
          ? "/auth/local/purchase-register"
          : "/auth/local/iys-register",
        bodyParams,
      );
      dispatch({ type: SET_LOADER, data: false });
      if (res && res?.ok) {
        if (isFromPackageBuy) {
          setUserToken(res?.token ?? "");
          setStep(5);
          return;
        }
      }

      if (isMounted.current) {
        if (res && res.ok) {
          if (!isFromPackageBuy) {
            setStep(step + 2);
          }
          const { token, user } = res;
          if (isMounted.current) {
            localStorage.setItem("userDetail", JSON.stringify(user));
            localStorage.setItem("usertoken", token);
            dispatch({ type: SET_USER, data: user });
            dispatch({ type: SET_TOKEN, data: token });
            if (!!user.has_org_approval) {
              // setStep(step + 2);
              // navigate(DASHBOARD_PAGE);
            } else {
              navigate(ACCESS_CODE_PAGE);
            }
          }
        } else if (res.message) {
          setShowError(res.message);
          setOtp("");
        }
      }
    }
    dispatch({ type: SET_LOADER, data: false });
  };

  useEffect(() => {
    if (otp.length === 6) {
      signupAction(otp);
    }
  }, [otp]);

  return (
    <Grid container direction="column" justifyContent="left" sx={{ p: 2 }}>
      <Grid item xs={12} alignItems="center" textAlign="center">
        <Typography variant="h3" sx={{ fontSize: "14px", color: "#3B3B3B" }}>
          Great! We've sent your OTP to your preferred option.
        </Typography>
      </Grid>
      <Grid item xs={12} alignItems="center" textAlign="center">
        <Typography
          variant="body1"
          sx={{
            my: 2,
            fontFamily: '"Poppins",sans-serif,Arial',
            color: "#3B3B3B",
            fontSize: "14px",
          }}
        >
          Please enter your One-Time PIN code.
        </Typography>
      </Grid>
      {showError && (
        <Grid item xs={12} alignItems="center" justifyContent="left">
          <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
            {showError}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={{ maxWidth: "460px", margin: "0 auto", width: "100%" }}>
          <OtpContainer textAlign="center">
            <OTPInput
              autoFocus
              otpType="number"
              OTPLength={6}
              value={otp}
              onChange={setOtp}
              disabled={false}
              inputClassName="otp-input"
              className="otp-container"
              sx={{
                "& .otp-input": {
                  width: "inherit",
                },
              }}
            />
            <ResendOTP
              maxTime={30}
              className="resend-container"
              renderButton={resendButton}
              renderTime={() => null}
              onResendClick={handleResendClick}
            />
          </OtpContainer>

          {/* Email Detail */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ mt: 5 }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              align="center"
              sx={{
                fontSize: "12px",
                padding: "0",
                color: "#383C51",
                fontFamily: "Arial",
                fontWeight: 600,
                lineHeight: "1",
              }}
            >
              {formData?.parent_email}
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              className="pointer textUnderline"
              align="center"
              sx={{
                fontSize: "12px",
                padding: "0",
                color: "#383C51",
                fontFamily: "Arial",
              }}
              onClick={editDetail}
            >
              Edit Email
            </Typography>
          </Stack>

          {/* Mobile Detail */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ mt: 3 }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              align="center"
              sx={{
                fontSize: "12px",
                padding: "0",
                color: "#383C51",
                fontFamily: "Arial",
                fontWeight: 600,
                lineHeight: "1",
              }}
            >
              {formData?.parent_mobile}
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              className="pointer textUnderline"
              align="center"
              onClick={editDetail}
              sx={{
                fontSize: "12px",
                padding: "0",
                color: "#383C51",
                fontFamily: "Arial",
              }}
            >
              Edit Mobile
            </Typography>
          </Stack>

          {/* GO BACK */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ mt: 6 }}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              className="pointer textUnderline"
              align="center"
              onClick={goBack}
              sx={{ fontSize: "12px", padding: "0", color: "#383C51" }}
            >
              Go Back
            </Typography>
          </Stack>
          {/* <Button variant='contained' fullWidth sx={{ mb: 3, mt: 4 }} onClick={() => setStep(5)}>Next</Button> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OtpScreen;
