import {
    Box,
    FormControlLabel,
    Grid,
    Checkbox
} from "@mui/material";
import UserInfoForm from "../../components/AppForms/UserInfoForm";


const AddEditParentGuardian = ({ isEdit, formik }) => {

    return (
        <>
            <Box className="guardian-form-box" sx={{
                '&.guardian-form-box div': {
                    maxWidth: '100%',
                }
            }}>
                <UserInfoForm formik={formik} isEmailPhoneDisabled={isEdit} />
            </Box>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="primary_parent_flag"
                            checked={formik.values.primary_parent_flag}
                            onChange={formik.handleChange}
                            color="primary"
                        />
                    }
                    label="Set as primary parent/guardian."
                />
            </Grid>
        </>
    );
};

export default AddEditParentGuardian;
