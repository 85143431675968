import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    styled, Card, CardContent
} from "@mui/material";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import AddressForm from "../../components/AppForms/AddressForm";
import UserInfoForm from "../../components/AppForms/UserInfoForm";

const validationSchema = Yup.object({
    first_name: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("First Name is required"),
    last_name: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Last Name is required"),
    address: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Address is required"),
    suite: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable(),
    city: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("City is required"),
    state: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("State is required"),
    zip_code: Yup.string().max(10).transform(value => (value === '' ? null : value)).nullable().required("Zip Code is required"),
});


const EditAthlete = ({ step, setStep, formData, setFormData, setActiveAthleteId }) => {
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(null);

    const handleSave = (values, actions) => {
        setShowError(null);
        setFormData((prevStage) => {
            return {
                ...prevStage,
                ...values,
            };
        });
        setStep(step + 1);
    };
    const formik = useFormik({
        initialValues: {
            first_name: formData?.first_name || "",
            last_name: formData?.last_name || "",
            email: formData?.email || "",
            mobile: formData?.mobile || "",
            countryCode: formData?.countryCode || "",
            address: formData?.address || "",
            suite: formData?.suite || "",
            city: formData?.city || "",
            state: formData?.state || "",
            zip_code: formData?.zip_code || "",
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    useEffect(() => {
        formik.setFieldValue("first_name", formData?.first_name)
        formik.setFieldValue("last_name", formData?.last_name)
        formik.setFieldValue("email", formData?.email)
        formik.setFieldValue("mobile", formData?.mobile)
        formik.setFieldValue("countryCode", formData?.countryCode)
        formik.setFieldValue("address", formData?.address)
        formik.setFieldValue("suite", formData?.suite)
        formik.setFieldValue("city", formData?.city)
        formik.setFieldValue("state", formData?.state)
        formik.setFieldValue("zip_code", formData?.zip_code)
    }, [formData?.id])


    return (
        <>
            <Box sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} sm={12} sx={{ my: 1 }}>
                    <Typography variant="h2" sx={{ color: '#262626' }}>
                        Edit Athlete's Name & Address
                    </Typography>
                </Grid>
            </Box>

            {showError && (
                <Grid item xs={12} alignItems="center" justifyContent="left">
                    <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
                        {showError}
                    </Alert>
                </Grid>
            )}
            <form onSubmit={formik.handleSubmit}>
                <Box className="guardian-form-box" sx={{
                    '&.guardian-form-box div': {
                        maxWidth: '100%',
                    }
                }}>
                    <UserInfoForm formik={formik} isEmailPhoneDisabled={formData?.email ? true : false} />
                </Box>
                <Divider sx={{ mt: 4, mb: 4 }}></Divider>
                <Box className="guardian-form-box" sx={{
                    '&.guardian-form-box div': {
                        maxWidth: '100%',
                    }
                }}>
                    <AddressForm formik={formik} />
                </Box>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Button
                            size="large"
                            variant="outlined"
                            sx={{ fontWeight: "600", mt: 3, width: "45%" }}
                            onClick={() => { setFormData({}); setStep(step - 3); }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disableElevation
                            disabled={formik.isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ fontWeight: "600", mt: 3, width: "45%" }}
                        >
                            Next
                        </Button>
                    </Box>
                </Grid>
            </form>
        </>
    );
};

export default EditAthlete;
