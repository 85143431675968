import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  SET_TOAST,
  createTeamByTeamManagerAction,
  getTeamCoachAction,
  getTeamManagerAction,
  SET_LOADER,
  getTournaments,
  getTournamentTeams,
  getAllTeamsAction,
  getAllTournamentTeamToCSV,
  uploadFileAction,
  getAllSubOrg,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import AddTeamManager from "../../components/Tournament/AddTeamManager";
import AddTeamCoach from "../../components/Tournament/AddTeamCoach";
import UploadRosterDocuments from "../../components/Tournament/UploadRosterDocuments";
import ComingSoon from "../../components/ComingSoon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import { useRef } from "react";
import {
  charValidate,
  formatCurrency,
  getFileNameFromFirebaseUrl,
} from "../../utils/functions";
import ActionCard from "../../components/Common/ActionCard";
import {
  Tournament_Add_Player_Page,
  Tournament_Paid_Roster_Athlete_List,
  Tournament_Roster_Athlete_List,
  Tournament_Teams_Page,
} from "../../routes/constants";
import TeamPaymentAmount from "../../components/Tournament/TeamPaymentAmount";
import httpClient from "@utils/httpClient";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FeePaymentDescription from "../../components/TournamentTeams/FeePaymentDescription";
import EditRegisteredTeamDetails from "../../components/TournamentTeams/EditRegisteredTeamDetails";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { APP_MESSAGES } from "../../utils/constants";
import AddEditSubOrgTeams from "../../components/SubOrgTeams/AddEditSubOrgTeams";
import SuccessPopup from "../../components/Common/SuccessPopup";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";

const Tournament = () => {
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const textInputStyle = (isDisabled) => {
    return {
      width: 300,
      ".MuiInputBase-input": {
        padding: "10.5px 14px !important",
        outline: "none",
        cursor: isDisabled ? "not-allowed" : "inherit",
      },
    };
  };
  const addBtnStyle = {
    ml: 2,
    mt: 3,
    fontSize: "22px",
    padding: "0px 7px",
    minWidth: "50px",
  };
  const mainBox = {
    width: "100%",
    //height: "75px",
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    padding: "1px",
    borderRadius: "10px",
    borderColor: "transparent",
    mt: 2,
    cursor: "pointer",
  };
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const selectionInputStyle = { width: "100%", height: 40, width: 300 };

  const [divisionGradeList, setDivisionGradeList] = useState([]);
  const [teamCoachList, setTeamCoachList] = useState([]);
  const [teamManagerList, setTeamManagerList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState([]);
  const [createdCoachName, setCreatedCoachName] = useState("");
  const [selectedTeamManager, setSelectedTeamManager] = useState([]);
  const [singleSelectedTeamManager, setSingleSelectedTeamManager] =
    useState("");
  const [createdManagerName, setCreatedManagerName] = useState("");
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [showCoachSelectionRequiredError, setShowCoachSelectionRequiredError] =
    useState(false);
  const [
    showTeamManagerSelectionRequiredError,
    setShowTeamManagerSelectionRequiredError,
  ] = useState(false);
  const [showAddTeamManagerModal, setShowAddTeamManagerModal] = useState(false);
  const [showAddTeamCoachModal, setShowAddTeamCoachModal] = useState(false);
  const [showTeamPaymentModal, setShowTeamPaymentModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [showTeamManagerRegistration, setShowTeamManagerRegistration] =
    useState(false);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverAnchorEl2, setPopoverAnchorEl2] = useState(null);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [isTournamentTeamListLoading, setTournamentTeamListLoading] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTeamsForPaymentAll, setSelectedTeamsForPaymentAll] = useState(
    [],
  );
  const [paymentType, setPaymentType] = useState("");
  const [disablePaymentButtons, setDisablePaymentButtons] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState();
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [showTeamDetailsEditModal, setShowTeamDetailsEditModal] =
    useState(false);
  const [subOrgList, setSubOrgList] = useState([]);
  const [subOrgId, setsubOrgId] = useState("all");
  const [filterTournamentId, setFilterTournamentId] = useState("all");
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);

  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const openPopover2 = Boolean(popoverAnchorEl2);
  const id2 = openPopover2 ? "simple-popover" : undefined;

  const [divisionItems, setDivisionItems] = useState([]);
  const tournamentItems = ["Gridiron Games"];
  const [tournamentData, setTournamentData] = useState([]);
  const openDivisionTypes = ["4th grade", "6th grade"];
  const recDivisionTypes = ["3rd grade", "4th grade", "5th grade", "6th grade"];
  const theme = useTheme();
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = state?.user?.user?.user_type;
  const tableRef = useRef();
  const location = useLocation();
  const isShowTeamManagerRegistrationForm =
    location?.state?.isShowTeamManagerRegistrationForm;
  const hasTeamControlAccess =
    userType === "teammanager" ||
    userType === "teamcoach" ||
    userType === "admin" ||
    userType === "superadmin"
      ? true
      : false;
  const tournamentId = location?.state?.tournamentId ?? "";
  const tournamentName = location?.state?.tournamentName ?? "";
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [hasSubOrgMore, setHasSubOrgMore] = useState(true);
  const [subOrgOffset, setSubOrgOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (isShowTeamManagerRegistrationForm) {
      setShowTeamManagerRegistration(true);
    }
  }, [isShowTeamManagerRegistrationForm]);

  const handleClosePopover = () => {
    setPopoverAnchorEl2(null);
  };

  let columns = [
    // {
    //   field: "tournament.name",
    //   headerName: "TOURNAMENT",
    //   flex: 1,
    //   // minWidth: 160,
    //   renderCell: (data) => (
    //     <Tooltip title={data?.row?.tournament?.name ?? "N/A"}>
    //       {data?.row?.tournament?.name ? charValidate(data?.row?.tournament?.name, 20) : "N/A"}
    //     </Tooltip>
    //   ),
    //   sortable: false,
    // },
    {
      field: "sub_organizationsData.title",
      headerName: "LEAGUE",
      flex: 1,
      maxWidth: 170,
      minWidth: 170,
      renderCell: (data) => (
        <Tooltip title={data?.row?.sub_organizationsData?.title ?? "N/A"}>
          {data?.row?.sub_organizationsData?.title
            ? charValidate(data?.row?.sub_organizationsData?.title, 20)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "title",
      headerName: "TEAM NAME",
      flex: 1,
      minWidth: 150,
      renderCell: (data) => (
        // userType === "admin" || userType === "teammanager" ?
        <Tooltip title={data?.row?.title ?? "N/A"}>
          <Typography
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            fontFamily={"Poppins"}
            fontSize={14}
            fontWeight={600}
            color={theme?.palette?.grey["700"]}
            onClick={() => setShowTeamDetailsEditModal(true)}
          >
            {data?.row?.title ? charValidate(data?.row?.title, 20) : "N/A"}
          </Typography>
        </Tooltip>
        // :
        // <Tooltip title={data?.row?.title ?? "N/A"}>
        //   {data?.row?.title ? charValidate(data?.row?.title, 20) : "N/A"}
        // </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "athletePaymentDone",
      headerName: "Payment Status",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.athletePaymentDone && data?.row?.teamPaymentDone
              ? "Payment has done"
              : "Payment has not done yet, please pay team registration and roster fee"
          }
        >
          <Box display="flex" alignItems="center">
            {data?.row?.athletePaymentDone && data?.row?.teamPaymentDone ? (
              <CheckCircleIcon
                sx={{ fontSize: "35px", color: theme.palette.success.dark }}
              />
            ) : (
              <WatchLaterIcon
                sx={{ fontSize: "35px", color: theme.palette.warning.dark }}
              />
            )}
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
  ];

  let allTeamsColumns = [
    {
      field: "tournament.name",
      headerName: "TOURNAMENT",
      flex: 1,
      minWidth: 140,
      renderCell: (data) => (
        <Tooltip title={data?.row?.tournament?.name ?? "N/A"}>
          {data?.row?.tournament?.name
            ? charValidate(data?.row?.tournament?.name, 20)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "sub_organizationsData.title",
      headerName: "LEAGUE",
      flex: 1,
      minWidth: 140,
      renderCell: (data) => (
        <Tooltip title={data?.row?.sub_organizationsData?.title ?? "N/A"}>
          {data?.row?.sub_organizationsData?.title
            ? charValidate(data?.row?.sub_organizationsData?.title, 20)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "title",
      headerName: "TEAM NAME",
      flex: 1,
      minWidth: 140,
      renderCell: (data) => (
        <Tooltip title={data?.row?.title ?? "N/A"}>
          {data?.row?.title ? charValidate(data?.row?.title, 20) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "coachEmail",
      headerName: "COACH EMAIL",
      flex: 1,
      minWidth: 190,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) => {
        if (data?.row?.team_coach && data?.row?.team_coach?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.team_coach?.map((teamCoach) => (
                <Tooltip title={teamCoach?.email ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4, wordBreak: "break-word" }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {teamCoach?.email ? teamCoach?.email : "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          );
        } else {
          return "N/A";
        }
      },
      sortable: false,
    },
    {
      field: "teamManagerEmail",
      headerName: "TEAM MANAGER EMAIL",
      flex: 1,
      minWidth: 190,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) => {
        if (data?.row?.team_manager && data?.row?.team_manager?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.team_manager?.map((teamManager) => (
                <Tooltip title={teamManager?.email ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4, wordBreak: "break-word" }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {teamManager?.email ? teamManager?.email : "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            <Box>
              <SettingsIcon
                onClick={(event) => {
                  setPopoverAnchorEl2(event.currentTarget);
                }}
                sx={{ fontSize: 22, cursor: "pointer" }}
                color="primary"
              />
              <Popover
                id={id2}
                open={openPopover2}
                anchorEl={popoverAnchorEl2}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{
                  p: 1,
                  "& .MuiPaper-root": {
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                  },
                }}
              >
                <ActionCard title={"Actions"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{ paddingX: 5.5, paddingY: 1, mt: 1 }}
                      onClick={() => redirectToPaidRoster()}
                    >
                      View Roster
                    </Button>
                  </Box>
                </ActionCard>
              </Popover>
            </Box>
          </>
        );
      },
    },
  ];

  const adminAndTeammanagerColumn = [
    ...columns,
    {
      field: "team_fee",
      headerName: "TEAM FEE",
      flex: 1,
      minWidth: 170,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.athlete_count === 0 &&
            data?.row?.paid_athlete_count === 0
              ? "Add Roster and Pay Roster Fee to see the Paid Rosters List"
              : ""
          }
        >
          <Button
            size="small"
            variant="contained"
            fullWidth
            sx={{
              paddingY: 1.2,
              width: "150px",
              background: data?.row?.teamPaymentDone
                ? theme.palette.success.dark
                : theme.palette.dark["800"],
            }}
            disabled={
              data?.row?.teamPaymentDone
                ? data?.row?.athlete_count === 0 &&
                  data?.row?.paid_athlete_count === 0
                  ? true
                  : false
                : disablePaymentButtons
            }
            onClick={() => {
              if (data?.row?.teamPaymentDone) {
                if (data?.row?.athlete_count === 0) {
                  navigate(Tournament_Paid_Roster_Athlete_List, {
                    state: {
                      teamName: data?.row?.title,
                      teamId: data?.row?.id,
                    },
                  });
                } else {
                  navigate(Tournament_Roster_Athlete_List, {
                    state: { teamId: data?.row?.id ?? "" },
                  });
                }
              } else {
                setSelectedTeamsForPaymentAll([]);
                setSelectedDataGridRow(data?.row);
                setPaymentType("TEAM");
                setShowPaymentMethodModal(true);
                // handlePaymentProcess(data?.row?.id);
              }
            }}
          >
            {data?.row?.teamPaymentDone
              ? data?.row?.athlete_count === 0
                ? "View Roster"
                : "View Unpaid Roster"
              : `Pay Fee (${formatCurrency(data?.row?.team_total_fees)})`}
          </Button>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "athlete_fee",
      headerName: "ROSTER FEES",
      flex: 1,
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.teamPaymentDone
              ? data?.row?.athlete_count === 0 &&
                data?.row?.paid_athlete_count === 0
                ? "Add Roster and Pay Roster Fee to see the Paid Rosters List"
                : ""
              : "Pay Team Fee First Before Pay Roster Fee"
          }
        >
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
          >
            <Button
              size="small"
              variant="contained"
              fullWidth
              sx={{
                paddingTop: 1.3,
                paddingBottom: 0.9,
                paddingX: 2,
                width: "auto",
                background:
                  data?.row?.athletePaymentDone ||
                  data?.row?.athlete_total_fees === 0
                    ? theme.palette.success.dark
                    : theme.palette.dark["800"],
              }}
              disabled={
                data?.row?.athlete_count === 0 &&
                data?.row?.paid_athlete_count === 0
                  ? true
                  : !data?.row?.teamPaymentDone || disablePaymentButtons
              }
              onClick={() => {
                if (
                  data?.row?.athletePaymentDone ||
                  data?.row?.athlete_total_fees === 0
                ) {
                  navigate(Tournament_Paid_Roster_Athlete_List, {
                    state: {
                      teamName: data?.row?.title,
                      teamId: data?.row?.id,
                    },
                  });
                } else {
                  setSelectedTeamsForPaymentAll([]);
                  setSelectedDataGridRow(data?.row);
                  setPaymentType("ROSTER");
                  setShowPaymentMethodModal(true);
                  // handlePaymentProcess(data?.row?.id);
                }
              }}
            >
              {data?.row?.athletePaymentDone ||
              data?.row?.athlete_total_fees === 0
                ? "View Roster"
                : `Pay Fee (${formatCurrency(data?.row?.athlete_total_fees)})`}
            </Button>
            <Box display="flex" flexDirection="row" mx={1}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  m: 0.5,
                  mx: 1,
                }}
                onClick={() => {
                  navigate(Tournament_Roster_Athlete_List, {
                    state: { teamId: data?.row?.id ?? "" },
                  });
                }}
              >
                Edit
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  m: 0.5,
                  mx: 1,
                }}
                onClick={() => {
                  navigate(Tournament_Add_Player_Page, {
                    state: {
                      teamId: data?.row?.id ?? "",
                      rosterCount: data?.row?.paid_athlete_count,
                    },
                  });
                }}
              >
                Add
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
  ];

  if (userType === "parent" || userType === "player") {
    columns.splice(2, 0, {
      field: "coachEmail",
      headerName: "COACH EMAIL",
      flex: 1,
      minWidth: 200,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) => {
        if (data?.row?.team_coach && data?.row?.team_coach?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.team_coach?.map((teamCoach) => (
                <Tooltip title={teamCoach?.email ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4, wordBreak: "break-word" }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {teamCoach?.email ? teamCoach?.email : "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          );
        } else {
          return "N/A";
        }
      },
      sortable: false,
    });
    columns.splice(3, 0, {
      field: "teamManagerEmail",
      headerName: "TEAM MANAGER EMAIL",
      flex: 1,
      minWidth: 200,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) => {
        if (data?.row?.team_manager && data?.row?.team_manager?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.team_manager?.map((teamManager) => (
                <Tooltip title={teamManager?.email ?? "N/A"}>
                  <Typography
                    sx={{ pt: 0.4, wordBreak: "break-word" }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {teamManager?.email ? teamManager?.email : "N/A"}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          );
        } else {
          return "N/A";
        }
      },
      sortable: false,
    });
    columns.pop();
    columns.push({
      field: "sub_organizationsData.org_code",
      headerName: "ACCESS CODE",
      flex: 1,
      minWidth: 120,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) => (
        <Tooltip title={data?.row?.sub_organizationsData?.org_code ?? "N/A"}>
          {data?.row?.sub_organizationsData?.org_code
            ? charValidate(data?.row?.sub_organizationsData?.org_code, 15)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    });
    columns.push({
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            <Box>
              <SettingsIcon
                onClick={(event) => {
                  setPopoverAnchorEl2(event.currentTarget);
                }}
                sx={{ fontSize: 22, cursor: "pointer" }}
                color="primary"
              />
              <Popover
                id={id2}
                open={openPopover2}
                anchorEl={popoverAnchorEl2}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{
                  p: 1,
                  "& .MuiPaper-root": {
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                  },
                }}
              >
                <ActionCard title={"Actions"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{ paddingX: 5.5, paddingY: 1, mt: 1 }}
                      onClick={() => {
                        navigate(
                          `/admin-tournament/admin-tournament-team/team-roster/${data?.row?.id}`,
                        );
                      }}
                    >
                      View Roster
                    </Button>
                  </Box>
                </ActionCard>
              </Popover>
            </Box>
          </>
        );
      },
    });
  }

  const validationSchema = Yup.object({
    division: Yup.string().required("Division selection is required"),
    teamName: Yup.string().trim().required("Team name is required"),
    gradeOrAgeGroup: Yup.string().required(
      "Grade or age group selection is required",
    ),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      division: "",
      tournament: tournamentId ?? "",
      teamName: "",
      gradeOrAgeGroup: "",
      coachFirstName: "",
      coachLastName: "",
      email: "",
      mobile: "",
      countryCode: "US",
      teamManagerFirstName: state?.user?.user?.first_name ?? "",
      teamManagerLastName: state?.user?.user?.last_name ?? "",
      teamManagerEmail: state?.user?.user?.email ?? "",
      teamManagerMobile: state?.user?.user?.mobile ?? "",
      teamManagerCountryCode: state?.user?.user?.countryCode ?? "US",
      largeLogo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // if (!selectedCoach?.length && !selectedTeamManager?.length) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: {
      //       type: "error",
      //       message: "Team Coach or Team Manager selection is required",
      //     },
      //   });
      // if (!selectedCoach?.length) {
      //   setShowCoachSelectionRequiredError(true);
      //   return;
      // }
      if (!selectedTeamManager?.length) {
        setShowTeamManagerSelectionRequiredError(true);
        return;
      }
      //}
      const coachManagerFilterData = teamManagerList.filter((x) =>
        selectedTeamManager.includes(x.id),
      );
      const team_manager_and_team_coach = coachManagerFilterData.map(
        (item) => ({
          id: item.id,
          user_type: item.user_type,
        }),
      );

      let bodyParams = {
        title: values?.teamName.trim() ?? "",
        division: values?.division,
        grade: values?.gradeOrAgeGroup,
        // team_coach: selectedCoach ?? [],
        // team_manager: selectedTeamManager ?? [],
        team_manager_and_team_coach: team_manager_and_team_coach,
        //tournament: location?.state?.tournamentId ?? ""
        tournament: values?.tournament ?? "",
        largeLogo: values?.largeLogo ?? "",
      };
      if (values?.id) {
        bodyParams.id = values.id;
      }
      createTeam(bodyParams);
    },
  });

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getTeamCoach();
      getTeamManager();
      // getAllTournamentTeam();
      getTournamentsList();
      getSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  useEffect(() => {
    if (divisionItems.length === 0 && tournamentId) {
      getTournamentsList();
    }
  }, [divisionItems, tournamentId]);

  useEffect(() => {
    if (selectedTeamsForPaymentAll?.length) {
      setDisablePaymentButtons(true);
    } else {
      setDisablePaymentButtons(false);
    }
  }, [selectedTeamsForPaymentAll]);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllTournamentTeam();
    }
  }, [
    state?.user?.token,
    state?.org?.org?.token,
    filterTournamentId,
    subOrgId,
  ]);

  const getTeamCoach = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getTeamCoachAction(params);
    if (response?.ok) {
      setTeamCoachList(response?.data ?? []);
    }
  };

  const getAllTournamentTeam = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    if (tournamentId) {
      params["tournamentId"] = tournamentId ?? "";
    }
    setTournamentTeamListLoading(true);
    try {
      let response = "";
      if (tournamentId) {
        response = await getTournamentTeams(params, dispatch);
        if (response?.ok) {
          setTeamsList(response?.data?.data?.TournamentList ?? []);
          setTournamentInfo(response?.data?.data?.TournamentInfo ?? []);
          setPaginationOptions({
            totalMainDocs: response?.data?.totalMainDocs ?? 0,
            totalDocs: response?.data?.totalDocs ?? 0,
            offset: response?.data?.offset ?? 0,
            limit: response?.data?.limit ?? 10,
            totalPages: response?.data?.totalPages ?? 0,
            currentPage: response?.data?.currentPage ?? 0,
            hasPrevPage: response?.data?.hasPrevPage ?? false,
            hasNextPage: response?.data?.hasNextPage ?? false,
          });
        }
      } else {
        params.tournamentId =
          filterTournamentId === "all" ? "" : filterTournamentId;
        params.subOrgId = subOrgId === "all" ? "" : subOrgId;
        response = await getAllTeamsAction(params, dispatch);
        if (response?.ok) {
          setTeamsList(response?.data?.data ?? []);
          setPaginationOptions({
            totalMainDocs: response?.data?.totalMainDocs ?? 0,
            totalDocs: response?.data?.totalDocs ?? 0,
            offset: response?.data?.offset ?? 0,
            limit: response?.data?.limit ?? 10,
            totalPages: response?.data?.totalPages ?? 0,
            currentPage: response?.data?.currentPage ?? 0,
            hasPrevPage: response?.data?.hasPrevPage ?? false,
            hasNextPage: response?.data?.hasNextPage ?? false,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong",
        },
      });
    }
    setTournamentTeamListLoading(false);
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize;
    paginationOptions.limit = pageSize ?? 10;
    getAllTournamentTeam();
  };

  const getTournamentsList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: offset,
      limit: 20,
    };
    let response = await getTournaments(params, dispatch);
    if (response?.ok) {
      const res = response?.data?.data ?? [];
      setTournamentData((prevOptions) => [...prevOptions, ...res]);
      setOffset((prevOffset) => prevOffset + res.length);
      if (res.length === 0) {
        setHasMore(false);
      }
      const tournament = res?.find((x) => x.id === tournamentId);
      setSelectedTournament(tournament);
      // if (tournament?.division_grade.length > 0) {
      //   setDivisionItems(tournament?.division_grade)
      // } else {
      //   setDivisionItems([])
      // }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            response.message ?? "Something went wrong for tournament data",
        },
      });
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target?.scrollHeight - target?.scrollTop === target?.clientHeight &&
      hasMore
    ) {
      getTournamentsList();
    }
  };

  const getSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: subOrgOffset,
      limit: 20,
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      const res = response?.data?.data ?? [];
      setSubOrgList((prevOptions) => [...prevOptions, ...res]);
      setSubOrgOffset((prevOffset) => prevOffset + res.length);
      if (res.length === 0) {
        setHasSubOrgMore(false);
      }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message:
            response.message ??
            "Something went wrong for sub-organization data",
        },
      });
    }
  };

  const handleSubOrgScroll = (e) => {
    const target = e.target;
    if (
      target?.scrollHeight - target?.scrollTop === target?.clientHeight &&
      hasSubOrgMore
    ) {
      getSubOrgList();
    }
  };

  const getTeamManager = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getTeamManagerAction(params);
    if (response?.ok) {
      let dataList = response?.data;
      // let selectedManager = dataList?.find((tm) => tm?.id == state?.user?.user?.id);
      // setSelectedTeamManager(selectedManager?.id ? [selectedManager?.id] : []);
      setTeamManagerList(dataList ?? []);
    }
  };

  const createTeam = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await createTeamByTeamManagerAction(
      dispatch,
      params,
      bodyParams,
    );
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response?.message ?? "Team created successfully",
        },
      });

      setIsEdit(false);
      formik.resetForm();
      setSelectedCoach([]);
      setThumbnailImage("");
      //setSingleSelectedTeamManager("")
      setSelectedTeamManager([]);
      navigate(Tournament_Add_Player_Page, {
        state: { teamId: response?.data?.id ?? "" },
      });
      // if (!isEdit) {
      //   // handlePaymentProcess(response?.data?.id);
      // } else {
      //   setShowTeamManagerRegistration(false);
      // }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleChangeCoachSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCoach(typeof value === "string" ? value.split(",") : value);
    setShowCoachSelectionRequiredError(false);
  };

  const handleChangeTeamManagerSelection = (event) => {
    const {
      target: { value },
    } = event;
    //setSingleSelectedTeamManager(value)
    setSelectedTeamManager(
      typeof value === "string" ? value.split(",") : value,
    );
    setShowTeamManagerSelectionRequiredError(false);
  };

  const handleTeamManagerModalClose = (
    isClosed,
    managerId = 0,
    managerName = "",
  ) => {
    setShowAddTeamManagerModal(false);
    if (!isClosed) {
      setShowTeamManagerSelectionRequiredError(false);
      setCreatedManagerName("");
      setSelectedTeamManager([]);
      getTeamManager();
      setSelectedTeamManager([managerId]);
      //setSingleSelectedTeamManager(managerId);
      setCreatedManagerName(managerName);
    }
  };

  const handleTeamCoachModalClose = (isClosed, coachId = 0, coachName = "") => {
    setShowAddTeamCoachModal(false);
    if (!isClosed) {
      setShowCoachSelectionRequiredError(false);
      setCreatedCoachName("");
      setSelectedCoach([]);
      getTeamCoach();
      setSelectedCoach([coachId]);
      setCreatedCoachName(coachName);
    }
  };

  const handleTeamPaymentAmountModalClose = () => {
    setShowTeamPaymentModal(false);
  };

  const handleEditTeam = (row) => {
    setIsEdit(true);
    formik.setFieldValue("id", row?.id);
    formik.setFieldValue("teamName", row?.title);
    formik.setFieldValue("division", row?.division);
    formik.setFieldValue("gradeOrAgeGroup", row?.grade);
    setSelectedCoach(row?.team_coach);
    setSelectedTeamManager(row?.team_manager);
    setShowTeamManagerRegistration(true);
  };

  const handleClosePaymentMethodModal = (isClosed) => {
    setShowPaymentMethodModal(false);
    if (!isClosed) {
      getAllTournamentTeam();
    }
  };

  const handleCloseEditTeamsDetailsModal = (isClosed) => {
    setShowTeamDetailsEditModal(false);
    if (!isClosed) {
      getAllTournamentTeam();
    }
  };

  const getAllTournamentTeamExportToCSV = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      tournamentId: tournamentId,
    };
    let response = await getAllTournamentTeamToCSV(params, dispatch);
    if (response?.ok) {
      downloadFile(response?.data);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response.message ?? "Success",
        },
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = ""; // You can specify the desired filename here

    document.body.appendChild(link);
    link.click();

    // Remove the link from the document after download
    document.body.removeChild(link);
  };

  const redirectToPaidRoster = () => {
    navigate(Tournament_Paid_Roster_Athlete_List, {
      state: {
        teamId: selectedTeam?.id,
      },
    });
  };

  const filterUI = () => {
    return (
      <>
        <Box
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          sx={{
            // mt: 1.5,
            [theme.breakpoints.down("md")]: {
              mt: 0,
              flexDirection: "column",
            },
          }}
        >
          <Select
            name="sub_organization"
            onChange={(e) => setsubOrgId(e.target.value)}
            value={subOrgId}
            input={<OutlinedInput sx={{ height: 40, width: 200 }} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  // width: 300,
                },
                onScroll: (event) => {
                  handleSubOrgScroll(event);
                },
              },
            }}
            inputProps={{
              "aria-label": "Without label",
            }}
            className="MuiSelect-select"
            sx={{
              m: 0.5,
              "& .MuiSelect-select": {
                padding: "10.5px 14px",
              },
              width: "100%",
              minWidth: "150px",
              [theme.breakpoints.down("md")]: {
                mx: 0,
                minWidth: "inherit",
              },
            }}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                let selected = subOrgList?.find(
                  (suborg) => suborg?.id === value,
                );
                if (selected) {
                  return selected?.title;
                }
                return "All";
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Filter by Sub-Organizations
                  </Typography>
                );
              }
            }}
            disabled={isEdit}
          >
            <MenuItem value="all">All</MenuItem>
            {subOrgList?.map((suborg) => (
              <MenuItem key={suborg?.id} value={suborg?.id}>
                {suborg?.title}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="filter_tournament"
            onChange={(e) => setFilterTournamentId(e.target.value)}
            value={filterTournamentId}
            input={<OutlinedInput sx={{ height: 40, width: 200 }} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  // width: "100%",
                },
                onScroll: (event) => {
                  handleScroll(event);
                },
              },
            }}
            inputProps={{
              "aria-label": "Without label",
            }}
            className="MuiSelect-select"
            sx={{
              m: 0.5,
              "& .MuiSelect-select": {
                padding: "10.5px 14px",
              },
              width: "100%",
              minWidth: "150px",
              [theme.breakpoints.down("md")]: {
                mx: 0,
                minWidth: "inherit",
              },
            }}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                let selected = tournamentData?.find(
                  (tournament) => tournament?.id === value,
                );
                if (selected) {
                  return selected?.name;
                }
                return "All";
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Filter by tournament
                  </Typography>
                );
              }
            }}
            disabled={isEdit}
          >
            <MenuItem value="all">All</MenuItem>
            {tournamentData?.map((tournament) => (
              <MenuItem key={tournament?.id} value={tournament?.id}>
                {tournament?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </>
    );
  };

  const handleSubOrgModalClose = (isClosed) => {
    setShowAddEditModal(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
    } else {
      setShowSuccessPopup(true);
      getAllTournamentTeam();
    }
  };

  const renderAllTeams = () => {
    return (
      <Box>
        <MuiDataGrid
          headerLabel={tournamentName ? `Teams (${tournamentName})` : "Teams"}
          gridData={isTournamentTeamListLoading ? [] : teamsList}
          columns={
            hasTeamControlAccess
              ? tournamentId
                ? adminAndTeammanagerColumn
                : allTeamsColumns
              : columns
          }
          paginationOptions={paginationOptions}
          handlePagination={handlePagination}
          tableRef={tableRef}
          isLoading={isTournamentTeamListLoading}
          isAddNewBtnShow={hasTeamControlAccess && tournamentId}
          onClickAddNew={() => setShowTeamManagerRegistration(true)}
          handleRowClick={(data) => {
            setSelectedTeam(data?.row);
          }}
          rightBtnLabel="Team Registration"
          options={{
            checkboxSelection:
              hasTeamControlAccess && tournamentId
                ? teamsList
                  ? teamsList?.length
                    ? true
                    : false
                  : false
                : false,
            disableRowSelectionOnClick: true,
            onRowSelectionModelChange: (teamId) => {
              setSelectedDataGridRow(undefined);
              setSelectedTeamsForPaymentAll(teamId);
            },
            isRowSelectable: (data) => {
              if (data?.row?.teamPaymentDone && data?.row?.athletePaymentDone) {
                return false;
              } else {
                return true;
              }
            },
          }}
          // showPayAllBtn={selectedTeamsForPaymentAll?.length ? true : false}
          handlePayAllPayment={() => setShowPaymentMethodModal(true)}
          // paymentBtnLabel="Pay Selected Fees"
          gridButtons={() => {
            return (
              <Box
                sx={{
                  my: 2,
                  px: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 1,
                }}
              >
                {tournamentId ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="inherit"
                    sx={{ paddingX: 3, paddingY: 0.8 }}
                    onClick={() => {
                      navigate("/admin-tournament");
                      // navigationState = undefined;
                    }}
                  >
                    Back
                  </Button>
                ) : null}
                {selectedTeamsForPaymentAll?.length && tournamentId ? (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3, paddingY: 1 }}
                    onClick={() => {
                      setPaymentType("TEAM_ROSTER_BOTH");
                      setShowPaymentMethodModal(true);
                    }}
                  >
                    Pay Selected Fees
                  </Button>
                ) : null}
              </Box>
            );
          }}
          gridToolbarButtons={() => {
            return (
              <>
                {userType === "admin" && tournamentId && (
                  <Button
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1, mr: 2 }}
                    onClick={() => {
                      getAllTournamentTeamExportToCSV();
                    }}
                  >
                    Export Report
                  </Button>
                )}
                {tournamentId && teamsList?.length ? (
                  <Button
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1 }}
                    onClick={() => {
                      navigate(`/view-and-add-score/${tournamentId}`);
                    }}
                  >
                    Tournament Scores & Details
                  </Button>
                ) : null}
                {!tournamentId && filterUI()}
                {!tournamentId && userType === "admin" && (
                  <Button
                    variant="contained"
                    // sx={{ width: "200px", paddingY: 1 }}
                    sx={{
                      paddingX: 3.5,
                      paddingY: 1.2,
                      whiteSpace: "nowrap",
                      ml: 0.4,
                      [theme.breakpoints.down("md")]: {
                        ml: 0,
                        mt: 0.5,
                        width: "100%",
                      },
                    }}
                    onClick={() => setShowAddEditModal(true)}
                  >
                    Add Team
                  </Button>
                )}
              </>
            );
          }}
        />
        {showTeamPaymentModal && (
          <TeamPaymentAmount
            open={showTeamPaymentModal}
            handleClose={handleTeamPaymentAmountModalClose}
            formData={paymentFormData}
            type={paymentType}
          />
        )}
        {showPaymentMethodModal && (
          <FeePaymentDescription
            open={showPaymentMethodModal}
            handleClose={handleClosePaymentMethodModal}
            teamIds={selectedTeamsForPaymentAll}
            dataList={teamsList}
            paymentType={paymentType}
            selectedDataGridRow={selectedDataGridRow}
            tournamentId={tournamentId}
          />
        )}
        {showTeamDetailsEditModal && (
          <EditRegisteredTeamDetails
            open={showTeamDetailsEditModal}
            handleClose={handleCloseEditTeamsDetailsModal}
            selectedTeam={selectedTeam}
            tournamentId={tournamentId}
          />
        )}
        {showAddEditModal && (
          <AddEditSubOrgTeams
            open={showAddEditModal}
            handleClose={handleSubOrgModalClose}
            data={{}}
          />
        )}
        {showSuccessPopup && (
          <SuccessPopup
            title={"Sub-Organization Team"}
            message={
              "Success! Your actions have been successfully implemented."
            }
            open={showSuccessPopup}
            handleClose={() => setShowSuccessPopup(false)}
          />
        )}
        {showCancelSaveConfirmPopup && (
          <CancelConfirmDialog
            title={"Sub-Organization Team"}
            message={"Are you sure, want to close without saving?"}
            open={showCancelSaveConfirmPopup}
            handleClose={() => setShowCancelSaveConfirmPopup(false)}
            handleCancel={() => {
              setShowCancelSaveConfirmPopup(false);
              setShowAddEditModal(true);
            }}
            handleConfirmClose={() => {
              setShowCancelSaveConfirmPopup(false);
              dispatch({
                type: "RESET_ORG_TEAMS_INFO",
                data: "reset",
              });
            }}
          />
        )}
      </Box>
    );
  };

  const hanleTournamentChange = (event) => {
    const tournamentId = event.target.value;
    formik.setFieldValue("tournament", tournamentId);
    const selected = tournamentData?.find(
      (tournament) => tournament?.id === tournamentId,
    );
    setSelectedTournament(selected);
    if (selected?.division_grade.length > 0) {
      setDivisionItems(selected?.division_grade);
    } else {
      setDivisionItems([]);
    }
  };

  const hanleDivisionChange = (event) => {
    const divisionId = event.target.value;
    formik.setFieldValue("division", divisionId);
    const selected = divisionItems?.find(
      (division) => division?.id === divisionId,
    );
    if (selected?.grades.length > 0) {
      setDivisionGradeList(selected?.grades);
    } else {
      setDivisionGradeList([]);
    }
  };

  const uploadImage = async (file) => {
    if (file) {
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      let formData = new FormData();
      formData.append("files", file);
      formData.append("type", "Team");
      formData.append("fileType", "Thumbnail");
      formData.append("userId", state?.user?.user?.id);
      let response = await uploadFileAction(dispatch, params, formData);
      if (response?.ok) {
        setThumbnailImage(getFileNameFromFirebaseUrl(response?.url));
        formik.setFieldValue("largeLogo", response?.url);
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response.message ?? "Logo Uploaded Successfully",
          },
        });
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  // if (userType === "teammanager" || userType === "teamcoach" || userType === "admin" || userType === "superadmin") {
  if (showTeamManagerRegistration) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
        <Box
          sx={{
            width: "95%",
            position: "relative",
            backgroundColor: theme?.palette?.background?.paper,
            boxShadow: theme?.palette?.boxShadow?.tableContainer,
            borderRadius: 1,
            border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
          }}
          mt={4}
        >
          <Box sx={{ pt: 3 }}>
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{ pl: 3, pb: 2.5, pt: 1.5 }}
            >
              Team Registration: {formik.values.division}
            </Typography>
            <Box
              width={1}
              height={"1px"}
              bgcolor={"gray"}
              sx={{ opacity: 0.25 }}
              mt={0}
            ></Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  p: 4,
                  [theme.breakpoints.down("md")]: {
                    p: 2,
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Box sx={{ mb: 2, width: "100%" }}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        Tournament
                      </CustomInputLabel>
                      <Select
                        name="tournament"
                        labelId="tournament"
                        onBlur={formik.handleBlur}
                        onChange={(e) => hanleTournamentChange(e)}
                        value={formik.values.tournament}
                        input={<OutlinedInput sx={selectionInputStyle} />}
                        MenuProps={MenuProps}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        renderValue={(value) => {
                          if (value) {
                            let selected = tournamentData?.find(
                              (tournament) => tournament?.id === value,
                            );
                            if (selected) {
                              return selected?.name;
                            }
                          } else {
                            return (
                              <Typography
                                variant="p"
                                fontWeight={400}
                                fontSize={14}
                                sx={{ opacity: 0.5 }}
                              >
                                Select tournament...
                              </Typography>
                            );
                          }
                        }}
                        className="MuiSelect-select"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10.5px 14px",
                          },
                          width: 1,
                          maxWidth: "100%",
                        }}
                      >
                        {tournamentData?.map((tournament) => (
                          <MenuItem key={tournament?.id} value={tournament?.id}>
                            {tournament?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(
                        formik.touched.tournament && formik.errors.tournament,
                      ) && (
                        <FormHelperText error>
                          {formik.errors.tournament}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    {divisionItems.length > 0 && (
                      <Box sx={{ width: "100%" }}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Choose Division
                        </CustomInputLabel>
                        <Select
                          name="division"
                          labelId="org-filter"
                          onBlur={formik.handleBlur}
                          onChange={(e) => hanleDivisionChange(e)}
                          value={formik.values.division}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = divisionItems?.find(
                                (division) => division?.id === value,
                              );
                              if (selected) {
                                return selected?.name;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select Division...
                                </Typography>
                              );
                            }
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: 1,
                            maxWidth: "100%",
                          }}
                        >
                          {divisionItems?.map((division) => (
                            <MenuItem key={division.id} value={division.id}>
                              {division.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          formik.touched.division && formik.errors.division,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.division}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    sx={{
                      marginTop: "1.5rem",
                      [theme.breakpoints.down("sm")]: {
                        marginTop: 0,
                      },
                    }}
                  >
                    {selectedTournament ? (
                      <Box
                        sx={{
                          background: "#EFF7FF",
                          border: `1px solid #7FBEFD`,
                          color: "black",
                          maxWidth: "20rem",
                          width: "100%",
                          borderRadius: "7px",
                          fontSize: "14px",
                          fontWeight: 500,
                          [theme.breakpoints.down("sm")]: {
                            maxWidth: "100%",
                          },
                        }}
                        p={1.4}
                      >
                        Team entry fee is ${selectedTournament?.team_fee} and
                        athlete fee is ${selectedTournament?.athlete_fee}.
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  px: 4,
                  [theme.breakpoints.down("md")]: {
                    px: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    height: 1.4,
                    background: "gray",
                    opacity: 0.3,
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  p: 4,
                  [theme.breakpoints.down("md")]: {
                    p: 2,
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Box width={1}>
                      <CustomInputLabel sx={inputLabelStyle}>
                        TEAM NAME
                      </CustomInputLabel>
                      <TextField
                        name="teamName"
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.teamName}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-input": {
                            padding: "10.5px 14px !important",
                            outline: "none",
                          },
                        }}
                        // className="input-style"
                        placeholder="Enter team name..."
                        error={Boolean(
                          formik.touched.teamName && formik.errors.teamName,
                        )}
                      />
                      {Boolean(
                        formik.touched.teamName && formik.errors.teamName,
                      ) && (
                        <FormHelperText error>
                          {formik.errors.teamName}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    {divisionGradeList.length > 0 && (
                      <>
                        <CustomInputLabel sx={inputLabelStyle}>
                          CHOOSE GRADE LEVEL
                        </CustomInputLabel>
                        <Select
                          name="gradeOrAgeGroup"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.gradeOrAgeGroup}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: 1,
                            maxWidth: "300px",
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = divisionGradeList?.find(
                                (grade) => grade?.id === value,
                              );
                              if (selected) {
                                return selected?.name;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select grade...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {divisionGradeList?.map((val) => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          formik.touched.gradeOrAgeGroup &&
                            formik.errors.gradeOrAgeGroup,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.gradeOrAgeGroup}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                {/* <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Box width={0.73}>
                          <CustomInputLabel sx={inputLabelStyle}>
                            Team Coach
                          </CustomInputLabel>
                          <Select
                            name="coachSelection"
                            multiple
                            displayEmpty
                            value={selectedCoach}
                            onChange={handleChangeCoachSelection}
                            input={
                              <OutlinedInput
                                sx={{ ...selectionInputStyle, width: 235 }}
                              />
                            }
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            renderValue={(selected) => {
                              if (selected?.length && teamCoachList?.length) {
                                let titles = [];
                                teamCoachList?.filter((coach) => {
                                  if (selected.includes(coach?.id)) {
                                    titles.push(`${coach?.first_name} ${coach?.last_name}`);
                                  }
                                });
                                if (titles?.length) {
                                  return titles.join(", ");
                                }
                                return selected?.join(", ");
                              } else {
                                return (
                                  <Typography
                                    variant="p"
                                    fontWeight={400}
                                    fontSize={14}
                                    sx={{ opacity: 0.5 }}
                                  >
                                    Select team coach...
                                  </Typography>
                                );
                              }
                            }}
                            MenuProps={MenuProps}
                            className="MuiSelect-select"
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "10.5px 14px"
                              },
                              width: 1,
                              maxWidth: "300px"
                            }}
                          >
                            <MenuItem disabled value="">
                              Select team coach...
                            </MenuItem>
                            {teamCoachList?.map((coach) => (
                              <MenuItem key={coach?.id} value={coach?.id}>
                                <Checkbox
                                  checked={selectedCoach.indexOf(coach?.id) > -1}
                                />
                                <ListItemText primary={`${coach?.first_name} ${coach?.last_name}`} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Button
                          size="medium"
                          variant="contained"
                          sx={addBtnStyle}
                          onClick={() => setShowAddTeamCoachModal(true)}
                        >
                          +
                        </Button>
                      </Box>
                    </Grid> */}
                {/* <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Button
                          size="medium"
                          variant="contained"
                          //sx={addBtnStyle}
                          onClick={() => setShowAddTeamCoachModal(true)}
                        >
                          Add Team Coach
                        </Button>
                      </Box>
                    </Grid> */}

                {/* <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Button
                          size="medium"
                          variant="contained"
                          //sx={addBtnStyle}
                          onClick={() => setShowAddTeamManagerModal(true)}
                        >
                          Add Coach/Team Manager
                        </Button>
                      </Box>
                    </Grid>
                    {showTeamManagerSelectionRequiredError && (
                      <Grid item xs={12}>
                        <FormHelperText error sx={{ fontSize: '14px', fontWeight: 700 }}>*Add Coach/Team Manager before proceeding further.</FormHelperText>
                      </Grid>
                    )} */}

                {/* <Grid item xs={6}>
                      {
                        createdCoachName &&
                        <Box display="flex" alignItems="center">
                          <CustomInputLabel sx={inputLabelStyle}>
                            Coach Name :
                          </CustomInputLabel>
                          <CustomInputLabel sx={{ fontSize: 12, marginBottom: "5px", ml: 1, fontFamily: 'poppins' }}>
                            {createdCoachName}
                          </CustomInputLabel>
                        </Box>
                      }
                    </Grid> */}
                {/* <Grid item xs={6}>
                      {
                        createdManagerName &&
                        <Box display="flex" alignItems="center">
                          <CustomInputLabel sx={inputLabelStyle}>
                            Manager Name :
                          </CustomInputLabel>
                          <CustomInputLabel sx={{ fontSize: 12, marginBottom: "5px", ml: 1, fontFamily: 'poppins' }}>
                            {createdManagerName}
                          </CustomInputLabel>
                        </Box>
                      }
                    </Grid> */}

                {/* <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <Box width={0.73}>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Team Manager
                        </CustomInputLabel>
                        <Select
                          name="teamManagerSelection"
                          onChange={handleChangeTeamManagerSelection}
                          value={singleSelectedTeamManager}
                          input={
                            <OutlinedInput
                              sx={selectionInputStyle}
                            />
                          }
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px"
                            },
                            width: 1,
                            maxWidth: "300px"
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = teamManagerList?.find((tm) => tm?.id === value);
                              if (selected) {
                                const fullName = selected?.first_name + " " + selected?.last_name
                                return fullName
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select team manager...
                                </Typography>
                              );
                            }
                          }}
                        >
                          {teamManagerList?.map((tm) => (
                            <MenuItem key={tm?.id} value={tm?.id}>
                              {tm?.first_name} {tm?.last_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Button
                        size="medium"
                        variant="contained"
                        sx={addBtnStyle}
                        onClick={() => setShowAddTeamManagerModal(true)}
                      >
                        +
                      </Button>
                    </Box>
                    {showTeamManagerSelectionRequiredError && (
                      <FormHelperText error>Team manager selection is required</FormHelperText>
                    )}
                  </Grid> */}

                <Grid container spacing={2} mt={0}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="add-coach"
                      sx={{
                        pr: "65px",
                        width: "100%",
                        position: "relative",
                        "& .MuiBox-root": {
                          width: "100%",
                        },
                      }}
                    >
                      <Box>
                        <CustomInputLabel sx={inputLabelStyle}>
                          Team Coach / Manager
                        </CustomInputLabel>
                        <Select
                          name="teamManagerSelection"
                          multiple
                          displayEmpty
                          value={selectedTeamManager}
                          onChange={handleChangeTeamManagerSelection}
                          input={
                            <OutlinedInput
                              sx={{ ...selectionInputStyle, width: 235 }}
                            />
                          }
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          renderValue={(selected) => {
                            if (selected?.length && teamManagerList?.length) {
                              let titles = [];
                              teamManagerList?.filter((tm) => {
                                if (selected.includes(tm?.id)) {
                                  titles.push(
                                    `${tm?.first_name} ${tm?.last_name}`,
                                  );
                                }
                              });
                              if (titles?.length) {
                                return titles.join(", ");
                              }
                              return selected?.join(", ");
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Select team coach/manager...
                                </Typography>
                              );
                            }
                          }}
                          MenuProps={MenuProps}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                            width: 1,
                            maxWidth: "100%",
                          }}
                        >
                          <MenuItem disabled value="">
                            Select team coach/manager...
                          </MenuItem>
                          {teamManagerList?.map((tm) => (
                            <MenuItem
                              // disabled={state?.user?.user?.id == tm?.id}
                              key={tm?.id}
                              value={tm?.id}
                            >
                              <Checkbox
                                checked={
                                  selectedTeamManager.indexOf(tm?.id) > -1
                                }
                                // disabled={state?.user?.user?.id == tm?.id}
                              />
                              <ListItemText
                                primary={`${tm?.first_name} ${tm?.last_name}`}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Button
                        size="medium"
                        variant="contained"
                        sx={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          height: "40px",
                          minWidth: "50px",
                          padding: "0",
                          fontSize: "1.5rem",
                        }}
                        onClick={() => setShowAddTeamManagerModal(true)}
                      >
                        +
                      </Button>
                    </Box>
                    {showTeamManagerSelectionRequiredError && (
                      <FormHelperText error>
                        Team coach / manager selection is required
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <CustomInputLabel
                      sx={inputLabelStyle}
                      htmlFor="sub-org-team-logo-lg"
                    >
                      Team Logo
                    </CustomInputLabel>
                    <FormControl
                      fullWidth
                      // error={Boolean(
                      //   formik.touched.largeLogo && formik.errors.largeLogo,
                      // )}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-formControl .MuiInputBase-formControl":
                          {
                            height: "35px",
                            padding: "12.5px 10px",
                          },
                        "& .MuiIconButton-root": {
                          background: "transparent",
                          borderRadius: "0 5px 5px 0",
                          left: 16,
                        },
                      }}
                    >
                      <InputLabel
                        htmlFor="sub-org-team-logo-lg"
                        shrink={false}
                        sx={{
                          fontFamily: "'Poppins', sans-serif, Arial",
                          fontWeight: 400,
                          fontSize: 14,
                        }}
                      >
                        {thumbnailImage ? thumbnailImage : "Upload image..."}
                      </InputLabel>
                      <OutlinedInput
                        id="sub-org-team-logo-lg"
                        name="thumbnailImage"
                        type={"file"}
                        onChange={(e) => uploadImage(e?.target?.files[0])}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="image"
                              edge="end"
                              size="large"
                              htmlFor="sub-org-team-logo-lg"
                              component="label"
                            >
                              <Box
                                sx={{
                                  width: 85,
                                  height: 44,
                                  backgroundColor:
                                    theme?.palette?.text?.containerTextLabel,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                htmlFor="sub-org-team-logo-lg"
                              >
                                <UploadFileIcon
                                  sx={{ fontSize: 20, color: "white" }}
                                />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          "& ::file-selector-button": {
                            opacity: 0,
                            display: "none",
                          },
                          "& .MuiOutlinedInput-root": {
                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                            width: "100%",
                            boxShadow: "none",
                            opacity: 0,
                          },
                          "& .MuiInputBase-input": {
                            padding: "11.5px 10px !important",
                          },
                        }}
                        inputProps={{
                          style: {
                            opacity: 0,
                          },
                          accept: "image/*",
                        }}
                      />
                      {/* <Typography variant="caption" display="block" gutterBottom>
                          {APP_MESSAGES.commentPicMaxSizeTxt}
                        </Typography> */}
                      {/* {Boolean(
                          formik.touched.largeLogo && formik.errors.largeLogo,
                        ) && (
                            <FormHelperText error>
                              {formik.errors.largeLogo ||
                                "File size is greater than maximum limit"}
                            </FormHelperText>
                          )} */}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      COACH FIRST NAME
                    </CustomInputLabel>
                    <TextField
                      name="coachFirstName"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.coachFirstName}
                      sx={textInputStyle(false)}
                      placeholder="Enter first name..."
                      error={Boolean(formik.touched.coachFirstName && formik.errors.coachFirstName)}
                    />
                    {Boolean(formik.touched.coachFirstName && formik.errors.coachFirstName) && (
                      <FormHelperText error>{formik.errors.coachFirstName}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      COACH LAST NAME
                    </CustomInputLabel>
                    <TextField
                      name="coachLastName"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.coachLastName}
                      sx={textInputStyle(false)}
                      placeholder="Enter last name..."
                      error={Boolean(formik.touched.coachLastName && formik.errors.coachLastName)}
                    />
                    {Boolean(formik.touched.coachLastName && formik.errors.coachLastName) && (
                      <FormHelperText error>{formik.errors.coachLastName}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      COACH EMAIL
                    </CustomInputLabel>
                    <TextField
                      type="email"
                      name="email"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      sx={textInputStyle(false)}
                      placeholder="Enter email..."
                      error={Boolean(formik.touched.email && formik.errors.email)}
                    />
                    {Boolean(formik.touched.email && formik.errors.email) && (
                      <FormHelperText error>{formik.errors.email}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <CustomInputLabel sx={inputLabelStyle}>
                    COACH PHONE
                  </CustomInputLabel>
                  <PhoneInput
                    country={"us"}
                    id="mobile"
                    name="mobile"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    onlyCountries={['us']}
                    disableCountryCode={true}
                    disableDropdown={true}
                    onChange={(value, country) => {
                      formik.setFieldValue('countryCode', country.countryCode || '')
                      formik.setFieldValue('mobile', value);
                    }}
                    inputClass=".form-control"
                    inputStyle={{
                      height: "41px",
                      width: "300px"
                    }}
                    containerStyle={{
                      height: "41px",
                      width: "300px"
                    }}
                    error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                  )}
                </Grid> */}

                {/* </Grid> */}

                {/* <Grid container spacing={2} sx={{ mt: 1 }}> */}
                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      TEAM MANAGER FIRST NAME
                    </CustomInputLabel>
                    <TextField
                      disabled={true}
                      name="teamManagerFirstName"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.teamManagerFirstName}
                      sx={textInputStyle(true)}
                      placeholder="Enter first name..."
                      error={Boolean(formik.touched.teamManagerFirstName && formik.errors.teamManagerFirstName)}
                    />
                    {Boolean(formik.touched.teamManagerFirstName && formik.errors.teamManagerFirstName) && (
                      <FormHelperText error>{formik.errors.teamManagerFirstName}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      TEAM MANAGER Last NAME
                    </CustomInputLabel>
                    <TextField
                      disabled={true}
                      name="teamManagerLastName"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.teamManagerLastName}
                      sx={textInputStyle(true)}
                      placeholder="Enter last name..."
                      error={Boolean(formik.touched.teamManagerLastName && formik.errors.teamManagerLastName)}
                    />
                    {Boolean(formik.touched.teamManagerLastName && formik.errors.teamManagerLastName) && (
                      <FormHelperText error>{formik.errors.teamManagerLastName}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Box width={1}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      TEAM MANAGER EMAIL
                    </CustomInputLabel>
                    <TextField
                      disabled={true}
                      type="email"
                      name="teamManagerEmail"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.teamManagerEmail}
                      sx={textInputStyle(true)}
                      placeholder="Enter email..."
                      error={Boolean(formik.touched.teamManagerEmail && formik.errors.teamManagerEmail)}
                    />
                    {Boolean(formik.touched.teamManagerEmail && formik.errors.teamManagerEmail) && (
                      <FormHelperText error>{formik.errors.teamManagerEmail}</FormHelperText>
                    )}
                  </Box>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <CustomInputLabel sx={inputLabelStyle}>
                    TEAM MANAGER PHONE
                  </CustomInputLabel>
                  <PhoneInput
                    disabled={true}
                    country={"us"}
                    name="teamManagerMobile"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.teamManagerMobile}
                    onlyCountries={['us']}
                    disableCountryCode={true}
                    disableDropdown={true}
                    onChange={(value, country) => {
                      formik.setFieldValue('teamManagerCountryCode', country.countryCode || '')
                      formik.setFieldValue('teamManagerMobile', value);
                    }}
                    inputClass=".form-control"
                    inputStyle={{
                      height: "41px",
                      width: "300px",
                    }}
                    containerStyle={{
                      height: "41px",
                      width: "300px"
                    }}
                    error={Boolean(formik.touched.teamManagerMobile && formik.errors.teamManagerMobile)}
                  />
                  {formik.touched.teamManagerMobile && formik.errors.teamManagerMobile && (
                    <FormHelperText error>{formik.errors.teamManagerMobile}</FormHelperText>
                  )}
                </Grid> */}
                <Typography
                  variant="h4"
                  fontWeight={400}
                  fontSize={12}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{ mt: 2 }}
                >
                  <span style={{ color: "red", fontSize: 18 }}>*</span> You can
                  add additional team coaches/managers later from your
                  dashboard.
                </Typography>
              </Box>
              {/* <Box mt={12} mb={2} display="flex" alignItems="center" justifyContent="end"> */}
              {/* <Box>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  color="inherit"
                  onClick={() => setShowTeamManagerRegistration(false)}
                >
                  Cancel
                </Button>
              </Box> */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  p: 4,
                  [theme.breakpoints.down("md")]: {
                    p: 2,
                  },
                }}
              >
                <Grid container spacing={2} mt={0}>
                  <Grid item sm={6} xs={12}>
                    <Box mr={3}>
                      <Typography
                        variant="h6"
                        sx={{
                          paddingY: 1.2,
                          paddingX: 3.5,
                          color: theme?.palette?.text?.containerTextLabel,
                          border: `2px solid ${theme?.palette?.text?.containerTextLabel}`,
                          fontWeight: 700,
                          // alignSelf: "flex-start",
                          borderRadius: "5px",
                          cursor: "pointer",
                          background: "white",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          navigate(Tournament_Teams_Page, {
                            state: {
                              isShowTeamManagerRegistrationForm: false,
                              tournamentId,
                            },
                          });
                          setShowTeamManagerRegistration(false);
                          setShowTeamManagerSelectionRequiredError(false);
                          setShowCoachSelectionRequiredError(false);
                          setIsEdit(false);
                          formik.resetForm();
                          setSelectedCoach([]);
                          setThumbnailImage("");
                          setSelectedTeamManager([]);
                          setDivisionItems([]);
                          setDivisionGradeList([]);
                          setCreatedManagerName("");
                          getAllTournamentTeam();
                        }}
                      >
                        Back
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box
                      sx={{
                        textAlign: "right",
                        [theme.breakpoints.down("sm")]: {
                          textAlign: "left",
                        },
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        sx={{
                          paddingX: 3.5,
                          paddingY: 1.2,
                          marginLeft: "auto",
                          display: "inline-block",
                        }}
                        // disabled={!isPaymentDone}
                      >
                        {isEdit ? "Update Team" : "Save Team Registration"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* </Box> */}
            </form>
            {showAddTeamManagerModal && (
              <AddTeamManager
                open={showAddTeamManagerModal}
                handleClose={handleTeamManagerModalClose}
              />
            )}
            {showAddTeamCoachModal && (
              <AddTeamCoach
                open={showAddTeamCoachModal}
                handleClose={handleTeamCoachModalClose}
              />
            )}
            {showTeamPaymentModal && (
              <TeamPaymentAmount
                open={showTeamPaymentModal}
                handleClose={handleTeamPaymentAmountModalClose}
                formData={paymentFormData}
                type="team"
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return renderAllTeams();
  }
  // } else {
  //   return (
  //     <>
  //       <Box sx={{ pt: 5, textAlign: "center" }}>
  //         <ComingSoon />
  //       </Box>
  //     </>
  //   );
  // }
};

export default Tournament;
