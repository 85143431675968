import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const EventQRSuccessErrorPopup = ({ open, handleClose, errorMessage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ p: 0 }}>
        <Typography
          variant="h4"
          fontWeight={700}
          fontSize={20}
          py={3}
          ml={3}
          color={theme?.palette?.error?.dark}
        >
          Error
        </Typography>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: 12,
        }}
      >
        <Typography
          sx={{ py: 5, textAlign: "center" }}
          fontFamily={"Poppins"}
          fontSize={20}
          fontWeight={500}
          color={theme?.palette?.error?.dark}
        >
          {errorMessage ??
            "This user already exists, please login to continue."}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pr: 4, pb: 3 }}>
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2 }}
          // color="inherit"
          onClick={handleClose}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventQRSuccessErrorPopup;
