import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import useDebounce from "../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  addUpdateAdminMessageAction,
  deleteAdminMessageAction,
  getAllScholarshipAction,
} from "../../store/actions";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessPopup from "../../components/Common/SuccessPopup";
import CustomTextField from "../../components/CustomTextField";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import ActionCard from "../../components/Common/ActionCard";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { useNavigate } from "react-router-dom";
import { Apply_Scholarship_Program, SUB_ORGANIZATIONS_TEAM_PAGE } from "../../routes/constants";
import { charValidate } from '@utils/functions';
import CustomSwitch from "../../components/Common/Inputs/Switch";
import { LIMIT_VALIDATION } from "../../utils/constants";
import { formatDate } from "../../utils/functions";
import ScholarshipDenyPopup from "../../components/Scholarship/ScholarshipDenyPopup";
import EditScholarshipDiscount from "../../components/Scholarship/EditScholarshipDiscount";

const validationSchema = Yup.object({
  messageName: Yup.string().trim().required("Message name is required"),
  themeColor: Yup.string().required("Theme color selection is required"),
  message: Yup.string().max(250, "Max character limit is 250").trim().required("Message is required"),
  expireDate: Yup.mixed().required("Expire date selection is required"),
  linkURL: Yup.string().matches(LIMIT_VALIDATION.UrlFormat, "URL format is invalid, enter correct URL"),
  messageFormatType: Yup.string().required("Message type selection is required"),
});

const AdminScholarshipList = () => {
  const tableRef = useRef();
  const [scholarshipListData, setScholarshipListData] = useState();
  const [searchUser, setSearchUser] = useState("")
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [checkCommunity, setCheckCommunity] = useState(selectedDataGridRow?.permission?.community ?? false);
  const [checkPost, setCheckPost] = useState(selectedDataGridRow?.permission?.post ?? false);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const debounceSearchValue = useDebounce(searchUser, 600);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDenyPopup, setShowDenyPopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const theme = useTheme();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const [activeInactiveMessage, setActiveInactiveMessage] = useState(true);
  const [selectedPlacement, setSelectedPlacement] = useState([]);
  const [messagePlacementList, setMessagePlacementList] = useState(["Dashboard Middle Top"]);
  const [showMessagePlacementSelectionRequiredError, setShowMessagePlacementSelectionRequiredError] = useState(false);
  const [confirmPopupActiveInactiveMessage, setConfirmPopupActiveInactiveMessage] = useState(false);
  const [messageFormatType, setMessageFormatType] = useState([
    {
      id: 1,
      label: "General",
      type: "link"
    },
    {
      id: 2,
      label: "Apply for Scholarship",
      type: "scholarship_apply"
    },
  ]);
  const [messageColorList, setMessageColorList] = useState([
    {
      id: 1,
      label: "General Message (Blue) (#EFF7FF)",
      value: "#EFF7FF"
    },
    {
      id: 2,
      label: "Warning Message (Red) (#FFF6F6)",
      value: "#FFF6F6"
    },
  ]);
  const [userTypesList, setUserTypesList] = useState([
    {
      id: 1,
      label: "Athlete/Player",
      value: "player"
    },
    {
      id: 2,
      label: "Parent",
      value: "parent"
    },
    {
      id: 3,
      label: "Team Manager",
      value: "teammanager"
    },
    {
      id: 4,
      label: "Team Coach",
      value: "teamcoach"
    },
    {
      id: 5,
      label: "Tournament Manager",
      value: "tournamentmanager"
    },
  ]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [showUserTypeSelectionRequiredError, setShowUserTypeSelectionRequiredError] = useState(false);

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const requiredStarStyle = { color: "red" }

  const formik = useFormik({
    initialValues: {
      messageName: selectedDataGridRow?.title ?? "",
      themeColor: selectedDataGridRow?.color ?? "",
      message: selectedDataGridRow?.desc ?? "",
      expireDate: selectedDataGridRow?.expire_date ?? "",
      linkURL: selectedDataGridRow?.link ?? "",
      messageFormatType: selectedDataGridRow?.format_type ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedPlacement?.length) {
        setShowMessagePlacementSelectionRequiredError(true);
        return;
      }
      if (!selectedUserType?.length) {
        setShowUserTypeSelectionRequiredError(true);
        return;
      }

      const postData = {
        id: selectedDataGridRow?.id ? selectedDataGridRow?.id : undefined,
        title: values?.messageName ?? "",
        desc: values?.message ?? "",
        color: values?.themeColor ?? "",
        link: values?.linkURL ?? "",
        is_active: activeInactiveMessage,
        expire_date: values?.expireDate,
        type: selectedPlacement?.length ? selectedPlacement[0] === "Dashboard Middle Top" ? "DashboardMiddleTop" : "" : "",
        format_type: values?.messageFormatType ?? "",
        user_type: selectedUserType?.length ? selectedUserType?.join() : "",
      }
      addUpdateMessage(postData, activeInactiveMessage);
    },
  });

  useEffect(() => {
    if (selectedDataGridRow?.format_type) {
      formik.setFieldValue("messageFormatType", selectedDataGridRow?.format_type);
    }
  }, [selectedDataGridRow])

  useEffect(() => {
    if (selectedDataGridRow) {
      let {
        title,
        color,
        desc,
        expire_date,
        link,
        is_active,
        user_type
      } = selectedDataGridRow;
      if (user_type && typeof user_type === "string") {
        setSelectedUserType(user_type?.split(","))
      }
      setSelectedPlacement([selectedDataGridRow?.type === "DashboardMiddleTop" ? "Dashboard Middle Top" : ""]);
      formik.setFieldValue("messageName", title ?? "");
      formik.setFieldValue("themeColor", color ?? "");
      formik.setFieldValue("message", desc ?? "");
      formik.setFieldValue("expireDate", expire_date ?? "");
      formik.setFieldValue("linkURL", link ?? "");
      setActiveInactiveMessage(is_active ?? true);
    }
  }, [selectedDataGridRow]);

  useEffect(() => {
    if (selectedPlacement?.length) {
      setShowMessagePlacementSelectionRequiredError(false);
    }
  }, [selectedPlacement]);

  useEffect(() => {
    if (selectedUserType?.length) {
      setShowUserTypeSelectionRequiredError(false);
    }
  }, [selectedUserType]);

  const addUpdateMessage = async (bodyParams, forActiveInactive) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    bodyParams["is_active"] = forActiveInactive ? true : activeInactiveMessage;
    let response = await addUpdateAdminMessageAction(dispatch, params, bodyParams);

    try {
      if (response?.ok) {
        handleClose(false);
        setShowSuccessPopup(true);
      } else {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response?.message ?? "Something went wrong. Please try again" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error?.message ?? "Something went wrong, try again" }
      })
    }
  };

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllScholarship();
    }
  }, [
    state?.user?.token,
    state?.org?.org?.token,
    debounceSearchValue, selectedUserType
  ]);

  const getAllScholarship = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
      searchKeyword: searchUser,
      // usertype: selectedUserType === 'all' ? "" : selectedUserType,
    };
    let response = await getAllScholarshipAction(params, dispatch);
    if (response?.ok) {
      setScholarshipListData(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getAllScholarship();
  };

  const handleClose = (isClosed) => {
    setOpen(false);
    setSelectedPlacement([]);
    setSelectedUserType([]);
    setActiveInactiveMessage(true);
    setConfirmPopupActiveInactiveMessage(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
      return;
    }
    getAllScholarship();
  };

  const columns = [
    {
      field: "id",
      headerName: "SUBMISSION NUMBER",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => data.api.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1,
      sortable: false,
    },
    {
      field: "parent_first_name",
      headerName: "parent first name",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.parent_first_name ?? "N/A"
          }
        >
          <span>
            {data?.row?.parent_first_name
              ? charValidate(data?.row?.parent_first_name, 20)
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "parent_last_name",
      headerName: "parent last name",
      minWidth: 160,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.parent_last_name ?? "N/A"
          }
        >
          <span>
            {data?.row?.parent_last_name
              ? charValidate(data?.row?.parent_last_name, 20)
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "program",
      headerName: "PROGRAM APPLIED FOR",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.program ?
              data?.row?.program === "public_sector" ?
                "Public Sector" :
                "Income-Based Scholarship"
              : "N/A"
          }
        >
          <span>
            {data?.row?.program
              ? charValidate(
                data?.row?.program === "public_sector" ?
                  "Public Sector" :
                  "Income-Based Scholarship",
                25
              )
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      align: "center",
      headerAlign: "center",
      renderCell: (data) => (
        <Box
          sx={{
            height: "38px",
            width: "100%",
            color: "white",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            background:
              data?.row?.status === "Awaiting Approval" ?
                theme?.palette?.grey[400] :
                data?.row?.status === "Denied" ?
                  theme?.palette?.error?.main :
                  theme?.palette?.inputsLabels?.green,
            borderRadius: "4px",
          }}
        >
          {data?.row?.status === "Awaiting Approval" ?
            "PENDING" :
            data?.row?.status === "Denied" ?
              "DENIED" :
              `${data?.row?.discount}%`
          }
        </Box>
      ),
      flex: 1,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClick={() => setPopoverAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Actions"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2, width: "200px" }}
                    onClick={() => {
                      navigate(Apply_Scholarship_Program, { state: { scholarshipData: selectedDataGridRow, isNewEntry: false } });
                    }}
                  >
                    View Submission
                  </Button>
                  {
                    selectedDataGridRow?.status !== "Denied" &&
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{
                        mt: 2,
                        paddingY: 1.2,
                        width: "200px",
                        background: theme?.palette?.error?.dark,
                      }}
                      onClick={() => {
                        setShowDenyPopup(true);
                      }}
                    // disabled={selectedDataGridRow?.status === "Awaiting Approval" ? false : true}
                    >
                      Deny Request
                    </Button>
                  }
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingY: 1.2,
                      width: "200px"
                    }}
                    onClick={() => {
                      setShowApprovePopup(true);
                    }}
                  >
                    {selectedDataGridRow?.status === "Approved" ? "Edit Discount" : "Approve Request"}
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = () => {
    setPopoverAnchorEl(null);
    setOpen(true);
  };

  const handleDeleteClick = () => {
    setPopoverAnchorEl(null);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteAdminMessageAction(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response?.message }
      })
      setShowDeletePopup(false);
      setSelectedDataGridRow("");
      handleClose(false);
      getAllScholarship();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong, try again" }
      })
    }
  };

  const handleMessagePlacementSelection = (e) => {
    let value = e?.target?.value;
    if (value) {
      setSelectedPlacement(value);
    }
  }

  const handleUserTypeSelection = (e) => {
    let value = e?.target?.value;
    if (value) {
      setSelectedUserType(value);
    }
  }

  const handleCloseDenyPopup = (isClosed) => {
    setShowDenyPopup(false);
    if (!isClosed) {
      getAllScholarship();
    }
  }

  const handleCloseApprovePopup = (isClosed) => {
    setShowApprovePopup(false);
    if (!isClosed) {
      getAllScholarship();
    }
  }

  const gridToolbar = () => {
    return (

      <Box display={"flex"} alignItems="center" justifyContent="center">
        <CustomTextField
          id="searchUser"
          name="searchUser"
          placeholder="Search..."
          variant="outlined"
          size="small"
          onChange={(e) => setSearchUser(e.target.value)}
          value={searchUser}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              border: `1px solid ${theme?.palette?.grey?.[400]}`,
              width: 300,
              boxShadow: "none",
            },
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <MuiDataGrid
        headerLabel={"Scholarship Program Submissions"}
        gridData={scholarshipListData}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={scholarshipListData ? false : true}
        handleRowClick={(data) => {
          setSelectedDataGridRow(data?.row);
        }}
        handlePagination={handlePagination}
        isAddNewBtnShow={true}
        onClickAddNew={() => {
          navigate(Apply_Scholarship_Program, { state: { isNewEntry: true } })
        }}
        options={{
          disableRowSelectionOnClick: true
        }}
        gridToolbarButtons={() => gridToolbar()}
      />

      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ py: 3 }}>
          {/* <Typography variant="h3" fontWeight={700}>
            Communicate
          </Typography> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            pt={1}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Communicate
            </Typography>
            <Box position="relative" sx={{ cursor: "pointer" }}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={activeInactiveMessage}
                    onChange={(e) => setActiveInactiveMessage(e?.target?.checked)}
                    size="sm"
                    sx={{ m: 1 }}
                  />
                }
                label={`Make active for this communication spot.`}
                labelPlacement="start"
                sx={{
                  "& .MuiTypography-root": {
                    color: theme?.palette?.text?.containerTextLabel,
                    fontWeight: 400,
                    fontFamily: "inherit",
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Divider></Divider>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: 4.5 }}>
            {step === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12} ml={1}>
                  <Box display="flex" alignItems="center">
                    <CustomInputLabel sx={{ fontWeight: "700", fontSize: 16, mb: 0, width: "38%" }}>
                      <span style={requiredStarStyle}>*</span> Message Placement:
                    </CustomInputLabel>
                    <Box display="flex" flexDirection="column" width={1}>
                      <Select
                        name="messagePlacementSelection"
                        multiple
                        displayEmpty
                        value={selectedPlacement}
                        onChange={handleMessagePlacementSelection}
                        input={
                          <OutlinedInput
                            sx={{ width: 300 }}
                          />
                        }
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        renderValue={(selected) => {
                          if (selected?.length && messagePlacementList?.length) {
                            return selected?.join(", ");
                          } else {
                            return (
                              <Typography
                                variant="p"
                                fontWeight={400}
                                fontSize={14}
                                sx={{ opacity: 0.5 }}
                              >
                                Select message placement...
                              </Typography>
                            );
                          }
                        }}
                        MenuProps={MenuProps}
                        className="MuiSelect-select"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10.5px 14px",
                          },
                          width: 1,
                          maxWidth: "300px",
                        }}
                      >
                        <MenuItem disabled value="">
                          Select message placement...
                        </MenuItem>
                        {messagePlacementList?.map((placementType) => (
                          <MenuItem
                            // disabled={state?.user?.user?.id == msg?.id}
                            key={placementType}
                            value={placementType}
                          >
                            <Checkbox
                              checked={selectedPlacement.indexOf(placementType) > -1}
                            // disabled={state?.user?.user?.id == msg?.id}
                            />
                            <ListItemText primary={placementType} />
                          </MenuItem>
                        ))}
                      </Select>
                      {showMessagePlacementSelectionRequiredError && (
                        <FormHelperText error>Message placement selection is required</FormHelperText>
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="messageName">
                    Name of Message <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.messageName && formik.errors.messageName,
                    )}
                  >
                    <CustomTextField
                      id="messageName"
                      name="messageName"
                      placeholder="Enter a name..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.messageName}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.messageName && formik.errors.messageName,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.messageName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="themeColor">
                    CHOOSE BACKGROUND THEME COLOR <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Select
                    name="themeColor"
                    labelId="themeColor"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.themeColor}
                    input={
                      <OutlinedInput
                        label=""
                        placeholder="Select message type"
                        sx={{ width: "100%", height: 43 }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    renderValue={(selected) => {
                      if (selected) {
                        let selectedColor = messageColorList?.find((color) => color?.value === selected);
                        return selectedColor?.label;
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select background color for the message...
                          </Typography>
                        );
                      }
                    }}
                    displayEmpty
                  >
                    {messageColorList && messageColorList?.length ?
                      messageColorList?.map(color => (
                        <MenuItem key={color?.id} value={color?.value}>{color?.label}</MenuItem>
                      ))
                      : null}
                  </Select>
                  {Boolean(formik.touched.themeColor && formik.errors.themeColor) && (
                    <FormHelperText error>{formik.errors.themeColor}</FormHelperText>
                  )}
                </Grid>
                {/* <Grid item xs={6}>
                  <CustomInputLabel htmlFor="themeColor">
                    CHOOSE COLOR THEME <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.themeColor &&
                      formik.errors.themeColor,
                    )}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                        height: "37px",
                        padding: "10px 10px",
                      },
                      "& .MuiIconButton-root": {
                        background: "transparent",
                        borderRadius: "0 5px 5px 0",
                        left: 16,
                      },
                    }}
                  >
                    <InputLabel
                      htmlFor="theme-color"
                      shrink={false}
                      sx={{
                        fontFamily: "'Poppins', sans-serif, Arial",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      {formik?.values?.themeColor
                        ? formik?.values?.themeColor
                        : "Choose message color theme..."}
                    </InputLabel>
                    <OutlinedInput
                      name="themeColor"
                      type={"color"}
                      value={formik.values.themeColor}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      sx={{
                        "& ::file-selector-button": {
                          opacity: 0,
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: 350,
                          boxShadow: "none",
                          opacity: 0,
                        },
                        "& .MuiInputBase-input": {
                          padding: "13.5px 10px !important",
                        },
                      }}
                      inputProps={{
                        style: {
                          opacity: 0,
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.themeColor &&
                      formik.errors.themeColor,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.themeColor}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                  <CustomInputLabel htmlFor="message">
                    Enter Message <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.message && formik.errors.message,
                    )}
                  >
                    <CustomTextField
                      multiline
                      id="message"
                      name="message"
                      placeholder="Enter message... (limit 250 characters)"
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                      rows={6}
                      cols={6}
                    />
                    {Boolean(
                      formik.touched.message && formik.errors.message,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.message}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="expireDate">
                    Message Expire Date <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.expireDate && formik.errors.expireDate,
                    )}
                  >
                    <CustomTextField
                      type="date"
                      id="admin-message-expire-date"
                      name="expireDate"
                      placeholder="Choose Date..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.expireDate}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                          background: "transparent",
                          bottom: 0,
                          color: "transparent",
                          cursor: "pointer",
                          height: "auto",
                          left: 0,
                          position: "absolute",
                          right: 0,
                          top: 0,
                          width: "auto"
                        }
                      }}
                    />
                    {Boolean(
                      formik.touched.expireDate && formik.errors.expireDate,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.expireDate}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="linkURL">
                    Link Message To:
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.linkURL && formik.errors.linkURL,
                    )}
                  >
                    <CustomTextField
                      id="linkURL"
                      name="linkURL"
                      placeholder="Enter URL to link message to..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.linkURL}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.linkURL && formik.errors.linkURL,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.linkURL}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="messageFormatType">
                    Message For: <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Select
                    name="messageFormatType"
                    labelId="messageFormatType"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.messageFormatType}
                    input={
                      <OutlinedInput
                        label=""
                        placeholder="Select message type"
                        sx={{ width: "100%", height: 43 }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    renderValue={(selected) => {
                      if (selected) {
                        let selectedMsg = messageFormatType?.find((msg) => msg?.type === selected);
                        return selectedMsg?.label;
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select message type...
                          </Typography>
                        );
                      }
                    }}
                    displayEmpty
                  >
                    {messageFormatType && messageFormatType?.length ?
                      messageFormatType?.map(msg => (
                        <MenuItem key={msg?.id} value={msg?.type}>{msg?.label}</MenuItem>
                      ))
                      : null}
                  </Select>
                  {Boolean(formik.touched.messageFormatType && formik.errors.messageFormatType) && (
                    <FormHelperText error>{formik.errors.messageFormatType}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <CustomInputLabel htmlFor="userType">
                    User Type: <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Box display="flex" flexDirection="column" width={1}>
                    <Select
                      name="userType"
                      multiple
                      displayEmpty
                      value={selectedUserType}
                      onChange={handleUserTypeSelection}
                      input={
                        <OutlinedInput
                          sx={{ width: "100%", height: 46 }}
                        />
                      }
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      renderValue={(selected) => {
                        if (selected?.length && userTypesList?.length) {
                          let roles = [];
                          userTypesList?.filter((userType) => {
                            if (selected.includes(userType?.value)) {
                              roles.push(userType?.label);
                            }
                          });
                          if (roles?.length) {
                            return roles.join(", ");
                          }
                          return selected?.join(", ");
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select user role to show message...
                            </Typography>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "12px 14px",
                        },
                        width: 1,
                      }}
                    >
                      <MenuItem disabled value="">
                        Select user role to show message...
                      </MenuItem>
                      {userTypesList?.map((userType) => (
                        <MenuItem
                          key={userType?.id}
                          value={userType?.value}
                        >
                          <Checkbox
                            checked={selectedUserType.indexOf(userType?.value) > -1}
                          />
                          <ListItemText primary={`${userType?.label}`} />
                        </MenuItem>
                      ))}
                    </Select>
                    {showUserTypeSelectionRequiredError && (
                      <FormHelperText error>User type selection is required</FormHelperText>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              p: 3.5,
              px: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Button
              size="small"
              variant="contained"
              sx={{ width: "130px", height: "43px" }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: "130px", height: "43px", }}
              onClick={() => {
                if (!selectedPlacement?.length) {
                  setShowMessagePlacementSelectionRequiredError(true);
                }
                if (!selectedUserType?.length) {
                  setShowUserTypeSelectionRequiredError(true);
                }
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Communicate"}
          message={"Are you sure, want to delete this message?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedDataGridRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Communicate"}
          message={"Are you sure you want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setOpen(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
            setSelectedDataGridRow(undefined);
            formik.resetForm();
            setCheckCommunity(false);
            setCheckPost(false);
          }}
        />
      )}
      {showSuccessPopup &&
        <SuccessPopup
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
          secondaryTitle="Message Saved/Updated"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere to continue"
        />
      }

      {confirmPopupActiveInactiveMessage && (
        <CancelConfirmDialog
          title={"Communicate"}
          message={`Are you sure, want to make this message ${selectedDataGridRow?.is_active ? "Inactive" : "Active"}?`}
          open={confirmPopupActiveInactiveMessage}
          handleClose={() => {
            setConfirmPopupActiveInactiveMessage(false);
            setSelectedDataGridRow("");
          }}
          handleCancel={() => {
            setConfirmPopupActiveInactiveMessage(false);
            setSelectedDataGridRow("");
          }}
          handleConfirmClose={() => addUpdateMessage(selectedDataGridRow, true)}
          confirmBtnLabel="Yes"
        />
      )}
      {showDenyPopup &&
        <ScholarshipDenyPopup
          open={showDenyPopup}
          handleClose={handleCloseDenyPopup}
          requestData={selectedDataGridRow}
        />
      }
      {showApprovePopup &&
        <EditScholarshipDiscount
          open={showApprovePopup}
          handleClose={handleCloseApprovePopup}
          requestData={selectedDataGridRow}
        />
      }
    </>
  );
};

export default AdminScholarshipList;
