import {
    Box,
    Button,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    useTheme,
    FormControl,
    Typography, InputLabel, InputAdornment, IconButton, FormControlLabel, Checkbox
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction, SET_TOAST, getProductCategories, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { APP_MESSAGES } from "../../utils/constants";
import { getFileNameFromFirebaseUrl } from "../../utils/functions";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const SubscriptionForm = ({ handleClose, setStep, formData, setFormData }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [thumbnailImage, setThumbnailImage] = useState(formData?.image || "")
    const { productCategories: productCategoriesList } =
        useSelector((state) => state.products || [])
    const [allow_scholarship, setAllowScholarshipFunds] = useState(false);

    useEffect(() => {
        dispatch(getProductCategories())
    }, [])

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleSave = (values) => {
        const image = thumbnailImage;
        const categories = values.categories ? [values.categories] : []
        const type = "subscription"
        setFormData((prevStage) => {
            return {
                ...prevStage,
                ...values,
                categories,
                image,
                type,
                allow_scholarship,
            };
        });
        setStep(7)
    }

    const validationSchema = Yup.object({
        categories: Yup.string().trim().required("Category is required"),
        sku: Yup.string().trim(),
        name: Yup.string().trim().required("Name is required"),
        desc: Yup.string().trim().required("Description is required"),
        image: Yup.string().trim()
    });

    const formik = useFormik({
        initialValues: {
            categories: formData?.categories ? formData?.categories[0] : "",
            sku: formData?.sku || "",
            name: formData?.name || "",
            desc: formData?.desc || "",
            image: "",
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    useEffect(() => {
        if (formData) {
            setAllowScholarshipFunds(formData?.allow_scholarship ?? false);
        }
    }, [formData]);

    const uploadImage = async (file) => {
        if (file) {
            let params = {
                userToken: state?.user?.token,
                orgToken: state?.org?.org?.token,
            };
            let formData = new FormData();
            formData.append("files", file);
            formData.append("type", "ProductPackage");
            formData.append("fileType", "Thumbnail");
            formData.append("userId", state?.user?.user?.id);
            let response = await uploadFileAction(dispatch, params, formData);
            if (response?.ok) {
                setThumbnailImage(getFileNameFromFirebaseUrl(response?.url))
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'success', message: response.message ?? "Thumbnail Upload Successfully", }
                })
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
                })
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                    <CustomInputLabel htmlFor="org-filter">
                        TYPE
                    </CustomInputLabel>
                    <Select
                        name="subOrg"
                        labelId="org-filter"
                        // onChange={formik.handleChange}
                        value="2"
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Product Type"
                                sx={{ //maxWidth: 350,
                                    width: "100%", height: 45
                                }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                        disabled={true}
                    >
                        <MenuItem value="1"> Package</MenuItem>
                        <MenuItem value="2"> Subscription</MenuItem>
                        <MenuItem value="3"> Team</MenuItem>
                        <MenuItem value="4"> League</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <CustomInputLabel htmlFor="categories">
                        CATEGORY
                    </CustomInputLabel>
                    <Select
                        name="categories"
                        labelId="categories"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.categories}
                        input={
                            <OutlinedInput
                                label=""
                                placeholder="Select Category"
                                sx={{// maxWidth: 350,
                                    width: "100%", height: 45
                                }}
                            />
                        }
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                        }}
                        displayEmpty
                        renderValue={(value) => {
                            if (value) {
                                let selected = productCategoriesList?.find((cat) => cat?.id === value);
                                if (selected) {
                                    return selected?.title;
                                }
                            } else {
                                return (
                                    <Typography
                                        variant="p"
                                        fontWeight={400}
                                        fontSize={14}
                                        sx={{ opacity: 0.5 }}
                                    >
                                        Select Category...
                                    </Typography>
                                );
                            }
                        }}
                    >
                        {productCategoriesList?.map((cat) => (
                            <MenuItem key={cat?.id} value={cat?.id}>
                                {cat?.title}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(formik.touched.categories && formik.errors.categories) && (
                        <FormHelperText error>{formik.errors.categories}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={2} sx={{ mt: 3.4 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        onClick={() => setStep(5)}
                    >
                        +
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <CustomInputLabel htmlFor="sku-id">
                        SKU #
                    </CustomInputLabel>
                    <CustomTextField
                        id="sku-id"
                        name="sku"
                        placeholder="Enter SKU"
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.sku}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                // maxWidth: 350,
                                width: "100%",
                                boxShadow: "none",
                            },
                        }}
                    />
                    {Boolean(formik.touched.sku && formik.errors.sku) && (
                        <FormHelperText error>{formik.errors.sku}</FormHelperText>
                    )}
                </Grid>

                <Grid item xs={6}>
                    <CustomInputLabel htmlFor="name-id">
                        NAME
                    </CustomInputLabel>
                    <CustomTextField
                        id="name-id"
                        name="name"
                        placeholder="Enter Name"
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                //maxWidth: 350,
                                width: "100%",
                                boxShadow: "none",
                            },
                        }}
                    />
                    {Boolean(formik.touched.name && formik.errors.name) && (
                        <FormHelperText error>{formik.errors.name}</FormHelperText>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <CustomInputLabel htmlFor="description-id">
                        DESCRIPTION
                    </CustomInputLabel>
                    <CustomTextField
                        id="description-id"
                        name="desc"
                        placeholder="Enter Description"
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.desc}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                // maxWidth: 700,
                                width: "100%",
                                boxShadow: "none",
                            },
                        }}
                        multiline
                        minRows={4}
                    />
                    {Boolean(formik.touched.desc && formik.errors.desc) && (
                        <FormHelperText error>{formik.errors.desc}</FormHelperText>
                    )}
                </Grid>

                <Grid item xs={5.5}>
                    <CustomInputLabel htmlFor="sub-org-team-logo-lg">
                        THUMBNAIL IMAGE
                    </CustomInputLabel>
                    <FormControl
                        fullWidth
                        error={Boolean(
                            formik.touched.image && formik.errors.image,
                        )}
                        sx={{
                            //maxWidth: 350,
                            width: "100%",
                            "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                                height: "45px",
                                padding: "12.5px 10px",
                            },
                            "& .MuiIconButton-root": {
                                background: "transparent",
                                borderRadius: "0 5px 5px 0",
                                left: 16,
                            },
                        }}
                    >
                        <InputLabel
                            htmlFor="sub-org-team-logo-lg"
                            shrink={false}
                            sx={{
                                fontFamily: "'Poppins', sans-serif, Arial",
                                fontWeight: 400,
                                fontSize: 14,
                            }}
                        >
                            {thumbnailImage ? thumbnailImage : "Upload image..."}
                        </InputLabel>
                        <OutlinedInput
                            id="sub-org-team-logo-lg"
                            name="image"
                            type={"file"}
                            value={formik.values.image}
                            onBlur={formik.handleBlur}
                            onChange={(e) => uploadImage(e?.target?.files[0])}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="image"
                                        edge="end"
                                        size="large"
                                        htmlFor="sub-org-team-logo-lg"
                                        component="label"
                                    >
                                        <Box
                                            sx={{
                                                width: 85,
                                                height: 47,
                                                backgroundColor:
                                                    theme?.palette?.text?.containerTextLabel,
                                                borderTopRightRadius: 8,
                                                borderBottomRightRadius: 8,
                                            }}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            htmlFor="sub-org-team-logo-lg"
                                        >
                                            <UploadFileIcon sx={{ fontSize: 20, color: "white" }} />
                                        </Box>
                                    </IconButton>
                                </InputAdornment>
                            }
                            sx={{
                                "& ::file-selector-button": {
                                    opacity: 0,
                                    display: "none",
                                },
                                "& .MuiOutlinedInput-root": {
                                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                    width: 350,
                                    boxShadow: "none",
                                    opacity: 0,
                                },
                                "& .MuiInputBase-input": {
                                    padding: "13.5px 10px !important",
                                },
                            }}
                            inputProps={{
                                style: {
                                    opacity: 0,
                                },
                                accept: "image/*",
                            }}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {APP_MESSAGES.commentPicMaxSizeTxt}
                        </Typography>
                        {Boolean(
                            formik.touched.image && formik.errors.image,
                        ) && (
                                <FormHelperText error>
                                    {formik.errors.image ||
                                        "File size is greater than maximum limit"}
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>

                <Grid item xs={6} mt={3.5} ml={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    color: "#00B60D",
                                    "&.Mui-checked": {
                                        color: "#00B60D",
                                    },
                                }}
                                checked={allow_scholarship}
                                onChange={(e) => {
                                    setAllowScholarshipFunds(e?.target?.checked);
                                }}
                            />
                        }
                        label="Allow scholarship funds to be used for this product"
                    />
                </Grid>
            </Grid>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ mt: 4 }}
            >

                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                    color="inherit"
                    onClick={() => handleClose(true)}
                >
                    Close
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
                    color="inherit"
                    onClick={() => setStep(1)}
                >
                    Back
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                >
                    Next
                </Button>
            </Box>
        </form>
    );
};

export default SubscriptionForm;
