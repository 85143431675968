import { Box, Container, Grid, Typography, useTheme, Dialog, DialogTitle, Alert, DialogContent, FormHelperText, DialogActions, Button } from "@mui/material";
import { styled } from '@mui/system';
import { React, useState, useEffect } from "react";
import IYS_Logo from "../../assets/images/IYS.png";
import GRIDIRION_Logo from "../../assets/images/GridIron.png";
import { ReactComponent as PoweredBy } from '../../assets/images/Poweredby.svg';
import { ReactComponent as SKETCHPLAY } from '../../assets/images/SKETCHPLAY.svg';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../components/CustomTextField";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import ProceedPayment from "./ProceedPayment";
import httpClient from "@utils/httpClient";
import { SET_LOADER, SET_TOAST, getOrgData } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import SuccessPopup from "../../components/Common/SuccessPopup";
import PoweredBy_Logo from '../../assets/images/poweredbysketchplay-lockup.png';

const RootContainer = styled(Container)({
    marginBottom: theme => theme.spacing(1),
    padding: 4
});

const SquareBox = styled(Grid)(({ theme }) => ({
    position: "relative",
    height: 150,
    backgroundColor: "white",
    backgroundColor: theme?.palette?.background?.paper,
    boxShadow: theme?.palette?.boxShadow?.tableContainer,
    borderRadius: 1,
    border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
    padding: theme.spacing(4),
    textAlign: "center"
}));

const TitleContainer = styled(Box)({
    textAlign: 'left',
    padding: "10px"
});

const LogoContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginTop: 100,
    textAlign: "center"
}));

const validationSchema = Yup.object({
    full_name: Yup.string().trim().required("Name is required"),
    email: Yup.string()
        .trim()
        .required("Email is required")
        .email("Must be a valid email"),
    //price: Yup.string().required("Mobile is required")
});


const IYSPayment = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [iysPaymentData, setIYSPaymentData] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showPaymentDoneModal, setShowPaymentDoneModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const { org } = useSelector((state) => state.org);
    const [showError, setShowError] = useState(null);

    const handleSave = async (values, actions) => {

        setShowError(null)
        httpClient.defaults.headers.common['X-ORG-TOKEN'] = `${org.token}`
        const apiRes = await httpClient.post(
            '/team-payments/iysPaymentIntent',
            values
        )
        if (apiRes && apiRes.ok) {
            values.amount = apiRes.data?.amount
            values.clientSecret = apiRes.data?.clientSecret
            values.transaction_id = apiRes.data?.transaction_id
            values.publishableKey = apiRes.data?.publishableKey
            setFormData(values)
            handleModalClose();
            handlePaymentModalOpen()
        } else {
            setShowError(apiRes?.message)
        }
    };

    useEffect(() => {
        dispatch(getOrgData())
    }, [])

    useEffect(() => {
        bindData()
    }, [org])

    const formik = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            team_id: ""
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    const handleModalOpen = (teamId, isPaid) => {
        if (isPaid) {
            handlePaymentDoneModalOpen();
        } else {
            formik.setFieldValue("team_id", teamId);
            setShowModal(true);
        }
    }

    const handleModalClose = () => {
        formik.resetForm()
        setShowModal(false);
    }

    const handlePaymentModalOpen = () => {
        setShowPaymentModal(true);
    }

    const handlePaymentModalClose = (isError = false, errorMessage) => {
        setFormData({})
        setShowPaymentModal(false);
        setErrorMessage(errorMessage)
        if (!isError) {
            handleSuccessModalOpen();
            bindData()
        } else {
            handleErrorModalOpen()
        }
    }

    const handleSuccessModalOpen = () => {
        setShowSuccessModal(true);
    }

    const handleSuccessModalClose = () => {
        setShowSuccessModal(false);
    }

    const handlePaymentDoneModalOpen = () => {
        setShowPaymentDoneModal(true);
    }

    const handlePaymentDoneModalClose = () => {
        setShowPaymentDoneModal(false);
    }

    const handleErrorModalOpen = () => {
        setShowErrorModal(true);
    }

    const handleErrorModalClose = () => {
        setShowErrorModal(false);
    }

    const bindData = async () => {
        dispatch({ type: SET_LOADER, data: true });
        if (org) {
            httpClient.defaults.headers.common['X-ORG-TOKEN'] = `${org.token}`
            const res = await httpClient.get("/getAllSubOrgTeam?id=1&iysTeam=true");
            dispatch({ type: SET_LOADER, data: false });

            if (res && res.ok) {
                setIYSPaymentData(res.data)
            }
        }
    }


    return (
        <>
            <RootContainer maxWidth="lg">
                <LogoContainer>
                    <img src={IYS_Logo} alt="IYS logo" />
                </LogoContainer>
                <Box sx={{ px: 3 }}>
                    <TitleContainer sx={{ px: 0, mb: 1 }}>
                        <Typography variant="h2">IYS Team Payment</Typography>
                    </TitleContainer>
                    <Grid container spacing={2}>
                        {(iysPaymentData.length > 0) ?
                            iysPaymentData.map((item) => (
                                <Grid item key={item.id} xs={6} sm={6} lg={2.4}>
                                    <SquareBox sx={{ cursor: "pointer", backgroundColor: item.isPaid ? "gray" : "#ffffff" }} onClick={() => handleModalOpen(item.id, item.isPaid)}>
                                        <Box component="div">
                                            <Box component="div">
                                                <SportsFootballIcon sx={{ color: "#1E2549", fontSize: "35px" }} />
                                            </Box>
                                            <Typography variant="h6" component="h3" sx={{ fontSize: "16px", color: "#000000", fontStyle: "italic", fontWeight: "bold" }}>
                                                {item.title}
                                            </Typography>
                                            {/* <Typography variant="span" color="textSecondary" sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#000000" }}>
                                        {item.description}
                                    </Typography> */}
                                        </Box>
                                    </SquareBox>
                                </Grid>
                            )) :
                            <Grid item xs={12} sx={{ textAlign: "center", mt: 4 }}>
                                <Typography variant="h2" >
                                    No Data Available
                                </Typography>
                            </Grid>}

                    </Grid>
                </Box>
                <LogoContainer>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', svg: { width: 1001 } }}>
                        <img src={PoweredBy_Logo} alt="GridIron logo" width="200" />
                    </Box>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2, justifyItems: 'center' }}>
                        <img src={GRIDIRION_Logo} alt="GridIron logo" width="26" height="41" />
                    </Box>
                </LogoContainer>
            </RootContainer >

            <Dialog
                open={showModal}
                //maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2.5}
                        pt={1}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={20}
                            color={theme?.palette?.text?.containerTextLabel}
                        >
                            Payment Process
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent sx={{ paddingX: 6 }}>
                        <Grid container spacing={2}>
                            {showError && (
                                <Grid item xs={12} alignItems="center" justifyContent="left">
                                    <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
                                        {showError}
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <CustomInputLabel htmlFor="full-name">
                                    Full Name
                                </CustomInputLabel>
                                <CustomTextField
                                    id="full-name"
                                    name="full_name"
                                    placeholder="Enter full name..."
                                    variant="outlined"
                                    size="small"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.full_name}
                                    sx={{
                                        width: "100%",
                                        "& .MuiOutlinedInput-root": {
                                            width: "100%",
                                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                            maxWidth: 350,
                                            boxShadow: "none",
                                        },
                                    }}
                                />
                                {Boolean(formik.touched.full_name && formik.errors.full_name) && (
                                    <FormHelperText error>{formik.errors.full_name}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} >
                                <CustomInputLabel htmlFor="email">Email Address</CustomInputLabel>
                                <CustomTextField
                                    id="email"
                                    name="email"
                                    placeholder="Enter email..."
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email.toLowerCase()}
                                    sx={{
                                        width: "100%",
                                        "& .MuiOutlinedInput-root": {
                                            width: "100%",
                                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                            maxWidth: 350,
                                            boxShadow: "none",
                                        },
                                    }}
                                />
                                {Boolean(formik.touched.email && formik.errors.email) && (
                                    <FormHelperText error>{formik.errors.email}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ margin: 2 }}>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ paddingX: 3.5, paddingY: 1.2 }}
                            color="inherit"
                            onClick={() => handleModalClose()}
                        >
                            Close
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                        >
                            Proceed To Pay
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={showPaymentDoneModal}
                //maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2.5}
                        pt={1}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={20}
                            color={theme?.palette?.text?.containerTextLabel}
                        >
                            Payment
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
                <DialogContent sx={{ paddingX: 6 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.text?.containerTextLabel}
                            >
                                Payment Completed
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: 2 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        color="inherit"
                        onClick={() => handlePaymentDoneModalClose()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showErrorModal}
                //maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2.5}
                        pt={1}
                    >
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={20}
                            color={theme?.palette?.error?.dark}
                        >
                            Error
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
                <DialogContent sx={{ paddingX: 6 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.error?.dark}
                            >
                                {errorMessage}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: 2 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        color="inherit"
                        onClick={() => handleErrorModalClose()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {showPaymentModal && <ProceedPayment formData={formData} open={showPaymentModal} onClose={handlePaymentModalClose} />}
            {
                showSuccessModal &&
                <SuccessPopup
                    open={showSuccessModal}
                    handleClose={handleSuccessModalClose}
                    title="Payment Success"
                    message="Payment Successfully Done" />
            }
        </>
    );
}

export default IYSPayment;

