import { Box, Button, Divider, FormControl, TextField, Typography, Grid, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles'
import PhoneInput from 'react-phone-input-2';
import facebookIcon from "@assets/images/social-links/facebook.png";
import instagramIcon from "@assets/images/social-links/instagram.png";
import linkedinIcon from "@assets/images/social-links/linkedin.png";
import snapchatIcon from "@assets/images/social-links/snapchat.png";
import tiktokIcon from "@assets/images/social-links/tiktok.png";
import twitterIcon from "@assets/images/social-links/twitter.png";
import youtubeIcon from "@assets/images/social-links/youtube.png";
import { dateFormat } from '../../utils/functions';
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";

const AthleteDetail = ({ step, setStep, formData, setFormData, setActiveAthleteId }) => {
    const theme = useTheme()

    // constants
    const SOCIALMEDIA = [
        { name: "twitter_url", icon: twitterIcon },
        { name: "tiktok_url", icon: tiktokIcon },
        { name: "insta_url", icon: instagramIcon },
        { name: "snap_url", icon: snapchatIcon },
        { name: "linkedin_url", icon: linkedinIcon },
        { name: "fb_url", icon: facebookIcon },
        { name: "yt_url", icon: youtubeIcon },
    ];

    // styles
    const inputStyle = {
        border: 0,
        outline: "none",
        height: 30,
        fontSize: 16,
        background: "transparent",
    };

    const formControlStyle = {
        ...theme.typography.customInput,
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            background: "transparent",
            color: theme?.palette?.primary?.main,
        },
    };

    const gradeArr = [
        { id: "1", value: "1st Grade" },
        { id: "2", value: "2nd Grade" },
        { id: "3", value: "3rd Grade" },
        { id: "4", value: "4th Grade" },
        { id: "5", value: "5th Grade" },
        { id: "6", value: "6th Grade" },
        { id: "7", value: "7th Grade" },
        { id: "8", value: "8th Grade" },
        { id: "9", value: "9th Grade" },
        { id: "10", value: "10th Grade" },
        { id: "11", value: "11th Grade" },
        { id: "12", value: "12th Grade" },
        { id: "college", value: "College" },
        { id: "pro", value: "Pro" },
    ];

    const formik = useFormik({
        initialValues: {
            first_name: formData?.first_name || "",
            last_name: formData?.last_name || "",
            email: formData?.email || "",
            mobile: formData?.mobile || "",
            address: formData?.address || "",
            suite: formData?.suite || "",
            city: formData?.city || "",
            state: formData?.state || "",
            zip_code: formData?.zip_code || "",
            school: formData?.school || "",
            grade: formData?.grade || "",
            position: formData?.position || "",
            dob: dateFormat(formData?.dob) || "",
            emergency_contact_name: formData?.emergency_contact_name || "",
            emergency_contact_number: formData?.emergency_contact_number || "",
            emergency_contact_countryCode: formData?.emergency_contact_countryCode || "",
            twitter_url: formData?.twitter_url || "",
            tiktok_url: formData?.tiktok_url || "",
            insta_url: formData?.insta_url || "",
            snap_url: formData?.snap_url || "",
            linkedin_url: formData?.linkedin_url || "",
            fb_url: formData?.fb_url || "",
            yt_url: formData?.yt_url || ""
        },
        // validationSchema: validationSchema,
        // onSubmit: handleSave
    });

    useEffect(() => {
        if (formData && formData?.position) {
            let position = formData?.position?.map((p) => p?.name);
            position = position?.join(",");
            formik.setFieldValue("position", position)
        }
        formik.setFieldValue("first_name", formData?.first_name)
        formik.setFieldValue("last_name", formData?.last_name)
        formik.setFieldValue("email", formData?.email)
        formik.setFieldValue("mobile", formData?.mobile)
        formik.setFieldValue("countryCode", formData?.countryCode)
        formik.setFieldValue("address", formData?.address)
        formik.setFieldValue("suite", formData?.suite)
        formik.setFieldValue("city", formData?.city)
        formik.setFieldValue("state", formData?.state)
        formik.setFieldValue("zip_code", formData?.zip_code)
        formik.setFieldValue("school", formData?.school)
        formik.setFieldValue("grade", formData?.grade)
        // formik.setFieldValue("position", formData?.position)
        formik.setFieldValue("dob", dateFormat(formData?.dob))
        formik.setFieldValue("emergency_contact_name", formData?.emergency_contact_name)
        formik.setFieldValue("emergency_contact_number", formData?.emergency_contact_number)
        formik.setFieldValue("emergency_contact_countryCode", formData?.emergency_contact_countryCode)
        formik.setFieldValue("twitter_url", formData?.twitter_url)
        formik.setFieldValue("tiktok_url", formData?.tiktok_url)
        formik.setFieldValue("insta_url", formData?.insta_url)
        formik.setFieldValue("snap_url", formData?.snap_url)
        formik.setFieldValue("linkedin_url", formData?.linkedin_url)
        formik.setFieldValue("fb_url", formData?.fb_url)
        formik.setFieldValue("yt_url", formData?.yt_url)
    }, [formData?.id])

    const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

    return (
        <>
            <Box sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} sm={12} sx={{ my: 1 }}>
                    <Typography variant="h2" sx={{ color: '#262626' }}>
                        Athlete's Name & Address
                    </Typography>
                </Grid>
            </Box>
            <form>
                <Box className="guardian-form-box" sx={{
                    // '&.guardian-form-box div': {
                    //     maxWidth: '100%',
                    // }
                }}>

                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={12} md={12}>
                                {/* First Name */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        First Name *
                                    </CustomInputLabel>
                                    <TextField
                                        id="first_name"
                                        name="first_name"
                                        variant="outlined"
                                        value={formik.values.first_name}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Last Name */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Last Name *
                                    </CustomInputLabel>
                                    <TextField
                                        id="last_name"
                                        name="last_name"
                                        variant="outlined"
                                        value={formik.values.last_name}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Email */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Email *
                                    </CustomInputLabel>
                                    <TextField
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        value={formik.values.email.toLowerCase()}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Mobile */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                            border: '0',
                                            boxShadow: '0px 6px 20px #1F54C326',
                                        }
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Mobile
                                    </CustomInputLabel>
                                    <Box
                                        sx={{
                                            '& .react-tel-input .form-control': {
                                                border: 0,
                                                "box-shadow": "0px 6px 20px #1F54C326",
                                                "min-width": "100%",
                                                "font-weight": 500,
                                                background: "#fafafa",
                                                padding: "15px 14px 15px 50px",
                                                "border-radius": "0",
                                                height: "51px",
                                            },
                                            "& .react-tel-input .flag-dropdown": {
                                                border: 0,
                                                background: "transparent"
                                            },
                                            "& .react-tel-input .selected-flag": {
                                                pl: 2,
                                            }
                                        }
                                        }>
                                        <PhoneInput
                                            country={"us"}
                                            id="mobile"
                                            name="mobile"
                                            variant="outlined"
                                            placeholder="(702) 123-4567"
                                            value={formik.values.mobile}
                                            onlyCountries={['us']}
                                            disableCountryCode={true}
                                            disableDropdown={true}
                                            disabled={true}
                                        />
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={12} md={12}>
                                {/* Address Line 1 */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Address Line 1 *
                                    </CustomInputLabel>
                                    <TextField
                                        id="address"
                                        name="address"
                                        variant="outlined"
                                        value={formik.values.address}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Address Line 2 */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Address Line 2 *
                                    </CustomInputLabel>
                                    <TextField
                                        id="suite"
                                        name="suite"
                                        variant="outlined"
                                        value={formik.values.suite}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* City */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        City *
                                    </CustomInputLabel>
                                    <TextField
                                        id="city"
                                        name="city"
                                        variant="outlined"
                                        value={formik.values.city}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* State */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        State *
                                    </CustomInputLabel>
                                    <TextField
                                        id="state"
                                        name="state"
                                        variant="outlined"
                                        value={formik.values.state}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Zip Code */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Zip Code *
                                    </CustomInputLabel>
                                    <TextField
                                        id="zip_code"
                                        name="zip_code"
                                        variant="outlined"
                                        value={formik.values.zip_code}
                                        label=""
                                        pattern="[0-9]{5}"
                                        type="number"
                                        inputMode="numeric"
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }}></Divider>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h2" sx={{ color: '#262626' }}>
                            Athlete's Information
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ mt: 2 }}>

                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={12} md={12}>
                                {/* School */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        School *
                                    </CustomInputLabel>
                                    <TextField
                                        id="school"
                                        name="school"
                                        variant="outlined"
                                        value={formik.values.school}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Grade */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Grade
                                    </CustomInputLabel>
                                    <TextField
                                        id="grade"
                                        name="grade"
                                        variant="outlined"
                                        value={formik.values.grade}
                                        label=""
                                        disabled={true}
                                    />
                                    {/* <TextField
                                        select
                                        name="grade"
                                        label=""
                                        value={formik.values.grade || ""}
                                        variant="outlined"
                                        sx={{ textAlign: "left" }}
                                        disabled={true}
                                    >
                                        {gradeArr.map((option) => (
                                            <MenuItem key={option.id} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* Position */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Position *
                                    </CustomInputLabel>
                                    <TextField
                                        id="position"
                                        name="position"
                                        variant="outlined"
                                        value={formik.values.position}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* DOB */}
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Date Of Birth
                                    </CustomInputLabel>
                                    <TextField
                                        id="dob"
                                        name="dob"
                                        variant="outlined"
                                        value={formik.values.dob}
                                        //label={datePickerLabel}
                                        type="text"
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
                        <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                            <Typography variant="h2" sx={{ color: '#262626' }}>
                                Emergency Contact
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput,
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "0",
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                        },
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Emergency Contact Name *
                                    </CustomInputLabel>
                                    <TextField
                                        id="emergency_contact_name"
                                        name="emergency_contact_name"
                                        variant="outlined"
                                        value={formik.values.emergency_contact_name}
                                        label=""
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                            border: '0',
                                            boxShadow: '0px 6px 20px #1F54C326',
                                        }
                                    }}
                                    disabled={true}
                                >
                                    <CustomInputLabel sx={inputLabelStyle}>
                                        Emergency Contact Number
                                    </CustomInputLabel>
                                    <Box
                                        sx={{
                                            '& .react-tel-input .form-control': {
                                                border: 0,
                                                "box-shadow": "0px 6px 20px #1F54C326",
                                                "min-width": "100%",
                                                "font-weight": 500,
                                                background: "#fafafa",
                                                padding: "15px 14px 15px 50px",
                                                "border-radius": "0",
                                                height: "51px",
                                            },
                                            "& .react-tel-input .flag-dropdown": {
                                                border: 0,
                                                background: "transparent"
                                            }
                                        }
                                        }>
                                        <PhoneInput
                                            country={"us"}
                                            id="emergency_contact_number"
                                            name="emergency_contact_number"
                                            variant="outlined"
                                            placeholder="(702) 123-4567"
                                            value={formik.values.emergency_contact_number}
                                            onlyCountries={['us']}
                                            disableCountryCode={true}
                                            disableDropdown={true}
                                            disabled={true}
                                        />
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }}></Divider>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h2" sx={{ color: '#262626' }}>
                            Social Accounts
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                        <Grid
                            container
                            spacing={3}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            {SOCIALMEDIA.map((social, index) => (
                                <Grid item xs={12} md={12}>
                                    <Box
                                        key={social.name}
                                        display="flex"
                                        alignItems="center"
                                        px={2}
                                        py={0.5}
                                        style={{
                                            border: "0",
                                            borderRadius: 5,
                                            boxShadow: theme?.palette?.boxShadow?.primary,
                                            backgroundColor: theme?.palette?.background?.paper,
                                        }}
                                    >
                                        <img
                                            src={social.icon}
                                            style={{
                                                width: SOCIALMEDIA.length === index + 1 ? 38 : 40,
                                                borderRight: "1px solid",
                                                paddingRight: SOCIALMEDIA.length === index + 1 ? 16 : 16,
                                                position: "relative",
                                                left: SOCIALMEDIA.length === index + 1 ? 3 : 0,
                                            }}
                                        />
                                        <FormControl
                                            fullWidth
                                            sx={formControlStyle}
                                            style={{ paddingLeft: 20 }}
                                            disabled={true}
                                        >
                                            <input
                                                value={formik.values[social?.name]}
                                                name={social?.name}
                                                placeholder="Enter Profile URL"
                                                style={inputStyle}
                                                disabled={true}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ mt: 4 }}>
                        <Box sx={{ width: "100%" }}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="outlined"
                                sx={{ fontWeight: "600" }}
                                onClick={() => { setFormData({}); setActiveAthleteId(0); setStep(1) }}
                            >
                                Close Detail Page
                            </Button>
                        </Box>
                    </Grid>
                </Box>

            </form >
        </>
    );
}

export default AthleteDetail;
