import { Box, Typography, Grid } from '@mui/material'
import ComingSoon from '../../components/ComingSoon';

const ManageCoach = () => {
    return (
        <>
            <Box sx={{ pt: 5, textAlign: "center" }}>
                <ComingSoon />
            </Box>
        </>
    )

}

export default ManageCoach;
