import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Grid,
  FormHelperText,
  Button,
  InputAdornment,
  Switch,
  FormControlLabel
} from "@mui/material";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import { SET_LOADER, SET_TOAST, addEventLocationsAction, createUpdateEventDiscountCodeAction, SET_SUCCESS_ERROR_POPUP } from "../../../../store/actions";
import CustomSwitch from "../../../../components/Common/Inputs/Switch";
import { styled } from "@mui/system";
import { useMutation } from "react-query";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const AddUpdateEventDiscountCode = ({
  open,
  handleClose,
  data,
  setSelectedEventDiscounts,
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [inputFields, setInputFields] = useState(data?.length > 0 ? [...data, {
    idObj: 'name-id-0', name: '', flatOff: '', flatPer: '', is_active: true
  }] : [
    { idObj: 'name-id-0', name: '', flatOff: '', flatPer: '', is_active: true }
  ]);
  const [isDiscountRequired, setIsDiscountRequired] = useState(false)
  const [isOneFieldFilled, setIsOneFieldFilled] = useState(false);
  const [isOneFieldRequiredError, setIsOneFieldRequiredError] = useState(false);
  const [isValueGTzero, setIsValueGTzero] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [discountId, setDiscountId] = useState('');
  const [discountIds, setDiscountIds] = useState(data?.length > 0 ? data?.map(x => x.id) : [])

  const { mutateAsync: createUpdateEventDiscountCode } = useMutation(createUpdateEventDiscountCodeAction);

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#00B60D",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleFieldChange = (index, fieldName, value) => {
    if (fieldName === 'name' && value.trim()) {
      setIsDiscountRequired(false)
    }
    const updatedFields = [...inputFields];
    if (fieldName === 'is_active') {
      updatedFields[index][fieldName] = value;
    } else {
      updatedFields[index][fieldName] = value.trim();
    }

    if (fieldName === 'flatPer' || fieldName === 'flatOff') {
      if (value > 0) {
        if (fieldName === 'flatPer') {
          // If flatPer is filled, set flatOff to empty string
          updatedFields[index]['flatOff'] = '';
          setIsOneFieldRequiredError(false)
        } else if (fieldName === 'flatOff') {
          updatedFields[index]['flatPer'] = '';
          setIsOneFieldRequiredError(false)
        }
        setIsValueGTzero(false)
      } else {
        setIsValueGTzero(true)
      }
    }

    setInputFields(updatedFields);
  };

  const handleAddInputField = async (indexVal) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    const bodyParam = inputFields.map((field) => ({
      name: field.name,
      flatOff: field.flatOff ? parseFloat(field.flatOff) : 0,
      flatPer: field.flatPer ? parseFloat(field.flatPer) : 0,
      is_active: field.is_active,
    }));
    if (bodyParam.length > 0) {
      const reqPayload = bodyParam[indexVal];
      const newArray = inputFields.filter((item, index) => index !== indexVal);
      if (reqPayload?.name && (reqPayload.flatOff || reqPayload.flatPer)) {
        const res = await createUpdateEventDiscountCode({ dispatch, params, bodyParam: bodyParam[indexVal] });
        if (res && res.ok) {
          const updateArr = [...discountIds, res?.data?.id]
          setDiscountIds(updateArr);
          setSelectedEventDiscounts(updateArr ?? []);
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: { open: true, type: 'success', message: "This discount created successfully" }
          })

          setIsDiscountRequired(false)
          setIsOneFieldFilled(false)
          setIsOneFieldRequiredError(false);
          setIsValueGTzero(false)
          // Update the state to include the new input field
          setInputFields((prevInputFields) => [
            res.data,
            ...newArray,
            {
              idObj: `name-id-${inputFields.length}`,
              name: '',
              flatOff: '',
              flatPer: '',
              is_active: true,
            },
          ]);

        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
          })
          return false;
        }
      } else {
        if (reqPayload?.name) {
          setIsDiscountRequired(false)
        } else {
          setIsDiscountRequired(true)
        }

        if (!reqPayload.flatOff || !reqPayload.flatPer) {
          setIsOneFieldRequiredError(true);
        } else {
          setIsOneFieldRequiredError(false);
        }
      }
    }
  };

  const handleEditInputField = async (indexVal) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    const bodyParam = inputFields.map((field) => ({
      ...field,
      flatOff: field.flatOff ? parseFloat(field.flatOff) : 0,
      flatPer: field.flatPer ? parseFloat(field.flatPer) : 0,
    }));
    if (bodyParam.length > 0) {
      const reqPayload = bodyParam[indexVal];
      const newArray = inputFields.filter((item, index) => index !== indexVal);
      if (reqPayload?.name && (reqPayload.flatOff || reqPayload.flatPer)) {
        const res = await createUpdateEventDiscountCode({ dispatch, params, bodyParam: bodyParam[indexVal] });
        if (res && res.ok) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: { open: true, type: 'success', message: "This discount updated successfully" }
          })
          setIsDiscountRequired(false)
          setIsOneFieldFilled(false)
          setIsOneFieldRequiredError(false);
          setIsValueGTzero(false)
          // Update the state to include the new input field
          setInputFields((prevInputFields) => [
            res.data,
            ...newArray
          ]);

        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
          })
          return false;
        }
      } else {
        if (reqPayload?.name) {
          setIsDiscountRequired(false)
        } else {
          setIsDiscountRequired(true)
        }

        if (!reqPayload.flatOff || !reqPayload.flatPer) {
          setIsOneFieldRequiredError(true);
        } else {
          setIsOneFieldRequiredError(false);
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          py={1.5}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={30}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Add Event Discount
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} mb={1}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      <DialogContent sx={{ px: 6 }}>
        <Grid container spacing={2}>
          {inputFields.map((inputField, index) => (
            <>
              <Grid item xs={2} sx={{ mt: 4 }}>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={inputField.is_active}
                      onChange={(e) =>
                        handleFieldChange(index, 'is_active', e.target.checked)
                      }
                      size="sm"
                      sx={{ m: 1 }}
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                  sx={{
                    "& .MuiTypography-root": {
                      color: theme?.palette?.text?.containerTextLabel,
                      fontWeight: 400,
                      fontFamily: "inherit",
                    },
                  }}
                //disabled={inputField?.hasOwnProperty("id")}
                />
              </Grid>

              <Grid item xs={3}>
                <CustomInputLabel htmlFor={inputField.idObj}>
                  Discount Code
                </CustomInputLabel>
                <CustomTextField
                  id={inputField.idObj}
                  name={`name-${index}`}
                  placeholder="Enter a code"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleFieldChange(index, 'name', e.target.value)
                  }
                  value={inputField.name}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                  disabled={!inputField?.is_active}
                />
                {(isDiscountRequired && !inputField.name) && (
                  <FormHelperText error>Discount Code is required</FormHelperText>
                )}
              </Grid>

              <Grid item xs={2}>
                <CustomInputLabel htmlFor={inputField.idObj}>
                  Discount $
                </CustomInputLabel>
                <CustomTextField
                  id={inputField.idObj}
                  name={`flatOff-${index}`}
                  type="number"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleFieldChange(index, 'flatOff', e.target.value)
                  }
                  value={inputField.flatOff}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        $
                      </InputAdornment>
                    ),
                  }}
                  disabled={!inputField?.is_active}
                />
                {(isOneFieldRequiredError && !inputField?.hasOwnProperty("id")) && (
                  <FormHelperText error>At least one field is required of discount off or discount percentage</FormHelperText>
                )}

                {(isValueGTzero && !inputField?.hasOwnProperty("id")) && (
                  <FormHelperText error>Value must be greater than to 0</FormHelperText>
                )}
              </Grid>

              <Grid item xs={2}>
                <CustomInputLabel htmlFor={inputField.idObj}>
                  Discount %
                </CustomInputLabel>
                <CustomTextField
                  id={inputField.idObj}
                  name={`flatPer-${index}`}
                  type="number"
                  variant="outlined"
                  size="small"
                  onChange={(e) =>
                    handleFieldChange(index, 'flatPer', e.target.value)
                  }
                  value={inputField.flatPer}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        %
                      </InputAdornment>
                    ),
                  }}
                  disabled={!inputField?.is_active}
                />
              </Grid>

              <Grid item xs={3}>
                {
                  inputField?.hasOwnProperty("id") ?
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        type="button"
                        sx={{ width: "70px", height: "45px", mt: 3.5, mr: 1 }}
                        onClick={() => { handleEditInputField(index) }}
                      // disabled={!inputField?.is_active}
                      >
                        <EditOutlinedIcon
                          sx={{ fontSize: 22, cursor: "pointer", }}
                        />
                      </Button>
                      {/* <Button
                          size="small"
                          variant="contained"
                          type="button"
                          sx={{ width: "70px", height: "45px", mt: 3.5 }}
                          onClick={() => { setDiscountId(inputField?.id); setShowDeletePopup(true) }}
                        //disabled={!inputField?.is_active}
                        >
                          <DeleteSweepIcon
                            onClick={() => { setDiscountId(inputField?.id); setShowDeletePopup(true) }}
                            sx={{ fontSize: 22, cursor: "pointer", }}
                          />
                        </Button> */}
                    </>
                    :
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      sx={{ width: "70px", height: "45px", mt: 3.5, fontSize: "25px" }}
                      onClick={() => handleAddInputField(index)}
                    >
                      +
                    </Button>
                }

              </Grid>
            </>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          px: 2.5,
          mt: 3,
          pb: 2
        }}>
        <Box display="flex" justifyContent="end" width={1}>
          <Typography
            variant="h5"
            sx={{
              width: 120,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer"
            }}
            onClick={() => handleClose(false)}
          >
            Cancel
          </Typography>
          {/* <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{
                width: 120,
                height: 40,
                background: theme?.palette?.secondary?.dark,
                color: "white",
                ml: 2
              }}
            >
              Save
            </Button> */}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddUpdateEventDiscountCode;
