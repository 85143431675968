import { Box, Button, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_TOAST,
  deleteTeamCoachAction,
  getAllSubOrg,
  getAllTeamCoachAction,
} from "../../store/actions";
import ActionCard from "../../components/Common/ActionCard";
import SuccessPopup from "../../components/Common/SuccessPopup";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { charValidate, formatPhoneNumber } from "../../utils/functions";
import AddEditCoach from "../../components/TeamCoachAndManager/AddEditCoach";
import ViewTeamsModal from "../../components/TeamCoachAndManager/ViewTeamsModal";

const TeamCoach = () => {
  const tableRef = useRef();
  const [dataGridList, setDataGridList] = useState();
  const [subOrgsList, setSubOrgs] = useState([]);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showViewTeamtModal, setShowViewTeamtModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [hasSubOrgMore, setHasSubOrgMore] = useState(true);
  const [subOrgOffset, setSubOrgOffset] = useState(0);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getTeamCoachList("");
      getAllSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: subOrgOffset,
      limit: 20
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      const res = response?.data?.data ?? []
      setSubOrgs(prevOptions => [...prevOptions, ...res]);
      setSubOrgOffset(prevOffset => prevOffset + res.length);
      if (res.length === 0) {
        setHasSubOrgMore(false);
      }
    }
  };

  const handleSubOrgScroll = (e) => {
    const target = e.target;
    if ((target?.scrollHeight - target?.scrollTop) === target?.clientHeight && hasSubOrgMore) {
      getAllSubOrgList();
    }
  };

  const getTeamCoachList = async (subOrgId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllTeamCoachAction(params, subOrgId);
    if (response?.ok) {
      setDataGridList(response?.data ?? []);
    }
  };

  const columns = [
    {
      field: "teamName",
      headerName: "TEAM NAME",
      flex: 1,
      // minWidth: 110,
      renderCell: (data) => {
        if (data?.row?.team && data?.row?.team?.length) {
          const recordsToDisplay = data?.row?.team?.slice(0, 1);
          return (
            <Box display="flex" flexDirection="column">
              {recordsToDisplay?.map(team => (
                <Tooltip title={team?.title ?? "N/A"}>
                  <Typography
                    sx={{ py: 0.4 }}
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={600}
                    color={theme?.palette?.grey["700"]}
                  >
                    {team?.title ? charValidate(`${team?.title}`, 20) : "N/A"}
                  </Typography>
                </Tooltip>
              ))}
              {data?.row?.team?.length > 1 && (
                <Typography
                  sx={{ cursor: 'pointer', color: theme?.palette?.primary?.main, textDecoration: "underline" }}
                  onClick={() => setShowViewTeamtModal(true)}
                >
                  View more Teams
                </Typography>
              )}
            </Box>
          )
        } else {
          return "N/A"
        }
      },
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      flex: 1,
      // minWidth: 120,
      renderCell: (data) => data?.row?.first_name ? charValidate(data?.row?.first_name, 15) : "N/A",
      renderCell: (data) => (
        <Tooltip title={data?.row?.first_name ?? "N/A"}>
          {data?.row?.first_name ? charValidate(data?.row?.first_name, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      flex: 1,
      // minWidth: 120,
      renderCell: (data) => data?.row?.last_name ? charValidate(data?.row?.last_name, 15) : "N/A",
      renderCell: (data) => (
        <Tooltip title={data?.row?.last_name ?? "N/A"}>
          {data?.row?.last_name ? charValidate(data?.row?.last_name, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      // minWidth: 120,
      renderCell: (data) => data?.row?.email ? charValidate(data?.row?.email, 15) : "N/A",
      renderCell: (data) => (
        <Tooltip title={data?.row?.email ?? "N/A"}>
          {data?.row?.email ? charValidate(data?.row?.email, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "mobile",
      headerName: "PHONE",
      renderCell: (data) => (
        // <Tooltip title={data?.row?.mobile ? `${data?.row?.countryCode ? "+"+data?.row?.countryCode : ""}${data?.row?.mobile}` : "N/A"}>
        //   {data?.row?.mobile ? charValidate(`${data?.row?.countryCode ? "+"+data?.row?.countryCode : ""}${data?.row?.mobile}`, 15) : "N/A"}
        // </Tooltip>
        <Tooltip title={data?.row?.mobile ?? "N/A"}>
          {data?.row?.mobile ? charValidate(formatPhoneNumber(data?.row?.mobile), 15) : "N/A"}
        </Tooltip>
      ),
      flex: 1,
      // minWidth: 130,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClick={() => setPopoverAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Actions"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2 }}
                    onClick={handleEditClick}
                  >
                    Edit Coach Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingY: 1.2,
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteClick}
                  >
                    Delete Coach
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = () => {
    setPopoverAnchorEl(null);
    setShowAddEditModal(true);
  };

  const handleDeleteClick = () => {
    setPopoverAnchorEl(null);
    setShowDeletePopup(true);
  };

  const handleModalClose = (isClosed) => {
    setShowAddEditModal(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
    } else {
      setShowSuccessPopup(true);
      getTeamCoachList("");
    }
  };

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteTeamCoachAction(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "success",
          message: response?.message,
        },
      });
      setShowDeletePopup(false);
      getTeamCoachList("");
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleSubOrgFilterChange = (e) => {
    let { value } = e?.target;
    if (value) {
      getTeamCoachList(value === "all" ? "" : value);
    }
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Team Coaches"}
        gridData={dataGridList}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={dataGridList ? false : true}
        isShowSubOrgFilter={true}
        onClickAddNew={() => { setShowAddEditModal(true); setSelectedDataGridRow("") }}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
        subOrgsList={subOrgsList}
        handleSubOrgScroll={handleSubOrgScroll}
        handleSubOrgFilterChange={handleSubOrgFilterChange}
      />
      {showAddEditModal &&
        <AddEditCoach
          open={showAddEditModal}
          handleClose={handleModalClose}
          editData={selectedDataGridRow}
        />
      }
      {showSuccessPopup && (
        <SuccessPopup
          title={"Team Coach"}
          message={"Success! Coach details have been added/updated."}
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
        />
      )}
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Team Coach"}
          message={
            "Are you sure you want to delete this coach?"
          }
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedDataGridRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {showViewTeamtModal && (
        <ViewTeamsModal
          open={showViewTeamtModal}
          handleClose={() => {
            setShowViewTeamtModal(false);
            setSelectedDataGridRow("");
          }}
          selectedData={selectedDataGridRow}
        />
      )}
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Team Coach"}
          message={"Are you sure, want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setShowAddEditModal(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
            setSelectedDataGridRow(undefined);
            dispatch({
              type: "RESET_ORG_TEAMS_INFO",
              data: "reset",
            });
          }}
        />
      )}
    </>
  );
};

export default TeamCoach;
