import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import {
  SET_TOAST,
  addEventSprintAction,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../../store/actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { format } from "date-fns";
import CustomSwitch from "../../../../components/Common/Inputs/Switch";

const AddSprint = ({ open, handleClose, editData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [doNotshowSprint, setDoNotShowSprint] = useState(true);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Name is required"),
    order: Yup.number()
      .min(1, "Invalid order number, should be greater than 0")
      .positive("Invalid order number, should be greater than 0")
      .required("Sprint order is required"),
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string().required("End Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: editData?.name ?? "",
      order: editData?.order,
      start_date: editData?.start_date ? dayjs(editData?.start_date) : "",
      end_date: editData?.end_date ? dayjs(editData?.end_date) : ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.name = values.name.trim();
      let formattedStartDate = dayjs(values.start_date).format("YYYY-MM-DD");
      let formattedEndDate = dayjs(values.end_date).format("YYYY-MM-DD");
      addEventSprint({
        id: editData?.id ? editData?.id : undefined,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        order: values?.order,
        name: values?.name,
        show: doNotshowSprint
      });
    },
  });

  const startDate = dayjs(formik.values.start_date);
  const endDate = dayjs(formik.values.end_date);

  const addEventSprint = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await addEventSprintAction(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response?.message ?? "Success",
        },
      });
      handleClose(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  useEffect(() => {
    if (editData?.id) {
      setDoNotShowSprint(editData?.show)
    }
  }, editData?.id)

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            py={1.5}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={30}
              color={theme?.palette?.text?.containerTextLabel}
            >
              {editData?.id ? "Edit Sprint" : "Add Sprint"}
            </Typography>
            <FormControlLabel
              my={1}
              sx={{
                my: 1,
                mx: 0,
                "& .MuiFormControlLabel-label": {
                  ml: 1,
                },
              }}
              control={
                <CustomSwitch
                  name="doNotshowSprint"
                  checked={doNotshowSprint}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    setDoNotShowSprint(newValue);
                  }}
                  sx={{ m: 0 }}
                />
              }
              label={"Show On The Sprint View"}
            />
          </Box>
        </DialogTitle>
        <Box width={1} mb={1}>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        <DialogContent sx={{ px: 6 }}>
          <Box width={1}>
            <CustomInputLabel htmlFor="name">Name</CustomInputLabel>
            <CustomTextField
              id="name"
              name="name"
              placeholder="Enter sprint name..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
            {Boolean(formik.touched.name && formik.errors.name) && (
              <FormHelperText error>{formik.errors.name}</FormHelperText>
            )}
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="name">Order</CustomInputLabel>
            <CustomTextField
              id="order"
              name="order"
              placeholder="Enter order..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.order}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
              type="number"
            />
            {Boolean(formik.touched.order && formik.errors.order) && (
              <FormHelperText error>{formik.errors.order}</FormHelperText>
            )}
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="start_date">Start Date</CustomInputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-US">
              <DatePicker
                disablePast
                slotProps={{
                  textField: {
                    size: "small",
                    error: false,
                  },
                }}
                name="start_date"
                onChange={(newVal) => {
                  formik.setFieldValue("start_date", newVal);
                  formik.setFieldValue("end_date", "");
                }}
                // value={formik.values.start_date}
                value={startDate}
                views={["year", "month", "day"]}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
            {Boolean(formik.touched.start_date && formik.errors.start_date) && (
              <FormHelperText error>{formik.errors.start_date}</FormHelperText>
            )}
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="end_date">End Date</CustomInputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-US">
              <DatePicker
                name="end_date"
                onChange={(newVal) => formik.setFieldValue("end_date", newVal) }
                slotProps={{
                  textField: {
                    size: "small",
                    error: false,
                  },
                }}
                value={endDate}
                views={["year", "month", "day"]}
                minDate={formik.values.start_date}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
            {Boolean(formik.touched.end_date && formik.errors.end_date) && (
              <FormHelperText error>{formik.errors.end_date}</FormHelperText>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            p: 4.5,
            pt: 0,
            [theme.breakpoints.down("sm")]: {
              p: 3,
              pt: 0,
            },
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(false)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSprint;
