import {
  Box,
  Typography,
  useTheme,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../components/CustomTextField";

export default function SendEmailStep1({
  onNext,
  step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const step1Details = state?.sendEmailCommunicate?.step1Details;
  const theme = useTheme();
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const validationSchema = Yup.object({
    campaign_name: Yup.string()
      .trim()
      .max(80, "Campaign name must be at most 80 characters")
      .required("Campaign name is required"),
  });

  const formik = useFormik({
    initialValues: {
      campaign_name: step1Details?.campaign_name.trim() ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "EMAIL_STEP_1_DETAILS",
        data: {
          campaign_name: values?.campaign_name.trim(),
        },
      });
      onNext();
    },
  });

  //   useEffect(() => {
  //     if (step1Details) {
  //       formik.setFieldValue(
  //         "campaign_name",
  //         step1Details?.campaign_name ? step1Details?.campaign_name : "",
  //       );
  //     }
  //   }, [step1Details]);

  return (
    <Box p={6}>
      {/* <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: {
            fontSize: 14,
          },
        }}
      >
        Step 1
      </Typography> */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={12} xs={12}>
            <>
              <CustomInputLabel sx={inputLabelStyle}>
                Create Campaign Name
              </CustomInputLabel>
              <Box display="flex" alignItems="center">
                <Typography variant="span">Sketchplay-</Typography>
                <CustomTextField
                  name="campaign_name"
                  placeholder="Enter a campaign name..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.campaign_name}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                    width: "100%",
                    ml: 1,
                  }}
                />
              </Box>
              <CustomInputLabel
                sx={{
                  fontSize: 12,
                  marginBottom: "5px",
                  color: "gray",
                  ml: 11,
                }}
              >
                MAX 80 Characters is allowed
              </CustomInputLabel>
              {Boolean(
                formik.touched.campaign_name && formik.errors.campaign_name,
              ) && (
                <FormHelperText error>
                  {formik.errors.campaign_name}
                </FormHelperText>
              )}
            </>
          </Grid>
          <Grid item xs={12} mt={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              width={1}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: "transparent",
                  border: `2px solid ${theme?.palette?.secondary?.dark}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme?.palette?.secondary?.dark,
                  cursor: "pointer",
                  mr: 2,
                  my: 1,
                  px: 3,
                }}
                onClick={() => setShowCancelConfirmPopup(true)}
              >
                Cancel
              </Typography>
              <Button
                size="small"
                variant="contained"
                type="submit"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: theme?.palette?.secondary?.dark,
                  color: "white",
                  cursor: "pointer",
                  px: 3,
                  my: 1,
                }}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

SendEmailStep1.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
SendEmailStep1.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];
