import {
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect, useRef } from "react";
import {
  getAllPositionsList,
  getGrades,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../utils/functions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import CancelIcon from "@mui/icons-material/Cancel";

const ChildSchoolInfo = ({
  formik,
  setSelectedPosition,
  selectedPosition,
  eventDetails = false,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const state = useSelector((state) => state);
  const usertoken = localStorage.getItem("usertoken");
  const handleFocus = () => {
    setIsDatePickerOpen(true);
  };
  const handleBlur = () => {
    setIsDatePickerOpen(false);
  };
  const [gradeArr, setGradeArr] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [openClosePositionDropdown, setOpenClosePositionDropdown] =
    useState(false);
  const [openCloseGradeDropdown, setOpenCloseGradeDropdown] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { mutateAsync: getAllPositions } = useMutation(getAllPositionsList);

  const closeIconStyle = {
    color: "red",
    cursor: "pointer",
    position: "absolute",
    right: "8px",
    margin: "0 10px",
  };

  useEffect(() => {
    if (state?.org?.org?.token && !eventDetails) {
      getGradeList();
      getPositions();
    } else {
      setGradeArr(eventDetails?.grade ?? []);
      setPositionList(eventDetails?.position ?? []);
    }
  }, [state?.org?.org?.token, eventDetails]);

  const getGradeList = async () => {
    let params = {
      userToken: state?.user?.token || usertoken,
      orgToken: state?.org?.org?.token,
    };
    let response = await getGrades(params, dispatch);
    if (response?.ok) {
      setGradeArr(response?.data ?? []);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const getPositions = async () => {
    let params = {
      userToken: state?.user?.token || usertoken,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllPositions({ dispatch, params });
    try {
      if (response?.ok) {
        setPositionList(response?.data ?? []);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response.message ?? "Something went wrong! try again",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error.message ?? "Something went wrong! try again",
        },
      });
    }
  };

  const handleDOBChange = (event) => {
    const value = event.target.value;
    const formattedDate = dateFormat(value);
    formik.setFieldValue("dob", formattedDate);
  };

  const handleChangePositionSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPosition(typeof value === "string" ? value.split(",") : value);
  };

  const handleOpenPositionDropdown = () => {
    setOpenClosePositionDropdown(true);
  };
  const handleClosePositionDropdown = () => {
    setOpenClosePositionDropdown(false);
  };

  const handleOpenGradeDropdown = () => {
    setOpenCloseGradeDropdown(true);
  };
  const handleCloseGradeDropdown = () => {
    setOpenCloseGradeDropdown(false);
  };

  return (
    <Grid item xs={12}>
      <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
        {/* School */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.school && formik.errors.school)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <TextField
            id="school"
            name="school"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.school}
            label="School *"
            error={Boolean(formik.touched.school && formik.errors.school)}
          />
          {Boolean(formik.touched.school && formik.errors.school) && (
            <FormHelperText error>{formik.errors.school}</FormHelperText>
          )}
        </FormControl>

        {/* Grade */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.grade && formik.errors.grade)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <Select
            displayEmpty
            name="grade"
            label="Grade *"
            value={formik.values.grade || ""}
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            open={openCloseGradeDropdown}
            onOpen={handleOpenGradeDropdown}
            onClose={handleCloseGradeDropdown}
            sx={{ textAlign: "left" }}
            error={Boolean(formik.touched.grade && formik.errors.grade)}
            input={<OutlinedInput label="" placeholder="Select grade..." />}
            inputProps={{
              "aria-label": "Without label",
            }}
            renderValue={(selected) => {
              if (selected) {
                return selected;
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Select grade...
                  </Typography>
                );
              }
            }}
          >
            <Box sx={{ position: "relative", height: "35px", mb: 1 }}>
              <Button
                variant="contained"
                color="info"
                size="small"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  background: theme?.palette?.secondary?.dark,
                  color: "white",
                  width: "50px",
                  mt: 0.5,
                  position: "absolute",
                  left: 15,
                  right: 0,
                  // margin: "auto",
                }}
                onClick={handleCloseGradeDropdown}
              >
                Close
              </Button>
            </Box>
            {gradeArr.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                <ListItemText primary={option?.name} />
              </MenuItem>
            ))}
          </Select>
          {Boolean(formik.touched.grade && formik.errors.grade) && (
            <FormHelperText error>{formik.errors.grade}</FormHelperText>
          )}
        </FormControl>

        {/* Position */}
        {/* <FormControl
          fullWidth
          error={Boolean(formik.touched.grade && formik.errors.grade)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <TextField
            select
            name="position"
            label="Position"
            value={formik.values.position || ""}
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            sx={{ textAlign: "left" }}
            error={Boolean(formik.touched.position && formik.errors.position)}
          >
            {positionList.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl> */}

        {/* <FormControl
          fullWidth
          error={Boolean(formik.touched.position && formik.errors.position)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <TextField
            id="position"
            name="position"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.position}
            label="Position *"
            error={Boolean(formik.touched.position && formik.errors.position)}
          />
          {Boolean(formik.touched.position && formik.errors.position) && (
            <FormHelperText error>{formik.errors.position}</FormHelperText>
          )}
        </FormControl> */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.position && formik.errors.position)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <Select
            multiple
            displayEmpty
            open={openClosePositionDropdown}
            value={selectedPosition}
            onOpen={handleOpenPositionDropdown}
            onClose={handleClosePositionDropdown}
            onChange={handleChangePositionSelection}
            input={<OutlinedInput label="" placeholder="Select position..." />}
            inputProps={{
              "aria-label": "Without label",
            }}
            renderValue={(selected) => {
              if (selected?.length && positionList?.length) {
                let positions = [];
                positionList?.forEach((position) => {
                  if (selected.includes(position?.id)) {
                    positions.push(position?.name);
                  }
                });
                if (positions?.length) {
                  return positions.join(", ");
                }
                return selected?.join(", ");
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Select one or more position
                  </Typography>
                );
              }
            }}
            // MenuProps={MenuProps}
          >
            <Box sx={{ position: "relative", height: "35px", mb: 1 }}>
              <Button
                variant="contained"
                color="info"
                size="small"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  background: theme?.palette?.secondary?.dark,
                  color: "white",
                  width: "50px",
                  mt: 0.5,
                  position: "absolute",
                  left: 15,
                  right: 0,
                  // margin: "auto",
                }}
                onClick={handleClosePositionDropdown}
              >
                Close
              </Button>
            </Box>
            {positionList && positionList?.length
              ? positionList?.map((position) => (
                  <MenuItem key={position?.id} value={position?.id}>
                    <Checkbox
                      checked={selectedPosition.indexOf(position?.id) > -1}
                    />
                    <ListItemText primary={position?.name} />
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {/* DOB */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.dob && formik.errors.dob)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="dob"
              onChange={(newVal) => formik.setFieldValue("dob", newVal)}
              value={dayjs(formik?.values?.dob)}
              views={["year", "month", "day"]}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                },
              }}
              maxDate={dayjs()}
            />
          </LocalizationProvider>
          {Boolean(formik.touched.dob && formik.errors.dob) && (
            <FormHelperText error>{formik.errors.dob}</FormHelperText>
          )}
          {/* <TextField
            id="dob"
            name="dob"
            variant="outlined"
            placeholder="Enter Birthdate"
            onBlur={handleBlur}
            onInput={handleDOBChange}
            value={formik.values.dob}
            type={isDatePickerOpen ? 'date' : 'text'}
            onFocus={handleFocus}
            label="Enter Birthdate *"
            onKeyDown={(e) => e.preventDefault()}
            inputProps={{
              max: new Date().toISOString().split("T")[0]
            }}
            sx={{
              '& input[type="date"]::-webkit-calendar-picker-indicator': {
                background: 'transparent',
                bottom: 0,
                color: 'transparent',
                cursor: 'pointer',
                height: 'auto',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                width: 'auto',
              },
            }}
            error={Boolean(formik.touched.dob && formik.errors.dob)}
          /> */}
          {/* {Boolean(formik.touched.dob && formik.errors.dob) && (
            <FormHelperText error>{formik.errors.dob}</FormHelperText>
          )} */}
        </FormControl>
      </Box>
    </Grid>
  );
};

export default ChildSchoolInfo;
