import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@emotion/react";
import { Button, MenuItem, OutlinedInput, Select, TablePagination } from "@mui/material";
import CustomTextField from "../../CustomTextField";
import { useNavigate } from "react-router-dom";
import { Team_Coach, Team_Manager } from "../../../routes/constants";
import { phoneFormat } from "../../../utils/functions";

const RowList = ({
  list,
  data,
  index,
  actions,
  cellDataFields,
  customCell,
  cellNameToCustomCell,
  customerDetailsComponent,
  rowClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const userType = list?.user_type || "";
  const isPlayer = userType === "player";
  const dataList =
    list?.athlete?.length
      ? list?.athlete
      : [];
  // const dataList = userType === "player"
  //   ? list?.parent?.length
  //     ? list?.parent
  //     : []
  //   : list?.athlete?.length
  //     ? list?.athlete
  //     : [];
  return (
    <>
      <TableRow
        key={list?.id}
        // sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => rowClick(list)}
      >
        <TableCell>
          {index + 1}
        </TableCell>
        <TableCell sx={{ width: "50px" }}>
          {dataList?.length ?
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> : null
          }
        </TableCell>
        {cellDataFields?.map((val) => {
          if (cellNameToCustomCell === val) {
            return customCell(list, {});
          } else {
            return (
              <TableCell key={val} >
                {typeof list[val] === "string" ||
                  typeof list[val] === "number"
                  ?
                  val === "mobile" ?
                    <>
                      {phoneFormat(list[val])}
                    </> : list[val]

                  : typeof list[val] === "object" ?
                    <Box display="flex" flexDirection="column">
                      {list[val]?.length ?
                        list[val]?.map(subOrg => (
                          <Typography
                            sx={{ pt: 0.4 }}
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={600}
                            color={theme?.palette?.text?.containerTextLabel}
                          >
                            {subOrg?.title ?? "N/A"},
                          </Typography>
                        )) : "N/A"}
                    </Box>
                    : "N/A"
                }
              </TableCell>
            );
          }
        })}
        <TableCell align="center">{actions(list)}</TableCell>
      </TableRow>
      {/* <TableRow sx={{ display: open ? "table-row" : "none" }}> */}
      {/* <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
          colSpan={12}
        > */}
      {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
      {/* <Box sx={{ margin: 1 }}> */}
      {/* <TableCell sx={{ borderBottom: "none" }}> */}
      {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
      {/* </TableCell> */}
      {customerDetailsComponent({
        list,
        actions,
        cellDataFields,
        customCell,
        cellNameToCustomCell,
        open,
        rowClick
      })}
      {/* </Box> */}
      {/* </Collapse> */}
      {/* {/* </TableCell> */}
      {/* </TableRow> */}
    </>
  );
};

const MuiTable = ({
  headerLabel,
  data = [],
  columns = [],
  paginationOptions,
  tableRef,
  isLoading,
  onClickAddNew,
  rightBtnLabel = "Add New",
  isSearchIconShow = false,
  isAddNewBtnShow = true,
  isShowClientFilter = false,
  handleSubOrgFilterChange,
  actions = () => "",
  cellDataFields = [],
  customCell = () => "",
  cellNameToCustomCell = "",
  customerDetailsComponent = () => "",
  gridToolbarButtons = () => null,
  rowClick,
  handleChangePage,
  handleChangeRowsPerPage,
  pageNum,
  rowsPerPage,
  totalRows,
  isShowUsersBtn = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: "95%",
          position: "relative",
          backgroundColor: theme?.palette?.background?.paper,
          boxShadow: theme?.palette?.boxShadow?.tableContainer,
          borderRadius: 1,
          border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
        }}
        mt={4}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={3}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: "column",
              alignItems: "start",
            }
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            pr={2}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {headerLabel}
          </Typography>
          <Box display="flex" alignItems="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                width: "100%",
              }
            }}
          >
            {isSearchIconShow && (
              <CustomTextField
                name="grid-search"
                placeholder="Search..."
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: 250,
                    boxShadow: "none",
                  },
                }}
              />
            )}
            {isShowClientFilter && (
              <Box ml={3}>
                <Select
                  labelId="filter-by-client"
                  onChange={handleSubOrgFilterChange}
                  input={
                    <OutlinedInput
                      placeholder="Filter By Client"
                      sx={{ width: 250, height: 45 }}
                    />
                  }
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Filter By Client
                      </Typography>
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    Filter By Client
                  </MenuItem>
                  {[]?.map((client) => (
                    <MenuItem key={client?.id} value={client?.id}>
                      {client?.title}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <Box className="user-select-box" sx={{
              display: "flex", alignItems: "center",
              [theme.breakpoints.down('md')]: {
                width: "100%",
                mt: 2,
                flexDirection: "column",
                alignItems: "start"
              }
            }}>
              {gridToolbarButtons()}
              {isAddNewBtnShow && (
                <Button
                  onClick={onClickAddNew}
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{
                    paddingX: 4, paddingY: 1.2, ml: 1, whiteSpace: "nowrap",
                    [theme.breakpoints.down('md')]: {
                      ml: 0,
                    }
                  }}
                >
                  {rightBtnLabel}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mt={1}
        ></Box>

        {/* MUI Table */}
        <Box px={3} py={3}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "50px" }}>
                    <Box sx={{ fontWeight: "bold" }}>
                      USER #
                    </Box>
                  </TableCell>
                  {/* <TableCell /> */}
                  <TableCell />
                  {columns?.map((column) => (
                    <TableCell
                      key={column.field}
                      sx={{ fontWeight: 700 }}
                      {...column.cellOptions}
                    >
                      {React.isValidElement(column.headerName)
                        ? column.headerName
                        : column.headerName.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((list, index) => {
                  return (
                    <RowList
                      cellDataFields={cellDataFields}
                      list={list}
                      data={data}
                      index={index}
                      actions={actions}
                      customCell={customCell}
                      cellNameToCustomCell={cellNameToCustomCell}
                      customerDetailsComponent={customerDetailsComponent}
                      rowClick={rowClick}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {data?.length ?
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={pageNum}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          /> : null
        }
        {!data?.length || isLoading ?
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize={16}
            mt={2}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{ textAlign: "center", p: 4 }}
          >
            {isLoading ? "Loading..." : "No Data Found"}
          </Typography> : null}
      </Box>
    </Box>
  );
};

export default MuiTable;
