import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import httpClient from "@utils/httpClient";
import { useState } from "react";
import { SET_USER, getMe, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { APP_MESSAGES } from "../../utils/constants";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import UserInfoForm from "../AppForms/UserInfoForm";
import AddressForm from "../AppForms/AddressForm";
import ChildSchoolInfo from "../AppForms/ChildSchoolInfo";
import SocialMediaProfile from "../AppForms/SocialMediaProfile";
import PhoneInput from "react-phone-input-2";
import UploadRosterDocuments from "../Tournament/UploadRosterDocuments";
import dayjs from "dayjs";
import { dateFormatForAPI } from "../../utils/functions";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required(APP_MESSAGES.emptyEditFirstNameMsg),
  last_name: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required(APP_MESSAGES.emptyEditLastNameMsg),
  bio: Yup.string().trim(),
  image: Yup.string(),
  address: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("Address is required"),
  suite: Yup.string().trim(),
  city: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("City is required"),
  state: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("State is required"),
  zip_code: Yup.string()
    .max(10)
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("Zip Code is required"),
  school: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("School Name is required"),
  grade: Yup.string().trim().required("Grade is required"),
  //position: Yup.string().trim().transform(value => (value === '' ? null : value)).nullable().required("Position is required"),
  dob: Yup.date().required("Date of Birth is required"),
  emergency_contact_name: Yup.string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("Contact Name is required"),
  emergency_contact_number: Yup.string()
    .min(10, "Contact No. must be at least 10 characters")
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .required("Contact Number is required"),
  twitter_url: Yup.string().trim(),
  tiktok_url: Yup.string().trim(),
  insta_url: Yup.string().trim(),
  snap_url: Yup.string().trim(),
  linkedin_url: Yup.string().trim(),
  fb_url: Yup.string().trim(),
  yt_url: Yup.string().trim(),
});

const AthleteProfile = ({ UserDrawerClose, setStep }) => {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [imageChange, setImageChange] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState([]);

  const handleSave = async (values, actions) => {
    setShowLoader(true);
    if (imageChange) {
      const API_URL = "/auth/change-profilePic";
      const formData = new FormData();
      formData.append("files", imageChange);
      const apiRes = await httpClient.post(API_URL, formData);
      if (apiRes && apiRes.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: "Your bio has successfully been updated.",
          },
        });
      } else {
        setShowLoader(false);
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: "error", message: apiRes.message },
        });
      }
    }
    if (!selectedPosition?.length) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: "Choose position" },
      });
      return;
    }
    const postData = { ...values, position: selectedPosition ?? [] };
    postData.dob = postData?.dob
      ? dateFormatForAPI(postData?.dob)
      : postData?.dob;
    const apiRes = await httpClient.post("/auth/edit-profile", postData);
    if (apiRes && apiRes.ok) {
      setShowLoader(false);
      dispatch({ type: SET_USER, data: apiRes.user });
      dispatch(getMe());
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: apiRes.message },
      });
      UserDrawerClose();
      setStep(0);
    } else {
      setShowLoader(false);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: apiRes.message },
      });
      return false;
    }
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      bio: "",
      address: "",
      suite: "",
      city: "",
      state: "",
      zip_code: "",
      school: "",
      grade: "",
      //position: "",
      dob: "",
      emergency_contact_name: "",
      emergency_contact_number: "",
      emergency_contact_countryCode: "",
      twitter_url: "",
      tiktok_url: "",
      insta_url: "",
      snap_url: "",
      linkedin_url: "",
      fb_url: "",
      yt_url: "",
      user_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue("first_name", user.first_name);
      formik.setFieldValue("last_name", user.last_name);
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("mobile", user.mobile);
      formik.setFieldValue("bio", user.bio);
      formik.setFieldValue("image", user.image);
      formik.setFieldValue("user_type", user.user_type);
      formik.setFieldValue("address", user.address);
      formik.setFieldValue("suite", user.suite || "");
      formik.setFieldValue("city", user.city);
      formik.setFieldValue("state", user.state);
      formik.setFieldValue("zip_code", user.zip_code);
      formik.setFieldValue("school", user.school);
      formik.setFieldValue("grade", user.grade);
      //formik.setFieldValue('position', user.position);
      formik.setFieldValue("dob", user.dob ? dayjs(user.dob) : "");
      formik.setFieldValue(
        "emergency_contact_name",
        user.emergency_contact_name,
      );
      formik.setFieldValue(
        "emergency_contact_number",
        user.emergency_contact_number,
      );
      formik.setFieldValue(
        "emergency_contact_countryCode",
        user.emergency_contact_countryCode,
      );
      formik.setFieldValue("twitter_url", user.twitter_url || "");
      formik.setFieldValue("tiktok_url", user.tiktok_url || "");
      formik.setFieldValue("insta_url", user.insta_url || "");
      formik.setFieldValue("snap_url", user.snap_url || "");
      formik.setFieldValue("linkedin_url", user.linkedin_url || "");
      formik.setFieldValue("fb_url", user.fb_url || "");
      formik.setFieldValue("yt_url", user.yt_url || "");
      if (user?.position?.length) {
        let positionIds = user?.position?.map((p) => p?.id);
        setSelectedPosition(positionIds ?? []);
      }
    }
  }, [user, formik.setFieldValue]);

  const handleImageChange = (e) => {
    setImageChange(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("image", URL.createObjectURL(file));
    }
  };
  const EditIconWrapper = styled(EditIcon)`
    position: absolute;
    top: 58px;
    right: 5px;
    color: #ffffff;
    background-color: #000000;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2 !important; ;
  `;

  const handleCloseDocModal = () => {
    setShowDocumentModal(false);
    dispatch(getMe());
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "5px",
            // p: 3,
            "& .MuiOutlinedInput-root": {
              border: "1px solid #dfe0df",
              boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
              background: "#fff",
              "& .MuiOutlinedInput-input": {
                background: "transparent",
                textAlign: "left",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0",
                boxShadow: "none",
              },
              "& span": {
                lineHeight: "inherit",
                height: "auto",
                width: "auto",
                fontSize: "inherit",
                fontWeight: "normal",
                textAlign: "left",
              },
            },
            "& .MuiFormControl-root .MuiFormControl-root": {
              boxShadow: "none",
            },
            span: {
              display: "block",
              fontSize: "37px",
              fontWeight: "bold",
              lineHeight: "99px",
              textAlign: "center",
              height: "100px",
              width: "100px",
            },
            figure: {
              margin: "0",
              height: "100px",
              width: "100px",
              minWidth: "50px",
              borderRadius: "50rem",
              position: "relative",
              overflow: "hidden",
              border: "3px solid #fff",
              boxShadow: " 0 0 15px rgba(0, 0, 0, 0.25)",
              left: "50%",
              transform: "translate(-50%)",
            },
            "#userImg": {
              width: "100%",
              height: "100%",
            },
            "#save": {
              py: 1.5,
              px: 3.5,
              fontSize: 15,
              fontWeight: 700,
              mt: 2,
            },
          }}
        >
          {user?.image ? (
            <Box sx={{ position: "relative" }}>
              <figure>
                <label htmlFor="imageUpload">
                  <img
                    id="userImg"
                    src={formik.values.image}
                    alt="profile-img"
                  />
                  <EditIconWrapper />
                </label>
                <input
                  id="imageUpload"
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </figure>
            </Box>
          ) : (
            <figure>
              <label htmlFor="imageUpload">
                {formik.values.image ? (
                  <img
                    id="userImg"
                    src={formik.values.image}
                    alt="profile-img"
                  />
                ) : (
                  <>
                    <Box
                      component="span"
                      sx={{
                        backgroundColor: "#000000",
                        justifyContent: "center",
                        margin: "0",
                        minWidth: "100px",
                        color: "#ffffff",
                        borderRadius: "50rem",
                        position: "relative",
                        overflow: "hidden",
                        border: "3px solid #fff",
                        boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                        textTransform: "uppercase",
                        height: "100px",
                        width: "100px",
                        lineHeight: "97px",
                        marginLeft: "-3px",
                        marginRight: "auto",
                        marginTop: "-3px",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                    >
                      {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(
                        0,
                      )}`}
                    </Box>
                    <EditIconWrapper />
                  </>
                )}
                <input
                  id="imageUpload"
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
            </figure>
          )}
          <Typography variant="h3" sx={{ mt: 2 }}>
            Your Name & Address
          </Typography>
          {/* <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 1, justifyContent: 'center' }}>
                        <Typography
                            variant='h4'
                            sx={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => setShowDocumentModal(true)}
                        >
                            {user?.documentStatus === "COMPLETE" ? "View Documents" : "Upload"}
                        </Typography>
                        {user?.documentStatus !== "COMPLETE" &&
                            <Typography
                                variant="h4"
                                sx={{
                                    ml: 1,
                                    color: "orange !important",
                                }}
                            >
                                Pending Documents
                            </Typography>
                        }
                        <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                    </Box> */}
          <Box sx={{ backgroundColor: "transparent" }}>
            <Box
              className="guardian-form-box"
              sx={{
                "&.guardian-form-box div": {
                  maxWidth: "100%",
                },
              }}
            >
              <UserInfoForm formik={formik} isEmailPhoneDisabled={true} />
              <FormControl
                fullWidth
                error={Boolean(formik.touched.bio && formik.errors.bio)}
              >
                <TextField
                  id="bio"
                  name="bio"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bio}
                  label="Enter Bio"
                  error={Boolean(formik.touched.bio && formik.errors.bio)}
                  sx={{ boxShadow: theme.shadows[1] }}
                  multiline
                  rows={6}
                />
                {Boolean(formik.touched.bio && formik.errors.bio) && (
                  <FormHelperText error>{formik.errors.bio}</FormHelperText>
                )}
              </FormControl>
              <Box>
                <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
              </Box>
              <AddressForm formik={formik} />
              <Box>
                <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  Your Information
                </Typography>
              </Box>
              <ChildSchoolInfo
                formik={formik}
                setSelectedPosition={setSelectedPosition}
                selectedPosition={selectedPosition}
              />
              <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.emergency_contact_name &&
                  formik.errors.emergency_contact_name,
                )}
                sx={{
                  ...theme.typography.customInput,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0",
                    boxShadow: theme?.palette?.boxShadow?.primary,
                  },
                }}
              >
                <TextField
                  id="emergency_contact_name"
                  name="emergency_contact_name"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.emergency_contact_name}
                  label="Emergency Contact Name *"
                  error={Boolean(
                    formik.touched.emergency_contact_name &&
                    formik.errors.emergency_contact_name,
                  )}
                />
                {Boolean(
                  formik.touched.emergency_contact_name &&
                  formik.errors.emergency_contact_name,
                ) && (
                    <FormHelperText error>
                      {formik.errors.emergency_contact_name}
                    </FormHelperText>
                  )}
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.emergency_contact_number &&
                  formik.errors.emergency_contact_number,
                )}
                sx={{
                  ...theme.typography.customInput,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0",
                    boxShadow: "0px 6px 20px #1F54C326",
                  },
                }}
              >
                <Box
                  sx={{
                    "& .react-tel-input .form-control": {
                      border: 0,
                      "box-shadow": "0px 6px 20px #1F54C326",
                      "min-width": "100%",
                      "font-weight": 500,
                      background: "#ffffff",
                      padding: "15px 14px 15px 50px",
                      "border-radius": "0",
                      height: "51px",
                    },
                    "& .react-tel-input .flag-dropdown": {
                      border: 0,
                      background: "transparent",
                    },
                  }}
                >
                  <PhoneInput
                    country={"us"}
                    id="emergency_contact_number"
                    name="emergency_contact_number"
                    placeholder="(702) 123-4567"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.emergency_contact_number}
                    onlyCountries={["us"]}
                    disableCountryCode={true}
                    disableDropdown={true}
                    onChange={(value, country) => {
                      formik.setFieldValue(
                        "emergency_contact_countryCode",
                        country.countryCode || "",
                      );
                      //formik.setFieldValue('dialCode', country.dialCode || '')
                      formik.setFieldValue("emergency_contact_number", value);
                    }}
                    error={Boolean(
                      formik.touched.emergency_contact_number &&
                      formik.errors.emergency_contact_number,
                    )}
                  />
                </Box>
                {Boolean(
                  formik.touched.emergency_contact_number &&
                  formik.errors.emergency_contact_number,
                ) && (
                    <FormHelperText error>
                      {formik.errors.emergency_contact_number}
                    </FormHelperText>
                  )}
              </FormControl>
              <Box>
                <Divider sx={{ mt: 1.5, mb: 2 }}></Divider>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  Your Social Accounts
                </Typography>
              </Box>
              <SocialMediaProfile formik={formik} />
            </Box>

            <Button id="save" fullWidth variant="contained" type="submit">
              {showLoader ? "Loading..." : "Save"}
            </Button>
          </Box>
        </Box>
      </form>

      {showDocumentModal && (
        <UploadRosterDocuments
          open={showDocumentModal}
          handleClose={handleCloseDocModal}
          rosterId={user?.id ?? ""}
          selectedAthlete={user}
          isShowRosterId={false}
        />
      )}
    </>
  );
};

export default AthleteProfile;
