
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, Card, CardContent, Alert, Button } from '@mui/material'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import AddEditParentGuardian from './AddEditParentGuardian';
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP, getMe } from "@store/actions";
import httpClient from "@utils/httpClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phoneFormat } from '../../utils/functions';

const validationSchema = Yup.object({
    first_name: Yup.string().trim().required("First Name is required"),
    last_name: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
        .trim()
        .required("Email is required")
        .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
    primary_parent_flag: Yup.boolean(),
});

const ManageParentByAthlete = () => {
    const { user } = useSelector((state) => state.user);
    const getArr = user.hasOwnProperty('parent') ? user.parent : []
    const parentArr = [...getArr];
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [isAbleToManage, setIsAbleToManage] = useState(false);
    const [activeParentId, setActiveParentId] = useState(0);


    useEffect(() => {
        dispatch(getMe())
        setIsAbleToManage(user?.registeredBy?.id === undefined ? true : user?.id === user?.registeredBy?.id)
    }, [])

    const handleSave = async (values, actions) => {

        const countryCode = values.countryCode.toUpperCase()
        //const isValid = mobile.length === 10 ? true : false;
        const postData = { ...values, countryCode }
        const reqPayload = {
            id: postData.id,
            first_name: postData.first_name,
            last_name: postData.last_name,
            email: postData.email,
            countryCode: countryCode,
            mobile: postData.mobile,
            primary_parent_flag: postData.primary_parent_flag
        }

        dispatch({ type: SET_LOADER, data: true });
        const API_URL = isEdit ? "/updateParentRelationDetails" : "/addGuardianByAthlete"
        const res = await httpClient.post(API_URL, reqPayload);
        dispatch({ type: SET_LOADER, data: false });

        if (res && res.ok) {
            actions.resetForm();
            setFormData({})
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'success', message: res.message }
            })
            dispatch(getMe())
            setActiveParentId(0)
            setIsEdit(false)
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: res.message }
            })
            return false;
        }
    };

    const handleEditParent = (obj, index) => {
        setActiveParentId(obj.id)
        setIsEdit(true)
        formik.setFieldValue("first_name", obj.first_name)
        formik.setFieldValue("last_name", obj.last_name)
        formik.setFieldValue("email", obj.email)
        formik.setFieldValue("mobile", obj.mobile)
        formik.setFieldValue("countryCode", obj.countryCode)
        formik.setFieldValue("primary_parent_flag", obj.isPrimaryParent)
        formik.setFieldValue("id", obj.id)
    }

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            countryCode: "",
            dialCode: "",
            id: "",
            primary_parent_flag: false
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    });

    return (
        <>
            <Grid
                container
                spacing={4}
                columns={{ xs: 12, sm: 12, md: 12 }}
                sx={{ pt: 2 }}
            >
                <Grid item xs={12} md={4} sx={{ mb: 3 }}>
                    <Grid container sx={{ mt: 4, mb: 4, alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <Typography variant="h2" sx={{ color: '#262626' }}>
                                My Parents/Guardians
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} alignItems="center" textAlign="center">
                        {parentArr.map((parentData, index) => (
                            <Card
                                key={index}
                                sx={{
                                    boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.1)",
                                    backgroundColor: "white !important",
                                    padding: "1rem",
                                    overflow: 'inherit',
                                    mt: 2,
                                    border: parentData.id === activeParentId ? '1px solid #000000' : 'none'
                                }}
                            >
                                <CardContent sx={{ p: "0", pb: "0 !important", position: 'relative' }}>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Box
                                                sx={{
                                                    width: "70px",
                                                    height: "70px",
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        backgroundColor: "#000000",
                                                        justifyContent: "center",
                                                        margin: "0",
                                                        minWidth: "70px",
                                                        color: "#ffffff",
                                                        borderRadius: "50rem",
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        textTransform: "uppercase",
                                                        height: "70px",
                                                        width: "70px",
                                                        lineHeight: "70px",
                                                        marginLeft: "0",
                                                        marginRight: "auto",
                                                        marginTop: "0",
                                                        fontSize: "2rem",
                                                        cursor: "pointer",
                                                        fontWeight: 'bold',
                                                        display: 'block',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {`${parentData?.first_name?.charAt(
                                                        0,
                                                    )}${parentData?.last_name?.charAt(0)}`}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs={8} container direction="column" spacing={2}>
                                                <Grid item xs sx={{ textAlign: 'left' }}>
                                                    <Typography
                                                        gutterBottom
                                                        variant="subtitle1"
                                                        component="div"
                                                        sx={{ fontSize: "16px", fontWeight: "600", m: 0 }}
                                                    >
                                                        {`${parentData?.first_name} ${parentData?.last_name}`}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            fontFamily: '"Arial",sans-serif,Arial',
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {phoneFormat(parentData?.mobile)}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            fontFamily: '"Arial",sans-serif,Arial',
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {parentData?.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4} sx={{ alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="div"
                                                    sx={{
                                                        textAlign: "center",
                                                        padding: '0 1rem 0 0',
                                                    }}
                                                >
                                                    {isAbleToManage &&
                                                        <>
                                                            {parentData.isPrimaryParent && <StarRoundedIcon sx={{ color: '#f1cb00', mr: 1 }} />
                                                            }
                                                            <EditOutlinedIcon sx={{ color: '#212121', cursor: 'pointer' }} onClick={() => handleEditParent(parentData, index)} />
                                                        </>
                                                    }

                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {/* <Box sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-30px',
                                        }}>
                                            {
                                                Object.keys(user?.registeredBy).length === 0 &&
                                                <>
                                                    {index !== 0 && (
                                                        <CancelIcon sx={{ color: '#f44236', fontSize: '2rem' }} />
                                                    )}
                                                </>
                                            }
                                        </Box> */}
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}

                    </Grid>
                </Grid>
                {
                    isAbleToManage &&
                    <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                        <Box sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} sx={{ my: 1 }}>
                                <Typography variant="h2" sx={{ color: '#262626' }}>
                                    {isEdit ? "Edit" : "Add"} Parent/Guardian
                                </Typography>
                            </Grid>
                        </Box>

                        <form onSubmit={formik.handleSubmit}>
                            <AddEditParentGuardian isEdit={isEdit} formik={formik} />

                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box sx={{ width: "100%" }}>
                                    <Button
                                        disableElevation
                                        disabled={formik.isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        sx={{ fontWeight: "600" }}
                                    >
                                        Save
                                    </Button>
                                    {isEdit && <Button
                                        fullWidth
                                        size="large"
                                        variant="outlined"
                                        sx={{ fontWeight: "600", mt: 2 }}
                                        onClick={() => { setActiveParentId(0); setIsEdit(false); formik.resetForm() }}
                                    >
                                        Cancel
                                    </Button>}
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                }

            </Grid >
        </>
    )

}

export default ManageParentByAthlete
