import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  ListItemText,
  Typography,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import {
  SET_TOAST,
  saveOrgSettingsAction,
  getEventListForReport,
  SET_SUCCESS_ERROR_POPUP,
  getExportReportToCSV,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import "react-quill/dist/quill.snow.css";
import { downloadFile } from "../../utils/functions";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

const ReportingFinance = () => {
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const [dates, setDates] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const orgdata = useSelector((state) => state.org?.org?.data);
  const [selectedReport, setSelectedReport] = useState("");
  const [eventData, setEventData] = useState([]);
  const [seletctedEventData, setSeletctedEventData] = useState([]);
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const selectionInputStyle = { height: 40, width: 250 };
  const reportData = [
    { id: 1, name: "Registered User List" },
    { id: 2, name: "Registered Athlete List for Event" },
    { id: 3, name: "User Product Purchase List" },
    { id: 4, name: "Scholarship List" },
    { id: 5, name: "Gridiron Ops" },
    { id: 6, name: "Pack Count Detail" },
    { id: 7, name: "Speed Agility" },
    { id: 8, name: "Event Athlete Scholarship Count" },
    { id: 9, name: "League Registered User List" },
  ];

  useEffect(() => {
    if (selectedReport === 2) {
      getEventList();
    }
  }, [selectedReport]);

  const exportData = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      start_date: dates?.length ? moment(dates[0]).format("YYYY-MM-DD") : "",
      end_date: dates?.length
        ? dates?.length > 1
          ? moment(dates[1]).format("YYYY-MM-DD")
          : moment(dates[0]).format("YYYY-MM-DD")
        : "",
      selectedReport: selectedReport,
    };
    if (selectedReport) {
      if (selectedReport === 1) {
        params.url = "/getAllUsersCSV";
      } else if (selectedReport === 2) {
        params.url =
          "/downloadRegisteredEventAthlete?event_id=" + seletctedEventData;
      } else if (selectedReport === 3) {
        params.url = "/downloadPackDetails";
      } else if (selectedReport === 4) {
        params.url = "/getAllScholarshipDownload";
      } else if (selectedReport === 5) {
        params.url = "/getAllTransactionReport";
      } else if (selectedReport === 6) {
        params.url = "/downloadSeparatePackDetails";
      } else if (selectedReport === 7) {
        params.url = "/getAllSpeedAgilityTransactionReport";
      } else if (selectedReport === 8) {
        params.url = "/getAllRegisteredEventAthleteCount";
      } else if (selectedReport === 9) {
        params.url = "/getAllRegisteredLeagueUsersCSV";
      }
      let response = await getExportReportToCSV(params, dispatch);
      if (response?.ok) {
        downloadFile(response?.data);
        setSelectedReport("");
        setDates([]);
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response?.message ?? "Data exported successfully",
          },
        });
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong",
          },
        });
      }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Please select at least one report",
        },
      });
    }
  };

  const getEventList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };

    let response = await getEventListForReport(params, dispatch);
    if (response?.ok) {
      setEventData(response?.data);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleChangeEvent = (event) => {
    const {
      target: { value },
    } = event;
    setSeletctedEventData(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" component="div">
                Reports
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              mt={1}
              // sx={{
              //     justifyContent: "center",
              // }}
            >
              <Grid item md={10} lg={6} sm={12} xs={12}>
                <Typography
                  variant="span"
                  //fontWeight={700}
                  sx={{ fontSize: 14 }}
                  color={theme.palette.dark.dark3}
                >
                  Export data from the Sketchplay platform to help you run
                  smoother operations
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={1}
              // sx={{
              //     justifyContent: "center",
              // }}
            >
              <Grid item md={10} lg={6} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      Select Date Range
                    </CustomInputLabel>
                    <DatePicker
                      value={dates}
                      onChange={(data, date) => {
                        if (data && date?.validatedValue) {
                          setDates(date?.validatedValue ?? []);
                        }
                      }}
                      name="dates"
                      format="MMMM DD YYYY"
                      sort
                      inputClass="multi_select_datepicker"
                      placeholder="Choose a date range..."
                      style={{
                        padding: "0 0 0 10px",
                        height: "42px",
                      }}
                      minDate={new Date(moment().subtract(20, "year"))}
                      maxDate={new Date()}
                      range={true}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      Select Report
                    </CustomInputLabel>
                    <Select
                      name="selectedReport"
                      onChange={(e) => {
                        setSeletctedEventData([]);
                        setSelectedReport(e?.target?.value);
                      }}
                      value={selectedReport}
                      input={<OutlinedInput sx={selectionInputStyle} />}
                      MenuProps={MenuProps}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      renderValue={(value) => {
                        if (value) {
                          let selected = reportData?.find(
                            (report) => report?.id === value,
                          );
                          if (selected) {
                            return selected?.name;
                          }
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Choose a report...
                            </Typography>
                          );
                        }
                      }}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "10.5px 14px",
                        },
                        width: "100%",
                      }}
                    >
                      {reportData?.map((report) => (
                        <MenuItem key={report.id} value={report.id}>
                          {report.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {selectedReport === 2 && (
                  <Grid item sm={6} xs={12} sx={{ mt: 2 }}>
                    <CustomInputLabel sx={inputLabelStyle}>
                      Select Event
                    </CustomInputLabel>
                    <Select
                      name="seletctedEventData"
                      onChange={handleChangeEvent}
                      value={seletctedEventData}
                      input={<OutlinedInput sx={selectionInputStyle} />}
                      MenuProps={MenuProps}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      multiple
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected?.length && eventData?.length) {
                          let eventIds = [];
                          eventData?.filter((event) => {
                            if (selected.includes(event?.id)) {
                              eventIds.push(event?.title);
                            }
                          });
                          if (eventIds?.length) {
                            return eventIds.join(", ");
                          }
                          return selected?.join(", ");
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select Events...
                            </Typography>
                          );
                        }
                      }}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "10.5px 14px",
                        },
                        width: "100%",
                      }}
                    >
                      {eventData?.map((event) => (
                        <MenuItem
                          key={event?.id}
                          value={event?.id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "baseline",
                            //px: 2,
                            //pb: 0.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              //px: 2,
                              //pb: 0.5,
                            }}
                          >
                            <Checkbox
                              checked={
                                seletctedEventData.indexOf(event?.id) > -1
                              }
                            />
                            <ListItemText primary={event?.title} />
                          </Box>

                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            color={theme?.palette?.warning?.blue}
                          >
                            (
                            {`${moment(event?.start_date).format(
                              "L",
                            )} - ${moment(event?.end_date).format("L")}`}
                            )
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              mt={0}
              // sx={{
              //     justifyContent: "center",
              // }}
            >
              <Grid item md={10} lg={6} sm={12} xs={12}>
                <Box display="flex" alignItems="center">
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1 }}
                    onClick={() => exportData()}
                    disabled={selectedReport ? false : true}
                  >
                    Export Data
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportingFinance;
