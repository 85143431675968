import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SET_TOAST,
  saveOrgSettingsAction,
  getOrgData,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADMIN_USERS_PAGE } from "../../routes/constants";
import { ReactComponent as OrgAdmin } from "../../assets/images/svg/OrgAdmin.svg";
import { styled, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SideMenu from "../../components/Common/SideMenu";
import ManageGrades from "../superAdmin/ManageGrades";
import ManageDivisions from "../superAdmin/ManageDivisions";
import ManageSprint from "../superAdmin/ManageSprint";
import ManagePositions from "../superAdmin/ManagePositions";
import ManageEmailAPIKeys from "../superAdmin/ManageEmailAPIKeys";

export default function OrgSettings() {
  const [selectedMenuOption, setSelectedMenuOption] = useState("ORG_ADMINS");
  const validationSchema = Yup.object({
    orgHeader: Yup.string().trim().required("Organization header is required"),
    volunteer_hourly_price: Yup.number()
      .min(0, "Volunteer hourly price must be greater than or equal to 0")
      .required("Volunteer hourly price is required"),
    foundation_hourly_price: Yup.number()
      .min(0, "Foundation credit price must be greater than or equal to 0")
      .required("Foundation credit price is required"),
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const orgdata = useSelector((state) => state.org?.org?.data);
  const [error, setErrors] = useState(false);

  const formik = useFormik({
    initialValues: {
      orgHeader: orgdata?.product_header_title ?? "",
      volunteer_hourly_price: orgdata?.volunteer_hourly_price ?? 0,
      foundation_hourly_price: orgdata?.foundation_hourly_price ?? 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParams = {
        product_header_title: values?.orgHeader ?? "",
        volunteer_hourly_price: values?.volunteer_hourly_price,
        foundation_hourly_price: values?.foundation_hourly_price,
      };
      const plainText = values?.orgHeader
        ?.replace(/<\/?[^>]+(>|$)/g, "")
        .trim();
      if (plainText) {
        saveOrgSetings(bodyParams);
        setErrors(false)
      } else {
        setErrors(true);

      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("orgHeader", orgdata?.product_header_title ?? "");
    formik.setFieldValue(
      "volunteer_hourly_price",
      orgdata?.volunteer_hourly_price ?? 0,
    );
    formik.setFieldValue(
      "foundation_hourly_price",
      orgdata?.foundation_hourly_price ?? 0,
    );
  }, [orgdata]);

  const contentBox = {
    width: "100%",
    height: "80px",
    // minHeight: 100,
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    borderRadius: "4px",
    borderColor: "transparent",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // cursor: "pointer"
  };
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const menItems = [
    {
      name: "Manage Organization Admins",
      type: "ORG_ADMINS",
    },
    {
      name: "Purchase Product Heading",
      type: "PURCHASE_PRODUCT_HEADER",
    },
    {
      name: "Sketchplay Bucks Settings",
      type: "SK_BUCKS",
    },
    {
      name: "Manage Grades",
      type: "GRADES",
    },
    {
      name: "Manage Divisions",
      type: "DIVISIONS",
    },
    {
      name: "Manage Sprints",
      type: "SPRINTS",
    },
    {
      name: "Manage Positions",
      type: "POSITIONS",
    },
    {
      name: "Manage Email API & Keys",
      type: "MANAGE_EMAIL_API_KEYS",
    },
  ];

  const saveOrgSetings = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await saveOrgSettingsAction(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch(getOrgData());
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: response?.message ?? "Organization updated successfully",
        },
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  const quillErrorStyle = {
    "@media (max-width: 1024px)": {
      "padding-top": "25px",
    },
    "@media (max-width: 1280px)": {
      "padding-top": "25px",
    },
  };

  const handleQuillChange = (content) => {
    formik.setFieldValue("orgHeader", content);
    setErrors(false)
  };

  const handleMenuOptClick = (optType) => {
    setSelectedMenuOption(optType);
  };

  return (
    <Box>
      <Typography variant="h3" component="div" mb={2} mt={0.5} ml={0.5}>
        Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <SideMenu
            listItems={menItems}
            selectedMenuOption={selectedMenuOption}
            handleMenuOptClick={handleMenuOptClick}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Card>
            <CardContent>
              {selectedMenuOption === "ORG_ADMINS" && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Grid item sm={12} xs={12} md={8} lg={8}>
                    <Box
                      sx={{
                        ...contentBox,
                        my: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(ADMIN_USERS_PAGE);
                      }}
                    >
                      <Box display="flex" alignItems="center" pt={2.5} pb={2}>
                        <OrgAdmin sx={{ fontSize: 45 }} />
                        <Typography
                          variant="span"
                          //fontWeight={700}
                          sx={{ fontSize: 16, ml: 2 }}
                          color={theme.palette.dark.dark3}
                        >
                          Manage Organization Admins
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {selectedMenuOption === "SK_BUCKS" ||
                selectedMenuOption === "PURCHASE_PRODUCT_HEADER" ? (
                <form onSubmit={formik.handleSubmit}>
                  {selectedMenuOption === "SK_BUCKS" ? (
                    <Grid
                      container
                      spacing={3}
                      mt={0}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputLabel sx={inputLabelStyle}>
                              Volunteer Hour is Equal to:
                            </CustomInputLabel>
                            <TextField
                              name="volunteer_hourly_price"
                              // variant="outlined"
                              type="number"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.volunteer_hourly_price}
                              sx={{
                                width: "100%",
                                ".MuiInputBase-input": {
                                  padding: "10.5px 14px !important",
                                  outline: "none",
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Enter volunteer hourly price..."
                              error={Boolean(
                                formik.touched.volunteer_hourly_price &&
                                formik.errors.volunteer_hourly_price,
                              )}
                            />
                            {Boolean(
                              formik.touched.volunteer_hourly_price &&
                              formik.errors.volunteer_hourly_price,
                            ) && (
                                <FormHelperText error>
                                  {formik.errors.volunteer_hourly_price}
                                </FormHelperText>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputLabel sx={inputLabelStyle}>
                              Foundation Credit is Equal to:
                            </CustomInputLabel>
                            <TextField
                              name="foundation_hourly_price"
                              // variant="outlined"
                              typr="number"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.foundation_hourly_price}
                              sx={{
                                width: "100%",
                                ".MuiInputBase-input": {
                                  padding: "10.5px 14px !important",
                                  outline: "none",
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Enter foundation credit price..."
                              error={Boolean(
                                formik.touched.foundation_hourly_price &&
                                formik.errors.foundation_hourly_price,
                              )}
                            />
                            {Boolean(
                              formik.touched.foundation_hourly_price &&
                              formik.errors.foundation_hourly_price,
                            ) && (
                                <FormHelperText error>
                                  {formik.errors.foundation_hourly_price}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={3}
                      mt={0}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Box>
                          <CustomInputLabel sx={inputLabelStyle}>
                            Purchase Product Header
                          </CustomInputLabel>
                          <ReactQuill
                            theme="snow"
                            name="orgHeader"
                            placeholder="Enter Organization Header..."
                            // onBlur={formik.handleBlur}
                            modules={OrgSettings.modules}
                            formats={OrgSettings.formats}
                            value={formik.values.orgHeader}
                            onChange={handleQuillChange}
                            error={Boolean(
                              formik.touched.orgHeader && formik.errors.orgHeader,
                            )}
                            style={{
                              width: "100%",
                              maxHeight: "35vh",
                              overflow: "auto",
                              ".MuiInputBase-input": {
                                padding: "10.5px 14px !important",
                                outline: "none",
                              },
                            }}
                          />
                          {error &&
                            <FormHelperText error>
                              This field is required
                            </FormHelperText>
                          }
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    spacing={3}
                    mt={0}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Grid item sm={12} xs={12} md={8} lg={8}>
                      <Box display="flex" alignItems="center">
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ paddingX: 3.5, paddingY: 1 }}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              ) : null}
              {selectedMenuOption === "GRADES" && <ManageGrades />}
              {selectedMenuOption === "DIVISIONS" && <ManageDivisions />}
              {selectedMenuOption === "SPRINTS" && <ManageSprint />}
              {selectedMenuOption === "POSITIONS" && <ManagePositions />}
              {selectedMenuOption === "MANAGE_EMAIL_API_KEYS" && (
                <ManageEmailAPIKeys />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
OrgSettings.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
OrgSettings.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];
