import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";

const DeleteConfirmPopup = ({ open, handleClose, title, message, handleConfirm }) => {
  const validationSchema = Yup.object({
    deleteMessage: Yup.string().trim().matches("delete", 'Confirm message should match the "delete" text'),
  });
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      deleteMessage: "",
    },
    validationSchema,
    onSubmit: () => {
      handleConfirm();
    },
  });

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ p: 0 }}>
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            py={3}
            ml={2}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {title}
          </Typography>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </DialogTitle>
        <DialogContent
          sx={{
            pt: 4,
            px: 4,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            pb: 6
          }}
        >
          <Typography
            sx={{ py: 3 }}
            fontFamily={"Poppins"}
            fontSize={16}
            fontWeight={600}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {message}
          </Typography>
          <CustomTextField
            name="deleteMessage"
            placeholder={'Type "delete" to confirm...'}
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                boxShadow: "none",
              },
            }}
            required
          />
          {Boolean(formik.touched.deleteMessage && formik.errors.deleteMessage) && (
            <FormHelperText sx={{ alignSelf: "flex-start", ml: 3 }} error>{formik.errors.deleteMessage}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions sx={{ pr: 4, pb: 3 }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            type="submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteConfirmPopup;
