import { Box, Typography, Grid } from "@mui/material";
import ComingSoon from "../../components/ComingSoon";
import { useSelector } from "react-redux";

const Communitys = () => {
  const state = useSelector((state) => state);

  return (
    <Grid container p={0} m={0} mt={1.5}>
      <Grid item xs={12}>
        <iframe
          src={`https://gridironsocial.sketchplay.io/sso.php?token=${
            state?.user?.token ?? ""
          }`}
          style={{ width: "100%", height: "85vh" }}
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default Communitys;
