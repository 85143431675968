import { Box, Button, Popover, Tooltip, useTheme } from "@mui/material";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_TOAST,
  deleteSubOrgTeam,
  getAllSubOrg,
  getSubOrgTeams,
} from "../../store/actions";
import ActionCard from "../../components/Common/ActionCard";
import AddEditSubOrgTeams from "../../components/SubOrgTeams/AddEditSubOrgTeams";
import SuccessPopup from "../../components/Common/SuccessPopup";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { charValidate } from "../../utils/functions";

const SubOrgTeams = () => {
  const tableRef = useRef();
  const [dataGridList, setDataGridList] = useState();
  const [subOrgsList, setSubOrgs] = useState([]);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [hasSubOrgMore, setHasSubOrgMore] = useState(true);
  const [subOrgOffset, setSubOrgOffset] = useState(0);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const state = useSelector((state) => state);
  const { subOrgDetails, subOrgTeamDetails, subOrgTeamManagerDetails } =
    state?.subOrgTeams;
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getSubOrgTeamsList("");
      getAllSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: subOrgOffset,
      limit: 20
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      const res = response?.data?.data ?? []
      setSubOrgs(prevOptions => [...prevOptions, ...res]);
      setSubOrgOffset(prevOffset => prevOffset + res.length);
      if (res.length === 0) {
        setHasSubOrgMore(false);
      }
    }
  };

  const handleSubOrgScroll = (e) => {
    const target = e.target;
    if ((target?.scrollHeight - target?.scrollTop) === target?.clientHeight && hasSubOrgMore) {
      getAllSubOrgList();
    }
  };

  const getSubOrgTeamsList = async (subOrgId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getSubOrgTeams(params, subOrgId);
    if (response?.ok) {
      setDataGridList(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getSubOrgTeamsList();
  };

  const columns = [
    {
      field: "title",
      headerName: "TEAM NAME",
      minWidth: 150,
      renderCell: (data) => (
        <Tooltip title={data?.row?.title ?? "N/A"}>
          {data?.row?.title ? charValidate(data?.row?.title, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "org_access_code",
      headerName: "ACCESS CODE",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title={data?.row?.org_access_code ?? "N/A"}>
          {data?.row?.org_access_code ? charValidate(data?.row?.org_access_code, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "primary_contact.first_name",
      headerName: "TEAM MANAGER FIRST NAME",
      flex: 1,
      minWidth: 130,
      renderCell: (data) => data?.row?.primary_contact?.first_name ? charValidate(data?.row?.primary_contact?.first_name, 15) : "N/A",
      renderCell: (data) => (
        <Tooltip title={data?.row?.primary_contact?.first_name ?? "N/A"}>
          {data?.row?.primary_contact?.first_name ? charValidate(data?.row?.primary_contact?.first_name, 15) : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "primary_contact.last_name",
      headerName: "TEAM MANAGER LAST NAME",
      minWidth: 130,
      renderCell: (data) => (
        <Tooltip title={data?.row?.primary_contact?.last_name ?? "N/A"}>
          {data?.row?.primary_contact?.last_name ? charValidate(data?.row?.primary_contact?.last_name, 15) : "N/A"}
        </Tooltip>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "primary_contact.email",
      headerName: "TEAM MANAGER EMAIL",
      renderCell: (data) => (
        <Tooltip title={data?.row?.primary_contact?.email ?? "N/A"}>
          {data?.row?.primary_contact?.email ? charValidate(data?.row?.primary_contact?.email, 15) : "N/A"}
        </Tooltip>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "primary_contact.mobile",
      headerName: "TEAM MANAGER CONTACT",
      renderCell: (data) => (
        <Tooltip title={data?.row?.primary_contact?.mobile ?? "N/A"}>
          {data?.row?.primary_contact?.mobile ? charValidate(data?.row?.primary_contact?.mobile, 15) : "N/A"}
        </Tooltip>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClick={() => setPopoverAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Sub-Org Details"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2 }}
                    onClick={handleEditClick}
                  >
                    Edit Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2, mt: 2 }}
                    onClick={() => {
                      window.open(data?.row?.org_website, "_blank");
                    }}
                  >
                    Go to Team Website
                  </Button>
                  <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25, mt: 3, mb: 1 }}
                  ></Box>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingY: 1.2,
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteClick}
                  >
                    Delete This Team
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = () => {
    setPopoverAnchorEl(null);
    setShowAddEditModal(true);
  };

  const handleDeleteClick = () => {
    setPopoverAnchorEl(null);
    setShowDeletePopup(true);
  };

  const handleModalClose = (isClosed) => {
    setShowAddEditModal(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
    } else {
      setShowSuccessPopup(true);
      getSubOrgTeamsList("");
    }
  };

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteSubOrgTeam(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "success",
          message: response?.message,
        },
      });
      setShowDeletePopup(false);
      getSubOrgTeamsList("");
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleSubOrgFilterChange = (e) => {
    let { value } = e?.target;
    if (value) {
      getSubOrgTeamsList(value === "all" ? "" : value);
    }
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Sub Organization Teams"}
        gridData={dataGridList}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={dataGridList ? false : true}
        isShowSubOrgFilter={true}
        onClickAddNew={() => { setShowAddEditModal(true); setSelectedDataGridRow("") }}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
        subOrgsList={subOrgsList}
        handleSubOrgScroll={handleSubOrgScroll}
        handleSubOrgFilterChange={handleSubOrgFilterChange}
      />
      {showAddEditModal && (
        <AddEditSubOrgTeams
          open={showAddEditModal}
          handleClose={handleModalClose}
          data={selectedDataGridRow}
        />
      )}
      {showSuccessPopup && (
        <SuccessPopup
          title={"Sub-Organization Team"}
          message={"Success! Your actions have been successfully implemented."}
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
        />
      )}
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Sub-Organization Team"}
          message={
            "Are you sure you want to delete this Sub-organization team?"
          }
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedDataGridRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Sub-Organization Team"}
          message={"Are you sure, want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setShowAddEditModal(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
            setSelectedDataGridRow(undefined);
            dispatch({
              type: "RESET_ORG_TEAMS_INFO",
              data: "reset",
            });
          }}
        />
      )}
    </>
  );
};

export default SubOrgTeams;
