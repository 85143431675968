import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  approveScholarshipRequestAction,
} from "../../store/actions";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessPopup from "../../components/Common/SuccessPopup";
import CustomTextField from "../../components/CustomTextField";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";

const validationSchema = Yup.object({
  reason: Yup.string().trim().required("Reason for deny is required"),
});

const ScholarshipDenyPopup = ({ open, handleClose, requestData }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const requiredStarStyle = { color: "red" }

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParams = {
        id: requestData?.id,
        status: "denied",
        reason: values?.reason ?? "",
      }
      denyRequest(bodyParams);
    },
  })


  const denyRequest = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let response = await approveScholarshipRequestAction(dispatch, params, bodyParams);

    try {
      if (response?.ok) {
        setShowSuccessPopup(true);
      } else {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response.message ?? "Something went wrong. Please try again!" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error.message ?? "Something went wrong. Please try again!" }
      })
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => handleClose(true)}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            pt={1}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Scholarship Funds
            </Typography>
          </Box>
        </DialogTitle>
        <Divider></Divider>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize={14}
            sx={{ my: 3, textAlign: "center", maxWidth: "530px" }}
            color={theme?.palette?.text?.containerTextLabel}
          >
            You must provide a reason for denying this request.
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: 4.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomInputLabel htmlFor="reason">
                  REASON <span style={requiredStarStyle}>*</span>
                </CustomInputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.reason && formik.errors.reason,
                  )}
                >
                  <CustomTextField
                    multiline
                    id="reason"
                    name="reason"
                    placeholder="Enter reason..."
                    variant="outlined"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.reason}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                        boxShadow: "none",
                      },
                    }}
                    rows={6}
                    cols={6}
                  />
                  {Boolean(
                    formik.touched.reason && formik.errors.reason,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.reason}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              p: 3.5,
              px: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Button
              size="small"
              variant="contained"
              sx={{ width: "130px", height: "43px" }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: "130px", height: "43px", }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {showSuccessPopup &&
        <SuccessPopup
          open={showSuccessPopup}
          handleClose={() => {
            setShowSuccessPopup(false);
            handleClose(false);
          }}
          secondaryTitle="You have successfully denied this request. Your reason has been shared with the original submitter."
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to hide this popup"
          alignCenterSecondaryTitle={true}
        />
      }
    </>
  );
};

export default ScholarshipDenyPopup;
