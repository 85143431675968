import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import Logo from "../../assets/images/IYS.png";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { useState } from 'react';
import PhoneInput from "react-phone-input-2";
import PasswordScreen from "../IYS/PasswordScreen";
import OtpTypeScreen from "../IYS/OtpTypeScreen";
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  getAllProduct,
  getAllProductPackages,
} from "../../store/actions";
import httpClient from "@utils/httpClient";
import { firebaseAuth } from "@utils/firebase";
import { randomNum } from "@utils/functions";
import OtpScreen from "../IYS/OtpScreen";
import EditDetailScreen from "../IYS/EditDetailsScreen";
import { convertToCapsOfFirstLetter } from "@utils/functions";
import {
  charValidate,
  dateFormatForAPI,
  formatCurrency,
} from "../../utils/functions";
import gridironGoldLogo from "../../assets/images/gg-gold-hero.png";
import StripePayment from "../../components/Tournament/StripePayment";
import ProductStripePayment from "./ProductStripePayment";
import SuccessPopup from "../../components/Common/SuccessPopup";
import ChildSchoolInfo from "../../components/AppForms/ChildSchoolInfo";
import dayjs from "dayjs";
import moment from "moment";

const validationSchema = Yup.object({
  first_name: Yup.string().trim().required("Athlete First Name is required"),
  last_name: Yup.string().trim().required("Athlete Last Name is required"),
  email: Yup.string()
    .trim()
    .required("Athlete Email is required")
    .email("Must be a valid email"),
  mobile: Yup.string().required("Athlete mobile is required"),
  parent_first_name: Yup.string().required("Parent first name is required"),
  parent_last_name: Yup.string().required("Parent last name is required"),
  parent_email: Yup.string()
    .required("Parent email is required")
    .email("Must be a valid email"),
  parent_mobile: Yup.string().required("Parent mobile is required"),
  parent_countryCode: Yup.string().required("Country Code is required"),
  countryCode: Yup.string().required("Country Code is required"),
  school: Yup.string().trim().required("School Name is required"),
  grade: Yup.string().trim().required("Grade is required"),
  // position: Yup.array().min(1, "Position is required"),
  dob: Yup.date().required("Data of Birth is required"),
  // teamId: Yup.string().required("Team selection is required"),
  // user_type: Yup.string().trim().when('isActive', {
  //     is: true,
  //     then: Yup.string().trim().required('Please select your type'),
  //     otherwise: Yup.string().trim(),
  // }),
});

const ProductBuy = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  let url = new URL(window?.location?.href);
  let params = new URLSearchParams(url.search);
  let productIdQueryParam = params ? params.getAll("productId") : [];
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { org } = useSelector((state) => state.org);
  const dispatch = useDispatch();
  const [randomId, setRandomId] = useState(null);
  const [formData, setFormData] = useState({
    device_id: "abc123",
    device_token: "abcxyz123",
    device_type: "web",
    // sub_organization: "1"
    sub_organization: 1,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState("");
  const [step, setStep] = useState(0);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [productList, setProductList] = useState([]);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [stripeTotalAmount, setStripeTotalAmount] = useState(0);
  const [selectedPack, setSelectedPack] = useState("");
  const [userToken, setUserToken] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      parent_first_name: "",
      parent_last_name: "",
      parent_email: "",
      parent_mobile: "",
      sub_organization: 1,
      parent_countryCode: "",
      countryCode: "",
      school: "",
      grade: "",
      // position: "",
      dob: "",
      // teamId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!selectedPack) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: "error", message: "Choose package" },
        });
        return;
      }
      if (!selectedPosition?.length) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: "error", message: "Choose position" },
        });
        return;
      }
      setShowError("");
      dispatch({ type: SET_LOADER, data: true });
      httpClient.defaults.headers.common["Authorization"] = "";
      const res = await httpClient.post("/auth/checkEmail", {
        email: values?.parent_email,
        mobile: values?.parent_mobile,
      });
      dispatch({ type: SET_LOADER, data: false });
      if (!res || !res.ok) {
        setShowError(res?.message ?? "Email or Mobile number already exists");
        // dispatch({
        //   type: SET_SUCCESS_ERROR_POPUP,
        //   data: { open: true, type: "error", message: res?.message },
        // });
        return false;
      } else {
        // dispatch({ type: SET_LOADER, data: true });
        // const athleteRes = await httpClient.post("/auth/checkEmail", {
        //   email: values?.email,
        //   mobile: values?.mobile,
        // });
        // dispatch({ type: SET_LOADER, data: false });
        // if (!athleteRes || !athleteRes.ok) {
        //   dispatch({
        //     type: SET_SUCCESS_ERROR_POPUP,
        //     data: { open: true, type: "error", message: athleteRes?.message },
        //   });
        //   return false;
        // } else {
        const formattedMobile = "1" + values.parent_mobile;
        const countryCode = values.countryCode.toUpperCase();
        const parent_countryCode = values.parent_countryCode.toUpperCase();
        setFormData((prevStage) => {
          return {
            ...prevStage,
            ...values,
            dob: dateFormatForAPI(values?.dob),
            position: selectedPosition ?? [],
            countryCode,
            parent_countryCode,
            formattedMobile,
          };
        });
        setShowError("");
        if (res?.isRegistered) {
          setUserToken(res?.token ?? "");
          setStep(5);
        } else {
          setStep(step + 1);
        }
        //}
      }
      // let bodyParam = {
      //     // id: adminData?.id ?? undefined,
      //     first_name: values?.first_name ?? "",
      //     last_name: values?.last_name ?? "",
      //     email: values?.email ?? "",
      //     countryCode: values?.countryCode ?? "",
      //     mobile: values?.mobile ?? "",
      //     "permission": {
      //         "eventRegister": true,
      //         "socialAccount": true
      //     }
      //     }
      //     addAdmin(bodyParam)
      // },
    },
  });
  const selectedPackVal = formik.values.selectedPack;
  // const addAdmin = async (bodyParams) => {
  //     let params = {
  //         // userToken: state?.user?.token,
  //         // orgToken: state?.org?.org?.token,
  //     };
  //     let response = await addAdminUser(dispatch, params, bodyParams);
  //     if (response?.ok) {
  //         // handleClose(false);
  //     } else {
  //         dispatch({
  //             type: SET_TOAST,
  //             data: {
  //                 type: 'error',
  //                 message: response.message ?? "Something went wrong",
  //             },
  //         })
  //     }
  // };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const getRandomNum = () => {
    const _randomId = randomNum();
    setRandomId(_randomId);
  };

  const requestOtp = async (verify_type) => {
    return new Promise(async (resolve) => {
      try {
        if (verify_type === "email") {
          dispatch({ type: SET_LOADER, data: true });
          httpClient.defaults.headers.common["Authorization"] = "";
          const res = await httpClient.post("/auth/sendEmailOtp", {
            first_name: formData.parent_first_name,
            last_name: formData.parent_last_name,
            email: formData.parent_email,
          });
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: true,
            data: res,
          });
        } else if (verify_type === "sms") {
          dispatch({ type: SET_LOADER, data: true });
          let _recaptchaVerifier = null;
          if (recaptchaVerifier) {
            _recaptchaVerifier = recaptchaVerifier;
          } else {
            _recaptchaVerifier = new RecaptchaVerifier(
              `mobile-signin-btn-${randomId}`,
              {
                size: "invisible",
              },
              firebaseAuth,
            );
            setRecaptchaVerifier(_recaptchaVerifier);
          }
          setTimeout(getRandomNum, 300);
          signInWithPhoneNumber(
            firebaseAuth,
            `+${formData.formattedMobile}`,
            _recaptchaVerifier,
          )
            .then((_confirmationResult) => {
              dispatch({ type: SET_LOADER, data: false });
              if (_confirmationResult.verificationId) {
                setConfirmationResult(_confirmationResult);
                resolve({
                  ok: true,
                });
              } else {
                resolve({
                  ok: false,
                  message: `Something went wrong. Our server is not able to send OTP SMS.`,
                });
              }
            })
            .catch((error) => {
              dispatch({ type: SET_LOADER, data: false });
              resolve({
                ok: false,
                message: `Provided Mobile Number is invalid. Please provide Mobile Number along with country code.`,
              });
            });
        } else {
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: false,
            message: "Invalid Verification Method.",
          });
        }
      } catch (err) {
        dispatch({ type: SET_LOADER, data: false });
        resolve({
          ok: true,
        });
      }
    });
  };
  //     } catch (err) {
  //       dispatch({ type: SET_LOADER, data: false });
  //       resolve({
  //         ok: false,
  //         message: "Something went wrong! Please try to refresh.",
  //       });
  //     }
  //   });
  // };

  const verifySmsOtp = (otpCode) => {
    return new Promise((resolve) => {
      dispatch({ type: SET_LOADER, data: true });
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: true,
            data: result,
          });
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: false,
            message: `Invalid Code. Please retry.`,
          });
        });
    });
  };

  useEffect(() => {
    getRandomNum();
    getDataList();
  }, []);

  const getDataList = async () => {
    if (productIdQueryParam) {
      let params = {
        productId: productIdQueryParam ? productIdQueryParam[0] : "",
      };
      let response = await getAllProduct(params, dispatch);
      if (response?.ok) {
        let dataObj = response?.data ?? [];
        let dataList = [];
        for (const key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            const data = dataObj[key];
            dataList.push({
              categoryName: key,
              data: data,
            });
          }
        }
        setProductList(dataList ?? []);
      } else {
        dispatch({
          type: SET_TOAST,
          data: {
            type: "error",
            message: response.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (step === 5) {
      individualStripePaymentIntent();
    }
  }, [step]);

  const individualStripePaymentIntent = async () => {
    if (productList?.length && selectedPack) {
      let values = {
        payment: [
          {
            // product_id: productList[0]?.data[0]?.id,
            // pack_id: selectedPack?.id,
            // total_amount: selectedPack?.amount,
            // discount_code_amount: 0,
            // original_amount: selectedPack?.amount,
            product_id: productList[0]?.data[0]?.id,
            pack_id: selectedPack?.id,
            total_amount: selectedPack?.amount,
          },
        ],
        total_amount: selectedPack?.amount,
        sketchplay_bucks: 0,
        scholarship_amount: 0,
        discount_code_amount: 0,
        original_amount: selectedPack?.amount,
        sketchplay_bucks: 0,
      };

      const API_URL = "/packPaymentIntent";

      dispatch({ type: SET_LOADER, data: true });

      httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${org.token}`;
      httpClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userToken}`;

      await httpClient
        .post(API_URL, values)
        .then((apiRes) => {
          dispatch({ type: SET_LOADER, data: false });
          if (apiRes && apiRes.ok) {
            values.clientSecret = apiRes.data?.clientSecret;
            values.transaction_id = apiRes.data?.transaction_id;
            values.publishableKey = apiRes.data?.publishableKey;
            values.amount = apiRes.data?.amount;
            values.original_amount = apiRes?.original_amount;
            setPaymentFormData(values);
          } else {
            dispatch({
              type: SET_SUCCESS_ERROR_POPUP,
              data: {
                open: true,
                type: "error",
                message: apiRes?.message ?? "Something went wrong, try again",
              },
            });
          }
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, data: false });
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: error?.message ?? "Something went wrong, try again",
            },
          });
        });
    }
  };

  const completeStripePaymentProcess = async () => {
    const API_URL = "/packPayment";

    if (productList?.length && selectedPack) {
      let values = {
        payment: [
          {
            product_id: productList[0]?.data[0]?.id,
            pack_id: selectedPack?.id,
            total_amount: selectedPack?.amount,
          },
        ],
        transaction_id: paymentFormData.transaction_id,
        total_amount: paymentFormData?.amount,
        discount: "",
        discount_value: 0,
        apply_scholarship: false,

        scholarship_amount: 0,
        discount_code_amount: 0,
        original_amount: selectedPack?.amount,
        sketchplay_bucks: 0,
      };

      dispatch({ type: SET_LOADER, data: true });
      httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${org.token}`;
      httpClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userToken}`;

      await httpClient
        .post(API_URL, values)
        .then((apiRes) => {
          dispatch({ type: SET_LOADER, data: false });
          if (apiRes && apiRes.ok) {
            setShowSuccessModal(true);
          } else {
            dispatch({
              type: SET_SUCCESS_ERROR_POPUP,
              data: {
                open: true,
                type: "error",
                message: apiRes?.message ?? "Something went wrong, try again",
              },
            });
          }
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, data: false });
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: error?.message ?? "Something went wrong, try again",
            },
          });
        });
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  return (
    <>
      {step === 0 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ padding: "5rem 0" }}
        >
          <Grid item xs={12}>
            <img
              src={gridironGoldLogo}
              style={{ width: "15rem", height: "5rem" }}
              alt="IYS logo"
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1, mt: 2, width: isMobile ? 0.8 : 0.5 }}>
            <Typography variant="h3" sx={{ textAlign: "center" }}>
              Please select the number of packs you’d like to purchase and fill
              out the information below. You will be able to complete your
              purchase on the next screen.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1.5,
                textAlign: "center",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              If you don’t already have an account with the Gridiron App, we’ll
              create one for you and send you a temp. password to login to the
              email you provided.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center", mt: 1.5 }}>
              Note: Email and Phone can be the same as parents for athletes who
              don’t have one.
            </Typography>
            {productList && productList?.length ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={1.5}
                sx={{
                  border: `1.7px solid gold`,
                  width: 1,
                  minHeight: "4rem",
                  p: 2,
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "#000", fontWeight: "bold" }}
                >
                  {productList[0]?.data[0].name}
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {productList[0]?.data[0].desc}
                </Typography>
              </Box>
            ) : null}
            {/* <Typography variant="body1" sx={{ ml: 8 }}><strong>Number of Weeks:</strong> 10 Weeks</Typography> */}
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
                {productList?.length
                  ? productList?.map((packageCate) => (
                      <Box key={packageCate?.id} width={1}>
                        {packageCate?.data?.map((packageInfo) =>
                          packageInfo?.is_activated ? (
                            <Box
                              width={1}
                              key={packageInfo?.id}
                              flexWrap={"wrap"}
                            >
                              <Box>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                  <TextField
                                    select
                                    name="selectedPack"
                                    label="Choose Package"
                                    variant="outlined"
                                    value={selectedPack}
                                    onChange={(e) => {
                                      let value = e?.target?.value;
                                      setSelectedPack(value);
                                    }}
                                    sx={{
                                      mt: 1,
                                      opacity: 1,
                                      zIndex: 1,
                                      width: "100%",
                                      "& .MuiSelect-select": {
                                        height: "50px",
                                        paddingTop: "0",
                                        paddingBottom: "0",
                                        lineHeight: "50px",
                                        boxShadow:
                                          "4px 5px 11px rgba(162, 188, 244, 0.4)",
                                      },
                                      "& .MuiInputLabel-root": {
                                        fontSize: "14px",
                                      },
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#dfe0df",
                                      },
                                    }}
                                  >
                                    {packageInfo?.packs?.map((pack) =>
                                      pack?.is_active ? (
                                        <MenuItem key={pack?.id} value={pack}>
                                          {pack?.title
                                            ? charValidate(pack?.title, 20)
                                            : "N/A"}{" "}
                                          ({formatCurrency(pack?.amount)})
                                        </MenuItem>
                                      ) : null,
                                    )}
                                  </TextField>
                                </FormControl>
                              </Box>
                            </Box>
                          ) : null,
                        )}
                      </Box>
                    ))
                  : null}
                <ChildSchoolInfo
                  formik={formik}
                  setSelectedPosition={setSelectedPosition}
                  selectedPosition={selectedPosition}
                />
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="first_name"
                    name="first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "first_name")
                    }
                    value={formik.values.first_name}
                    placeholder="Athlete's First Name *"
                    error={Boolean(
                      formik.touched.first_name && formik.errors.first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.first_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="last_name"
                    name="last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "last_name")
                    }
                    value={formik.values.last_name}
                    placeholder="Athlete's Last Name *"
                    error={Boolean(
                      formik.touched.last_name && formik.errors.last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.last_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email.toLowerCase()}
                    placeholder="Athlete's Email *"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                  />
                  {Boolean(formik.touched.email && formik.errors.email) && (
                    <FormHelperText error>{formik.errors.email}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "& .react-tel-input .form-control": {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent",
                      },
                    }}
                  >
                    <PhoneInput
                      country={"us"}
                      id="mobile"
                      name="mobile"
                      variant="outlined"
                      onlyCountries={["us"]}
                      disableCountryCode={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue(
                          "countryCode",
                          country.countryCode || "",
                        );
                        formik.setFieldValue("mobile", value || "");
                      }}
                      error={Boolean(
                        formik.touched.mobile && formik.errors.mobile,
                      )}
                    />
                  </Box>
                  {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                    <FormHelperText error>
                      {formik.errors.mobile}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_first_name &&
                      formik.errors.parent_first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_first_name"
                    name="parent_first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "parent_first_name")
                    }
                    value={formik.values.parent_first_name}
                    placeholder="Parent's First Name *"
                    error={Boolean(
                      formik.touched.parent_first_name &&
                        formik.errors.parent_first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_first_name &&
                      formik.errors.parent_first_name,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.parent_first_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_last_name &&
                      formik.errors.parent_last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_last_name"
                    name="parent_last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "parent_last_name")
                    }
                    value={formik.values.parent_last_name}
                    placeholder="Parent's Last Name *"
                    error={Boolean(
                      formik.touched.parent_last_name &&
                        formik.errors.parent_last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_last_name &&
                      formik.errors.parent_last_name,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.parent_last_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_email"
                    name="parent_email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.parent_email}
                    placeholder="Parent's Email *"
                    error={Boolean(
                      formik.touched.parent_email && formik.errors.parent_email,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.parent_email}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "& .react-tel-input .form-control": {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent",
                      },
                    }}
                  >
                    <PhoneInput
                      country={"us"}
                      id="parent_mobile"
                      name="parent_mobile"
                      variant="outlined"
                      onlyCountries={["us"]}
                      disableCountryCode={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.parent_mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue(
                          "parent_countryCode",
                          country.countryCode || "",
                        );
                        formik.setFieldValue("parent_mobile", value || "");
                      }}
                      error={Boolean(
                        formik.touched.parent_mobile &&
                          formik.errors.parent_mobile,
                      )}
                    />
                  </Box>
                  {Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.parent_mobile}
                    </FormHelperText>
                  )}
                </FormControl>
                <Typography
                  variant="body2"
                  sx={{ color: "red", textAlign: "center" }}
                >
                  {showError}
                </Typography>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  sx={{ mt: 2 }}
                  label={
                    <Typography>
                      By checking this box, you agree to digital sign the <a href={org?.data?.waiver_url} target="_blank">
                        <u>Gridiron waiver</u>
                      </a> and agree to the Sketchplay terms and services.{" "}
                      <a
                        href={org?.data?.terms_url}
                        target="_blank"
                      >
                        <u>terms and services</u>
                      </a>
                    </Typography>
                  }
                /> */}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mb: 3, mt: 4 }}
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    (!formik.validateOnMount && !formik.dirty)
                  }
                  type="submit"
                >
                  Next
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            {/* <Grid item xs={12}>
              <img src={Logo} alt="IYS logo" />
            </Grid> */}
            <PasswordScreen
              step={step}
              setStep={setStep}
              formData={formData}
              setFormData={setFormData}
            />
          </Grid>
        </>
      )}
      {step === 2 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          {/* <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid> */}
          <OtpTypeScreen
            step={step}
            setStep={setStep}
            setFormData={setFormData}
            requestOtp={requestOtp}
          />
        </Grid>
      )}
      {step === 3 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          {/* <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid> */}
          <OtpScreen
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            requestOtp={requestOtp}
            verifySmsOtp={verifySmsOtp}
            isFromPackageBuy={true}
            setUserToken={(token) => {
              setUserToken(token);
            }}
          />
        </Grid>
      )}
      {step === 4 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          {/* <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid> */}
          <EditDetailScreen
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            isFromPackageBuy={true}
          />
        </Grid>
      )}
      {step === 5 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Box
            sx={{
              background: "#EFF7FF",
              border: `1px solid #7FBEFD`,
              color: "black",
              width: "355px",
              borderRadius: "7px",
              fontSize: "14px",
              fontWeight: 500,
              mb: 4,
              mt: 1,
            }}
            p={2}
          >
            <span style={{ color: "red" }}>*&nbsp;&nbsp;</span>Please be patient
            upon submission. This process could take a several moments to
            complete.
          </Box>
          <Grid item xs={12}>
            <ProductStripePayment
              handleClose={() => {
                setStep(0);
                // setDiscountDetails({
                //   couponCode,
                //   couponCodeAmount,
                //   scholarshipDiscountValue,
                //   totalAmount,
                //   isApplyScholarshipDiscount,
                //   isDiscountPayment,
                //   isFullDiscount,
                //   isCouponApplied,
                //   originalAmountValue,
                // });
                // handleClose(true);
              }}
              afterPayemntMethod={completeStripePaymentProcess}
              formData={paymentFormData}
              type="package"
              payAmount={selectedPack ? selectedPack?.amount : 0}
            />
          </Grid>
          {/* <Grid item xs={12} >
            <img src={Logo} alt="IYS logo" />
          </Grid> */}
          {/* <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h3">Thanks! just one more step.</Typography>
            <Typography variant="h5" sx={{ my: 2, fontSize: '16px', fontFamily: '"Poppins",sans-serif,Arial', maxWidth: "600px" }}>To qualify for financial assistance please fill out the following form if Only fill this out if Your child is currently enrolled in Medicaid, SNAP or WIC.</Typography>
            <Button variant="contained" sx={{ mt: 2, width: "50%" }} onClick={redirectToFacebook}>Fill out form</Button>
            <Typography variant="h5" sx={{ mt: 5, fontSize: '16px', fontFamily: '"Poppins",regural' }} >Thank You!</Typography>
          </Grid> */}
        </Grid>
      )}
      <div id={`mobile-signin-btn-${randomId}`} style={{ display: "none" }} />
      {showSuccessModal && (
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to see next view"
        />
      )}
    </>
  );
};

export default ProductBuy;
