import {
  Box,
  Typography,
  useTheme,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useCallback } from "react";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDaysToCurrentDate,
  getFileNameFromFirebaseUrl,
} from "../../../../utils/functions";
import {
  SET_TOAST,
  getGrades,
  uploadFileAction,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef } from "react";
import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import { LIMIT_VALIDATION } from "../../../../utils/constants";
import { CALENDAR_PAGE } from "../../../../routes/constants";
import { useNavigate } from "react-router";
import AddAddress from "./AddAddress";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { UploadFileRounded } from "@mui/icons-material";
import { useEffect } from "react";
import CustomSwitch from "../../../../components/Common/Inputs/Switch";
import CheckIcon from "@mui/icons-material/Check";

export default function CreateCampStep3({
  onNext,
  onBack,
  step,
  selectedEvent,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const selectionInputStyle = {
    width: "100%",
    minWidth: "300px",
    padding: "12px 14px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const step3Details = state?.createCampEvent?.step3Details;
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    cost: Yup.string()
      .trim()
      // .matches(/^\$?[0-9,]+$/, 'Cost must be a non-negative number')
      // .test('is-non-negative', 'Cost must be non-negative', (value) => {
      //   if (!value) return true;
      //   const numericValue = parseFloat(value.replace(/[$,]/g, ''));
      //   return !isNaN(numericValue) && numericValue >= 0;
      // })
      .test("max-value", "Cost cannot exceed $10,000", (value) => {
        if (!value) return true;
        const numericValue = parseFloat(value.replace(/[$,]/g, ""));
        return !isNaN(numericValue) && numericValue <= 10000;
      }),
    // .required('Cost is required'),
    attendees: Yup.number()
      .min(0, "Attendees must be greater than or equal to 0")
      .integer("Attendees should not be in decimal"),
  });
  const [imageHandleChange, setImageHandleChange] = useState(false);
  const [gradeArr, setGradeArr] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [isOpenToAll, setIsOpenToAll] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [eventFlyerImg, setEventFlyerImg] = useState(null);
  const [eventFlyerImgFileError, setEventFlyerImgFileError] = useState(false);
  const [imageFileName, setImageFileName] = useState("");
  const [imageFileURL, setImageFileURL] = useState("");

  const formik = useFormik({
    initialValues: {
      cost: step3Details?.cost,
      attendees: step3Details?.attendees ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedGrades?.length) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: "Grade selection is required",
          },
        });
        return;
      }
      // if (!imageFileURL) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: {
      //       type: "error",
      //       message: "Select & upload event flyer image",
      //     },
      //   });
      //   return;
      // }
      let data = {
        ...values,
        color: selectedColor,
        imageFileURL,
        selectedGrades,
        imageFileName,
        isOpenToAll,
        eventFlyerImg,
        cost: values?.cost ? values?.cost : 0,
      };
      dispatch({
        type: "STEP_3_DETAILS",
        data,
      });
      onNext();
    },
  });

  const numericInputStyle = {
    padding: "1px",
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.grey?.[400]}`,
    width: "100%",
    boxShadow: "none",
    outline: "none",
    height: "44px",
    background: "#fafafa",
    padding: "10px 14px",
  };

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const colors = [
    "#000000",
    "#4594FF",
    "#44C434",
    "#F739FF",
    "#AF52FF",
    "#FF0000",
    "#FFE62C",
    "#FF9300",
    "#3948FF",
    "#C0A771",
  ];

  useEffect(() => {
    getGradeList();
  }, []);

  useEffect(() => {
    if (gradeArr && selectedGrades) {
      if (gradeArr?.length === selectedGrades?.length) {
        setIsOpenToAll(true);
      } else {
        setIsOpenToAll(false);
      }
    }
  }, [gradeArr, selectedGrades]);

  useEffect(() => {
    if (step3Details) {
      setIsOpenToAll(step3Details?.isOpenToAll ?? false);
      setSelectedGrades(step3Details?.selectedGrades ?? []);
      setImageFileName(step3Details?.imageFileName ?? "");
      setImageFileURL(step3Details?.imageFileURL ?? "");
      setSelectedColor(step3Details?.color ?? "#000000");
      setEventFlyerImg(step3Details?.eventFlyerImg ?? null);
    }
  }, [step3Details]);

  const getGradeList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getGrades(params, dispatch);
    if (response?.ok) {
      setGradeArr(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const handleChangeOpenToAll = (event) => {
    const isChecked = event.target.checked;
    setIsOpenToAll(isChecked);
    if (isChecked) {
      let gradeIds = [];
      gradeArr?.forEach((grade) => gradeIds?.push(grade?.id));
      setSelectedGrades(gradeIds);
    } else {
      setSelectedGrades([]);
    }
  };

  const handleChangeGrade = (gradeId) => {
    if (selectedGrades?.includes(gradeId)) {
      let gradeIds = selectedGrades?.filter((id) => id !== gradeId);
      setSelectedGrades(gradeIds);
    } else {
      setSelectedGrades([...selectedGrades, gradeId]);
    }
  };

  const validateUploadImage = (event) => {
    if (event.target.files.length > 0) {
      const MAX_FILE_SIZE = 1024 * 5; // 5MB
      const imageFile = event.target.files[0];
      const fileSizeKiloBytes = imageFile.size / 1024;
      setImageFileURL("");
      setImageFileName(imageFile.name);
      setEventFlyerImg(imageFile);
      setEventFlyerImgFileError(!!(fileSizeKiloBytes > MAX_FILE_SIZE));
    } else {
      //setEventFlyerImg(null);
      setEventFlyerImgFileError(false);
    }
  };

  const uploadImage = async () => {
    if (eventFlyerImg) {
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      let formData = new FormData();
      formData.append("files", eventFlyerImg);
      formData.append("type", "EventFlyer");
      formData.append("fileType", "EventImage");
      formData.append("userId", state?.user?.user?.id);
      let response = await uploadFileAction(dispatch, params, formData);

      if (response?.ok) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response.message ?? "Success",
          },
        });
        setImageFileURL(response?.url);
        setEventFlyerImg(response?.url);
        setImageFileName(response?.url);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  const deleteSelectedImageFile = () => {
    setImageFileName("");
    setEventFlyerImg(null);
    setEventFlyerImgFileError(false);
    setImageFileURL("");
  };

  return (
    <Box p={3} mt={1}>
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
        }}
      >
        Enter your event details below. You will be guided through a few steps
        to create your event. At anytime, you can select the step you’d like to
        go back to without losing any information.
      </Typography>
      <Box mt={3}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5.5}>
              <Box>
                <CustomInputLabel sx={inputLabelStyle}>COST</CustomInputLabel>
                <NumericFormat
                  name="cost"
                  placeholder="$0 by default if left blank..."
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.cost}
                  style={numericInputStyle}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  allowNegative={false}
                />
                {Boolean(formik.touched.cost && formik.errors.cost) && (
                  <FormHelperText error>{formik.errors.cost}</FormHelperText>
                )}
              </Box>
              <Box mt={2}>
                <CustomInputLabel sx={inputLabelStyle}>
                  MAX ATTENDEES
                </CustomInputLabel>
                <CustomTextField
                  type="number"
                  name="attendees"
                  placeholder="Unlimited if left blank..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                    width: "100%",
                  }}
                />
                {Boolean(
                  formik.touched.attendees && formik.errors.attendees,
                ) && (
                  <FormHelperText error>
                    {formik.errors.attendees}
                  </FormHelperText>
                )}
              </Box>
              <Box mt={2}>
                <CustomInputLabel sx={inputLabelStyle}>
                  ASSIGN EVENT COLOR
                </CustomInputLabel>
                <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                  {colors?.map((color) => (
                    <Box
                      key={color}
                      sx={{
                        background: color,
                        width: 35,
                        height: 35,
                        borderRadius: "100%",
                        mr: 1,
                        mb: 1,
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setSelectedColor(color);
                      }}
                    >
                      {selectedColor === color ? (
                        <CheckIcon sx={{ color: "white" }} fontSize="medium" />
                      ) : null}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box mt={2}>
                <CustomInputLabel sx={inputLabelStyle}>
                  EVENT FLYER/IMAGE
                </CustomInputLabel>
                <FormControl fullWidth sx={{ maxWidth: "100%", width: "100%" }}>
                  <InputLabel
                    htmlFor="eventFlyerDoc"
                    sx={{ fontSize: "14px" }}
                    shrink={false}
                  >
                    {imageFileName
                      ? getFileNameFromFirebaseUrl(imageFileName) ||
                        (imageHandleChange ? imageFileName : "Upload image...")
                      : "Upload image..."}
                  </InputLabel>
                  <OutlinedInput
                    name={"eventFlyerDoc"}
                    id="eventFlyerDoc"
                    type={"file"}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="eventFlyerDoc"
                          edge="end"
                          size="large"
                          htmlFor="eventFlyerDoc"
                          component="label"
                          sx={{
                            backgroundColor: theme?.palette?.secondary?.dark,
                            borderRadius: "0 3px 3px 0",
                            width: "80px",
                            left: "3px",
                            // ml: 'auto',
                            // mr: '-14px',
                            p: "10px",
                          }}
                        >
                          <UploadFileRounded
                            sx={{ color: "white", fontSize: "25px" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "0",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "12.5px 14px",
                      },
                      "& ::file-selector-button": {
                        opacity: 0,
                        display: "none",
                      },
                      "& .MuiInputAdornment-root": {
                        width: "auto",
                        marginLeft: "0",
                      },
                    }}
                    inputProps={{
                      style: {
                        opacity: 0,
                      },
                      accept: "image/*",
                      onChange: (event) => {
                        validateUploadImage(event);
                        //setImageFileURL("");
                        setImageHandleChange(true);
                      },
                    }}
                  />
                </FormControl>
                {eventFlyerImg && !eventFlyerImgFileError ? (
                  <Box display={"flex"} alignItems={"center"} mt={1.5}>
                    {!imageFileURL && imageHandleChange && (
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "15px",
                          fontWeight: 600,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={uploadImage}
                      >
                        Upload
                      </Typography>
                    )}
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "red",
                        ml: 2,
                      }}
                      onClick={deleteSelectedImageFile}
                    >
                      Delete
                    </Typography>
                  </Box>
                ) : null}
                {Boolean(
                  (formik.touched.eventFlyerDoc &&
                    formik.errors.eventFlyerDoc) ||
                    eventFlyerImgFileError,
                ) && (
                  <FormHelperText error>
                    {formik.errors.eventFlyerDoc ||
                      "File size is greater than maximum limit of 5MB, select file lower than the limit to upload"}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={1}
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              <Box
                sx={{
                  width: "1px",
                  height: "100%",
                  background: "grey",
                  opacity: 0.4,
                  m: "auto",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5.5}>
              <Box>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  fontSize={17}
                  color={theme?.palette?.text?.containerTextLabel}
                >
                  Grade Permissions
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  fontSize={11}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{ mt: 1, letterSpacing: "0.1px" }}
                >
                  If you'd like to restrict specific age group to this package,
                  remove the check the box next to the appropriate age group.
                </Typography>
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize={14}
                    color={theme?.palette?.text?.containerTextLabel}
                    sx={{ mr: 2, letterSpacing: "0.1px" }}
                  >
                    Open to All
                  </Typography>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={isOpenToAll}
                        onChange={(e) => {
                          handleChangeOpenToAll(e);
                        }}
                        sx={{ m: 1 }}
                      />
                    }
                    label=""
                  />
                </Box>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  fontSize={11}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={{ mt: 1, letterSpacing: "0.1px" }}
                >
                  Toggling this on will allow any user regardless of their age
                  purchase this package.
                </Typography>

                <Box
                  sx={{ height: 200, overflowY: "auto", overflowX: "none" }}
                  mt={2}
                  bgcolor="#F4F4F4"
                >
                  {gradeArr?.length ? (
                    gradeArr?.map((grade) => (
                      <Box key={grade?.id}>
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            sx={{
                              color: "#00B60D",
                              "&.Mui-checked": {
                                color: "#00B60D",
                              },
                            }}
                            checked={selectedGrades.indexOf(grade?.id) > -1}
                            onChange={() => handleChangeGrade(grade?.id)}
                          />
                          <Typography
                            variant="h6"
                            fontWeight={400}
                            fontSize={13}
                            color={theme?.palette?.text?.containerTextLabel}
                            sx={{ letterSpacing: "0.1px" }}
                          >
                            {grade?.name}
                          </Typography>
                        </Box>
                        <Box
                          width={1}
                          height={"1px"}
                          bgcolor={"gray"}
                          sx={{ opacity: 0.25 }}
                        ></Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      fontWeight={400}
                      fontSize={13}
                      color={theme?.palette?.text?.containerTextLabel}
                      sx={{
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        mt: 7,
                      }}
                    >
                      No grade found...
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            width={1}
            mt={6}
          >
            <Typography
              variant="h5"
              sx={{
                maxWidth: 120,
                height: 40,
                background: "transparent",
                border: `2px solid ${theme?.palette?.secondary?.dark}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme?.palette?.secondary?.dark,
                cursor: "pointer",
                mr: 2,
                my: 1,
                px: 3,
              }}
              onClick={() => setShowCancelConfirmPopup(true)}
            >
              Cancel
            </Typography>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography
                variant="h5"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: "transparent",
                  border: `2px solid ${theme?.palette?.secondary?.dark}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme?.palette?.secondary?.dark,
                  cursor: "pointer",
                  px: 3,
                  my: 1,
                }}
                onClick={onBack}
              >
                Back
              </Typography>
              <Button
                size="small"
                variant="contained"
                type="submit"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: theme?.palette?.secondary?.dark,
                  color: "white",
                  ml: 2,
                  px: 3,
                  my: 1,
                }}
                // disabled={
                //   selectedGrades?.length &&
                //     selectedColor ?
                //     false :
                //     true
                // }
              >
                Next
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
