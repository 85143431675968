import {
  Box,
  Typography,
  Grid,
  Divider,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import ComingSoon from "../../components/ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useRef } from "react";
import TiktokIcon from "../../assets/images/social-links/tiktok.png";
import TwitterIcon from "../../assets/images/social-links/twitter.png";
import InstaIcon from "../../assets/images/social-links/instagram.png";
import SnapIcon from "../../assets/images/social-links/snapchat.png";
import FacebookIcon from "../../assets/images/social-links/facebook.png";
import LinkedInIcon from "../../assets/images/social-links/linkedin.png"
import YouTubeIcon from "@mui/icons-material/YouTube";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTheme } from "@mui/material/styles";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Tile from "../../assets/images/Tile.png";
import Employee from "../../assets/images/employee.png";
import { getMe, SET_USER_DRAWER, uploadFileAction, SET_TOAST, SET_SUCCESS_ERROR_POPUP, SET_USER, SET_LOADER } from "../../store/actions";
import * as pages from '@routes/constants'
import { useNavigate } from "react-router";
import { charValidate } from '@utils/functions'
import SchoolIcon from "@mui/icons-material/School";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dailyTraining_icon from "../../assets/images/dashboard-menu/icon-daily-training.svg";
import products_icon from "../../assets/images/dashboard-menu/icon-products.svg";
import filmRoom_icon from "../../assets/images/dashboard-menu/icon-filmroom.svg";
import performanceEvaluations_icon from "../../assets/images/dashboard-menu/icon-performance-evaluations.svg";
import campsClinics_icon from "../../assets/images/dashboard-menu/icons-camps-clinics.svg";
import approvals_icon from "../../assets/images/dashboard-menu/icon-approvals.svg";
import support_icon from "../../assets/images/dashboard-menu/icon-support.svg";
import scholarshipProgram_icon from "../../assets/images/dashboard-menu/icon-scholarship-program.svg";
import adminCommunicate_icon from "../../assets/images/Icon_ionic-md-megaphone.png";
import communication_icon from "../../assets/images/dashboard-menu/icon-communication.svg";
import manageCoaches_icon from "../../assets/images/dashboard-menu/icon-manage-coaches.svg";
import manageAthletes_icon from "../../assets/images/dashboard-menu/icon-manage-athletes.svg";
import httpClient from '@utils/httpClient'

function DashboardForAdmin() {
  const { user, token } = useSelector((state) => state.user);
  const org = useSelector((state) =>
    state.org.org ? state.org.org.data : {}
  )
  const state = useSelector(state => state);
  const drawerOpened = useSelector((state) => state.theme.drawer);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSocialLink = org ? (org?.web_menu_settings?.coach[0]?.dashboard.filter(x => x.id === 'social')[0].externalLink) : "";
  const mainMenuOptions = org?.web_menu_settings?.admin?.map((menu) => menu?.dashboardMenuOptions?.mainMenuOptions ?? []);
  const secondaryOptions = org?.web_menu_settings?.admin?.map((menu) => menu?.dashboardMenuOptions?.secondaryOptions ?? []);

  const fileInputRef = useRef(null);

  const mainBox = {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    padding: "1px",
    borderRadius: "4px",
    borderColor: "transparent",
    mt: 2,
    px: 2.5,
    py: 2,
    color: theme?.palette?.dark?.dark3,
    display: "flex",
    alignItems: "center",
  };
  const contentBox = {
    width: "50%",
    minWidth: 130,
    height: 180,
    minHeight: 100,
    backgroundColor: "white",
    boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
    border: "none",
    borderRadius: "4px",
    borderColor: "transparent",
    textAlign: "center",
    p: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer"
  };
  const iconStyle = {
    color: theme?.palette?.dark?.dark3,
    fontSize: "30px",
  };
  const titleStyle = {
    fontStyle: "italic",
    fontSize: "15px",
    color: theme?.palette?.secondary?.dark,
    fontWeight: "900",
    mb: 1,
    mt: 1.5,
  };
  const subTitleStyle = {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: theme?.palette?.secondary?.dark,
    fontWeight: 500,
    my: 1
  };
  const userProfilePlusIconStyle = {
    color: "green",
    fontSize: "1.4rem",
    position: "absolute",
    bottom: "8px",
    right: 0,
    background: "white",
    borderRadius: "100%",
    cursor: "pointer"
  }

  const renderMainMenuIcons = (iconName) => {
    if ("dailyTraining_icon" === iconName) {
      return dailyTraining_icon;
    } else if ("products_icon" === iconName) {
      return products_icon;
    } else if ("filmRoom_icon" === iconName) {
      return filmRoom_icon;
    } else if ("performanceEvaluations_icon" === iconName) {
      return performanceEvaluations_icon;
    } else if ("campsClinics_icon" === iconName) {
      return campsClinics_icon;
    } else if ("approvals_icon" === iconName) {
      return approvals_icon;
    } else if ("support_icon" === iconName) {
      return support_icon;
    } else if ("scholarshipProgram_icon" === iconName) {
      return scholarshipProgram_icon;
    }
  }

  const renderSideMenuIcons = (iconName) => {
    if ("adminCommunicate_icon" === iconName) {
      return adminCommunicate_icon;
    } else if ("communication_icon" === iconName) {
      return communication_icon;
    } else if ("manageCoaches_icon" === iconName) {
      return manageCoaches_icon;
    } else if ("manageAthletes_icon" === iconName) {
      return manageAthletes_icon;
    }
  }

  const handleFileInputChange = async (event) => {
    const selectedFile = event.target.files[0];
    const API_URL = "/auth/change-profilePic"
    const formData = new FormData();
    formData.append('files', selectedFile);
    dispatch({ type: SET_LOADER, data: true })
    const apiRes = await httpClient.post(API_URL, formData)
    if (apiRes && apiRes.ok) {
      dispatch({ type: SET_LOADER, data: false })
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: apiRes.message }
      })
      dispatch({ type: SET_USER, data: apiRes.user })
    } else {
      dispatch({ type: SET_LOADER, data: false })
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes.message }
      })
    }
  };

  const handleIconClick = () => {
    // Trigger the file input when the icon is clicked
    fileInputRef.current.click();
  };

  useEffect(() => {
    dispatch(getMe())
  }, [user?.user_type])

  return (
    <Box sx={{
      height: "100%",
    }}>
      <Grid
        container
        spacing={2}
        sx={{ alignSelf: "center", justifySelf: "center", height: "100%", }}
      >
        <Grid
          item
          lg={4} md={5} xs={12}
          sx={{
            background: "#FAFAFA",
            borderRight: "1.7px solid #DDDDDD",
            // height: "100vh"
            [theme.breakpoints.down('md')]: {
              background: "transparent",
              borderRight: "0",
            }
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            sx={{
              p: 3,
              pl: 1,
              [theme.breakpoints.down('md')]: {
                background: "#FAFAFA",
                borderRight: "1px solid #DDDDDD",
                borderTop: "1px solid #DDDDDD",
                borderLeft: "1px solid #DDDDDD",
                pl: 3,
              },
              "& .MuiOutlinedInput-root": {
                border: "1px solid #dfe0df",
                boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
                background: "#fff",
                "& .MuiOutlinedInput-input": {
                  background: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: "none",
                },
              },
              "& .MuiFormControl-root .MuiFormControl-root": {
                boxShadow: "none",
              },
              span: {
                display: "block",
                "font-size": "25px",
                "font-weight": 700,
                "line-height": "57px",
                /* text-align: center; */
                height: "60px",
                width: "60px",
                pl: "12px",
              },
              figure: {
                margin: 0,
                height: "60px",
                width: "60px",
                "min-width": "30px",
                "border-radius": "50rem",
                position: "relative",
                overflow: "hidden",
                border: "3px solid #fff",
                "box-shadow": "0 0 15px rgba(0, 0, 0, 0.25)",
                left: "50%",
                "-webkit-transform": "translate(-50%)",
                "-moz-transform": "translate(-50%)",
                "-ms-transform": "translate(-50%)",
                transform: "translate(-50%)",
              },
              "#userImg": {
                width: "100%",
                height: "100%",
              },
              "#save": {
                py: 1.5,
                px: 3.5,
                fontSize: 15,
                fontWeight: 700,
                mt: 2,
              },
            }}
          >
            <Box display="flex" alignItems="center">
              {user?.image ?
                <Box
                  sx={{
                    // border: `3px solid ${theme?.palette?.background?.paper}`,
                    // boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                    // textTransform: "uppercase",
                    height: 100,
                    width: 100,
                    borderRadius: "100%",
                    position: "relative",
                    cursor: "pointer",
                    [theme.breakpoints.down('lg')]: {
                      height: 80,
                      width: 80,
                    }
                  }}
                // onClick={() => {
                //   dispatch({ type: SET_USER_DRAWER, data: true });
                // }}
                >
                  <img
                    src={user?.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "100%",
                      objectFit: "cover",
                      background: "white",
                      padding: "5px",
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                      [theme.breakpoints.down('lg')]: {
                        height: "100%",
                        width: "100%",
                      }
                    }}
                  />
                  <Box>
                    <input
                      type="file"
                      accept="image/*" // Specify the file types allowed (e.g., images)
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                    />
                    <AddCircleIcon sx={userProfilePlusIconStyle} onClick={handleIconClick} />
                  </Box>
                </Box> :
                <Box
                  sx={{
                    position: "relative", height: 100, width: 100,
                    [theme.breakpoints.down('lg')]: {
                      height: 80,
                      width: 80,
                    }
                  }}
                // onClick={() => {
                //   dispatch({ type: SET_USER_DRAWER, data: true });
                // }}
                >
                  <Box
                    sx={{
                      backgroundColor: theme?.palette?.secondary?.dark,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: theme?.palette?.background?.paper,
                      border: `3px solid ${theme?.palette?.background?.paper}`,
                      boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                      textTransform: "uppercase",
                      height: 100,
                      width: 100,
                      borderRadius: "100%",
                      fontSize: "24px",
                      [theme.breakpoints.down('lg')]: {
                        height: 80,
                        width: 80,
                      }
                    }}
                  >
                    {`${user?.first_name?.charAt(
                      0,
                    )} ${user?.last_name?.charAt(0)}`}
                  </Box>
                  <Box>
                    <input
                      type="file"
                      accept="image/*" // Specify the file types allowed (e.g., images)
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                    />
                    <AddCircleIcon sx={userProfilePlusIconStyle} onClick={handleIconClick} />
                  </Box>
                </Box>
              }
              <Box display="flex" flexDirection="column" ml={2}>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    fontSize: "20px",
                    color: theme?.palette?.dark?.dark3,
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    [theme.breakpoints.down('lg')]: {
                      ml: 0,
                    }
                  }}
                >
                  {`${user?.first_name} ${user?.last_name}`}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    fontSize: "14px",
                    color: theme?.palette?.text?.containerTextLabel,
                    my: 1,
                    fontFamily: "Arial, Regular",
                    fontWeight: 500,
                    [theme.breakpoints.down('lg')]: {
                      ml: 0,
                    }
                  }}
                >
                  {user?.email ? charValidate(user?.email, 20) : "N/A"}
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: 'wrap',
                  }}
                >
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.tiktok_url} target="_blank"
                      style={{
                        pointerEvents: user?.tiktok_url ? 'auto' : 'none',
                        opacity: user?.tiktok_url ? 1 : 0.5,
                        cursor: user?.tiktok_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={TiktokIcon}
                        alt="Tiktok Icon"
                        width="auto"
                        height="20px"
                      />
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.twitter_url} target="_blank"
                      style={{
                        pointerEvents: user?.twitter_url ? 'auto' : 'none',
                        opacity: user?.twitter_url ? 1 : 0.5,
                        cursor: user?.twitter_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={TwitterIcon}
                        alt="Twitter Icon"
                        width="20px"
                        height="auto"
                      />
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.linkedin_url} target="_blank"
                      style={{
                        pointerEvents: user?.linkedin_url ? 'auto' : 'none',
                        opacity: user?.linkedin_url ? 1 : 0.5,
                        cursor: user?.linkedin_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={LinkedInIcon}
                        alt="Linked Icon"
                        width="20px"
                        height="auto"
                      />
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.insta_url} target="_blank"
                      style={{
                        pointerEvents: user?.insta_url ? 'auto' : 'none',
                        opacity: user?.insta_url ? 1 : 0.5,
                        cursor: user?.insta_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={InstaIcon}
                        alt="Instagram Icon"
                        width="20px"
                        height="auto"
                      />
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.yt_url} target="_blank"
                      style={{
                        pointerEvents: user?.yt_url ? 'auto' : 'none',
                        opacity: user?.yt_url ? 1 : 0.5,
                        cursor: user?.yt_url ? 'pointer' : 'default',
                      }}
                    >
                      <YouTubeIcon sx={{ color: "#1E2549" }} />{" "}
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.snap_url} target="_blank"
                      style={{
                        pointerEvents: user?.snap_url ? 'auto' : 'none',
                        opacity: user?.snap_url ? 1 : 0.5,
                        cursor: user?.snap_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={SnapIcon}
                        alt="Snap Icon"
                        width="20px"
                        height="auto"
                      />
                    </a>
                  </Box>
                  <Box px={2} py={1} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <a href={user?.fb_url} target="_blank"
                      style={{
                        pointerEvents: user?.fb_url ? 'auto' : 'none',
                        opacity: user?.fb_url ? 1 : 0.5,
                        cursor: user?.fb_url ? 'pointer' : 'default',
                      }}
                    >
                      <img
                        src={FacebookIcon}
                        alt="Facebook Icon"
                        width="20px"
                        height="auto"
                      />
                    </a>
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                // border: "1px solid",
                // borderRadius: "4px",
                height: "115px",
                width: "100%",
                mt: 4,
                overflowY: "auto",
                padding: "1rem",
                [theme.breakpoints.down('lg')]: {
                  mt: 3,
                },
                [theme.breakpoints.down('md')]: {
                  mt: 2,
                }
              }}
            >
              {
                user.bio ?
                  <Typography
                    sx={{
                      // textAlign: "center",
                      fontSize: "14px",
                      color: theme?.palette?.dark?.dark3,
                      fontWeight: 400,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {user.bio}
                  </Typography> :
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: theme?.palette?.dark?.dark3,
                      textDecoration: "underline",
                      fontWeight: 400,
                      fontFamily: "Poppins, sans-serif",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      dispatch({ type: SET_USER_DRAWER, data: true });
                    }}
                  >
                    Add Bio
                  </Typography>
              }
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} mt={0} pl={1} pr={3} sx={{
            [theme.breakpoints.down('md')]: {
              background: "#FAFAFA",
              borderRight: "1px solid #DDDDDD",
              borderBottom: "1px solid #DDDDDD",
              borderLeft: "1px solid #DDDDDD",
              pl: 3,
              pb: 2,
            },
          }}>
            {secondaryOptions && secondaryOptions?.length ?
              secondaryOptions[0]?.map((option) => (
                <Box
                  key={option?.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "4rem",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    my: 1.5,
                    background: "white",
                    padding: "1.5rem",
                    borderRadius: "5px",
                    cursor: "pointer",
                    [theme.breakpoints.down('md')]: {
                      mt: 0,
                    }
                  }}
                  onClick={() => {
                    if (option?.title === "Manage Community") {
                      // window.open(option?.path);
                      window.open(`${isSocialLink}?token=${token}`, "_blank")
                    } else {
                      navigate(option?.path);
                    }
                  }}
                >
                  <img
                    src={renderSideMenuIcons(option?.icon)}
                    style={{
                      width: "1.8rem",
                      height: "1.8rem"
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: theme?.palette?.secondary?.dark,
                      fontWeight: 500,
                      fontFamily: "Poppins, sans-serif",
                      ml: 2.5
                    }}
                  >
                    {option?.title}
                  </Typography>
                </Box>
              ))
              : null}
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Divider
            sx={{
              width: "0.7px",
              height: "100%",
              backgroundColor: "grey",
              opacity: 0.5,
            }}
          />
        </Grid> */}
        <Grid item lg={8} md={7} xs={12}>
          <Box mx={1} mb={2}>
            {mainMenuOptions && mainMenuOptions?.length ?
              mainMenuOptions[0]?.map((menu) => (
                <Box key={menu?.id}>
                  <Box
                    sx={{
                      mt: 3,
                      pb: 2.5,
                      borderBottom: "2.5px solid rgba(0,0,0,0.1)",
                      [theme.breakpoints.down('md')]: {
                        pb: 1.5,
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: theme?.palette?.text?.darkTextSubHeader,
                        fontFamily: "Poppins",
                      }}
                    >
                      {menu?.title}
                    </Typography>
                  </Box>
                  <Grid container spacing={4} mt={0}>
                    {menu?.options?.map((option) => (
                      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}> {/* sm={12} xs={12} */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            background: "white",
                            padding: "1.5rem",
                            borderRadius: "5px",
                            cursor: "pointer",
                            border: option?.title === "Create Products" ? "2px solid #1E4925" : "none",
                            [theme.breakpoints.down('sm')]: {
                              padding: "1.5rem 1rem",
                            }
                          }}
                          onClick={() => {
                            if (option?.path) {
                              navigate(option?.path);
                            }
                          }}
                        >
                          <img
                            src={renderMainMenuIcons(option?.icon)}
                            style={{
                              width: "1.8rem",
                              height: "1.8rem",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: theme?.palette?.secondary?.dark,
                              fontWeight: 700,
                              fontFamily: "Poppins, sans-serif",
                              textAlign: "center",
                              mt: 2
                            }}
                          >
                            {option?.title.split(' ').map((word, index) => (
                              <div key={index}>
                                {word}
                                <br />
                              </div>
                            ))}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))
              : null}
          </Box>
          {/* <Box sx={{ ...mainBox, cursor: "pointer" }} onClick={() => navigate(pages?.Admin_Communicate)}>
            <GroupsIcon sx={{ fontSize: 32 }} />
            <Typography
              variant="h4"
              sx={{ ml: 1.5, color: theme?.palette?.dark?.dark3, fontSize: 15, fontWeight: 700 }}
            >
              Communicate
            </Typography>
          </Box> */}
          {/* <Typography
            sx={{
              mt: 3,
              mb: 1.5,
              fontSize: 18,
              fontWeight: 700,
              color: theme?.palette?.text?.darkTextSubHeader,
              fontFamily: "Poppins",
            }}
          >
            Compete
          </Typography> */}
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ ...contentBox, mr: 2 }} onClick={() => navigate(pages?.LEAGUE_SUPERADMIN)}>
              <SportsFootballIcon sx={iconStyle} />
              <Typography sx={titleStyle}>LEAGUES</Typography>
              <Typography sx={subTitleStyle}>
                Create and/or join exclusive Gridiron leagues.
              </Typography>
            </Box>
            <Box sx={contentBox} onClick={() => navigate(pages?.Admin_Tournament_PAGE)}>
              <img src={Tile} alt="Tile Icon" width="40px" height="25px" />
              <Typography sx={titleStyle}>TOURNAMENTS</Typography>
              <Typography sx={subTitleStyle}>
                Create and/or join exclusive Gridiron leagues.
              </Typography>
            </Box>
          </Box> */}
          {/* <Typography
            sx={{
              mt: 3,
              mb: 1.5,
              fontSize: 18,
              fontWeight: 700,
              color: theme?.palette?.text?.darkTextSubHeader,
              fontFamily: "Poppins",
            }}
          >
            Support
          </Typography> */}
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ ...contentBox, mr: 2 }} onClick={() => navigate(Scholarship_Request)}>
              <SchoolIcon sx={{ fontSize: 40, color: theme?.palette?.secondary?.dark, }} />
              <Typography sx={titleStyle}>SCHOLARSHIP FUNDS</Typography>
            </Box>
          </Box> */}
          {/* <Divider
            sx={{
              my: 4,
              width: "100%",
              height: "0.5px",
              backgroundColor: "grey",
              opacity: 0.5,
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ ...contentBox, mr: 2 }} onClick={() => navigate(pages?.Manage_Parent_BY_ATHLETE_Page)}>
              <img src={Employee} width="25px" height="25px" />
              <Typography sx={subTitleStyle}>
                Manage Parents <br />or Guardians
              </Typography>
            </Box>
            <Box sx={contentBox} onClick={() => navigate(pages?.Manage_COACH)}>
              <PeopleRoundedIcon sx={{ ...iconStyle, color: theme?.palette?.secondary?.dark }} />
              <Typography sx={subTitleStyle}>Manage <br />My Coaches</Typography>
            </Box>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardForAdmin;
