import {
  Box,
  Typography,
  useTheme,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomTextField from "../../components/CustomTextField";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Package_List } from "../../routes/constants";
import { useNavigate } from "react-router-dom";

const MuiDataGrid = ({
  headerLabel,
  gridData,
  columns,
  paginationOptions,
  tableRef,
  isLoading,
  onClickAddNew,
  uniqueId = "",
  isSearchIconShow = false,
  isAddNewBtnShow = true,
  isShowSubOrgFilter = false,
  isShowClientFilter = false,
  isShowFileDownloadOpt = false,
  isShowProductTypeFilter = false,
  handleRowClick,
  subOrgsList,
  productTypeList,
  gridProducts = () => null,
  handleSubOrgFilterChange,
  handleSubOrgScroll,
  rightBtnLabel = "Add New",
  handleProductTypeFilterChange,
  gridButtons = () => null,
  gridToolbarButtons = () => null,
  gridMessage = null,
  options,
  handlePrint = () => {},
  handlePayAllPayment = () => {},
  handlePagination = () => {},
  showPayAllBtn = false,
  paymentBtnLabel = "",
  textLabelButtonForDownloadOpt = "",
  isAddNewTeamCoach = false,
  onClickAddNewCoach,
  tableMessageForPaidRostersList = "",
  isShowProductPurchaseBtn = false,
  pageSizeOptions = [10, 25, 50, 100],
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const dataGrid = () => {
    return (
      <Box
        sx={{
          height: "auto",
          width: "100%",
        }}
      >
        {isShowSubOrgFilter && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pb={3}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={17}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{ mb: 2 }}
            >
              Filter by sub-organization
            </Typography>
            <Select
              labelId="sub-org-filter"
              onChange={handleSubOrgFilterChange}
              input={
                <OutlinedInput
                  placeholder="Select Sub Organization"
                  sx={{ width: 400, height: 45 }}
                />
              }
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                  onScroll: (event) => {
                    handleSubOrgScroll(event);
                  },
                },
              }}
              renderValue={(value) => {
                if (value) {
                  let selected = subOrgsList?.find(
                    (subOrg) => subOrg?.id === value,
                  );
                  if (selected) {
                    return selected?.title;
                  }
                  // return "All";
                } // else {

                //   return (
                //     <Typography
                //       variant="p"
                //       fontWeight={400}
                //       fontSize={14}
                //       sx={{ opacity: 0.5 }}
                //     >
                //       Select Sub-Organization...
                //     </Typography>
                //   );
                // }
                return "All";
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {subOrgsList?.map((orgType) => (
                <MenuItem key={orgType?.id} value={orgType?.id}>
                  {orgType?.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {isShowProductTypeFilter && (
          <Box
            display="flex"
            //flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              spacing={3}
              sx={{ mb: 3, justifyContent: "center" }}
            >
              <Grid item lg={8} md={10} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Select
                      labelId="product-type-filter"
                      onChange={handleProductTypeFilterChange}
                      input={
                        <OutlinedInput
                          placeholder="Select Type"
                          sx={{ width: "100%", height: 45 }}
                        />
                      }
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      MenuProps={MenuProps}
                      renderValue={(value) => {
                        if (value) {
                          let selected = productTypeList?.find(
                            (prodtype) => prodtype?.id === value,
                          );
                          if (selected) {
                            return selected?.name;
                          }
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Filter by Type...
                            </Typography>
                          );
                        }
                        //return "All";
                      }}
                    >
                      <MenuItem value={""}>All</MenuItem>
                      {productTypeList?.map((prodType) => (
                        <MenuItem key={prodType?.id} value={prodType?.id}>
                          {prodType?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {gridProducts()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Typography
              variant="h4"
              fontWeight={700}
              fontSize={17}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{ mb: 2 }}
            >
              Filter by Type
            </Typography> */}
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            // "& .super-app-theme--cell": {
            //   fontWeight: "600",
            // },
          }}
        >
          {/* {showPayAllBtn &&
            <Typography
              variant="h6"
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                ml: 1,
                mb: 3,
              }}
              onClick={handlePayAllPayment}
            >
             {paymentBtnLabel}
            </Typography>
          } */}
          <DataGrid
            rows={gridData ?? []}
            columns={columns ?? []}
            pageSizeOptions={pageSizeOptions}
            ref={tableRef}
            pagination={false}
            getRowId={(data) => (uniqueId ? data[uniqueId] : data?.id)}
            paginationMode="server"
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  page: paginationOptions?.offset,
                  pageSize: paginationOptions?.limit,
                },
              },
            }}
            onPaginationModelChange={handlePagination}
            rowCount={paginationOptions?.totalMainDocs}
            hideFooterPagination={
              pageSizeOptions && pageSizeOptions?.length ? false : true
            }
            hideFooterSelectedRowCount={
              pageSizeOptions && pageSizeOptions?.length ? false : true
            }
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            getRowHeight={() => "auto"}
            slots={{
              loadingOverlay: () => (
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={14}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={noDataLoadingLabelStyle}
                >
                  Loading...
                </Typography>
              ),
              noRowsOverlay: () => (
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={14}
                  color={theme?.palette?.text?.containerTextLabel}
                  sx={noDataLoadingLabelStyle}
                >
                  {tableMessageForPaidRostersList
                    ? tableMessageForPaidRostersList
                    : "No Data Found"}
                </Typography>
              ),
            }}
            onRowClick={handleRowClick}
            sx={{
              width: 1,
              "& .MuiDataGrid-overlayWrapper": {
                minHeight: "150px !important",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase !important",
                fontWeight: "700 !important",
              },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "18px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "18px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "18px",
              },
            }}
            className="super-app-theme--cell"
            {...options}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
      <Box
        sx={{
          width: "95%",
          position: "relative",
          backgroundColor: theme?.palette?.background?.paper,
          boxShadow: theme?.palette?.boxShadow?.tableContainer,
          borderRadius: 1,
          border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
        }}
        mt={4}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={3}
          sx={{
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
              px: 3,
              pt: 3,
              pb: 2,
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            sx={{
              // whiteSpace: "nowrap",
              wordBreak: "break-all",
            }}
            mr={2}
            mb={{ xs: 1, sm: 1, md: 0 }}
            color={theme?.palette?.text?.containerTextLabel}
          >
            {headerLabel}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >
            {/* ----> Table filtering, searching, sorting, pagination disabled for now <---- */}

            {/* {isSearchIconShow && (
              <CustomTextField
                name="grid-search"
                placeholder="Search..."
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: 250,
                    boxShadow: "none",
                  },
                }}
              />
            )} */}
            {/* {isShowClientFilter && (
              <Box ml={3}>
                <Select
                  labelId="filter-by-client"
                  onChange={handleSubOrgFilterChange}
                  input={
                    <OutlinedInput
                      placeholder="Filter By Client"
                      sx={{ width: 250, height: 45 }}
                    />
                  }
                  displayEmpty
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Filter By Client
                      </Typography>
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    Filter By Client
                  </MenuItem>
                  {[]?.map((client) => (
                    <MenuItem key={client?.id} value={client?.id}>
                      {client?.title}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )} */}
            {gridMessage ? gridMessage : null}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              sx={{
                [theme.breakpoints.down("md")]: {
                  justifyContent: "start",
                  flexWrap: "wrap",
                  width: "100%",
                },
              }}
            >
              {gridToolbarButtons()}

              {isShowFileDownloadOpt ? (
                textLabelButtonForDownloadOpt ? (
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      textDecoration: "underline",
                      cursor: "pointer",
                      m: 1,
                    }}
                    onClick={handlePrint}
                  >
                    {textLabelButtonForDownloadOpt}
                  </Typography>
                ) : (
                  <LocalPrintshopIcon
                    sx={{
                      color: theme?.palette?.dark?.dark3,
                      cursor: "pointer",
                      fontSize: "1.8rem",
                    }}
                    onClick={handlePrint}
                  />
                )
              ) : null}

              {isAddNewTeamCoach && (
                <Button
                  onClick={onClickAddNewCoach}
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ paddingX: 2.5, paddingY: 1.2, m: 0.5 }}
                >
                  Add Coach/Manager
                </Button>
              )}
              {isShowProductPurchaseBtn && (
                <Button
                  onClick={() => navigate(Package_List)}
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ paddingX: 2.5, paddingY: 1.2, m: 0.5 }}
                >
                  Purchase Product
                </Button>
              )}
              {isAddNewBtnShow && (
                <Button
                  onClick={onClickAddNew}
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ paddingX: 2.5, paddingY: 1.2, m: 0.5 }}
                >
                  {rightBtnLabel}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mt={1}
        ></Box>
        <Box px={3} py={3}>
          {dataGrid()}
        </Box>
        {gridButtons()}
      </Box>
    </Box>
  );
};

const noDataLoadingLabelStyle = {
  textAlign: "center",
  position: "relative",
  top: "30%",
};

export default MuiDataGrid;
