import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import Logo from "../../assets/images/IYS.png";
import { useTheme } from '@mui/material/styles'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material'
// import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import PasswordScreen from './PasswordScreen';
import OtpTypeScreen from './OtpTypeScreen';
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP, SET_TOAST, addAdminUser, getSubOrgTeams } from '../../store/actions';
import httpClient from "@utils/httpClient";
import { firebaseAuth } from "@utils/firebase";
import { randomNum } from "@utils/functions";
import OtpScreen from "./OtpScreen";
import EditDetailScreen from "./EditDetailsScreen";
import { convertToCapsOfFirstLetter } from "@utils/functions";


const validationSchema = Yup.object({
  first_name: Yup.string().trim().required('Athlete First Name is required'),
  last_name: Yup.string().trim().required('Athlete Last Name is required'),
  email: Yup.string().trim()
    .required('Athlete Email is required')
    .email('Must be a valid email'),
  mobile: Yup.string().required('Athlete mobile is required'),
  parent_first_name: Yup.string().required('Parent first name is required'),
  parent_last_name: Yup.string().required('Parent last name is required'),
  parent_email: Yup.string().required('Parent email is required').email('Must be a valid email'),
  parent_mobile: Yup.string().required('Parent mobile is required'),
  parent_countryCode: Yup.string().required('Country Code is required'),
  countryCode: Yup.string().required('Country Code is required'),
  teamId: Yup.string().required("Team selection is required"),
  // user_type: Yup.string().trim().when('isActive', {
  //     is: true,
  //     then: Yup.string().trim().required('Please select your type'),
  //     otherwise: Yup.string().trim(),
  // }),
});

const Registration = () => {
  const theme = useTheme()
  const { org } = useSelector((state) => state.org);
  const dispatch = useDispatch();
  const [randomId, setRandomId] = useState(null);
  const [formData, setFormData] = useState({
    device_id: "abc123",
    device_token: "abcxyz123",
    device_type: "web",
    // sub_organization: "1"
    sub_organization: 1,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState("")
  const [step, setStep] = useState(0);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [subOrgTeamsList, setSubOrgTeamsList] = useState([]);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      parent_first_name: "",
      parent_last_name: "",
      parent_email: "",
      parent_mobile: "",
      sub_organization: 1,
      parent_countryCode: "",
      countryCode: "",
      teamId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setShowError("")
      dispatch({ type: SET_LOADER, data: true })
      const res = await httpClient.post('/auth/verifyEmail', {
        email: values?.parent_email,
        mobile: values?.parent_mobile,
      })
      dispatch({ type: SET_LOADER, data: false })
      if (!res || !res.ok) {
        // setShowError(res?.message ?? "Email or Mobile number already exists");
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: res?.message ?? "Email or Mobile number already exists" }
        })
        return false;
      } else {
        const formattedMobile = "1" + values.parent_mobile;
        const countryCode = values.countryCode.toUpperCase()
        const parent_countryCode = values.parent_countryCode.toUpperCase()
        setFormData((prevStage) => {
          return {
            ...prevStage,
            ...values,
            countryCode,
            parent_countryCode,
            formattedMobile
          }
        })
        setStep(step + 1)
      }
      // let bodyParam = {
      //     // id: adminData?.id ?? undefined,
      //     first_name: values?.first_name ?? "",
      //     last_name: values?.last_name ?? "",
      //     email: values?.email ?? "",
      //     countryCode: values?.countryCode ?? "",
      //     mobile: values?.mobile ?? "",
      //     "permission": {
      //         "eventRegister": true,
      //         "socialAccount": true
      //     }
      //     }
      //     addAdmin(bodyParam)
      // },
    }
  });
  // const addAdmin = async (bodyParams) => {
  //     let params = {
  //         // userToken: state?.user?.token,
  //         // orgToken: state?.org?.org?.token,
  //     };
  //     let response = await addAdminUser(dispatch, params, bodyParams);
  //     if (response?.ok) {
  //         // handleClose(false);
  //     } else {
  //         dispatch({
  //             type: SET_TOAST,
  //             data: {
  //                 type: 'error',
  //                 message: response.message ?? "Something went wrong",
  //             },
  //         })
  //     }
  // };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const getRandomNum = () => {
    const _randomId = randomNum();
    setRandomId(_randomId);
  };

  const requestOtp = async (verify_type) => {
    return new Promise(async (resolve) => {
      try {
        if (verify_type === 'email') {
          dispatch({ type: SET_LOADER, data: true })
          const res = await httpClient.post('/auth/sendEmailOtp', {
            first_name: formData.parent_first_name,
            last_name: formData.parent_last_name,
            email: formData.parent_email,
          })
          dispatch({ type: SET_LOADER, data: false })
          resolve({
            ok: true,
            data: res,
          })
        } else if (verify_type === 'sms') {
          dispatch({ type: SET_LOADER, data: true })
          let _recaptchaVerifier = null
          if (recaptchaVerifier) {
            _recaptchaVerifier = recaptchaVerifier
          } else {
            _recaptchaVerifier = new RecaptchaVerifier(
              `mobile-signin-btn-${randomId}`,
              {
                size: 'invisible',
              },
              firebaseAuth
            )
            setRecaptchaVerifier(_recaptchaVerifier)
          }
          setTimeout(getRandomNum, 300)
          signInWithPhoneNumber(
            firebaseAuth,
            `+${formData.formattedMobile}`,
            _recaptchaVerifier
          )
            .then((_confirmationResult) => {
              dispatch({ type: SET_LOADER, data: false })
              if (_confirmationResult.verificationId) {
                setConfirmationResult(_confirmationResult)
                resolve({
                  ok: true,
                })
              } else {
                resolve({
                  ok: false,
                  message: `Something went wrong. Our server is not able to send OTP SMS.`,
                })
              }
            })
            .catch((error) => {
              dispatch({ type: SET_LOADER, data: false })
              resolve({
                ok: false,
                message: `Provided Mobile Number is invalid. Please provide Mobile Number along with country code.`,
              })
            })
        } else {
          dispatch({ type: SET_LOADER, data: false })
          resolve({
            ok: false,
            message: 'Invalid Verification Method.',
          })
        }
      } catch (err) {
        dispatch({ type: SET_LOADER, data: false })
        resolve({
          ok: true,
        });
      }
    })
  }
  //     } catch (err) {
  //       dispatch({ type: SET_LOADER, data: false });
  //       resolve({
  //         ok: false,
  //         message: "Something went wrong! Please try to refresh.",
  //       });
  //     }
  //   });
  // };

  const verifySmsOtp = (otpCode) => {
    return new Promise((resolve) => {
      dispatch({ type: SET_LOADER, data: true });
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: true,
            data: result,
          });
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, data: false });
          resolve({
            ok: false,
            message: `Invalid Code. Please retry.`,
          });
        });
    });
  };

  useEffect(() => {
    getRandomNum();
    getSubOrgTeamsList();
  }, []);

  const getSubOrgTeamsList = async () => {
    let params = {
      userToken: "",
      orgToken: "",
    };
    let response = await getSubOrgTeams(params, 1);
    if (response?.ok) {
      setSubOrgTeamsList(response?.data ?? []);
    }
  };

  const redirectToFacebook = () => {
    window.open('https://everykidsports.org/every-kid-sports-pass/', '_blank');
  };
  return (
    <>
      {step === 0 &&
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ padding: "5rem 0" }}
        >
          <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
            <Typography variant="h3" sx={{ mb: 1 }}> IYS Recreational League Fall 2023 </Typography>
            <Typography variant="body1" sx={{ ml: 2, color: '#000', fontWeight: 'bold' }}> $50 Per Athlete (You may qualify for assistance)</Typography>
            <Typography variant="body1" sx={{ ml: 10 }}><strong>Start Date: </strong>August 12th</Typography>
            <Typography variant="body1" sx={{ ml: 8 }}><strong>Number of Weeks:</strong> 10 Weeks</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
                <FormControl fullWidth sx={{ my: 1 }}>
                  <TextField
                    select
                    name="teamId"
                    label="Select Team"
                    variant="outlined"
                    value={formik.values?.teamId}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{
                      mt: 1,
                      opacity: 1,
                      zIndex: 1,
                      width: "100%",
                      "& .MuiSelect-select": {
                        height: "50px",
                        paddingTop: "0",
                        paddingBottom: "0",
                        lineHeight: "50px",
                        boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#dfe0df",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      Select Team
                    </MenuItem>
                    {subOrgTeamsList?.map((team) => (
                      <MenuItem value={team?.id} key={team?.id}>
                        {team?.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="first_name"
                    name="first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'first_name')}
                    value={formik.values.first_name}
                    placeholder="Athlete's First Name *"
                    error={Boolean(
                      formik.touched.first_name && formik.errors.first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.first_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="last_name"
                    name="last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'last_name')}
                    value={formik.values.last_name}
                    placeholder="Athlete's Last Name *"
                    error={Boolean(
                      formik.touched.last_name && formik.errors.last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.last_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.email && formik.errors.email,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email.toLowerCase()}
                    placeholder="Athlete's Email *"
                    error={Boolean(
                      formik.touched.email && formik.errors.email,
                    )}
                  />
                  {Boolean(
                    formik.touched.email && formik.errors.email,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.email}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.mobile && formik.errors.mobile,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      '& .react-tel-input .form-control': {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent"
                      }
                    }
                    }
                  >
                    <PhoneInput
                      country={"us"}
                      id="mobile"
                      name="mobile"
                      variant="outlined"
                      onlyCountries={['us']}
                      disableCountryCode={true}
                      placeholder="(702) 123-4567"
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue('countryCode', country.countryCode || "")
                        formik.setFieldValue('mobile', value || "");
                      }}
                      error={Boolean(
                        formik.touched.mobile && formik.errors.mobile
                      )}
                    />
                  </Box>
                  {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                    <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_first_name && formik.errors.parent_first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_first_name"
                    name="parent_first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'parent_first_name')}
                    value={formik.values.parent_first_name}
                    placeholder="Parent's First Name *"
                    error={Boolean(
                      formik.touched.parent_first_name && formik.errors.parent_first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_first_name && formik.errors.parent_first_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_first_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_last_name && formik.errors.parent_last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_last_name"
                    name="parent_last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'parent_last_name')}
                    value={formik.values.parent_last_name}
                    placeholder="Parent's Last Name *"
                    error={Boolean(
                      formik.touched.parent_last_name && formik.errors.parent_last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_last_name && formik.errors.parent_last_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_last_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_email"
                    name="parent_email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.parent_email}
                    placeholder="Parent's Email *"
                    error={Boolean(
                      formik.touched.parent_email && formik.errors.parent_email,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_email}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      '& .react-tel-input .form-control': {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent"
                      }
                    }
                    }
                  >
                    <PhoneInput
                      country={"us"}
                      id="parent_mobile"
                      name="parent_mobile"
                      variant="outlined"
                      placeholder="(702) 123-4567"
                      onlyCountries={['us']}
                      disableCountryCode={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.parent_mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue('parent_countryCode', country.countryCode || "")
                        formik.setFieldValue('parent_mobile', value || "");
                      }}
                      error={Boolean(
                        formik.touched.parent_mobile && formik.errors.parent_mobile
                      )}
                    />
                  </Box>
                  {Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_mobile}
                      </FormHelperText>
                    )}
                </FormControl>
                <Typography variant="body2" sx={{ color: "red", textAlign: "center" }}>{showError}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  sx={{ mt: 2 }}
                  label={
                    <Typography>
                      By checking this box, you agree to digital sign the <a href={org?.data?.waiver_url} target="_blank">
                        <u>Gridiron waiver</u>
                      </a> and agree to the Sketchplay terms and services.{" "}
                      <a
                        href={org?.data?.terms_url}
                        target="_blank"
                      >
                        <u>terms and services</u>
                      </a>
                    </Typography>
                  }
                />
                <Button variant='contained' fullWidth sx={{ mb: 3, mt: 4 }} disabled={formik.isSubmitting || !formik.isValid || !isChecked ||
                  (!formik.validateOnMount && !formik.dirty)} type='submit'>Next</Button>
              </Box>
            </form>
          </Grid >
        </Grid >
      }
      {step === 1 &&
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <Grid item xs={12}>
              <img src={Logo} alt="IYS logo" />
            </Grid>
            <PasswordScreen
              step={step}
              setStep={setStep}
              formData={formData}
              setFormData={setFormData}
            />
          </Grid>
        </>
      }
      {step === 2 &&
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid>
          <OtpTypeScreen
            step={step}
            setStep={setStep}
            setFormData={setFormData}
            requestOtp={requestOtp}
          />
        </Grid>
      }
      {step === 3 &&
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid>
          <OtpScreen
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            requestOtp={requestOtp}
            verifySmsOtp={verifySmsOtp}
          />
        </Grid>
      }
      {step === 4 &&
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <img src={Logo} alt="IYS logo" />
          </Grid>
          <EditDetailScreen
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
          />
        </Grid>
      }
      {step === 5 &&
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ p: "70px", minHeight: "100vh" }}
        >
          <Grid item xs={12} >
            <img src={Logo} alt="IYS logo" />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h3">Thanks! just one more step.</Typography>
            <Typography variant="h5" sx={{ my: 2, fontSize: '16px', fontFamily: '"Poppins",sans-serif,Arial', maxWidth: "600px" }}>To qualify for financial assistance please fill out the following form if Only fill this out if Your child is currently enrolled in Medicaid, SNAP or WIC.</Typography>
            <Button variant="contained" sx={{ mt: 2, width: "50%" }} onClick={redirectToFacebook}>Fill out form</Button>
            <Typography variant="h5" sx={{ mt: 5, fontSize: '16px', fontFamily: '"Poppins",regural' }} >Thank You!</Typography>
          </Grid>
        </Grid>
      }
      <div id={`mobile-signin-btn-${randomId}`} style={{ display: "none" }} />
    </>
  )

}

export default Registration;
