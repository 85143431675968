import {
    Box,
    Button,
    Grid,
    Typography,
    useTheme,
    Card, CardContent, Select, OutlinedInput, MenuItem, Divider, FormHelperText
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
    SET_TOAST,
    getAllTournamentTeams, getTournamentScores, SET_LOADER, SET_SUCCESS_ERROR_POPUP
} from '../../store/actions';
import { useParams } from 'react-router-dom';
import { Admin_Tournament_PAGE, Tournament_Teams_Page } from '../../routes/constants';
import CustomTextField from '../CustomTextField';
import { CustomInputLabel } from '../Common/Inputs/InputLabel';
import httpClient from "@utils/httpClient";
import { dateFormat } from '../../utils/functions';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const ViewAndAddScore = () => {
    const navigate = useNavigate()
    const [tournamentTeamsData, setTournamentTeamsData] = useState([])
    const [tournamentInfo, setTournamentInfo] = useState({});
    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
            },
        },
    };
    const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
    const selectionInputStyle = { width: "100%", height: 40, width: 300, };

    const theme = useTheme();
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [inputFields, setInputFields] = useState([]);
    const [filterInputFields, setFilterInputFields] = useState([]);
    const userType = state?.user?.user?.user_type;
    const [filterDate, setFilterDate] = useState('');
    const [filterDivision, setFilterDivision] = useState('');
    const [filterGrde, setFilterGrde] = useState('');
    const [divisionData, setDivisionData] = useState([]);
    const [gradeData, setGradeData] = useState([]);
    const [isScoreOneValueGTzero, setIsScoreOneValueGTzero] = useState(false);
    const [isScoreTwoValueGTzero, setIsScoreTwoValueGTzero] = useState(false);
    const today = dayjs();


    useEffect(() => {
        getTournamentTeamsList();
        getTournamentScoreList()
    }, []);

    useEffect(() => {
        if (userType === 'admin') {
            const filterData = filterInputFields.filter(x =>
                (filterDate === '' || x.date === filterDate) &&
                (filterDivision === '' || x.division?.id === filterDivision) &&
                (filterGrde === '' || x.grade?.id === filterGrde)
            );
            setInputFields(filterData);
        } else {
            const filterData = filterInputFields.filter(x =>
                (filterDate === '' || x.date === dateFormat(filterDate)) &&
                (filterDivision === '' || x.division?.id === filterDivision) &&
                (filterGrde === '' || x.grade?.id === filterGrde)
            );
            setInputFields(filterData);
        }

    }, [filterDate, filterDivision, filterGrde]);

    const getTournamentTeamsList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            tournamentId: id
        };
        let response = await getAllTournamentTeams(params, dispatch);
        if (response?.ok) {
            const tournament = response?.data?.TournamentInfo;
            setTournamentTeamsData(response?.data?.TournamentList ?? []);
            setTournamentInfo(tournament ?? {});
            setDivisionData(tournament?.division || [])
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: response.message ?? "Something went wrong for tournament data" }
            })
        }
    }

    const getTournamentScoreList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            tournamentId: id
        };
        let response = await getTournamentScores(params, dispatch);
        if (response?.ok) {
            if (userType === 'admin') {
                const mapData = response?.data?.map((field) => ({
                    ...field,
                    team_one: field.team_one?.id,
                    team_two: field.team_two?.id,
                    date: dayjs(field?.date),
                    division: Object.values(field.team_one?.division).length !== 0 ? field.team_one?.division : 'N/A',
                    grade: Object.values(field.team_one?.grade).length !== 0 ? field.team_one?.grade : 'N/A',
                }));
                setInputFields([...mapData, { idObj: 'name-id-0', team_one: '', score_one: '', division: '', grade: '', team_two: '', score_two: '', date: '' }]);
                setFilterInputFields(mapData)
            } else {
                const mapData = response?.data?.map((field) => ({
                    ...field,
                    team_one: field.team_one?.title,
                    team_two: field.team_two?.title,
                    date: dateFormat(field?.date),
                    division: Object.values(field.team_one?.division).length !== 0 ? field.team_one?.division : 'N/A',
                    grade: Object.values(field.team_one?.grade).length !== 0 ? field.team_one?.grade : 'N/A',
                }));
                setFilterInputFields(mapData)
                setInputFields(mapData)
            }

        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: response.message ?? "Something went wrong for tournament score data" }
            })
        }
    }

    const handleFieldChange = (index, fieldName, value) => {
        const updatedFields = [...inputFields];
        if (fieldName === 'date') {
            updatedFields[index][fieldName] = dayjs(value);
        } else {
            updatedFields[index][fieldName] = value;
        }

        if (fieldName === 'team_one') {
            const filterTeamArr = tournamentTeamsData.find(x => x.id === value)
            updatedFields[index]['division'] = filterTeamArr?.division;
            updatedFields[index]['grade'] = filterTeamArr?.grade;
            updatedFields[index]["team_one"] = value;
        } else if (fieldName === 'score_one') {
            if (value < 0) {
                setIsScoreOneValueGTzero(true)
            } else {
                setIsScoreOneValueGTzero(false)
            }
        } else if (fieldName === 'score_two') {
            if (value < 0) {
                setIsScoreTwoValueGTzero(true)
            } else {
                setIsScoreTwoValueGTzero(false)
            }
        }
        setInputFields(updatedFields);
    };

    const handleAddInputField = async () => {
        const data = inputFields[inputFields.length - 1];
        if (data.team_one === '' || data.team_two === '' || data.score_one === '' || data.score_two === '' || data.date === '') {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: "All fields must be required" }
            })
        } else {
            // const uniqueIds = new Set();
            // inputFields.forEach((item) => {
            //     uniqueIds.add(item.team_one);
            //     uniqueIds.add(item.team_two);
            // });
            // const filteredTeamArr = tournamentTeamsData.filter((team) => !uniqueIds.has(team.id));
            // setTournamentTeamsData(filteredTeamArr)
            setIsScoreOneValueGTzero(false)
            setIsScoreTwoValueGTzero(false)
            setInputFields((prevInputFields) => [
                ...prevInputFields,
                {
                    idObj: `name-id-${inputFields.length}`,
                    team_one: '',
                    score_one: '',
                    team_two: '',
                    score_two: '',
                    division: '',
                    grade: '',
                    date: ''
                },
            ]);
        }
    };

    const getDayFromDate = (dateString) => {
        const date = new Date(dateString);
        const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' }); // Get the full day name
        return dayOfWeek;
    };

    const handleSaveScore = async () => {
        const fieldFilterArr = inputFields.filter(x => x.team_one !== '' && x.team_two !== '' && x.score_one !== '' && x.score_two !== '' && x.date !== '')
        const fieldValues = fieldFilterArr.map((field) => ({
            ...field,
            score_one: parseInt(field.score_one),
            score_two: parseInt(field.score_two),
            day: getDayFromDate(field.date),
            tournament: id
        }));

        if (fieldValues.length > 0) {
            const reqPayload = { "scores": fieldValues }
            dispatch({ type: SET_LOADER, data: true });
            const res = await httpClient.post("/createTournamentScores", reqPayload);
            dispatch({ type: SET_LOADER, data: false });
            if (res && res.ok) {
                setIsScoreOneValueGTzero(false)
                setIsScoreTwoValueGTzero(false)
                setInputFields([])
                getTournamentScoreList()
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'success', message: res.message }
                })
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: res.message ?? "Something went wrong" }
                })
                return false;
            }
        } else {
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: "Please add at least one tournament score" }
            })
            return false;
        }
    };

    const handleFilterChange = (fieldName, value) => {
        if (fieldName === 'filterDate') {
            setFilterDate(value)
        } else if (fieldName === 'filterDivision') {
            setFilterDivision(value)
            const getGradeData = tournamentInfo?.division_grade?.find(x => x.id === value);
            setGradeData(getGradeData?.grades);
        } else if (fieldName === 'filterGrde') {
            setFilterGrde(value)
        }
    }

    const handleClearFilter = () => {
        setFilterDate('')
        setInputFields(filterInputFields)
        setFilterDivision('')
        setGradeData([]);
        setFilterGrde('')
    }

    return (
        <>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: "column",
                                    alignItems: "start",
                                }
                            }}>
                                <Typography variant="h4" component="div"
                                    sx={{
                                        pr: 1,
                                        [theme.breakpoints.down('md')]: {
                                            mb: 1,
                                        }
                                    }}>
                                    Score Teams
                                </Typography>
                                <Box display="flex" alignItems="center" flexWrap="wrap">
                                    {tournamentInfo?.schedule &&
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            sx={{ paddingX: 3.5, paddingY: 1, m: 0.5, ml: 0 }}
                                            onClick={() => {
                                                window.open(tournamentInfo?.schedule, '_blank');
                                            }}
                                        >
                                            Schedule
                                        </Button>
                                    }
                                    {tournamentInfo?.rules &&
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            sx={{ paddingX: 3.5, paddingY: 1, m: 0.5 }}
                                            onClick={() => {
                                                window.open(tournamentInfo?.rules, '_blank');
                                            }}
                                        >
                                            Rules
                                        </Button>
                                    }
                                    {tournamentInfo?.legal &&
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            sx={{ paddingX: 3.5, paddingY: 1, m: 0.5 }}
                                            onClick={() => {
                                                window.open(tournamentInfo?.legal, '_blank');
                                            }}
                                        >
                                            Legal
                                        </Button>
                                    }
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ paddingX: 3.5, paddingY: 1, m: 0.5, mr: 0 }}
                                        onClick={() => {
                                            window.open("https://gridirongames.org/results.php", '_blank');
                                        }}
                                    >
                                        View Standings
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={userType === 'admin' ? 2 : 0}>
                                <>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item md={3} xs={12}>
                                                <CustomInputLabel sx={inputLabelStyle}>
                                                    Sort By Date :
                                                </CustomInputLabel>
                                                <CustomTextField
                                                    id='filterDate'
                                                    name='filterDate'
                                                    type="date"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) =>
                                                        handleFilterChange('filterDate', e.target.value)
                                                    }
                                                    value={filterDate}
                                                    sx={{
                                                        mb: 0,
                                                        width: "100%",
                                                        "& .MuiOutlinedInput-root": {
                                                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                                            width: "100%",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    inputProps={{
                                                        min: tournamentInfo?.start_date,
                                                        max: tournamentInfo?.end_date
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <CustomInputLabel sx={inputLabelStyle}>
                                                    Sort By Division :
                                                </CustomInputLabel>
                                                <Select
                                                    name='filterDivision'
                                                    onChange={(e) =>
                                                        handleFilterChange('filterDivision', e.target.value)
                                                    }
                                                    value={filterDivision}
                                                    input={
                                                        <OutlinedInput
                                                            sx={selectionInputStyle}
                                                        />
                                                    }
                                                    MenuProps={MenuProps}
                                                    inputProps={{
                                                        "aria-label": "Without label",
                                                    }}
                                                    className="MuiSelect-select"
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            padding: "10.5px 14px"
                                                        },
                                                        width: "100%",
                                                        //maxWidth: "300px"
                                                    }}
                                                    displayEmpty
                                                    renderValue={(value) => {
                                                        if (value) {
                                                            let selected = divisionData.find((division) => division?.id === value);
                                                            if (selected) {
                                                                return selected?.name
                                                            }
                                                        } else {
                                                            return (
                                                                <Typography
                                                                    variant="p"
                                                                    fontWeight={400}
                                                                    fontSize={14}
                                                                    sx={{ opacity: 0.5 }}
                                                                >
                                                                    Select Division...
                                                                </Typography>
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {divisionData?.map((division) => (
                                                        <MenuItem key={division?.id} value={division?.id}>
                                                            {division?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item md={3} sm={6} xs={12}>
                                                <CustomInputLabel sx={inputLabelStyle}>
                                                    Sort By Grade :
                                                </CustomInputLabel>
                                                <Select
                                                    name='filterGrde'
                                                    onChange={(e) =>
                                                        handleFilterChange('filterGrde', e.target.value)
                                                    }
                                                    value={filterGrde}
                                                    input={
                                                        <OutlinedInput
                                                            sx={selectionInputStyle}
                                                        />
                                                    }
                                                    MenuProps={MenuProps}
                                                    inputProps={{
                                                        "aria-label": "Without label",
                                                    }}
                                                    className="MuiSelect-select"
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            padding: "10.5px 14px"
                                                        },
                                                        width: "100%",
                                                        //maxWidth: "300px"
                                                    }}
                                                    displayEmpty
                                                    renderValue={(value) => {
                                                        if (value) {
                                                            let selected = gradeData.find((grade) => grade?.id === value);
                                                            if (selected) {
                                                                return selected?.name
                                                            }
                                                        } else {
                                                            return (
                                                                <Typography
                                                                    variant="p"
                                                                    fontWeight={400}
                                                                    fontSize={14}
                                                                    sx={{ opacity: 0.5 }}
                                                                >
                                                                    Select Grade...
                                                                </Typography>
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {gradeData?.map((grade) => (
                                                        <MenuItem key={grade?.id} value={grade?.id}>
                                                            {grade?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item md={3} xs={12} sx={{
                                                mt: 3.3,
                                                [theme.breakpoints.down('md')]: {
                                                    mt: 0,
                                                }
                                            }}>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    sx={{ fontSize: "14px" }}
                                                    onClick={() => handleClearFilter()}
                                                >
                                                    Reset Filter
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                                        <Divider></Divider>
                                    </Grid> */}
                                </>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card sx={{ mt: 2 }}>
                        <CardContent sx={{
                            [theme.breakpoints.down('sm')]: {
                                p: 2,
                            }
                        }}>
                            {
                                userType === 'admin' ?
                                    <>

                                        {
                                            inputFields.length > 0 ?
                                                <>
                                                    <Grid container spacing={1} sx={{ m: "0 -0.5rem", width: "auto" }}>
                                                        {inputFields.map((inputField, index) => (
                                                            <>
                                                                <Grid item lg={4} md={4} sm={6} xs={12} key={index} sx={{ p: "0.5rem !important", }}>
                                                                    <Grid container spacing={2} sx={{ border: '1px solid #dedede', p: 2, pl: 0, pt: 0, borderRadius: '0.3rem', height: '100%', width: '100%', m: 0, }}>
                                                                        <Grid item xs={8}>
                                                                            <Select
                                                                                name={`team_one-${index}`}
                                                                                onChange={(e) =>
                                                                                    handleFieldChange(index, 'team_one', e.target.value)
                                                                                }
                                                                                value={inputField.team_one}
                                                                                input={
                                                                                    <OutlinedInput
                                                                                        sx={selectionInputStyle}
                                                                                    />
                                                                                }
                                                                                MenuProps={MenuProps}
                                                                                inputProps={{
                                                                                    "aria-label": "Without label",
                                                                                }}
                                                                                className="MuiSelect-select"
                                                                                sx={{
                                                                                    "& .MuiSelect-select": {
                                                                                        padding: "10.5px 14px"
                                                                                    },
                                                                                    width: "100%",
                                                                                    //maxWidth: "300px"
                                                                                }}
                                                                                displayEmpty
                                                                                renderValue={(value) => {
                                                                                    if (value) {
                                                                                        let selected = tournamentTeamsData?.find((team) => team?.id === value);
                                                                                        if (selected) {
                                                                                            return selected?.title
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <Typography
                                                                                                variant="p"
                                                                                                fontWeight={400}
                                                                                                fontSize={14}
                                                                                                sx={{ opacity: 0.5 }}
                                                                                            >
                                                                                                Select Team1...
                                                                                            </Typography>
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {tournamentTeamsData?.map((team) => (
                                                                                    <MenuItem key={team?.id} value={team?.id}>
                                                                                        {team?.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Grid item xs={4} sx={{}}>
                                                                            <CustomTextField
                                                                                id={inputField.idObj}
                                                                                name={`score_one-${index}`}
                                                                                type="number"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={(e) =>
                                                                                    handleFieldChange(index, 'score_one', e.target.value)
                                                                                }
                                                                                value={inputField.score_one}
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiOutlinedInput-root": {
                                                                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                                                                        width: "100%",
                                                                                        boxShadow: "none",
                                                                                        "& .MuiInputBase-input": {
                                                                                            height: "1.2375em",
                                                                                        }
                                                                                    },
                                                                                }}
                                                                            />
                                                                            {(isScoreOneValueGTzero && inputField?.score_one < 0) && (
                                                                                <FormHelperText error>Score One Value must be greater than to 0</FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item xs={8} sx={{}}>
                                                                            <Select
                                                                                name={`team_two-${index}`}
                                                                                onChange={(e) =>
                                                                                    handleFieldChange(index, 'team_two', e.target.value)
                                                                                }
                                                                                value={inputField.team_two}
                                                                                input={
                                                                                    <OutlinedInput
                                                                                        sx={selectionInputStyle}
                                                                                    />
                                                                                }
                                                                                MenuProps={MenuProps}
                                                                                inputProps={{
                                                                                    "aria-label": "Without label",
                                                                                }}
                                                                                className="MuiSelect-select"
                                                                                sx={{
                                                                                    "& .MuiSelect-select": {
                                                                                        padding: "10.5px 14px"
                                                                                    },
                                                                                    width: "100%",
                                                                                    //maxWidth: "300px"
                                                                                }}
                                                                                displayEmpty
                                                                                renderValue={(value) => {
                                                                                    if (value) {
                                                                                        let selected = tournamentTeamsData?.find((team) => team?.id === value);
                                                                                        if (selected) {
                                                                                            return selected?.title
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <Typography
                                                                                                variant="p"
                                                                                                fontWeight={400}
                                                                                                fontSize={14}
                                                                                                sx={{ opacity: 0.5 }}
                                                                                            >
                                                                                                Select team2...
                                                                                            </Typography>
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {tournamentTeamsData?.map((team) => (
                                                                                    <MenuItem key={team?.id} value={team?.id}>
                                                                                        {team?.title}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Grid item xs={4} sx={{}}>
                                                                            <CustomTextField
                                                                                id={inputField.idObj}
                                                                                name={`score_two-${index}`}
                                                                                type="number"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                onChange={(e) =>
                                                                                    handleFieldChange(index, 'score_two', e.target.value)
                                                                                }
                                                                                value={inputField.score_two}
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiOutlinedInput-root": {
                                                                                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                                                                        width: "100%",
                                                                                        boxShadow: "none",
                                                                                    },
                                                                                }}
                                                                            />
                                                                            {(isScoreTwoValueGTzero && inputField?.score_two < 0) && (
                                                                                <FormHelperText error>Score Two Value must be greater than to 0</FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid itm xs={12} sx={{ mt: 1.5, pl: 1.5, }}>
                                                                            <Box sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                p: 0.5,
                                                                            }}>
                                                                                <Typography variant="h4" component="div" sx={{ whiteSpace: "nowrap", }}>
                                                                                    Division :
                                                                                </Typography>
                                                                                <Typography component="span" sx={{ ml: 1, fontSize: '16px', textAlign: 'left' }}>
                                                                                    {inputField.division?.name || 'N/A'}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid itm xs={12} sx={{ pl: 1.5, }}>
                                                                            <Box sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                p: 0.5,
                                                                            }}>
                                                                                <Typography variant="h4" component="div" sx={{ whiteSpace: "nowrap", }}>
                                                                                    Grade :
                                                                                </Typography>
                                                                                <Typography component="span" sx={{ ml: 1, fontSize: '16px', textAlign: 'left' }}>
                                                                                    {inputField.grade?.name || 'N/A'}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>

                                                                        <Grid item xs={12} sx={{ mb: 2, }}>
                                                                            <CustomInputLabel sx={inputLabelStyle}>
                                                                                Select Date :
                                                                            </CustomInputLabel>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    id={inputField.idObj}
                                                                                    name={`date-${index}`}
                                                                                    onChange={(newVal) => handleFieldChange(index, 'date', newVal)}
                                                                                    value={inputField.date}
                                                                                    views={['year', 'month', 'day']}
                                                                                    sx={{
                                                                                        "&.MuiTextField-root ": {
                                                                                            width: '100%',
                                                                                        }
                                                                                    }}
                                                                                    minDate={dayjs(tournamentInfo?.start_date)}
                                                                                    // maxDate={dayjs.min(dayjs(new Date()), dayjs(tournamentInfo?.end_date))}
                                                                                    maxDate={today.isAfter(dayjs(tournamentInfo?.end_date)) ? dayjs(tournamentInfo?.end_date) : today}
                                                                                />
                                                                            </LocalizationProvider>
                                                                            {/* <CustomTextField
                                                                                        id={inputField.idObj}
                                                                                        name={`date-${index}`}
                                                                                        type="date"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        onChange={(e) =>
                                                                                            handleFieldChange(index, 'date', e.target.value)
                                                                                        }
                                                                                        value={inputField.date}
                                                                                        sx={{
                                                                                            mb: 1,
                                                                                            width: "100%",
                                                                                            "& .MuiOutlinedInput-root": {
                                                                                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                                                                                width: "100%",
                                                                                                boxShadow: "none",
                                                                                            },
                                                                                        }}
                                                                                        inputProps={{
                                                                                            min: tournamentInfo?.start_date,
                                                                                            max: tournamentInfo?.end_date
                                                                                        }}
                                                                                    /> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid >
                                                            </>
                                                        ))}
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="h4" component="div">
                                                                No Data Found
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                        }

                                    </>
                                    :
                                    <>
                                        {
                                            inputFields.length > 0 ?
                                                <>
                                                    <Grid container spacing={1} sx={{ m: "0 -0.5rem", width: "auto" }}>
                                                        {
                                                            inputFields.map((inputField, index) => (
                                                                <>
                                                                    <Grid item lg={3} md={4} sm={6} xs={12} key={index} sx={{ p: "0.5rem !important" }}>
                                                                        <Grid container sx={{ border: '1px solid #dedede', p: 2, borderRadius: '0.3rem', height: '100%', }}>
                                                                            <Grid itm xs={10}>
                                                                                <Box sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "left",
                                                                                    p: 0.5,
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <Typography variant="h4" component="div">
                                                                                        {inputField.day || 'N/A'},
                                                                                    </Typography>
                                                                                    <Typography variant="h4" component="div" sx={{ ml: 1, fontSize: '16px' }}>
                                                                                        {inputField.date || 'N/A'}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            {/* <Grid itm xs={12} sx={{ mt: 1 }}>
                                                                                    <Box sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        p: 0.5,
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                        <Typography variant="h4" component="div">
                                                                                            Team Name
                                                                                        </Typography>
                                                                                        <Typography variant="h4" component="div">
                                                                                            Score
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid> */}
                                                                            <Grid itm xs={12}>
                                                                                <Box sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    p: 0.5,
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <Typography component="span" sx={{ fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.team_one || 'N/A'}
                                                                                    </Typography>
                                                                                    <Typography component="span" sx={{ fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.score_one}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid itm xs={12}>
                                                                                <Box sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    p: 0.5,
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <Typography component="span" sx={{ fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.team_two || 'N/A'}
                                                                                    </Typography>
                                                                                    <Typography component="span" sx={{ fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.score_two}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                                                                            </Grid>
                                                                            <Grid itm xs={12}>
                                                                                <Box sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "left",
                                                                                    p: 0.5,
                                                                                }}>
                                                                                    <Typography variant="h4" component="div" sx={{ whiteSpace: "nowrap", }}>
                                                                                        Division :
                                                                                    </Typography>
                                                                                    <Typography component="span" sx={{ ml: 1, fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.division?.name || 'N/A'}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid itm xs={12}>
                                                                                <Box sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "left",
                                                                                    p: 0.5,
                                                                                }}>
                                                                                    <Typography variant="h4" component="div" sx={{ whiteSpace: "nowrap", }}>
                                                                                        Grade :
                                                                                    </Typography>
                                                                                    <Typography component="span" sx={{ ml: 1, fontSize: '16px', textAlign: 'left' }}>
                                                                                        {inputField.grade?.name || 'N/A'}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid >
                                                                </>
                                                            ))
                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="h4" component="div">
                                                                No Data Found
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                        }

                                    </>
                            }


                            {
                                userType === 'admin' &&
                                <Grid item xs={12}>
                                    <Box sx={{
                                        pl: 1,
                                        pt: 1,
                                    }}>
                                        <Button
                                            variant="contained"
                                            type="button"
                                            sx={{ fontSize: "14px" }}
                                            onClick={() => handleAddInputField()}
                                        >
                                            Add More Score
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} mb={2}>
                    <Card>
                        <CardContent>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ paddingX: 3.5, paddingY: 1 }}
                                    onClick={() => {
                                        if (userType === 'admin' || userType === 'teammanager' || userType === 'teamcoach') {
                                            navigate(
                                                Tournament_Teams_Page,
                                                {
                                                    state:
                                                    {
                                                        tournamentId: tournamentInfo?.id,
                                                        tournamentName: tournamentInfo?.name
                                                    }
                                                }
                                            );
                                        } else if (userType === 'player' || userType === 'parent') {
                                            navigate(`/admin-tournament-team/${id}`);
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                                {
                                    userType === 'admin' &&
                                    <Box display="flex" alignItems="center">
                                        <Button
                                            variant="contained"
                                            sx={{ paddingX: 3.5, paddingY: 1 }}
                                            onClick={() => {
                                                handleSaveScore()
                                            }}
                                        >
                                            Save Score
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    )
}
export default ViewAndAddScore;
