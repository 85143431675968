import {
  Avatar,
  Button,
  Container,
  Paper,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  SET_TOAST,
  deleteAdminUser,
  getUsers,
  getAllUsersToCSV,
  getRoleTypes,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ActionCard from "../../components/Common/ActionCard";
import { Box, useTheme } from "@mui/system";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import MuiTable from "../../components/Common/mui-table/MuiTable";
import { useNavigate } from "react-router-dom";
import {
  USER_DETAILS_PAGE,
  Team_Coach,
  Team_Manager,
} from "../../routes/constants";
import CustomTextField from "../../components/CustomTextField";
import useDebounce from "../../hooks/useDebounce";
import { styled } from "@mui/system";
import { phoneFormat } from "../../utils/functions";

const BoldHeader = styled("div")({
  fontWeight: "bold",
});

const Users = () => {
  const tableRef = useRef(null);
  const [users, setUsers] = useState();
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paginationOptions, setPaginationOptions] = useState({
    totalMainDocs: 0,
    totalDocs: 0,
    offset: 0,
    pageNum: 0,
    limit: 10,
    totalPages: 0,
    currentPage: 1,
    hasPrevPage: false,
    hasNextPage: false,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const debounceSearchValue = useDebounce(searchUser, 600);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;

  const getUsersList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
      searchKeyword: searchUser,
      usertype: selectedUserType === "all" ? "" : selectedUserType,
    };
    let response = await getUsers(dispatch, params);
    if (response?.ok) {
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
      setUsers(response?.data?.data ?? []);
    }
  };

  const getAllUsersExportToCSV = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllUsersToCSV(params, dispatch);
    if (response?.ok) {
      downloadFile(response?.data);
      dispatch({
        type: SET_TOAST,
        data: {
          type: "success",
          message: response.message,
        },
      });
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getUsersList();
    }
  }, [
    paginationOptions?.offset,
    paginationOptions?.limit,
    state?.user?.token,
    state?.org?.org?.token,
    debounceSearchValue,
    selectedUserType,
  ]);

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getRoleList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getRoleList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getRoleTypes(params, dispatch);
    if (response?.ok) {
      setRoleList(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const cellDataFields = [
    //"sub_organization",
    "first_name",
    "last_name",
    "email",
    "mobile",
    "user_type",
  ];
  const playerCellDataFields = [
    // "sub_organization",
    "first_name",
    "last_name",
    "email",
    "mobile",
    "user_type",
  ];
  const columns = [
    // {
    //   field: "sub_organization",
    //   headerName: "SUB-ORGANIZATION",
    // },
    {
      field: "first_name",
      minWidth: 150,
      headerName: <BoldHeader>FIRST NAME</BoldHeader>,
      // headerName: (
      //   <>
      //     <BoldHeader>
      //       First Name
      //     </BoldHeader>
      //   </>
      // ),
    },
    {
      field: "last_name",
      minWidth: 150,
      headerName: <BoldHeader>LAST NAME</BoldHeader>,
    },
    {
      field: "email",
      minWidth: 150,
      // headerName: "Email",
      headerName: <BoldHeader>EMAIL</BoldHeader>,
    },
    {
      field: "mobile",
      minWidth: 150,
      headerName: <BoldHeader>MOBILE</BoldHeader>,
    },
    {
      field: "user_type",
      minWidth: 150,
      headerName: <BoldHeader>USER TYPE</BoldHeader>,
    },
    {
      field: "actions",
      minWidth: 150,
      headerName: <BoldHeader>ACTIONS</BoldHeader>,
    },
  ];

  const handleDeleteAdminClick = () => {
    handleClose();
    setShowDeletePopup(true);
  };

  const handleConfirm = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteAdminUser(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message },
      });
      setShowDeletePopup(false);
      getUsersList();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleViewUserClick = () => {
    handleClose();
    navigate(USER_DETAILS_PAGE, {
      state: {
        selectedUserData: selectedDataGridRow,
      },
    });
  };

  const CustomDetailsComponent = ({
    list,
    customCell,
    cellNameToCustomCell,
    open,
    rowClick,
  }) => {
    const userType = list?.user_type || "";
    const isPlayer = userType === "player";
    // const dataList = isPlayer
    //   ? list?.parent?.length
    //     ? list?.parent
    //     : []
    //   : list?.athlete?.length
    //     ? list?.athlete
    //     : [];
    const dataList = list?.athlete?.length ? list?.athlete : [];
    return (
      // <Box py={2}>
      //   <TableContainer component={Paper}>
      // <Table
      //   sx={{
      //     // display: "flex",
      //     // alignItems: "center",
      //     // justifyContent: "center",
      //     // flexDirection: "column",
      //     width: "100%",
      //   }}
      // >
      <>
        {dataList?.length ? (
          dataList?.map((user) => (
            // <TableRow
            //   sx={{
            //     width: "100%",
            //     // display: "flex",
            //     // alignItems: "center",
            //     // justifyContent: "space-evenly",
            //     "& > *": { borderBottom: "unset" },
            //   }}
            // >
            <TableRow sx={{ display: open ? "table-row" : "none" }}>
              <TableCell
                sx={{ borderBottom: "none", width: "50px" }}
              ></TableCell>
              <TableCell
                sx={{ borderBottom: "none", width: "50px" }}
              ></TableCell>
              {playerCellDataFields?.map((val, index) => {
                if (cellNameToCustomCell === val) {
                  return customCell(user, {
                    opacity: 0.5,
                    borderBottom: "none",
                    fontWeight: 500,
                    // padding: 0.8
                  });
                } else {
                  return (
                    <>
                      <TableCell
                        sx={{
                          fontWeight: 500,
                          opacity: 0.5,
                          borderBottom: "none",
                          // padding: 0.8
                        }}
                      >
                        {typeof user[val] === "string" ||
                        typeof user[val] === "number" ? (
                          val === "mobile" ? (
                            <>{phoneFormat(user[val])}</>
                          ) : (
                            user[val]
                          )
                        ) : typeof user[val] === "object" ? (
                          <Box display="flex" flexDirection="column">
                            {user[val]?.length
                              ? user[val]?.map((subOrg) => (
                                  <Typography
                                    sx={{ pt: 0.4 }}
                                    fontFamily={"Poppins"}
                                    fontSize={13}
                                    fontWeight={500}
                                    color={
                                      theme?.palette?.text?.containerTextLabel
                                    }
                                  >
                                    {subOrg?.title ?? "N/A"},
                                  </Typography>
                                ))
                              : "N/A"}
                          </Box>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    </>
                  );
                }
              })}
            </TableRow>
          ))
        ) : (
          <></>
          // <Box>
          //   <Typography
          //     variant="h6"
          //     fontWeight={500}
          //     fontSize={14}
          //     mt={2}
          //     color={theme?.palette?.text?.containerTextLabel}
          //     sx={{ textAlign: "center", p: 3 }}
          //   >
          //     {isPlayer ? "No Parent or Gaurdian" : "No Player"}
          //   </Typography>
          // </Box>
        )}
      </>
      // </Table>
      // {/* //   </TableContainer> */}
      // </Box>
    );
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = ""; // You can specify the desired filename here

    document.body.appendChild(link);
    link.click();

    // Remove the link from the document after download
    document.body.removeChild(link);
  };

  const handleActions = (rowData) => {
    return (
      <Box>
        <SettingsIcon
          onClick={handleClick}
          sx={{ fontSize: 22, cursor: "pointer" }}
          color="primary"
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            p: 1,
            "& .MuiPaper-root": {
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
            },
          }}
        >
          <ActionCard title={"User"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="contained"
                fullWidth
                sx={{ paddingX: 5.5, paddingY: 1 }}
                onClick={() => {
                  handleViewUserClick();
                }}
              >
                View User Details
              </Button>
              {/* <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingX: 5.5,
                      paddingY: 1,
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteAdminClick}
                  >
                    Delete User
                  </Button> */}
            </Box>
          </ActionCard>
        </Popover>
      </Box>
    );
  };

  const gridToolbar = () => {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          [theme.breakpoints.down("md")]: {
            mb: 2,
          },
        }}
      >
        <Grid item md={6} xs={12}>
          <Select
            name="selectedUserType"
            onChange={(e) => setSelectedUserType(e.target.value)}
            value={selectedUserType}
            input={<OutlinedInput sx={{ height: 40, width: 200 }} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  // width: "100%",
                  width: "auto",
                },
              },
            }}
            inputProps={{
              "aria-label": "Without label",
            }}
            className="MuiSelect-select"
            sx={{
              mr: 2,
              "& .MuiSelect-select": {
                padding: "11.5px 14px",
              },
              width: "100%",
              // width: "auto",
            }}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                let selected = roleList?.find((role) => role?.value === value);
                if (selected) {
                  return selected?.name;
                }
                return "All";
              } else {
                return (
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={14}
                    sx={{ opacity: 0.5 }}
                  >
                    Filter by User Type
                  </Typography>
                );
              }
            }}
          >
            <MenuItem value="all">All</MenuItem>
            {roleList?.map((role) => (
              <MenuItem key={role?.value} value={role?.value}>
                {role?.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomTextField
            id="searchUser"
            name="searchUser"
            placeholder="Search..."
            variant="outlined"
            size="small"
            onChange={(e) => setSearchUser(e.target.value)}
            value={searchUser}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box mb={4}>
      <MuiTable
        headerLabel={"Users"}
        data={users}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={users ? false : true}
        isShowClientFilter={false}
        cellDataFields={cellDataFields}
        onClickAddNew={() => {
          getAllUsersExportToCSV();
        }}
        rightBtnLabel="Export To CSV"
        rowClick={(data) => setSelectedDataGridRow(data)}
        customCell={(rowData, cellProps) => {
          const userType = rowData?.user_type || "";
          return (
            <TableCell sx={{ fontWeight: 500, ...cellProps }}>
              <Box display="flex" alignItems="center">
                {/* <Avatar
                  sx={{
                    bgcolor: userType === "player" ? "#319FA8" : "#1E2549",
                    color: "primary.contrastText",
                    marginRight: 1,
                    width: 16,
                    height: 16,
                    fontSize: 10,
                  }}
                >
                  {userType === "player" ? "A" : "P"}
                </Avatar> */}
                {rowData?.first_name ?? "N/A"}
              </Box>
            </TableCell>
          );
        }}
        cellNameToCustomCell="first_name"
        customerDetailsComponent={(props) => (
          <CustomDetailsComponent {...props} />
        )}
        handleChangePage={(e, pageNum) => {
          let offset = pageNum <= 0 ? 0 : `${pageNum}0`;
          offset = Number(offset);
          setPaginationOptions((prevState) => {
            return {
              ...prevState,
              // offset: offset,
              offset: pageNum === 0 ? 0 : pageNum * Number(prevState.limit),
              currentPage: paginationOptions?.currentPage,
              pageNum,
            };
          });
        }}
        handleChangeRowsPerPage={(e) => {
          let limit = e?.target?.value ?? 10;
          setPaginationOptions((prevState) => {
            return {
              ...prevState,
              limit,
            };
          });
        }}
        pageNum={
          // typeof paginationOptions?.offset === "string" ?
          //   Number(paginationOptions?.offset) :
          //     paginationOptions?.offset
          paginationOptions?.currentPage
        }
        rowsPerPage={paginationOptions?.limit}
        totalRows={paginationOptions?.totalMainDocs}
        isShowUsersBtn={true}
        gridToolbarButtons={() => gridToolbar()}
        actions={(rowData) => handleActions(rowData)}
      />
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"User"}
          message={"Are you sure you want to delete this user?"}
          open={showDeletePopup}
          handleClose={() => setShowDeletePopup(false)}
          handleConfirm={handleConfirm}
        />
      )}
    </Box>
  );
};

export default Users;
