import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  ListItemText, FormControl, InputLabel, InputAdornment, IconButton
} from "@mui/material";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SET_TOAST, createTeamByTeamManagerAction, getTeamManagerAction, getTournaments, uploadFileAction, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getFileNameFromFirebaseUrl } from "../../utils/functions";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const EditRegisteredTeamDetails = ({
  open,
  handleClose,
  selectedTeam,
  tournamentId
}) => {

  const [teamManagerList, setTeamManagerList] = useState([]);
  const [divisionItems, setDivisionItems] = useState([]);
  const [divisionGradeList, setDivisionGradeList] = useState([]);
  const [selectedTeamManager, setSelectedTeamManager] = useState([]);
  const [showTeamManagerSelectionRequiredError, setShowTeamManagerSelectionRequiredError] = useState(false);
  const theme = useTheme();
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = state?.user?.user?.user_type;
  const location = useLocation();
  const hasTeamControlAccess = userType === "teammanager" || userType === "teamcoach" || userType === "admin" || userType === "superadmin" ? true : false;
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const selectionInputStyle = {
    width: 1,
    height: 40,
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const [thumbnailImage, setThumbnailImage] = useState("");

  const validationSchema = Yup.object({
    division: Yup.string().required("Division selection is required"),
    teamName: Yup.string().required("Team name is required"),
    gradeOrAgeGroup: Yup.string().required("Grade or age group selection is required"),
  });

  const formik = useFormik({
    initialValues: {
      division: "",
      tournament: "",
      teamName: selectedTeam?.title ?? "",
      gradeOrAgeGroup: "",
      largeLogo: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedTeamManager?.length) {
        setShowTeamManagerSelectionRequiredError(true);
        return;
      }

      const coachManagerFilterData = teamManagerList.filter(x => selectedTeamManager.includes(x.id));
      const team_manager_and_team_coach = coachManagerFilterData.map(item => ({
        id: item.id,
        user_type: item.user_type
      }));

      let bodyParams = {
        id: selectedTeam?.id,
        title: values?.teamName ?? "",
        division: values?.division,
        grade: values?.gradeOrAgeGroup,
        // team_coach: [],
        // team_manager: selectedTeamManager ?? [],
        team_manager_and_team_coach: team_manager_and_team_coach ?? [],
        tournament: selectedTeam?.tournament?.id ?? "",
        largeLogo: values?.largeLogo
      }
      editTeam(bodyParams);
    },
  });

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getTournamentsList();
      getTeamManager();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  useEffect(() => {
    if (selectedTeam) {
      formik.setFieldValue("division", selectedTeam?.division?.id);
      if (selectedTeam?.largeLogo) {
        formik.setFieldValue("largeLogo", selectedTeam?.largeLogo)
        setThumbnailImage(getFileNameFromFirebaseUrl(selectedTeam?.largeLogo))
      }
    }
  }, [selectedTeam]);

  const getTournamentsList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: 0,
      limit: 1000
    };
    let response = await getTournaments(params, dispatch);
    if (response?.ok) {
      let resData = response?.data?.data ?? [];
      if (resData?.length) {
        const selected = resData?.find((tournament) => tournament?.id === tournamentId);
        if (selected?.division_grade?.length > 0) {
          setDivisionItems(selected?.division_grade);
          if (selectedTeam?.grade?.id) {
            const selectedDivision = selected?.division_grade?.find((division) => division?.id === selectedTeam?.division?.id);
            const selectedDivisionGrade = selectedDivision?.grades ? selectedDivision?.grades?.find((grade) => grade?.id === selectedTeam?.grade?.id) : "";
            setDivisionGradeList(selectedDivision?.grades ?? []);
            formik.setFieldValue("gradeOrAgeGroup", selectedDivisionGrade?.id);
          }
        } else {
          setDivisionItems([]);
        }
      }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong for tournament data" }
      })
    }
  }

  const getTeamManager = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let response = await getTeamManagerAction(params);
    if (response?.ok) {
      let dataList = response?.data;
      if (selectedTeam?.team_manager_and_team_coach?.length) {
        let teamIds = selectedTeam?.team_manager?.map((team) => team?.id);
        let coachIds = selectedTeam?.team_coach?.map((team) => team?.id);

        setSelectedTeamManager([...teamIds, ...coachIds]);
      }
      setTeamManagerList(dataList ?? []);
    }
  }

  const editTeam = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await createTeamByTeamManagerAction(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response.message ?? "Success" }
      })
      formik.resetForm();
      setSelectedTeamManager([]);
      handleClose(false);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
      })
    }
  };

  const hanleDivisionChange = (event) => {
    const divisionId = event.target.value;
    formik.setFieldValue('division', divisionId)
    const selected = divisionItems?.find((division) => division?.id === divisionId);
    if (selected?.grades.length > 0) {
      setDivisionGradeList(selected?.grades)
    } else {
      setDivisionGradeList([])
    }
  }

  const handleChangeTeamManagerSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTeamManager(value);
    setShowTeamManagerSelectionRequiredError(false);
  };

  const uploadImage = async (file) => {
    if (file) {
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      let formData = new FormData();
      formData.append("files", file);
      formData.append("type", "Team");
      formData.append("fileType", "Thumbnail");
      formData.append("userId", state?.user?.user?.id);
      let response = await uploadFileAction(dispatch, params, formData);
      if (response?.ok) {
        setThumbnailImage(getFileNameFromFirebaseUrl(response?.url))
        formik.setFieldValue("largeLogo", response?.url)
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response.message ?? "Logo Uploaded Successfully" }
        })
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
        })
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => handleClose(true)}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={2.5}
              pt={1}
              sx={{
                [theme.breakpoints.down('md')]: {
                  px: 0,
                }
              }}
            >
              <Typography
                variant="h4"
                fontWeight={700}
                fontSize={20}
                color={theme?.palette?.text?.containerTextLabel}
              >
                Edit Team Details
              </Typography>
            </Box>
          </DialogTitle>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
          <DialogContent sx={{
            p: 5,
            [theme.breakpoints.down('md')]: {
              p: 3,
            }
          }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box>
                    <CustomInputLabel sx={inputLabelStyle}>
                      TEAM NAME
                    </CustomInputLabel>
                    <TextField
                      name="teamName"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.teamName}
                      disabled={!hasTeamControlAccess}
                      sx={{
                        // width: 300,
                        width: 1,
                        ".MuiInputBase-input": {
                          padding: "10.5px 14px !important",
                          outline: "none",
                        },
                      }}
                      // className="input-style"
                      placeholder="Enter team name..."
                      error={Boolean(formik.touched.teamName && formik.errors.teamName)}
                    />
                    {Boolean(formik.touched.teamName && formik.errors.teamName) && (
                      <FormHelperText error>{formik.errors.teamName}</FormHelperText>
                    )}
                  </Box>
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel sx={inputLabelStyle}>
                    Division
                  </CustomInputLabel>
                  <Select
                    name="division"
                    labelId="org-filter"
                    onBlur={formik.handleBlur}
                    onChange={(e) => hanleDivisionChange(e)}
                    disabled={!hasTeamControlAccess}
                    value={formik.values.division}
                    input={
                      <OutlinedInput
                        sx={selectionInputStyle}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    renderValue={(value) => {
                      if (value) {
                        let selected = divisionItems?.find((division) => division?.id === value);
                        if (selected) {
                          return selected?.name
                        }
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select Division...
                          </Typography>
                        );
                      }
                    }}
                    className="MuiSelect-select"
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10.5px 14px"
                      },
                      width: 1,
                      // maxWidth: "300px"
                    }}
                  >
                    {divisionItems?.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                        {division.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.touched.division && formik.errors.division) && (
                    <FormHelperText error>{formik.errors.division}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* {divisionGradeList.length > 0 && */}
                  <>
                    <CustomInputLabel sx={inputLabelStyle}>
                      GRADE LEVEL
                    </CustomInputLabel>
                    <Select
                      disabled={hasTeamControlAccess ? divisionGradeList.length > 0 ? false : true : true}
                      name="gradeOrAgeGroup"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.gradeOrAgeGroup}
                      input={
                        <OutlinedInput
                          sx={selectionInputStyle}
                        />
                      }
                      MenuProps={MenuProps}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "10.5px 14px"
                        },
                        width: 1,
                        // maxWidth: "300px"
                      }}
                      displayEmpty
                      renderValue={(value) => {
                        if (value) {
                          let selected = divisionGradeList?.find((grade) => grade?.id === value);
                          if (selected) {
                            return selected?.name
                          }
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select grade...
                            </Typography>
                          );
                        }
                      }}
                    >
                      {divisionGradeList?.map((val) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(formik.touched.gradeOrAgeGroup && formik.errors.gradeOrAgeGroup) && (
                      <FormHelperText error>{formik.errors.gradeOrAgeGroup}</FormHelperText>
                    )}
                  </>
                  {/* } */}
                </Grid>

                <Grid item md={6} xs={12}>
                  <>
                    <CustomInputLabel sx={inputLabelStyle}>
                      Team Coach / Manager
                    </CustomInputLabel>
                    <Select
                      name="teamManagerSelection"
                      multiple
                      displayEmpty
                      value={selectedTeamManager}
                      onChange={handleChangeTeamManagerSelection}
                      disabled={!hasTeamControlAccess}
                      input={
                        <OutlinedInput
                          sx={{
                            ...selectionInputStyle,
                            //  width: 235
                          }}
                        />
                      }
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      renderValue={(selected) => {
                        if (selected?.length && teamManagerList?.length) {
                          let titles = [];
                          teamManagerList?.filter((tm) => {
                            if (selected.includes(tm?.id)) {
                              titles.push(`${tm?.first_name} ${tm?.last_name}`);
                            }
                          });
                          if (titles?.length) {
                            return titles.join(", ");
                          }
                          return selected?.join(", ");
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select team coach/manager...
                            </Typography>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "10.5px 14px",
                        },
                        width: 1,
                        // maxWidth: "300px",
                      }}
                    >
                      <MenuItem disabled value="">
                        Select team coach/manager...
                      </MenuItem>
                      {teamManagerList?.map((tm) => (
                        <MenuItem
                          // disabled={state?.user?.user?.id == tm?.id}
                          key={tm?.id}
                          value={tm?.id}
                        >
                          <Checkbox
                            checked={selectedTeamManager.indexOf(tm?.id) > -1}
                          // disabled={state?.user?.user?.id == tm?.id}
                          />
                          <ListItemText primary={`${tm?.first_name} ${tm?.last_name}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                  {showTeamManagerSelectionRequiredError && (
                    <FormHelperText error>Team coach or manager selection is required</FormHelperText>
                  )}
                  {/* {selectedTeam?.team_manager && selectedTeam?.team_manager?.length ?
                    <Box
                      sx={{ height: 150, overflowY: "auto", overflowX: "none" }}
                      my={2}
                      bgcolor="#F4F4F4"
                    >
                      {selectedTeam?.team_manager?.map((teamManager) => (
                        <Box key={teamManager?.id}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              fontSize={14}
                              color={theme?.palette?.text?.containerTextLabel}
                              sx={{ ml: 2 }}
                            >
                              {teamManager?.first_name} {teamManager?.last_name}
                            </Typography>
                          </Box>
                          <Box
                            width={1}
                            height={"1px"}
                            bgcolor={"gray"}
                            sx={{ opacity: 0.25 }}
                          ></Box>
                        </Box>
                      ))}
                    </Box>
                    : null} */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInputLabel sx={inputLabelStyle}>
                    Team Logo
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    // error={Boolean(
                    //   formik.touched.largeLogo && formik.errors.largeLogo,
                    // )}
                    sx={{
                      width: 1,
                      "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                        height: "45px",
                        padding: "12.5px 10px",
                      },
                      "& .MuiIconButton-root": {
                        background: "transparent",
                        borderRadius: "0 5px 5px 0",
                        left: 16,
                      },
                    }}
                  >
                    <InputLabel
                      htmlFor="sub-org-team-logo-lg"
                      shrink={false}
                      sx={{
                        fontFamily: "'Poppins', sans-serif, Arial",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      {thumbnailImage ? thumbnailImage : "Upload image..."}
                    </InputLabel>
                    <OutlinedInput
                      id="sub-org-team-logo-lg"
                      name="thumbnailImage"
                      type={"file"}
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="image"
                            edge="end"
                            size="large"
                            htmlFor="sub-org-team-logo-lg"
                            component="label"
                          >
                            <Box
                              sx={{
                                width: 85,
                                height: 47,
                                backgroundColor:
                                  theme?.palette?.text?.containerTextLabel,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                              }}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              htmlFor="sub-org-team-logo-lg"
                            >
                              <UploadFileIcon sx={{ fontSize: 20, color: "white" }} />
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={{
                        "& ::file-selector-button": {
                          opacity: 0,
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: 350,
                          boxShadow: "none",
                          opacity: 0,
                        },
                        "& .MuiInputBase-input": {
                          padding: "13.5px 10px !important",
                        },
                      }}
                      inputProps={{
                        style: {
                          opacity: 0,
                        },
                        accept: "image/*",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Typography
                variant="h4"
                fontWeight={400}
                fontSize={12}
                color={theme?.palette?.text?.containerTextLabel}
                sx={{ mt: 3, ml: 3.5 }}
              >
                <span style={{ color: "red", fontSize: 18 }}>*</span> You can add additional team managers later from your dashboard.
              </Typography> */}
            </Box>
            {/* </Box> */}
          </DialogContent>

          <Box sx={{
            display: 'flex', justifyContent: 'space-between',
            px: 5, pb: 5,
            [theme.breakpoints.down('md')]: {
              px: 3, pb: 3,
            }
          }}>
            <Button
              size="small"
              variant="contained"
              sx={{ paddingX: 3.5, paddingY: 1.2 }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            {hasTeamControlAccess &&
              <Button
                size="small"
                variant="contained"
                type="submit"
                sx={{ paddingX: 3.5, paddingY: 1.2 }}
              >
                Save
              </Button>
            }
          </Box>
        </form>
      </Dialog>
      {/* {showSuccessModal &&
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to see next view"
        />
      } */}
    </>
  )
}

export default EditRegisteredTeamDetails;
