import * as actionTypes from '@store/actions'
import httpClient from '@utils/httpClient'

export const initialState = {
    user: null,
    token: null,
    userPlan: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOKEN:
            httpClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${action.data}`
            return {
                ...state,
                token: action.data,
            }
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.data,
            }
        case actionTypes.SET_UPDATE_USER:
            const user = { ...state.user }
            const updateData = action.data;
            user.first_name = updateData.first_name
            user.last_name = updateData.last_name
            if (updateData.hasOwnProperty('user_type')) {
                user.user_type = updateData.user_type
            }
            return {
                ...state,
                user: user,
            }
        case actionTypes.SET_UPDATE_PLAYER_USER:
            return {
                ...state,
                user: action.data,
            }
        case actionTypes.SET_UPDATE_SUBPARENT_USER:
            const parentUser = { ...state.user }
            const findIndex = parentUser.parent.findIndex((x) => x.id === action.data.id)
            parentUser.parent[findIndex] = action.data
            return {
                ...state,
                user: parentUser,
            }
        case actionTypes.SET_UPDATE_SUBATHLETE_USER:
            const athleteUser = { ...state.user }
            const athletFindIndex = athleteUser.athlete.findIndex((x) => x.id === action.data.id)
            athleteUser.athlete[athletFindIndex] = action.data
            return {
                ...state,
                user: athleteUser,
            }
        case actionTypes.SET_USER_HISTORIC_PLAN:
            return {
                ...state,
                userPlan: action.planData,
            }
        case actionTypes.LOGOUT_USER:
            localStorage.setItem("userToken", null);
            localStorage.setItem("userDetail", {});
            delete httpClient.defaults.headers.common['Authorization']
            delete httpClient.defaults.headers.common['X-ORG-TOKEN']
            return {
                ...state,
                user: null,
                token: null,
            }
        default:
            return state
    }
}

export default reducer
