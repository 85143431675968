import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  Button
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import { SET_TOAST, addEventLocationsAction, SET_SUCCESS_ERROR_POPUP } from "../../../../store/actions";

const AddAddress = ({
  open,
  handleClose,
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Address name is required"),
    address1: Yup.string().trim().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("Zip is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let bodyParams = {
        name: values?.name ?? "",
        address: values?.address1 ?? "",
        suite: values?.address2 ?? "",
        city: values?.city ?? "",
        state: values?.state ?? "",
        zip_code: values?.zip ?? "",
        instructions: values?.instructions ?? ""
      }
      addEventLocation(bodyParams);
    },
  });

  const addEventLocation = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await addEventLocationsAction(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: response?.message ?? "Success" }
      })
      handleClose(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
      })
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          py={1.5}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={30}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Add Address
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} mb={1}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ px: 6 }}>
          <Box width={1}>
            <CustomInputLabel htmlFor="address-1">ADDRESS NAME / TITLE</CustomInputLabel>
            <CustomTextField
              name="name"
              placeholder="Enter address name..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
            {Boolean(formik.touched.name && formik.errors.name) && (
              <FormHelperText error>{formik.errors.name}</FormHelperText>
            )}
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="address-1">ADDRESS</CustomInputLabel>
            <CustomTextField
              id="address-1"
              name="address1"
              placeholder="Enter address line 1..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address1}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
            {Boolean(formik.touched.address1 && formik.errors.address1) && (
              <FormHelperText error>{formik.errors.address1}</FormHelperText>
            )}
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="address-2">ADDRESS 2</CustomInputLabel>
            <CustomTextField
              id="address-2"
              name="address2"
              placeholder="Enter address line 2 (Optional)..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address2}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="address-2">INSTRUCTIONS</CustomInputLabel>
            <CustomTextField
              name="instructions"
              placeholder="Enter instructions (Optional)..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.instructions}
              multiline
              rows={5}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
          </Box>

          <Box width={1} mt={2}>
            <CustomInputLabel htmlFor="city">CITY</CustomInputLabel>
            <CustomTextField
              id="city"
              name="city"
              placeholder="Enter city..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.city}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                },
              }}
            />
            {Boolean(formik.touched.city && formik.errors.city) && (
              <FormHelperText error>{formik.errors.city}</FormHelperText>
            )}
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Box width={0.5} mt={2} mr={1}>
              <CustomInputLabel htmlFor="state">STATE</CustomInputLabel>
              <CustomTextField
                id="state"
                name="state"
                placeholder="Enter state..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.state}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.state && formik.errors.state) && (
                <FormHelperText error>{formik.errors.state}</FormHelperText>
              )}
            </Box>

            <Box width={0.5} mt={2} ml={1}>
              <CustomInputLabel htmlFor="state">ZIP</CustomInputLabel>
              <CustomTextField
                id="zip"
                name="zip"
                //type="number"
                placeholder="Enter zip..."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.zip}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                }}
              />
              {Boolean(formik.touched.zip && formik.errors.zip) && (
                <FormHelperText error>{formik.errors.zip}</FormHelperText>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 2.5,
            mt: 3,
            pb: 2
          }}>
          <Box display="flex" justifyContent="space-between" width={1}>
            <Typography
              variant="h5"
              sx={{
                width: 120,
                height: 40,
                background: "transparent",
                border: `2px solid ${theme?.palette?.secondary?.dark}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme?.palette?.secondary?.dark,
                cursor: "pointer"
              }}
              onClick={() => handleClose(false)}
            >
              Cancel
            </Typography>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{
                width: 120,
                height: 40,
                background: theme?.palette?.secondary?.dark,
                color: "white",
                ml: 2
              }}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAddress;
