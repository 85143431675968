import { Button, Popover, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, deleteAdminUser, deleteCoachAction, getAdminUsers, getCoach } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import SuccessPopup from "../../components/Common/SuccessPopup";
import ActionCard from "../../components/Common/ActionCard";
import { Box, useTheme } from "@mui/system";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import AddEditCoachingStaff from "../../components/CoachingStaff/AddEditCoachingStaff";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import PhoneNumber from 'libphonenumber-js';
import { charValidate } from "../../utils/functions";


const CoachingStaff = () => {
  const tableRef = useRef();
  const [adminUsers, setAdminUsers] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getCoachList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getCoachList = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getCoach({ dispatch, params });
    if (response?.ok) {
      setAdminUsers(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getCoachList();
  };

  const columns = [
    {
      field: "affiliations",
      headerName: "Affiliations",
      minWidth: 130,
      renderCell: (data) => {
        return "N/A";
      },
      flex: 1,
    },
    {
      field: "Profiles",
      headerName: "Profiles",
      minWidth: 100,
      renderCell: (data) => {
        return "N/A";
      },
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 150,
      renderCell: (data) => {
        return data?.row?.first_name ?? "N/A";
      },
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 150,
      renderCell: (data) => {
        return data?.row?.last_name ?? "N/A";
      },
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: (data) => {
        return <>
          <Tooltip title={data?.row?.email ?? "N/A"}>
            {charValidate(data?.row?.email, 20) ?? "N/A"};
          </Tooltip>
        </>
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "",
      flex: 0.55,
    },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 150,
      renderCell: (data) => {
        if (data?.row?.mobile) {
          return formatPhoneNumber(data?.row?.mobile)
        } else {
          return "N/A";
        }
      },
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={handleClick}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)" }
              }}
            >
              <ActionCard title={"Coaching Staff"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingX: 5.5, paddingY: 1 }}
                    onClick={handleEditAdminClick}
                  >
                    Edit Coach Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2, paddingX: 5.5, paddingY: 1, background: theme?.palette?.error?.dark }}
                    onClick={handleDeleteAdminClick}
                  >
                    Delete Coach
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        )
      }
    },
  ];

  const formatPhoneNumber = (phoneNumber) => {
    try {
      const formattedNumber = new PhoneNumber(phoneNumber, 'US').formatInternational();
      return formattedNumber;
    } catch (error) {
      return phoneNumber; // Return original if formatting fails
    }
  };

  const handleModalClose = (isClosed) => {
    setShowAddEditModal(false);
    setSelectedRow(undefined);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
    } else {
      setShowSuccessPopup(true);
      getCoachList();
    }
  }

  const handleEditAdminClick = () => {
    handleClose();
    setShowAddEditModal(true);
  }

  const handleDeleteAdminClick = () => {
    handleClose();
    setShowDeletePopup(true);
  }

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteCoachAction(dispatch, params, { id: selectedRow?.id });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message }
      })
      setShowDeletePopup(false);
      setSelectedRow("");
      getCoachList();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: response.message ?? "Something went wrong" }
      })
    }
  }

  return (
    <Box>
      <MuiDataGrid
        headerLabel={"Coaching Staff"}
        gridData={adminUsers}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={adminUsers ? false : true}
        handlePagination={handlePagination}
        onClickAddNew={() => { setShowAddEditModal(true); setSelectedRow('') }}
        handleRowClick={(data) => setSelectedRow(data?.row)}
      />
      {showAddEditModal &&
        <AddEditCoachingStaff
          open={showAddEditModal}
          handleClose={handleModalClose}
          editData={selectedRow}
        />
      }
      {showSuccessPopup &&
        <SuccessPopup
          title={"Coaching Staff"}
          message={"Success! Coach details have been added/updated."}
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
        />
      }
      {showDeletePopup &&
        <DeleteConfirmPopup
          title={"Coaching Staff"}
          message={"Are you sure you want to delete this coach?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      }
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Coaching Staff"}
          message={"Are you sure, want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setShowAddEditModal(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
          }}
        />
      )}
    </Box>
  );
};

export default CoachingStaff;
