import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import SubOrgTeamsAsstets from "./SubOrgTeamsAsstets";
import SubOrgTeamManager from "./SubOrgTeamManager";
import Step1Done from "@assets/images/steps/step_1_done.png";
import Step2Done from "@assets/images/steps/step_2_done.png";
import Step3Done from "@assets/images/steps/step_3_done.png";
import Step2NotDone from "@assets/images/steps/step_2_not_done.png";
import Step3NotDone from "@assets/images/steps/step_3_not_done.png";
import Step4NotDone from "@assets/images/steps/step_4_not_done.png";
import SubOrgTeamsForm from "./SubOrgTeamsForm";

const AddEditSubOrgTeams = ({ open, handleClose, data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const subOrgTeams = state?.subOrgTeams;

  const stepImgStyle = {
    width: 50,
    height: 50,
  };

  const stepLinesStyle = (bgColor) => {
    return {
      width: "20px",
      height: "2.7px",
      background: bgColor,
    };
  };

  useEffect(() => {
    if (data) {
      dispatch({
        type: "TEAM_LOGO_LG_URL",
        data: data?.largeLogo ?? "",
      });
      dispatch({
        type: "TEAM_LOGO_SM_URL",
        data: data?.smallLogo ?? "",
      });
    }
  }, [])

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "start",
          }
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{
              p: 1,
            }}
          >
            Sub-Organization Team
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{
          p: 1,
        }}
        >
          <img src={Step1Done} style={stepImgStyle} />
          <Box
            sx={stepLinesStyle(
              subOrgTeams?.step === 2 || subOrgTeams?.step === 3
                ? theme?.palette?.background?.stepLine
                : theme?.palette?.text?.containerTextLabel,
            )}
          ></Box>
          <img
            src={subOrgTeams?.step === 2 || subOrgTeams?.step === 3 ? Step2Done : Step2NotDone}
            style={stepImgStyle}
          />
          <Box
            sx={stepLinesStyle(
              subOrgTeams?.step === 3
                ? theme?.palette?.background?.stepLine
                : theme?.palette?.text?.containerTextLabel,
            )}
          ></Box>
          <img
            src={subOrgTeams?.step === 3 ? Step3Done : Step3NotDone}
            style={stepImgStyle}
          />
          {/* <Box
            sx={stepLinesStyle(theme?.palette?.text?.containerTextLabel)}
          ></Box>
          <img src={Step4NotDone} style={stepImgStyle} /> */}
        </Box>
      </DialogTitle>
      <Box
        width={1}
        height={"1px"}
        bgcolor={"gray"}
        sx={{ opacity: 0.25 }}
      ></Box>
      <DialogContent sx={{ p: 3 }}>
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={17}
          color={theme?.palette?.text?.containerTextLabel}
        >
          {subOrgTeams?.step === 3
            ? "Team Manager"
            : "Sub-Organization Team Details"}
        </Typography>
        {subOrgTeams?.step === 1 && (
          <SubOrgTeamsForm data={data} handleClose={handleClose} />
        )}
        {subOrgTeams?.step === 2 && (
          <SubOrgTeamsAsstets data={data} handleClose={handleClose} />
        )}
        {subOrgTeams?.step === 3 && (
          <SubOrgTeamManager data={data} handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddEditSubOrgTeams;
