import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import { convertToEmbedURL } from "../../utils/functions";

const ViewHelpVideoDetails = ({ open, handleClose, helpVideoObj }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="lg"
            fullWidth
            onClose={() => handleClose(true)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2.5}
                    pt={1}
                >
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        View Video Detail
                    </Typography>
                </Box>
            </DialogTitle>
            <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }} mb={3}>
                <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                ></Box>
            </Box>
            <DialogContent sx={{ paddingX: 6 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        paddingBottom: '56.25%',
                        '& .ctmViewVideo': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'

                        }
                    }}>
                        <iframe className='ctmViewVideo' src={helpVideoObj?.url ? convertToEmbedURL(helpVideoObj?.url) : ""} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '0px !important' }}>
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            fontSize={20}
                            color={theme?.palette?.text?.containerTextLabel}
                        >
                            {helpVideoObj?.title ? helpVideoObj?.title : "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '0px !important' }}>
                        <Typography
                            variant="span"
                            fontSize={17}
                            color={theme?.palette?.text?.containerTextLabel}
                        >
                            {helpVideoObj?.desc && helpVideoObj?.desc}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ margin: 2 }}>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                    color="inherit"
                    onClick={() => handleClose(true)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewHelpVideoDetails;