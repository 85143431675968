import { Box, Button, FormHelperText, Grid, useTheme } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import CustomTextField from "../CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import { SET_SUCCESS_ERROR_POPUP, SET_TOAST, createSubOrgTeam } from "../../store/actions";
import { convertToCapsOfFirstLetter } from "@utils/functions";

const SubOrgTeamManager = ({ handleClose, data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const subOrgTeams = state?.subOrgTeams;
  const { subOrgDetails, subOrgTeamDetails } = subOrgTeams;

  const validationSchema = Yup.object({
    // Sub Org Team's Manager Details
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Must be a valid email"),
    mobile: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: data?.primary_contact?.first_name ?? "",
      lastName: data?.primary_contact?.last_name ?? "",
      email: data?.primary_contact?.email ?? "",
      mobile: data?.primary_contact?.mobile ?? "",
      countryCode: data?.primary_contact?.countryCode ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (subOrgTeams?.subOrgDetails && subOrgTeams?.subOrgTeamDetails) {
        handleFinishSteps(values);
      }
    },
  });

  const handleFinishSteps = async (values) => {
    // const formattedMobileNum = values?.mobile?.slice(values.countryCode.length);
    let bodyParams = {
      id: data?.id ?? undefined,
      title: subOrgDetails?.subOrgName ?? "",
      org_code: subOrgDetails?.orgCode ?? "",
      primary_contact: {
        first_name: values?.firstName ?? "",
        last_name: values?.lastName ?? "",
        email: values?.email ?? "",
        countryCode: "US",
        mobile: values?.mobile ?? "",
      },
      primary_color: subOrgTeamDetails?.subOrgTeamPrimaryColor ?? "",
      secondary_color: subOrgTeamDetails?.subOrgTeamSecondaryColor ?? "",
      largeLogo: subOrgTeams?.teamLogoLgUrl ?? "",
      smallLogo: subOrgTeams?.teamLogoSmUrl ?? "",
      sub_organizations: subOrgDetails?.subOrg,
      address: {
        address: subOrgDetails?.address1 ?? "",
        Address2: subOrgDetails?.address2 ?? "",
        city: subOrgDetails?.city ?? "",
        state: subOrgDetails?.state ?? "",
        zip_code: subOrgDetails?.zip ?? "",
      },
      org_website: subOrgDetails?.websiteUrl ?? "",
      contact_mobile: subOrgDetails?.orgMobile ?? "",
      contact_countryCode: "US",
      order: subOrgDetails?.order ?? "",
      paymentDisable: subOrgDetails?.paymentDisable,
      price: subOrgDetails?.price ?? "",

    };
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await createSubOrgTeam(dispatch, params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: "RESET_ORG_TEAMS_INFO",
        data: "reset",
      });
      dispatch({
        type: "SET_ALL_STEPS_COMPLETED",
        data: true,
      });
      formik.resetForm();
      handleClose(false);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
      })
    }
  };

  const handleSteps = () => {
    dispatch({
      type: "STEP_COUNT",
      data: subOrgTeams?.step - 1,
    });
  };

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} mt={0.001}>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="first-name" sx={inputLabelStyle}>First Name</CustomInputLabel>
          <CustomTextField
            id="first-name"
            name="firstName"
            placeholder="Enter first name..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
            value={formik.values.firstName}
            sx={{
              "&.MuiFormControl-root": {
                width: '100%',
              },
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: "100%",
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.firstName && formik.errors.firstName) && (
            <FormHelperText error>{formik.errors.firstName}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="last-name" sx={inputLabelStyle}>Last Name</CustomInputLabel>
          <CustomTextField
            id="last-name"
            name="lastName"
            placeholder="Enter last name..."
            variant="outlined"
            size="small"
            onBlur={formik.handleBlur}
            onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'lastName')}
            value={formik.values.lastName}
            sx={{
              "&.MuiFormControl-root": {
                width: '100%',
              },
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: '100%',
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.lastName && formik.errors.lastName) && (
            <FormHelperText error>{formik.errors.lastName}</FormHelperText>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="email" sx={inputLabelStyle}>Email Address</CustomInputLabel>
          <CustomTextField
            id="email"
            name="email"
            placeholder="Enter email..."
            variant="outlined"
            size="small"
            type="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email.toLowerCase()}
            sx={{
              "&.MuiFormControl-root": {
                width: '100%',
              },
              "& .MuiOutlinedInput-root": {
                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                width: '100%',
                boxShadow: "none",
              },
            }}
          />
          {Boolean(formik.touched.email && formik.errors.email) && (
            <FormHelperText error>{formik.errors.email}</FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="mobile-number" sx={inputLabelStyle}>
            Mobile Number
          </CustomInputLabel>
          <Box
            sx={{
              "& .react-tel-input .form-control": {
                minWidth: "100%",
                fontWeight: 500,
                background: theme?.palette?.grey[50],
                height: "42px",
              },
            }}
          >
            <PhoneInput
              country={"us"}
              id="mobile"
              name="mobile"
              variant="outlined"
              onBlur={formik.handleBlur}
              placeholder="(702) 123-4567"
              value={formik.values.mobile}
              onChange={(value, country) => {
                formik.setFieldValue("countryCode", country.countryCode || "");
                formik.setFieldValue("mobile", value || "");
              }}
              error={Boolean(formik.touched.mobile && formik.errors.mobile)}
              size="sm"
              style={{
                width: "100%",
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "12px 14px",
                },
              }}
              disableDropdown={true}
              disableCountryCode={true}
              onlyCountries={["us"]}
            />
            {Boolean(formik.touched.mobile && formik.errors.mobile) && (
              <FormHelperText error>{formik.errors.mobile}</FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          pt: 3,
          [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
          }
        }}
      >
        <Button
          size="small"
          variant="outlined"
          sx={{ paddingX: 3.5, paddingY: 1.2 }}
          color="inherit"
          onClick={handleSteps}
        >
          Back
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
          color="inherit"
          onClick={() => handleClose(true)}
        >
          Close
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default SubOrgTeamManager;
