import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";

const ActionCard = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Box
      p={3}
      sx={{
        backgroundColor: theme?.palette?.background?.paper,
      }}>
      {/* <Box sx={{
        width: 0,
        height: 0,
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: "10px solid white",
      }}></Box> */}
      <Box>
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={16}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{mb: 2}}
          >
            {title}
          </Typography>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
            mb={2}
          ></Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default ActionCard;
