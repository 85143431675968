import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import { useNavigate } from "react-router";
import SuccessPopup from "../Common/SuccessPopup";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StripePayment from "../Tournament/StripePayment";
import { ReactComponent as PoweredByStripe } from '../../assets/images/svg/Powered-by-Stripe.svg';
import { useEffect } from "react";
import { SET_LOADER, SET_TOAST, applyTeamDiscountCodeAction, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import httpClient from "@utils/httpClient";
import { formatCurrency } from "../../utils/functions";
import CustomSwitch from "../Common/Inputs/Switch";

const FeePaymentDescription = ({
  open,
  handleClose,
  teamIds,
  dataList,
  paymentType,
  selectedDataGridRow,
  tournamentId
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userType = state?.user?.user?.user_type;
  const selectionInputStyle = { width: "100%", padding: "12px 14px", };
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [isCouponApplied, setCouponApplied] = useState(false);
  const [couponAppliedMessage, setCouponAppliedMessage] = useState();
  const [couponCodeAmount, setCouponCodeAmount] = useState(0);
  const [isDiscountPayment, setIsDiscountPayment] = useState(false);
  const [isFullDiscountPayment, setIsFullDiscountPayment] = useState(false);
  // const [isStripeCardDetailsFilled, setIsStripeCardDetailsFilled] = useState(false);
  const [isShowStripeCardDetailsFilledError, setShowStripeCardDetailsFilledError] = useState(false);
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const navigate = useNavigate();
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const boldFont = { fontWeight: 700 };
  const couponAppliedMessageTextStyle = { color: "green", marginTop: 10, fontSize: 14, marginBottom: 0 }

  function createData(
    title,
    team_total_fees,
    athlete_total_fees,
    total_amount,
  ) {
    return { title, team_total_fees, athlete_total_fees, total_amount };
  }
  // const rows = [
  //   createData('3rd Grade Mudsocks Blue', 360, 475, 850),
  //   createData('3rd Grade Mudsocks Blue2', 360, 475, 850),
  //   createData('3rd Grade Mudsocks Blue2', 360, 475, 850),
  //   createData('3rd Grade Mudsocks Blue2', 360, 475, 850),
  //   createData('3rd Grade Mudsocks Blue2', 360, 475, 850),
  //   createData('3rd Grade Mudsocks Blue2', 360, 475, 850),
  // ];

  const rows = [];
  let totalAmount = 0;
  let selectedTeamsList = [];
  let selectedTeamsIds = [];
  let isStripeCardDetailsFilled = false;

  if (paymentType) {
    if (paymentType === "TEAM_ROSTER_BOTH") {
      if (dataList && dataList?.length && teamIds && teamIds?.length) {
        let teamList = dataList?.filter((team) => teamIds?.includes(team?.id));
        selectedTeamsIds = teamList?.map((team) => team?.id);
        totalAmount = teamList.reduce((total, team) => total + team.total_amount, 0);
        if (teamList && teamList?.length) {
          selectedTeamsList = [...teamList];
          teamList?.forEach((team) => {
            rows.push(
              createData(team?.title, team?.team_total_fees, team?.athlete_total_fees, team?.total_amount),
            );
          });
        }
      }
    } else {
      if (paymentType === "TEAM") {
        totalAmount = selectedDataGridRow?.team_total_fees;
        rows.push(
          createData(selectedDataGridRow?.title, selectedDataGridRow?.team_total_fees, 0, selectedDataGridRow?.team_total_fees),
        );
      }
      if (paymentType === "ROSTER") {
        totalAmount = selectedDataGridRow?.athlete_total_fees;
        rows.push(
          createData(selectedDataGridRow?.title, 0, selectedDataGridRow?.athlete_total_fees, selectedDataGridRow?.athlete_total_fees),
        );
      }
    }
  }

  const validationSchema = Yup.object({
    description: Yup.string().trim(),
    discountCode: Yup.string().required("Discount code is required"),
    //  Yup.string().when("$paymentMethod", {
    //   is: "Stripe",
    //   then: Yup.string().trim(),
    //   otherwise: Yup.string().trim().required("Description is required")
    // }),
    paymentMethod: Yup.string().required("Payment method selection is required"),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
      paymentMethod: "",
      discountCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // let bodyParam = {
      //   description: "",
      //   paymentMethod: ""
      // }
      if (values?.paymentMethod === "Stripe") {
        if (paymentType === "TEAM" || paymentType === "ROSTER") {
          completeIndividualStripePaymentProcess();
        }
        if (paymentType === "TEAM_ROSTER_BOTH") {
          completeAllSelectedStripePaymentProcess();
        }
      } else {
        if (userType === "admin" || userType === "superadmin") {
          completeAdminAllTeamAndAthletePaymentProcess(values?.description)
        }
      }
    },
  });
  const paymentMethod = formik.values.paymentMethod;
  let couponCode = formik?.values?.discountCode ?? "";

  useEffect(() => {
    localStorage.setItem("isStripeCardDetailsFilled", false);

    return () => {
      localStorage.removeItem("isStripeCardDetailsFilled");
    }
  }, []);

  useEffect(() => {
    if (userType === "teammanager" || userType === "teamcoach") {
      formik.setFieldValue("paymentMethod", "Stripe");
    }
  }, [userType]);

  useEffect(() => {
    if (paymentMethod === "Stripe") {
      if (paymentType === "TEAM" || paymentType === "ROSTER") {
        individualStripePaymentIntent();
      }
      if (paymentType === "TEAM_ROSTER_BOTH") {
        allSelectedStripePaymentIntent();
      }
    }
  }, [paymentMethod]);

  const individualStripePaymentIntent = async () => {
    if (totalAmount - couponCodeAmount == 0) {
      setIsFullDiscountPayment(true);
      return;
    }

    let values = {
      team_id: selectedDataGridRow?.id ?? "",
      amount: totalAmount - couponCodeAmount,
    }

    if (paymentType === "ROSTER") {
      values["athlete_count"] = selectedDataGridRow?.athlete_count;
      values["athletes"] = selectedDataGridRow?.athlete_ids;
    }

    const API_URL = paymentType === 'TEAM' ? "/team-payments/paymentIntent" : "/athlete-payments/paymentIntent";

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      values.clientSecret = apiRes.data?.clientSecret;
      values.transaction_id = apiRes.data?.transaction_id;
      values.publishableKey = apiRes.data?.publishableKey;
      values.amount = apiRes.data?.amount;
      setPaymentFormData(values);
      // setShowTeamPaymentModal(true);
    } else {
      //manage error
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
      })
    }
  };

  const allSelectedStripePaymentIntent = async () => {
    if (totalAmount - couponCodeAmount == 0) {
      setIsFullDiscountPayment(true);
      return;
    }
    if (!selectedTeamsList?.length) {
      return;
    }

    let values = {
      payment: [],
      total_amount: totalAmount - couponCodeAmount,
    }
    if (selectedTeamsList?.length) {
      selectedTeamsList?.forEach((team) => {
        values.payment.push({
          team_id: team?.id,
          team_fees: team?.team_total_fees,
          athlete_fees: team?.athlete_total_fees,
          total_amount: team?.total_amount,
          athlete_count: team?.athlete_count,
          athletes: team?.athlete_ids
        });
      })
    }
    const API_URL = "/team-payments/teamAndAthletePaymentIntent";

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      values.clientSecret = apiRes.data?.clientSecret;
      values.transaction_id = apiRes.data?.transaction_id;
      values.publishableKey = apiRes.data?.publishableKey;
      values.amount = apiRes.data?.amount;
      setPaymentFormData(values);
      // setShowTeamPaymentModal(true);
    } else {
      //manage error
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
      })
    }
  };

  const completeIndividualStripePaymentProcess = async () => {
    // let isCardDetailsFilled = localStorage.getItem("isStripeCardDetailsFilled");
    // if (isCardDetailsFilled === "false") {
    //   setShowStripeCardDetailsFilledError(true);
    //   return
    // }
    const API_URL = paymentType === 'TEAM' ? "/team-payments/teamPayment" : "/athlete-payments/athletePayment";

    let values = {
      team_id: paymentFormData.team_id,
      transaction_id: totalAmount - couponCodeAmount === 0 ? "free" : paymentFormData.transaction_id,
      amount: paymentFormData?.amount,
      discount: isCouponApplied ? couponCode : "",
      discount_value: isCouponApplied ? couponCodeAmount : 0,
    }
    if (paymentType === "ROSTER") {
      values["athlete_count"] = selectedDataGridRow?.athlete_count;
      values["athletes"] = selectedDataGridRow?.athlete_ids;
    }

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      setShowSuccessModal(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
      })
    }
  }

  const completeAllSelectedStripePaymentProcess = async () => {
    // let isCardDetailsFilled = localStorage.getItem("isStripeCardDetailsFilled");

    // if (isCardDetailsFilled === "false") {
    //   setShowStripeCardDetailsFilledError(true);
    //   return
    // }
    if (!selectedTeamsList?.length) {
      return;
    }

    let values = {
      payment: [],
      total_amount: paymentFormData?.amount,
      transaction_id: totalAmount - couponCodeAmount === 0 ? "free" : paymentFormData?.transaction_id,
      discount: isCouponApplied ? couponCode : "",
      discount_value: isCouponApplied ? couponCodeAmount : 0,
    }
    if (selectedTeamsList?.length) {
      selectedTeamsList?.forEach((team) => {
        values.payment.push({
          team_id: team?.id,
          team_fees: team?.team_total_fees,
          athlete_fees: team?.athlete_total_fees,
          total_amount: team?.total_amount,
          athlete_count: team?.athlete_count,
          athletes: team?.athlete_ids
        });
      })
    }
    const API_URL = "/team-payments/teamAndAthletePayment";

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      setShowSuccessModal(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
      })
    }
  }

  const completeAdminAllTeamAndAthletePaymentProcess = async (description) => {
    let teamAthletePaymentList = selectedTeamsList?.length ? selectedTeamsList : selectedDataGridRow ? [selectedDataGridRow] : [];
    let values = {
      payment: [],
      total_amount: totalAmount - couponCodeAmount,
      admin_desc: description ?? "",
      discount: isCouponApplied ? couponCode : "",
      discount_value: isCouponApplied ? couponCodeAmount : 0,
    }
    if (teamAthletePaymentList?.length) {
      teamAthletePaymentList?.forEach((team) => {
        values.payment.push({
          team_id: team?.id,
          team_fees: paymentType === "TEAM_ROSTER_BOTH" ? team?.team_total_fees : paymentType === "TEAM" ? team?.team_total_fees : 0,
          athlete_fees: paymentType === "TEAM_ROSTER_BOTH" ? team?.athlete_total_fees : paymentType === "TEAM" ? 0 : team?.athlete_total_fees,
          total_amount: team?.total_amount,
          athlete_count: paymentType === "TEAM_ROSTER_BOTH" ? team?.athlete_count : paymentType === "TEAM" ? 0 : team?.athlete_count,
          athletes: paymentType === "TEAM_ROSTER_BOTH" ? team?.athlete_ids : paymentType === "TEAM" ? [] : team?.athlete_ids,
        });
      })
    }
    const API_URL = "/team-payments/adminTeamAndAthletePayment";

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      setShowSuccessModal(true);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: apiRes?.message || "Something went wrong" }
      })
    }
  }

  const handleSuccessModalClose = () => {
    setCouponCodeAmount(0);
    setCouponApplied(false);
    setCouponAppliedMessage(undefined);
    setShowSuccessModal(false);
    handleClose(false);
  }

  const PaymentOverviewTable = () => {
    return (
      <TableContainer component={Paper} sx={{ border: "1px solid", height: "200px", overflowY: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={boldFont} align="left">TEAM NAME</TableCell>
              <TableCell sx={boldFont} align="right">TEAM FEE</TableCell>
              <TableCell sx={boldFont} align="right">ROSTER FEES</TableCell>
              <TableCell sx={boldFont} align="right">TOTAL FEES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                  {row.name}
                </TableCell> */}
                <TableCell align="left">{row.title}</TableCell>
                <TableCell align="right">{formatCurrency(row.team_total_fees)}</TableCell>
                <TableCell align="right">{formatCurrency(row.athlete_total_fees)}</TableCell>
                <TableCell align="right">{formatCurrency(row.total_amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const PaymentTotalPreviewTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {isCouponApplied &&
                <>
                  <TableCell sx={boldFont} align="left">Discounts:</TableCell>
                  <TableCell sx={boldFont} align="right">-{formatCurrency(couponCodeAmount)}</TableCell>
                </>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell sx={boldFont} align="left">TOTAL:</TableCell>
            <TableCell sx={boldFont} align="right">{formatCurrency(totalAmount - couponCodeAmount)}</TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const applyCouponCode = async () => {
    if (couponCode) {
      setCouponCodeAmount(0);
      setCouponApplied(false);
      setCouponAppliedMessage(undefined);
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      }
      let paymentData = {
        code: couponCode,
        // team_id: paymentType === "TEAM_ROSTER_BOTH" ? selectedTeamsIds ?? [] : paymentFormData.team_id,
        tournament_id: tournamentId,
        total_amount: totalAmount
      }
      try {
        let response = await applyTeamDiscountCodeAction(dispatch, params, paymentData);
        if (response?.ok) {
          let data = response?.data ?? "";
          // dispatch({
          //   type: SET_TOAST,
          //   data: {
          //     type: "success",
          //     message: response?.message ?? "Success!",
          //   },
          // });
          if (data) {
            if (data?.flatOff && typeof data?.flatOff === "number") {
              setCouponCodeAmount(data?.discount_amount);
              setCouponApplied(true);
              // totalAmount = totalAmount - data?.discount_amount;
              totalAmount = data?.final_amount;
              setCouponAppliedMessage(<h6 style={couponAppliedMessageTextStyle}>{response?.message ?? "Coupon Code Applied Successfully!"}</h6>);
            }
            if (data?.flatPer && typeof data?.flatPer === "number") {
              // let percentageAmount = (totalAmount / 100) * data?.flatPer;
              let percentageAmount = data?.discount_amount;
              setCouponCodeAmount(percentageAmount);
              setCouponApplied(true);
              // totalAmount = totalAmount - percentageAmount;
              totalAmount = data?.final_amount;
              setCouponAppliedMessage(<h6 style={couponAppliedMessageTextStyle}>{response?.message ?? "Coupon Code Applied Successfully!"}</h6>);
            }
          }
        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: { open: true, type: 'error', message: response?.message ?? "Something went wrong! Try again" }
          })
          setCouponAppliedMessage(<h6 style={{ ...couponAppliedMessageTextStyle, color: "red" }}>{response?.message ?? "Coupon Code is Wrong, Try Different One"}</h6>);
        }
      } catch (error) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: error?.message ?? "Something went wrong! Try again" }
        })
        setCouponAppliedMessage(<h6 style={{ color: "red", marginTop: 10 }}>{error?.message ?? "Coupon Code is Wrong, Try Different One"}</h6>);
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => {
          setCouponCodeAmount(0);
          setCouponApplied(false);
          setCouponAppliedMessage(undefined);
          handleClose(true);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            py={1.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                alignItems: "start",
                p: 0,
              }
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={30}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: "24px",
                }
              }}
            >
              Payment
            </Typography>
            <Box
              sx={{
                background: "#EFF7FF",
                border: `1px solid #7FBEFD`,
                color: "black",
                maxWidth: "30rem",
                width: "100%",
                borderRadius: "7px",
                fontSize: "14px",
                fontWeight: 500,
                ml: 3, mt: 2,
                [theme.breakpoints.down('md')]: {
                  ml: 0,
                }
              }}
              p={2}
            >
              <span style={{ color: "red" }}>*&nbsp;&nbsp;</span>Please be patient once submitted. This process could take a several moments to complete.
            </Box>
          </Box>
        </DialogTitle>
        <Box width={1} mb={1}>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        <Box display="flex" justifyContent="center" mx={5.2} mt={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              mx: 3,
            }
          }}>
          <PaymentOverviewTable />
        </Box>
        <Box display="flex" justifyContent="center" mx={5.2}
          sx={{
            [theme.breakpoints.down('md')]: {
              mx: 3,
            }
          }}>
          <PaymentTotalPreviewTable />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{
            paddingX: 6, [theme.breakpoints.down('md')]: {
              paddingX: 3.4,
            }
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={isDiscountPayment}
                      onChange={(e) => {
                        setIsDiscountPayment(e?.target?.checked);
                      }}
                      sx={{ m: 1 }}
                    />
                  }
                  label="Payment with Coupon Code"
                />
              </Grid>
              {isDiscountPayment &&
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomInputLabel sx={inputLabelStyle} htmlFor="athlete-school">DISCOUNT CODE</CustomInputLabel>
                      <CustomTextField
                        name="discountCode"
                        placeholder="Enter discount code..."
                        variant="outlined"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.discountCode}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            border: `1px solid ${theme?.palette?.grey?.[400]}`,
                            width: "100%",
                            boxShadow: "none",
                          },
                          width: "100%"
                        }}
                      />
                      {Boolean(formik.touched.discountCode && formik.errors.discountCode) && (
                        <FormHelperText error>{formik.errors.discountCode}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={applyCouponCode}
                        sx={{
                          paddingX: 1.8, paddingY: 1.2, ml: 2, height: "45px", fontSize: "12px", mt: 3,
                          [theme.breakpoints.down('md')]: {
                            m: 0,
                          }
                        }}
                        type="submit"
                      >
                        Apply Discount
                      </Button>
                    </Grid>
                  </Grid>
                  {isCouponApplied &&
                    couponAppliedMessage
                  }
                </Grid>
              }
              {!isDiscountPayment || isCouponApplied ?
                <>
                  {
                    userType === "admin" || userType === "superadmin" &&
                    <Grid item xs={12}>
                      <CustomInputLabel sx={inputLabelStyle} htmlFor="payment-method">
                        SELECT PAYMENT METHOD (Payment Selection is Only Available for Admins or Super-Admins)
                      </CustomInputLabel>
                      <Select
                        id="payment-method"
                        name="paymentMethod"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.paymentMethod}
                        input={
                          <OutlinedInput
                            sx={selectionInputStyle}
                          />
                        }
                        MenuProps={MenuProps}
                        disabled={
                          userType === "admin" || userType === "superadmin" ?
                            false :
                            true
                        }
                        inputProps={{
                          "aria-label": "Without label",
                          style: {
                            width: "100%",
                          }
                        }}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "12px 14px",
                          },
                        }}
                        displayEmpty
                        renderValue={(value) => {
                          if (value) {
                            return value;
                          } else {
                            return (
                              <Typography
                                variant="p"
                                fontWeight={400}
                                fontSize={14}
                                sx={{ opacity: 0.5 }}
                              >
                                Select payment method...
                              </Typography>
                            );
                          }
                        }}
                      >
                        <MenuItem value={"Stripe"}>
                          Stripe
                        </MenuItem>
                        {userType === "admin" || userType === "superadmin" ?
                          <MenuItem value={"Other"}>
                            Other
                          </MenuItem> :
                          null
                        }
                      </Select>
                      {Boolean(formik.touched.paymentMethod && formik.errors.paymentMethod) && (
                        <FormHelperText error>{formik.errors.paymentMethod}</FormHelperText>
                      )}
                    </Grid>
                  }
                </>
                : null}
              {formik.values.paymentMethod === "Other" ?
                <Grid item xs={12} mt={1}>
                  <CustomInputLabel sx={inputLabelStyle} htmlFor="athlete-school">Description</CustomInputLabel>
                  <CustomTextField
                    name="description"
                    placeholder="Description about payment method, etc..."
                    variant="outlined"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    required={true}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                        width: "100%",
                        boxShadow: "none",
                      },
                      width: "100%"
                    }}
                    multiline
                    rows={6}
                  />
                  {Boolean(formik.touched.description && formik.errors.description) && (
                    <FormHelperText error>{formik.errors.description}</FormHelperText>
                  )}
                </Grid>
                :
                formik.values.paymentMethod === "Stripe" && (totalAmount - couponCodeAmount !== 0) ?
                  <>
                    <StripePayment
                      handleClose={handleClose}
                      // handleChange={(e) => {
                      //   // if(e?.complete) {
                      //   localStorage.setItem("isStripeCardDetailsFilled", e?.complete);
                      //   // }
                      //   // setIsStripeCardDetailsFilled(e?.complete);
                      // }}
                      afterPayemntMethod={(paymentType === "TEAM" || paymentType === "ROSTER") ? completeIndividualStripePaymentProcess : completeAllSelectedStripePaymentProcess}
                      formData={paymentFormData}
                      type="team"
                      payAmount={(totalAmount - couponCodeAmount)}
                    />
                    {isShowStripeCardDetailsFilledError && (
                      <FormHelperText sx={{ ml: 2 }} error>Fill out all the card details to pay (including - Card Number, Expiry Date, and CVC)</FormHelperText>
                    )}
                  </>
                  : null
              }
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              marginX: 6,
              paddingX: 0,
              pt: 2,
              pb: 3.5,
            }}>
            {
              formik.values.paymentMethod === "Other" || (totalAmount - couponCodeAmount === 0) ?
                <Box width={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                  {/* <Box sx={{ width: '185px' }}>
                  {formik.values.paymentMethod === "Stripe" &&
                    <PoweredByStripe />
                  }
                </Box> */}
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ paddingX: 3.5, paddingY: 1.2, mr: 2 }}
                      color="inherit"
                      onClick={() => {
                        setCouponCodeAmount(0);
                        setCouponApplied(false);
                        setCouponAppliedMessage(undefined);
                        handleClose(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      sx={{ paddingX: 3.5, paddingY: 1.2 }}
                    >
                      Pay {totalAmount - couponCodeAmount === 0 ? "" : formatCurrency(totalAmount - couponCodeAmount)}
                    </Button>
                  </Box>
                </Box>
                : null
            }

          </DialogActions>
        </form>
      </Dialog>
      {showSuccessModal &&
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to close"
        />
      }
    </>
  );
};

export default FeePaymentDescription;
