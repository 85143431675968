import moment from "moment";
import { useState } from "react";

export function useCurrentDates(current) {
  const [currentMoment, setCurrentMoment] = useState(current);

  let currentMonth = currentMoment.clone().format("MMM");
  let currentDate = currentMoment.clone().format("D");

  let weekStart = currentMoment.clone().startOf('week');
  let weekEnd = currentMoment.clone().endOf('week');

  let currentWeekDays = [];

  for (var i = 0; i <= 6; i++) {
    currentWeekDays.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
  }

  const setCurrentDate = (date) => {
    if(date) {
      setCurrentMoment(moment(date));
    }
  }

  return [
    currentWeekDays,
    currentMonth,
    currentDate,
    setCurrentDate,
    currentMoment,
  ]
}

export function useGetCurrentDateTime() {
  // Get the current date
  const currentDate = new Date();

  // Extract year, month, and day components
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
  const day = currentDate.getDate().toString().padStart(2, '0');
  // Create the date string in the specified format
  const currentDateString = `${year}-${month}-${day}`;

  // Extract hours and minutes components
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  // Determine whether it's AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';
  // Convert hours to 12-hour format
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  // Create the time string in the specified format
  const currentTimeString = `${formattedHours}:${minutes} ${period}`;


  return [
    currentDateString,
    currentTimeString,
  ]

}
