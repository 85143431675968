import {
  Avatar,
  Box,
  Button,
  Tooltip,
  Typography,
  useTheme,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import MuiDataGrid from "../MuiDataGrid/MuiDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  getWalletHistory,
} from "../../store/actions";
import { charValidate } from "../../utils/functions";
import { USER_DETAILS_PAGE } from "../../routes/constants";
import { useLocation, useNavigate } from "react-router";
import { dateFormat } from "../../utils/functions";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AddSketchplayBucksConfirmModal from "./AddSketchplayBucksConfirmModal";

const ViewWalletHistory = () => {
  const tableRef = useRef();
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedUserData = location?.state?.userData;
  const [dataList, setDataList] = useState([]);
  const [selectedDebitType, setSelectedDebitType] = useState("");
  const [showSketchplayBucksConfirmModal, setShowSketchplayBucksConfirmModal] =
    useState(false);
  const [selectedReason, setSelectedReason] = useState("");

  const userType = state?.user?.user?.user_type;
  const debitTypes = [
    { id: "add", name: "Credited" },
    { id: "subtract", name: "Debited" },
  ];
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const selectionInputStyle = { height: 40, width: 250 };

  useEffect(() => {
    getDataList();
  }, []);

  useEffect(() => {
    getDataList();
  }, [selectedDebitType]);

  const getDataList = async () => {
    //sketchplay_bucks
    let params = {
      id: selectedUserData?.id,
      debit_type: selectedDebitType,
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getWalletHistory(dispatch, params);
    if (response?.ok) {
      setDataList(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize;
    paginationOptions.limit = pageSize ?? 10;
    getDataList();
  };

  let columns = [
    {
      field: "value",
      headerName: "Hours/Credit",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <>
          {data?.row?.debit_type === "add" ? (
            <Typography sx={{ color: "green" }}>
              {data?.row?.value ? `+${data?.row?.value}` : "0"}
            </Typography>
          ) : (
            <Typography sx={{ color: "red" }}>
              {data?.row?.value ? `-${data?.row?.value}` : "0"}
            </Typography>
          )}
        </>
      ),
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title={data?.row?.value ?? "N/A"}>
          <Typography>
            {data?.row?.type
              ? data?.row?.type === "volunteer_hours"
                ? "Volunteer Hours"
                : "Foundation Credit"
              : "N/A"}
          </Typography>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Transaction Date",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Typography>
          {data?.row?.created_at ? dateFormat(data?.row?.created_at) : "N/A"}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "description",
      headerName: "Reason",
      flex: 1,
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (data) =>
        data?.row?.description ? (
          <Box onClick={() => showReasonModal(data?.row?.description)}>
            <StickyNote2Icon fontSize="medium" sx={{ cursor: "pointer" }} />
          </Box>
        ) : (
          <Typography>N/A</Typography>
        ),
      sortable: false,
    },
  ];

  const showReasonModal = (reason) => {
    setSelectedReason(reason);
    setShowSketchplayBucksConfirmModal(true);
  };

  const handleSketchplayBucksConfirmModalClose = () => {
    setShowSketchplayBucksConfirmModal(false);
  };

  const gridToolbar = () => {
    return (
      <Box
        display={"flex"}
        my={1.5}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" fontWeight={700} fontSize={16}>
          Current Balance :{" "}
          {"$" + (selectedUserData?.sketchplay_bucks.toFixed(2) ?? "0.00")}
        </Typography>
        <Box display={"flex"} alignItems="center" justifyContent="center">
          {dataList?.length > 0 && (
            <Select
              name="debittypeFilter"
              onChange={(e) => setSelectedDebitType(e?.target?.value)}
              value={selectedDebitType}
              input={<OutlinedInput sx={selectionInputStyle} />}
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              renderValue={(value) => {
                if (value) {
                  let selected = debitTypes?.find(
                    (debitType) => debitType?.id === value,
                  );
                  if (selected) {
                    return selected?.name;
                  }
                } else {
                  return (
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={14}
                      sx={{ opacity: 0.5 }}
                    >
                      Filter by debit type...
                    </Typography>
                  );
                }
              }}
              className="MuiSelect-select"
              sx={{
                "& .MuiSelect-select": {
                  padding: "10.5px 14px",
                },
                width: "200px",
                mx: 2,
              }}
            >
              <MenuItem value={""}>All</MenuItem>
              {debitTypes?.map((debitType) => (
                <MenuItem key={debitType.id} value={debitType.id}>
                  {debitType.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Transaction History"}
        gridData={dataList}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={false}
        isAddNewBtnShow={false}
        gridButtons={() => {
          return (
            <>
              {userType === "admin" ? (
                <Box
                  sx={{
                    my: 2,
                    px: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1 }}
                    color="inherit"
                    onClick={() => {
                      navigate(USER_DETAILS_PAGE, {
                        state: {
                          selectedUserData: selectedUserData,
                        },
                      });
                    }}
                  >
                    Back
                  </Button>
                </Box>
              ) : null}
            </>
          );
        }}
        gridToolbarButtons={() => gridToolbar()}
      />
      {showSketchplayBucksConfirmModal && (
        <AddSketchplayBucksConfirmModal
          open={showSketchplayBucksConfirmModal}
          handleClose={handleSketchplayBucksConfirmModalClose}
          isShowReason={true}
          selectedReason={selectedReason}
        />
      )}
    </>
  );
};

export default ViewWalletHistory;
