import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, Card, CardContent, Alert, Button, useMediaQuery } from '@mui/material'
//import StarRoundedIcon from '@mui/icons-material/StarRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
//import CancelIcon from '@mui/icons-material/Cancel';
import AddEditParentGuardian from './AddEditParentGuardian';
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP, getMe } from "@store/actions";
import httpClient from "@utils/httpClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { phoneFormat } from '../../utils/functions';

const validationSchema = Yup.object({
  first_name: Yup.string().trim().required("First Name is required"),
  last_name: Yup.string().trim().required("Last Name is required"),
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Must be a valid email"),
  mobile: Yup.string().min(10).required("Mobile is required"),
  countryCode: Yup.string().required("Country Code is required"),
  filmRoom: Yup.boolean().required(),
  calendar: Yup.boolean().required(),
  community: Yup.boolean().required(),
  manageAthletes: Yup.boolean().required(),
  manageSubscription: Yup.boolean().required(),
});

const ManageParent = () => {
  const { user } = useSelector((state) => state.user);
  const getArr = user.hasOwnProperty('parent') ? user.parent : []
  const parentArr = [user, ...getArr];
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const [showError, setShowError] = useState(null);
  const [isAbleToManage, setIsAbleToManage] = useState(false);
  const [activeParentId, setActiveParentId] = useState(0);
  const formContainerRef = useRef(null);

  useEffect(() => {
    dispatch(getMe())
    if (user.registeredBy?.id === undefined || user?.id === user?.registeredBy?.id) {
      setIsAbleToManage(true)
    } else if (user.registeredBy?.id !== null && user?.registeredBy.user_type == "player") {
      setIsAbleToManage(user?.isPrimaryParent)
    } else {
      setIsAbleToManage(false)
    }
  }, [])

  const handleSave = async (values, actions) => {

    const countryCode = values.countryCode.toUpperCase()
    const mobile = values.mobile;
    const postData = { ...values, mobile }
    const reqPayload = {
      id: postData.id,
      first_name: postData.first_name,
      last_name: postData.last_name,
      email: postData.email,
      countryCode: countryCode,
      mobile: mobile,
      permission: {
        filmRoom: postData.filmRoom,
        calendar: postData.calendar,
        community: postData.community,
        manageAthletes: postData.manageAthletes,
        manageSubscription: postData.manageSubscription
      }
    }

    dispatch({ type: SET_LOADER, data: true });
    const API_URL = isEdit ? "/updateParentRelationDetails" : "/addGuardianByGuardian"
    const res = await httpClient.post(API_URL, reqPayload);
    dispatch({ type: SET_LOADER, data: false });

    if (res && res.ok) {
      actions.resetForm();
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'success', message: res.message }
      })
      dispatch(getMe())
      setActiveParentId(0)
      setIsEdit(false)
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: res.message }
      })
      return false;
    }
  };

  const handleEditParent = (obj, index) => {
    if(isMobile) {
      scrollToFormContainer()
    }
    setActiveParentId(obj.id)
    setIsEdit(true)
    formik.setFieldValue("first_name", obj.first_name)
    formik.setFieldValue("last_name", obj.last_name)
    formik.setFieldValue("email", obj.email)
    formik.setFieldValue("mobile", obj.mobile)
    formik.setFieldValue("countryCode", obj.countryCode)
    formik.setFieldValue("filmRoom", obj.permission.filmRoom)
    formik.setFieldValue("calendar", obj.permission.calendar)
    formik.setFieldValue("community", obj.permission.community)
    formik.setFieldValue("manageAthletes", obj.permission.manageAthletes)
    formik.setFieldValue("manageSubscription", obj.permission.manageSubscription)
    formik.setFieldValue("id", obj.id)
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      countryCode: "",
      dialCode: "",
      filmRoom: true,
      calendar: true,
      community: true,
      manageAthletes: true,
      manageSubscription: true,
      id: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const scrollToFormContainer = () => {
    if (formContainerRef.current) {
      formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ pt: 2 }}
      >
        <Grid item xs={12} lg={4} md={5} sx={{ mb: 3 }}>
          <Grid container sx={{
            mt: 4, mb: 4, alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              mt: 0,
              mb: 2,
            }
          }}>
            <Grid item xs={12}>
              <Typography variant="h2" sx={{
                color: '#262626',
                [theme.breakpoints.down('md')]: {
                  fontSize: "1.3rem",
                }
              }}>
                My Parents/Guardians
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems="center" textAlign="center">
            {parentArr.map((parentData, index) => (
              <Card
                key={index}
                sx={{
                  boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "white !important",
                  padding: "1rem",
                  overflow: 'inherit',
                  mt: 2,
                  border: parentData.id === activeParentId ? '1px solid #000000' : 'none'
                }}
              >
                <CardContent sx={{ p: "0", pb: "0 !important", position: 'relative' }}>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                    <Box sx={{
                      pr: 2,
                    }}>
                      <Box
                        sx={{
                          width: "70px",
                          height: "70px",
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: "#000000",
                            justifyContent: "center",
                            margin: "0",
                            minWidth: "70px",
                            color: "#ffffff",
                            borderRadius: "50rem",
                            position: "relative",
                            overflow: "hidden",
                            textTransform: "uppercase",
                            height: "70px",
                            width: "70px",
                            lineHeight: "70px",
                            marginLeft: "0",
                            marginRight: "auto",
                            marginTop: "0",
                            fontSize: "2rem",
                            cursor: "pointer",
                            fontWeight: 'bold',
                            display: 'block',
                            textAlign: 'center',
                          }}
                        >
                          {`${parentData?.first_name?.charAt(
                            0,
                          )}${parentData?.last_name?.charAt(0)}`}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      width: "100%",
                    }}>
                      <Grid container spacing={2} >
                        <Grid item xs={8} container direction="column" spacing={2}>
                          <Grid item xs sx={{ textAlign: 'left' }}>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="div"
                              sx={{ fontSize: "16px", fontWeight: "600", m: 0 }}
                            >
                              {`${parentData?.first_name} ${parentData?.last_name}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                fontFamily: '"Arial",sans-serif,Arial',
                                fontSize: "14px",
                              }}
                            >
                              {phoneFormat(parentData?.mobile)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                fontFamily: '"Arial",sans-serif,Arial',
                                fontSize: "14px",
                              }}
                            >
                              {parentData?.email}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{ alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              textAlign: "center",
                              padding: '0 0 0 0',
                            }}
                          >
                            {isAbleToManage &&
                              <>
                                {index !== 0 &&
                                  <Box onClick={() => handleEditParent(parentData, index)} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    background: 'black',
                                    width: '2rem', // Adjust the width and height as needed
                                    height: '2rem',
                                    cursor: 'pointer'
                                  }}>
                                    <EditOutlinedIcon sx={{ color: 'white', width: '0.8em', height: '0.8em' }} />
                                  </Box>
                                }
                              </>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* <Box sx={{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-30px',
                                        }}>
                                            {(Object.keys(user?.registeredBy).length === 0 && index !== 0) && (
                                                <CancelIcon sx={{ color: '#f44236', fontSize: '2rem' }} />
                                            )}
                                        </Box> */}
                  </Box>
                </CardContent>
              </Card>
            ))}

          </Grid>
        </Grid>
        {isAbleToManage &&

          <Grid item xs={12} md={6} sx={{ mb: 3 }}>
            <Box sx={{
              mt: 3, mb: 3, display: 'flex', alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                mt: 0,
                mb: 2,
              }
            }}>
              <Grid item xs={12} sm={12} sx={{ my: 1 }}>
                <Typography variant="h2" sx={{
                  color: '#262626',
                  [theme.breakpoints.down('md')]: {
                    fontSize: "1.3rem",
                  }
                }}>
                  {isEdit ? "Edit" : "Add"} Parent/Guardian
                </Typography>
              </Grid>
            </Box>
            {/* {showError && (
                        <Grid item xs={12} alignItems="center" justifyContent="left">
                            <Alert severity="error" sx={{ border: "1px solid #FF0000" }}>
                                {showError}
                            </Alert>
                        </Grid>
                    )} */}

            <form onSubmit={formik.handleSubmit} ref={formContainerRef}>
              <AddEditParentGuardian isEdit={isEdit} formik={formik} />

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Box sx={{ width: "100%" }}>
                  {
                    !isEdit ?
                      <Button
                        disableElevation
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{
                          fontWeight: "600", width: "10.5%", float: "right",
                          [theme.breakpoints.down('md')]: {
                            float: "none",
                            width: "100%",
                          }
                        }}
                      >
                        Save
                      </Button> :
                      <>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Button
                            //fullWidth
                            size="large"
                            variant="outlined"
                            sx={{ fontWeight: "600" }}
                            onClick={() => { setIsEdit(false); setActiveParentId(0); formik.resetForm() }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disableElevation
                            disabled={formik.isSubmitting}
                            //fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ fontWeight: "600" }}
                          >
                            Save
                          </Button>
                        </Box>

                      </>
                  }
                </Box>
              </Grid>
            </form>

          </Grid>}
      </Grid >
    </>
  )

}

export default ManageParent
