import {
  Box,
  Typography,
  useTheme,
  Grid,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAllClientsList,
  SET_TOAST,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../store/actions";

export default function SendEmailStep3({
  onNext,
  onBack,
  step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const step3Details = state?.sendEmailCommunicate?.step3Details;
  const theme = useTheme();

  const [clients, setClients] = useState([]);
  const [selectedListIDs, setSelectedListIDs] = useState(
    step3Details?.ListIDs ?? [],
  );

  const validationSchema = Yup.object({
    //campName: Yup.string().trim().required("Event name is required"),
  });

  const handleNext = () => {
    if (selectedListIDs?.length > 0) {
      dispatch({
        type: "EMAIL_STEP_3_DETAILS",
        data: {
          ListIDs: selectedListIDs,
        },
      });
      onNext();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "Please select at least one",
        },
      });
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllClientsList(dispatch, params);
    if (response?.ok) {
      setClients(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleCheckboxChange = (listID) => {
    if (selectedListIDs.includes(listID)) {
      setSelectedListIDs(selectedListIDs.filter((id) => id !== listID));
    } else {
      setSelectedListIDs([...selectedListIDs, listID]);
    }
  };

  return (
    <Box p={6}>
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: {
            fontSize: 14,
          },
        }}
      >
        Who are you sending this email to?
      </Typography>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={12} xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        borderBottom: "none !important",
                        px: 2,
                        py: 0.5,
                      }}
                      align={"left"}
                    >
                      All lists
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        borderBottom: "none !important",
                      }}
                      align={"left"}
                    >
                      Subscribers
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients?.length ? (
                    clients.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            borderBottom: "none !important",
                            px: 1,
                            py: 0,
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Checkbox
                              checked={selectedListIDs.includes(row.ListID)}
                              onChange={() => handleCheckboxChange(row.ListID)}
                            />
                            <Typography>{row?.Name}</Typography>
                          </Box>
                        </TableCell>
                        {/* <TableCell sx={{ borderBottom: "none !important" }}>
                          <Typography>0</Typography>
                        </TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <Typography
                      variant="h4"
                      fontWeight={500}
                      fontSize={15}
                      sx={{
                        textAlign: "center",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        mt: 5,
                      }}
                    >
                      No Data Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} mt={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              width={1}
              mt={5}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: 120,
                  height: 40,
                  background: "transparent",
                  border: `2px solid ${theme?.palette?.secondary?.dark}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme?.palette?.secondary?.dark,
                  cursor: "pointer",
                  mr: 2,
                  my: 1,
                  px: 3,
                }}
                onClick={() => setShowCancelConfirmPopup(true)}
              >
                Cancel
              </Typography>

              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Typography
                  variant="h5"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: "transparent",
                    border: `2px solid ${theme?.palette?.secondary?.dark}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme?.palette?.secondary?.dark,
                    cursor: "pointer",
                    px: 3,
                    my: 1,
                  }}
                  onClick={onBack}
                >
                  Back
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  //type="submit"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: theme?.palette?.secondary?.dark,
                    color: "white",
                    ml: 2,
                    px: 3,
                    my: 1,
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
