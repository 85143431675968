import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';

export default function SideMenu({
  listItems = [],
  selectedMenuOption,
  handleMenuOptClick,
}) {

  const theme = useTheme();
  let state = useSelector((state) => state);
  const orgDetails = state?.org?.org?.data;

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: theme?.palette?.background?.paper,
        p: 2,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {listItems && listItems?.length ?
        listItems?.map((item) => (
          <ListItemButton
            key={item?.type}
            sx={{
              background: selectedMenuOption === item?.type ?
                `${orgDetails?.primary_color} !important` :
                `${theme?.palette?.background?.paper} !important`,
              borderRadius: "5px",
              width: 0.7,
              [theme.breakpoints.down('lg')]: {
                width: 0.4,
              },
              [theme.breakpoints.down('md')]: {
                width: 0.6,
              },
              [theme.breakpoints.down('sm')]: {
                width: 1,
              },
            }}
            onClick={() => handleMenuOptClick(item?.type)}
          >
            <ListItemText
              primary={item?.name}
              sx={{
                ".MuiListItemText-primary": {
                  color: selectedMenuOption === item?.type ?
                    `${theme?.palette?.background?.paper} !important` :
                    `${orgDetails?.primary_color} !important`,
                  fontFamily: "Poppins,sans-serif,Arial",
                }
              }}
            />
          </ListItemButton>
        ))
        : null
      }
    </List>
  );
}
