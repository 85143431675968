import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import Chips from "react-chips";

export default function SendPreviewModal({ open, handleClose }) {
  const theme = useTheme();

  const validationSchema = Yup.object({
    email: Yup.array()
      .of(Yup.string().email("Invalid email address"))
      .min(1, "At least one email address is required")
      .required("At least one email address is required"),
  });

  const handleSave = async (values, actions) => {
    handleClose(false, values?.email);
  };

  const formik = useFormik({
    initialValues: {
      email: [],
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={() => handleClose(true, "")}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={0.5}
          pt={1}
          sx={{
            [theme.breakpoints.down("sm")]: {
              p: 0,
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
          >
            Send Preview
          </Typography>
        </Box>
      </DialogTitle>
      <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }}>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            p: 4.5,
            [theme.breakpoints.down("sm")]: {
              p: 3,
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInputLabel htmlFor="email">Email address</CustomInputLabel>
              <Chips
                value={formik?.values?.email}
                onChange={(chips) => formik?.setFieldValue("email", chips)}
                placeholder="Enter email addresses"
              />

              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: 4.5,
            pt: 0,
            [theme.breakpoints.down("sm")]: {
              p: 3,
              pt: 0,
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
            color="inherit"
            onClick={() => handleClose(true, "")}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
