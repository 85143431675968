const initialState = {
  step: 1,
  subOrgDetails: null,
  subOrgTeamDetails: null,
  subOrgTeamManagerDetails: null,
  isAllStepsCompleted: false,
  teamLogoLgUrl: "",
  teamLogoSmUrl: "",
};

const actionTypes = {
  STEP_COUNT: "STEP_COUNT",
  SUB_ORG_DETAILS: "SUB_ORG_DETAILS",
  SUB_ORG_TEAM_THEME_DETAILS: "SUB_ORG_TEAM_THEME_DETAILS",
  SUB_ORG_TEAM_MANAGER_DETAILS: "SUB_ORG_TEAM_MANAGER_DETAILS",
  RESET_ORG_TEAMS_INFO: "RESET_ORG_TEAMS_INFO",
  SET_ALL_STEPS_COMPLETED: "SET_ALL_STEPS_COMPLETED",
  TEAM_LOGO_LG_URL: "TEAM_LOGO_LG_URL",
  TEAM_LOGO_SM_URL: "TEAM_LOGO_SM_URL",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STEP_COUNT: {
      return {
        ...state,
        step: action.data,
      };
    }
    case actionTypes.SUB_ORG_DETAILS: {
      return {
        ...state,
        subOrgDetails: action.data,
      };
    }
    case actionTypes.SUB_ORG_TEAM_THEME_DETAILS: {
      return {
        ...state,
        subOrgTeamDetails: action.data,
      };
    }
    case actionTypes.SUB_ORG_TEAM_MANAGER_DETAILS: {
      return {
        ...state,
        subOrgTeamManagerDetails: action.data,
      };
    }
    case actionTypes.SET_ALL_STEPS_COMPLETED: {
      return {
        ...state,
        isAllStepsCompleted: action.data,
      };
    }
    case actionTypes.TEAM_LOGO_LG_URL: {
      return {
        ...state,
        teamLogoLgUrl: action.data,
      };
    }
    case actionTypes.TEAM_LOGO_SM_URL: {
      return {
        ...state,
        teamLogoSmUrl: action.data,
      };
    }
    case actionTypes.RESET_ORG_TEAMS_INFO: {
      return {
        step: 1,
        subOrgDetails: null,
        subOrgTeamDetails: null,
        subOrgTeamManagerDetails: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
