import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useTheme,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  SET_SUCCESS_ERROR_POPUP,
  approveScholarshipRequestAction,
  getScholarshipDiscountsAction,
} from "../../store/actions";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessPopup from "../../components/Common/SuccessPopup";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import { Scholarship_Request } from "../../routes/constants";
import { useNavigate } from "react-router";

const EditScholarshipDiscount = ({ open, handleClose, requestData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [discountsList, setDiscountsList] = useState([]);
  const userType = state?.user?.user?.user_type;

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      discount: requestData?.discount ?? "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      approveScholarshipRequest();
    },
  });

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token && userType === "admin") {
      getAllScholarshipDiscounts();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllScholarshipDiscounts = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getScholarshipDiscountsAction(params, dispatch);
    if (response?.ok) {
      setDiscountsList(response?.data ?? []);
    }
  };

  const approveScholarshipRequest = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    const discountValue = formik?.values?.discount;
    let bodyParam = {
      id: requestData ? requestData?.id : undefined,
      status: "approved",
      discount: discountValue,
    };
    if (!discountValue) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: "Please select discount" },
      });
      return;
    }

    let response = await approveScholarshipRequestAction(
      dispatch,
      params,
      bodyParam,
    );
    try {
      if (response?.ok) {
        handleClose(false);
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message:
              "Success! Scholarship discount has been awarded to this user.",
          },
        });
        navigate(Scholarship_Request);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong! Try again",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong! Try again",
        },
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => handleClose(true)}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            pt={1}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
            >
              Scholarship Discounts
            </Typography>
          </Box>
        </DialogTitle>
        <Divider></Divider>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: 4.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomInputLabel>Apply Discount</CustomInputLabel>
                <Select
                  name="discount"
                  displayEmpty
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.discount}
                  input={
                    <OutlinedInput
                      sx={{
                        maxWidth: "250px",
                        width: "100%",
                        [theme.breakpoints.down("sm")]: {
                          maxWidth: "100%",
                        },
                      }}
                    />
                  }
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  renderValue={(selected) => {
                    if (selected && discountsList?.length) {
                      // let selectedDiscountValue = discountsList?.find((discount) => discount?.value === selected);
                      return `Scholarship Program - ${selected}%`;
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select Discount to Apply...
                        </Typography>
                      );
                    }
                  }}
                  MenuProps={MenuProps}
                  className="MuiSelect-select"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                    maxWidth: "250px",
                    width: "100%",
                    [theme.breakpoints.down("sm")]: {
                      maxWidth: "100%",
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    Select Discount to Apply...
                  </MenuItem>
                  {discountsList?.map((discount) => (
                    <MenuItem key={discount} value={discount}>
                      {`Scholarship Program - ${discount}%`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              p: 3.5,
              px: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{ width: "130px", height: "43px" }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: "130px", height: "43px" }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditScholarshipDiscount;
