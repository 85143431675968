import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Box, Container, Grid, Typography, useTheme, Dialog, DialogTitle, DialogContent, FormHelperText, DialogActions, Button } from "@mui/material";
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { useState, useEffect } from "react";
import httpClient from "@utils/httpClient";
import {
    SET_LOADER, SET_TOAST
} from '@store/actions'
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { SET_SUCCESS_ERROR_POPUP } from "../../store/actions";

const CheckoutForm = ({ formData, onOpen, onClose }) => {
    const stripe = useStripe();
    const theme = useTheme();
    const elements = useElements();
    // const [clientSecret, setClientSecret] = useState("")
    // const [transId, setTransId] = useState("")
    // const [amount, setAmount] = useState("")
    const dispatch = useDispatch();
    const { org } = useSelector((state) => state.org);

    const cardElementOptions = {
        hidePostalCode: true,
    };
    const StyledCardElement = styled(CardElement)`
    border: 1px solid #000000;
    padding: 15px;
    font-size: 18px;
    color: #000000;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  
    ::placeholder {
      color: #999999;
    }
  
    :focus,
    :hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    :invalid {
      color: #ff0000;
    }
  `;

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch({ type: SET_LOADER, data: true })
        if (!stripe || !elements) {
            return;
        }

        let cardElement = elements.getElement(CardElement);
        const result = await stripe.confirmCardPayment(formData.clientSecret, {
            payment_method: {
                card: cardElement
            },
        });
        if (result.error) {
            onClose(true, result?.error?.message || "Invalid Card")
        } else {
            const iysPaymentData = {
                team_id: formData.team_id,
                full_name: formData.full_name,
                athlete_name: formData.athlete_name,
                email: formData.email,
                transaction_id: formData.transaction_id,
                amount: formData.amount
            }
            httpClient.defaults.headers.common['X-ORG-TOKEN'] = `${org.token}`
            const apiRes = await httpClient.post(
                'team-payments/gdlTeamPayment',
                iysPaymentData
            )

            if (apiRes && apiRes.ok) {
                dispatch({ type: SET_LOADER, data: false })
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'success', message: apiRes?.message ?? "Something went wrong" }
                })
                onClose()
            }
            else {
                onClose(true, apiRes.message || "Something Went Wrong")
            }
        }
    };

    return (
        <Dialog
            open={onOpen}
            //maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2.5}
                    pt={1}
                >
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Payment Detail
                    </Typography>
                </Box>
            </DialogTitle>
            <Box
                width={1}
                height={"1px"}
                bgcolor={"gray"}
                sx={{ opacity: 0.25 }}
            ></Box>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{ paddingX: 6 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StyledCardElement options={cardElementOptions} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: 2 }}>
                    {/* <Button
                        size="small"
                        variant="contained"
                        sx={{ paddingX: 3.5, paddingY: 1.2 }}
                        color="inherit"
                        onClick={() => onClose()}
                    >
                        Close
                    </Button> */}
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                    >
                        Pay {formData.amount}$
                    </Button>
                </DialogActions>
            </form >
        </Dialog>

    );
};

const ProceedPayment = ({ formData, open, onClose }) => {
    const stripePromise = loadStripe(formData.publishableKey);
    return (
        <Elements stripe={stripePromise} >
            <CheckoutForm
                formData={formData}
                onClose={onClose}
                onOpen={open}
            />
        </Elements>
    );
};


export default ProceedPayment;