import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { SET_LOADER, SET_SUCCESS_ERROR_POPUP, getMe } from "@store/actions";
import httpClient from "@utils/httpClient";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SocialMediaProfile from "../../components/AppForms/SocialMediaProfile";
import { dateFormatForAPI } from "../../utils/functions";

const validationSchema = Yup.object({
  twitter_url: Yup.string().trim(),
  tiktok_url: Yup.string().trim(),
  insta_url: Yup.string().trim(),
  snap_url: Yup.string().trim(),
  linkedin_url: Yup.string().trim(),
  fb_url: Yup.string().trim(),
  yt_url: Yup.string().trim(),
  socialAccount: Yup.boolean().required(),
});

const SocialAccounts = ({ step, setStep, formData, setFormData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const handleSave = async (values, actions) => {
    const permission = {
      eventRegister: formData.eventRegister,
      socialAccount: values.socialAccount,
    };
    values.primary_parent = user.id;
    const postData = { ...formData, ...values, permission };
    postData.dob = postData?.dob ? dateFormatForAPI(postData?.dob) : "";
    dispatch({ type: SET_LOADER, data: true });

    const res = await httpClient.post("/addAthlete", postData);

    if (res && res.ok) {
      actions.resetForm();
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: res.message },
      });
      setFormData({});
      await dispatch(getMe());
      dispatch({ type: SET_LOADER, data: false });
      setStep(step - 2);
    } else {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "error", message: res.message },
      });
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      twitter_url: formData?.twitter_url || "",
      tiktok_url: formData?.tiktok_url || "",
      insta_url: formData?.insta_url || "",
      snap_url: formData?.snap_url || "",
      linkedin_url: formData?.linkedin_url || "",
      fb_url: formData?.fb_url || "",
      yt_url: formData?.yt_url || "",
      socialAccount: formData?.socialAccount || true,
      submittedBy: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const handlePreviousClick = () => {
    const values = formik.values;
    setFormData((prevStage) => {
      return {
        ...prevStage,
        ...values,
      };
    });
    setStep(step - 1);
  };

  return (
    <>
      <Box sx={{ mt: 3, mb: 3, display: "flex", alignItems: "center" }}>
        <Grid item xs={12} sm={12} sx={{ my: 1 }}>
          <Typography variant="h2" sx={{ color: "#262626" }}>
            Add Athlete’s Social Accounts
          </Typography>
        </Grid>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box
          className="guardian-form-box"
          sx={{
            "&.guardian-form-box div": {
              maxWidth: "100%",
            },
          }}
        >
          <SocialMediaProfile formik={formik} />
        </Box>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              mb: 4,
              "& .MuiTypography-root": {
                color: "#3B3B3B",
                textAlign: "left",
              },
              "& .MuiSwitch-thumb": {
                background: "#fff",
                height: "13px",
                width: "13px",
                marginTop: "5px",
              },
              "& .MuiSwitch-track": {
                background: "#C9C9C9",
                opacity: "1",
                height: "18px",
                borderRadius: "50px",
              },
              "& .MuiSwitch-switchBase": {
                left: "6px",
                "&.Mui-checked": {
                  transform: "translateX(15px)",
                },
                "&.Mui-checked+.MuiSwitch-track": {
                  background: "#00B60D",
                  opacity: "1",
                },
              },
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="socialAccount"
                  checked={formik.values.socialAccount}
                  onChange={formik.handleChange}
                />
              }
              label="Allow this athlete to enter their own social account links the next time they login."
            />
          </Box>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              sx={{ fontWeight: "600", width: "95%" }}
              onClick={() => handlePreviousClick()}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Button
              disableElevation
              fullWidth
              disabled={formik.isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              sx={{ fontWeight: "600", widht: "95%" }}
            >
              Save & Finished
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SocialAccounts;
