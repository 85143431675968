import { useRef, useState } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const OtpTypeScreen = ({ step, setStep, setFormData, requestOtp }) => {
    const isMounted = useRef(true)

    const [showError, setShowError] = useState(null)

    const handleClick = async (verify_type) => {
        let otp_token = null
        let goNext = false
        try {
            setShowError(null)
            const requestOtpRes = await requestOtp(verify_type)
            if (isMounted.current) {
                if (!requestOtpRes.ok && requestOtpRes.message) {
                    setShowError(requestOtpRes.message)
                } else if (verify_type === 'email') {
                    otp_token = requestOtpRes.data.otp_token || null
                    goNext = true
                } else if (verify_type === 'sms') {
                    goNext = true
                }
            } else {
                return
            }
        } catch (err) {
            setShowError('Something went wrong! Please try to refresh.')
        }
        if (goNext) {
            setFormData((prevStage) => {
                return {
                    ...prevStage,
                    verify_type,
                    otp_token,
                }
            })
            setStep(step + 1)
        }
    }

    const goBack = () => {
        setFormData((prevStage) => {
            return {
                ...prevStage,
                verify_type: null,
                otp_token: null,
            }
        })
        setStep(step - 1)
    }

    return (
        <Grid container direction="column" justifyContent="left" sx={{ p: 2 }}>
            <Box sx={{ maxWidth: '489px', margin: '0 auto', width: '100%' }}>
                <Grid item xs={12} alignItems="center" textAlign="center" sx={{ mt: 3 }}>
                    <Typography variant="h3" sx={{ fontSize: '14px', color: '#3B3B3B', }}>
                        I know, a little annoying, but let's confirm you are you.
                    </Typography>
                </Grid>
                <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body1" sx={{
                        my: 2, fontFamily: '"Poppins",sans-serif,Arial', color: '#3B3B3B', fontSize: '14px'
                    }}>
                        We need to verify your account.We do that by sending a one
                        time PIN code(OTP) to either your email or your mobile
                        device.Choose below where you'd like us to send your
                        verification code.
                    </Typography>
                </Grid>
            </Box >
            <Grid item xs={12}>
                <Box sx={{ maxWidth: '350px', margin: '0 auto', width: '100%' }}>
                    {showError && (
                        <Grid
                            item
                            xs={12}
                            alignItems="center"
                            justifyContent="left"
                        >
                            <Alert severity="error" sx={{ border: '1px solid #FF0000' }}>{showError}</Alert>
                        </Grid>
                    )}

                    {/* Buttons */}
                    <Box sx={{ mt: 3 }}>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="button"
                            variant="contained"
                            sx={{ mb: 3, fontWeight: '600' }}
                            onClick={() => handleClick('email')}
                        >
                            Send OTP to My Email
                        </Button>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="button"
                            variant="contained"
                            sx={{ mb: 3, fontWeight: '600' }}
                            onClick={() => handleClick('sms')}
                        >
                            Send OTP to My Mobile Device
                        </Button>
                    </Box>

                    {/* Links */}
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{ mt: 4 }}
                    >
                        <Typography
                            variant="subtitle1"
                            color="secondary"
                            className="pointer textUnderline"
                            align="center"
                            onClick={goBack}
                        >
                            Go Back
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        </Grid >
    )
}

export default OtpTypeScreen
