import { Button, Popover, Tooltip, Select, Typography, OutlinedInput, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { getProducts, getGrades, SET_TOAST } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import SuccessPopup from "../../components/Common/SuccessPopup";
import ActionCard from "../../components/Common/ActionCard";
import { Box, useTheme } from "@mui/system";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import ProductType from "../../components/Products/ProductType";
import { styled } from '@mui/system';
// import { charValidate } from "../../utils/functions";
const BoldHeader = styled('div')({
    fontWeight: 'bold',
});


const Products = () => {
    // const classes = useStyles();
    const tableRef = useRef();
    const [selectedProduct, setSelectedProduct] = useState();
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [paginationOptions, setPaginationOptions] = useState({
        pageSize: 0,
        pageNum: 0,
        offset: 0,
        limit: 10,
    });
    const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] = useState(false);
    const [isFromDuplicate, setIsFromDuplicate] = useState(false);
    // const { prodcutList } =
    //     useSelector((state) => state.products || [])
    const theme = useTheme();
    const dispatch = useDispatch();
    let state = useSelector((state) => state);
    const prodTypeList = [
        { id: "package", name: "Package" },
        { id: "subscription", name: "Subscription" },
        { id: "team", name: "Team" },
        { id: "league", name: "League" }
    ]
    const [productType, setProductType] = useState("")
    const [ageGrade, setAgeGrade] = useState("")
    const [gradeArr, setGradeArr] = useState([])
    const [productList, setProductList] = useState([])

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        getGradeList();
    }, [])

    useEffect(() => {
        getAllProducts()
    }, [productType, ageGrade])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const columns = [
        // {
        //     field: "sku",
        //     headerName: "SKU #",
        //     flex: 1,
        //     sortable: false,
        // },
        {
            field: "type",
            headerName: (
                <BoldHeader>
                    Type
                </BoldHeader>
            ),
            renderCell: (data) => {
                return (
                    <>
                        {data?.row?.type ? data?.row?.type.charAt(0).toUpperCase() + data?.row?.type.slice(1) : "N/A"}
                    </>
                )
            },
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: "name",
            headerName: (
                <BoldHeader>
                    Name
                </BoldHeader>
            ),
            minWidth: 330,
            flex: 1,
            sortable: false,
        },
        {
            field: "categoriesDesc",
            // headerName: "Category",
            headerName: (
                <BoldHeader>
                    Category
                </BoldHeader>
            ),
            flex: 1,
            minWidth: 120,
            renderCell: (data) => {
                return (
                    <>
                        {data?.row?.categoriesDesc[0]?.title ?? "N/A"}
                    </>
                )
            },
            sortable: false,
        },
        // {
        //     field: "desc",
        //     headerName: "Description",
        //     flex: 1,
        //     renderCell: (data) => (
        //         <Tooltip title={data?.row?.desc ?? "N/A"}>
        //             {data?.row?.desc ? charValidate(data?.row?.desc, 20) : "N/A"}
        //         </Tooltip>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: "subscriptionCost",
        //     headerName: "Subscription Cost",
        //     flex: 1,
        //     sortable: false,
        // },
        // {
        //     field: "cost",
        //     headerName: "Package Cost",
        //     flex: 1,
        //     renderCell: (data) => (
        //         <Tooltip title={data?.row?.cost ?? "N/A"}>
        //             {data?.row?.cost ? "$" + data?.row?.cost.toFixed(2) : "N/A"}
        //         </Tooltip>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: "sessions",
        //     headerName: "Sessions",
        //     flex: 1,
        //     sortable: false,
        // },
        {
            field: "actions",
            headerName: (
                <BoldHeader>
                    Actions
                </BoldHeader>
            ),
            flex: 1,
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            sortable: false,
            renderCell: (data) => {
                return (
                    <Box>
                        <SettingsIcon
                            onClick={handleClick}
                            sx={{ fontSize: 22, cursor: "pointer" }}
                            color="primary"
                        />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                p: 1,
                                "& .MuiPaper-root": { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)" }
                            }}
                        >
                            <ActionCard title={"Actions"}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        fullWidth
                                        sx={{ paddingX: 5.5, paddingY: 1 }}
                                        onClick={handleEditProductClick}
                                    >
                                        Edit Product Details
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        fullWidth
                                        sx={{ mt: 2, paddingX: 5.5, paddingY: 1 }}
                                        onClick={handleDuplicateProductClick}
                                    >
                                        Duplicate & Edit New Product
                                    </Button>
                                </Box>
                            </ActionCard>
                        </Popover>
                    </Box>
                )
            }
        },
    ];

    const handleModalClose = (isClosed) => {
        if (isClosed) {
            if (selectedProduct?.rowType === 'duplicate') {
                getAllProducts()
            }
            setShowCancelSaveConfirmPopup(true);
            //setSelectedProduct("");
        } else {
            getAllProducts()
            setShowSuccessPopup(true);
        }
    }

    const getGradeList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        let response = await getGrades(params, dispatch);
        if (response?.ok) {
            setGradeArr(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for grade data",
                },
            });
        }
    }

    const getAllProducts = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            skip: paginationOptions?.offset ?? 0,
            limit: paginationOptions?.limit ?? 10,
            type: productType,
            grade: ageGrade
        };
        let response = await getProducts(dispatch, params);
        if (response?.ok) {
            setProductList(response?.data?.data ?? []);
            setPaginationOptions({
                totalMainDocs: response?.data?.totalMainDocs ?? 0,
                totalDocs: response?.data?.totalDocs ?? 0,
                offset: response?.data?.offset ?? 0,
                limit: response?.data?.limit ?? 10,
                totalPages: response?.data?.totalPages ?? 0,
                currentPage: response?.data?.currentPage ?? 0,
                hasPrevPage: response?.data?.hasPrevPage ?? false,
                hasNextPage: response?.data?.hasNextPage ?? false,
            });
        }
    };

    const handlePagination = (values) => {
        let page = values?.page;
        let pageSize = values?.pageSize;
        paginationOptions.offset = page * pageSize
        paginationOptions.limit = pageSize ?? 10
        getAllProducts();
    };

    const handleEditProductClick = () => {
        handleClose();
        setShowAddEditModal(true);
        setIsFromDuplicate(false);
    }

    const handleDuplicateProductClick = () => {
        handleClose();
        selectedProduct.rowType = "duplicate";
        selectedProduct.name = "";
        selectedProduct.discount = [];
        selectedProduct.packs = [];
        setShowAddEditModal(true);
        setIsFromDuplicate(true);
    }

    const handleProdTypeFilterChange = (e) => {
        let { value } = e?.target;
        setProductType(value)
    };

    const handleAgeGradeFilterChange = (e) => {
        let { value } = e?.target;
        setAgeGrade(value)
    };

    return (
        <Box>
            <MuiDataGrid
                headerLabel={"Products"}
                gridData={productList}
                columns={columns}
                paginationOptions={paginationOptions}
                handlePagination={handlePagination}
                tableRef={tableRef}
                isLoading={productList ? false : true}
                onClickAddNew={() => { setShowAddEditModal(true); setSelectedProduct("") }}
                handleRowClick={(data) => setSelectedProduct(data?.row)}
                productTypeList={prodTypeList}
                handleProductTypeFilterChange={handleProdTypeFilterChange}
                isShowProductTypeFilter={true}
                isShowProductPurchaseBtn={true}
                gridProducts={() => {
                    return (
                        <Box display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Select
                                labelId="age-grade-filter"
                                onChange={handleAgeGradeFilterChange}
                                input={
                                    <OutlinedInput
                                        //placeholder="Select grade"
                                        sx={{ width: "100%", height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                displayEmpty
                                MenuProps={MenuProps}
                                renderValue={(value) => {
                                    if (value) {
                                        let selected = gradeArr?.find((grade) => grade?.id === value);
                                        if (selected) {
                                            return selected?.name;
                                        }
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Filter by grade...
                                            </Typography>
                                        );
                                    }
                                    //return "All";
                                }}
                            >
                                <MenuItem value={""}>All</MenuItem>
                                {gradeArr?.map((grade) => (
                                    <MenuItem key={grade?.id} value={grade?.id}>
                                        {grade?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    )
                }}
            />
            {showAddEditModal &&
                <ProductType
                    open={showAddEditModal}
                    handleClose={handleModalClose}
                    productData={selectedProduct}
                    isFromDuplicate={isFromDuplicate}
                />
            }
            {showSuccessPopup &&
                <SuccessPopup
                    title={"Products"}
                    message={"Success! Product details have been added/updated."}
                    open={showSuccessPopup}
                    handleClose={() => {
                        setShowSuccessPopup(false);
                        setShowAddEditModal(false);
                    }}
                />
            }

            {showCancelSaveConfirmPopup && (
                <CancelConfirmDialog
                    title={"Products"}
                    message={"Are you sure you want to close without saving?"}
                    open={showCancelSaveConfirmPopup}
                    handleClose={() => setShowCancelSaveConfirmPopup(false)}
                    handleCancel={() => {
                        setShowCancelSaveConfirmPopup(false);
                        setShowAddEditModal(true);
                    }}
                    handleConfirmClose={() => {
                        setSelectedProduct("");
                        setShowCancelSaveConfirmPopup(false);
                        setShowAddEditModal(false);
                    }}
                />
            )}
        </Box>
    );
};

export default Products;
