import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { APP_MESSAGES } from "../../utils/constants";
import { useFormik } from "formik";
import { SET_TOAST, uploadFileAction, SET_SUCCESS_ERROR_POPUP } from "../../store/actions";
import { getFileNameFromFirebaseUrl } from "../../utils/functions";

const SubOrgTeamsAsstets = ({ handleClose, data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const subOrgTeams = state?.subOrgTeams;
  const [lgLogoFileName, setLgLogoFileName] = useState(
    data?.largeLogo ? getFileNameFromFirebaseUrl(data?.largeLogo) :
      subOrgTeams?.teamLogoLgUrl ?
        getFileNameFromFirebaseUrl(subOrgTeams?.teamLogoLgUrl) :
        ""
  );
  const [smLogoFileName, setSmLogoFileName] = useState(
    data?.smallLogo ? getFileNameFromFirebaseUrl(data?.smallLogo) :
      subOrgTeams?.teamLogoSmUrl ?
        getFileNameFromFirebaseUrl(subOrgTeams?.teamLogoSmUrl) :
        ""
  );

  const validationSchema = Yup.object({
    // Sub Org Team's Theme Details
    //subOrgTeamLogoLg: Yup.string().trim().required("Team Logo is required"),
    //subOrgTeamLogoSm: Yup.string().trim().required("Team Logo is required"),
    subOrgTeamPrimaryColor: Yup.string()
      .trim()
      .required("Team Primary Color is required"),
    subOrgTeamSecondaryColor: Yup.string()
      .trim()
      .required("Team Secondary Color is required"),
  });

  const formik = useFormik({
    initialValues: {
      subOrgTeamLogoLg: "",
      subOrgTeamLogoSm: "",
      subOrgTeamPrimaryColor:
        data?.primary_color ??
        subOrgTeams?.subOrgTeamDetails?.subOrgTeamPrimaryColor ??
        "",
      subOrgTeamSecondaryColor:
        data?.secondary_color ??
        subOrgTeams?.subOrgTeamDetails?.subOrgTeamSecondaryColor ??
        "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "STEP_COUNT",
        data: 3,
      });
      dispatch({
        type: "SUB_ORG_TEAM_THEME_DETAILS",
        data: values,
      });
    },
  });

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  useEffect(() => {
    if (subOrgTeams?.isAllStepsCompleted) {
      formik.resetForm();
    }
  }, [subOrgTeams?.isAllStepsCompleted]);

  const uploadImage = async (logoType, file) => {
    if (logoType && file) {
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      };
      let formData = new FormData();
      formData.append("files", file);
      formData.append("type", "SubOrgTeam");
      formData.append("fileType", "Thumbnail");
      formData.append("userId", state?.user?.user?.id);
      let response = await uploadFileAction(dispatch, params, formData);
      if (response?.ok) {
        if (logoType === "LG") {
          dispatch({
            type: "TEAM_LOGO_LG_URL",
            data: response?.url ?? "",
          });
          setLgLogoFileName(getFileNameFromFirebaseUrl(response?.url));
        } else {
          dispatch({
            type: "TEAM_LOGO_SM_URL",
            data: response?.url ?? "",
          });
          setSmLogoFileName(getFileNameFromFirebaseUrl(response?.url));
        }
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
        })
      }
    }
  }

  const handleSteps = () => {
    dispatch({
      type: "STEP_COUNT",
      data: subOrgTeams?.step - 1,
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} mt={0.001}>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="sub-org-team-logo-lg" sx={inputLabelStyle}>
            ORGANIZATION LOGO (LARGE)
          </CustomInputLabel>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.subOrgTeamLogoLg && formik.errors.subOrgTeamLogoLg,
            )}
            sx={{
              width: "100%",
              "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                height: "45px",
                padding: "12.5px 10px",
                width: "100%",
              },
              "& .MuiIconButton-root": {
                background: "transparent",
                borderRadius: "0 4px 4px 0",
                padding: 0,
                left: "auto",
                right: "-1px",
              },
            }}
          >
            <InputLabel
              htmlFor="sub-org-team-logo-lg"
              shrink={false}
              sx={{
                fontFamily: "'Poppins', sans-serif, Arial",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              {lgLogoFileName
                ? lgLogoFileName
                : "Upload main logo..."}
            </InputLabel>
            <OutlinedInput
              id="sub-org-team-logo-lg"
              name="subOrgTeamLogoLg"
              type={"file"}
              value={formik.values.subOrgTeamLogoLg}
              onBlur={formik.handleBlur}
              onChange={(e) => uploadImage("LG", e?.target?.files[0])}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="subOrgTeamLogoLg"
                    edge="end"
                    size="large"
                    htmlFor="sub-org-team-logo-lg"
                    component="label"
                  >
                    <Box
                      sx={{
                        width: 47,
                        height: 47,
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      htmlFor="sub-org-team-logo-lg"
                    >
                      <UploadFileIcon sx={{ fontSize: 20, color: "white" }} />
                    </Box>
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                "& ::file-selector-button": {
                  opacity: 0,
                  display: "none",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                  opacity: 0,
                },
                "& .MuiInputBase-input": {
                  padding: "13.5px 10px !important",
                },
              }}
              inputProps={{
                style: {
                  opacity: 0,
                },
                accept: "image/*",
              }}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {APP_MESSAGES.commentPicMaxSizeTxt}
            </Typography>
            {Boolean(
              formik.touched.subOrgTeamLogoLg && formik.errors.subOrgTeamLogoLg,
            ) && (
                <FormHelperText error>
                  {formik.errors.subOrgTeamLogoLg ||
                    "File size is greater than maximum limit"}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="city" sx={inputLabelStyle}>
            ORGANIZATION LOGO (SMALL)
          </CustomInputLabel>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.subOrgTeamLogoSm && formik.errors.subOrgTeamLogoSm,
            )}
            sx={{
              width: "100%",
              "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                height: "45px",
                padding: "12.5px 10px",
                width: "100%",
              },
              "& .MuiIconButton-root": {
                background: "transparent",
                borderRadius: "0 4px 4px 0",
                padding: 0,
                left: "auto",
                right: "-1px",
              },
            }}
          >
            <InputLabel
              htmlFor="sub-org-team-logo-lg"
              shrink={false}
              sx={{
                fontFamily: "'Poppins', sans-serif, Arial",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              {smLogoFileName
                ? smLogoFileName
                : "Upload secondary logo..."}
            </InputLabel>
            <OutlinedInput
              id="sub-org-team-logo-sm"
              name="subOrgTeamLogoSm"
              type={"file"}
              value={formik.values.subOrgTeamLogoSm}
              onBlur={formik.handleBlur}
              onChange={(e) => uploadImage("SM", e?.target?.files[0])}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="subOrgTeamLogoSm"
                    edge="end"
                    size="large"
                    htmlFor="sub-org-team-logo-sm"
                    component="label"
                  >
                    <Box
                      sx={{
                        width: 47,
                        height: 47,
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      htmlFor="sub-org-team-logo-sm"
                    >
                      <UploadFileIcon sx={{ fontSize: 20, color: "white" }} />
                    </Box>
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                "& ::file-selector-button": {
                  opacity: 0,
                  display: "none",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                  opacity: 0,
                },
                "& .MuiInputBase-input": {
                  padding: "13.5px 10px !important",
                },
              }}
              inputProps={{
                style: {
                  opacity: 0,
                },
                accept: "image/*",
              }}
            />
            <Typography variant="caption" display="block" gutterBottom>
              {APP_MESSAGES.commentPicMaxSizeTxt}
            </Typography>
            {Boolean(
              formik.touched.subOrgTeamLogoSm && formik.errors.subOrgTeamLogoSm,
            ) && (
                <FormHelperText error>
                  {formik.errors.subOrgTeamLogoSm ||
                    "File size is greater than maximum limit"}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="address-1" sx={inputLabelStyle}>
            CHOOSE PRIMARY COLOR
          </CustomInputLabel>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.subOrgTeamPrimaryColor &&
              formik.errors.subOrgTeamPrimaryColor,
            )}
            sx={{
              width: "100%",
              "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                height: "45px",
                padding: "12.5px 10px",
                width: "100%",
              },
              "& .MuiIconButton-root": {
                background: "transparent",
                borderRadius: "0 4px 4px 0",
                padding: 0,
                left: "auto",
                right: "-1px",
              },
            }}
          >
            <InputLabel
              htmlFor="sub-org-team-primary-color"
              shrink={false}
              sx={{
                fontFamily: "'Poppins', sans-serif, Arial",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              {formik?.values?.subOrgTeamPrimaryColor
                ? formik?.values?.subOrgTeamPrimaryColor
                : "Choose color..."}
            </InputLabel>
            <OutlinedInput
              id="sub-org-team-primary-color"
              name="subOrgTeamPrimaryColor"
              type={"color"}
              value={formik.values.subOrgTeamPrimaryColor}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="subOrgTeamPrimaryColor"
                    edge="end"
                    size="large"
                    htmlFor="sub-org-team-primary-color"
                    component="label"
                  >
                    <Box
                      sx={{
                        width: 47,
                        height: 47,
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      htmlFor="sub-org-team-primary-color"
                    >
                      <ColorizeIcon sx={{ fontSize: 20, color: "white" }} />
                    </Box>
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                "& ::file-selector-button": {
                  opacity: 0,
                  display: "none",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                  opacity: 0,
                },
                "& .MuiInputBase-input": {
                  padding: "13.5px 10px !important",
                },
              }}
              inputProps={{
                style: {
                  opacity: 0,
                },
              }}
            />
            {Boolean(
              formik.touched.subOrgTeamPrimaryColor &&
              formik.errors.subOrgTeamPrimaryColor,
            ) && (
                <FormHelperText error>
                  {formik.errors.subOrgTeamPrimaryColor}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomInputLabel htmlFor="state" sx={inputLabelStyle}>
            CHOOSE SECONDARY COLOR
          </CustomInputLabel>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.subOrgTeamSecondaryColor &&
              formik.errors.subOrgTeamSecondaryColor,
            )}
            sx={{
              width: "100%",
              "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                height: "45px",
                padding: "12.5px 10px",
                width: "100%",
              },
              "& .MuiIconButton-root": {
                background: "transparent",
                borderRadius: "0 4px 4px 0",
                padding: 0,
                left: "auto",
                right: "-1px",
              },
            }}
          >
            <InputLabel
              htmlFor="sub-org-team-secondary-color"
              shrink={false}
              sx={{
                fontFamily: "'Poppins', sans-serif, Arial",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              {formik?.values?.subOrgTeamSecondaryColor
                ? formik?.values?.subOrgTeamSecondaryColor
                : "Choose color..."}
            </InputLabel>
            <OutlinedInput
              id="sub-org-team-secondary-color"
              name="subOrgTeamSecondaryColor"
              type={"color"}
              value={formik.values.subOrgTeamSecondaryColor}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="subOrgTeamSecondaryColor"
                    edge="end"
                    size="large"
                    htmlFor="sub-org-team-secondary-color"
                    component="label"
                  >
                    <Box
                      sx={{
                        width: 47,
                        height: 47,
                        backgroundColor:
                          theme?.palette?.text?.containerTextLabel,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      htmlFor="sub-org-team-secondary-color"
                    >
                      <ColorizeIcon sx={{ fontSize: 20, color: "white" }} />
                    </Box>
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                "& ::file-selector-button": {
                  opacity: 0,
                  display: "none",
                },
                "& .MuiOutlinedInput-root": {
                  border: `1px solid ${theme?.palette?.grey?.[400]}`,
                  width: "100%",
                  boxShadow: "none",
                  opacity: 0,
                },
                "& .MuiInputBase-input": {
                  padding: "13.5px 10px !important",
                },
              }}
              inputProps={{
                style: {
                  opacity: 0,
                },
              }}
            />
            {Boolean(
              formik.touched.subOrgTeamSecondaryColor &&
              formik.errors.subOrgTeamSecondaryColor,
            ) && (
                <FormHelperText error>
                  {formik.errors.subOrgTeamSecondaryColor}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          pt: 3,
          [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
          }
        }}
      >
        <Button
          size="small"
          variant="outlined"
          sx={{ paddingX: 3.5, paddingY: 1.2 }}
          color="inherit"
          onClick={handleSteps}
        >
          Back
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2 }}
          color="inherit"
          onClick={() => handleClose(true)}
        >
          Close
        </Button>
        <Button
          size="small"
          variant="contained"
          type="submit"
          sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

export default SubOrgTeamsAsstets;
