import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
  FormHelperText,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TOAST,
  createTeamCoachAction,
  updateTeamAthleteDetailsAction,
  SET_SUCCESS_ERROR_POPUP,
  getAllPositionsList,
} from "../../store/actions";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../components/CustomTextField";
import { useMutation } from "react-query";
import { useEffect } from "react";

const ChangeJerseyPosition = ({
  open,
  handleClose,
  selectedAthlete,
  teamId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userType = state?.user?.user?.user_type;
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };

  const [positionsList, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(
    selectedAthlete?.position?.map((x) => x.id) ?? [],
  );
  const { mutateAsync: getAllPositions } = useMutation(getAllPositionsList);
  const selectionInputStyle = {
    width: "100%",
    minWidth: "300px",
    height: "44px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  const validationSchema = Yup.object({
    jerseyNo: Yup.number()
      .integer("This field should contain an integer")
      .min(0, "Jersey number must be greater than or equal to 0"),
  });

  useEffect(() => {
    getPositions();
  }, []);

  const formik = useFormik({
    initialValues: {
      jerseyNo: selectedAthlete?.jersey ?? "",
      //position: selectedAthlete?.position ?? [],
      weight: selectedAthlete?.weight ?? "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!teamId) {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: "Something went wrong! go back and reload the page",
          },
        });
        return;
      }
      let bodyParam = {
        jersey: values?.jerseyNo ?? "",
        position: selectedPosition,
        weight: values?.weight ?? "",
        team_id: teamId,
        userId: selectedAthlete?.id,
      };
      handleSave(bodyParam);
    },
  });

  const handleSave = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    try {
      let response = await updateTeamAthleteDetailsAction(
        dispatch,
        params,
        bodyParams,
      );
      if (response?.ok) {
        handleClose(false, response?.data?.id);
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "success",
            message: response?.message ?? "Success!",
          },
        });
        handleClose(false);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong",
        },
      });
    }
  };

  const getPositions = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllPositions({ dispatch, params });
    if (response?.ok) {
      setPositions(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong for grade data",
        },
      });
    }
  };

  const handleChangePositionSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPosition(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="sm"
      fullWidth
      onClose={() => handleClose(true)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box
          // display="flex"
          // justifyContent="space-between"
          // alignItems="center"
          px={2.5}
          pt={1}
          sx={{
            [theme.breakpoints.down("md")]: {
              paddingX: "0",
            },
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: "18px",
              },
            }}
          >
            Change: Position or Jersey Number
          </Typography>
          <Typography
            variant="h6"
            fontWeight={500}
            fontSize={14}
            color={theme?.palette?.secondary?.darkLabel_1}
            sx={{ mt: 0.8 }}
          >
            Roster:&nbsp;&nbsp;{selectedAthlete?.first_name ?? "N/A"}{" "}
            {selectedAthlete?.last_name ?? "N/A"}
          </Typography>
        </Box>
      </DialogTitle>
      <Box
        width={1}
        height={"1px"}
        bgcolor={"gray"}
        mb={3}
        sx={{
          opacity: 0.25,
          [theme.breakpoints.down("md")]: {
            mb: 0,
          },
        }}
      >
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </Box>
      {/* <Box
        width={1}
        height={"1px"}
        bgcolor={"gray"}
        sx={{ opacity: 0.25 }}
      ></Box> */}
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            paddingX: 6,
            [theme.breakpoints.down("md")]: {
              paddingX: 3,
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <CustomInputLabel sx={inputLabelStyle} htmlFor="position">
                Position
              </CustomInputLabel>
              <Select
                multiple
                displayEmpty
                name="position"
                value={selectedPosition}
                onChange={handleChangePositionSelection}
                input={
                  <OutlinedInput
                    label=""
                    placeholder="Select position..."
                    sx={selectionInputStyle}
                  />
                }
                inputProps={{
                  "aria-label": "Without label",
                }}
                renderValue={(selected) => {
                  if (selected?.length && positionsList?.length) {
                    let positions = [];
                    positionsList?.forEach((position) => {
                      if (selected.includes(position?.id)) {
                        positions.push(position?.name);
                      }
                    });
                    if (positions?.length) {
                      return positions.join(", ");
                    }
                    return selected?.join(", ");
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select one or more position...
                      </Typography>
                    );
                  }
                }}
                MenuProps={MenuProps}
              >
                {positionsList && positionsList?.length
                  ? positionsList?.map((position) => (
                      <MenuItem key={position?.id} value={position?.id}>
                        <Checkbox
                          checked={selectedPosition.indexOf(position?.id) > -1}
                        />
                        <ListItemText primary={position?.name} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInputLabel sx={inputLabelStyle} htmlFor="jerseyNo">
                Jersey Number
              </CustomInputLabel>
              <CustomTextField
                type="number"
                id="jerseyNo"
                name="jerseyNo"
                placeholder="Enter jersey no...."
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.jerseyNo}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme?.palette?.grey?.[400]}`,
                    width: "100%",
                    boxShadow: "none",
                  },
                  width: "100%",
                }}
              />
              {Boolean(formik.touched.jerseyNo && formik.errors.jerseyNo) && (
                <FormHelperText error>{formik.errors.jerseyNo}</FormHelperText>
              )}
            </Grid>
            {userType === "admin" && (
              <Grid item md={6} xs={12}>
                <CustomInputLabel sx={inputLabelStyle} htmlFor="weight">
                  Weight
                </CustomInputLabel>
                <CustomTextField
                  type="number"
                  id="weight"
                  name="weight"
                  placeholder="Enter weight...."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.weight}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                    width: "100%",
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            mt: 4,
            [theme.breakpoints.down("md")]: {
              mt: 0,
              pt: 0,
            },
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1.2, mr: 1.5 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            sx={{ paddingX: 3.5, paddingY: 1.2 }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeJerseyPosition;
