import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    useTheme, Grid, Select, OutlinedInput, MenuItem, Button, Switch, FormControlLabel
} from "@mui/material";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import PackageForm from "./PackageForm";
import PackageSession from "./PackageSession";
import PackageAgeGroup from "./PackageAgeGroup";
import ProductCategory from "./ProductCategory";
import { styled } from "@mui/system";
import SubscriptionForm from "./SubscriptionForm";
import SubscriptionSession from "./SubscriptionSession";
import TeamForm from "./TeamForm";
import TeamDiscount from "./TeamDiscount";
import PackagePack from "./PackagePack";

const ProductType = ({ open, handleClose, productData, isFromDuplicate }) => {
    const theme = useTheme();
    const state = useSelector((state) => state);
    const [selectedType, setSelectedType] = useState(
        productData.type === "package" ? '1' : productData.type === "subscription" ? '2' : productData.type === "team" ? '3' : '1'
    );
    const [step, setStep] = useState(1)
    const [formData, setFormData] = useState(productData?.id ? productData : {})
    const [activeProduct, setActiveProduct] = useState(productData?.id ? productData?.is_activated : true);

    const CustomSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: "#00B60D",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleSave = () => {
        if (selectedType) {
            if (selectedType === '1') {
                setStep(2);
            } else if (selectedType === '2') {
                setStep(6);
            } else if (selectedType === '3') {
                setStep(8);
            }
        }
    };

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="md"
            fullWidth
            //onClose={() => handleClose(true)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2.5}
                    py={2}
                >
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={20}
                        color={theme?.palette?.text?.containerTextLabel}
                    >
                        Products
                    </Typography>
                    {/* <Box position="relative" sx={{ cursor: "pointer" }}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={activeProduct}
                                    onChange={(e) => setActiveProduct(e?.target?.checked)}
                                    size="sm"
                                    sx={{ m: 1 }}
                                />
                            }
                            label="Activated"
                            labelPlacement="start"
                            sx={{
                                "& .MuiTypography-root": {
                                    color: theme?.palette?.text?.containerTextLabel,
                                    fontWeight: 400,
                                    fontFamily: "inherit",
                                },
                            }}
                        />
                    </Box> */}
                </Box>
            </DialogTitle>
            <Box
                width={1}
                height={"1px"}
                bgcolor={"gray"}
                sx={{ opacity: 0.25 }}
            ></Box>
            <DialogContent sx={{ p: 4 }}>
                {
                    step === 1 &&
                    <>
                        <Grid item xs={6} mt={1}>
                            <CustomInputLabel htmlFor="org-filter">
                                CHOOSE PRODUCT TYPE
                            </CustomInputLabel>
                            <Select
                                name="subOrg"
                                labelId="org-filter"
                                value={selectedType}
                                onChange={(event) => setSelectedType(event.target.value)}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Product Type"
                                        sx={{ maxWidth: 350, width: "100%", height: 45 }}
                                    />
                                }
                                MenuProps={MenuProps}
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                displayEmpty
                                disabled={productData?.id ? true : false}
                            >
                                <MenuItem value="1"> Package</MenuItem>
                                <MenuItem value="2"> Subscription</MenuItem>
                                <MenuItem value="3"> Team</MenuItem>
                                <MenuItem value="4"> League</MenuItem>
                            </Select>
                        </Grid>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            sx={{ mt: 4 }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ paddingX: 3.5, paddingY: 1.2, mx: 2, marginRight: 'auto !important', marginLeft: '0 !important' }}
                                color="inherit"
                                onClick={() => handleClose(true)}
                            >
                                Close
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleSave}
                                sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                }
                {
                    step === 2 &&
                    <PackageForm
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                {
                    step === 3 &&
                    <PackageSession
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                {
                    step === 4 &&
                    <PackageAgeGroup
                        handleClose={handleClose}
                        setStep={setStep}
                        activeProduct={activeProduct}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                {
                    step === 5 &&
                    <ProductCategory
                        setStep={setStep}
                    />
                }
                {
                    step === 6 &&
                    <SubscriptionForm
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                {
                    step === 7 &&
                    <SubscriptionSession
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                        activeProduct={activeProduct}
                    />
                }
                {
                    step === 8 &&
                    <TeamForm
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                        activeProduct={activeProduct}
                    />
                }
                {
                    step === 9 &&
                    <TeamDiscount
                        handleClose={handleClose}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                        step={step}
                    />
                }
                {
                    step === 10 &&
                    <PackagePack
                        handleClose={handleClose}
                        setStep={setStep}
                        activeProduct={activeProduct}
                        formData={formData}
                        setFormData={setFormData}
                        isFromDuplicate={isFromDuplicate}
                    />
                }
            </DialogContent>
        </Dialog>
    );
};

export default ProductType;