import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageLoader from "@components/PageLoader";
import NotificationPopup from "@components/NotificationPopup";
import ToastMessage from "@components/ToastMessage";
import { Box, Grid } from "@mui/material";
import sketchplayLogo from "../../assets/images/sketchPlay.png";
import Coltslogo from "../../assets/images/colts-logo.png";
import GDLGoldLogo from "../../assets/images/GDL_Gold_Logo.png";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { getOrgData } from "@store/actions";
import { useTheme, useMediaQuery } from "@mui/material";
// import { Box } from '@mui/system'
import SuccessErrorPopup from "../../components/Common/SuccessErrorPopup";

const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  // minHeight: '100vh',
  "& .pointer": {
    cursor: "pointer",
  },
  "& .textUnderline": {
    textDecoration: "underline",
    textDecorationColor: "secondary",
  },
  "& .d-flex": {
    display: "flex",
  },
}));

const MinimalLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const showLoader = useSelector((state) => state.theme.showLoader);
  const org = useSelector((state) => (state.org.org ? state.org.org.data : {}));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const showPopup = useSelector((state) => state.theme.successErrorPopup);
  // const [getImage, setImage] = useState("https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRoUjtWGvtxMHPZxmFfv7_-dn3WhzdlfrCahVUDa7wBhDChQkVA")

  useEffect(() => {
    dispatch(getOrgData());
  }, []);

  const redirectToSketchPlay = () => {
    window.open("https://sketchplay.com/", "_blank");
  };

  const redirectToColtsOrGDL = () => {
    if (
      location?.pathname === "/iysregistration" ||
      location?.pathname === "/gdlregistration"
    ) {
      window.open("https://gridironleagues.org/", "_blank");
    } else {
      window.open("https://www.colts.com/", "_blank");
    }
  };

  return (
    <>
      <Helmet
        defaultTitle="App"
        titleTemplate="%s App"
        title={org.title}
      // sx={{ minHeight: "100vh" }}
      />
      <Box>
        {isMobile ? null : (
          <Box
            position="fixed"
            left={0}
            width={0.4}
            sx={{
              backgroundColor: location?.pathname === "/buy-product" ? theme.palette.secondary.dark : `${org?.primary_color}`,
              background: location?.pathname === "/buy-product" ? theme.palette.secondary.dark : `${org?.primary_color}`,
              height: "100%",
              display: "grid",
              alignItems: "center",
            }}
          >
            {/* <Grid
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Grid container>
                                <Grid item xs={12} md={12} sx={{ p: 2, mb: 1, background: "black" }}>
                                    <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={sketchplayLogo} alt="SketchPlay Logo" style={{ cursor: "pointer" }} onClick={redirectToSketchPlay} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 2, background: "#042D5E" }}>
                                    <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={Coltslogo} alt="GridIron Logo" style={{ cursor: "pointer" }} onClick={redirectToColts} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid> */}
            <Box p={3}>
              <Box
                onClick={redirectToSketchPlay}
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                mb={3}
                border={"1px solid darkgray"}
                bgcolor={"black"}
                sx={{ height: "calc(45vh - 12px)", cursor: "pointer" }}
              >
                <img
                  src={sketchplayLogo}
                  alt="SketchPlay Logo"
                  style={{ maxWidth: '100%', width: 'auto', objectFit: 'cover', maxHeight: '100%' }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                mt={3}
                bgcolor={
                  location?.pathname === "/iysregistration" ||
                    location?.pathname === "/gdlregistration"
                    ? "#fff"
                    : "#042D5E"
                }
                sx={{ height: "calc(45vh - 12px)", cursor: "pointer" }}
                onClick={redirectToColtsOrGDL}
              >
                <img
                  src={
                    location?.pathname === "/iysregistration" ||
                      location?.pathname === "/gdlregistration"
                      ? GDLGoldLogo
                      : Coltslogo
                  }
                  alt="GDL-Gold-Logo"
                  // style={{
                  //   width:
                  //     location?.pathname === "/iysregistration" ||
                  //       location?.pathname === "/gdlregistration"
                  //       ? "25rem"
                  //       : "26rem",
                  // }}
                  style={{ maxWidth: '100%', width: 'auto', objectFit: 'cover', maxHeight: '100%' }}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box
          width={isMobile ? 1 : 0.6}
          sx={{
            backgroundColor: "#F4F6F9",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: isMobile ? "relative" : "absolute",
            right: 0,
            minHeight: "100vh"
          }}
        >
          <Wrapper>
            <Outlet />
          </Wrapper>
          {/* <Box item xs={12} sx={{ textAlign: "center", mt: 'auto', mb: 3, svg: { mr: 2, cursor: "pointer", color: "#0063A8" } }}>
                        <a href={org?.fb_url} target='_blank'>
                            <FacebookIcon />
                        </a>
                        <a href={org?.twitter_url} target='_blank'>
                            <TwitterIcon />
                        </a>
                        <a href={org?.tiktok_url} target='_blank'>
                            <MusicNoteIcon />
                        </a>
                        <a href={org?.instagram_url} target='_blank'>
                            <InstagramIcon />
                        </a>
                        <a href={org?.hype_video_url} target='_blank'>
                            <YouTubeIcon />
                        </a>
                    </Box> */}
        </Box>
      </Box>
      {/* notification popup */}
      <NotificationPopup />

      {showLoader && <PageLoader />}
      <ToastMessage />
      {showPopup?.open && <SuccessErrorPopup />}
    </>
  );
};
export default MinimalLayout;
