import {
    Box,
    Typography,
    Grid,
    Divider,
    FormControl,
    TextField,
    Button,
} from "@mui/material";
import ComingSoon from "../../components/ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import TiktokIcon from "../../assets/images/social-links/tiktok.png";
import TwitterIcon from "../../assets/images/social-links/twitter.png";
import InstaIcon from "../../assets/images/social-links/instagram.png";
import SnapIcon from "../../assets/images/social-links/snapchat.png";
import FacebookIcon from "../../assets/images/social-links/facebook.png";
import LinkedInIcon from "../../assets/images/social-links/linkedin.png"
import YouTubeIcon from "@mui/icons-material/YouTube";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTheme } from "@mui/material/styles";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Tile from "../../assets/images/Tile.png";
import Employee from "../../assets/images/employee.png";
import { getMe, SET_USER_DRAWER } from "../../store/actions";
import * as pages from '@routes/constants'
import { useNavigate } from "react-router";
import { charValidate } from '@utils/functions'

function DashboardForCoach() {
    const { user, token } = useSelector((state) => state.user);
    const org = useSelector((state) =>
        state.org.org ? state.org.org.data : {}
    )
    const drawerOpened = useSelector((state) => state.theme.drawer);
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSocialLink = org ? (org?.web_menu_settings?.coach[0]?.dashboard.filter(x => x.id === 'social')[0].externalLink) : "";
    const mainBox = {
        width: "100%",
        backgroundColor: "white",
        boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
        border: "none",
        padding: "1px",
        borderRadius: "4px",
        borderColor: "transparent",
        mt: 2,
        px: 2.5,
        py: 2,
        color: theme?.palette?.dark?.dark3,
        display: "flex",
        alignItems: "center",
    };
    const contentBox = {
        width: "50%",
        minWidth: 130,
        height: 180,
        minHeight: 100,
        backgroundColor: "white",
        boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
        border: "none",
        borderRadius: "4px",
        borderColor: "transparent",
        textAlign: "center",
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer"
    };
    const iconStyle = {
        color: theme?.palette?.dark?.dark3,
        fontSize: "30px",
    };
    const titleStyle = {
        fontStyle: "italic",
        fontSize: "15px",
        color: theme?.palette?.secondary?.dark,
        fontWeight: "900",
        mb: 1,
        mt: 1.5,
    };
    const subTitleStyle = {
        fontFamily: "Poppins",
        fontSize: "12px",
        color: theme?.palette?.secondary?.dark,
        fontWeight: 500,
        my: 1
    };

    useEffect(() => {
        dispatch(getMe())
    }, [user?.user_type])

    return (
        <Box>
            <Grid
                container
                spacing={2}
                sx={{ my: 2, alignSelf: "center", justifySelf: "center" }}
            >
                <Grid item xs={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        sx={{
                            p: 3,
                            "& .MuiOutlinedInput-root": {
                                border: "1px solid #dfe0df",
                                boxShadow: "4px 5px 11px rgba(162, 188, 244, 0.4)",
                                background: "#fff",
                                "& .MuiOutlinedInput-input": {
                                    background: "transparent",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "0",
                                    boxShadow: "none",
                                },
                            },
                            "& .MuiFormControl-root .MuiFormControl-root": {
                                boxShadow: "none",
                            },
                            span: {
                                display: "block",
                                "font-size": "25px",
                                "font-weight": 700,
                                "line-height": "57px",
                                /* text-align: center; */
                                height: "60px",
                                width: "60px",
                                pl: "12px",
                            },
                            figure: {
                                margin: 0,
                                height: "60px",
                                width: "60px",
                                "min-width": "30px",
                                "border-radius": "50rem",
                                position: "relative",
                                overflow: "hidden",
                                border: "3px solid #fff",
                                "box-shadow": "0 0 15px rgba(0, 0, 0, 0.25)",
                                left: "50%",
                                "-webkit-transform": "translate(-50%)",
                                "-moz-transform": "translate(-50%)",
                                "-ms-transform": "translate(-50%)",
                                transform: "translate(-50%)",
                            },
                            "#userImg": {
                                width: "100%",
                                height: "100%",
                            },
                            "#save": {
                                py: 1.5,
                                px: 3.5,
                                fontSize: 15,
                                fontWeight: 700,
                                mt: 2,
                            },
                        }}
                    >
                        {user?.image ?
                            <Box
                                sx={{
                                    border: `3px solid ${theme?.palette?.background?.paper}`,
                                    boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                                    textTransform: "uppercase",
                                    height: 70,
                                    width: 70,
                                    borderRadius: "100%"
                                }}
                            >
                                <img
                                    src={user?.image}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "100%",
                                        objectFit: "cover"
                                    }}
                                />
                            </Box> :
                            <Box
                                component="span"
                                sx={{
                                    backgroundColor: theme?.palette?.secondary?.dark,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: theme?.palette?.background?.paper,
                                    border: `3px solid ${theme?.palette?.background?.paper}`,
                                    boxShadow: "0 0 15px rgba(0, 0, 0, 0.25)",
                                    textTransform: "uppercase",
                                    height: 70,
                                    width: 70,
                                    borderRadius: "100%"
                                }}
                            >
                                {`${user?.first_name?.charAt(
                                    0,
                                )}${user?.last_name?.charAt(0)}`}
                            </Box>
                        }
                        <Typography
                            variant="h3"
                            sx={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: theme?.palette?.dark?.dark3,
                                py: 2,
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                            }}
                        >
                            {`${user?.first_name} ${user?.last_name}`}
                        </Typography>
                        {
                            user.bio ?
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        color: theme?.palette?.dark?.dark3,
                                        fontWeight: 400,
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    {charValidate(user.bio, 100)}
                                </Typography> :
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        color: theme?.palette?.dark?.dark3,
                                        textDecoration: "underline",
                                        fontWeight: 400,
                                        fontFamily: "Poppins, sans-serif",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        dispatch({ type: SET_USER_DRAWER, data: true });
                                    }}
                                >
                                    Add Bio
                                </Typography>
                        }
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: 'wrap',
                                mt: "4rem",
                            }}
                        >
                            {user?.tiktok_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.tiktok.com/${user?.tiktok_url}` */}
                                    <a href={user?.tiktok_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.tiktok_url ? 'auto' : 'none',
                                            opacity: user?.tiktok_url ? 1 : 0.5,
                                            cursor: user?.tiktok_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={TiktokIcon}
                                            alt="Tiktok Icon"
                                            width="auto"
                                            height="20px"
                                        />
                                    </a>
                                </Box>
                            }
                            {user?.twitter_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://twitter.com//${user?.twitter_url}` */}
                                    <a href={user?.twitter_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.twitter_url ? 'auto' : 'none',
                                            opacity: user?.twitter_url ? 1 : 0.5,
                                            cursor: user?.twitter_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={TwitterIcon}
                                            alt="Twitter Icon"
                                            width="20px"
                                            height="auto"
                                        />
                                    </a>
                                </Box>
                            }
                            {user?.linkedin_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.linkedin.com/${user?.linkedin_url}` */}
                                    <a href={user?.linkedin_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.linkedin_url ? 'auto' : 'none',
                                            opacity: user?.linkedin_url ? 1 : 0.5,
                                            cursor: user?.linkedin_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={LinkedInIcon}
                                            alt="Linked Icon"
                                            width="20px"
                                            height="auto"
                                        />
                                    </a>
                                </Box>
                            }
                            {user?.insta_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.instagram.com/${user?.insta_url}` */}
                                    <a href={user?.insta_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.insta_url ? 'auto' : 'none',
                                            opacity: user?.insta_url ? 1 : 0.5,
                                            cursor: user?.insta_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={InstaIcon}
                                            alt="Instagram Icon"
                                            width="20px"
                                            height="auto"
                                        />
                                    </a>
                                </Box>
                            }
                            {user?.yt_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.youtube.com/${user?.yt_url}` */}
                                    <a href={user?.yt_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.yt_url ? 'auto' : 'none',
                                            opacity: user?.yt_url ? 1 : 0.5,
                                            cursor: user?.yt_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <YouTubeIcon sx={{ color: "#1E2549" }} />{" "}
                                    </a>
                                </Box>
                            }
                            {user?.snap_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.snapchat.com/add/${user?.snap_url}` */}
                                    <a href={user?.snap_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.snap_url ? 'auto' : 'none',
                                            opacity: user?.snap_url ? 1 : 0.5,
                                            cursor: user?.snap_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={SnapIcon}
                                            alt="Snap Icon"
                                            width="20px"
                                            height="auto"
                                        />
                                    </a>
                                </Box>
                            }
                            {user.fb_url &&
                                <Box px={2} py={1} sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    {/* append url - `https://www.facebook.com/${user?.fb_url}` */}
                                    <a href={user?.fb_url} target="_blank"
                                        style={{
                                            pointerEvents: user?.fb_url ? 'auto' : 'none',
                                            opacity: user?.fb_url ? 1 : 0.5,
                                            cursor: user?.fb_url ? 'pointer' : 'default',
                                        }}
                                    >
                                        <img
                                            src={FacebookIcon}
                                            alt="Facebook Icon"
                                            width="20px"
                                            height="auto"
                                        />
                                    </a>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Divider
                        sx={{
                            width: "0.7px",
                            height: "100%",
                            backgroundColor: "grey",
                            opacity: 0.5,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ ...mainBox, cursor: "pointer" }} onClick={() => window.open(`${isSocialLink}?token=${token}`, "_blank")}>
                        <GroupsIcon sx={{ fontSize: 32 }} />
                        <Typography
                            variant="h4"
                            sx={{ ml: 1.5, color: theme?.palette?.dark?.dark3, fontSize: 15, fontWeight: 700 }}
                        >
                            Community & Communication
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            mt: 3,
                            mb: 1.5,
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme?.palette?.text?.darkTextSubHeader,
                            fontFamily: "Poppins",
                        }}
                    >
                        Compete
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ ...contentBox, mr: 2 }} onClick={() => navigate(pages?.League_Coach_Page)}>
                            <SportsFootballIcon sx={iconStyle} />
                            <Typography sx={titleStyle}>LEAGUES</Typography>
                            <Typography sx={subTitleStyle}>
                                Create and/or join exclusive Gridiron leagues.
                            </Typography>
                        </Box>
                        <Box sx={contentBox} onClick={() => navigate(pages?.Tournament_Page)}>
                            {/* <SportsFootballIcon sx={{ color: theme?.palette?.secondary?.dark, fontSize: "30px" }} /> */}
                            <img src={Tile} alt="Tile Icon" width="40px" height="25px" />
                            <Typography sx={titleStyle}>TOURNAMENTS</Typography>
                            <Typography sx={subTitleStyle}>
                                Create and/or join exclusive Gridiron leagues.
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Divider
            sx={{
              my: 4,
              width: "100%",
              height: "0.5px",
              backgroundColor: "grey",
              opacity: 0.5,
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ ...contentBox, mr: 2 }} onClick={() => navigate(pages?.Manage_Parent_BY_ATHLETE_Page)}>
              <img src={Employee} width="25px" height="25px" />
              <Typography sx={subTitleStyle}>
                Manage Parents <br />or Guardians
              </Typography>
            </Box>
            <Box sx={contentBox} onClick={() => navigate(pages?.Manage_COACH)}>
              <PeopleRoundedIcon sx={{ ...iconStyle, color: theme?.palette?.secondary?.dark }} />
              <Typography sx={subTitleStyle}>Manage <br />My Coaches</Typography>
            </Box>
          </Box> */}
                </Grid>
            </Grid>
        </Box>
    );
}

export default DashboardForCoach;