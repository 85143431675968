import { combineReducers } from "redux";

// reducer import
import theme from "@reducers/theme";
import org from "@reducers/org";
import user from "@reducers/user";
import buckets from "@reducers/buckets";
import notification from "@reducers/notification";
import sketch from "@reducers/sketch";
import playerContent from "@reducers/playerContent";
import fileRoomContent from "@reducers/fileRoomContent";
import social from "@reducers/social";
import event from "@reducers/event";
import athleteDataArray from "@reducers/athleteDataArray";
import subOrgTeams from "@reducers/subOrgTeams";
import products from "@reducers/products";
import createCampEvent from "@reducers/createCampEvent";
import sendEmailCommunicate from "@reducers/sendEmailCommunicate";

const reducer = combineReducers({
  theme,
  org,
  user,
  buckets,
  notification,
  sketch,
  playerContent,
  fileRoomContent,
  social,
  event,
  athleteDataArray,
  subOrgTeams,
  products,
  createCampEvent,
  sendEmailCommunicate,
});

export default reducer;
