import { useTheme } from "@mui/material";
import { styled } from "@mui/system";


export const CustomInputLabel = (props) => {

  const theme = useTheme();

  const InputLabel = styled("label")({
    color: theme?.palette?.text?.containerTextLabel,
    letterSpacing: "0.1px",
    fontSize: 14,
    marginBottom: "10px",
    textTransform: "uppercase",
    fontFamily: "Poppins, sans-serif, Arial",
    display: "block",
    fontWeight: 500,
  });
  return <InputLabel {...props} />;
}
