import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import ProfileUpload from "../../assets/images/profileUpload.png";

const UserProfileUpload = ({handleBack, handleChangeProfilePic, profileImgUrl}) => {

  const theme = useTheme();

  const uploadImgStyle = {
    width: profileImgUrl ? "8rem" : "9rem",
    height:  profileImgUrl ? "8rem" : "9rem",
    borderRadius: "100%"
  };

  const uploadImgInputStyle = {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    opacity: 0,
    cursor: "pointer"
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        width={"12rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <img src={profileImgUrl ? profileImgUrl : ProfileUpload} style={uploadImgStyle} />
        <input onChange={handleChangeProfilePic} style={uploadImgInputStyle} type="file" />
      </Box>
      <Box sx={{ position: "relative" }} mt={profileImgUrl ? 2 : 0}>
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={22}
          color={theme?.palette?.text?.containerTextLabel}
          sx={{ textAlign: "center" }}
        >
          Set Profile Pic
        </Typography>
        <Typography
          variant="p"
          fontWeight={400}
          fontSize={16}
          color={theme?.palette?.text?.containerTextLabel}
          mx={{ textAlign: "center" }}
        >
          Tap to allow access to camera.
        </Typography>
      </Box>
      <Button
        size="small"
        variant="contained"
        sx={{
          paddingX: 3.5,
          paddingY: 1.2,
          mr: 5,
          mt: 8,
          mb: 4,
          alignSelf: "end",
        }}
        color="inherit"
        onClick={handleBack}
      >
        Back
      </Button>
    </Box>
  );
};

export default UserProfileUpload;
