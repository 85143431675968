import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";

const CancelConfirmDialog = ({
  title,
  message,
  open,
  handleClose,
  handleCancel,
  handleConfirmClose,
  confirmBtnLabel = "Yes, Close",
  cancelBtnLabel = "Cancel",
  modalSize = "xs",
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth={modalSize}
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ p: 0 }}>
        <Typography
          variant="h4"
          fontWeight={700}
          fontSize={20}
          py={3}
          ml={2}
          color={theme?.palette?.text?.containerTextLabel}
        >
          {title}
        </Typography>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
        ></Box>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: 5,
        }}
      >
        <Typography
          sx={{ pt: 2, pb: 2 }}
          fontFamily={"Poppins"}
          fontSize={16}
          fontWeight={600}
          color={theme?.palette?.text?.containerTextLabel}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ px: 3, pb: 3, display: "flex", justifyContent: "space-between" }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2 }}
          color="inherit"
          onClick={handleCancel}
        >
          {cancelBtnLabel}
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ paddingX: 3.5, paddingY: 1.2 }}
          onClick={handleConfirmClose}
        >
          {confirmBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelConfirmDialog;
