import {
  Avatar,
  Box,
  Button,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_LOADER,
  SET_TOAST,
  getAthleteListPdfAction,
  getTournamentTeamMemberAction,
} from "../../store/actions";
import { charValidate } from "../../utils/functions";
import {
  Tournament_Add_Player_Page,
  Tournament_Roster_Athlete_List,
  Tournament_Teams_Page,
} from "../../routes/constants";
import { useLocation, useNavigate } from "react-router";
import UploadRosterDocuments from "../../components/Tournament/UploadRosterDocuments";
import StatusButton from "../../components/Common/StatusButton";
import SuccessCheck from "../../assets/images/success_check.png";
import ChangeJerseyPosition from "./ChangeJerseyPosition";
import AddTeamManager from "../../components/Tournament/AddTeamManager";

const PaidRosterAthleteList = () => {
  const tableRef = useRef();
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const checkImgStyle = {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    cursor: "pointer",
  };
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationState = location?.state;
  const teamName = navigationState?.teamName ?? "";
  const [dataList, setDataList] = useState([]);
  const [isRosterAthleteListLoading, setRosterAthleteListLoading] =
    useState(false);
  const [showJerseyPositionModal, setShowJerseyPositionModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedAthletesForPaymentAll, setSelectedAthletesForPaymentAll] =
    useState([]);
  const [showAddTeamCoachModal, setShowAddTeamCoachModal] = useState(false);
  const userType = state?.user?.user?.user_type;

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getDataList();
    }
  }, [state?.user?.token, state?.org?.org?.token, navigationState]);

  const getDataList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    setRosterAthleteListLoading(true);
    let response = await getTournamentTeamMemberAction(
      params,
      navigationState?.teamId ?? "",
    );
    setRosterAthleteListLoading(false);
    if (response?.ok) {
      let dataList = response?.data?.athleteList;
      setDataList(dataList ?? []);
    }
  };

  const printDataListPdf = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    dispatch({ type: SET_LOADER, data: true });
    let response = await getAthleteListPdfAction(
      params,
      navigationState?.teamId ?? "",
    );
    dispatch({ type: SET_LOADER, data: false });
    if (response?.ok) {
      window.open(response?.data);
    }
  };

  const handleShowDocModal = (row, roasterId) => {
    // if (
    //   row?.documentStatus === "NOT STARTED" ||
    //   row?.documentStatus === "IN PROGRESS"
    // ) {
    row.roasterId = roasterId;
    setSelectedDataGridRow(row);
    setShowDocumentModal(true);
    // }
  };

  const handleCloseDocModal = () => {
    setShowDocumentModal(false);
    getDataList();
  };

  const handleCloseJerseyPositionModal = (isClose) => {
    setShowJerseyPositionModal(false);
    if (!isClose) {
      getDataList();
    }
  };

  let columns = [
    {
      field: "id",
      headerName: "ROSTER ID#",
      minWidth: 80,
      flex: 1,
      // align: "center",
      // headerAlign: "center",
      // renderCell: (data) => data?.row?.id ?? "N/A",
      renderCell: (data) =>
        data.api.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1,
      sortable: false,
    },
    {
      field: "teamName",
      headerName: "TEAM NAME",
      flex: 1,
      minWidth: 110,
      renderCell: (data) => {
        if (data?.row?.team && data?.row?.team?.length) {
          return (
            <Box display="flex" flexDirection="column">
              {data?.row?.team?.map((team) => (
                <Typography
                  sx={{ pt: 0.4 }}
                  fontFamily={"Poppins"}
                  fontSize={14}
                  fontWeight={600}
                  color={theme?.palette?.grey["700"]}
                >
                  {team?.title ?? "N/A"}
                </Typography>
              ))}
            </Box>
          );
        } else {
          return "N/A";
        }
      },
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "ATHLETE FIRST NAME",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title={data?.row?.first_name ?? "N/A"}>
          {/* {data?.row?.first_name
            ? charValidate(data?.row?.first_name, 15)
            : "N/A"} */}
          <Box sx={{ display: "flex" }}>
            <Avatar
              sx={{
                bgcolor: theme?.palette?.success?.main,
                color: "white",
                mt: 0.4,
                marginRight: 1,
                width: 16,
                height: 16,
                fontSize: 10,
              }}
            >
              A
            </Avatar>
            {/* <Typography variant='h6' sx={{ position: "relative", bottom: "3px" }}> */}
            {data?.row?.first_name
              ? charValidate(data?.row?.first_name, 15)
              : "N/A"}
            {/* </Typography> */}
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "ATHLETE LAST NAME",
      flex: 1,
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title={data?.row?.last_name ?? "N/A"}>
          {data?.row?.last_name
            ? charValidate(data?.row?.last_name, 15)
            : "N/A"}
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "jersey",
      headerName: "JERSEY #",
      flex: 1,
      minWidth: 120,
      // headerAlign: "center",
      // align: "center",
      renderCell: (data) =>
        editJerseyPositionModal(data?.row, data?.row?.jersey),
      sortable: false,
    },
    {
      field: "position",
      headerName: "POSITION",
      flex: 1,
      minWidth: 120,
      // headerAlign: "center",
      // align: "center",
      //renderCell: (data) => editJerseyPositionModal(data?.row, data?.row?.position ? data?.row?.position : "N/A", "Position"),
      renderCell: (data) => {
        if (data?.row?.position?.length > 0) {
          return (
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column">
                {data?.row?.position?.map((position) => (
                  <Tooltip title={position?.name ?? "N/A"}>
                    <Typography
                      sx={{ pt: 0.4, wordBreak: "break-word" }}
                      fontFamily={"Poppins"}
                      fontSize={14}
                      fontWeight={600}
                      color={theme?.palette?.grey["700"]}
                    >
                      {position?.name ? position?.name : "N/A"}
                    </Typography>
                  </Tooltip>
                ))}
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    textDecoration: "underline",
                    cursor: "pointer",
                    ml: 1.2,
                  }}
                  onClick={() => {
                    setSelectedDataGridRow(data?.row);
                    setShowJerseyPositionModal(true);
                  }}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box display="flex" alignItems="center">
              <Typography sx={{ pt: 0.4, wordBreak: "break-word" }}>
                N/A
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                  ml: 1.2,
                }}
                onClick={() => {
                  setSelectedDataGridRow(data?.row);
                  setShowJerseyPositionModal(true);
                }}
              >
                Edit
              </Typography>
            </Box>
          );
        }
      },
      sortable: false,
    },
    {
      field: "documentStatus",
      headerName: "DOCUMENT STATUS",
      align: "center",
      headerAlign: "center",
      renderCell: (data) => (
        <>
          {data?.row?.is_document_verified ? (
            <img
              src={SuccessCheck}
              style={checkImgStyle}
              //onClick={() => handleShowDocModal(data?.row, data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1)}
            />
          ) : (
            <StatusButton
              status={data?.row?.documentStatus}
              // options={{
              //   onClick: () => handleShowDocModal(data?.row, data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1)
              // }}
              style={{
                fontWeight: 500,
                // cursor: "pointer",
              }}
            />
          )}
        </>
      ),
      flex: 1,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      align: "center",
      headerAlign: "center",
      renderCell: (data) => (
        <>
          <Button
            size="medium"
            variant="contained"
            sx={{
              height: "30px",
              width: "30px",
              borderRadius: "50px !important",
              minWidth: "0px !important",
              padding: "0px !important",
            }}
            onClick={() =>
              handleShowDocModal(
                data?.row,
                data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1,
              )
            }
          >
            +
          </Button>
        </>
      ),
      flex: 1,
      minWidth: 120,
      sortable: false,
    },
  ];

  const weightCol =
    userType === "admin"
      ? {
          field: "weight",
          headerName: "WEIGHT",
          flex: 1,
          minWidth: 120,
          // headerAlign: "center",
          // align: "center",
          renderCell: (data) =>
            editJerseyPositionModal(
              data?.row,
              data?.row?.weight ? data?.row?.weight : 0,
            ),
          sortable: false,
        }
      : {};

  const editJerseyPositionModal = (row, value, type = "") => {
    return (
      <Box display="flex" alignItems="center">
        <Typography
          variant="hr"
          fontWeight={600}
          fontSize={14}
          color={theme?.palette?.grey["700"]}
        >
          {type === "Position"
            ? charValidate(value, 15) ?? "N/A"
            : value ?? "N/A"}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            textDecoration: "underline",
            cursor: "pointer",
            ml: 1.2,
          }}
          onClick={() => {
            setSelectedDataGridRow(row);
            setShowJerseyPositionModal(true);
          }}
        >
          Edit
        </Typography>
      </Box>
    );
  };

  const handleClosePaymentMethodModal = (isClosed) => {
    setShowPaymentMethodModal(false);
  };

  const handleTeamCoachModalClose = (isClosed) => {
    setShowAddTeamCoachModal(false);
    if (!isClosed) {
    }
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={teamName ? `Team Roster (${teamName})` : "Team Roster"}
        gridData={isRosterAthleteListLoading ? [] : dataList}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={isRosterAthleteListLoading}
        onClickAddNew={() => {
          navigate(Tournament_Add_Player_Page, {
            state: {
              teamId: navigationState?.teamId ?? "",
              rosterCount: dataList?.length,
            },
          });
        }}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
        rightBtnLabel="Add Athlete"
        isShowFileDownloadOpt={dataList?.length ? true : false}
        textLabelButtonForDownloadOpt={dataList?.length ? "Export Roster" : ""}
        isAddNewTeamCoach={
          userType === "admin" ||
          userType === "teammanager" ||
          userType === "superadmin"
            ? dataList?.length
              ? true
              : false
            : false
        }
        onClickAddNewCoach={() => setShowAddTeamCoachModal(true)}
        gridButtons={() => {
          return (
            <Box
              sx={{
                my: 2,
                px: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{ paddingX: 3.5, paddingY: 1 }}
                color="inherit"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </Box>
          );
        }}
        gridToolbarButtons={() => {
          return (
            <Typography
              variant="h6"
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                mx: 1.5,
              }}
              onClick={() => {
                navigate(Tournament_Roster_Athlete_List, {
                  state: {
                    dataList: [],
                    teamId: navigationState?.teamId ?? "",
                  },
                });
              }}
            >
              View Unpaid Roster List
            </Typography>
          );
        }}
        handlePrint={printDataListPdf}
        options={{
          // checkboxSelection: dataList ? dataList?.length ? true : false : false,
          // disableRowSelectionOnClick: true,
          onRowSelectionModelChange: (teamId) => {
            setSelectedAthletesForPaymentAll(teamId);
          },
        }}
        // showPayAllBtn={selectedAthletesForPaymentAll?.length ? true : false}
        handlePayAllPayment={() => setShowPaymentMethodModal(true)}
        // paymentBtnLabel="Pay for All"
        tableMessageForPaidRostersList={
          userType === "player" || userType === "parent"
            ? "Your Team Manager is finalizing the team registration. Once they complete this process, you'll be able to see the roster here."
            : "You must pay the roster fee to view the full roster. Click or tap on View unpaid roster to complete roster registration."
        }
      />
      {showDocumentModal && (
        <UploadRosterDocuments
          open={showDocumentModal}
          handleClose={handleCloseDocModal}
          rosterId={selectedDataGridRow?.id ?? ""}
          selectedAthlete={selectedDataGridRow}
        />
      )}
      {showJerseyPositionModal && (
        <ChangeJerseyPosition
          open={showJerseyPositionModal}
          handleClose={handleCloseJerseyPositionModal}
          selectedAthlete={selectedDataGridRow}
          teamId={navigationState?.teamId}
        />
      )}
      {showAddTeamCoachModal && (
        <AddTeamManager
          open={showAddTeamCoachModal}
          handleClose={handleTeamCoachModalClose}
          teamId={navigationState?.teamId ?? ""}
        />
      )}
    </>
  );
};

export default PaidRosterAthleteList;
