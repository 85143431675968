import * as actionTypes from '@store/actions'

export const initialState = {
    drawer: false,
    showLoader: false,
    goBackPath: null,
    toastMsg: null,
    userDrawer: false,
    successErrorPopup: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DRAWER:
            return {
                ...state,
                drawer: action.data,
            }
        case actionTypes.SET_LOADER:
            return {
                ...state,
                showLoader: action.data,
            }
        case actionTypes.SET_GOBACK:
            return {
                ...state,
                goBackPath: action.data,
            }
        case actionTypes.SET_TOAST:
            return {
                ...state,
                toastMsg: action.data,
            }
        case actionTypes.SET_USER_DRAWER:
            return {
                ...state,
                userDrawer: action.data,
            }
        case actionTypes.SET_SUCCESS_ERROR_POPUP:
            return {
                ...state,
                successErrorPopup: action.data,
            }
        default:
            return state
    }
}

export default reducer
