import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import CssBaseline from '@mui/material/CssBaseline'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'

import Routes from '@routes'
import themes from '@themes'

const App = () => {
    const { org } = useSelector((state) => state.org)
    const [customTheme, setCustomTheme] = useState({})
    const queryClient = new QueryClient();

    useEffect(() => {
        let _customTheme = {}
        if (org && org.data) {
            const { primary_color, secondary_color } = org.data
            _customTheme = {
                colors: {},
            }
            if (primary_color) {
                _customTheme = {
                    colors: {
                        ..._customTheme.colors,
                        primaryMain: primary_color,
                    },
                }
            }
            if (secondary_color) {
                _customTheme = {
                    colors: {
                        ..._customTheme.colors,
                        primaryDark: secondary_color,
                    },
                }
            }
            setCustomTheme(_customTheme)
        }
    }, [org])

    return (
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customTheme)}>
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    )
}

export default App
