import { Button, Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_LOADER,
  getDivisions,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import ActionCard from "../../components/Common/ActionCard";
import { Box, useTheme } from "@mui/system";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import AddDivision from "../../components/Tournament/AddDivision";
import httpClient from "@utils/httpClient";

const ManageDivisions = () => {
  const tableRef = useRef();
  const [gradeList, setGradeList] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const state = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getDivisionList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getDivisionList = async () => {
    const params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getDivisions(dispatch);
    if (response?.ok) {
      setGradeList(response?.data ?? []);
      // setPaginationOptions({
      //     totalMainDocs: response?.data?.totalMainDocs ?? 0,
      //     totalDocs: response?.data?.totalDocs ?? 0,
      //     offset: response?.data?.offset ?? 0,
      //     limit: response?.data?.limit ?? 10,
      //     totalPages: response?.data?.totalPages ?? 0,
      //     currentPage: response?.data?.currentPage ?? 0,
      //     hasPrevPage: response?.data?.hasPrevPage ?? false,
      //     hasNextPage: response?.data?.hasNextPage ?? false,
      // });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize;
    paginationOptions.limit = pageSize ?? 10;
    getDivisionList();
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      renderCell: (data) => {
        return data?.row?.name ?? "N/A";
      },
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={handleClick}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Manage Division"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingX: 5.5, paddingY: 1 }}
                    onClick={handleEditDivisionClick}
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingX: 5.5,
                      paddingY: 1,
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteDivisionClick}
                  >
                    Delete
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleModalClose = (isClosed) => {
    setShowAddEditModal(false);
    setSelectedRow(undefined);
    if (!isClosed) {
      getDivisionList();
    }
  };

  const handleEditDivisionClick = () => {
    handleClose();
    setShowAddEditModal(true);
  };

  const handleDeleteDivisionClick = () => {
    handleClose();
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    dispatch({ type: SET_LOADER, data: true });
    const response = await httpClient.post("/deleteDivision", {
      id: selectedRow?.id,
    });
    dispatch({ type: SET_LOADER, data: false });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message },
      });
      setShowDeletePopup(false);
      setSelectedRow("");
      getDivisionList();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <Box>
      <MuiDataGrid
        headerLabel={"Manage Divisions"}
        gridData={gradeList}
        columns={columns}
        paginationOptions={paginationOptions}
        tableRef={tableRef}
        isLoading={gradeList ? false : true}
        //handlePagination={handlePagination}
        onClickAddNew={() => {
          setShowAddEditModal(true);
          setSelectedRow("");
        }}
        handleRowClick={(data) => setSelectedRow(data?.row)}
        pageSizeOptions={[]}
      />
      {showAddEditModal && (
        <AddDivision
          open={showAddEditModal}
          handleClose={handleModalClose}
          editData={selectedRow}
        />
      )}
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Division"}
          message={"Are you sure you want to delete this division?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
    </Box>
  );
};

export default ManageDivisions;
