import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  getEventDetailsNotSignin,
} from "../../store/actions";
import httpClient from "@utils/httpClient";
import { randomNum } from "@utils/functions";
import { convertToCapsOfFirstLetter } from "@utils/functions";
import gridironGoldLogo from "../../assets/images/gg-gold-hero.png";
import ProductStripePayment from "../packages/ProductStripePayment";
import SuccessPopup from "../../components/Common/SuccessPopup";
import ChildSchoolInfo from "../../components/AppForms/ChildSchoolInfo";
import { ROOT_PAGE } from "../../routes/constants";
import ThankYou from "../../components/Common/ThankYou";
import { useGetCurrentDateTime } from "../../hooks/useCurrentDates";
import { dateFormatForAPI, formatDate } from "../../utils/functions";
import EventQRSuccessErrorPopup from "./components/EventQRSuccessErrorPopup";
import dayjs from "dayjs";

const validationSchema = Yup.object({
  first_name: Yup.string().trim().required("Athlete First Name is required"),
  last_name: Yup.string().trim().required("Athlete Last Name is required"),
  email: Yup.string()
    .trim()
    .required("Athlete Email is required")
    .email("Must be a valid email"),
  mobile: Yup.string().required("Athlete mobile is required"),
  parent_first_name: Yup.string().required("Parent first name is required"),
  parent_last_name: Yup.string().required("Parent last name is required"),
  parent_email: Yup.string()
    .required("Parent email is required")
    .email("Must be a valid email"),
  parent_mobile: Yup.string().required("Parent mobile is required"),
  //   parent_countryCode: Yup.string().required("Country Code is required"),
  //   countryCode: Yup.string().required("Country Code is required"),
  school: Yup.string().trim().required("School Name is required"),
  grade: Yup.string().trim().required("Grade is required"),
  dob: Yup.date().required("Data of Birth is required"),
});

const EventRegistrationQR = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  let params = new URLSearchParams(window?.location?.search);
  let eventIdQueryParam = params.get("event_id");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { org } = useSelector((state) => state.org);
  const dispatch = useDispatch();
  const [randomId, setRandomId] = useState(null);
  const [formData, setFormData] = useState({
    device_id: "abc123",
    device_token: "abcxyz123",
    device_type: "web",
    sub_organization: 1,
  });
  const [showError, setShowError] = useState("");
  const [step, setStep] = useState(0);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showEventQRErrorModal, setShowEventQRErrorModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [currentDateString, currentTimeString] = useGetCurrentDateTime();
  const [isEventCostZero, setIsEventCostZero] = useState(false);
  const [eventDetails, setEventDetails] = useState({});

  useEffect(() => {
    getEventById();
  }, []);

  const getEventById = async () => {
    let params = {
      eventId: eventIdQueryParam,
    };

    let response = await getEventDetailsNotSignin(params, dispatch);
    if (response?.ok) {
      setEventDetails(response?.data);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      countryCode: "",
      school: "",
      grade: "",
      dob: dayjs() || "",
      parent_first_name: "",
      parent_last_name: "",
      parent_email: "",
      parent_mobile: "",
      parent_countryCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // if (!selectedPosition?.length) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: { type: "error", message: "Choose position" },
      //   });
      //   return;
      // }
      setShowError("");
      const countryCode = values.countryCode.toUpperCase();
      const parent_countryCode = values.parent_countryCode.toUpperCase();
      const position = selectedPosition ?? [];
      const event_id = eventIdQueryParam;
      const postData = {
        ...values,
        countryCode,
        parent_countryCode,
        position,
        event_id,
      };
      postData.dob = postData?.dob ? dateFormatForAPI(postData?.dob) : "";
      postData.date = currentDateString;
      postData.time = currentTimeString;
      setFormData((prevStage) => {
        return {
          ...prevStage,
          ...postData,
        };
      });
      individualStripePaymentIntent(postData);
    },
  });

  const getRandomNum = () => {
    const _randomId = randomNum();
    setRandomId(_randomId);
  };

  useEffect(() => {
    getRandomNum();
  }, []);

  useEffect(() => {
    if (isEventCostZero) {
      completeStripePaymentProcess();
    }
  }, [isEventCostZero]);

  const individualStripePaymentIntent = async (postData) => {
    let values = { ...postData };
    const API_URL = "/events/paymentIntentWithoutLogin";

    dispatch({ type: SET_LOADER, data: true });

    httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${org.token}`;
    httpClient.defaults.headers.common["Authorization"] = "";

    await httpClient
      .post(API_URL, values)
      .then((apiRes) => {
        dispatch({ type: SET_LOADER, data: false });
        if (apiRes && apiRes.ok) {
          const response = apiRes?.data;
          setPaymentFormData(response);
          if (parseInt(response?.amount) === 0 && !response?.clientSecret) {
            setIsEventCostZero(true);
          } else {
            setStep(1);
          }
        } else {
          if (apiRes?.isLogin) {
            setShowErrorMessage(apiRes?.message);
            setShowEventQRErrorModal(true);
          } else {
            dispatch({
              type: SET_SUCCESS_ERROR_POPUP,
              data: {
                open: true,
                type: "error",
                message: apiRes?.message ?? "Something went wrong, try again",
              },
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: error?.message ?? "Something went wrong, try again",
          },
        });
      });
  };

  const completeStripePaymentProcess = async () => {
    const API_URL = "/registerEventWithoutLogin";
    let values = { ...paymentFormData };
    dispatch({ type: SET_LOADER, data: true });
    httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${org.token}`;
    await httpClient
      .post(API_URL, values)
      .then((apiRes) => {
        dispatch({ type: SET_LOADER, data: false });
        if (apiRes && apiRes.ok) {
          if (isEventCostZero) {
            setStep(2);
          } else {
            setShowSuccessModal(true);
          }
          setIsEventCostZero(false);
        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: apiRes?.message ?? "Something went wrong, try again",
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: error?.message ?? "Something went wrong, try again",
          },
        });
      });
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    setStep(2);
  };

  const handleEventQRErrorModalClose = () => {
    setShowEventQRErrorModal(false);
    setFormData({});
    setShowErrorMessage("");
    localStorage.setItem("eventQREmail", formik?.values?.parent_email);
    navigate(`${ROOT_PAGE}?eventId=${eventIdQueryParam}`, {
      state: {
        isPurchasePage: true,
      },
    });
    setPaymentFormData({});
  };

  const renderSelectedLocations = () => {
    if (eventDetails.locations?.length) {
      let counter = 0;
      return eventDetails.locations?.map((location) => {
        counter++;
        return (
          <Box key={location?.id} display={"flex"} alignItems={"center"} my={1}>
            <Box
              sx={{
                background: theme?.palette?.secondary?.dark,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                width: 40,
                height: 40,
                minWidth: 40,
                minHeight: 40,
                borderRadius: "100%",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {counter}
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: theme?.palette?.dark?.darkTextSubHeader,
                fontSize: 15,
                fontFamily: "Poppins",
                fontWeight: 500,
                ml: 2,
              }}
            >
              {location?.name ? `${location?.name} -` : null} &nbsp;
              {`${location?.address ? location?.address + ", " : ""}
                  ${location?.suit ? location?.suit + ", " : ""}
                  ${location?.state ? location?.state + ", " : ""}
                  ${location?.city ? location?.city + ", " : ""}
                  ${location?.zip_code ? location?.zip_code : ""}
                  `}
            </Typography>
          </Box>
        );
      });
    }
  };

  return (
    <>
      {step === 0 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ padding: "5rem 1rem" }}
        >
          <Grid item xs={12}>
            <img
              src={gridironGoldLogo}
              style={{ width: "15rem", height: "5rem" }}
              alt="IYS logo"
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1, mt: 2, width: isMobile ? 0.8 : 0.5 }}>
            {/* <Box
              direction="column"
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ mt: 4, mb: 4, flexDirection: "row" }}
            >
              <Typography variant="body1" color="secondary" align="center">
                Already Have an Account?
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                className="pointer textUnderline"
                align="center"
                sx={{ ml: 1 }}
                onClick={() => handleNavigate()}
              >
                Go to Login
              </Typography>
            </Box> */}
            <Typography variant="body1" sx={{ textAlign: "center", mt: 1.5 }}>
              Note: Email and Phone can be the same as parents for athletes who
              don’t have one.
            </Typography>
          </Grid>
          {eventDetails && eventDetails?.title ? (
            <Grid item xs={12} sx={{ mb: 1.5, mt: 0.5, width: 1 }}>
              <Typography
                variant="h3"
                sx={{
                  color: theme?.palette?.secondary?.dark,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  mb: 1.5,
                }}
              >
                Event Details
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  border: `1.7px solid gold`,
                  width: 1,
                  minHeight: "4rem",
                  p: 2,
                  borderRadius: "5px",
                  maxWidth: "350px",
                  margin: "0 auto",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme?.palette?.secondary?.dark,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  {eventDetails?.title}
                </Typography>
                <Box mt={1.5}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 0.8, fontFamily: "Poppins" }}
                    >
                      Start Date:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme?.palette?.secondary?.dark,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      {formatDate(eventDetails?.start_date)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 0.8, fontFamily: "Poppins" }}
                    >
                      End Date:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme?.palette?.secondary?.dark,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      {formatDate(eventDetails?.end_date)}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 0.7 }} />
                <Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 0.8, fontFamily: "Poppins" }}
                    >
                      Start Time:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme?.palette?.secondary?.dark,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      {eventDetails?.start_time}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 0.8, fontFamily: "Poppins" }}
                    >
                      End Time:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme?.palette?.secondary?.dark,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      {eventDetails?.end_time}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ) : null}
          {eventDetails && eventDetails?.title ? (
            <Grid item xs={12} sx={{ mb: 1.5, mt: 0.5, width: 1 }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  width: 1,
                  maxWidth: "350px",
                  margin: "0 auto",
                }}
              >
                {renderSelectedLocations()}
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_first_name &&
                    formik.errors.parent_first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_first_name"
                    name="parent_first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "parent_first_name")
                    }
                    value={formik.values.parent_first_name}
                    placeholder="Parent's First Name *"
                    error={Boolean(
                      formik.touched.parent_first_name &&
                      formik.errors.parent_first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_first_name &&
                    formik.errors.parent_first_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_first_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_last_name &&
                    formik.errors.parent_last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_last_name"
                    name="parent_last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "parent_last_name")
                    }
                    value={formik.values.parent_last_name}
                    placeholder="Parent's Last Name *"
                    error={Boolean(
                      formik.touched.parent_last_name &&
                      formik.errors.parent_last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_last_name &&
                    formik.errors.parent_last_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_last_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="parent_email"
                    name="parent_email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.parent_email}
                    placeholder="Parent's Email *"
                    error={Boolean(
                      formik.touched.parent_email && formik.errors.parent_email,
                    )}
                  />
                  {Boolean(
                    formik.touched.parent_email && formik.errors.parent_email,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_email}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "& .react-tel-input .form-control": {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent",
                      },
                    }}
                  >
                    <PhoneInput
                      country={"us"}
                      id="parent_mobile"
                      name="parent_mobile"
                      variant="outlined"
                      placeholder="(702) 123-4567"
                      onlyCountries={["us"]}
                      disableCountryCode={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.parent_mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue(
                          "parent_countryCode",
                          country.countryCode || "",
                        );
                        formik.setFieldValue("parent_mobile", value || "");
                      }}
                      error={Boolean(
                        formik.touched.parent_mobile &&
                        formik.errors.parent_mobile,
                      )}
                    />
                  </Box>
                  {Boolean(
                    formik.touched.parent_mobile && formik.errors.parent_mobile,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.parent_mobile}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="first_name"
                    name="first_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "first_name")
                    }
                    value={formik.values.first_name}
                    placeholder="Athlete's First Name *"
                    error={Boolean(
                      formik.touched.first_name && formik.errors.first_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.first_name && formik.errors.first_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.first_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  )}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="last_name"
                    name="last_name"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      convertToCapsOfFirstLetter(e, formik, "last_name")
                    }
                    value={formik.values.last_name}
                    placeholder="Athlete's Last Name *"
                    error={Boolean(
                      formik.touched.last_name && formik.errors.last_name,
                    )}
                  />
                  {Boolean(
                    formik.touched.last_name && formik.errors.last_name,
                  ) && (
                      <FormHelperText error>
                        {formik.errors.last_name}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email.toLowerCase()}
                    placeholder="Athlete's Email *"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                  />
                  {Boolean(formik.touched.email && formik.errors.email) && (
                    <FormHelperText error>{formik.errors.email}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.mobile && formik.errors.mobile)}
                  sx={{
                    ...theme.typography.customInput,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                      boxShadow: "0px 6px 20px #1F54C326",
                    },
                  }}
                >
                  <Box
                    sx={{
                      "& .react-tel-input .form-control": {
                        border: 0,
                        "box-shadow": "0px 6px 20px #1F54C326",
                        "min-width": "100%",
                        "font-weight": 500,
                        background: "#ffffff",
                        padding: "15px 14px 15px 50px",
                        "border-radius": "0",
                        height: "51px",
                      },
                      "& .react-tel-input .flag-dropdown": {
                        border: 0,
                        background: "transparent",
                      },
                    }}
                  >
                    <PhoneInput
                      country={"us"}
                      id="mobile"
                      name="mobile"
                      variant="outlined"
                      onlyCountries={["us"]}
                      placeholder="(702) 123-4567"
                      disableCountryCode={true}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      onChange={(value, country) => {
                        formik.setFieldValue(
                          "countryCode",
                          country.countryCode || "",
                        );
                        formik.setFieldValue("mobile", value || "");
                      }}
                      error={Boolean(
                        formik.touched.mobile && formik.errors.mobile,
                      )}
                    />
                  </Box>
                  {Boolean(formik.touched.mobile && formik.errors.mobile) && (
                    <FormHelperText error>
                      {formik.errors.mobile}
                    </FormHelperText>
                  )}
                </FormControl>
                <ChildSchoolInfo
                  formik={formik}
                  setSelectedPosition={setSelectedPosition}
                  selectedPosition={selectedPosition}
                  eventDetails={eventDetails}
                />

                <Typography
                  variant="body2"
                  sx={{ color: "red", textAlign: "center" }}
                >
                  {showError}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mb: 3, mt: 4, p: 1.5 }}
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    (!formik.validateOnMount && !formik.dirty)
                  }
                  type="submit"
                >
                  Next
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      )}

      {step === 1 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh", padding: "5rem 1rem" }}
        >
          <Box
            sx={{
              background: "#EFF7FF",
              border: `1px solid #7FBEFD`,
              color: "black",
              width: "315px",
              borderRadius: "7px",
              fontSize: "14px",
              fontWeight: 500,
              mb: 4,
              mt: 1,
            }}
            p={2}
          >
            <span style={{ color: "red" }}>*&nbsp;&nbsp;</span>Please be patient
            upon submission. This process could take a several moments to
            complete.
          </Box>
          <Grid item xs={12}>
            <ProductStripePayment
              handleClose={() => {
                setStep(0);
              }}
              afterPayemntMethod={completeStripePaymentProcess}
              formData={paymentFormData}
              type="package"
              payAmount={paymentFormData ? paymentFormData?.amount : 0}
            />
          </Grid>
        </Grid>
      )}

      {step === 2 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <ThankYou />
          </Grid>
        </Grid>
      )}

      <div id={`mobile-signin-btn-${randomId}`} style={{ display: "none" }} />
      {showSuccessModal && (
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to see next view"
          alignCenterSecondaryTitle={true}
        />
      )}

      {showEventQRErrorModal && (
        <EventQRSuccessErrorPopup
          open={showEventQRErrorModal}
          handleClose={handleEventQRErrorModalClose}
          errorMessage={showErrorMessage}
        />
      )}
    </>
  );
};

export default EventRegistrationQR;
