import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Button,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import SendEmailStep1 from "./SendEmailStep1";
import SendEmailStep2 from "./SendEmailStep2";
import SendEmailStep3 from "./SendEmailStep3";
import SendEmailStep4 from "./SendEmailStep4";
import SendEmailStep5 from "./SendEmailStep5";
import {
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  createUpdateEvent,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Admin_Communicate, CALENDAR_PAGE } from "../../../routes/constants";
import CustomSwitch from "../../../components/Common/Inputs/Switch";
import CancelConfirmDialog from "../../../components/Common/CancelConfirmDialog";
import CancelSendCampaign from "./CancelSendCampaign";

export default function SendEmailCommunicate() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const sendEmailCommunicateDetail = state?.sendEmailCommunicate;
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [currentStep, setCurrentStep] = useState(1);
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] = useState(false);

  const steps = [1, 2, 3, 4, 5];
  const selectedEvent = location?.state?.selectedEvent ?? "";
  const eventType = location?.state?.eventType ?? "";
  const isEditEvent = location?.state?.isEditEvent ?? false;

  let permissions = [
    {
      id: 1,
      label: "Allow drop in registrations.",
      name: "allow_drop_in",
      flag: selectedEvent ? selectedEvent?.allow_drop_in : false,
    },
    {
      id: 2,
      label: "Restrict Athlete’s from registering.",
      name: "restrict_atheletes_to_register",
      flag: selectedEvent ? !selectedEvent?.allow_athletes : false,
    },
    {
      id: 3,
      label: "Allow Scholarship Funds to be applied to this event.",
      name: "applied_scholarship_funds_to_event",
      flag: selectedEvent ? selectedEvent?.allow_scholarship : false,
    },
    {
      id: 4,
      label: "Allow Discount Codes to be applied to this event.",
      name: "allow_discount_code_to_event",
      flag: selectedEvent ? selectedEvent?.allow_discount_code : false,
    },
    {
      id: 5,
      label: "Require approval for registrations outside of selected grades",
      name: "approval_grade",
      flag: selectedEvent ? selectedEvent?.approval_grade : false,
    },
    {
      id: 6,
      label: "Make this event private, invite only (Coming Soon)",
      name: "is_event_private",
      flag: selectedEvent
        ? selectedEvent?.visibility === "private"
          ? true
          : false
        : false,
    },
    {
      id: 7,
      label: "Allow a single pack to be applied during registration",
      name: "allow_single_pack",
      flag: selectedEvent ? selectedEvent?.allow_single_pack : false,
    },
  ];

  useEffect(() => {
    dispatch({ type: "RESET_CAMPAIGN_DATA" });
  }, []);

  const onNext = (stepNum) => {
    setCurrentStep(stepNum);
    setCompletedSteps((prevState) => {
      return [...prevState, stepNum];
    });
  };

  const onBack = (stepNum) => {
    if (stepNum > 1) {
      setCurrentStep(stepNum - 1);
      let steps = completedSteps.filter((step) => step !== stepNum);
      setCompletedSteps(steps);
    }
  };

  const closeStepsView = () => {
    dispatch({ type: "RESET_CAMPAIGN_DATA" });
    navigate(Admin_Communicate);
  };

  return (
    <Card
      sx={{
        my: 1.5,
        border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
      }}
    >
      <CardContent sx={{ p: 0 }} style={{ paddingBottom: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 3,
            borderBottom: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="h3" component="div" mr={2}>
              Send Details
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              padding: currentStep === 5 ? 0 : "0 1rem",
              [theme.breakpoints.down("md")]: {
                alignItems: "start",
                mx: 0,
                p: 0,
              },
            }}
          >
            {steps.map((step) => (
              <Typography
                variant="h6"
                sx={{
                  background: completedSteps.includes(step)
                    ? theme?.palette?.secondary?.dark
                    : theme?.palette?.grey["300"],
                  width: 60,
                  height: 60,
                  borderRadius: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 15,
                  mx: 1,
                  color: completedSteps.includes(step)
                    ? "white"
                    : theme?.palette?.secondary?.darkNewVariant1,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  [theme.breakpoints.down("lg")]: {
                    width: 40,
                    height: 40,
                    mx: 0.5,
                  },
                  [theme.breakpoints.down("md")]: {
                    fontSize: 14,
                  },
                  // cursor: completedSteps.includes(step) ? "pointer" : "initial"
                }}
                key={step}
              >
                {step}
              </Typography>
            ))}
          </Box>
        </Box>
        {currentStep === 1 && (
          <SendEmailStep1
            step={currentStep}
            onNext={() => onNext(2)}
            handleCancel={closeStepsView}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 2 && (
          <SendEmailStep2
            step={currentStep}
            onNext={() => onNext(3)}
            onBack={() => onBack(currentStep)}
            handleCancel={closeStepsView}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 3 && (
          <SendEmailStep3
            step={currentStep}
            onNext={() => onNext(4)}
            onBack={() => onBack(currentStep)}
            handleCancel={closeStepsView}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 4 && (
          <SendEmailStep4
            step={currentStep}
            onNext={onNext}
            onBack={() => onBack(currentStep)}
            handleCancel={closeStepsView}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
        {currentStep === 5 && (
          <SendEmailStep5
            step={currentStep}
            onNext={onNext}
            onBack={() => onBack(currentStep)}
            handleCancel={closeStepsView}
            setShowCancelConfirmPopup={setShowCancelConfirmPopup}
          />
        )}
      </CardContent>
      {showCancelConfirmPopup && (
        <CancelSendCampaign
          open={showCancelConfirmPopup}
          handleClose={() => setShowCancelConfirmPopup(false)}
          handleConfirmClose={closeStepsView}
        />
      )}
    </Card>
  );
}
