import { Button, Typography, styled } from "@mui/material";

const OutlineBtnStyled = styled(Typography)(({ theme, style }) => {
  return ({
    height: 40,
    width: "100%",
    background: "transparent",
    border: `2px solid ${theme?.palette?.primary?.main}`,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme?.palette?.primary?.main,
    cursor: "pointer",
    fontFamily: "Poppins ,sans-serif,Arial",
    fontWeight: 700,
    padding: 10,
    ...style,
  })
});

const ContainedBtnStyled = styled(Button)(({ theme, style }) => ({
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  backgroundColor: theme?.palette?.primary?.main,
  mx: 2,
  color: "white",
}));

const UnderlineBtnStyled = styled(Typography)(({ theme, style }) => ({
  fontSize: "14px",
  fontWeight: 600,
  textDecoration: "underline",
  cursor: "pointer",
  color: theme?.palette?.primary?.main,
  fontFamily: "Poppins ,sans-serif,Arial",
}));

const StatusStyled = styled(Typography)(({ theme, style }) => ({
  maxWidth: 120,
  height: 45,
  cursor: "initial",
  boxShadow: "none",
  borderRadius: "4px",
  display: "grid",
  alignItems: "center",
  fontFamily: "Poppins ,sans-serif,Arial",
  fontSize: "15px",
  backgroundColor: theme?.palette?.grey["500"],
  color: "white",
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%"
  }
}));

export {
  OutlineBtnStyled,
  ContainedBtnStyled,
  UnderlineBtnStyled,
  StatusStyled,
}
