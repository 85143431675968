import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { convertToCapsOfFirstLetter } from "@utils/functions";

const UserInfoForm = ({ formik, isEmailPhoneDisabled = false, isAllowLogin = false, isParent = false }) => {
  const theme = useTheme();
  const [isAthleteLogin, setIsAthleteLogin] = useState(false)
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAllowLogin && isParent && formik?.values?.mobile) {
      const isMatchMobile = user.mobile === formik?.values?.mobile ? true : false;
      setIsAthleteLogin(isMatchMobile)
    }
  }, [])

  const handleCheckBoxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAthleteLogin(isChecked)
    if (isChecked) {
      formik.setFieldValue("email", user?.email)
      formik.setFieldValue("mobile", user?.mobile)
      formik.setFieldValue("countryCode", user?.countryCode)
    } else {
      formik.setFieldValue("email", "")
      formik.setFieldValue("mobile", "")
      formik.setFieldValue("countryCode", "")
    }
  }

  return (
    <Grid item xs={12}>
      <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
        {/* First Name */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.first_name && formik.errors.first_name)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            '& .MuiOutlinedInput-root': {
              border: '0',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0',
            },
            '& .MuiInputBase-input': {
              border: '0',
              borderRadius: '5px',
              background: 'transparent',
            },
            '& .MuiInputBase-root': {
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 6px 20px #1F54C326',
            }
          }}
        >
          <TextField
            id="first_name"
            name="first_name"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'first_name')}
            value={formik.values.first_name}
            // label="First Name *"
            placeholder="First Name *"
            error={Boolean(
              formik.touched.first_name && formik.errors.first_name,
            )}

          />
          {Boolean(formik.touched.first_name && formik.errors.first_name) && (
            <FormHelperText error>{formik.errors.first_name}</FormHelperText>
          )}
        </FormControl>

        {/* Last Name */}
        <FormControl
          fullWidth
          error={Boolean(formik.touched.last_name && formik.errors.last_name)}
          sx={{
            ...theme.typography.customInput,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
              boxShadow: theme?.palette?.boxShadow?.primary,
            },
            '& .MuiOutlinedInput-root': {
              border: '0',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0',
            },
            '& .MuiInputBase-input': {
              border: '0',
              borderRadius: '5px',
              background: 'transparent',
            },
            '& .MuiInputBase-root': {
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 6px 20px #1F54C326',
            }
          }}
        >
          <TextField
            id="last_name"
            name="last_name"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'last_name')}
            value={formik.values.last_name}
            // label="Last Name *"
            placeholder="Last Name *"
            error={Boolean(formik.touched.last_name && formik.errors.last_name)}
          />
          {Boolean(formik.touched.last_name && formik.errors.last_name) && (
            <FormHelperText error>{formik.errors.last_name}</FormHelperText>
          )}
        </FormControl>

        {(isAllowLogin && isParent) ?
          <>
            {/* Email */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              sx={{
                ...theme.typography.customInput,
                // "& .MuiOutlinedInput-notchedOutline": {
                //   border: "0",
                //   boxShadow: theme?.palette?.boxShadow?.primary,
                // },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiOutlinedInput-root': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                }
              }}
              disabled={isAthleteLogin}
            >
              <TextField
                id="email"
                name="email"
                type="email"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email.toLowerCase()}
                // label="Email *"
                placeholder="Email *"
                error={Boolean(formik.touched.email && formik.errors.email) && !isAthleteLogin}
                disabled={isAthleteLogin}
              />
              {!isAthleteLogin && Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>

            {/* Mobile */}
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.mobile && formik.errors.mobile
              )}
              sx={{
                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                  boxShadow: '0px 6px 20px #1F54C326',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                }
              }}
              disabled={isAthleteLogin}
            >
              <Box
                sx={{
                  '& .react-tel-input .form-control': {
                    border: 0,
                    "box-shadow": "0px 6px 20px #1F54C326",
                    "min-width": "100%",
                    "font-weight": '500',
                    background: "#ffffff",
                    padding: "15px 14px 15px 50px",
                    height: "51px",
                    width: '100%'
                  },
                  "& .react-tel-input .flag-dropdown": {
                    border: 0,
                    background: "transparent"
                  }
                }
                }>
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  placeholder="(702) 123-4567"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onlyCountries={['us']}
                  disableCountryCode={true}
                  disableDropdown={true}
                  onChange={(value, country) => {
                    formik.setFieldValue('countryCode', country.countryCode || '')
                    formik.setFieldValue('mobile', value);
                  }}
                  error={Boolean(formik.touched.mobile && formik.errors.mobile) && !isAthleteLogin}
                  disabled={isAthleteLogin}
                />
              </Box>
              {!isAthleteLogin && Boolean(formik.touched.mobile && formik.errors.mobile) && (
                <FormHelperText error>{formik.errors.mobile}</FormHelperText>
              )}
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={isAthleteLogin}
                  onChange={(e) => handleCheckBoxChange(e)}
                  color="primary"
                />
              }
              label="Allow this user to use my email and mobile number to login to their account (note, password will be different)."
            />
          </> :
          <>
            {/* Email */}
            <FormControl
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              sx={{
                ...theme.typography.customInput,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                  boxShadow: theme?.palette?.boxShadow?.primary,
                },
                '& .MuiOutlinedInput-root': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                }
              }}
              disabled={isEmailPhoneDisabled}
            >
              <TextField
                id="email"
                name="email"
                type="email"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email.toLowerCase()}
                // label="Email *"
                placeholder="Email *"
                error={Boolean(formik.touched.email && formik.errors.email)}
                disabled={isEmailPhoneDisabled}
              />
              {Boolean(formik.touched.email && formik.errors.email) && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>

            {/* Mobile */}
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.mobile && formik.errors.mobile
              )}
              sx={{
                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                  boxShadow: '0px 6px 20px #1F54C326',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0',
                },
                '& .MuiInputBase-input': {
                  border: '0',
                  borderRadius: '5px',
                  background: 'transparent',
                },
                '& .MuiInputBase-root': {
                  background: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 6px 20px #1F54C326',
                }
              }}
              disabled={isEmailPhoneDisabled}
            >
              <Box
                sx={{
                  '& .react-tel-input .form-control': {
                    border: 0,
                    "box-shadow": "0px 6px 20px #1F54C326",
                    "min-width": "100%",
                    "font-weight": '500',
                    background: "#ffffff",
                    padding: "15px 14px 15px 50px",
                    height: "51px",
                    width: '100%'
                  },
                  "& .react-tel-input .flag-dropdown": {
                    border: 0,
                    background: "transparent"
                  }
                }
                }>
                <PhoneInput
                  country={"us"}
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  placeholder="(702) 123-4567"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onlyCountries={['us']}
                  disableCountryCode={true}
                  disableDropdown={true}
                  onChange={(value, country) => {
                    formik.setFieldValue('countryCode', country.countryCode || '')
                    //formik.setFieldValue('dialCode', country.dialCode || '')
                    formik.setFieldValue('mobile', value);
                  }}
                  error={Boolean(
                    formik.touched.mobile && formik.errors.mobile
                  )}
                  disabled={isEmailPhoneDisabled}
                />
              </Box>
              {Boolean(
                formik.touched.mobile && formik.errors.mobile
              ) && (
                  <FormHelperText error>
                    {formik.errors.mobile}
                  </FormHelperText>
                )}
            </FormControl>
          </>
        }
      </Box>
    </Grid>
  );
};

export default UserInfoForm;
