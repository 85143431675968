import facebookIcon from "@assets/images/social-links/facebook.png";
import instagramIcon from "@assets/images/social-links/instagram.png";
import linkedinIcon from "@assets/images/social-links/linkedin.png";
import snapchatIcon from "@assets/images/social-links/snapchat.png";
import tiktokIcon from "@assets/images/social-links/tiktok.png";
import twitterIcon from "@assets/images/social-links/twitter.png";
import youtubeIcon from "@assets/images/social-links/youtube.png";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

const SocialMediaProfile = ({ formik }) => {
  // hooks
  const theme = useTheme();

  // constants
  const SOCIALMEDIA = [
    { name: "twitter_url", icon: twitterIcon },
    { name: "tiktok_url", icon: tiktokIcon },
    { name: "insta_url", icon: instagramIcon },
    { name: "snap_url", icon: snapchatIcon },
    { name: "linkedin_url", icon: linkedinIcon },
    { name: "fb_url", icon: facebookIcon },
    { name: "yt_url", icon: youtubeIcon },
  ];

  // styles
  const inputStyle = {
    border: 0,
    outline: "none",
    height: 30,
    fontSize: 16,
  };

  const formControlStyle = {
    ...theme.typography.customInput,
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
      background: "transparent",
      color: theme?.palette?.primary?.main,
    },
  };

  return (
    <Grid item xs={12}>
      <Box sx={{ maxWidth: "350px", margin: "0 auto", width: "100%" }}>
        {/* <Box pb={1}>
          <Typography
            variant="h3"
            style={{
              color: theme?.palette?.dark?.darkTextSubHeader,
              fontWeight: "bold",
            }}
          >
            Your Social Accounts
          </Typography>
        </Box> */}
        {SOCIALMEDIA.map((social, index) => (
          <Box
            key={social.name}
            display="flex"
            alignItems="center"
            px={2}
            py={0.5}
            my={2}
            style={{
              border: "0",
              borderRadius: 5,
              boxShadow: theme?.palette?.boxShadow?.primary,
              backgroundColor: theme?.palette?.background?.paper,
            }}
          >
            <img
              src={social.icon}
              style={{
                width: SOCIALMEDIA.length === index + 1 ? 38 : 40,
                borderRight: "1px solid",
                paddingRight: SOCIALMEDIA.length === index + 1 ? 23 : 16,
                position: "relative",
                left: SOCIALMEDIA.length === index + 1 ? 3 : 0,
                ...(social.name === "yt_url" && {
                  paddingRight: "12px",
                  width: "40px",
                  left: "1px",
                }),
              }}
            />
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.profileUsername && formik.errors.profileUsername,
              )}
              sx={formControlStyle}
              style={{ paddingLeft: 20 }}
            >
              <input
                value={formik.values[social?.name]}
                onChange={formik.handleChange}
                name={social?.name}
                placeholder="Enter Profile URL"
                style={inputStyle}
              />
            </FormControl>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default SocialMediaProfile;
