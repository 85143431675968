import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_LOADER, SET_TOAST,
  uploadDocumentAction,
  updateRosterDocumentAction,
  deleteRosterDocumentAction,
  verifyRosterDocumentAction, getMe, SET_SUCCESS_ERROR_POPUP
} from "../../store/actions";
import StatusButton from "../Common/StatusButton";
import { UploadFileRounded } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import UnsuccessCheck from "../../assets/images/unsuccess_check.jpg";
import SuccessCheck from "../../assets/images/success_check.png";
import TrashIcon from "../../assets/images/icons/trash.jpg";
import DeleteConfirmPopup from "../Common/DeleteConfirm";
import { getFileNameFromFirebaseUrl } from "../../utils/functions";

const UploadRosterDocuments = ({ open, handleClose, rosterId = "", selectedAthlete, isShowRosterId = true }) => {

  const checkImgStyle = { width: "30px", height: "30px", objectFit: "contain" };
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userType = state?.user?.user?.user_type;
  const [documentTypes, setDocumentTypes] = useState([
    {
      id: 1,
      docType: "birth_certificate", type: "Birth Certificate or State ID",
      docUrl: selectedAthlete?.birth_certificate ?? "",
      status: selectedAthlete?.birth_certificate ? "COMPLETE" : "NOT STARTED",
      placeholder: "Upload birth certificate or state ID...",
      accept: "application/pdf, image/*"
    },
    // {
    //   id: 2,
    //   docType: "state_id", type: "State or School ID",
    //   docUrl: selectedAthlete?.state_id ?? "",
    //   status: selectedAthlete?.state_id ? "COMPLETE" : "NOT STARTED",
    //   placeholder: "Upload state or school ID..."
    // },
    {
      id: 3,
      docType: "report_card",
      type: "Report Card or Grade Verification",
      docUrl: selectedAthlete?.report_card ?? "",
      status: selectedAthlete?.report_card ? "COMPLETE" : "NOT STARTED",
      placeholder: "Upload report card or grade verification...",
      accept: "application/pdf, image/*"
    },
    {
      id: 4,
      docType: "image",
      type: "Profile Picture",
      docUrl: selectedAthlete?.image ?? "",
      status: selectedAthlete?.image ? "COMPLETE" : "NOT STARTED",
      placeholder: "Upload profile picture...",
      accept: "image/*"
    },
  ]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [documentObj, setDocumentObj] = useState({})

  let isAllDocUploaded = false;
  let allDocStatusArr = documentTypes?.map(doc => doc?.status);
  let isAllDocNotStarted = allDocStatusArr?.every(status => "NOT STARTED".includes(status));
  let isAllDocCompleted = allDocStatusArr?.every(status => "COMPLETE".includes(status));
  documentTypes?.forEach(doc => {
    if (doc?.docUrl) {
      isAllDocUploaded = true;
    } else {
      isAllDocUploaded = false;
    }
  });

  useEffect(() => {
    if (selectedAthlete?.tournamentDocumentRequired?.length > 0) {
      const filteredDocArr = documentTypes.filter(item => selectedAthlete?.tournamentDocumentRequired?.includes(item.docType));
      setDocumentTypes(filteredDocArr)
    } else {
      setDocumentTypes([])
    }
  }, [])

  const handleChangeFileUpload = (e, id, docType) => {
    let file = e?.target?.files[0];
    let newArr = documentTypes.map(obj => {
      if (obj.id == id) {
        obj.placeholder = file?.name
      }
      return obj;
    });
    setDocumentTypes(newArr);
    uploadDocument(file, id, docType);
  }

  const uploadDocument = async (file, id, docType) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let formData = new FormData();
    formData.append("files", file ?? "");
    formData.append("type", "FilmRoom");
    formData.append("fileType", "Thumbnail");
    formData.append("userId", rosterId ?? "");
    try {
      dispatch({ type: SET_LOADER, data: true });
      let response = await uploadDocumentAction(dispatch, params, formData);
      if (response?.ok) {
        let url = response?.url ?? "";
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response?.message ?? "Success!" }
        })
        let newArr = documentTypes.map(obj => {
          if (obj.id == id) {
            obj.docUrl = url;
          }
          return obj;
        });
        setDocumentTypes(newArr);
        updateDocument(id, docType, url)
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error?.message ?? "Something went wrong" }
      })
    }
  }

  const updateDocument = async (id, docType, url) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let bodyParams = {
      user_id: rosterId ?? "",
      type: docType,
      url: url
    }
    try {
      dispatch({ type: SET_LOADER, data: true });
      let response = await updateRosterDocumentAction(dispatch, params, bodyParams);
      if (response?.ok) {
        dispatch(getMe())
        let newArr = documentTypes.map(obj => {
          if (obj.id == id) {
            obj.status = "COMPLETE";
          }
          return obj;
        });
        setDocumentTypes(newArr);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error?.message ?? "Something went wrong" }
      })
    }
  }

  const deleteDocument = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    let bodyParams = {
      user_id: rosterId ?? "",
      type: documentObj?.docType,
    }
    try {
      dispatch({ type: SET_LOADER, data: true });
      let response = await deleteRosterDocumentAction(dispatch, params, bodyParams);
      if (response?.ok) {
        dispatch(getMe())
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'success', message: response?.message ?? "Success!" }
        })

        let newArr = documentTypes.map(obj => {
          if (obj.id == documentObj?.id) {
            obj.docUrl = "";
            obj.status = "NOT STARTED"
            if (obj.id == 1) {
              obj.placeholder = "Upload birth certificate or state ID......"
            } else if (obj.id == 3) {
              obj.placeholder = "Upload report card or grade verification..."
            } else {
              obj.placeholder = "Upload profile picture..."
            }
          }
          return obj;
        });
        setDocumentTypes(newArr);
        setShowDeletePopup(false)
        setDocumentObj({})
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error?.message ?? "Something went wrong" }
      })
    }
  }

  const verifyRosterDocument = async () => {
    if (!isAllDocUploaded) {
      return;
    }
    try {
      let params = {
        userToken: state?.user?.token,
        orgToken: state?.org?.org?.token,
      }
      let bodyParams = {
        user_id: rosterId ?? "",
      }
      dispatch({ type: SET_LOADER, data: true });
      let response = await verifyRosterDocumentAction(dispatch, params, bodyParams);
      if (response?.ok) {
        dispatch({ type: SET_LOADER, data: false });
        handleClose();
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
        })
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: error?.message ?? "Something went wrong" }
      })
    }
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => handleClose(true)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle pt={0}>
          <Box
            display="flex"
            justifyContent="space-between"
            py={2}
            px={1}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="h4"
                fontWeight={700}
                fontSize={20}
                color={theme?.palette?.text?.containerTextLabel}
              >
                Required Documents
              </Typography>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize={12}
                color={theme?.palette?.secondary?.darkLabel_1}
                sx={{ mt: 1, }}
              >
                {selectedAthlete?.first_name ?? "N/A"} {selectedAthlete?.last_name ?? "N/A"}
              </Typography>
              {isShowRosterId &&
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize={12}
                  color={theme?.palette?.secondary?.darkLabel_1}
                >
                  Roster ID# {selectedAthlete?.roasterId ?? "N/A"}
                </Typography>
              }
            </Box>
            <StatusButton
              status={
                isAllDocNotStarted ? "NOT STARTED" :
                  isAllDocCompleted ? "COMPLETE" : "IN PROGRESS"
              }
              style={{ fontWeight: 500, height: "40px", py: 1.5, px: 3, mt: 2, display: "inline-block", }}
            />
          </Box>
        </DialogTitle>
        <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }}>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        {/* <DialogContent sx={{ paddingX: 6 }}> */}
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={2}>
            {documentTypes?.map((doc) => (
              <Grid item xs={12} key={doc?.id}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item md={4} sm={12} xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                      {doc?.status === "COMPLETE" && userType === "admin" && !selectedAthlete?.is_document_verified ?
                        <span onClick={() => { setShowDeletePopup(true); setDocumentObj(doc) }}
                          style={{
                            display: "block",
                            width: 2,
                            marginRight: "8px",
                            verticalAlign: "top",
                          }}
                        >
                          <img
                            src={TrashIcon}
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "contain",
                              cursor: "pointer",
                              verticalAlign: "top",
                            }} />
                        </span>
                        : null
                      }

                      {doc?.status === "COMPLETE" ?
                        <img src={SuccessCheck} style={checkImgStyle} /> :
                        <img src={UnsuccessCheck} style={checkImgStyle} />
                      }
                      <Typography
                        variant="h6"
                        fontWeight={500}
                        fontSize={13}
                        color={theme?.palette?.secondary?.darkLabel_1}
                        sx={{
                          ml: 2,
                          textDecoration: doc?.docUrl ? "underline" : "none",
                          cursor: doc?.docUrl ? "pointer" : "inherit",
                        }}
                        onClick={() => {
                          if (doc?.docUrl) {
                            window.open(doc?.docUrl);
                          }
                        }}
                      >
                        {doc?.type}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={5} sm={8} xs={12}>
                    {selectedAthlete?.is_document_verified ?
                      null :
                      <FormControl
                        fullWidth
                      >
                        <InputLabel sx={{ fontSize: "14px" }} shrink={false}>
                          {doc?.docUrl ? getFileNameFromFirebaseUrl(doc?.docUrl) : doc?.placeholder}
                        </InputLabel>
                        <OutlinedInput
                          name={`doc_${doc?.id}`}
                          id={`doc_${doc?.id}`}
                          type={"file"}
                          onChange={(e) => {
                            handleChangeFileUpload(e, doc?.id, doc?.docType);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="docImg"
                                edge="end"
                                size="large"
                                htmlFor={`doc_${doc?.id}`}
                                component="label"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor:
                                    theme?.palette?.text?.containerTextLabel,
                                  borderRadius: "0 3px 3px 0",
                                  width: '50px',
                                  left: "3px",
                                  // ml: 'auto',
                                  // mr: '-14px',
                                  p: '10px'
                                }}
                              >
                                <UploadFileRounded sx={{ color: "white" }} />
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: "12.5px 14px",
                            },
                            "& ::file-selector-button": {
                              opacity: 0,
                              display: "none",
                            },
                            '& .MuiInputAdornment-root': {
                              width: 'auto',
                              marginLeft: '0',
                            }
                          }}
                          inputProps={{
                            style: {
                              opacity: 0,
                            },
                            accept: doc?.accept
                          }}
                        />
                      </FormControl>}
                  </Grid>
                  <Grid item md={3} sm={4} xs={12}>
                    <StatusButton status={doc?.status} style={{ fontWeight: 600, height: "40px", }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ m: 0 }}></Divider>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {/* <Grid container spacing={2}>
            {documentTypes?.map((doc) => (
              <Grid item xs={12} key={doc?.id}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box width={0.25} display="flex" alignItems="center">
                    {doc?.status === "COMPLETE" && userType === "admin" && !selectedAthlete?.is_document_verified ?
                      <img
                        onClick={() => { setShowDeletePopup(true); setDocumentObj(doc) }}
                        src={TrashIcon}
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "contain",
                          marginRight: "10px",
                          cursor: "pointer"
                        }} /> : null
                    }
                    {doc?.status === "COMPLETE" ?
                      <img src={SuccessCheck} style={checkImgStyle} /> :
                      <img src={UnsuccessCheck} style={checkImgStyle} />
                    }
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize={13}
                      color={theme?.palette?.secondary?.darkLabel_1}
                      sx={{
                        ml: 2,
                        textDecoration: doc?.docUrl ? "underline" : "none",
                        cursor: doc?.docUrl ? "pointer" : "inherit",
                      }}
                      onClick={() => {
                        if (doc?.docUrl) {
                          window.open(doc?.docUrl);
                        }
                      }}
                    >
                      {doc?.type}
                    </Typography>
                  </Box>
                  <Box width={0.45}>
                    {selectedAthlete?.is_document_verified ?
                      null :
                      <FormControl
                        fullWidth
                        sx={{ my: 1, maxWidth: "100%", ml: 2, width: "100%", }}
                      >
                        <InputLabel sx={{ fontSize: "14px" }} shrink={false}>
                          {doc?.docUrl ? getFileNameFromFirebaseUrl(doc?.docUrl) : doc?.placeholder}
                        </InputLabel>
                        <OutlinedInput
                          name={`doc_${doc?.id}`}
                          id={`doc_${doc?.id}`}
                          type={"file"}
                          onChange={(e) => {
                            handleChangeFileUpload(e, doc?.id, doc?.docType);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="docImg"
                                edge="end"
                                size="large"
                                htmlFor={`doc_${doc?.id}`}
                                component="label"
                                sx={{
                                  zIndex: 1,
                                  backgroundColor:
                                    theme?.palette?.text?.containerTextLabel,
                                  borderRadius: "0 3px 3px 0",
                                  width: '50px',
                                  left: "3px",
                                  // ml: 'auto',
                                  // mr: '-14px',
                                  p: '10px'
                                }}
                              >
                                <UploadFileRounded sx={{ color: "white" }} />
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: "12.5px 14px",
                            },
                            "& ::file-selector-button": {
                              opacity: 0,
                              display: "none",
                            },
                            '& .MuiInputAdornment-root': {
                              width: 'auto',
                              marginLeft: '0',
                            }
                          }}
                          inputProps={{
                            style: {
                              opacity: 0,
                            },
                            accept: doc?.accept
                          }}
                        />
                      </FormControl>}
                  </Box>
                  <Box sx={{ ml: 4 }}>
                    <StatusButton status={doc?.status} style={{ fontWeight: 600, height: "40px", }} />
                  </Box>
                </Box>
                <Box width={1} height={"1px"} bgcolor={"gray"} sx={{ opacity: 0.25 }} mt={3} mb={1}>
                  <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25 }}
                  ></Box>
                </Box>
              </Grid>
            ))}
          </Grid> */}
        </DialogContent >
        <DialogActions sx={{ p: 3, pt: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button
            size="small"
            variant="contained"
            sx={{ paddingX: 3.5, paddingY: 1 }}
            color="inherit"
            onClick={() => handleClose(true)}
          >
            Close
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {userType === "admin" && !selectedAthlete?.is_document_verified ?
              <Button
                size="small"
                variant="outlined"
                sx={{ paddingX: 3.5, paddingY: 0.8, fontWeight: 600, border: "2px solid" }}
                disabled={!isAllDocUploaded}
                onClick={verifyRosterDocument}
              >
                Verify User
              </Button>
              : null}
          </Box>
        </DialogActions>
      </Dialog >
      {showDeletePopup &&
        <DeleteConfirmPopup
          title={"Document"}
          message={"Are you sure you want to delete this Document?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setDocumentObj({});
          }}
          handleConfirm={deleteDocument}
        />
      }
    </>
  );
};

export default UploadRosterDocuments;
