import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import MuiPhoneNumber from 'material-ui-phone-number'

import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import httpClient from '@utils/httpClient'
import { SET_LOADER } from '@store/actions'
import PhoneInput from 'react-phone-input-2';

const validationSchema = Yup.object({
    parent_email: Yup.string()
        .required('Email is required')
        .email('Must be a valid email'),
    parent_mobile: Yup.string().min(10).required('Mobile is required'),
})

const EditDetailScreen = ({ setStep, formData, setFormData, isFromPackageBuy = false }) => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const [showError, setShowError] = useState(null)

    const goBack = () => {
        setStep(3)
    }

    const handleSave = async (values) => {
        // CHECK FOR VALID EMAIL & MOBILE //
        const formattedMobile = "1" + values.parent_mobile
        const mobile = values.parent_mobile
        setShowError(null)
        dispatch({ type: SET_LOADER, data: true })
        const res = await httpClient.post(isFromPackageBuy ? '/auth/checkEmail' :'/auth/verifyEmail', {
            email: values.parent_email,
            mobile,
        })
        dispatch({ type: SET_LOADER, data: false })
        if (!res || !res.ok) {
            setShowError(res.message)
            return false
        } else {
            setFormData((prevStage) => {
                return {
                    ...prevStage,
                    ...values,
                    //mobile,
                    formattedMobile,
                }
            })
            setStep(2)
        }
    }

    const formik = useFormik({
        initialValues: {
            parent_email: formData.parent_email || '',
            parent_mobile: formData.parent_mobile || '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSave,
    })

    return (
        <Grid container direction="column" justifyContent="left" sx={{ p: 2 }}>
            {showError && (
                <Grid item xs={12} alignItems="center" justifyContent="left">
                    <Alert severity="error" sx={{ border: '1px solid #FF0000' }}>{showError}</Alert>
                </Grid>
            )}
            <Box sx={{ maxWidth: '450px', margin: '0 auto', width: '100%' }}><Grid item xs={12} alignItems="center" textAlign="center">
                <Typography variant="h3" sx={{ fontSize: '14px', color: '#3B3B3B', }}>
                    Make a mistake or do you need to update your account
                    information?
                </Typography>
            </Grid>
                <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body1" sx={{ my: 2, fontFamily: '"Poppins",sans-serif,Arial', color: '#3B3B3B', fontSize: '14px' }}>
                        If you need to make an update to the information you
                        provided, do so here.
                    </Typography>
                </Grid>
            </Box>
            <Grid item xs={12}>
                <Box sx={{ maxWidth: '350px', margin: '0 auto', width: '100%' }}>
                    <form onSubmit={formik.handleSubmit}>
                        {/* Email */}
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.parent_email && formik.errors.parent_email
                            )}
                            sx={{
                                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                    border: '0',
                                    boxShadow: '0px 6px 20px #1F54C326',
                                }
                            }}
                        >
                            <TextField
                                id="parent_email"
                                name="parent_email"
                                type="email"
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.parent_email.toLowerCase()}
                                label="Email *"
                                error={Boolean(
                                    formik.touched.parent_email && formik.errors.parent_email
                                )}
                            />
                            {Boolean(
                                formik.touched.parent_email && formik.errors.parent_email
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.parent_email}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        {/* Mobile */}
                        {/* <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.parent_mobile && formik.errors.parent_mobile
                            )}
                            sx={{
                                ...theme.typography.customInput, '& .MuiOutlinedInput-notchedOutline': {
                                    border: '0',
                                    boxShadow: '0px 6px 20px #1F54C326',
                                }
                            }}
                        >
                            <MuiPhoneNumber
                                id="parent_mobile"
                                name="parent_mobile"
                                defaultCountry={'us'}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={(val) =>
                                    formik.setFieldValue('parent_mobile', val || '')
                                }
                                value={formik.values.parent_mobile}
                                label="Mobile *"
                                error={Boolean(
                                    formik.touched.parent_mobile && formik.errors.parent_mobile
                                )}
                            />
                            {Boolean(
                                formik.touched.parent_mobile && formik.errors.parent_mobile
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.parent_mobile}
                                    </FormHelperText>
                                )}
                        </FormControl> */}
                        <FormControl
                            fullWidth
                            error={Boolean(
                                formik.touched.parent_mobile && formik.errors.parent_mobile,
                            )}
                            sx={{
                                ...theme.typography.customInput,
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "0",
                                    boxShadow: "0px 6px 20px #1F54C326",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    '& .react-tel-input .form-control': {
                                        border: 0,
                                        "box-shadow": "0px 6px 20px #1F54C326",
                                        "min-width": "100%",
                                        "font-weight": 500,
                                        background: "#ffffff",
                                        padding: "15px 14px 15px 50px",
                                        "border-radius": "0",
                                        height: "51px",
                                    },
                                    "& .react-tel-input .flag-dropdown": {
                                        border: 0,
                                        background: "transparent"
                                    }
                                }
                                }
                            >
                                <PhoneInput
                                    country={"us"}
                                    id="parent_mobile"
                                    name="parent_mobile"
                                    placeholder="(702) 123-4567"
                                    variant="outlined"
                                    onlyCountries={['us']}
                                    disableDropdown={true}
                                    disableCountryCode={true}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.parent_mobile}
                                    onChange={(value, country) => {
                                        formik.setFieldValue('parent_mobile', value || "");
                                    }}
                                    error={Boolean(
                                        formik.touched.parent_mobile && formik.errors.parent_mobile
                                    )}
                                />
                            </Box>
                            {Boolean(
                                formik.touched.parent_mobile && formik.errors.parent_mobile,
                            ) && (
                                    <FormHelperText error>
                                        {formik.errors.parent_mobile}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        {/* Links */}
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            sx={{ mt: 4, mb: 6 }}
                        >
                            <Typography
                                variant="body1"
                                color="secondary"
                                align="center"
                                className="pointer"
                                onClick={goBack}
                                sx={{ fontSize: '12px', padding: '0', color: '#383C51', fontFamily: 'Arial', textDecoration: 'underline' }}
                            >
                                Cancel edit and <b>go back</b>.
                            </Typography>
                        </Stack>

                        {/* Button */}
                        <Box sx={{ mt: 2 }}>
                            <Button
                                disableElevation
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{ fontWeght: '600' }}
                            >
                                Update and Resend OTP
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    )
}

export default EditDetailScreen
