import { Box, useTheme } from "@mui/material";

const StatusButton = ({ status, style, options }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        color: "white",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        textAlign: "center",
        padding: "0.4rem 1rem",
        lineHeight: "1.3",
        background: status
          ? status === "COMPLETE"
            ? theme?.palette?.inputsLabels?.green
            : status === "IN PROGRESS"
              ? "rgb(20, 115, 230)"
              : theme?.palette?.error?.main
          : theme?.palette?.dark?.dark3,
        borderRadius: "4px",
        ...style,
      }}
      {...options}
    >
      {status
        ? status === "COMPLETE"
          ? "complete"
          : status === "IN PROGRESS"
            ? "In Progress"
            : "Not Started"
        : "N/A"}
    </Box>
  );
};

export default StatusButton;
