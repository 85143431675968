import { Avatar, Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
    SET_LOADER,
    SET_TOAST,
    getAthleteListPdfAction,
    getTournamentTeamMemberAction
} from "../../store/actions";
import { charValidate } from "../../utils/functions";
import { Tournament_Add_Player_Page, Tournament_Teams_Page } from "../../routes/constants";
import { useLocation, useNavigate } from "react-router";
import UploadRosterDocuments from "../../components/Tournament/UploadRosterDocuments";
import StatusButton from "../../components/Common/StatusButton";
import SuccessCheck from "../../assets/images/success_check.png";
import ChangeJerseyPosition from "../../views/team-manager/ChangeJerseyPosition";
import FeePaymentDescription from "../../components/TournamentTeams/FeePaymentDescription";
import { useParams } from 'react-router-dom';

const ViewTeamRoster = () => {
    const tableRef = useRef();
    const [selectedDataGridRow, setSelectedDataGridRow] = useState();
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [paginationOptions, setPaginationOptions] = useState({
        pageSize: 0,
        pageNum: 0,
        limit: 5,
    });
    const checkImgStyle = { width: "30px", height: "30px", objectFit: "contain", cursor: "pointer" };
    const state = useSelector((state) => state);
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationState = location?.state;
    const teamName = navigationState?.teamName ?? "";
    const [dataList, setDataList] = useState([]);
    const [isRosterAthleteListLoading, setRosterAthleteListLoading] = useState(false);
    const [showJerseyPositionModal, setShowJerseyPositionModal] = useState(false);
    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
    const [selectedAthletesForPaymentAll, setSelectedAthletesForPaymentAll] = useState([]);
    const userType = state?.user?.user?.user_type;
    const { teamId } = useParams();

    useEffect(() => {
        if (state?.user?.token && state?.org?.org?.token) {
            getDataList();
        }
    }, [state?.user?.token, state?.org?.org?.token, navigationState]);

    const getDataList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        setRosterAthleteListLoading(true);
        let response = await getTournamentTeamMemberAction(params, teamId ?? "");
        setRosterAthleteListLoading(false);
        if (response?.ok) {
            let dataList = response?.data?.athleteList;
            setDataList(dataList ?? []);
        }
    }

    const printDataListPdf = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        dispatch({ type: SET_LOADER, data: true });
        let response = await getAthleteListPdfAction(params, navigationState?.teamId ?? "");
        dispatch({ type: SET_LOADER, data: false });
        if (response?.ok) {
            window.open(response?.data)
        }
    }

    const handleShowDocModal = (row, roasterId) => {
        // if (
        //   row?.documentStatus === "NOT STARTED" ||
        //   row?.documentStatus === "IN PROGRESS"
        // ) {
        row.roasterId = roasterId;
        setSelectedDataGridRow(row);
        setShowDocumentModal(true);
        // }
    }

    const handleCloseDocModal = () => {
        setShowDocumentModal(false);
        getDataList();
    };

    const handleCloseJerseyPositionModal = (isClose) => {
        setShowJerseyPositionModal(false);
        if (!isClose) {
            getDataList();
        }
    }

    let columns = [
        {
            field: "id",
            headerName: "ROSTER ID#",
            minWidth: 80,
            flex: 1,
            renderCell: (data) => data.api.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1,
            sortable: false,
        },
        {
            field: "teamName",
            headerName: "TEAM NAME",
            flex: 1,
            minWidth: 110,
            renderCell: (data) => {
                if (data?.row?.team && data?.row?.team?.length) {
                    return (
                        <Box display="flex" flexDirection="column">
                            {data?.row?.team?.map(team => (
                                <Typography
                                    sx={{ pt: 0.4 }}
                                    fontFamily={"Poppins"}
                                    fontSize={14}
                                    fontWeight={600}
                                    color={theme?.palette?.grey["700"]}
                                >
                                    {team?.title ?? "N/A"}
                                </Typography>
                            ))}
                        </Box>
                    )
                } else {
                    return "N/A"
                }
            },
            sortable: false,
        },
        {
            field: "first_name",
            headerName: "ATHLETE FIRST NAME",
            flex: 1,
            minWidth: 120,
            renderCell: (data) => (
                <Tooltip title={data?.row?.first_name ?? "N/A"}>
                    <Box sx={{ display: 'flex' }}>
                        <Avatar
                            sx={{
                                bgcolor: theme?.palette?.success?.main,
                                color: "white",
                                mt: 0.4,
                                marginRight: 1,
                                width: 16,
                                height: 16,
                                fontSize: 10,
                            }}
                        >
                            A
                        </Avatar>
                        {data?.row?.first_name
                            ? charValidate(data?.row?.first_name, 15)
                            : "N/A"}
                    </Box>
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "last_name",
            headerName: "ATHLETE LAST NAME",
            flex: 1,
            minWidth: 120,
            renderCell: (data) => (
                <Tooltip title={data?.row?.last_name ?? "N/A"}>
                    {data?.row?.last_name
                        ? charValidate(data?.row?.last_name, 15)
                        : "N/A"}
                </Tooltip>
            ),
            sortable: false,
        },
        {
            field: "jersey",
            headerName: "JERSEY #",
            flex: 1,
            minWidth: 120,
            sortable: false,
        },
        {
            field: "position",
            headerName: "POSITION",
            renderCell: (data) => {
                return (
                    <>
                        {data?.row?.position ? data?.row?.position : "N/A"}
                    </>
                )
            },
            flex: 1,
            minWidth: 120,
            sortable: false,
        },
        {
            field: "documentStatus",
            headerName: "DOCUMENT STATUS",
            align: "center",
            headerAlign: "center",
            renderCell: (data) => (
                <>
                    {
                        data?.row?.isPermitted &&
                        <>
                            {data?.row?.is_document_verified ?
                                <img
                                    src={SuccessCheck}
                                    style={checkImgStyle}
                                //onClick={() => handleShowDocModal(data?.row, data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1)}
                                /> :
                                <StatusButton
                                    status={data?.row?.documentStatus}
                                    // options={{
                                    //     onClick: () => handleShowDocModal(data?.row, data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1)
                                    // }}
                                    style={{
                                        fontWeight: 500,
                                        // cursor: "pointer",
                                    }}
                                />
                            }
                        </>
                    }
                </>
            ),
            flex: 1,
            minWidth: 120,
            sortable: false,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            align: "center",
            headerAlign: "center",
            renderCell: (data) => (
                <>
                    {
                        data?.row?.isPermitted &&
                        <>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ height: '30px', width: '30px', borderRadius: '50px !important', minWidth: '0px !important', padding: '0px !important' }}
                                onClick={() => handleShowDocModal(data?.row, data?.api?.getRowIndexRelativeToVisibleRows(data?.row?.id) + 1)}
                            >
                                +
                            </Button>
                        </>
                    }
                </>
            ),
            flex: 1,
            minWidth: 120,
            sortable: false,
        },
    ];

    return (
        <>
            <MuiDataGrid
                headerLabel={teamName ? `Team Roster (${teamName})` : "Team Roster"}
                gridData={isRosterAthleteListLoading ? [] : dataList}
                columns={columns}
                paginationOptions={paginationOptions}
                tableRef={tableRef}
                isLoading={isRosterAthleteListLoading}
                isAddNewBtnShow={false}
                handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
                gridButtons={() => {
                    return (
                        <Box sx={{ my: 2, px: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ paddingX: 3.5, paddingY: 1 }}
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Back
                            </Button>
                        </Box>
                    )
                }}
                tableMessageForPaidRostersList={
                    userType === "player" || userType === "parent" ?
                        "Your Team Manager is finalizing the team registration. Once they complete this process, you'll be able to see the roster here." :
                        "You must pay the roster fee to view the full roster. Click or tap on View unpaid roster to complete roster registration."
                }
            />
            {showDocumentModal &&
                <UploadRosterDocuments
                    open={showDocumentModal}
                    handleClose={handleCloseDocModal}
                    rosterId={selectedDataGridRow?.id ?? ""}
                    selectedAthlete={selectedDataGridRow}
                />
            }
        </>
    );
};

export default ViewTeamRoster;
