import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiDataGrid from "../MuiDataGrid/MuiDataGrid";
import { charValidate } from "../../utils/functions";
import { SET_SUCCESS_ERROR_POPUP, getMyProductPacksAction } from "../../store/actions";

const BoughtPackages = ({
  open,
  handleClose,
}) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    limit: 5,
  });
  const [packs, setPacks] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getListData();
  }, []);

  function transformInputToOutput(input) {
    const output = [];
    input.forEach(item => {
      item.packs.forEach(pack => {
        const transformedItem = {
          id: pack.id,
          packTitle: item.packTitle,
          name: item.name,
          desc: item.desc,
          pack_title: pack.title,
          auto_renew: item.auto_renew,
          pack: pack.pack,
          utilized: pack.utilized,
          amount: pack.amount
        };
        output.push(transformedItem);
      });
    });

    return output;
  }

  const getListData = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    }
    setLoader(true);
    let response = await getMyProductPacksAction(params, dispatch);

    if (response?.ok) {
      let dataList = response?.data;
      if (dataList && dataList?.length) {
        let rows = transformInputToOutput(dataList) || [];
        setPacks(rows);
      }
      setLoader(false);
    } else {
      setLoader(false);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: 'error', message: response?.message ?? "Something went wrong" }
      })
    }
  }

  // const createData = (
  //   id,
  //   packTitle,
  //   name,
  //   desc,
  //   pack_title,
  //   auto_renew,
  //   pack,
  //   amount
  // ) => {
  //   return { id, packTitle, name, desc, pack_title, auto_renew, pack, amount };
  // }

  let columns = [
    {
      field: "packTitle",
      headerName: "product category",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.row?.packTitle ?? "N/A"}>
          <Box sx={{ display: 'flex' }}>
            {data?.row?.packTitle
              ? charValidate(data?.row?.packTitle, 15)
              : "N/A"}
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "name",
      headerName: "product name",
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.row?.name ?? "N/A"}>
          <Box sx={{ display: 'flex' }}>
            {data?.row?.name
              ? charValidate(data?.row?.name, 15)
              : "N/A"}
          </Box>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: 'pack_title',
      headerName: 'Pack',
      flex: 1,
      renderCell: (data) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {data?.row?.pack_title ? `$${data?.row?.pack_title}` : 'N/A'}
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "auto_renew",
      headerName: "auto renew",
      flex: 1,
      renderCell: (data) => (
        <Box sx={{ display: 'flex' }}>
          {data?.row?.auto_renew ? "Yes" : "No"}
        </Box>
      ),
      sortable: false,
    },
    {
      field: 'pack',
      headerName: 'Pack Count',
      flex: 1,
      renderCell: (data) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {data?.row?.pack ? `$${data?.row?.pack}` : 'N/A'}
          </Box>
        );
      },
      sortable: false,
    },
    // {
    //   field: 'amount',
    //   headerName: 'Pack',
    //   flex: 1,
    //   renderCell: (data) => {
    //     return (
    //       <Box sx={{ display: 'flex' }}>
    //         {data?.row?.amount ? `$${data?.row?.amount}` : 'N/A'}
    //       </Box>
    //     );
    //   },
    //   sortable: false,
    // },
  ];

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => {
          handleClose(true);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={0}
            py={1}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: "column",
                alignItems: "start"
              }
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={25}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: "22px",
                }
              }}
            >
              My Products
            </Typography>
          </Box>
        </DialogTitle>
        <Box width={1} mb={1}>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        <DialogContent>
          <Box>
            {/* <MuiDataGrid
              headerLabel={"Packages"}
              gridData={[]}
              columns={[]}
              paginationOptions={paginationOptions}
              isLoading={false}
              isAddNewBtnShow={false}
            /> */}

            <TableContainer component={Paper} sx={{ border: "1px solid #e0e0e0", maxHeight: "400px", overflowY: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns?.map((column) => (
                      <TableCell
                        sx={{ fontWeight: 700, textTransform: "uppercase", whiteSpace: "nowrap", }}
                        align={column?.position ?? "left"}
                      >
                        {column?.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packs?.length ?
                    packs.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" sx={{ minWidth: "200px" }}>
                          <Tooltip title={row?.packTitle ?? "N/A"}>
                            <Box sx={{ display: 'flex' }}>
                              {/* {row?.packTitle
                              ? charValidate(row?.packTitle, 20)
                              : "N/A"} */}
                              {row?.packTitle ?? "N/A"}
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" sx={{ minWidth: "250px" }}>
                          <Tooltip title={row?.name ?? "N/A"}>
                            <Box sx={{ display: 'flex' }}>
                              {/* {row?.name
                              ? charValidate(row?.name, 20)
                              : "N/A"} */}
                              {row?.name ?? "N/A"}
                            </Box>
                          </Tooltip>
                        </TableCell>
                        {/* <TableCell align="right">
                        <Tooltip title={row?.desc ?? "N/A"}>
                          <Box sx={{ display: 'flex' }}>
                            {row?.desc
                              ? charValidate(row?.desc, 15)
                              : "N/A"}
                          </Box>
                        </Tooltip>
                      </TableCell> */}
                        <TableCell>
                          <Box>
                            {row?.pack_title}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            {row?.auto_renew ? "Yes" : "No"}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            {row?.pack}
                          </Box>
                        </TableCell>
                        {/* <TableCell>
                          <Box>
                            {row?.amount}
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    )) :
                    <Typography
                      variant="h4"
                      fontWeight={500}
                      fontSize={15}
                      sx={{
                        textAlign: "center",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        mt: 5,
                      }}
                    >
                      {loader ? "Loading..." : "No Data Found"}
                    </Typography>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            marginX: 6,
            paddingX: 0,
            pt: 2,
            pb: 3.5,
          }}>
          <Box width={1} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Box>
              <Button
                size="small"
                variant="contained"
                sx={{ paddingX: 3.5, paddingY: 1.2 }}
                color="inherit"
                onClick={() => {
                  handleClose(true);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BoughtPackages;
